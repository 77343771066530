import React from "react";

import { Container } from "./styles";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";
import { SLTable, SLTableCell } from "~/pages/Sistema/componentes/SistemaList";
function CustomBodyContent({ setTabFilter }) {
  const [tab, setTab] = React.useState();

  return (
    <>
      <TabsWrapper>
        <Tab
          materialIcon="priority_high"
          onClick={() => {
            setTab(0);
            setTabFilter({ etapa: (data) => data !== 1 && data !== 2 });
          }}
        >
          Pendentes
        </Tab>
        <Tab
          materialIcon="done"
          onClick={() => {
            setTab(1);
            setTabFilter({ etapa: 1 });
          }}
        >
          Aceitas
        </Tab>
        <Tab
          materialIcon="done_all"
          onClick={() => {
            setTab(2);
            setTabFilter({ etapa: 2 });
          }}
        >
          Finalizadas
        </Tab>
      </TabsWrapper>
      <Container>
        <SLTable noEdit noDelete={tab === 2}>
          <SLTableCell name="dados.aluno.nome">Aluno</SLTableCell>
          <SLTableCell name="periodoLetivo.nome">Período Letivo</SLTableCell>
          <SLTableCell name="serie.nome">Série</SLTableCell>
          <SLTableCell
            formatValue={(data) => (data ? "Sim" : "Não")}
            name="atualizacaoDados"
          >
            Req. Atualização
          </SLTableCell>
        </SLTable>
      </Container>
    </>
  );
}

export default CustomBodyContent;
