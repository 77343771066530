import React, { useState } from "react";

import api from "~/services/api";
import CreateEditContent from "./CreateEditContent";
import ShowContent from "./ShowContent";
import { SLCreateModal, SLShowModal, SLEditModal } from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import TableComponent from "./TableComponent";
import { useEffect } from "react";

function Clientes() {
  const [mountedData, setMountedData] = useState();
  const [tabFilter, setTabFilter] = useState({ status: 1 });
  const [estados, setEstados] = useState([]);
  const [condicoesPagamentos, setCondicoesPagamentos] = useState([]);

  useEffect(() => {
    api.get("estados").then((r) => setEstados(r?.data || []));
    api.get("admin/condicoespagamentos").then((r) => setCondicoesPagamentos(r?.data || []));
  }, []);

  return (
    <Container
      requests={{
        findAll: () => api.get("admin/empresas", { params: { paranoid: false } }),
        deleteOne: (thisData) => api.delete("admin/empresa", { data: { cod: thisData.cod } }),
        findOne: (thisData) => api.get("admin/empresa", { params: { cod: thisData.cod } }),
        restoreOne: (thisData) => api.post("admin/empresa/restaura", { data: { cod: thisData.cod } }),
      }}
      dataFilter={tabFilter}
    >
      <TableComponent {...{ setTabFilter }} />

      <SLShowModal title="Cliente" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLEditModal title="Atualizar Cliente" setter={setMountedData}>
        <CreateEditContent {...{ mountedData, estados, condicoesPagamentos }} />
      </SLEditModal>

      <SLCreateModal title="Novo Cliente">
        <CreateEditContent {...{ estados, condicoesPagamentos }} />
      </SLCreateModal>
    </Container>
  );
}

export default Clientes;
