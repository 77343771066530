import styled from "styled-components";

export const Container = styled.div`
  width: 50em;
  max-width: 100%;

  .Label_inputWithButton {
    display: flex;
    align-items: center;

    button {
      padding: 0.25em 0.5em;
      margin-bottom: 0.4em;
      margin-left: 0.25em;
    }
  }

  .Label_arquivos_file_input {
    margin-bottom: 1em;
  }
`;
