import React, { useEffect, useState } from "react";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import Label from "~/componentes/CustomForm/Label";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";

// import { Container } from './styles';

function InputBanco({ bancoSelected, setBancoSelected }) {

  return (
    <SistemaInput
      name="bancoCod"
      defaultValue={bancoSelected?.codigo || ""}
      setter={(banco) => setBancoSelected({...bancoSelected, codigo: banco || ""})}
      required
    />
  );
}

export default InputBanco;
