import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { Container, SubTitle } from "./styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import { Materialize } from "~/styles/materializeGrid";

function ShowContent({ mountedData }) {
  return (
    <Container>
      <CustomScrollBar fixScrollMargin autoHeightMax="80vh">
        <Materialize>
          <div className="row">
            <SubTitle>Dados do Contratante</SubTitle>
            <Label className="col s12 m12 l12" title="NOME:" text={mountedData?.proprietario?.nome || ""} />
            <Label className="col s12 m12 l6" title="EMAIL:" text={mountedData?.proprietario?.email || ""} />
            <Label className="col s12 m12 l3" title="CPF:" text={mountedData?.proprietario?.cpf || ""} />
            <Label className="col s12 m12 l3" title="DATA DE NASCIMENTO:" text={mountedData?.proprietario?.nascimento || ""} />
            <SubTitle>Dados da Empresa</SubTitle>
            <Label className="col s12 m12 l5" title="RAZÃO SOCIAL:" text={mountedData?.razaoSocial || ""} />
            <Label className="col s12 m12 l4" title="NOME FANTASIA:" text={mountedData?.nomeFantasia || ""} />
            <Label className="col s12 m12 l3" title="CNPJ:" text={mountedData?.cnpj || ""} />
            <Label className="col s12 m12 l6" title="EMAIL DE CONTATO:" text={mountedData?.email || ""} />
            <Label className="col s12 m12 l3" title="TELEFONE 1:" text={mountedData?.telefone1 || ""} />
            <Label className="col s12 m12 l3" title="TELEFONE 2:" text={mountedData?.telefone2 || ""} />
            <Label className="col s12 m12 l12" title="SITE:" text={mountedData?.site || ""} />
            <Label className="col s12 m12 l3" title="CEP:" text={mountedData?.cep || ""} />
            <Label className="col s12 m12 l3" title="PAIS:" text={mountedData?.pais || ""} />
            <Label className="col s12 m12 l3" title="ESTADO:" text={mountedData?.estado?.nome || ""} />
            <Label className="col s12 m12 l3" title="CIDADE:" text={mountedData?.cidade?.nome || ""} />
            <Label className="col s12 m12 l3" title="BAIRRO:" text={mountedData?.bairro || ""} />
            <Label className="col s12 m12 l7" title="LOGRADOURO:" text={mountedData?.logradouro || ""} />
            <Label className="col s12 m12 l2" title="NÚMERO:" text={mountedData?.numeroLogradouro || ""} />
            <Label className="col s12 m12 l6" title="COMPLEMENTO:" text={mountedData?.complementoLogradouro || ""} />
            <Label className="col s12 m12 l3" title="PLANO:" text={mountedData?.plano?.nome || ""} />
            <Label className="col s12 m12 l3" title="QUANTIDADE DE ALUNOS:" text={mountedData?.plano?.quantidadeAlunos || ""} />
            <Label className="col s12 m12 l5" title="TOKEN DE PAGAMENTO PUBLICO:" text={mountedData?.tokenPagamentoPub || ""} />
            <Label className="col s12 m12 l5" title="TOKEN DE PAGAMENTO PRIVADO:" text={mountedData?.tokenPagamentoPriv || ""} />
          </div>
        </Materialize>
      </CustomScrollBar>
    </Container>
  );
}

export default ShowContent;
