import styled from "styled-components";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";

export const Container = styled(SistemaComponenteInterno)`
  margin-bottom: 1em;

  .ComunicadoEscolar_selectUsuarioDiv {
    display: flex;
    align-items: center;
    margin-bottom: 2em;

    p {
      margin-right: 0.5em;
    }
  }
`;
