import styled from "@react-pdf/styled-components";

export const Content = styled.Page`
  padding: 5%;
  color: #333;
  font-weight: normal;
  font-size: 14pt;
`;

export const Card = styled.View`
  border-bottom: /*1pt solid #aaa*/ none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 12pt;
  padding: 2pt;
  margin-bottom: 10px;
`;

export const TwoColumnCell = styled.Text`
  border: 1px solid black;
  padding: 5px;
  width: 50%;
`;
export const FourColumnCell = styled.Text`
  border: 1px solid black;
  padding: 5px;
  width: 25%;
`;

export const ColumnCell = styled.Text`
  width: 25%;
`;

export const Paragraph = styled.Text`
  border: 1px solid black;
  padding: 10px;
  /* margin-top: 1%;
  margin-bottom: 1%; */
  display: block;
  width: 100%;
`;
export const Nota = styled.Text`
  margin-top: 1%;
  margin-bottom: 1%;
  display: block;
  width: 100%;
  font-size: 15pt;
`;

export const Title = styled.Text`
  color: #000;
  font-weight: bolder;
  text-align: center;
  font-size: 20pt;
  margin-top: 3%;
  margin-bottom: 3%;
`;

export const Strong = styled.Text`
  color: #000;
  display: inline;
`;
