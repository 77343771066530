import React from "react";
import styled from "styled-components";

/**
 * Div que separa duas childrens em um única linha quando estiver em um desktop, e em linha única para mobile.
 * Transforma os inputs e selects em 100% do width.
 * @param {Object} props
 * @param {Boolean} props.rightAlign Faz que as childrens tenha um text-aling end e a primeira start.
 */
function ThisDiv({ children, rightAlign, ...rest }) {
  return <div {...rest}>{children}</div>;
}

export const FlexDiv = styled(ThisDiv)`
  display: flex;
  width: 100%;
  flex-direction: column;

  /* input,
  textarea,
  select, */
  > div,
  label {
    width: 100%;
  }

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;

    ${(props) =>
      props.rightAlign &&
      `
    text-align: end;

    & > :first-child {
      text-align: start;
    }
    `}

    /* input,
    textarea,
    select, */
    > div,
    label {
      width: 49%;
    }

    /* label textarea,
    input,
    select {
      width: 100%;
    } */
  }
`;
