import React, { useState } from "react";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";
import SistemaTable from "~/componentes/StyledComponents/Tables/SistemaTable";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import Label from "~/componentes/CustomForm/Label";
import IconButton from "~/componentes/CustomForm/Button/Styles/IconButton";
import Pagination from "~/componentes/Pagination";
import { useEffect } from "react";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import api from "~/services/api";

import { ThisAddDisciplinaTurma } from "./styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import ShowError from "~/utils/ShowError";
import { Materialize } from "~/styles/materializeGrid";
import { SubTitle } from "../ShowContent/styles";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import { SLForm, SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import sistemaListStore, { sistemaListShowModal } from "~/pages/Sistema/componentes/SistemaList/ThisMainComponent/thisStore";
import ColoredFileInput from "~/componentes/CustomForm/FileInput/styles/ColoredFileInput";

function ADDNotaAlunoTurmaModalEdit({ data, outClick, dataDia }) {
  const [dtMatriculada, setDtMatriculada] = useState([]);
  const [chamada, setChamada] = useState([]);
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState('');
  const { state, dispatch } = React.useContext(sistemaListStore);

  //console.log("periodocod", data)
  let alunosNotas = data?.t?.notas.filter((el)=> {
    if(data.tudo.turma.periodo[0].cod == parseInt(data.bimestre)){
      return el.notaAvaliacao
    }
  });

  useEffect(() => {
    (async () => {
      let res = await api.get("professor/chamada/one", { params: { alunoTurmaCod: data?.t?.alunoTurmaCod, data: dataDia } });
      setChamada(res.data)
      if(res.data[0]?.tipo){
        setStatus(res.data[0]?.tipo)
      }
    })()
  }, [])
  
  let endSubmit = false;
  function handleSubmit(dataPut, options){
    //console.log(dataPut);
    //console.log(options);
    //console.log(state);
    //console.log(chamada);

    if (dataPut instanceof FormData) {
      console.log(dataPut.get('descricao_faltajustificada'));
      !dataPut.get('descricao_faltajustificada') && dataPut.set("descricao_faltajustificada", "-");
      dataPut.set("disciplinaTurmaCod", data?.t?.disciplinaTurmaCod);
      dataPut.set("alunoTurmaCod", data?.t?.alunoTurmaCod);
      data?.tudo?.professorCod && dataPut.set("professorCod", data?.tudo?.professorCod);
      dataPut.set("data", dataDia);
    }

    ShowConfirmacao(
      async () => {
        let responseUpdate;
        let responseCreate;

        if (chamada.length > 0) {
          responseUpdate = await api.post(
            "/professor/chamada/alterar",
            dataPut
          );
        }
        if (chamada.length == 0) {
          responseCreate = await api.post(
            "/professor/chamada",
            dataPut
          );
        }
        options.reloadData();
        endSubmit = true;
      },
      {
        errorModal: {defaultMessage: "Não foi possível atualizar a matrícula dessa turma, por favor, tente novamente mais tarde."},
        successModal: false,
        message: "Tem certeza que deseja atualizar a nota desse aluno?",
      }
    );
  }

  function onSubmit(closeModal){
    document.querySelector("#BTN_SUBMIT").click();
    let timer = setInterval(() => {
      if(endSubmit){
        closeModal()
        clearInterval(timer);
      }
    }, 1000)
  }
    //console.log(data?.tudo)
  return (
    <SistemaFixedModal
      title="Fazer Chamada"
      style={{ width: "50em", maxWidth: "100%" }}
      {...{ outClick }}
      footerButtons={{submitButton: {onClick: onSubmit}}}
    >
      <SLForm id="FORM_EDIT_NOTAS" isFormData onSubmit={handleSubmit}>
        <Materialize>
          <SubTitle>Aluno</SubTitle>
          <div className="row">
            <Label
              title="NOME:"
              text={data?.t?.alunoTurma?.aluno.usuario.nome || ""}
              className="col s12 m12 l6"
            />
          </div>
          <SubTitle>Presença</SubTitle>
          <div className="row">
            <Label
              title={'Status'}
              className="col s12 m12 l3"
            >
              <SistemaSelect name={`tipo_chamada`} value={status} onChange={(thiss) => {setStatus(thiss.target.value);}}>
                <option value={'P'}>Presente</option>
                <option value={'F'}>Falta</option>
                <option value={'FJ'}>Falta Justificada</option>
              </SistemaSelect>
              {/* <SistemaInput name={`${alunosNotas[index]?.cod || 'novo-' + index}`} defaultValue={`${alunosNotas[index]?.notaAvaliacao  || 'nulo'}`} required />   */}
            </Label>
          </div>
          {status == 'FJ' && (
            <>
            <SubTitle>Falta Justificada</SubTitle>
            <div className="row">
              <Label
                title={'Descrição'}
                className="col s12 m12 l6"
              >
                <SistemaInput name={`descricao_faltajustificada`} defaultValue={chamada[0]?.descricao ? chamada[0]?.descricao : ``} required />  
              </Label>
              <Label
                title={'Arquivo'}
                className="col s12 m12 l6"
              >
                <ColoredFileInput name="files" defaultFile={chamada.length > 0 ? {
                  cod: chamada[0]['arquivos.cod'],
                  createdAtDate: chamada[0]['arquivos.createdAtDate'],
                  createdAtHour: chamada[0]['arquivos.createdAtHour'],
                  createdByUserCod: chamada[0]['arquivos.createdByUserCod'],
                  deletedAtDate: chamada[0]['arquivos.deletedAtDate'],
                  ext: chamada[0]['arquivos.ext'],
                  filename: chamada[0]['arquivos.filename'],
                  originalname: chamada[0]['arquivos.originalname'],
                  status: chamada[0]['arquivos.status'],
                } : {}} formFile={setFiles} />
              </Label>
            </div>
            </>
          )}
          <input type="submit" id="BTN_SUBMIT" style={{opacity: 0}} />
        </Materialize>
        {/* <SLModalFooter cancelButton={false}  /> */}
      </SLForm>
    </SistemaFixedModal>
  );
}

export default ADDNotaAlunoTurmaModalEdit;
