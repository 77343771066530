import React, { useState } from "react";

import api from "~/services/api";
import CreateEditContent from "./CreateEditContent";
import ShowContent from "./ShowContent";
import {
  SLCreateModal,
  SLShowModal,
  SLEditModal,
} from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import TableComponent from "./TableComponent";

function VersaoApp() {
  const [mountedData, setMountedData] = useState();
  const [tabFilter, setTabFilter] = useState({ ativo: 1 });

  return (
    <Container
      requests={{
        findAll: () => api.get("admin/versoesapp"),
        // deleteOne: (thisData) => api.delete("admin/versaoapp", { data: { cod: thisData.cod } }),
        findOne: (thisData) =>
          api.get("admin/versaoapp", { params: { cod: thisData.cod } }),
      }}
      dataFilter={tabFilter}
    >
      <TableComponent {...{ setTabFilter }} />

      <SLShowModal title="Versão do App" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLEditModal title="Atualizar Versão do App" setter={setMountedData}>
        <CreateEditContent {...{ mountedData }} />
      </SLEditModal>

      <SLCreateModal title="Nova Versão do App">
        <CreateEditContent />
      </SLCreateModal>
    </Container>
  );
}

export default VersaoApp;
