import React, { useState, useLayoutEffect } from "react";

import api from "~/services/api";
import CreateEditContent from "./CreateEditContent";
import ShowContent from "./ShowContent";
import {
  SLShowModal,
  SLEditModal,
} from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import TableComponent from "./TableComponent";

function Menus() {
  const [mountedData, setMountedData] = useState();
  const [tabFilter, setTabFilter] = useState({ status: 1 });
  const [modulos, setModulos] = useState([]);

  useLayoutEffect(() => {
    api
      .get("admin/modulos")
      .then((response) => response?.data && setModulos(response.data));
  }, []);

  return (
    <Container
      requests={{
        findAll: () => api.get("admin/menus", { params: { paranoid: false } }),
        deleteOne: (thisData) =>
          api.delete("admin/menu", { data: { cod: thisData.cod } }),
        restoreOne: (thisData) =>
          api.post("admin/menu/restaura", { data: { cod: thisData.cod } }),
        findOne: (thisData) =>
          api.get("admin/menu", { params: { cod: thisData.cod } }),
      }}
      dataFilter={tabFilter}
      inputs={[{ filter: "nome", label: "Nome" }]}
      selects={[
        {
          filter: "moduloCod",
          list: modulos,
          outLabel: "Modulo",
          value: "cod",
          label: "nome",
        },
        {
          filter: "modulo.tipo",
          list: [
            { nome: "admin" },
            { nome: "filial" },
            { nome: "professor" },
            { nome: "padrao" },
          ],
          outLabel: "Visão",
          value: "nome",
        },
      ]}
    >
      <TableComponent {...{ setTabFilter }} />

      <SLShowModal title="Menu" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLEditModal title="Atualizar Menu" setter={setMountedData}>
        <CreateEditContent {...{ mountedData, modulos }} />
      </SLEditModal>

      {/* <SLCreateModal title="Novo Menu">
        <CreateEditContent {...{ modulos }} />
      </SLCreateModal> */}
    </Container>
  );
}

export default Menus;
