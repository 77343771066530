import styled from "styled-components";

export const Container = styled.div`
  width: 90vw;
  margin: auto;

  .componentBox {
    width: 100%;
    background: #fff;
    padding: 3em 0 3em;
    border-radius: 2em;
  }

  .botaoAno {
    display: flex;
    justify-content: space-between;
    height: 0.2em;
    background: #1e505c;
    width: 25em;
    max-width: 95%;
    margin: auto;

    button {
      margin-top: -1.5em;
      background: #102f37;
      height: 3em;
      width: 3em;
      border-radius: 50%;
      border: none;
      color: #fff;
      z-index: 1;
    }

    .botaoAnoSelected {
      background: #1ca9d0;
      margin-top: -2em;
      width: 4em;
      height: 4em;
    }
  }
`;

export const ComponentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1em;

  h1,
  .inputButtonDiv,
  .filterButtonDiv {
    width: auto;
  }

  h1,
  .toggleButton {
    color: #fff;
  }

  h1 {
    font-size: 1.5em;
  }

  .upperContentButtonDivs {
    display: flex;
    justify-content: space-between;
    width: 6em;

    .toggleButton {
      background: #4ea654;
      border: none;
      height: 1.5em;
      width: 1.5em;
      font-size: 1.4em;
      border-radius: 0.2em;

      :hover {
        box-shadow: 0 0 1em #6666;
      }
    }

    .inputButtonDiv {
      display: flex;
      width: fit-content;
      align-content: center;
      position: relative;
      .searchInput {
        position: absolute;
        right: 3em;
        padding: 0.5em;
        border: none;
        box-shadow: 0 0 1em 0.1em #6666;
        background: #fff;
        color: #333;
        height: 2em;
        border-radius: 0.4em;
      }
    }

    .filterButtonDiv {
      position: relative;
      .filterList {
        position: absolute;
        top: 100%;
        left: -10em;
        width: 13em;
        padding: 0.5em 1em 1em;
        background: #fff;
        border-radius: 0.4em;
        z-index: 1;
        border: 0.1em solid #bbb;

        button::before {
          content: "";
          background: #1ca9d0;
          display: inline-block;
          border-radius: 50%;
          width: 0.45em;
          height: 0.45em;
          margin-left: 0;
          margin-right: 0.5em;
        }

        button {
          width: 100%;
          font-weight: 550;
          font-size: 0.9em;
          background: none;
          border: none;
          text-align: left;
          padding-top: 0.5em;
        }
      }
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  position: relative;
  ${(props) => {
    if (props.desktopRotatedTablet) {
      return `
          display: grid;
          grid-template-columns: auto 0.2em auto;
          justify-content: space-between;
        `;
    } else {
      return `
          display: flex;
          flex-direction: column;
        `;
    }
  }}

  .box {
    display: flex;
    flex-direction: column;
    ${(props) => {
      if (props.desktop) {
        return "padding: 0 8em 5em 3em; max-width: 100%;";
      }
      if (props.rotatedTablet) {
        return "padding: 0 2em 5em 1em; max-width: 100%;";
      }
    }}

    ${(props) =>
      !props.desktopRotatedTablet &&
      `margin: auto; max-width: 90%;`}

    > :not(:last-of-type) {
      margin-bottom: ${(props) => props.desktopRotatedTablet && `8em`};
    }

    .boxContent {
      ${(props) => props.desktopRotatedTablet && `border-top: 0.2em solid #1ca9d0;`}
      box-shadow: 0.5em 0.5em 0.5em #6666;
      border-radius: ${(props) => (props.desktopRotatedTablet ? `0 0` : `1em 1em`)} 2em
        2em;
      position: relative;
      ${(props) => !props.desktopRotatedTablet && `margin-bottom: 5em;`}

      .dataBalao {
        position: absolute;
        display: flex;
        top: -2em;
        flex-direction: column;
        justify-content: center;
        border-radius: 50%;
        text-align: center;
        width: 3em;
        height: 3em;
        background: #1ca9d0;
        color: #fff;
        right: ${(props) => {
          if (props.desktop) {
            return `-9.6em`;
          }
          if (props.rotatedTablet) {
            return "-3.6em";
          }
        }};

        .mes {
          font-size: 0.6em;
        }
      }

      .rightBalao {
        left: ${(props) => {
          if (props.desktop) {
            return `-9.6em`;
          }
          if (props.rotatedTablet) {
            return "-3.6em";
          }
        }};
      }

      .boxContentImage {
        width: 100%;
        height: 13em;
        border-radius: ${(props) => (props.desktopRotatedTablet ? `0 0` : `1em 1em`)} 0 0;
        position: relative;

        img {
          position: absolute;
          width: 100%;
          height: auto;
          object-fit: contain;
          object-position: top 0;
        }
      }

      .PageBlogBoxInfo {
        position: relative;
        margin-top: -15%;
        padding: 0.5em 1em 1em;
        background: #ffffff;
        border-radius: 2em;

        .boxTitulo {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .boxTituloAutor {
          height: 5em;
        }

        .boxTags {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 0.5em;

          > div {
            background: #ffda44;
            border-radius: 0.5em;
            font-weight: 550;
            font-size: #000;
            font-size: 0.6em;
            padding: 0.5em;
          }
        }

        .boxAutoria {
          font-size: 0.6em;
          font-weight: 550;
          color: #555;

          span {
            color: #1ca9d0;
          }
        }

        .boxText {
          font-weight: 550;
          font-size: 0.9em;
          margin-bottom: 0.5em;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .botao {
          margin-left: auto;
          button {
            height: 2em;
            width: 7em;
            font-size: 0.9em;
          }
        }
      }
    }
  }

  .rightBox {
    ${(props) => {
      if (props.desktop) {
        return `padding: 5em 3em 5em 8em; max-width: 100%`;
      }
      if (props.rotatedTablet) {
        return "padding: 5em 1em 5em 2em; max-width: 100%;";
      }
    }}
  }

  .divisao {
    background: #1e505c;
  }
`;
