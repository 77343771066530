import styled from "@react-pdf/styled-components";

export const Content = styled.Page`
  padding: 5%;
  color: #333;
  font-weight: normal;
  font-size: 14pt;
`;

export const Card = styled.View`
  border-bottom: 1pt solid #aaa;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 12pt;
  padding: 2pt;
`;

export const TwoColumnCell = styled.Text`
  width: 50%;
`;

export const ColumnCell = styled.Text`
  width: 25%;
`;

export const Paragraph = styled.Text`
  margin-top: 1%;
  margin-bottom: 1%;
  display: block;
  width: 100%;
`;

export const Title = styled.Text`
  color: #000;
  font-weight: bolder;
  text-align: center;
  font-size: 20pt;
  margin-top: 3%;
  margin-bottom: 3%;
`;

export const Strong = styled.Text`
  color: #000;
  display: inline;
`;
