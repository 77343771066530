import React from "react";

/**
 * @param {Object} props
 * @param {string} props.name Informe a chave para retornar o valor, baseado no objeto da tabela.
 * @param {string} props.search Informe um nome para identificar o input que será utilizado como busca.
 * @param {Object} props.filter Informe as configurações de filtro.
 * @param {Object} props.filter.list Informe a lista que será utilizada para popular um dropDown, aceita array, string com a url de um request, ou uma função.
 * @param {Object} props.filter.value Informe a chave que deve ser obtido o valor para a option
 * @param {Object} props.filter.label Informe a chave que deve ser obtido o label da option.
 * @param {Object} props.filter.outLabel Informe um nome para mostrar como label do dropdown.
 */
function CustomListTableCell(props) {
  return <td {...props} />;
}

export default CustomListTableCell;
