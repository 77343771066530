import React, { useState, useEffect } from "react";
import { Form } from "~/componentes/CustomForm";
import customListStore from "../../customListStore";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import { IsFunction } from "~/utils/CheckDataType";
import { CLModalButtons } from "..";
import CustomListReloadList from "../../utils/CustomListReloadList";

/**
 * @param {Object} props
 * @param {Function} props.contentMount Aceita uma função que será chamada quando o componente for montado.
 * @param {Function} props.contentUnmount Aceita uma função que será chamada quando o componente for desmontado.
 * @param {Function} props.onSubmit Recebe como primeiro argumento o dado do form e segundo uma função que irá recarregar os dados da lista.
 * @param {Function} props.defaultStyle Adiciona os botões padrões e o scrollBar.
 * @param {Function} props.defaultButtons Adiciona os botões padrões do modal de Form.
 */
function StyledCustomListForm({
  contentMount,
  contentUnmount,
  children,
  onSubmit,
  defaultStyle,
  defaultButtons = defaultStyle,
  ...rest
}) {
  const { state, dispatch } = React.useContext(customListStore);
  const { requests, sortOnEdit } = state || {};
  const { findAll } = requests || {};
  const [mount] = useState(() => contentMount);
  const [unmount] = useState(() => contentUnmount);

  useEffect(() => {
    IsFunction(mount, "Function") && mount();
  }, [mount]);

  useEffect(() => {
    return () => {
      IsFunction(unmount, "Function") && unmount();
    };
  }, [unmount]);

  async function handleSubmit(data) {
    return onSubmit(data, () => CustomListReloadList({ findAll, sortOnEdit, dispatch }));
  }

  return (
    <Form onSubmit={onSubmit && handleSubmit} {...rest}>
      {defaultStyle ? (
        <CustomScrollBar fixScrollMargin autoHeightMax={"65vh"}>
          {children}
        </CustomScrollBar>
      ) : (
        children
      )}
      {defaultButtons && <CLModalButtons {...defaultButtons} />}
    </Form>
  );
}

export default StyledCustomListForm;
