import React from "react";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import { ItemLabel } from "./styles";

// AJUSTAR: performance do textArea do item.

function Item({ title, data, handleData, handleRemoveItem, ...rest }) {
  return (
    <ItemLabel
      className="col s12 m12 l6"
      title={`${title}: ${data?.gabarito === true ? "Verdadeiro" : "Falso"}`}
      {...rest}
    >
      <div className="Questoes_Item_enunciado">
        <SistemaTextArea
          value={data?.enunciado}
          onChange={(e) => {
            handleData({ ...data, enunciado: e.target.value });
          }}
          placeholder="Descrição do item"
          readOnly
        />
      </div>
    </ItemLabel>
  );
}

export default Item;
