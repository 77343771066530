import React from "react";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";

import CustomScrollBar from "~/componentes/CustomScrollBar";
import { Materialize } from "~/styles/materializeGrid";
import { Container } from "./styles";
import { SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import api from "~/services/api";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";

function CreateEditContent({ mountedData, condicaoPagamento }) {
  function handleSubmit(data, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let response;

        if (mountedData) {
          response = await api.put("admin/tabelaprecoaluno", {
            ...data,
            cod: mountedData.cod,
          });
        } else {
          response = await api.post("admin/tabelaprecoaluno", data);
        }

        if (response?.status === 200) {
          closeReload && closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao ${mountedData ? "atualizar" : "criar"} essa tabela de preço, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja ${mountedData ? "atualizar" : "criar"} essa tabela de preço?`,
        successModal: `Tabela de preço ${mountedData ? "atualizada" : "cadastrada"} com sucesso!`,
      }
    );
  }

  return (
    <Container onSubmit={handleSubmit}>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          <div className="row">
            <Label title="NOME: *" className="col s12 m12 l12">
              <SistemaInput name="nome" defaultValue={mountedData?.nome} required />
            </Label>
            <Label title="FAIXA INICIAL: *" className="col s12 m12 l6">
              <SistemaInput type="number" name="faixaInicial" defaultValue={mountedData?.faixaInicial} required />
            </Label>
            <Label title="FAIXA FINAL: *" className="col s12 m12 l6">
              <SistemaInput type="number" name="faixaFinal" defaultValue={mountedData?.faixaFinal} required />
            </Label>
            <Label title="VALOR: *" className="col s12 m12 l6">
              <SistemaInput type="number" step="0.01" name="valorPorAluno" defaultValue={mountedData?.valorPorAluno} required />
            </Label>
            <Label title="CONDIÇÃO DE PAGAMENTO: *" className="col s12 m12 l6">
              <SistemaSelect
                name="condicaoPagamentoCod"
                firstOption
                list={{ list: condicaoPagamento, value: "cod", label: "nome" }}
                defaultValue={mountedData?.condicaoPagamentoCod}
                required
              />
            </Label>
          </div>
        </Materialize>
      </CustomScrollBar>
      <SLModalFooter />
    </Container>
  );
}

export default CreateEditContent;
