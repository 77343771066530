import { IsObject, IsBoolean } from "./CheckDataType";

function PassByValue(data) {
  if (IsObject(data)) {
    return { ...data };
  } else if (Array.isArray(data)) {
    return [...data];
  } else if (IsBoolean(data)) {
    return !!data;
  } else if (data === undefined) {
    return undefined;
  } else if (data === null) {
    return null;
  } else {
    return JSON.parse(JSON.stringify(data));
  }
}

export default PassByValue;
