import React from "react";
import { Button } from "~/componentes/CustomForm";
import { useSelector } from "react-redux";
import customListStore, { customListToggleModal } from "../../customListStore";

import { Container } from "./styles";

function StyledCustomListModalHeader({ children, cancelButton, ...rest }) {
  const { state, dispatch } = React.useContext(customListStore);
  const { closeModal, onClick: cancelClick } = cancelButton || {};
  const {
    confirmacaoModal,
    errorModal: checkErrorModal,
    successModal: checkSuccessModal,
  } = useSelector((state) => state) || {};

  return (
    <Container {...rest}>
      <h3>{children}</h3>
      <Button
        className="material-icons CustomTable_Modal_Button_fechar"
        onClick={(e) => {
          if (
            (closeModal || (!cancelClick && closeModal !== false)) &&
            state.modal &&
            !confirmacaoModal?.ativo &&
            !checkErrorModal?.ativo &&
            !checkSuccessModal?.ativo
          ) {
            dispatch(customListToggleModal(false));
          }
          cancelClick && cancelClick(e);
        }}
      >
        close
      </Button>
    </Container>
  );
}

export default StyledCustomListModalHeader;
