import styled from "styled-components";
import { SLShowButton } from "~/pages/Sistema/componentes/SistemaList";

export const Container = styled.div`
  overflow-x: hidden;
`;

export const ThisCard = styled(SLShowButton).attrs((props) => ({
  ...props,
  asDiv: true,
}))`
  padding: 0 0 0.625em 0;
  margin-bottom: 0.75em;
  border-bottom: solid #ebebeb 0.125em;
  cursor: pointer;
  transition: 0.2s ease;

  &:active {
    transform: scale3d(0.98, 0.98, 0.98);
  }
`;

export const ThisCardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 0.8em;
    line-height: 1.375em;
    color: #1ca9d0;

    i {
      margin-left: 0.25em;
      color: #66707c;
    }
  }

  span {
    color: #666;
    font-weight: 600;
    font-size: 0.8em;
    line-height: 1.375em;
    text-transform: uppercase;
  }
`;

export const ThisCardBody = styled.div`
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.125em;
    color: #293f57;
  }

  .ThisCardBody_title_tur_disc_div {
    margin-bottom: 0.5em;
  }

  .ThisCardbody_turma_disciplina {
    width: 100%;
    font-weight: 600;
    font-size: 0.8em;
    color: #a6a6a6;
    margin-top: 0.1em;
  }

  .ThisCardBody_TextContent_pre {
    width: 100%;
    font-weight: 600;
    font-size: 0.875em;
    color: #66707c;
  }
`;
