import styled, { keyframes } from "styled-components";
import { Button } from "~/componentes/CustomForm";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";

const startNavbar = keyframes`
  100% {
    top: 0;
  }
`;

export const Container = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  top: -4.5em;
  padding: 0.5em 1em;
  background-color: #fff;
  display: flex;
  align-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  border-bottom-left-radius: 0.8em;
  border-bottom-right-radius: 0.8em;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  z-index: 500;
  animation: ${startNavbar} 0.3s ease forwards;
  transition: 0.25s ease;

  .toggleLogoDiv {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-between;
  }

  .toggleMenu {
    border: none;
    background: none;
    outline: none;
  }
`;

export const LogoButton = styled(Button)`
  img {
    height: 2.5em;
    margin-top: 0.5em;
  }
`;

export const DefaultButtonsDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoggedInButtonsDiv = styled.div`
  width: 100%;
  margin-bottom: 0.5em;

  button {
    margin-top: 0.5em;
    border: none;
    background: none;
    outline: 0;
    cursor: pointer;
    display: block;
    font-size: 1.1em;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 0.05em solid #c0c0c0;
    transition: 300ms;

    .PerfilModal_Button_icon {
      margin-right: 0.5em;
    }
  }
`;

export const ThisColoredButton = styled(ColoredButton)`
  margin: 0.5em;
  padding: 0.25em 0.5em;
`;

export const Perfil = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em;
  align-self: center;

  .letterButton {
    border: none;
    line-height: 1;
    background: #364859;
    font-size: 1.5em;
    height: 1.5em;
    max-height: 1.5em;
    width: 1.5em;
    max-width: 1.5em;
    border-radius: 50%;
    color: #fff;
    transition: 300ms;
  }
`;
