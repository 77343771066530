/* eslint-disable eqeqeq */
import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { Container } from "./styles";
import { Materialize } from "~/styles/materializeGrid";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import api from "~/services/api";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import ColoredFileInput from "~/componentes/CustomForm/FileInput/styles/ColoredFileInput";
import AulasDisciplinaTurmaTable from "../AulasDisciplinaTurmaTable";
import AulaLinks from "../AulaLinks";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import getYoutubeEmbebedUrl from "~/utils/getYoutubeEmbebedUrl";
import ShowError from "~/utils/ShowError";

function EditContent({ periodosLetivos, mountedData }) {
  // const [aulasDisciplinasTurmas, setAulasDisciplinasTurmas] = React.useState([]);
  const [removeArquivos, setRemoveArquivos] = React.useState([]);
  const [links, setLinks] = React.useState([]);

  // React.useEffect(() => {
  //   if (Array.isArray(mountedData?.aulasDisciplinasTurmas)) {
  //     setAulasDisciplinasTurmas((prev) => (prev.length > 0 ? prev : [...mountedData.aulasDisciplinasTurmas]));
  //   }
  //   if (Array.isArray(mountedData?.links)) {
  //     setLinks((prev) => (prev.length > 0 ? prev : [...mountedData.links]));
  //   }
  // }, [mountedData]);

  async function handleSubmit(data, options) {
    const { closeReload } = options || {};

    function addData(key, val) {
      if (Array.isArray(val) && val.length > 0);
      try {
        const thisData = JSON.stringify(val);
        data.set(key, thisData);
      } catch {}
    }

    data.set("cod", mountedData?.cod);

    let invalid = false;
    const thisLinks = links?.filter((e) => {
      if (typeof e?.link === "string" && e.link.length > 0) {
        const linkId = getYoutubeEmbebedUrl(e?.link).videoId;

        if (!linkId) {
          invalid = true;
        }
        return !!linkId;
      }
      return false;
    });

    if (invalid) {
      return ShowError("Link do video inválido.");
    } else {
      addData("links", thisLinks);
      // addData("aulasDisciplinasTurmas", aulasDisciplinasTurmas);
      addData("removeArquivos", removeArquivos);

      ShowConfirmacao(
        async () =>
          await api.put("professor/gestaoDisciplinas/aula", data).then((r) => {
            if (r?.status === 200 && typeof closeReload === "function") {
              closeReload();
            }
            return r;
          }),
        {
          successModal: "Aula atualizada com sucesso",
          errorModal: {
            defaultMessage: "Falha ao atualizar a aula, por favor tente novamente.",
          },
          message: "Tem certeza que deseja atualizar essa aula?",
        }
      );
    }
  }

  return (
    <Container isFormData defaultStyle onSubmit={handleSubmit}>
      <Materialize>
        <div className="row">
          <Label title="Título: *" className="col s12 m12 l12">
            <SistemaInput readonly={mountedData?.disponibilizado} defaultValue={mountedData?.titulo} required name="titulo" />
          </Label>
          <Label title="Descrição: *" className="col s12 m12 l12">
            <SistemaTextArea readonly={mountedData?.disponibilizado} defaultValue={mountedData?.descricao} required name="descricao" />
          </Label>

          {mountedData?.disponibilizado && (
            <Label title="Aula reutilizável: *" className="col s12 m12 l12">
              <SistemaSelect
                required
                defaultValue={mountedData?.reutilizavel === "true" || mountedData?.reutilizavel === true ? "true" : "false"}
                name="reutilizavel"
              >
                <option value="false">Não</option>
                <option value="true">Sim</option>
              </SistemaSelect>

              <p style={{ marginBottom: "0.5em", fontSize: "0.9em", color: "#444" }}>
                Aulas reutilizáveis são aulas que podem ser reaproveitas para diversas turmas em diversos períodos. Aulas não reutilizável, não
                poderão ser reutilizáveis. Essa opção pode ser revertida.
              </p>
            </Label>
          )}

          <AulaLinks {...{ readonly: mountedData?.disponibilizado, links, setLinks }} />

          <AulasDisciplinaTurmaTable
            {...{
              aulasDisciplinasTurmas: mountedData?.aulasDisciplinasTurmas,
              // periodosLetivos, setAulasDisciplinasTurmas, readonly: mountedData?.disponibilizado
            }}
          />

          <Label title="Arquivos" className="col s12 m12 12" />
          {mountedData?.disponibilizado ? (
            mountedData?.arquivos?.map((arq, i) => (
              <div key={arq?.cod || i} className="Label_arquivos_file_input col s12 m12 l6">
                <ColoredFileInput readOnly defaultFile={arq} />
              </div>
            ))
          ) : (
            <>
              <div className="col s12 m12 l6">
                <ColoredFileInput
                  onDefaultFileRemove={(e) => e?.cod && setRemoveArquivos((prev) => [...prev, e?.cod])}
                  defaultFile={mountedData?.arquivos?.[0]}
                  name="file0"
                />
              </div>
              <div className="col s12 m12 l6">
                <ColoredFileInput
                  onDefaultFileRemove={(e) => e?.cod && setRemoveArquivos((prev) => [...prev, e?.cod])}
                  defaultFile={mountedData?.arquivos?.[1]}
                  name="file1"
                />
              </div>
              <div className="col s12 m12 l6">
                <ColoredFileInput
                  onDefaultFileRemove={(e) => e?.cod && setRemoveArquivos((prev) => [...prev, e?.cod])}
                  defaultFile={mountedData?.arquivos?.[2]}
                  name="file2"
                />
              </div>
              <div className="col s12 m12 l6">
                <ColoredFileInput
                  onDefaultFileRemove={(e) => e?.cod && setRemoveArquivos((prev) => [...prev, e?.cod])}
                  defaultFile={mountedData?.arquivos?.[3]}
                  name="file3"
                />
              </div>
            </>
          )}
        </div>
      </Materialize>
    </Container>
  );
}

export default EditContent;
