import React from "react";
import ColoredFileInput from "~/componentes/CustomForm/FileInput/styles/ColoredFileInput";

// import { Container } from './styles';

function ThisFileInput({ setFiles, blueLayout, grupo, props }) {
  const { name, value, defaultValue, notForm, ...rest } = props || {};

  return (
    <ColoredFileInput
      {...rest}
      blueLayout={blueLayout}
      defaultFile={defaultValue}
      name={`${grupo?.chave}_${name}`}
      formDataSetter={setFiles}
    />
  );
}

export default ThisFileInput;
