import styled from "styled-components";

export const Container = styled.div`
  border-top: solid 0.1em #f5f5f5;
  display: flex;
  justify-content: flex-end;

  button {
    margin: 0.5em 0 0.5em 1em;
  }
`;
