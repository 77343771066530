import React, { useState } from "react";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";
import SistemaTable from "~/componentes/StyledComponents/Tables/SistemaTable";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import Label from "~/componentes/CustomForm/Label";
import IconButton from "~/componentes/CustomForm/Button/Styles/IconButton";
import Pagination from "~/componentes/Pagination";
import { useEffect } from "react";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import api from "~/services/api";

import { ThisAddDisciplinaTurma } from "./styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import ShowError from "~/utils/ShowError";
import { Materialize } from "~/styles/materializeGrid";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import { SLForm, SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import { SubTitle } from "../../ShowContent/styles";

function ADDAtividades({ data, outClick }) {
  let endSubmit = false;
 async function mudarMedia(dataPut, options){
  ShowConfirmacao(
    async () => {
      let response = await api.post(
        "professor/turma/alunos/quantidadeAvaliacoes",
        {cod: data.cod, quantidadeAvaliacoes: await document.getElementById('quantidadeAvaliacoes').value}
      );
      options.reloadData();
      endSubmit = true;
      return response
    },
    {
      errorModal: {defaultMessage: "Não foi possível atualizar a média devido a um erro no sistema"},
      successModal: false,
      message: "Tem certeza que deseja atualizar a média manualmente?",
    }
  );
 }
 function onSubmit(closeModal){
  document.querySelector("#BTN_SUBMIT").click();
  let timer = setInterval(() => {
    if(endSubmit){
      closeModal()
      clearInterval(timer);
    }
  }, 1000)
}
  return (
    <SistemaFixedModal
      title="Adicionar Notas"
      style={{ width: "30em", maxWidth: "100%" }}
      {...{ outClick }}
      footerButtons={{submitButton: {onClick: onSubmit}}}
    >
      <SLForm onSubmit={mudarMedia}>
        <Materialize>
          <SubTitle>Número de atividades</SubTitle>
          <Label
            title={'Mudar numero de atividades bimestrais para alunos'}
            className="col s12 m12 l12"
          >
            <SistemaInput id={'quantidadeAvaliacoes'} name={'quantidadeAvaliacoes'} defaultValue={data.quantidadeAvaliacoes} required />  
          </Label>
          <input type="submit" id="BTN_SUBMIT" style={{opacity: 0}} />
        </Materialize>
        {/* <SLModalFooter cancelButton={false} SubTitle={'Confirmar'}>
        </SLModalFooter> */}
      </SLForm>
    </SistemaFixedModal>
  );
}

export default ADDAtividades;
