import React, { useState } from "react";

import { Container, ComponentHeader, Content } from "./styles";
import escritorio from "~/assets/wallpapers/maos_escritorio.png";
import armazem from "~/assets/wallpapers/homem_armazem.png";
import UserDevice from "~/componentes/UserDevice";
import Modal from "~/componentes/Modal";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";

export default function Blog() {
  const [toggleInputSearch, setToggleInputSearch] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const userDevice = UserDevice();
  const { desktop, rotatedTablet } = userDevice;

  const cardSite = (dia, mes, mesNome, ano) => (
    <div className="boxContent">
      {(desktop || rotatedTablet) && (
        <div className="dataBalao leftBalao">
          <p className="dia">{dia}</p>
          <p className="mes">{mesNome}</p>
        </div>
      )}
      <img className="boxContentImage" src={escritorio} alt="Escritório" />
      <div className="PageBlogBoxInfo">
        <div className="boxTags">
          <div>CASO DE SUCESSO</div>
        </div>
        <div className="boxTituloAutor">
          <strong className="boxTitulo">
            Como um site novo impactou em nossa presença na internet
          </strong>
          <p className="boxAutoria">
            POR <span>ISABELLE CHRISTINE</span> EM{" "}
            <span>
              {dia}/{mes}/{ano}
            </span>
          </p>
        </div>
        <p className="boxText">
          Você já deve ter ouvido falar o ditado ?em casa de ferreiro o espeto é de pau?
          pois é, Quer saber como reformulamos o nosso site e com isso conseguimos
          melhorar nossos resultados?
        </p>
        <ColoredButton className="botao" blueLayout>
          Leia Mais
        </ColoredButton>
      </div>
    </div>
  );

  const cardEstoque = (dia, mes, mesNome, ano) => (
    <div className="boxContent">
      {(desktop || rotatedTablet) && (
        <div className="dataBalao rightBalao">
          <p className="dia">{dia}</p>
          <p className="mes">{mesNome}</p>
        </div>
      )}
      <div className="boxContentImage">
        <img src={armazem} alt="Armazem" />
      </div>
      <div className="PageBlogBoxInfo">
        <div className="boxTags">
          <div>CASO DE SUCESSO</div>
        </div>
        <div className="boxTituloAutor">
          <strong className="boxTitulo">Gestão de estoque ? O Guia Completo</strong>
          <p className="boxAutoria">
            POR <span>ISABELLE CHRISTINE</span> EM{" "}
            <span>
              {dia}/{mes}/{ano}
            </span>
          </p>
        </div>
        <p className="boxText">
          Você já deve ter ouvido falar o ditado ?em casa de ferreiro o espeto é de pau?
          pois é, Quer saber como reformulamos o nosso site e com isso conseguimos
          melhorar nossos resultados?
        </p>
        <ColoredButton className="botao" blueLayout>
          Leia Mais
        </ColoredButton>
      </div>
    </div>
  );

  return (
    <Container desktopRotatedTablet={desktop || rotatedTablet}>
      <ComponentHeader desktopRotatedTablet={desktop || rotatedTablet}>
        <h1>Nosso Blog</h1>
        <div className="upperContentButtonDivs">
          <div className="inputButtonDiv">
            {toggleInputSearch && (
              <input
                className="searchInput"
                type="text"
                placeholder="Buscar por..."
              ></input>
            )}
            <button
              onClick={() => setToggleInputSearch(!toggleInputSearch)}
              className="material-icons toggleButton"
            >
              search
            </button>
          </div>
          <div className="filterButtonDiv">
            <button
              onClick={() => setShowModal(!showModal)}
              className="material-icons toggleButton"
            >
              filter_list
            </button>
            {showModal && (
              <Modal outClose outClick={setShowModal} className="filterList">
                <button>Fabrica de Software</button>
                <button>Estoque</button>
                <button>Infraestrutura</button>
                <button>Case de Sucesso</button>
                <button>Fabrica de Software</button>
                <button>Estoque</button>
                <button>Infraestrutura</button>
                <button>Case de Sucesso</button>
              </Modal>
            )}
          </div>
        </div>
      </ComponentHeader>
      <div className="componentBox">
        <Content
          desktop={desktop}
          rotatedTablet={rotatedTablet}
          desktopRotatedTablet={desktop || rotatedTablet}
        >
          <div className="box leftBox">
            {cardSite("11", "03", "MAR", "2020")}
            {cardSite("20", "02", "FEV", "2020")}
            {cardSite("07", "03", "JAN", "2020")}
          </div>
          {(desktop || rotatedTablet) && <div className="divisao" />}
          <div className="box rightBox">
            {cardEstoque("8", "03", "MAR", "2020")}
            {cardEstoque("18", "02", "FEV", "2020")}
            {cardEstoque("04", "03", "JAN", "2020")}
          </div>
        </Content>
        <div className="botaoAno">
          <button>2018</button>
          <button>2019</button>
          <button className="botaoAnoSelected">2020</button>
          <button>2021</button>
          <button>2022</button>
        </div>
      </div>
    </Container>
  );
}
