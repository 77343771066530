import { store } from "../store";
import { errorToggle } from "~/store/modules/errorModal/actions";

/**
 * @param {any} error Recebe uma string ou um error. Se string, será mostrado a mensagem
 *  informada, se for um erro, buscará por um padrão de erro do Youch, retornando a mensagem
 *  contida no primeiro erro que tenha como true a chave errorHandled.
 * @param {String} defaultMessage Substitui a mensagem padrão caso o objeto de erro não seja adequado.
 */
export default async function ShowError(error, defaultMessage) {
  const dispatch = store.dispatch;

  return dispatch(errorToggle(error, defaultMessage));
}
