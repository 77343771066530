import styled from "styled-components";
import { SLForm } from "~/pages/Sistema/componentes/SistemaList";

export const Container = styled(SLForm)`
  max-width: 100%;
  width: 70em;

  textarea {
    height: 15em;
  }

  .col.questoes_lista_item {
    padding: 0 !important;
  }

  .label_preview {
    height: 17.72em;

    img {
      max-width: 100%;
      height: auto;
      max-height: 15em;
      display: block;
      margin: auto;
      border-radius: 0.5em;
    }
  }
`;
