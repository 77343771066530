import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  height: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 10em;
  grid-template-areas: "card1 card2 card3 card4";
  grid-gap: 1em;

  .chart1 {
    grid-area: chart1;
  }
  .card1 {
    grid-area: card1;
  }
  .card2 {
    grid-area: card2;
  }
  .card3 {
    grid-area: card3;
  }
  .card4 {
    grid-area: card4;
  }

  .card-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card-header h2 {
    font-size: 1.3em;
    color: #293f57;
    margin-left: 0.2em;
    font-weight: 600;
  }

  .card-body {
    width: 100%;
    height: 54%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1ca9d0;
  }
  .card-body h1 {
    font-size: 2.8em;
    font-weight: 600;
  }
  .card-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: solid #f5f5f5 0.125em;
    padding: 0.4em 0;
  }
  .card-footer h6 {
    color: #9ab1b6;
    font-size: 0.8em;
    font-weight: 500;
  }

  @media only screen and (max-width: 993px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 10em 10em;
    grid-template-areas:
      "card1 card2"
      "card3 card4";
  }
  @media only screen and (max-width: 601px) {
    grid-template-columns: 1fr;
    grid-template-rows: 10em 10em 10em 10em;
    grid-template-areas:
      "card1"
      "card2"
      "card3"
      "card4";
  }
`;
