import React from "react";
import { QuestaoItensContainer } from "./styles";
import Item from "./Item";
import ChangeIndexOrder from "~/utils/ChangeIndexOrder";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import ShowConfirmacao from "~/utils/ShowConfirmacao";

// import { Container } from './styles';

function ItensDiv({ deleteRequest, initialItemData, itens, setItens }) {
  if (itens?.length === 0) {
    setItens((prev) => [...prev, initialItemData]);
  }
  async function handleRemoveItem(index) {
    function remove() {
      setItens((prev) => {
        let newState = [];
        prev.forEach((e, i) => {
          i !== index && newState.push(e);
        });
        return newState;
      });
    }

    if (deleteRequest) {
      await ShowConfirmacao(async () => {
        const response = await deleteRequest(itens[index]);
        remove();
        return response;
      });
    } else {
      remove();
    }
  }

  function handleAddItem() {
    if (itens?.length < 5) {
      setItens((prev) => [...prev, initialItemData]);
    }
  }

  return (
    <QuestaoItensContainer>
      {itens?.map((e, i) => (
        <Item
          key={i}
          title={`Item (${ChangeIndexOrder(i)})`}
          data={e}
          handleData={(data) => {
            setItens((prev) => {
              let newState = [...prev];
              newState[i] = data;
              return newState;
            });
          }}
          handleRemoveItem={() => handleRemoveItem(i)}
        />
      ))}
      <div className="col s12 m12 l12">
        <ColoredButton className="Questoes_itens_addItem" type="button" onClick={handleAddItem} small>
          <i className="material-icons">add_circle</i>
          ADICIONAR ITEM
        </ColoredButton>
      </div>
    </QuestaoItensContainer>
  );
}

export default ItensDiv;
