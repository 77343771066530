import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  .RespostaImagem_list_imageDiv {
    height: 6em;
    width: 8em;
    border: 0.1em solid #cedde0;
    border-radius: 0.2em;
    margin: 0.25em;
    cursor: zoom-in;

    img {
      transition: transform 0.25s ease;
      height: 100%;
      width: 100%;
    }

    .RespostaImagem_list_imageDiv_closeIcon {
      display: none;
    }
  }

  .zoom {
    z-index: 50;
    height: 100vh;
    width: 100vw;
    /* max-width: 80vw;
    max-height: 80vh; */
    border: none;
    margin: 0;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    cursor: zoom-out;

    img {
      height: initial;
      width: initial;
      max-width: 95vw;
      max-height: 95vh;

      -webkit-animation-name: zoom;
      -webkit-animation-duration: 0.6s;
      animation-name: zoom;
      animation-duration: 0.6s;

      @-webkit-keyframes zoom {
        from {
          -webkit-transform: scale(0);
        }
        to {
          -webkit-transform: scale(1);
        }
      }

      @keyframes zoom {
        from {
          transform: scale(0);
        }
        to {
          transform: scale(1);
        }
      }
    }

    .RespostaImagem_list_imageDiv_closeIcon {
      display: inline;
      position: absolute;
      top: 2em;
      right: 2em;
      color: #fff;
    }
  }
`;
