import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { Container } from "./styles";

function ShowContent({ mountedData }) {
  return (
    <Container>
      <Label title="NOME" text={mountedData?.nome} />
      <Label title="DISCIPLINA PADRÃO:" text={mountedData?.disciplina?.nome} />
    </Container>
  );
}

export default ShowContent;
