/* eslint-disable no-useless-computed-key */
import React, { useState, useLayoutEffect } from "react";

import api from "~/services/api";
import ShowContent from "./ShowContent";
import { SLShowModal } from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import TableComponent from "./TableComponent";

function AvaliacoesDisponibilizadas() {
  const [mountedData, setMountedData] = useState();
  const [tabFilter, setTabFilter] = useState({
    ["disciplinasFilial.ativo"]: true,
  });
  const [turmas, setTurmas] = useState([]);
  const [disciplinasFilial, setDisciplinasFilial] = useState([]);
  const [series, setSeries] = useState([]);
  const [reloadData, setReloadData] = useState(() => () => {});

  useLayoutEffect(() => {
    api
      .get("professor/gestaoTurmas/avaliacoesDisponibilizadas/filters")
      .then((r) => {
        let hSeries = [];
        let hTurmas = [];
        let hDiscip = {};

        if (Array.isArray(r?.data)) {
          r.data.forEach((s) => {
            const { turmas: sT, ...sRest } = s || {};
            hSeries.push(sRest);
            if (Array.isArray(sT)) {
              sT.forEach((t) => {
                const { disciplinasTurma: tDT, ...tRest } = t || {};
                hTurmas.push(tRest);
                if (Array.isArray(tDT)) {
                  tDT.forEach((dT) => {
                    dT?.disciplinaFilial?.cod &&
                      (hDiscip[dT.disciplinaFilial.cod] = dT.disciplinaFilial);
                  });
                }
              });
            }
          });
        }

        setSeries(hSeries);
        setTurmas(hTurmas);
        setDisciplinasFilial(Object.values(hDiscip));
      });
  }, []);

  return (
    <Container
      requests={{
        findAll: () => api.get("professor/avaliacoesDisponibilizadas"),
        findOne: (thisData) =>
          api.get("professor/avaliacaoDisponibilizada", {
            params: { cod: thisData.cod },
          }),
      }}
      reloadSetter={setReloadData}
      inputs={[{ filter: "avaliacao.nome", label: "Nome" }]}
      selects={[
        {
          filter: "disciplinaTurma.disciplinaFilialCod",
          list: disciplinasFilial,
          outLabel: "Disciplina",
          value: "cod",
          label: "nome",
        },
        {
          filter: "disciplinaTurma.turma.serieCod",
          list: series,
          outLabel: "Série",
          value: "cod",
          label: "nome",
        },
        {
          filter: "disciplinaTurma.turmaCod",
          list: turmas,
          outLabel: "Turma",
          value: "cod",
          label: "nome",
        },
      ]}
      dataFilter={tabFilter}
    >
      <TableComponent {...{ setTabFilter }} />

      <SLShowModal title="Avaliação" setter={setMountedData}>
        <ShowContent {...{ reloadData, mountedData }} />
      </SLShowModal>
    </Container>
  );
}

export default AvaliacoesDisponibilizadas;
