import React from "react";
import { ThisSistemaListaIconButonStyle } from "../styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import { useContext } from "react";
import sistemaListStore from "../../../ThisMainComponent/thisStore";

function ThisDeleteButton({ icon = "delete", data, onClick, ...rest }) {
  const { state } = useContext(sistemaListStore);
  const { requests, reloadData } = state || {};
  const { deleteOne } = requests || {};

  async function handleClick() {
    await ShowConfirmacao(async () => {
      const response = await deleteOne(data);

      if (response?.status === 200) {
        reloadData && reloadData();
      }

      return response;
    });

    onClick && onClick();
  }

  return (
    <ThisSistemaListaIconButonStyle
      moldalType="destroy"
      className={`material-icons`}
      onClick={handleClick}
      {...rest}
    >
      {icon}
    </ThisSistemaListaIconButonStyle>
  );
}

export default ThisDeleteButton;
