import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { Container, TriggerButton, Content } from "./styles";
import { signOut } from "~/store/modules/auth/actions";
import { Button } from "~/componentes/CustomForm";
import { BiLogOut } from "react-icons/bi";
import { IoIosHome } from "react-icons/io";

export default function LeaveSystem() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  return (
    <Container>
      <TriggerButton
        onClick={() => setShowModal(!showModal)}
        className="material-icons"
      >
        exit_to_app
      </TriggerButton>
      {showModal && (
        <Content outClose outClick={() => setShowModal(false)}>
          <Button link="/">
            <IoIosHome className="PerfilModal_Button_icon" />
            Tela principal
          </Button>
          <Button onClick={() => dispatch(signOut())}>
            <BiLogOut className="PerfilModal_Button_icon" />
            Finalizar Sessão
          </Button>
        </Content>
      )}
    </Container>
  );
}
