import styled from "styled-components";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";

export const Container = styled(SistemaFixedModal)`
  width: 50em;
  max-width: 100%;

  .Label_withButton {
    display: flex;
    align-items: center;

    button {
      width: fit-content;
      padding: 0.3em 0.7em;
      margin-bottom: 0.3em;
      margin-left: 0.25em;
    }
  }
`;
