import React, { useState } from "react";

import api from "~/services/api";
import CreateEditContent from "./CreateEditContent";
import ShowContent from "./ShowContent";
import { SLCreateModal, SLShowModal, SLEditModal } from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import TableComponent from "./TableComponent";

function Disciplinas() {
  const [mountedData, setMountedData] = useState();
  const [tabFilter, setTabFilter] = useState({ status: 1 });

  return (
    <Container
      requests={{
        findAll: () => api.get("admin/disciplinas", { params: { paranoid: false } }),
        deleteOne: (thisData) => api.delete("admin/disciplina", { data: { cod: thisData.cod } }),
        restoreOne: (thisData) => api.post("admin/disciplina/restaura", { data: { cod: thisData.cod } }),
        findOne: (thisData) => api.get("admin/disciplina", { params: { cod: thisData.cod } }),
      }}
      dataFilter={tabFilter}
    >
      <TableComponent {...{ setTabFilter }} />

      <SLShowModal title="Disciplina" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLEditModal title="Atualizar Disciplina" setter={setMountedData}>
        <CreateEditContent {...{ mountedData }} />
      </SLEditModal>

      <SLCreateModal title="Nova Disciplina">
        <CreateEditContent />
      </SLCreateModal>
    </Container>
  );
}

export default Disciplinas;
