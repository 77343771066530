import React from "react";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import RespostaAudio from "./RespostaAudio";
import RespostaDissertativa from "./RespostaDissertativa";
import RespostaImagem from "./RespostaImagem";
import RespostaObjetiva from "./RespostaObjetiva";

// import { Container } from './styles';

function RespostaQuestao({ handleChange, questao, respostaQuestoes, respostaQuestao }) {
  const Element = React.useMemo(() => {
    switch (questao?.questaoTipo?.chave) {
      case "DIS":
        return RespostaDissertativa;
      case "MUL":
        return RespostaObjetiva;
      case "AUD":
        return RespostaAudio;
      case "IMG":
        return RespostaImagem;
      default:
        return false;
    }
  }, [questao]);

  return Element ? (
    <>
      <Element {...{ handleChange, questao, respostaQuestoes, respostaQuestao }} />
      <Label className="col s12 m12 l12" title="Correção:">
        <SistemaTextArea
          value={respostaQuestoes?.[respostaQuestao?.cod]?.correcao || ""}
          onChange={(e) => {
            const thisVal = e.currentTarget.value;
            handleChange({ correcao: thisVal || "" });
          }}
        />
      </Label>

      <Label className="col s12 m12 l4" title="Marcar como:">
        <SistemaSelect
          firstOption={!(parseInt(respostaQuestao?.statusResposta) >= 0)}
          onChange={(e) => {
            let thisVal = e.currentTarget.value;
            thisVal = parseInt(thisVal);
            let result = { statusResposta: thisVal >= 0 ? thisVal : null };
            if (thisVal === 0) {
              result.pontuacao = 0;
            } else if (thisVal === 2) {
              result.pontuacao = respostaQuestao?.avaliacaoQuestao?.pontuacao;
            }

            handleChange(result);
          }}
          list={{
            list: [
              { v: "0", l: "Incorreto" },
              { v: "1", l: "Incompleto" },
              { v: "2", l: "Correto" },
            ],
            value: "v",
            label: "l",
          }}
          value={(() => {
            const thisVal = respostaQuestoes?.[respostaQuestao?.cod]?.statusResposta;

            if (parseInt(thisVal) >= 0) {
              return String(thisVal);
            }
            return "";
          })()}
        />
      </Label>

      <Label className="col s12 m6 l4" title="Pontuacao:">
        <SistemaInput
          value={respostaQuestoes?.[respostaQuestao?.cod]?.pontuacao}
          onChange={(e) => {
            let thisVal = e.currentTarget.value;
            thisVal = parseFloat(thisVal);
            const thisMax = respostaQuestao?.avaliacaoQuestao?.pontuacao;
            if (isNaN(thisVal) || thisVal < 0) {
              thisVal = 0;
            } else if (thisVal > thisMax) {
              thisVal = thisMax;
            }
            handleChange({ pontuacao: thisVal });
          }}
          max={respostaQuestao?.avaliacaoQuestao?.pontuacao}
          onFocus={(event) => event.target.select()}
          type="number"
          step="0.1"
          min="0"
        />
      </Label>
    </>
  ) : (
    <h4>"Questão não respondida"</h4>
  );
}

export default RespostaQuestao;
