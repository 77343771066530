import React from "react";
import { Document } from "@react-pdf/renderer";

import {
  Content,
  Strong,
  Title,
  Paragraph,
  Card,
  TwoColumnCell,
  ColumnCell,
} from "./styles";
import handleDate from "~/utils/handleDate";

function PdfDoc({ data, avaliacoesTurma }) {
  return (
    <Document>
      <Content>
        <Title>Relatório de Disciplina-Turma</Title>
        <Paragraph>
          <Strong>Disciplina:</Strong> {data?.disciplinaFilial?.nome}
        </Paragraph>
        <Paragraph>
          <Strong>Turma:</Strong> {data?.turma?.nome}
        </Paragraph>
        <Paragraph>
          <Strong>Série:</Strong> {data?.turma?.serie?.nome}
        </Paragraph>
        <Paragraph>
          <Strong>Período Letivo:</Strong> {data?.turma?.periodoLetivo?.nome}
        </Paragraph>
        <Paragraph>
          <Strong>Total de alunos:</Strong> {data?.totalAlunos}
        </Paragraph>
        <Title>Avaliacoes</Title>
        {avaliacoesTurma?.map?.((avt, i) => (
          <Card key={avt?.cod || i}>
            <TwoColumnCell>
              <Strong>Nome da Avaliação:</Strong> {avt?.avaliacao?.nome}
            </TwoColumnCell>
            <ColumnCell>
              <Strong>Corrigido:</Strong> {avt?.corrigido ? "Sim" : "Não"}
            </ColumnCell>
            <Paragraph>
              <Strong>Data da avaliação:</Strong>{" "}
              {handleDate(avt?.dataInicio).dmyhm} até{" "}
              {handleDate(avt?.dataFim).dmyhm}
            </Paragraph>
          </Card>
        ))}
      </Content>
    </Document>
  );
}

export default PdfDoc;
