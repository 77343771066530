import React, { useState } from "react";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";

import { Container } from "./styles";
import { useEffect } from "react";
import { useMemo } from "react";
import Label from "~/componentes/CustomForm/Label";

/**
 * @param {Object} props
 * @param {Object} props.reloadable Por padrão é false, as configurações de input carregam uma única vez, logo após perceber que o inputProps é um array com length > 0. Caso seja essa propriedade seja true, irá recarregar sempre que a referência  do input alterar, isso pode cuasar rerenderizações desnecessárias caso seja passado um array fora de um state.
 * @param {Object} props.inputProps demais props passadas para todos os inputs.
 * @param {Object[]} props.inputs
 * @param {string} props.inputs.filter Obrigatório, informa a chave de busca do filtro.
 * @param {string} props.inputs.label Obrigatório, informe o nome que deve aparecer no label ou no select.
 */
function FilterInputList({ inputs, reloadable, useLabel, inputProps, ...rest }) {
  const [selectInput, setSelectInput] = useState();
  const [inputValue, setInputValue] = useState("");
  const [loaded, setLoaded] = useState(false);

  const checkReload = useMemo(() => reloadable || !loaded, [loaded, reloadable]);

  useEffect(() => {
    if (checkReload && Array.isArray(inputs) && inputs.length > 0 && inputs[0].filter) {
      setLoaded(true);
      setSelectInput(inputs[0].filter);
    }
  }, [checkReload, inputs]);

  return (
    <Container className="CustomTable_Filter_Search" {...rest}>
      {selectInput && (
        <>
          <Label className="FilterInputList_Label_Select" title="SELECIONE">
            <SistemaSelect
              value={selectInput}
              onChange={(e) => {
                const thisValue = e.currentTarget.value;
                if (thisValue) {
                  setSelectInput(thisValue);
                }
                setInputValue("");
              }}
              list={{ list: inputs, value: "filter", label: "label" }}
            />
          </Label>
          <Label title="PESQUISAR">
            <SistemaInput
              type="text"
              value={inputValue}
              onChange={(e) => {
                const thisValue = e.currentTarget.value || "";
                setInputValue(thisValue);
              }}
              filter={selectInput}
              {...inputProps}
            />
          </Label>
        </>
      )}
    </Container>
  );
}

export default FilterInputList;
