import React, { useContext } from "react";

import { ThisSistemaListaIconButonStyle } from "../styles";
import sistemaListStore, {
  sistemaListShowModal,
} from "../../../ThisMainComponent/thisStore";
import { Button } from "~/componentes/CustomForm";

function ThisCloseButton({ closeCondition = true, className, ...rest }) {
  const { dispatch } = useContext(sistemaListStore);

  return (
    <ThisSistemaListaIconButonStyle
      style={{ color: "#293f57" }}
      as={Button}
      onClick={() => {
        closeCondition && dispatch(sistemaListShowModal(false));
      }}
      className={`material-icons ${className}`}
      {...rest}
    >
      close
    </ThisSistemaListaIconButonStyle>
  );
}

export default ThisCloseButton;
