import React, { useState } from "react";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";
import SistemaTable from "~/componentes/StyledComponents/Tables/SistemaTable";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import Label from "~/componentes/CustomForm/Label";
import IconButton from "~/componentes/CustomForm/Button/Styles/IconButton";
import Pagination from "~/componentes/Pagination";
import { useEffect } from "react";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import api from "~/services/api";

import { ThisAddDisciplinaTurma } from "./styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import ShowError from "~/utils/ShowError";
import { Materialize } from "~/styles/materializeGrid";
import { SubTitle } from "../ShowContent/styles";
import ColoredFileInput from "~/componentes/CustomForm/FileInput/styles/ColoredFileInput";

function ADDNotaAlunoTurmaModal({ data, outClick, bimestre, aluno }) {
  const [dtMatriculada, setDtMatriculada] = useState([]);
  const [dataAluno, setDataAluno] = useState(data?.aluno?.aluno);

  let alunosNotas = data?.t?.notas.filter((el)=> {
    if(el.periodo.periodoCod == parseInt(data.bimestre)){
      return el.notaAvaliacao
    }
  });

  useEffect(() => {
    //console.log('aaaaaaafghy', data?.aluno?.aluno);
    setDataAluno(data?.aluno?.aluno);
  }, [data])

  function getStatus(status){
    switch (status) {
      case 'P':
        return 'Presente';
      case 'F':
          return 'Falta';
      case 'FJ':
        return 'Falta Justificada';
    
      default:
        break;
        
    }
  }
  //console.log(data, alunosNotas)
  return (
    <SistemaFixedModal
      title="Ver Notas"
      style={{ width: "50em", maxWidth: "100%" }}
      {...{ outClick }}
    >
        <Materialize>
          <SubTitle>Alunos</SubTitle>
          <div className="row">
            <Label
              title="NOME:"
              text={data?.t?.alunoTurma?.aluno.usuario.nome || ""}
              className="col s12 m12 l6"
            />
          </div>
          <SubTitle>Notas</SubTitle>
          <div className="row">
            {/* {data?.t?.disciplinaTurma?.quantidadeAvaliacoes > 0 &&
              Array.from(Array(data?.t?.disciplinaTurma?.quantidadeAvaliacoes).keys()).map((index, dt) =>{
              let ava = 'Avaliação ' + (index + 1);
              return (<Label
                  key={index}
                  title={ava}
                  text={`${alunosNotas[index]?.notaAvaliacao  || 'nulo'}`}
                  className="col s12 m12 l3"
                />
                )
              })
            } */}
            <Label
              title={"Status"}
              text={getStatus(dataAluno.tipo) || ""}
              className="col s12 m12 l3"
            />
            
          </div>
          {dataAluno.tipo == 'FJ' && (
              <>
              <SubTitle>Falta Justificada</SubTitle>
              <div className="row">
                <Label
                  title={'Descrição'}
                  text={dataAluno?.descricao || ""}
                  className="col s12 m12 l6"
                />
                <div
                  style={{ paddingRight: "1em", marginBottom: "1em" }}
                  className="col s12 m12 l6"
                >
                  <p class="Label_title_p" style={{
                      textAlign: 'left',
                      marginBottom: '-0.25em',
                      maxWidth: '100%',
                      fontWeight: '600',
                      fontSize: '0.8em',
                      lineHeight: '2.8em',
                      textTransform: 'uppercase'
                  }}>Arquivo</p>
                  <ColoredFileInput name="files" readOnly />
                </div>
              </div>
              </>
            )}
        </Materialize>
    </SistemaFixedModal>
  );
}

export default ADDNotaAlunoTurmaModal;
