import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { Container } from "./styles";
import AcessosList from "~/componentes/AcessosList";
import { useEffect } from "react";
import { useState } from "react";
import PassByValue from "~/utils/PassByValue";
import CustomScrollBar from "~/componentes/CustomScrollBar";

function ShowContent({ mountedData }) {
  const [handledModulos, setHandledModulos] = useState({});

  useEffect(() => {
    if (Array.isArray(mountedData?.menus) && mountedData.menus.length > 0) {
      let newDataList = PassByValue(mountedData);
      let thisModulos = {};

      newDataList.menus.forEach((e) => {
        let thisModulo = e?.modulo;
        let thisMenu = e;

        delete thisMenu?.modulo;

        let thisTipo = thisModulo?.tipo;
        let thisNome = thisModulo?.nome;

        if (thisTipo) {
          if (thisModulos?.[thisTipo]?.[thisNome]?.menus) {
            thisModulos[thisTipo][thisNome].menus.push(thisMenu);
          } else if (thisModulos?.[thisTipo]) {
            thisModulos[thisTipo][thisNome] = { ...thisModulo, menus: [thisMenu] };
          } else {
            thisModulos[thisTipo] = { [thisNome]: { ...thisModulo, menus: [thisMenu] } };
          }
        }
      });

      Object.entries(thisModulos).forEach(
        ([key, value]) => (thisModulos[key] = Object.values(value))
      );

      setHandledModulos(thisModulos);
    }
  }, [mountedData]);

  return (
    <Container>
      <CustomScrollBar fixScrollMargin autoHeightMax="27em">
        <Label title="NOME" text={mountedData?.nome} />
        {Object.entries(handledModulos).map(([key, value]) => (
          <AcessosList
            showOnly
            key={key}
            modulos={value}
            title={`Acessos ${key}`}
            minimizable
          />
        ))}
      </CustomScrollBar>
    </Container>
  );
}

export default ShowContent;
