/* eslint-disable no-useless-computed-key */
import React from "react";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTableCell } from "~/pages/Sistema/componentes/SistemaList";
import { ThisTable } from "./styles";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";

function TableComponent({ setTabFilter }) {
  return (
    <>
      <TabsWrapper tabHeight={2.4}>
        <Tab materialIcon="check_circle_outline" onClick={() => setTabFilter({ status: 1 })}>
          Ativo
        </Tab>
        <Tab materialIcon="highlight_off" onClick={() => setTabFilter({ status: 0 })}>
          Desativo
        </Tab>
      </TabsWrapper>

      <SistemaComponenteInterno>
        <ThisTable>
          <SLTableCell name="nome" inputfilter>
            Nome
          </SLTableCell>
          <SLTableCell name="condicaoPagamento.nome" inputfilter>
            Cond. de Pag.
          </SLTableCell>
          <SLTableCell name="faixaInicial" inputfilter>
            Faixa Inicial
          </SLTableCell>
          <SLTableCell name="faixaFinal" inputfilter>
            Faixa Final
          </SLTableCell>
          <SLTableCell name="valorPorAluno" inputfilter>
            Valor por Aluno
          </SLTableCell>
        </ThisTable>
      </SistemaComponenteInterno>
    </>
  );
}

export default TableComponent;
