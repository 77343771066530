import React from "react";

import CustomScrollBar from "~/componentes/CustomScrollBar";
import { StyledTable, Container } from "./styles";
import customListStore from "../../customListStore";
import CustomListPagination from "../CustomListPagination";

function CustomListTable(props) {
  const { state } = React.useContext(customListStore);
  const { tableHeader, tableBody } = state?.childrens || {};

  return (
    <Container>
      <CustomScrollBar fixScrollMargin>
        <StyledTable {...props}>
          {tableHeader}
          {tableBody}
        </StyledTable>
      </CustomScrollBar>
      <CustomListPagination />
    </Container>
  );
}

export default React.memo(CustomListTable);
