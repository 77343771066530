import React, { useState } from "react";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";
import { Container } from "./styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import api from "~/services/api";
import { IsFunction } from "~/utils/CheckDataType";
import { Materialize } from "~/styles/materializeGrid";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";

function CreateContent({ periodosLetivos }) {
  const [disableDesativarClone, setDisableDesativarClone] = useState(false);

  function handleSubmit(data, options) {
    const { closeReload } = options || {};
    ShowConfirmacao(async () => {
      const response = await api.post("filial/periodoLetivo", data);

      if (response?.status === 200 && IsFunction(closeReload)) {
        closeReload();
      }

      return response;
    });
  }

  return (
    <Container defaultStyle onSubmit={handleSubmit}>
      <Materialize>
        <div className="row">
          <Label title="NOME:" className="col">
            <SistemaInput name="nome" required />
          </Label>
          <Label title="INÍCIO:" className="col s12 m12 l6">
            <SistemaInput type="date" name="dataInicio" required />
          </Label>
          <Label title="TÉRMINO:" className="col s12 m12 l6">
            <SistemaInput type="date" name="dataFim" required />
          </Label>
          <Label title="INÍCIO PERIODO DE MATRICULA:" className="col s12 m12 l6">
            <SistemaInput type="date" name="matriculaInicio" required />
          </Label>
          <Label title="TÉRMINO PERIODO DE MATRICULA:" className="col s12 m12 l6">
            <SistemaInput type="date" name="matriculaFim" required />
          </Label>
        </div>
      </Materialize>
      {Array.isArray(periodosLetivos) && periodosLetivos.length > 0 && (
        <div className="copiarPeriodoLetivo_div">
          <Materialize>
            <div className="row">
              <Label title="Copiar Período Letivo" className="col s12 m12 l6 paddingRight">
                <SistemaSelect
                  name="clonar"
                  firstOption
                  setter={(e) => setDisableDesativarClone(!!e)}
                  list={{ list: periodosLetivos, value: "cod", label: "nome" }}
                />
              </Label>
              <Label title="Desativar Período Clonado" className="col s12 m12 l6">
                <SistemaSelect name="desativarClone" firstOption isBoolean disabled={!disableDesativarClone}>
                  <option value="true">Sim</option>
                </SistemaSelect>
              </Label>
            </div>
          </Materialize>
          <p>
            Essa opção tentará criar cópias sem alunos de todas as turmas e disciplinas ligadas ao período letivo escolhido. Caso confirme a
            desativação do período letivo clonado, irá desativar o período selecionado e todas as suas disciplinas e turmas antigas.
          </p>
        </div>
      )}
    </Container>
  );
}

export default CreateContent;
