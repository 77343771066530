import React from "react";
import CustomScrollBar from "~/componentes/CustomScrollBar";

import { SistemaTableStyle } from "./styles";

function SistemaTable({ children, ...rest }) {
  return (
    <CustomScrollBar style={{ maxWidth: "100%" }} autoHeight>
      <SistemaTableStyle {...rest}>{children}</SistemaTableStyle>
    </CustomScrollBar>
  );
}

export default SistemaTable;
