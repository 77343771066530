import React from "react";
import { Button } from "~/componentes/CustomForm";
import acessos from "~/pages/Sistema/acessos";
import { MdChevronRight, MdExpandMore } from "react-icons/md";

import { ThisButton, ModuloNameDiv } from "./styles";

function ModuloButton({
  modulo,
  tipo,
  selected,
  setComponentPath,
  showMenu,
  setShowMenu,
  showLateralMenu,
  setShowLateralMenu,
}) {
  const menusLength = Array.isArray(modulo?.menus) && modulo.menus.length;

  return (
    <ThisButton
      selected={selected}
      as={menusLength > 1 ? "button" : Button}
      onClick={() => {
        if (modulo.noMenu) {
          const Componente = acessos[tipo]?.[modulo?.path];
          if (Componente) {
            setComponentPath({
              moduloPath: modulo?.path,
              menuPath: false,
            });
          }
        } else {
          setShowMenu(() => {
            let newState = [...showMenu];
            let index = newState.indexOf(modulo.cod);
            if (index > -1) {
              newState.splice(index, 1);
              return newState;
            } else {
              !showLateralMenu && setShowLateralMenu(true);
              newState.push(modulo.cod);
              return newState;
            }
          });
        }
      }}
    >
      <ModuloNameDiv>
        <i className="material-icons">{modulo.icone}</i>
        {showLateralMenu && <p>{modulo.nome}</p>}
      </ModuloNameDiv>
      {!modulo.noMenu &&
        (showMenu.includes(modulo.cod) ? (
          <MdExpandMore style={{ fontSize: "1.2em" }} />
        ) : (
          <MdChevronRight style={{ fontSize: "1.2em" }} />
        ))}
    </ThisButton>
  );
}

export default ModuloButton;
