import React, { useState, useEffect, useMemo } from "react";

import {
  QuestaoCard,
  QuestionFooter,
  CheckCustom,
  ActionButtons,
  ListQuestions,
} from "./styles";
import { Button } from "~/componentes/CustomForm";
import Pagination from "~/componentes/Pagination";
import { ShowQuestao } from "../../Questoes";
import ShowLoading from "~/utils/ShowLoading";
import api from "~/services/api";
import { IsFunction, IsNumber } from "~/utils/CheckDataType";
import QuestoesFilter from "./QuestoesFilter";
import TextContent from "~/componentes/TextContent";
import Label from "~/componentes/CustomForm/Label";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";

/**
 * @param {Object} props
 * @param {Object} props.questoes Caso seja informado uma lista de questões, o componente não mais fará o request ao banco.
 * @param {Object} props.startSelected Inicia com o filtro de selecionados ativado.
 * @param {Function} props.setQuestaoContent Recebe uma função de setState no qual Recebe o conteúdo para gerar um Crud de questão.
 * @param {Object} props.showOnly Habilita apenas o visualizar do Crud e ative o noCheckBox.
 * @param {Array} props.questoesAtivas Recebe um array de códigos de questões para utilizar como valor inicial do Check.
 * @param {Number} props.disciplinaFilialCod Caso seja fornecido um código de uma disciplinaFilial, ele será usado como filtro.
 * @param {Object} props.size Padrão 7 caso create seja true, e 8 caso contrário. Quantidade de cards que serão geradas.
 * @param {string} props.name Padrão "questoes". Nome da chave que será enviada pro Form.
 */
function QuestoesList(props) {
  const {
    defaultData,
    setQuestaoContent,
    showOnly,
    startSelected,
    questoesAtivas: questoesAtivasProp,
    disciplinaFilialCod,
    size,
    name = "questoes",
  } = props || {};
  const [questoesAtivas, setQuestoesAtivas] = useState([]);
  const [questoesFiltradas, setQuestoesFiltradas] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [questoes, setQuestoes] = useState([]);
  const [questoesDisciplina, setQuestoesDisciplina] = useState([]);
  const [pontuacoes, setPontuacoes] = useState({});

  const checkModalSetter = useMemo(() => IsFunction(setQuestaoContent), [
    setQuestaoContent,
  ]);

  useEffect(() => {
    if (Array.isArray(questoesAtivasProp)) {
      setQuestoesAtivas(questoesAtivasProp);
    }
  }, [questoesAtivasProp]);

  useEffect(() => {
    if (disciplinaFilialCod) {
      const checkQuestoes = questoes.filter(
        (e) => e.disciplinaFilialCod === disciplinaFilialCod
      );
      setQuestoesDisciplina(checkQuestoes);
    } else {
      setQuestoesDisciplina(questoes);
    }
  }, [disciplinaFilialCod, questoes]);

  useEffect(() => {
    if (defaultData) {
      setQuestoes(defaultData);

      const dPontuacoes = {};
      defaultData.forEach(
        (e) => e?.cod && (dPontuacoes[e.cod] = e?.AvaliacaoQuestao?.pontuacao)
      );

      setPontuacoes((prev) => ({ ...prev, ...dPontuacoes }));
    } else {
      api.get("professor/questoes").then((response) => {
        setQuestoes(response?.data);
      });
    }
  }, [defaultData]);

  function handleQuestaoModal(data) {
    if (checkModalSetter) {
      function editShowQuestao(response) {
        return (
          <ShowQuestao customClose={() => setQuestaoContent()} mountedData={response} />
        );
      }

      ShowLoading(
        () =>
          api.get("professor/questao", { params: { cod: data.cod } }).then((response) => {
            setQuestaoContent(editShowQuestao(response.data));

            return response;
          }),
        { successModal: false }
      );
    }
  }

  return (
    <div>
      <QuestoesFilter
        {...{
          questoesDisciplina,
          questoesAtivas,
          showOnly,
          startSelected,
          setQuestoesFiltradas,
        }}
      />
      <ListQuestions
        {...(showOnly
          ? { as: "div" }
          : {
              name: name,
              isArray: true,
              keepEmptyArray: true,
              setter: (data) => data && setQuestoesAtivas(data),
            })}
      >
        {pageData?.map((thisData, i) => {
          let thisRef = [];
          const selected = questoesAtivas.includes(thisData?.cod);

          return (
            <QuestaoCard key={thisData?.cod || i} selected={selected}>
              <div className="Questoes_Card_cod">
                #{thisData?.cod} <span>{thisData?.disciplinaFilial?.nome}</span>
                <TextContent
                  relativeHeight={false}
                  lines={1}
                  className="Questoes_Card_nomeProfessor"
                >
                  {thisData?.professor?.usuario?.nome}
                </TextContent>
              </div>
              <p className="Questoes_Card_titulo">{thisData?.nome || " "}</p>
              <TextContent
                relativeHeight={false}
                className="Questoes_Card_enunciado"
                lines={1}
              >
                {thisData?.enunciado}
              </TextContent>
              <Label
                title="Pontuação"
                text={showOnly && (pontuacoes?.[thisData?.cod] || 0)}
              >
                {!showOnly && (
                  <SistemaInput
                    onChange={(event) => {
                      const thisVal = event.currentTarget.value;
                      setPontuacoes((prev) => ({ ...prev, [thisData?.cod]: thisVal }));
                    }}
                    value={pontuacoes?.[thisData?.cod] || 0}
                    name={`pontuacoes.${thisData?.cod}`}
                    onFocus={(event) => event.target.select()}
                    type="number"
                    step="0.1"
                    min="0"
                  />
                )}
              </Label>
              <div className="Questoes_Card_footer">
                <QuestionFooter useCheckBox={!showOnly} ref={(r) => r && thisRef.push(r)}>
                  {!showOnly && (
                    <CheckCustom assistChecked={!!selected} value={thisData?.cod} />
                  )}
                  <ActionButtons>
                    <Button
                      clean
                      type="button"
                      onClick={() => handleQuestaoModal(thisData)}
                    >
                      <i className="material-icons">visibility</i>
                    </Button>
                  </ActionButtons>
                </QuestionFooter>
              </div>
            </QuestaoCard>
          );
        })}
      </ListQuestions>
      <Pagination
        data={questoesFiltradas}
        size={IsNumber(size) ? size : showOnly ? 8 : 7}
        getInfo={({ pageData }) => setPageData(pageData)}
      />
    </div>
  );
}

export default QuestoesList;
