import styled from "styled-components";
import { Button } from "~/componentes/CustomForm";

export const FormField = styled.form`
  margin: 8px 0px;
  width: 420px;
`;

export const Container = styled.div`
  margin: 0.5em 0;
  width: 30em;
  max-width: 100%;
`;

export const ButtonForm = styled(Button)`
  display: flex;
  align-items: center;
  background-color: #4fa754;
  color: #fff;
  border: none;
  box-shadow: 0 0.3em 0 #2d7a32;
  border-radius: 0.2em;
  font-family: "Doppio One";
  text-transform: uppercase;
  outline: none;
  margin-bottom: 0.3em;
  padding: 0 0.5em;
  font-size: 2.5vh;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 2.1em;

  :active {
    margin-top: 0.3em;
    box-shadow: none;
    margin-bottom: 0;
  }

  i {
    padding-left: 8px;
  }
`;

export const ButtonField = styled.div`
  display: flex;
  justify-content: center;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 16%;
  }
`;
