export function updatePerfilRequest(data) {
  return {
    type: "@user/UPDATE_PERFIL_REQUEST",
    payload: { data },
  };
}

export function updatePerfilSuccess(perfil) {
  return {
    type: "@user/UPDATE_PERFIL_SUCCESS",
    payload: { perfil },
  };
}

export function updatePerfilFailure() {
  return {
    type: "@user/UPDATE_PERFIL_FAILURE",
  };
}
