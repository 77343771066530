import React, { useEffect, memo } from "react";

import { useState } from "react";
import CustomListDefaultModalBody from "../../Styles/CustomListDefaultModalBody";
import customListStore, { customListModalChildrenProps } from "../../../customListStore";
import { IsFunction, IsBoolean } from "~/utils/CheckDataType";

/**
 * Esse componente gerencia o modal de criação de um registro. Pode ser utilizado os componentes de
 * formulário da tabela passando apenas um name para automatizar o processo de criação. Mas também
 * pode ser passado a propriedade formData para customizar os dados caso este possua uma maior complexidade.
 * @param {Object} props
 * @param {Function} props.contentMountUnMount Aceita uma função que será chamada quando o componente for montado e desmontado.
 * @param {Function} props.contentMount Aceita uma função que será chamada quando o componente for montado.
 * @param {Function} props.contentUnmount Aceita uma função que será chamada quando o componente for desmontado.
 * @param {string} props.title Altera o titulo padrão.
 * @param {Object} props.modalStyle Aceita propriedades de style para alterar a estilização do componente do Modal.
 * @param {any} props.loadCondition Padrão true. Apenas carregará o children caso true. Recebe Boolean, função, ou Array. No caso do array, é um array de dependencias, será apenas verificado se cada um possui algum tipo de valor, enquanto algum for undefined, null ou false, o children não irá carregar.
 */
function CustomListCreateComponent(props) {
  const {
    children,
    contentMount,
    contentUnmount,
    contentMountUnMount,
    title,
    loadCondition,
    modalStyle,
    ...rest
  } = props || {};
  const { dispatch } = React.useContext(customListStore);
  const [mount] = useState(() => contentMount);
  const [unmount] = useState(() => contentUnmount);
  const [mountUnMount] = useState(() => contentMountUnMount);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      if (IsBoolean(loadCondition)) {
        setLoaded(loadCondition);
      } else if (Array.isArray(loadCondition)) {
        setLoaded(loadCondition.every((e) => (e ? true : false)));
      } else if (IsFunction(loadCondition)) {
        setLoaded(loadCondition());
      } else {
        setLoaded(true);
      }
    }
  }, [loadCondition, loaded]);

  useEffect(() => {
    IsFunction(mount) && mount();
  }, [mount]);

  useEffect(() => {
    const checkFunction = IsFunction(mountUnMount);

    checkFunction && mountUnMount();

    return () => checkFunction && mountUnMount();
  }, [mountUnMount]);

  useEffect(() => {
    return () => {
      IsFunction(unmount) && unmount();
    };
  }, [unmount]);

  useEffect(() => {
    dispatch(customListModalChildrenProps(props));
  }, [dispatch, props]);

  return (
    <CustomListDefaultModalBody {...rest}>
      {loaded && children}
    </CustomListDefaultModalBody>
  );
}

export default memo(CustomListCreateComponent);
