import React, { useState } from "react";
import { Container } from "./styles";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";

import CustomScrollBar from "~/componentes/CustomScrollBar";
import { Materialize } from "~/styles/materializeGrid";
import PreviewFileInput from "~/componentes/CustomForm/FileInput/styles/PreviewFileInput";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import SistemaTopico from "~/componentes/StyledComponents/Textos/SistemaTopico";
import ItensDiv from "./ItensDiv";

function ShowContent({ mountedData, customClose }) {
  const [itens, setItens] = useState(
    mountedData?.itens ? mountedData.itens : []
  );
  const initialItemData = mountedData
    ? { questaoCod: mountedData.cod, enunciado: "", gabarito: false }
    : { questaoCod: 0, enunciado: "", gabarito: false };
  return (
    <Container>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          <div className="row">
            <SistemaTopico>Dados Questão</SistemaTopico>
            <Label title="TÍTULO:" className="col s12 m12 l6">
              <SistemaInput
                name="nome"
                defaultValue={mountedData?.nome}
                readOnly
              />
            </Label>
            <Label title="DISCIPLINA:" className="col s12 m12 l3">
              <SistemaInput
                name="nome"
                defaultValue={mountedData?.disciplinaFilial?.nome}
                readOnly
              />
            </Label>
            <Label title="NÍVEL DA QUESTÃO:" className="col s12 m12 l3">
              <SistemaInput
                name="nome"
                defaultValue={mountedData?.nivel?.nome}
                readOnly
              />
            </Label>
            <Label title="TIPO DE QUESTÃO:" className="col s12 m12 l3">
              <SistemaInput
                name="nome"
                defaultValue={mountedData?.questaoTipo?.nome}
                readOnly
              />
            </Label>
            <Label title="POSSUI TEMPO:" className="col s12 m12 l3">
              <SistemaInput
                name="nome"
                defaultValue={
                  mountedData?.possuiTempo.toString() === "true" ? "Sim" : "Não"
                }
                readOnly
              />
            </Label>
            <Label title="TEMPO: (Min:Seg)" className="col s12 m12 l3">
              <SistemaInput
                name="nome"
                defaultValue={mountedData?.tempo}
                readOnly
              />
            </Label>
            <Label title="PRIVACIDADE:" className="col s12 m12 l3">
              <SistemaInput
                name="nome"
                defaultValue={
                  mountedData?.privado?.toString() === "true"
                    ? "Privado"
                    : "Público"
                }
                readOnly
              />
            </Label>
            <Label title="ENUNCIADO:" className="col s12 m12 l8">
              <SistemaTextArea
                name="enunciado"
                defaultValue={mountedData?.enunciado}
                readOnly
              />
            </Label>
            <Label title="IMAGEM:" className="col s12 m12 l4 label_preview">
              <PreviewFileInput
                maxHeight="15em"
                defaultPreview={mountedData?.arquivo?.url}
                readOnly
              />
            </Label>
            {mountedData?.questaoTipo?.chave === "MUL" && itens?.length > 0 && (
              <SistemaTopico>Dados Itens</SistemaTopico>
            )}
            {mountedData?.questaoTipo?.chave === "MUL" && itens?.length > 0 && (
              <Label className="col s12 m12 l12 questoes_lista_item">
                <ItensDiv
                  itens={itens}
                  initialItemData={initialItemData}
                  setItens={setItens}
                />
              </Label>
            )}
            {mountedData?.questaoTipo?.chave !== "MUL" &&
              itens?.length === 0 && (
                <Label
                  title="OBSERVAÇÕES PARA CORREÇÃO:"
                  className="col s12 m12 l12"
                >
                  <SistemaTextArea
                    name="gabarito"
                    defaultValue={mountedData?.gabarito}
                    readOnly
                  />
                </Label>
              )}{" "}
          </div>
        </Materialize>
      </CustomScrollBar>
    </Container>
  );
}

export default ShowContent;
