/* eslint-disable no-unused-vars */
import React from "react";

import { Container } from "./styles";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";

const buttonProps = {
  type: "",
  onClick: () => {},
  text: "",
  redLayout: false,
  blueLayout: false,
  greenLayout: false,
  yellowLayout: false,
};

/**
 * @param {Object} props
 * @param {string} props.className
 * @param {HTMLStyleElement} props.style
 * @param {buttonProps} props.cancelButton
 * @param {buttonProps} props.submitButton
 */
function SistemaModalFooter({ children, cancelButton, submitButton, ...rest }) {
  return (
    <Container {...rest}>
      {children}
      {(!children || cancelButton) && (
        <ColoredButton redLayout small type="button" {...cancelButton}>
          {cancelButton?.text || "Cancelar"}
        </ColoredButton>
      )}
      {submitButton !== false && (!children || submitButton) && (
        <ColoredButton type="submit" greenLayout small {...submitButton}>
          {submitButton?.text || "Confirmar"}
        </ColoredButton>
      )}
    </Container>
  );
}

export default SistemaModalFooter;
