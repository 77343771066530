import React from "react";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import Label from "~/componentes/CustomForm/Label";
import { Container } from "./styles";
import AcessosList from "~/componentes/AcessosList";

function ShowContent({ mountedData, modulos }) {
  return (
    <Container>
      <CustomScrollBar fixScrollMargin autoHeightMax="27em">
        <Label title="NOME" text={mountedData?.usuario?.nome || ""} />
        <Label title="CARGO" text={mountedData?.cargo?.nome || ""} />
        <Label title="EMAIL" text={mountedData?.usuario?.email || ""} />
        <AcessosList
          showOnly
          filterMenuCod={mountedData?.acessos}
          modulos={modulos}
          title={`Acessos`}
        />
      </CustomScrollBar>
    </Container>
  );
}

export default ShowContent;
