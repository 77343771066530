import styled from "styled-components";

const CustomListDefaultModalBody = styled.div`
  max-width: 100%;

  .CustomTable_Modal_label {
    line-height: 2;
    display: block;

    p {
      margin-bottom: -0.25em;
    }

    select,
    textArea,
    .CustomTable_Input {
      width: 100%;
      margin-bottom: 0.5em;
    }
  }
`;

export default CustomListDefaultModalBody;
