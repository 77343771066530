import React from "react";
import sistemaStore from "~/pages/Sistema/sistemaStore";
import Card from "./Card";

import { Container } from "./styles";

function FirstSection({ stats }) {
  const { setComponentPath } = React.useContext(sistemaStore);

  return (
    <Container>
      <Card
        title="Professores"
        subtitle="Quantidade de professores"
        color="#FF4031"
        cardNumber={1}
        number={stats?.professores}
        onClick={() =>
          setComponentPath({
            moduloPath: "GestaoEscolar",
            menuPath: "Professores",
          })
        }
      />
      <Card
        title="Alunos"
        subtitle="Quantidade de alunos matriculados"
        color="#4FA754"
        cardNumber={2}
        number={stats?.alunos}
        onClick={() =>
          setComponentPath({
            moduloPath: "GestaoEscolar",
            menuPath: "Alunos",
          })
        }
      />
      <Card
        title="Matrículas"
        subtitle="Quantidade de matrículas pendentes"
        color="#1CA9D0"
        cardNumber={3}
        number={stats?.matriculas}
        onClick={() =>
          setComponentPath({
            moduloPath: "GestaoMatriculas",
            menuPath: "Matriculas",
          })
        }
      />
      <Card title="Questões" subtitle="Quantidade de questões" color="#FFDA44" cardNumber={4} number={stats?.questoes} />
      <Card
        title="Funcionários"
        subtitle="Quantidade de funcionários"
        color="#FF4031"
        cardNumber={5}
        number={stats?.funcionarios}
        onClick={() =>
          setComponentPath({
            moduloPath: "ControleAcesso",
            menuPath: "Funcionarios",
          })
        }
      />
      <Card
        title="Disciplinas"
        subtitle="Quantidade de disciplinas"
        color="#4FA754"
        cardNumber={6}
        number={stats?.disciplinas}
        onClick={() =>
          setComponentPath({
            moduloPath: "GestaoEscolar",
            menuPath: "Disciplinas",
          })
        }
      />
      <Card
        title="Turmas"
        subtitle="Quantidade de Turmas ativas"
        color="#1CA9D0"
        cardNumber={7}
        number={stats?.turmas}
        onClick={() =>
          setComponentPath({
            moduloPath: "GestaoEscolar",
            menuPath: "Turmas",
          })
        }
      />
      <Card title="Avaliações" subtitle="Quantidade de avaliações" color="#FFDA44" cardNumber={8} number={stats?.avaliacoes} />
    </Container>
  );
}

export default FirstSection;
