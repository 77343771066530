import React from "react";
import IconButton from "~/componentes/CustomForm/Button/Styles/IconButton";
import SistemaTable from "~/componentes/StyledComponents/Tables/SistemaTable";
import handleDate from "~/utils/handleDate";
import AddAulaDisciplinaTurmaModal from "./AddAulaDisciplinaTurmaModal";

function AulasDisciplinaTurmaTable({ readonly, aulasDisciplinasTurmas, setAulasDisciplinasTurmas, periodosLetivos }) {
  const editable = !readonly && typeof setAulasDisciplinasTurmas === "function";

  return (
    <SistemaTable style={{ margin: "1em 0" }}>
      <thead>
        <tr>
          <th>Periodo Letivo</th>
          <th>Turma</th>
          <th>Disciplina</th>
          <th>Disponibilização</th>
          <th style={{ width: "3.3em" }}>-</th>
        </tr>
      </thead>
      <tbody>
        {editable && <AddAulaDisciplinaTurmaModal addNames {...{ aulasDisciplinasTurmas, periodosLetivos, setAulasDisciplinasTurmas }} />}

        {aulasDisciplinasTurmas?.map((aulaDt, i) => {
          const { disciplinaFilial, turma } = aulaDt?.disciplinaTurma || {};
          const disciplinaNome = disciplinaFilial?.nome || aulaDt?.disciplinaNome;
          const turmaNome = turma?.nome || aulaDt?.turmaNome;
          const periodoLetivoNome = turma?.periodoLetivo?.nome || aulaDt?.periodoLetivoNome;

          return (
            <tr key={aulaDt?.cod || i}>
              <td>{periodoLetivoNome}</td>
              <td>{turmaNome}</td>
              <td>{disciplinaNome}</td>
              <td>{handleDate(aulaDt?.dataDisponibilizacao)?.dmyhms}</td>
              {editable && (
                <td>
                  {!aulaDt?.cod && (
                    <IconButton
                      materialIcon="delete"
                      onClick={() =>
                        setAulasDisciplinasTurmas((prev) => {
                          prev.splice(i, 1);
                          return [...prev];
                        })
                      }
                    />
                  )}
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </SistemaTable>
  );
}

export default AulasDisciplinaTurmaTable;
