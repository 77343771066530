import styled from "styled-components";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";

export const Container = styled(SistemaFixedModal)`
  width: 25em;
  max-width: 95%;
  
  max-height: 70vh;
  overflow-y: auto;


  .Matricula_ModalLogin_p_loginMsg {
    font-size: 0.8em;
    line-height: 2.2em;
    color: #5a5a5a;
    font-weight: 600;
  }

  .Matricula_ModalLogin_Button_recSenha {
    font-size: 0.8em;
    width: fit-content;
    cursor: pointer;
    transition: 0.2s ease;
    border: none;
    background: transparent;
    font-weight: bold;

    :active {
      transform: scale(0.98);
    }
  }

  .Matricula_ModalLogin_Button_footer {
    width: 100%;
  }
`;

export const ThisFooterButtons = styled.div`
  padding-top: 0.6em;

  p {
    text-align: center;
    font-size: 0.8em;
    color: #333;
    margin-bottom: 0.4em;
    font-weight: 600;
  }

  .Matricula_ModalLogin_Button_footer {
    margin: 0 0 0.8em 0;
    width: 100%;
    padding: 0.5em 0.5em;
  }
`;
