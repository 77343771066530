import React, { useEffect, useState } from "react";

import { Container } from "./styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import InfoContent from "./InfoContent";
import UserDevice from "~/componentes/UserDevice";
import EscolaDados from "./EscolaDados";
import api from "~/services/api";
import TabelaSeries from "./TabelaSeries";

function ModalShow({ filial, setModalShow }) {
  const { desktop } = UserDevice();
  // const [matModal, setMatModal] = useState(false);
  const [periodosLetivos, setPeriodosLetivos] = useState([]);
  const [dadosMatriculas, setDadosMatriculas] = useState([]);

  useEffect(() => {
    if (filial?.cod) {
      async function fetchInitialData() {
        await api
          .get("configuracoesMatriculaFilial", {
            params: { filialCod: filial.cod },
          })
          .then((r) => {
            let checkPl = [];

            if (Array.isArray(filial?.periodosLetivos)) {
              filial.periodosLetivos.forEach((pl) => {
                const thisSeries =
                  pl?.series?.filter((s) => {
                    const a = r?.data?.find((e) => {
                      return e?.cod === s?.ensinoCod;
                    });
                    return !!a;
                  }) || [];

                if (thisSeries?.length > 0) {
                  checkPl.push({ ...pl, series: thisSeries });
                }
              });
            }

            setPeriodosLetivos(checkPl);
            setDadosMatriculas(r?.data || []);
          });
      }

      fetchInitialData();
    }
  }, [filial]);

  return (
    <Container
      scrollBar={!desktop}
      // footerButtons={{
      //   submitButton: {
      //     type: "button",
      //     onClick: () => setMatModal(true),
      //     text: "MATRICULAR",
      //   },
      // }}
      title="Escola"
      backgroundProps={{ style: { zIndex: 600 } }}
      outClick={() => setModalShow(false)}
    >
      {/* {matModal && periodosLetivos?.[0]?.nome && dadosMatriculas && (
        <FormContent {...{ filial, outClick: setMatModal, periodosLetivos, dadosMatriculas }} />
      )} */}
      <div className="modal-flex">
        <div className="flex-item-1">
          {desktop ? (
            <CustomScrollBar fixScrollMargin autoHide autoHeightMax="83vh">
              <InfoContent {...{ filial }} />
            </CustomScrollBar>
          ) : (
            <InfoContent {...{ filial }} />
          )}
        </div>
        <div className="flex-item-2">
          {desktop ? (
            <CustomScrollBar fixScrollMargin autoHide autoHeightMax="83vh">
              <EscolaDados {...{ filial }} />
              <TabelaSeries {...{ filial, dadosMatriculas, periodosLetivos }} />
            </CustomScrollBar>
          ) : (
            <EscolaDados {...{ filial }} />
          )}
        </div>
      </div>
    </Container>
  );
}

export default ModalShow;
