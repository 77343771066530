import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";

import { Container } from "./styles";

function RespostaDissertativa({ respostaQuestao }) {
  return (
    <Container>
      <Label className="col s12 m12 l12" title="Resposta:">
        <SistemaTextArea readOnly value={respostaQuestao?.resposta || ""} />
      </Label>
    </Container>
  );
}

export default RespostaDissertativa;
