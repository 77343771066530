import React, { useState, useLayoutEffect } from "react";

import api from "~/services/api";
import ShowContent from "./ShowContent";
import CreateEditContent from "./CreateEditContent";
import ShareContent from "./ShareContent";

import { Container } from "./styles";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import Pagination from "~/componentes/Pagination";
import CustomBodyContent from "./CustomBodyContent";
import {
  SLCreateModal,
  SLEditModal,
  SLShowModal,
  SLShareModal,
} from "~/pages/Sistema/componentes/SistemaList";

export default function Avaliacao() {
  const [mountedData, setMountedData] = useState();
  const [data, setData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [tabFilter, setTabFilter] = useState({ ativo: true });
  const [disciplinasFilial, setDisciplinasFilial] = useState([]);
  const [questoesNiveis, setQuestoesNiveis] = useState([]);
  const [closeCondition, setCloseCondition] = useState(true);
  const [tiposQuestoes, setTiposQuestoes] = useState([]);

  useLayoutEffect(() => {
    api.get("professor/disciplinasFilial").then((response) => {
      const thisResult = response.data.sort((a, b) => a?.nome - b?.nome);
      setDisciplinasFilial(thisResult);
    });

    api.get("professor/questoestipos").then((response) => {
      const thisResult = response.data.sort((a, b) => a?.nome - b?.nome);
      setTiposQuestoes(thisResult);
    });

    api.get("professor/questaoNiveis").then((response) => response?.data && setQuestoesNiveis(response.data));
  }, []);

  return (
    <Container
      inputs={[
        { filter: "cod", label: "Código" },
        { filter: "nome", label: "Nome" },
        { filter: "professor.usuario.nome", label: "Professor" },
      ]}
      selects={[
        {
          filter: "disciplinaFilialCod",
          list: disciplinasFilial,
          outLabel: "Disciplinas",
          value: "cod",
          label: "nome",
        },
      ]}
      requests={{
        findAll: () => api.get("professor/avaliacoes"),
        findOne: (thisData) => api.get("professor/avaliacao", { params: { cod: thisData.cod } }),
        deleteOne: (thisData) => api.delete("professor/avaliacao", { data: { cod: thisData.cod } }),
      }}
      setter={(thisData) => {
        setData(thisData);
      }}
      dataFilter={tabFilter}
    >
      <TabsWrapper tabHeight={2.4}>
        <Tab materialIcon="check_circle_outline" onClick={() => setTabFilter({ ativo: true })}>
          Ativo
        </Tab>
        <Tab materialIcon="highlight_off" onClick={() => setTabFilter({ ativo: false })}>
          Desativo
        </Tab>
      </TabsWrapper>

      <SLCreateModal
        title="Cadastrar Avaliação"
        contentMount={setMountedData}
        contentUnmount={setMountedData}
        closeCondition={closeCondition}
      >
        <CreateEditContent {...{ disciplinasFilial, questoesNiveis, tiposQuestoes, setCloseCondition }} />
      </SLCreateModal>

      <SLEditModal
        title="Editar Avaliação"
        contentMount={setMountedData}
        contentUnmount={setMountedData}
        closeCondition={closeCondition}
      >
        <CreateEditContent
          {...{ mountedData, disciplinasFilial, questoesNiveis, tiposQuestoes, setCloseCondition }}
        />
      </SLEditModal>

      <SLShowModal title="Visualizar Avaliação" contentMount={setMountedData} contentUnmount={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLShareModal
        title="Disponibilizar Avaliação"
        contentMount={setMountedData}
        contentUnmount={setMountedData}
      >
        <ShareContent {...{ mountedData }} />
      </SLShareModal>

      <SistemaComponenteInterno defaultStyle setter={setMountedData}>
        <CustomBodyContent {...{ pageData }} />

        <Pagination
          data={data}
          getInfo={({ pageData }) => {
            setPageData(pageData);
          }}
          size={9}
        />
      </SistemaComponenteInterno>
    </Container>
  );
}

export { ShowContent as ShowQuestao, CreateEditContent as CreateEditQuestao };
