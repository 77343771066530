import React, { useState, useEffect } from "react";

import { Container, Schools, Header, FlexDiv } from "./styles";
import ModalFilter from "./ModalFilter";
import ModalLogin from "./ModalLogin";
import ModalShow from "./ModalShow";
import SchoolCard from "./SchoolCard";
import api from "~/services/api";
import { useSelector } from "react-redux";

function Matricula() {
  const [modalFilter, setModalFilter] = useState(false);
  const [modalLogin, setModalLogin] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [series, setSeries] = useState();
  const [filiais, setFiliais] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [inputFilter, setInputFilter] = useState("");
  const [filters, setFilters] = useState();
  const inputFilterRef = React.useRef();

  const {
    auth: { token },
  } = useSelector((state) => state) || {};

  useEffect(() => {
    token && setModalLogin(false);
  }, [token]);

  useEffect(() => {
    async function fetchData() {
      api.get("series").then((r) => setSeries(r?.data));

      await api.get("matriculasAbertas").then((r) => {
        setFiliais(r?.data || []);
        setFilteredData(r?.data || []);
      });
    }
    fetchData();
  }, []);

  const renderFiliais = React.useMemo(() => {
    const result = filteredData?.filter?.((fil) =>
      fil?.nomeFantasia?.toLowerCase()?.includes(inputFilter?.toLowerCase())
    );

    if (Array.isArray(result)) {
      return result;
    } else {
      return [];
    }
  }, [filteredData, inputFilter]);

  return (
    <Container>
      <Header>
        <h4>Escolha uma instituição</h4>
        <FlexDiv>
          <input ref={inputFilterRef} />
          <button
            onClick={() => {
              const v = inputFilterRef.current.value;
              setInputFilter(v);
            }}
          >
            <span className="material-icons">search</span>
          </button>
          <button
            className="green"
            onClick={() => setModalFilter(!modalFilter)}
          >
            <span className="material-icons">filter_alt</span>
          </button>
        </FlexDiv>
      </Header>
      <Schools>
        {renderFiliais.map((filial, i) => (
          <SchoolCard
            key={filial?.cod || i}
            onClick={() => (token ? setModalShow(filial) : setModalLogin(true))}
            {...{ filial }}
          />
        ))}
      </Schools>
      {modalLogin && <ModalLogin setModalLogin={setModalLogin} />}
      {modalFilter && (
        <ModalFilter
          {...{
            setModalFilter,
            series,
            filters,
            setFilters,
            filiais,
            setFilteredData,
          }}
        />
      )}
      {modalShow && (
        <ModalShow filial={modalShow} setModalShow={setModalShow} />
      )}
    </Container>
  );
}

export default Matricula;
