import React from "react";
import Label from "~/componentes/CustomForm/Label";

// import { Container } from './styles';

function RespostaAudio({ respostaQuestao, showResp }) {
  return (
    <Label className="col s12 m12 l12" text={!showResp && ""} title="Resposta:">
      {showResp && <audio controls src={respostaQuestao?.audio?.url} />}
    </Label>
  );
}

export default RespostaAudio;
