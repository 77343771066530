import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 0.5em;

  .Professores_div_addDisciplinas {
    margin-bottom: 0.5em;
    display: flex;
    justify-content: space-between;

    select {
      width: 100%;
      margin-right: 1em;
    }
  }
`;
