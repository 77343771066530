import React, { useState } from "react";
import { ButtonForm, ButtonField, Container } from "../styles";
import Label from "~/componentes/CustomForm/Label";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import { CLModalHeader } from "~/pages/Sistema/componentes/CustomList/CustomListStyledComponents";
import api from "~/services/api";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import ShowLoading from "~/utils/ShowLoading";
import StyledCustomListCreateUser from "./StyledCustomListCreateUser";
import StyledCustomListCheckUser from "./StyledCustomListCheckUser";

function StyledCustomListFindUser({ data, setData, setConfirmData }) {
  const [email, setEmail] = useState();

  async function handleSubmit() {
    ShowLoading(
      () =>
        api
          .get("global/usuario", { params: { email: email } })
          .then((e) => {
            e?.status === 200 && setData(e.data);
            return e;
          })
          .catch((err) => {
            ShowConfirmacao(() => setData(false), {
              successModal: false,
              errorModal: false,
              message:
                "Usuário não existe em nossa base de dados deseja efetuar um novo cadastro?",
            });
          }),
      { successModal: false }
    );
  }

  return (
    <Container>
      <CLModalHeader>
        {data === false ? "Cadastre o Usuário" : "Informe o Usuário"}
      </CLModalHeader>
      <Label title="EMAIL">
        <SistemaInput
          type="email"
          defaultValue={email}
          onKeyDown={(e) => e.key === "Enter" && handleSubmit()}
          onChange={(e) => {
            data && setData({});
            setEmail(e.target.value);
          }}
        />
      </Label>
      <ButtonField>
        <ButtonForm type="button" onClick={handleSubmit}>
          Buscar
          <i className="material-icons">person_search</i>
        </ButtonForm>
      </ButtonField>
      {data && <StyledCustomListCheckUser setConfirmData={setConfirmData} data={data} />}
      {data === false && <StyledCustomListCreateUser setData={setData} email={email} />}
    </Container>
  );
}

export default StyledCustomListFindUser;
