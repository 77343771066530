import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  align-content: center;

  .astronauta {
    width: 24em;
    height: auto;
    margin-left: 1.5em;
    animation: floating1 8s ease infinite;
  }

  @keyframes floating1 {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-2em);
    }
    100% {
      transform: translateY(0);
    }
  }
`;
const floatingContainer = keyframes`
  0%, 10%, 30%, 90% {
    transform: translateX(-0.2em) translateY(-0.3em);
  }
  10% {
    transform: translateX(-0.1em) translateY(0.1em);
  }
  20% {
    transform: translateX(0.2em) translateY(0.2em);

  }
  30% {
    transform: translateX(0em) translateY(0.2em);
  }
  40% {
    transform: translateX(0em) translateY(-0.2em);
  }
  50% {
    transform: translateX(-0.2em) translateY(-0.2em);
  }
  60% {
    transform: translateX(0.2em) translateY(-0.2em);
  }
  100% {
    transform: translateX(-0.2em) translateY(-0.3em);
  }
  
`;

export const Content = styled.div`
  width: ${(props) => (props.userDevice.desktop ? "52em" : "28em")};
  max-width: 90%;
  margin-right: 2em;
  min-height: 30.6em;
  border-radius: 2.5em;
  ${(props) => !props.toggleAstro && "margin: 3em auto;"}
  background: #ffffff;
  box-shadow: 0.375em 0.375em 1.25em rgba(134, 134, 134, 0.7);
  animation: ${floatingContainer} 15s ease infinite;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .botoes {
    display: grid;
    grid-template-columns: repeat(2, 3em);
    align-content: center;
    width: fit-content;
    margin: 1em auto 1em;
  }

  @media (max-width: 993px) {
    .botoes {
      margin-top: 2em;
    }
    .conteudo {
      margin-left: 0.2em !important;
    }
    & {
      width: 100%;
      border-radius: 1em;
      animation: none;
    }
  }
`;

export const CircleButton = styled.button`
  margin: auto;
  border-radius: 50%;
  width: ${(props) => (props.selected ? "2em" : "1.75em")};
  height: ${(props) => (props.selected ? "2em" : "1.75em")};
  background: ${(props) => props.color};
  border: ${(props) => (props.selected ? `0.1em solid ${props.color}` : "none")};
  ${(props) => props.selected && "box-shadow: inset 0em 0em 0 0.15em #fff;"}
`;
