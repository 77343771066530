import React from "react";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import api from "~/services/api";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import Label from "~/componentes/CustomForm/Label";

import { Container } from "./styles";

function CreateEditContent({ disciplinas, mountedData }) {
  function handleSubmit(editedData, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let response;
        if (mountedData) {
          response = await api.put("filial/disciplinaFilial", {
            ...editedData,
            cod: mountedData.cod,
          });
        } else {
          response = await api.post("filial/disciplinaFilial", {
            ...editedData,
          });
        }
        if (closeReload && response?.status === 200) {
          closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao ${
            mountedData ? "atualizar" : "criar"
          } essa disciplina, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja ${
          mountedData ? "atualizar" : "criar"
        } essa disciplina?`,
        successModal: `Disciplina ${
          mountedData ? "atualizada" : "cadastrada"
        } com sucesso!`,
      }
    );
  }

  return (
    <Container defaultStyle onSubmit={handleSubmit}>
      <Label title="NOME:">
        <SistemaInput name="nome" defaultValue={mountedData?.nome} />
      </Label>
      <Label title="DISCIPLINA PADRÃO:">
        <SistemaSelect
          name="disciplinaCod"
          firstOption
          list={{ list: disciplinas, value: "cod", label: "nome" }}
          defaultValue={mountedData?.disciplinaCod}
        />
      </Label>
    </Container>
  );
}

export default CreateEditContent;
