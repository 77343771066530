import styled from "styled-components";

export const Container = styled.div`
  background: #fff;
  box-shadow: 0 0.313em 0.938em rgba(0, 0, 0, 0.1);
  border-radius: 0.375em;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 98%;

  .chart-container {
    padding: 0.8em;
    max-width: 100%;
  }
  .chart-header {
    width: 100%;
    padding-bottom: 3em;

    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 1.375em;
      line-height: 1.625em;
      color: #293f57;
    }
  }
  .one-line {
    width: 100%;
    text-align: center;
    margin-bottom: 0.5em;

    h5 {
      padding-bottom: 0.2em;
      font-style: normal;
      font-weight: 600;
      font-size: 1.5em;

      color: #293f57;
    }
    h6 {
      font-style: normal;
      font-weight: 600;
      font-size: 1em;
      line-height: 1.375em;

      color: #9ab1b6;
    }
  }
`;

export const ChartContainer = styled.div`
  max-width: 100%;
`;
