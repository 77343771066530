import React, { useEffect } from "react";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";

import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import { useState } from "react";

import { Container, FlexDiv, SubTitle } from "./styles";
import { SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import { Materialize } from "~/styles/materializeGrid";
import api from "~/services/api";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import setRegex, { setMoney } from "~/utils/setRegex";
import FindOrCreateUser from "~/componentes/FindOrCreateUser";
import UserDevice from "~/componentes/UserDevice";
import CreateModal from "./CreateModal";
import CentroCustoModal from "./CentroCustoModal";
import InputBanco from "./CreateModal/ThisInputBanco";
import InputCentroCusto from "./CentroCustoModal/ThisInputCentroCusto";
import InputTipoTitulo from "./TipoTituloModal/ThisInputTipoTitulo";
import TipoTituloModal from "./TipoTituloModal";
import InputNatureza from "./NaturezaModal/ThisInputNatureza";
import NaturezaModal from "./NaturezaModal";
import InputFornecedor from "./FornecedorModal/ThisInputFornecedor";
import FornecedorModal from "./FornecedorModal";

function CreateEditContent({ mountedData }) {
  const [bancoSelected, setBancoSelected] = useState(mountedData?.banco);
  const [agenciaSelected, setAgenciaSelected] = useState(mountedData?.agenciaPortador);
  const [contaSelected, setContaSelected] = useState(mountedData?.contaPortador);
  const [centroCustoSelected, setCentroCustoSelected] = useState(mountedData?.centroCusto);
  const [naturezaSelected, setNaturezaSelected] = useState(mountedData?.natureza);
  const [tipoTituloSelected, setTipoTituloSelected] = useState(mountedData?.tipoTitulo);
  const [fornecedorSelected, setFornecedorSelected] = useState(mountedData?.usuario.cod);
  const [nomeClienteSelected, setNomeFornecedorSelected] = useState(mountedData?.usuario.nome);

  const [bancos, setBancos] = useState([]);
  const [centroCusto, setCentroCusto] = useState([]);
  const [natureza, setNatureza] = useState([]);
  const [tipoTitulo, setTipoTitulo] = useState([]);
  const [fornecedores, setFornecedores] = useState([]);

  const { mobile } = UserDevice();
  const [tab, setTab] = useState(0);

  useEffect(() => {
    api
      .get("filial/banco", {
        params: {  },
      })
      .then((r) => setBancos(r?.data || []));

    api
      .get("/filial/centrocusto", {
        params: {  },
      })
      .then((r) => setCentroCusto(r?.data || []));

    api
      .get("/filial/tipotitulo", {
        params: {  },
      })
      .then((r) => setTipoTitulo(r?.data || []));

    api
      .get("/filial/natureza", {
        params: {  },
      })
      .then((r) => setNatureza(r?.data || []));

    api
      .get("/filial/fornecedores", {
        params: {  },
      })
      .then((r) => setFornecedores(r?.data || []));
  }, []);

  function handleSubmit(data, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let response;
        data = {
          ...data,
          bancoId: parseInt(bancoSelected.cod),
          centroCustoId: parseInt(centroCustoSelected.cod),
          naturezaId: parseInt(naturezaSelected.cod),
          tipoTituloId: parseInt(tipoTituloSelected.cod)
        }

        if (mountedData) {
          response = await api.put("filial/titulopagar", {
            ...data,
            cod: mountedData.cod,
          });
        } else {
          response = await api.post("filial/titulopagar", { ...data });
        }

        if (response?.status === 200) {
          closeReload && closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao ${
            mountedData ? "atualizar" : "criar"
          } esse titulo, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja ${
          mountedData ? "atualizar" : "criar"
        } esse Titulo?`,
        successModal: `Titulo ${
          mountedData ? "atualizado" : "cadastrado"
        } com sucesso!`,
      }
    );
  }

  return (
    <Container onSubmit={handleSubmit}>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          <div className="row">
            <SubTitle>Dados do Titulo</SubTitle>
            <Label title="PREFIXO:" className="col s12 m12 l1">
              <SistemaInput
                name="prefixo"
                maxLength={80}
                defaultValue={mountedData?.prefixo}
                required
              />
            </Label>
            <Label title="CÓDIGO: *" className="col s12 m12 l2">
              <SistemaInput
                name="codigo"
                maxLength={80}
                defaultValue={mountedData?.codigo}
                required
              />
            </Label>
            <Label title="PARCELA:" className="col s12 m12 l1">
              <SistemaInput
                name="parcela"
                defaultValue={mountedData?.parcela}
                required
              />
            </Label>
            <Label className="col s12 m12 l3" title="TIPO DE TÍTULO: *">
              <FlexDiv>
                <InputTipoTitulo
                  {...{
                    tipoTituloSelected, setTipoTituloSelected
                  }}
                />
                <TipoTituloModal
                  {...{
                    setTab,
                    mobile,
                    setTipoTituloSelected,
                    tipoTitulo
                  }}
                />
              </FlexDiv>
            </Label>
            <Label className="col s12 m12 l3" title="NATUREZA: *">
              <FlexDiv>
                <InputNatureza
                  {...{
                    naturezaSelected, setNaturezaSelected
                  }}
                />
                <NaturezaModal
                  {...{
                    setTab,
                    mobile,
                    setNaturezaSelected,
                    natureza
                  }}
                />
              </FlexDiv>
            </Label>
            <Label title="EMISSÃO: *" className="col s12 m12 l3">
              <SistemaInput
                name="emissao"
                required
                type="date"
                value={mountedData?.emissao}
              />
            </Label>
            <Label className="col s12 m12 l3" title="FORNECEDOR: *">
              <FlexDiv>
                <InputFornecedor
                  {...{
                    fornecedorSelected, setFornecedorSelected
                  }}
                />
                <FornecedorModal
                  {...{
                    setTab,
                    mobile,
                    setFornecedorSelected,
                    fornecedores,
                    setNomeFornecedorSelected
                  }}
                />
              </FlexDiv>
            </Label>
            <Label title="NOME FORNECEDOR: *" className="col s12 m12 l3">
              <SistemaInput
                name="nomeFornecedor"
                disabled={true}
                value={nomeClienteSelected}
                required
              />
            </Label>
            <Label title="VENCIMENTO: *" className="col s12 m12 l3">
              <SistemaInput
                name="vencimento"
                required
                type="date"
                value={mountedData?.vencimento}
              />
            </Label>
            <Label title="VALOR: *" className="col s12 m12 l3">
              <SistemaInput
                name="valor"
                value={mountedData?.valor}
                required
              />
            </Label>
            <Label title="DESCONTO:" className="col s12 m12 l3">
            <SistemaInput
                name="desconto"
                value={mountedData?.descontoValor}
                required
              />
            </Label>
            <Label title="HISTORICO:" className="col s12 m12 l3">
              <SistemaInput
                name="historico"
                maxLength={80}
                defaultValue={mountedData?.historico}
              />
            </Label>
          </div>
          <div className="row">
            <div>
              <SubTitle>Dados do Banco</SubTitle>
              <Label className="col s12 m12 l3" title="CENTRO DE CUSTO: *">
                <FlexDiv>
                  <InputCentroCusto
                    {...{
                      centroCustoSelected, setCentroCustoSelected
                    }}
                  />
                  <CentroCustoModal
                    {...{
                      setTab,
                      mobile,
                      setCentroCustoSelected,
                      centroCusto
                    }}
                  />
                </FlexDiv>
              </Label>
              <Label className="col s12 m12 l3" title="BANCO: *">
                <FlexDiv>
                  <InputBanco
                    {...{
                      bancoSelected, setBancoSelected
                    }}
                  />
                  <CreateModal
                    {...{
                      setTab,
                      mobile,
                      setBancoSelected,
                      bancos,
                      bancoSelected,
                      setAgenciaSelected,
                      setContaSelected
                    }}
                  />
                </FlexDiv>
              </Label>
              <div className="wrapper-input">
                <Label title="Agencia: *" className="col s12 m12 l3">
                  <SistemaInput
                    required
                    name="agenciaPortador"
                    disabled={true}
                    value={agenciaSelected}
                  />
                </Label>
              </div>

              <div className="wrapper-input">
                <Label title="Conta: *" className="col s12 m12 l3">
                  <SistemaInput
                    required
                    name="contaPortador"
                    disabled={true}
                    value={contaSelected}
                  />
                </Label>
              </div>
              
            </div>
          </div>
        </Materialize>
      </CustomScrollBar>
      <SLModalFooter />
    </Container>
  );
}

export default CreateEditContent;
