import React from "react";
import customListStore, { customListDeleteData } from "../../../customListStore";
import TableButtonStyle from "../styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";

function CustomListDeleteButton({ data, icon, className, onClick, ...rest }) {
  const { state, dispatch } = React.useContext(customListStore);
  const { requests, errorModal } = state || {};

  async function handleDelete(data) {
    await ShowConfirmacao(
      async () => {
        const response = await requests?.deleteOne(data);
        if (response?.status === 200) {
          dispatch(customListDeleteData(data));
        } else {
          throw new Error();
        }
        return response;
      },
      { errorModal }
    );
  }

  return (
    <TableButtonStyle
      onClick={() => {
        handleDelete(data);
        onClick && onClick();
      }}
      className={`CustomTable_button_delete ${className}`}
      {...rest}
    >
      <i className="material-icons">{icon || "delete"}</i>
    </TableButtonStyle>
  );
}

export default CustomListDeleteButton;
