const INITIAL_STATE = false;

export default function loadingModal(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@loadingModal/loadingToggle": {
      return !state;
    }
    default: {
      return state;
    }
  }
}
