import styled from "styled-components";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";

export const Container = styled(SistemaFixedModal)`
  font-size: 0.9em;
  z-index: 99;
  width: 30em;

  .confirmacaoHeader {
    border-bottom: 0.1em solid #f5f5f5;
    padding-bottom: 0.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.4em;

    button {
      background: none;
      border: none;
    }
  }

  .confirmacaoBody {
    border-bottom: 0.1em solid #efefefee;
    display: flex;
    align-items: center;
    padding: 1em 0.5em;

    i {
      min-width: 1.2em;
      width: 1.2em;
      height: 1.2em;
      border-radius: 50%;
      background: #f5f5f5;
      color: #9fb0af;
      font-size: 4em;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    p {
      font-size: 1.2em;
      margin-left: 2em;
    }
  }

  .confirmacaoBotoes {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      margin: 0.5em 0 0.5em 1em;

      .loadingIcon {
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
        animation: loading 2s linear infinite;
      }

      @keyframes loading {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
`;

// export const Container = styled.div`
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   z-index: 2;
//   margin: -1em -2em;

//   .ConfirmacaoInterna_Modal_modal {
//     font-size: 0.9em;
//     position: sticky;
//     background: #fff;
//     border-radius: 0.5em;
//     box-shadow: 0 0 1em #848484, 0 0 0 100vmax #0006;
//     width: 30em;
//     top: calc(50% - 10.5em);
//     left: calc(50% - 15em);
//     padding: 1em 1em 0.5em;
//     z-index: 10;

//     .confirmacaoHeader {
//       border-bottom: 0.1em solid #f5f5f5;
//       padding-bottom: 0.5em;
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       font-size: 1.4em;

//       button {
//         background: none;
//         border: none;
//       }
//     }

//     .confirmacaoBody {
//       border-bottom: 0.1em solid #efefefee;
//       display: flex;
//       align-items: center;
//       padding: 1em 0.5em;

//       i {
//         min-width: 1.2em;
//         width: 1.2em;
//         height: 1.2em;
//         border-radius: 50%;
//         background: #f5f5f5;
//         color: #9fb0af;
//         font-size: 4em;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//       }

//       p {
//         font-size: 1.2em;
//         margin-left: 2em;
//       }
//     }

//     .confirmacaoBotoes {
//       display: flex;
//       justify-content: flex-end;
//       align-items: center;

//       > div {
//         button {
//           .loadingIcon {
//             width: 1.5em;
//             height: 1.5em;
//             border-radius: 50%;
//             animation: loading 2s linear infinite;
//           }

//           @keyframes loading {
//             0% {
//               transform: rotate(0deg);
//             }
//             100% {
//               transform: rotate(360deg);
//             }
//           }
//         }
//       }
//     }
//   }
// `;
