import styled from "styled-components";
import { Materialize } from "~/styles/materializeGrid";

export const Container = styled.div`
  .table-content {
    position: relative;
    background: #fff;
    box-shadow: 0 0.313em 0.938em rgba(0, 0, 0, 0.1);
    border-radius: 0.375em;
    padding: 0.8em;
    height: auto;
    table {
      border-collapse: collapse;
      margin-bottom: 1em;
    }
    thead tr {
      background: #f5f5f5;

      border-bottom: solid #ebebeb 0.125em;
    }
    tbody tr {
      border-bottom: solid #ebebeb 0.125em;
    }
    tr:nth-child(2n) {
      background: #f5f5f5;
    }
    th {
      color: #293f57;
      height: 2.5em;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.95em;
      white-space: nowrap;
      padding: 0.2em 1.5em;
    }
    td {
      padding: 1em 1.5em;
      white-space: nowrap;
      text-align: center;
      color: #7b898c;
      font-weight: 600;
    }

    .table-action-buttons {
      margin: 1em 0;

      .paginator {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .actions-paginator {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        button {
          margin-left: 0.5em;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .actions {
      justify-content: center !important;
    }
    .content .content-cards {
      padding: 0.2rem !important;
    }
    .paginator {
      justify-content: center !important;
    }
    .actions-paginator {
      margin-top: 1em;
      justify-content: center !important;
    }
  }
`;

export const MaterializeContent = styled(Materialize)`
  width: 100%;
`;
