import React from "react";
import { toast } from "react-toastify";

const toastConfig = {
  position: "top-right",
  closeButton: true,
  onClick: (e) => e.stopPropagation(),
};

function Toastify({ icon, message }) {
  return (
    <div className="toast-conatiner">
      {icon && <span className="material-icons">{icon}</span>}
      <div>{message}</div>
    </div>
  );
}

class CustomToast {
  default(message) {
    toast(<Toastify {...{ message }} />, {
      ...toastConfig,
    });
  }

  error(message) {
    toast.error(<Toastify {...{ message, icon: "error_outline" }} />, toastConfig);
  }

  success(message) {
    toast.success(
      <Toastify {...{ message, icon: "check_circle_outline" }} />,
      toastConfig
    );
  }
}

export default new CustomToast();
