import styled from "styled-components";

export const Container = styled.div`
  background: #fff;
  box-shadow: 0 0.313em 0.938em rgba(0, 0, 0, 0.1);
  border-radius: 0.375em;
  padding: 0.5em;
  margin-bottom: 0.5em;

  .card-header {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .card-header h2 {
    font-size: 1.3em;
    color: #293f57;
    margin-left: 0.2em;
    font-weight: 600;
  }

  .card-body {
    width: 100%;
    height: 54%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1ca9d0;
  }
  .card-body h1 {
    font-size: 2.8em;
    font-weight: 600;
  }
  .card-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: solid #f5f5f5 0.125em;
    padding: 0.4em 0;
  }
  .card-footer h6 {
    color: #9ab1b6;
    font-size: 0.8em;
    font-weight: 500;
  }
`;
