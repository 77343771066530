import React, { useState } from "react";

import api from "~/services/api";
import CreateEditContent from "./CreateEditContent";
import ShowContent from "./ShowContent";
import { SLCreateModal, SLShowModal, SLEditModal } from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import TableComponent from "./TableComponent";

function NivelQuestoes() {
  const [mountedData, setMountedData] = useState();

  return (
    <Container
      requests={{
        findAll: () => api.get("admin/questaoniveis"),
        deleteOne: (thisData) => api.delete("admin/questaonivel", { data: { cod: thisData.cod } }),
        findOne: (thisData) => api.get("admin/questaonivel", { params: { cod: thisData.cod } }),
      }}
    >
      <TableComponent />

      <SLShowModal title="Nível da Questão" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLEditModal title="Atualizar Nível da Questão" setter={setMountedData}>
        <CreateEditContent {...{ mountedData }} />
      </SLEditModal>

      <SLCreateModal title="Novo Nível da Questão">
        <CreateEditContent />
      </SLCreateModal>
    </Container>
  );
}

export default NivelQuestoes;
