import React from "react";
import acessos from "~/pages/Sistema/acessos";

import { ThisButton } from "./styles";

function MenuButton({ tipo, selected, setComponentPath, menu, modulo }) {
  return (
    <ThisButton
      selected={selected}
      onClick={() => {
        const Componente = acessos[tipo]?.[modulo?.path]?.[menu?.path];

        if (typeof Componente === "function") {
          setComponentPath({
            moduloPath: modulo?.path,
            menuPath: menu?.path,
          });
        }
      }}
    >
      <i className="material-icons">{menu.icone}</i>
      <p className="acessoNome sub-menu">{menu.nome}</p>
    </ThisButton>
  );
}

export default MenuButton;
