import styled from "styled-components";

export const Container = styled.div`
  color: #293f57;
  max-width: 100%;
  border-bottom: solid 0.1em #f5f5f5;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  margin-bottom: 0.25em;

  .SistemaList_ModalHeader_closeButton {
    position: absolute;
    top: 0.35em;
    right: 0;
  }
`;
