import React from "react";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import Label from "~/componentes/CustomForm/Label";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import DadosMatricula from "~/componentes/DadosMatricula";

import { Container } from "./styles";

function ChangeDados({ grupoDados, mountedData }) {
  const [value, setValue] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);

  return (
    <Label
      title="Requisição de atuaização de dados"
      className="col s12 m12 l12"
    >
      {showModal && (
        <Container
          outClick={() => setShowModal(false)}
          scrollBar
          title="Requisição para atualização de dados"
        >
          <DadosMatricula
            readOnly
            grupos={grupoDados}
            defaultValue={mountedData?.atualizacaoDados}
          />
        </Container>
      )}
      <div className="Label_withButton">
        <SistemaSelect
          value={value}
          onChange={(e) => {
            const thisVal = e.currentTarget.value;
            setValue(thisVal);
          }}
          name="acceptAtualizacaoDados"
          firstOption
        >
          <option value="true">Aceitar Requisição</option>
          <option value="false">Negar Requisição</option>
        </SistemaSelect>
        <ColoredButton
          onClick={() => setShowModal(!showModal)}
          type="button"
          yellowLayout
          materialIcon="visibility"
        />
      </div>
      <div className="Matriculas_ShowContent_div_avisos">
        {value === "true" && (
          <p>Se aceito, irá alterar os dados atuais da matrícula.</p>
        )}
        {value === "false" && (
          <p>
            Se rejeitado, o pedido será cancelado e não será mais visto a menos
            que outro seja feito.
          </p>
        )}
      </div>
    </Label>
  );
}

export default ChangeDados;
