/* eslint-disable eqeqeq */
import React from "react";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import SistemaModalFooter from "~/componentes/Modal/Styleds/Footers/SistemaModalFooter";
import { SLForm } from "~/pages/Sistema/componentes/SistemaList";
import api from "~/services/api";
import { Materialize } from "~/styles/materializeGrid";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import ChangeDados from "./ChangeDados";
import { Container } from "./styles";

function Pendencias({ mountedData, series, grupoDados, ...rest }) {
  const [turmaCod, setTurmaCod] = React.useState();
  const [etapa, setEtapa] = React.useState();

  const turmas = React.useMemo(() => {
    const thisTurmas =
      series?.find((e) => e?.cod === mountedData?.serieCod)?.turmas || [];

    return thisTurmas.filter((e) => e?.vagasDisponiveis > 0);
  }, [mountedData, series]);

  async function handleSubmit(data, options) {
    const { closeReload } = options || {};

    await ShowConfirmacao(
      async () => {
        const response = await api.put("filial/gestaoMatriculas/matricula", {
          ...data,
          cod: mountedData?.cod,
          ...(etapa != 0 ? { turmaCod } : {}),
        });

        if (closeReload && response?.status === 200) {
          closeReload();
        }

        return response;
      },
      {
        message: "Tem certeza que deseja alterar essa matrícula?",
        successModal: "Matricula alterada com sucesso.",
      }
    );
  }

  return (
    <Container title="Matrícula" {...rest}>
      <SLForm onSubmit={handleSubmit}>
        <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
          <Materialize>
            <div className="row">
              {mountedData?.atualizacaoDados && (
                <ChangeDados {...{ grupoDados, mountedData }} />
              )}
              <Label title="Etapa" className="col s12 m12 l12">
                {mountedData?.etapa == 2 ? (
                  <SistemaInput value="Matriculado" readOnly notForm />
                ) : (
                  <SistemaSelect
                    name="etapa"
                    // firstOption
                    setter={setEtapa}
                    defaultValue={String(mountedData?.etapa)}
                    className="col s12 m12 l12"
                  >
                    <option value="0">Pendente</option>
                    <option value="1">Aceito</option>
                    <option value="2">Finalizado</option>
                  </SistemaSelect>
                )}
                <div className="Matriculas_ShowContent_div_avisos">
                  {etapa == 1 && (
                    <p>
                      Aceito: Ocupará uma vaga da turma, porém, não efetuará a
                      matrícula. Útil para aguardar o pagamento da matrícula.
                    </p>
                  )}
                  {etapa == 2 && (
                    <p>
                      Finalizado: Ocupará uma vaga da turma, e efetuará a
                      matrícula do aluno. Uma vez nessa etapa, a matrícula não
                      pode mais ser alterada de atapas ou de turma.
                    </p>
                  )}
                </div>
              </Label>
              {etapa != 0 && (
                <Label
                  title="Turma"
                  className="col s12 m12 l12"
                  text={
                    mountedData?.etapa == 2 &&
                    turmas?.find((e) => e.cod == mountedData?.turmaCod)?.nome
                  }
                >
                  {mountedData?.etapa != 2 && (
                    <SistemaSelect
                      firstOption
                      required
                      value={turmaCod}
                      defaultValue={mountedData?.turmaCod}
                      onChange={(e) => {
                        const thisVal = e.currentTarget.value;
                        setTurmaCod(thisVal);
                      }}
                      list={{ list: turmas, value: "cod", label: "nome" }}
                    />
                  )}
                </Label>
              )}
              {etapa == 2 && (
                <Label title="Matricula:" className="col s12 m12 l12">
                  <SistemaInput
                    required
                    name="matricula"
                    defaultValue={mountedData?.matricula}
                  />
                </Label>
              )}
              <Label title="Pendencias" className="col s12 m12 l12">
                <SistemaTextArea
                  name="pendencias"
                  defaultValue={mountedData?.pendencias}
                />
              </Label>
            </div>
          </Materialize>
        </CustomScrollBar>
        <SistemaModalFooter />
      </SLForm>
    </Container>
  );
}

export default Pendencias;
