import React from "react";

import { Container } from "./styles";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import sistemaListStore, {
  sistemaListShowModal,
} from "../../../ThisMainComponent/thisStore";
import { IsFunction } from "~/utils/CheckDataType";
import { useEffect } from "react";

function ThisModalFooter({
  closeCondition = true,
  children,
  closeModalSetter,
  cancelButton,
  submitButton,
  ...rest
}) {
  const { dispatch } = React.useContext(sistemaListStore);

  useEffect(() => {
    IsFunction(closeModalSetter) &&
      closeModalSetter(() => dispatch(sistemaListShowModal(false)));
  }, [closeModalSetter, dispatch]);

  return (
    <Container {...rest}>
      {cancelButton !== false && (
        <ColoredButton
          redLayout
          small
          type="button"
          {...cancelButton}
          onClick={() => {
            if (cancelButton?.onClick) {
              cancelButton.onClick(() => dispatch(sistemaListShowModal(false)));
            } else {
              closeCondition && dispatch(sistemaListShowModal(false));
            }
          }}
        >
          {cancelButton?.text || "Cancelar"}
        </ColoredButton>
      )}
      {children}
      {submitButton !== false && (
        <ColoredButton type="submit" greenLayout small {...submitButton}>
          {submitButton?.text || "Confirmar"}
        </ColoredButton>
      )}
    </Container>
  );
}

export default ThisModalFooter;
