import styled from "styled-components";
import TextArea from "./index";

export const SistemaTextArea = styled(TextArea).attrs((props) => ({
  ...props,
  as: props?.as || props?.readOnly ? "textarea" : TextArea,
}))`
  font-size: 1em;
  line-height: 1.25;
  border-radius: 0.3em;
  border: 0.1em solid #cedde0;

  align-self: center;
  padding: 0.25em 0.5em;
  width: 10em;
  height: 5em;
  max-width: 100%;

  ::-webkit-scrollbar {
    width: 0.5em;
  }

  ::-webkit-scrollbar-track {
    :hover {
      width: 0.75em;
      cursor: pointer;
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 0.1em solid slategrey;
    border-radius: 0.5em;
  }

  &:read-only {
    border: 0.1em solid #e4e4e4;
    background: #ededed;
  }
`;
