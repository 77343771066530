import React from "react";
import objetiveIcon from "~/assets/logos/iconObjetiveTI.png";

import { Container, Grid, Footer } from "./styles";

export default function Nuvem() {
  return (
    <Container>
      {/* &nbsp; */}
      <Footer>
        <Grid>
          <div className="objetive">
            <img className="objetiveLogo" src={objetiveIcon} alt="Objetive TI Logo" />
            <p>
              A Objetive TI é uma empresa fundada e situada na cidade de Fortaleza-CE, formada por profissionais capacitados nas mais diversas áreas
              da Tecnologia, tais como implantação e consultoria de sistemas, desenvovimento e infraestrutura.
            </p>
          </div>
          <div className="listas">
            {/* <div className="mapa">
              <strong>Mapa do Site</strong>
              <br />
              <br />
              <div>
                <ul>
                  <li>Matrícula</li>
                  <li>Login</li>
                  <li>Sobre</li>
                  <li>FAQ</li>
                </ul>
                <ul>
                  <li>Blog</li>
                  <li>Contatos</li>
                  <li>Planos</li>
                  <li>Vantagens</li>
                </ul>
              </div>
            </div> */}
            <div className="local">
              <strong>Objetive TI</strong>
              <br />
              <br />
              <ul>
                <li>
                  <p>Objetive TI</p>
                  <p>R. NUNES VALENTE 1901</p>
                  <p>FORTALEZA - CE - 60125-035</p>
                  <p>CONTATO@OBJETIVETI.COM.BR</p>
                  <p>---</p>
                  <p>(85)3047-7001</p>
                </li>
              </ul>
            </div>
          </div>
        </Grid>
        <p className="copyRight">&copy; 2022 Copyright - Objetive TI</p>
      </Footer>
    </Container>
  );
}
