/* eslint-disable eqeqeq */
import React from "react";

import { Container, MaterializeContent } from "./styles";
import FirstSection from "./FirstSection";
import ThisTable from "./ThisTable";
import Pagination from "~/componentes/Pagination";
import ThisFilter from "./ThisFilter";
import ThisPdfButton from "./ThisPdfButton";
import ThisExcelButton from "./ThisExcelButton";

function Disciplinas() {
  const [data, setData] = React.useState();
  const [avaliacoesTurma, setAvaliacoesTurma] = React.useState([]);

  return (
    <Container>
      <ThisFilter {...{ setData }} />

      <FirstSection {...{ data }} />
      <div className="table-content">
        <ThisTable {...{ data, avaliacoesTurma }} />

        <div className="table-action-buttons">
          <MaterializeContent>
            <div className="row">
              <div className="col s12 m12 l6 paginator">
                <Pagination
                  data={data?.avaliacoesDisponibilizadas}
                  getInfo={({ pageData }) => {
                    setAvaliacoesTurma(pageData);
                  }}
                  size={4}
                />
              </div>
              <div className="col s12 m12 l6 actions-paginator">
                <ThisPdfButton {...{ data, avaliacoesTurma }} />
                <ThisExcelButton {...{ data, avaliacoesTurma }} />
              </div>
            </div>
          </MaterializeContent>
        </div>
      </div>
    </Container>
  );
}

export default Disciplinas;
