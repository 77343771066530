import styled from "styled-components";
import TextContent from "~/componentes/TextContent";

export const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;

  .ColoredFileInput_Container_button {
    margin: -0.4em 0.5em 0 0;
    height: 2em;
  }

  .ColoredFileInput_Container_icons {
    display: flex;
    align-items: center;
    margin-left: 0.5em;

    i {
      color: #7b898c;
      font-size: 1.5em;
    }
  }
`;

export const FileName = styled(TextContent)`
  border: solid 0.15em ${({ blueLayout }) => (blueLayout ? "#1f3346" : "#cedde0")};
  width: 100%;
  max-width: 100%;
  border-radius: 0.5em;
  padding-left: 0.5em;

  ${({ blueLayout }) =>
    blueLayout &&
    `
    background-color: #256172;
    color: #fff !important;
  `}
`;
