import styled from "styled-components";
import { Form } from "~/componentes/CustomForm";

export const Container = styled(Form)`
  min-width: 280px;
  width: 20em;
  max-width: 90%;
  background: #fff;
  border-radius: 1em;
  position: relative;
  padding: 1.5em;
  margin: auto;

  h1 {
    text-align: center;
    color: #32495f;
    font-size: 1.4em;
    margin-bottom: 0.5em;
  }

  .form-separador {
    width: 100%;
    position: relative;
    z-index: 0;
    margin: 1.5em 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .form-separador::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.185em;
    z-index: -1;
    background: #eeeeee;
  }
  .form-separador h5 {
    background: #fff;
    position: absolute;
    text-transform: uppercase;
    padding: 0 1em;
    font-weight: 600;
    font-size: 0.8em;
    color: #999e9f;
  }

  .botao {
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    button {
      width: 100%;
    }
    button:nth-child(2) {
      margin-bottom: 1.2em;
      margin-top: 0.8em;
    }
  }
`;

export const ModalCentral = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const VideoChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: 85vh;
  width: inherit;
`;

export const RemoteVideoChat = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 23vh;
  width: inherit;
  overflow: auto;
`;

export const LocalVideoChat = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  //position: absolute;
  
  width: 11rem;
  height: 8rem;

  right: 0;
  bottom: 0;
  border-radius: 10px;
  box-shadow: 2px 2px 10px 0px black;
  margin-bottom: 22px;

  video{
    width: inherit;
    height: inherit;
    border-radius: 10px;
    object-fit: cover;
  }

  img{
    width: 50px;
  }
`;

export const RemoteVideo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  
  width: 11rem;
  height: 7rem;

  border-radius: 10px;
  box-shadow: 2px 2px 10px 0px black;
  margin-inline: 10px;
  margin-block: 5px;

  video{
    width: inherit;
    height: inherit;
    border-radius: 10px;
    object-fit: cover;
  }

  img{
    width: 50px;
  }

  &:hover {
    #description-remotevideo{
      opacity: 1;
    }
  }
`;

export const RemoteVideoPrimary = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 60vh;
  flex-wrap: wrap;
  margin-bottom: 15px;
  border-radius: 10px;

  video{
    width: inherit;
    border-radius: 10px;
  }
`;

export const ContainerVideos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  //height: 23vh;
  width: inherit;
  //overflow: auto;
`;

export const AsideContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 17rem;
  height: 60vh;
  background: #fff;
  margin-left: 1em;
  border-radius: 25px;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 850px) { 
    display: none;
  }
`;

export const AsideCode = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;

  p{
    font-size: 1.1em;
  }
`;

export const TeacherVideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  position: relative;
  //max-width: 525px;
  background: black;

  &:hover {
    #description{
      opacity: 1;
    }
  }

  video {
    width: 100%;
    height: 100%;
    //object-fit: fill;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 315px) and (max-width: 576px) { 
    width: 100vw;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) { ... }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) { ... }

  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 
    width: 650px;
  }

  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    min-width: 750px;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  right: -1px;
  opacity: 0;
  transition: opacity .15s ease-in-out;

  @media (max-width: 375px) { 
    justify-content: center;
  }
`;

export const Avatar = styled.span`
  background: #ff4031;
  padding: 5px 12px;
  border-radius: 100%;
  color: #fff;
`;

export const Name = styled.p`
  font-size: 1em;
`;

export const Description = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 10px;
  gap: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;

  @media (max-width: 375px) { 
    display: none;
  }
`;

export const Actions = styled.div`
  background: #fff;
  padding: 5px 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;

  i{
    cursor: pointer;
  }

  #disconnectIcon{
    border-radius: 100%;
    padding: 10px;
    background: #ff4031;
    color: #fff;
  }

  @media (max-width: 375px) { 
    border-radius: 10px;
    margin-bottom: 10px;
  }
`;

export const VideoCamPaused = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  background: #000;
  border-radius: 10px;
  width: inherit;

  
  /* @media (min-width: 315px) and (max-width: 576px) { 
    width: inherit;
  }

  @media (min-width: 577px) and (max-width: 1200px) { 
    width: 750px;
  }

  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1200px) { 
    min-width: 750px;
  } */
`;

export const PlaceholderVideoCam = styled.span`
  padding: 30px 45px;
  background: #333;
  border-radius: 100%;
  color: #fff;
  font-size: 1.8rem;
`;

export const DescriptionRemoteVideo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 5px;
  gap: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity .15s ease-in-out;

  span{
    background: #ff4031;
    padding: 5px 10px;
    border-radius: 100%;
    color: #fff;
    font-size: 0.7em;
  }
  p{
    font-size: 0.7em;
  }
`;

export const Separator = styled.div`
  width: 30px;
  position: relative;
  z-index: 0;
  margin: 2em 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;

  &::before{
    content: "";
    position: absolute;
    width: 5rem;
    height: 0.185em;
    z-index: -1;
    background: #bbbb;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    margin-top: 20px;
  }
`;

export const AsideTeacherList = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  width: inherit;
  
  border-radius: 10px;

  h3 {
    margin-block: 10px;
  }

  #placeholderTeacher {
    margin-inline: 25px;
  }
`;

export const AsideTeacherListCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  width: -webkit-fill-available;
  margin-inline: 25px;

  i {
    cursor: pointer;
  }

  #AsideTeacherListCard-containerName{
    display: flex;
    gap: 10px;
    align-items: center;
  }

`;