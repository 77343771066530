import { takeLatest, call, put, all } from "redux-saga/effects";
import api from "~/services/api";
import { updatePerfilSuccess, updatePerfilFailure } from "./actions";

export function* updatePerfil({ payload }) {
  try {
    const { name, email, avatar_id, ...rest } = payload.data;

    const perfil = Object.assign(
      { name, email, avatar_id },
      rest.oldPassword ? rest : {}
    );

    const response = yield call(api.put, "users", perfil);

    yield put(updatePerfilSuccess(response.data));
  } catch (err) {
    yield put(updatePerfilFailure());
  }
}

export default all([takeLatest("@user/UPDATE_PERFIL_REQUEST", updatePerfil)]);
