function getYoutubeEmbebedUrl(urlToParse) {
  if (typeof urlToParse === "string") {
    try {
      let videoId = new URL(urlToParse)?.searchParams?.get?.("v");

      if (!videoId) {
        videoId = urlToParse.substring(urlToParse.lastIndexOf("/") + 1);
      }

      return { videoId, embebedUrl: videoId ? `https://www.youtube.com/embed/${videoId}` : undefined };
    } catch {}
  }
  return { videoId: undefined, embebedUrl: undefined };
}

export default getYoutubeEmbebedUrl;
