import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { Container, SubTitle } from "./styles";
import { Materialize } from "~/styles/materializeGrid";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import SistemaTable from "~/componentes/StyledComponents/Tables/SistemaTable";

function ShowContent({ mountedData }) {
  return (
    <Container>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          <div className="row">
            <Label
              title="NOME:"
              text={mountedData?.turma?.nome || ""}
              className="col s12 m12 l3"
            />
            <Label
              title="SÉRIE:"
              text={mountedData?.turma?.serie?.nome || ""}
              className="col s12 m12 l3"
            />
            <Label
              title="PERÍODO LETIVO:"
              text={mountedData?.turma?.periodoLetivo?.nome || ""}
              className="col s12 m12 l3"
            />
            <Label
              title="DISCIPLINA:"
              text={mountedData?.disciplinaFilial?.nome || ""}
              className="col s12 m12 l3"
            />
            <div className="col s12 m12 l12">
              <SubTitle>Alunos</SubTitle>
              <SistemaTable>
                <thead>
                  <tr>
                    <th>Matricula</th>
                    <th>Nome</th>
                    <th>Email</th>
                    <th>Telefone</th>
                  </tr>
                </thead>
                <tbody>
                  {mountedData?.alunoDisciplinaTurma?.map((t, i) => (
                    <tr key={t?.alunoTurma?.aluno?.cod || i}>
                      <td>{t?.alunoTurma?.aluno?.matricula}</td>
                      <td>{t?.alunoTurma?.aluno?.usuario?.nome}</td>
                      <td>{t?.alunoTurma?.aluno?.usuario?.email}</td>
                      <td>
                        {t?.alunoTurma?.aluno?.usuario?.telefone1
                          ? t?.alunoTurma?.aluno?.usuario?.telefone1
                          : ""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </SistemaTable>
            </div>
          </div>
        </Materialize>
      </CustomScrollBar>
    </Container>
  );
}

export default ShowContent;
