import React from "react";
import gestaoImage from "~/assets/figuras/multiplataforma_amarelo.png";
import gamificacaoImage from "~/assets/figuras/mobile_game_azul.png";
import acompanhamentoImage from "~/assets/figuras/desktop_estatistica_verde.png";

import { Container, H1 } from "./styles";
import UserDevice from "~/componentes/UserDevice";

export default function Vantagens() {
  const userDevice = UserDevice();
  const { desktop, rotatedTablet } = userDevice;

  const botaoEsquerda = !rotatedTablet && !desktop && (
    <button className="material-icons" type="button">
      chevron_left
    </button>
  );

  const botaoDireita = !rotatedTablet && !desktop && (
    <button className="material-icons" type="button">
      chevron_right
    </button>
  );

  const gestao = (
    <div className="card">
      <H1>Principais Vantagens</H1>
      <div className="card-content">
        <img src={gestaoImage} alt="Gestão" />
        <strong>Gestão</strong>
        <p>
          Apoio que faltava para gerenciar o ensino por uma plataforma web,
          apoiando o ensino presencial e à distância.
        </p>
      </div>
    </div>
  );

  const gamificacao = (
    <div className="card">
      <H1>Principais Vantagens</H1>
      <div className="card-content">
        <img src={gamificacaoImage} alt="Gamificação" />
        <strong>Gamificação</strong>
        <p>
          É uma das apostas no século 21, o termo "gamificação" usado para o
          ensino é a forma de atrair e engajar o aluno motivando a resolução de
          problemas, tornando o aprendizado mais atrativo.
        </p>
      </div>
    </div>
  );

  const acompanhamento = (
    <div className="card">
      <H1>Principais Vantagens</H1>
      <div className="card-content">
        <img src={acompanhamentoImage} alt="Acompanhamento" />
        <strong>Acompanhamento</strong>
        <p>
          A plataforma contém relatórios de acompanhamento para instituições de
          ensino, professores e responsáveis além de aproximar a escola à
          família.
        </p>
      </div>
    </div>
  );
  // leftButton={botaoEsquerda} rightButton={botaoDireita}
  return (
    <Container
      desktop={desktop}
      rotatedTablet={rotatedTablet}
      leftButton={botaoEsquerda}
      rightButton={botaoDireita}
    >
      {gestao}
      {gamificacao}
      {acompanhamento}
    </Container>
  );
}
