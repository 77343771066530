import React, { useEffect, useState } from "react";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";

function CustomListSearch({ inputs, setParentState, ...rest }) {
  const [selectInput, setSelectInput] = useState(Array.isArray(inputs) && inputs.length > 0 && inputs[0].name);

  const [inputValue, setInputValue] = useState();

  useEffect(() => {
    setParentState && setParentState({ [selectInput]: inputValue || "" });
  }, [inputValue, selectInput, setParentState]);

  return (
    <div className="CustomTable_Filter_Search" {...rest}>
      {Array.isArray(inputs) && (
        <>
          {inputs.length > 1 && (
            <Label title="Selecione">
              <div style={{ marginRight: "0.5em" }}>
                <SistemaSelect
                  className="CustomTable_Filter_Search_select"
                  defaultValue={selectInput}
                  onChange={e => e.target.value && setSelectInput(e.target.value)}
                  list={{ list: inputs, value: "name", label: "label" }}
                />
              </div>
            </Label>
          )}
          {inputs.length > 0 && (
            <Label title="PESQUISAR">
              <SistemaInput
                className="CustomTable_Filter_Search_input"
                type="text"
                onChange={e => setInputValue(e.target.value)}
              />
            </Label>
          )}
        </>
      )}
    </div>
  );
}

export default CustomListSearch;
