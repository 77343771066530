import React from "react";
import api from "~/services/api";
import ShowConfirmacao from "~/utils/ShowConfirmacao";

import { Container } from "./styles";
import { useState } from "react";
import DisciplinasFilialList from "../DisciplinasFilialList";

function EditContent({ mountedData }) {
  const [disciplinasFilial, setDisciplinasFilial] = useState([]);

  function handleSubmit(data, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let response = await api.put("filial/professor", {
          ...data,
          disciplinasFilial,
          cod: mountedData.cod,
        });

        if (response?.status === 200 && closeReload) {
          closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao atualizar esse professor, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja atualizar esse professor?`,
        successModal: `Professor atualizado com sucesso!`,
      }
    );
  }

  return (
    <Container defaultStyle onSubmit={handleSubmit}>
      <DisciplinasFilialList
        setter={setDisciplinasFilial}
        defaultData={mountedData?.disciplinasFilial}
      />
    </Container>
  );
}

export default EditContent;
