import produce from "immer";
import CustomToast from "~/componentes/CustomToast";
import api from "~/services/api";

const INITIAL_STATE = {
  matriculas: null,
  token: null,
  logado: false,
  preferencias: false,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@auth/SIGN_IN_SUCCESS": {
        const { matriculas, matricula } = action.payload || {};
        api.defaults.headers.Authorization = `Bearer ${matricula.token}`;
        draft.matriculas = matriculas;
        draft.token = matricula.token;
        draft.logado = true;
        draft.preferencias = {
          tipo: matricula?.tipo,
          matriculaCod: matricula?.matriculaCod,
          isProprietario: matricula?.isProprietario,
        };
        break;
      }
      case "@auth/CHANGE_TOKEN": {
        const { matricula } = action.payload || {};
        api.defaults.headers.Authorization = `Bearer ${matricula.token}`;
        draft.token = action.payload.matricula.token;
        draft.preferencias = {
          tipo: matricula?.tipo,
          matriculaCod: matricula?.matriculaCod,
          isProprietario: matricula?.isProprietario,
        };
        break;
      }
      case "@auth/SIGN_IN_FAILURE": {
        const { err } = action.payload || {};
        api.defaults.headers.Authorization = "";
        draft.matriculas = null;
        draft.token = null;
        draft.logado = false;
        if (action?.payload?.preferencias) {
          draft.preferencias = action.payload.preferencias;
        }

        if(err.error){
          if(err.error == "A senha está incorreta" || err.error == "Usuário não foi encontrado"){
            CustomToast.error(
              "Email ou senha incorretos."
            );
          } else {
            CustomToast.error(
              err.error
            );
          }
        } else {
          CustomToast.error(
            "Falha ao tentar entrar no sistema. Verifique o email e a senha."
          );
        }

        break;
      }
      case "@auth/SIGN_OUT": {
        draft.matriculas = null;
        draft.token = null;
        draft.logado = false;
        break;
      }
      default:
    }
  });
}
