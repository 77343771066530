import React from "react";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";
import SistemaTopico from "~/componentes/StyledComponents/Textos/SistemaTopico";
import { Materialize } from "~/styles/materializeGrid";

function FilterModal({ outClick, tabFilter, setTabFilter }) {
  const [state, setState] = React.useState();

  return (
    <SistemaFixedModal
      footerButtons={{
        submitButton: {
          type: "button",
          onClick: (closeModal) => {
            setTabFilter((prev) => {
              return {
                ...prev,
                createdAtDate: (value) => {
                  let result = true;
                  const valueDate = value && new Date(value).getUTCMonth();
                  if (typeof valueDate === "number" && typeof state === "number" && state !== valueDate) {
                    result = false;
                  }

                  return result;
                },
              };
            });
            if (typeof closeModal === "function") {
              closeModal();
            }
          },
        },
      }}
      style={{ width: "30em" }}
      title="FILTRO"
      outClick={outClick}
    >
      <Materialize>
        <div className="row">
          <SistemaTopico title="Data" />
          <Label title="Data de criação:" className="col s12 m12 l12">
            <SistemaInput
              onChange={(e) => {
                const thisVal = e.currentTarget.value;
                setState(thisVal ? new Date(thisVal).getUTCMonth() : thisVal);
              }}
              type="month"
            />
          </Label>
          {/* <Label title="Até:" className="col s6 m6 l6">
            <SistemaInput
              onChange={(e) => {
                const thisVal = e.currentTarget.value;
                setState((prev) => ({ ...prev, para: thisVal }));
              }}
              type="date"
            />
          </Label> */}
        </div>
      </Materialize>
    </SistemaFixedModal>
  );
}

export default FilterModal;
