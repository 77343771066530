import React from "react";

import { Container } from "./styles";
// import api from "~/services/api";

export default function DesempenhoAluno() {
  // React.useEffect(() => {
  //   api
  //     .get("/padrao/acompanhamentoEscolar/comunicadoEscolar/responsavelAlunos")
  //     .then((r) => r?.data);
  // }, []);
  return <Container>DesempenhoAluno</Container>;
}
