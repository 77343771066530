import styled from "styled-components";
import Label from "~/componentes/CustomForm/Label";

export const Container = styled.div`
  .RespostaObjetiva_h4_naoRespondido {
    text-align: center;
  }
`;

export const QuestaoItem = styled(Label)`
  textarea {
    resize: none;
    height: auto;

    ${({ marcado, correto, showResp }) => {
      let thisBorder = false;

      if (correto && showResp) {
        thisBorder = "#4FA754";
      } else if (marcado) {
        thisBorder = showResp ? "#FF4031" : "#FFDA44";
      }

      if (thisBorder) {
        return `border-color: ${thisBorder} !important;`;
      }
    }}
  }
`;
