import styled from "styled-components";

export const Container = styled.div`
  color: #333333;
  max-width: 100%;
  border-bottom: solid 0.1em #f5f5f5;
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;

  .SistemaModalHeader_h4_title {
    font-weight: bolder;
    font-size: 1.3em;
  }

  .SistemaList_ModalHeader_closeButton {
    color: #333333;
    position: absolute;
    top: 0.35em;
    right: 0;
  }
`;
