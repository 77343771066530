/* eslint-disable eqeqeq */
import React, { useState } from "react";

import { Container } from "./styles";
import Label from "~/componentes/CustomForm/Label";
import { EduquemeSelect } from "~/componentes/CustomForm/Select/styles";
import { Materialize } from "~/styles/materializeGrid";
import estados from "~/config/estados";

function handleBool(bool) {
  if (bool === false || bool === "false") {
    return false;
  } else if (bool === true || bool === "true") {
    return true;
  } else {
    return undefined;
  }
}

function handleNumber(numb) {
  const checkNumber = numb && numb !== "" && Number(numb);
  if (typeof numb === "number" || checkNumber) {
    return checkNumber;
  } else {
    return false;
  }
}

function ModalFilter({ filiais, setFilteredData, series, filters, setFilters, setModalFilter, ...rest }) {
  const [cidades, setCidades] = React.useState(filters?.estadoCod && estados?.find((e) => e?.cod == filters?.estadoCod)?.cidades);

  const [data, setData] = useState(filters);

  function handleDataFilter() {
    const ec = handleNumber(data?.estadoCod);
    const cc = handleNumber(data?.cidadeCod);
    const sc = handleNumber(data?.serieCod);
    const p = handleBool(data?.publico);

    const newFiliais = filiais?.filter((fil) => {
      if (ec && fil?.estadoCod !== ec) {
        return false;
      } else if (cc && fil?.cidadeCod !== cc) {
        return false;
      } else if ((p || p === false) && fil?.publico !== p) {
        return false;
      } else if (sc) {
        return fil?.periodosLetivos?.some((pl) => {
          return pl?.series?.find((s) => s?.cod === sc);
        });
      } else {
        return true;
      }
    });

    return newFiliais || [];
  }

  return (
    <Container
      footerButtons={{
        submitButton: {
          type: "button",
          onClick: (closeModal) => {
            setFilteredData(handleDataFilter());
            setFilters(data);
            closeModal && closeModal();
          },
        },
      }}
      title="Filtrar Escolas"
      outClick={setModalFilter}
      {...rest}
    >
      <Materialize>
        <div className="row">
          <Label title="Estado" className="col s12 m12 l6">
            <EduquemeSelect
              firstOption
              notForm
              list={{ list: estados, value: "cod", label: "nome" }}
              value={data?.estadoCod || ""}
              onChange={(e) => {
                const val = e.currentTarget.value;
                const thisFind = estados?.find((e) => e?.cod == val);

                setCidades(thisFind?.cidades);
                setData((prev) => ({
                  ...prev,
                  estadoCod: val,
                  cidadeCod: undefined,
                }));
              }}
            />
          </Label>
          <Label title="Cidade" className="col s12 m12 l6">
            <EduquemeSelect
              firstOption
              notForm
              value={data?.cidadeCod || ""}
              list={{ list: cidades, value: "cod", label: "nome" }}
              onChange={(e) => {
                const val = e.currentTarget.value;

                setData((prev) => ({
                  ...prev,
                  cidadeCod: val,
                }));
              }}
            />
          </Label>
          <Label title="Categoria" className="col s12 m12 l6">
            <EduquemeSelect
              firstOption
              notForm
              value={data?.publico || ""}
              onChange={(e) => {
                const val = e.currentTarget.value;

                setData((prev) => ({
                  ...prev,
                  publico: val,
                }));
              }}
            >
              <option value="true">Pública</option>
              <option value="false">Particular</option>
            </EduquemeSelect>
          </Label>
          <Label title="Série" className="col s12 m12 l6">
            <EduquemeSelect
              firstOption
              notForm
              value={data?.serieCod || ""}
              list={{ list: series, value: "cod", label: "nome" }}
              onChange={(e) => {
                const val = e.currentTarget.value;

                setData((prev) => ({
                  ...prev,
                  serieCod: val,
                }));
              }}
            />
          </Label>
        </div>
      </Materialize>
    </Container>
  );
}

export default ModalFilter;
