const INITIAL_STATE = {
  ativo: false,
  confirmado: false,
  key: false,
  data: false,
  message: "Tem certeza que deseja realizar esta ação?",
  request: false,
  response: false,
  errorModal: true,
};

export default function confirmacaoModal(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case "@confirmacaoModal/confirmacaoModalToggle": {
      return {
        ativo: !state.ativo,
        message: payload.message,
        key: payload.key,
        data: payload.data,
        request: payload.request,
        errorModal: payload.errorModal,
        successModal: payload.successModal,
        response: false,
        confirmado: false,
      };
    }
    case "@confirmacaoModal/responseModalToggle": {
      return { ...state, response: payload.response, ativo: false };
    }
    case "@confirmacaoModal/acceptConfirmacaoModalToggle": {
      return {
        ...state,
        confirmado: true,
      };
    }
    default: {
      return state;
    }
  }
}
