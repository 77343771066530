import React from "react";
import { CLEditComponent } from "~/pages/Sistema/componentes/CustomList";
import api from "~/services/api";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import Label from "~/componentes/CustomForm/Label";
import { useState } from "react";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import { CLForm } from "~/pages/Sistema/componentes/CustomList/CustomListStyledComponents";

function EditModal({ cargos }) {
  const [funcionario, setFuncionario] = useState();

  function handleSubmit(editedData, reload) {
    return ShowConfirmacao(
      async () => {
        const response = await api.put("filial/funcionarioFilial", {
          ...editedData,
          cod: funcionario.cod,
        });

        if (response?.status === 200) {
          reload();
        }

        return response;
      },
      {
        message: "Tem certeza que deseja atualizar esse funcionário?",
        successModal: "Funcionário atualizado com sucesso!",
      }
    );
  }

  return (
    <CLEditComponent
      title="Atualizar Funcionário"
      contentMount={setFuncionario}
      contentUnmount={() => {
        setFuncionario();
      }}
    >
      <CLForm defaultStyle onSubmit={handleSubmit}>
        <Label title="Cargos:">
          <SistemaSelect
            list={{ list: cargos, value: "cod", label: "nome" }}
            defaultValue={funcionario?.cargoFilialCod}
            name="cargoFilialCod"
            firstOption
          />
        </Label>
      </CLForm>
    </CLEditComponent>
  );
}

export default EditModal;
