import styled from "styled-components";

export const Container = styled.div`
  grid-area: ranking;
  background: #ffffff;
  box-shadow: 0 0.313em 0.938em rgba(0, 0, 0, 0.1);
  border-radius: 0.375em;
  height: auto;
  padding-bottom: 0.5em;

  .ranking-header {
    border-bottom: solid #f5f5f5 0.125em;
    margin: 0 1em;
    padding: 0.5em 0;

    h5 {
      font-style: normal;
      font-weight: 600;
      font-size: 1.175em;
      line-height: 1.625em;
      color: #293f57;
    }
  }

  ul {
    padding-left: 2em;
    padding-right: 1.75em;
    list-style: none;
    position: relative;

    li:first-child {
      margin-top: 0.5em;
    }

    li {
      width: 100%;
      min-height: 4em;
      /* margin-bottom: 0.5em;
      margin-top: 0.5em; */
      padding: inherit 2em;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: solid #1ca9d0 0.125em;
      border-top: none;
      padding: 1em 1em 1em 1.5em;

      h4 {
        margin: 0 0 0em 0.4em;
        font-style: normal;
        font-weight: 600;
        font-size: 1em;
        color: #293f57;
        position: relative;
      }

      .star-field {
        display: flex;
        margin-right: 0.5em;

        h6 {
          margin-left: 0.4em;
          font-size: 0.8em;
          color: #293f57;
        }
      }

      .RankingAlunos_img_astronauta {
        width: 3em;
        height: 3em;
        position: absolute;
        left: -1.5em;
      }

      .ball-small {
        width: 2em;
        height: 2em;
        position: absolute;
        right: -1em;
        background: #1ca9d0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 50%;
      }
    }
  }
`;
