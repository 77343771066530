import React, { useState } from "react";
import api from "~/services/api";
import Label from "~/componentes/CustomForm/Label";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";

import { Container } from "./styles";
import AcessosList from "~/componentes/AcessosList";
import ThisFindOrCreateUser from "~/pages/Sistema/componentes/SistemaList/ThisStyledComponents/ThisModalComponents/ThisFindOrCreateUser";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import { Materialize } from "~/styles/materializeGrid";
import { SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import ShowConfirmacao from "~/utils/ShowConfirmacao";

function CreateContent({ modulos, cargos }) {
  const [disabledData, setDisabledData] = useState([]);
  const [usuario, setUsuario] = useState();

  async function handleDisabledList(data) {
    if (data) {
      const thisAcessos = cargos.find((e) => e.cod === data)?.acessos;
      Array.isArray(thisAcessos) && setDisabledData(thisAcessos);
    }
  }

  async function handleSubmit(data, options) {
    const { closeReload } = options || {};

    ShowConfirmacao(
      async () => {
        let response;

        response = await api.post("admin/administrador", {
          ...data,
          usuario,
          usuarioCod: usuario?.cod,
        });

        if (response?.status === 200) {
          closeReload && closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao cadastrar esse Funcionário, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja cadastrar essa funcionário?`,
        successModal: `Funcionário cadastrado com sucesso!`,
      }
    );
  }

  return (
    <Container onSubmit={handleSubmit}>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          <ThisFindOrCreateUser setter={setUsuario} />
          <div className="row">
            <Label title="CARGO" className="col s12 m12 l12">
              <SistemaSelect
                isNumber
                list={{ list: cargos, value: "cod", label: "nome" }}
                setter={handleDisabledList}
                name="cargoCod"
                firstOption
              />
            </Label>
            <AcessosList
              reloadDependency={disabledData}
              defaultDisabledData={disabledData}
              modulos={modulos}
              className="col s12 m12 l12"
            />
          </div>
        </Materialize>
      </CustomScrollBar>
      <SLModalFooter />
    </Container>
  );
}

export default CreateContent;
