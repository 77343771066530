import styled from "styled-components";
import { Button } from "~/componentes/CustomForm";

export const Pages = styled.div`
  color: #979797;
  display: flex;
  align-items: center;
  padding: 0.5em;

  ${(props) => props.mobile && "justify-content: center;"}

  .CustomTable_Table_p_pageSummary {
    min-width: ${(props) =>
      props.summarydigits > 2 ? `${props.summarydigits * 0.4 + 3.6}em` : "4.4em"};
    font-size: 0.9em;

    ${(props) => props.mobile && "text-align: center;"}
  }
`;

export const PageButtonNumber = styled(Button)`
  background: none;
  border: none;
  font-size: 1.1em;
  color: ${(props) => (props.ativo ? "#293F57" : "#979797")};
  min-width: ${(props) =>
    props.numberdigits > 2 ? `${props.numberdigits * 0.4 + 2}em` : "2.4em"};

  :hover {
    color: #293f57;
  }
`;

export const PageButtonIcon = styled(Button)`
  background: none;
  border: none;
  margin-top: 0.1em;
  color: #979797;
  font-size: 1.1em;

  :hover {
    color: #293f57;
  }
`;
