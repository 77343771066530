import styled from "styled-components";
import { SLTable } from "~/pages/Sistema/componentes/SistemaList";

export const ThisTable = styled(SLTable)`
  table-layout: fixed;

  th,
  td {
    padding-right: 0;
  }

  td:nth-child(2),
  th:nth-child(2) {
    text-align: center;
    width: 15em;
  }

  td:nth-child(3),
  th:nth-child(3) {
    text-align: center;
    width: 20em;
  }

  td:first-child,
  th:first-child {
    width: calc(100% - 30em);
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
