import React, { useEffect, useState } from "react";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import Label from "~/componentes/CustomForm/Label";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";

// import { Container } from './styles';

function InputCliente({ clienteSelected, setClienteSelected }) {

  return (
    <SistemaInput
      name="usuarioId"
      defaultValue={clienteSelected}
      setter={(cliente) => setClienteSelected(cliente || "")}
      required
    />
  );
}

export default InputCliente;
