/* eslint-disable eqeqeq */
import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { Container } from "./styles";
import { Materialize } from "~/styles/materializeGrid";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import api from "~/services/api";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import ColoredFileInput from "~/componentes/CustomForm/FileInput/styles/ColoredFileInput";
import AulasDisciplinaTurmaTable from "../AulasDisciplinaTurmaTable";
import AulaLinks from "../AulaLinks";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import ShowError from "~/utils/ShowError";
import getYoutubeEmbebedUrl from "~/utils/getYoutubeEmbebedUrl";

function CreateContent({ periodosLetivos }) {
  const [aulasDisciplinasTurmas, setAulasDisciplinasTurmas] = React.useState([]);
  const [links, setLinks] = React.useState([]);

  /**
   * @param {FormData} data
   * @param {{closeReload: () => {})}} options
   */
  async function handleSubmit(data, options) {
    const { closeReload } = options || {};

    function addData(key, val) {
      if (Array.isArray(val) && val.length > 0);
      try {
        const thisData = JSON.stringify(val);
        data.set(key, thisData);
      } catch {}
    }

    let invalid = false;
    const thisLinks = links?.filter((e) => {
      if (typeof e?.link === "string" && e.link.length > 0) {
        const linkId = getYoutubeEmbebedUrl(e?.link).videoId;

        if (!linkId) {
          invalid = true;
        }
        return !!linkId;
      }
      return false;
    });

    if (invalid) {
      return ShowError("Link do video inválido.");
    } else {
      addData("links", thisLinks);
      addData("aulasDisciplinasTurmas", aulasDisciplinasTurmas);

      if (data?.get?.("reutilizavel") !== "true" && !(aulasDisciplinasTurmas.length > 0)) {
        ShowError("Informa as disciplinas e turmas.");
      } else {
        ShowConfirmacao(
          async () =>
            await api.post("professor/gestaoDisciplinas/aula", data).then((r) => {
              if (r?.status === 200 && typeof closeReload === "function") {
                closeReload();
              }
              return r;
            }),
          {
            successModal: "Aula criada com sucesso",
            errorModal: {
              defaultMessage: "Falha ao cria a aula, por favor tente novamente.",
            },
            message: "Tem certeza que deseja criar essa aula?",
          }
        );
      }
    }
  }

  return (
    <Container isFormData defaultStyle onSubmit={handleSubmit}>
      <Materialize>
        <div className="row">
          <Label title="Título: *" className="col s12 m12 l12">
            <SistemaInput required name="titulo" />
          </Label>
          <Label title="Descrição: *" className="col s12 m12 l12">
            <SistemaTextArea required name="descricao" />
          </Label>
          <Label title="Aula reutilizável: *" className="col s12 m12 l12">
            <SistemaSelect required defaultValue="false" name="reutilizavel">
              <option value="false">Não</option>
              <option value="true">Sim</option>
            </SistemaSelect>

            <p style={{ marginBottom: "0.5em", fontSize: "0.9em", color: "#444" }}>
              Aulas reutilizáveis podem ser disponibilizadas mesmo após criada. Caso a aula não seja reutilizável, deverá ser disponibilizada em sua
              criação. Uma vez disponibilizada, a aula não pode mais ser editada.
            </p>
          </Label>

          <AulaLinks {...{ links, setLinks }} />

          <AulasDisciplinaTurmaTable {...{ aulasDisciplinasTurmas, periodosLetivos, setAulasDisciplinasTurmas }} />

          <Label title="Arquivos" className="col s12 m12 12" />
          <div className="col s12 m12 l6">
            <ColoredFileInput name="file1" />
          </div>
          <div className="col s12 m12 l6">
            <ColoredFileInput name="file2" />
          </div>
          <div className="col s12 m12 l6">
            <ColoredFileInput name="file3" />
          </div>
          <div className="col s12 m12 l6">
            <ColoredFileInput name="file4" />
          </div>
        </div>
      </Materialize>
    </Container>
  );
}

export default CreateContent;
