import React from "react";
import { Bar } from "react-chartjs-2";

import { Container, ChartContainer } from "./styles";

const optionsData = {
  responsive: true,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },

        barThickness: 12, // number (pixels) or 'flex'
        maxBarThickness: 12, // number (pixels)
      },
    ],
  },
};

function BarChart({ stats }) {
  return (
    <Container className="chart1">
      <div className="chart-container">
        <div className="chart-header">
          <h4>Desempenho</h4>
        </div>
        <ChartContainer>
          <Bar data={stats || {}} options={optionsData} />
        </ChartContainer>
      </div>
    </Container>
  );
}

export default BarChart;
