import React, { useState } from "react";

import {
  AcessosContent,
  ModuloDiv,
  CheckBoxLabel,
  MenusDiv,
  BoxContainer,
  Container,
} from "./styles";
import CheckBox from "../CustomForm/Check/styles/CheckBox";
import { Button } from "../CustomForm";
import { useEffect } from "react";

function Box({ modulo, showOnly, selecionados }) {
  const [collapsed, setCollapsed] = useState(true);
  const moduloRef = React.useRef();

  return (
    <BoxContainer className="li_primary">
      <ModuloDiv
        onClick={(e) =>
          !moduloRef?.current?.contains(e.target) && setCollapsed(!collapsed)
        }
      >
        <CheckBoxLabel ref={moduloRef}>
          {!showOnly && <CheckBox value={modulo?.menus?.map((e) => e.cod) || []} />}
          <h6>{modulo?.nome}</h6>
        </CheckBoxLabel>
        <div className="Acessos_Box_selecionados_icon_div">
          {!showOnly && (
            <p>
              {selecionados}/{modulo?.menus?.length}
            </p>
          )}
          <i className={`material-icons ${collapsed ? "green" : "red"}`}>
            {collapsed ? "add" : "remove"}
          </i>
        </div>
      </ModuloDiv>

      {!collapsed && (
        <MenusDiv>
          {modulo?.menus.map((menu) => (
            <div key={menu?.path} className="menu">
              <CheckBoxLabel>
                {!showOnly && <CheckBox value={menu?.cod} />}
                <h6>
                  {showOnly && "- "}
                  {menu?.nome}
                </h6>
              </CheckBoxLabel>
            </div>
          ))}
        </MenusDiv>
      )}
    </BoxContainer>
  );
}

/**
 * @param {Object} props
 * @param {Array} props.modulos Recebe uma lista de modulos com seus menus.
 * @param {Array} props.defaultSelect Recebe uma lista de códigos de menus para popular inicialmente os dados e checkBoxes.
 * @param {Array} props.disabledData Disabilita a seleção de qualquer menu que esteja nessa lista.
 * @param {Array} props.defaultDisabledData Disabilita e selecionada qualquer menu que esteja nessa lista.
 * @param {string} props.reloadDependency Reinicia os checks para as configurações iniciais sempre que a dependência for alterada.
 * @param {string} props.title Nome do título
 * @param {string} props.name nome da chave que será enviada para o Form.
 * @param {string} props.showOnly Se true, irá desativar toda a parte referente a um formulário, mantendo apenas visualização.
 * @param {string} props.filterMenuCod Array de códigos de menus. Usado para realizar um filtro, mostrando apenas os menus que tenham os códigos informados.
 * @param {string} props.minimizable Adiciona um botão de minimizar a listagem de módulos.
 * @param {string} props.keepEmptyArray  Padrão true, mantém o array no Form mesmo que esteja vazio.
 * @param {string} props.setter Função para obter os dados sem precisar do Form.
 */
function AcessosList({
  modulos,
  defaultSelect,
  disabledData,
  defaultDisabledData,
  reloadDependency,
  title = "ACESSOS",
  name = "acessos",
  showOnly,
  filterMenuCod,
  minimizable,
  keepEmptyArray = true,
  setter,
  ...rest
}) {
  const [collapsed, setCollapsed] = useState(false);
  const [selecionados, setSelecionados] = useState([]);
  const [handledModulos, setHandledModulos] = useState();

  useEffect(() => {
    if (Array.isArray(modulos) && modulos?.length > 0) {
      if (Array.isArray(filterMenuCod) && filterMenuCod.length > 0) {
        let thisModulos = [];

        modulos.forEach((mod) => {
          const { menus, ...restMod } = mod || {};
          let thisMenus = mod?.menus?.filter((e) => filterMenuCod.includes(e.cod));

          if (Array.isArray(thisMenus) && thisMenus.length > 0) {
            thisModulos.push({ ...restMod, menus: thisMenus });
          }
        });

        if (thisModulos.length > 0) {
          setHandledModulos(thisModulos);
        }
      } else {
        setHandledModulos(modulos);
      }
    }
  }, [modulos, filterMenuCod]);

  return (
    <Container minimizable={minimizable} {...rest}>
      {title && <p className="AcessosList_title_p">{title}</p>}
      {(!minimizable || (minimizable && !collapsed)) && (
        <AcessosContent
          {...(showOnly
            ? { as: "div" }
            : {
                reloadDependency,
                defaultData: defaultSelect,
                defaultDisabledData,
                disabledData,
                isArray: true,
                keepEmptyArray,
                setter: (thisData) => {
                  setSelecionados(thisData);
                  setter && setter(thisData);
                },
              })}
          name={name}
        >
          {handledModulos?.map((modulo) => (
            <Box
              key={modulo?.path}
              modulo={modulo}
              showOnly={showOnly}
              selecionados={
                modulo?.menus?.filter((r) => selecionados?.includes(r.cod))?.length || 0
              }
            />
          ))}
        </AcessosContent>
      )}
      {minimizable && (
        <Button
          className="AcessosList_Button_minimizable"
          type="button"
          onClick={() => setCollapsed(!collapsed)}
        >
          <i className={`material-icons ${collapsed ? "green" : "red"}`}>
            {collapsed ? "add" : "remove"}
          </i>
        </Button>
      )}
    </Container>
  );
}

export default AcessosList;
