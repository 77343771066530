import React from "react";
import TableButtonStyle from "../styles";
import customListStore, { customListToggleModal } from "../../../customListStore";
import CustomListLoadInitialData from "../../../utils/CustomListLoadInitialData";
import CustomListModal from "../../CustomListModal";

function CustomListShowButton({ data, icon, className, onClick, ...rest }) {
  const { state, dispatch } = React.useContext(customListStore);

  return (
    <TableButtonStyle
      onClick={async () => {
        await CustomListLoadInitialData({ data, state, dispatch });
        dispatch(
          customListToggleModal(<CustomListModal bodyComponent={{ show: true }} />)
        );
        onClick && onClick();
      }}
      className={`CustomTable_button_show ${className}`}
      {...rest}
    >
      <i className="material-icons">{icon || "visibility"}</i>
    </TableButtonStyle>
  );
}

export default CustomListShowButton;
