import React from "react";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import { useState } from "react";
import ShowError from "~/utils/ShowError";
import Label from "~/componentes/CustomForm/Label";

// import { Container } from './styles';

function ThisEditModal({ editModal, setSeriesAtivas, setEditModal }) {
  const [qntAlunos, setQntAlunos] = useState();

  return (
    <SistemaFixedModal
      title="ATUALIZAR"
      outClick={() => setEditModal(false)}
      footerButtons={{
        submitButton: {
          type: "button",
          onClick: (closeModal) => {
            if (qntAlunos > 0) {
              setSeriesAtivas((prev) => {
                const newPrev = [...prev];
                const thisFind = newPrev.find((t) => t?.cod === editModal?.cod);

                if (thisFind) {
                  thisFind.quantidadeAlunos = qntAlunos;
                }
                closeModal && closeModal();
                return newPrev;
              });
            } else {
              ShowError("A quantidade de alunos deve ser no mínimo 1");
            }
          },
        },
      }}
    >
      <Label title="ALUNOS">
        <SistemaInput
          type="number"
          defaultValue={editModal?.quantidadeAlunos}
          setter={setQntAlunos}
        />
      </Label>
    </SistemaFixedModal>
  );
}

export default ThisEditModal;
