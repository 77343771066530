import React from "react";
import Label from "~/componentes/CustomForm/Label";

import { Container, StyledTable } from "./styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";

function ShowContent({ mountedData }) {
  return (
    <Container>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Label title="TURMA" text={mountedData?.nome} />
        <Label title="SÉRIE" text={mountedData?.serie?.nome} />
        <Label title="ENSINO" text={mountedData?.serie?.ensino?.nome} />
        <StyledTable>
          <thead>
            <tr>
              <th>Disciplina</th>
              <th>Professor</th>
            </tr>
          </thead>
          <tbody>
            {mountedData?.disciplinasTurma?.map(
              (disciplina, i) =>
                disciplina?.disciplinaFilial?.nome &&
                disciplina?.professor?.usuario?.nome && (
                  <tr key={disciplina?.cod || i}>
                    <td>{disciplina.disciplinaFilial.nome}</td>
                    <td>{disciplina.professor.usuario.nome}</td>
                  </tr>
                )
            )}
          </tbody>
        </StyledTable>
      </CustomScrollBar>
    </Container>
  );
}

export default ShowContent;
