import React from "react";

import SistemaTable from "~/componentes/StyledComponents/Tables/SistemaTable";
import handleDate from "~/utils/handleDate";
import { Container } from "./styles";

function ThisTable({ avaliacoesTurma }) {
  return (
    <Container>
      <SistemaTable>
        <thead>
          <tr>
            <th>Título da avaliação</th>
            <th style={{ textAlign: "center" }}>Corrigido</th>
            <th style={{ textAlign: "center" }}>Data Início</th>
            <th style={{ textAlign: "center" }}>Data Fim</th>
          </tr>
        </thead>
        <tbody>
          {avaliacoesTurma?.map?.((e, i) => (
            <tr key={e?.cod || i}>
              <td style={{ textAlign: "left" }}>{e?.avaliacao?.nome}</td>
              <td>{e?.corrigido ? "Sim" : "Não"}</td>
              <td>{handleDate(e?.dataInicio).dmy}</td>
              <td>{handleDate(e?.dataFim).dmy}</td>
            </tr>
          ))}
        </tbody>
      </SistemaTable>
    </Container>
  );
}

export default ThisTable;
