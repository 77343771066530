import styled from "styled-components";

export const ModalStyle = styled.div`
  z-index: 1;
  ${props => props.animation && `animation: modalIn ${props.animation}ms;`}

  @keyframes modalIn {
    from {
      transform: ${props => props.checkCenter && "translate(-50%, -50%)"}  scale(0);
    }
    to {
      transform: ${props => props.checkCenter && "translate(-50%, -50%)"} scale(1);
    }
  }

  ${props =>
    props.close &&
    props.animation &&
    `
  animation: modalOut ${props.animation}ms;

  @keyframes modalOut {
    from {
      transform: ${props.checkCenter && "translate(-50%, -50%)"} scale(1);
      opacity: 1;
    }
    to {
      transform: ${props.checkCenter && "translate(-50%, -50%)"} scale(0);
      opacity: 0;
    }
  }
      transform: scale(0);
      opacity: 0;
  `}

  ${props =>
    (props.borderShadow || props.centerDefault || props.fixedCenterDefault) && `box-shadow: 0 0 0 100vmax #0006;`}

  ${props =>
    (props.defaultStyle || props.centerDefault || props.fixedCenterDefault) &&
    `
      padding: 0.5em 1em;
      background: #fff;
      border-radius: 0.5em;
  `}

  ${props =>
    props.checkCenter &&
    `position: ${props.fixedCenter || props.fixedCenterDefault ? "fixed" : "absolute"};
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);`}
`;
