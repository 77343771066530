import React from "react";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import ChangeIndexOrder from "~/utils/ChangeIndexOrder";

import { Container, QuestaoItem } from "./styles";

function RespostaObjetiva({ questao, respostaQuestao, showResp }) {
  const [respostas, setRespostas] = React.useState([]);

  React.useEffect(() => {
    let thisResult = [];
    if (Array.isArray(questao?.itens)) {
      questao.itens.forEach((item) => {
        const thisResp = respostaQuestao?.respostasItens?.find(
          (ri) => ri?.questaoItemCod === item.cod
        );

        const marcado = thisResp ? "true" : undefined;

        thisResult.push({
          marcado,
          enunciado: item?.enunciado || "",
          correto: item?.gabarito ? "true" : undefined,
        });
      });
    }

    setRespostas(thisResult);
  }, [questao, respostaQuestao]);

  return (
    <Container>
      {respostas?.map((item, i) => (
        <QuestaoItem
          key={item?.cod || i}
          marcado={item.marcado}
          correto={item.correto}
          showResp={showResp ? "true" : undefined}
          className="col s12 m12 l6"
          title={`${ChangeIndexOrder(i)}) ${item.marcado ? "Marcado" : ""}`}
        >
          <SistemaTextArea rows="5" readOnly value={item.enunciado} />
        </QuestaoItem>
      ))}
    </Container>
  );
}

export default RespostaObjetiva;
