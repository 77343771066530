import React, { useState } from "react";

import api from "~/services/api";
import ShowContent from "./ShowContent";
import { SLCreateModal, SLShowModal, SLEditModal } from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import TableComponent from "./TableComponent";
import { useEffect } from "react";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import { useSelector } from "react-redux";

function ExtratoFinanceiro() {
  const [mountedData, setMountedData] = useState();
  const [tabFilter, setTabFilter] = useState({ baixaTitulo: "NB" });

  return (
    <Container
      requests={{
        findAll: () => api.get("/padrao/ExtratosFinanceiros", { params: { paranoid: false } }),
        findOne: (thisData) => api.get("/padrao/ExtratoFinanceiro", { params: { cod: thisData.cod } })
      }}
      dataFilter={tabFilter}
    >
      <TableComponent {...{ setTabFilter, tabFilter }} />

      <SLShowModal title="Titulo" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      {/* <SLEditModal title="Atualizar Titulo" setter={setMountedData}>
        <CreateEditContent {...{ mountedData }} />
      </SLEditModal>

      <SLCreateModal title="Novo Titulo">
        <CreateEditContent />
      </SLCreateModal> */}
    </Container>
  );
}

export default ExtratoFinanceiro;
