import React from "react";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import PdfDownloader from "~/componentes/PdfDownloader";
import PdfDoc from "./PdfDoc";

function ThisPdfButton({ data, selectedDTM, avaliacoesDisponibilizadas }) {
  return (
    <ColoredButton
      onClick={() =>
        PdfDownloader({
          document: (
            <PdfDoc {...{ data, selectedDTM, avaliacoesDisponibilizadas }} />
          ),
          filename: selectedDTM?.cod
            ? selectedDTM?.disciplinaFilial?.nome
            : data?.nome,
        })
      }
      materialIcon="arrow_downward"
      redLayout
    >
      PDF
    </ColoredButton>
  );
}

export default ThisPdfButton;
