import { customListSetData, customListSortData } from "../customListStore";

/**
 *
 * @param {Object} props
 * @param {Object} props.findAll
 * @param {Object} props.sortOnEdit
 * @param {Object} props.dispatch
 */
export default function CustomListReloadList({ findAll, sortOnEdit, dispatch }) {
  if (findAll) {
    findAll().then((result) => {
      if (result?.status === 200) {
        dispatch(customListSetData(result?.data));
        sortOnEdit && dispatch(customListSortData());
      }
    });
    // if (response?.status === 200 && response?.data) {
    //   dispatch(customListEditData(response.data));
    // }
  }
}
