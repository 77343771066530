import styled from "styled-components";
import { Form } from "~/componentes/CustomForm";

export const Container = styled(Form)`
  min-width: 280px;
  width: 20em;
  max-width: 90%;
  background: #fff;
  border-radius: 1em;
  position: relative;
  padding: 1.5em;
  box-shadow: 0 0.5em 0.625em 0.063em rgba(0, 0, 0, 0.14), 0 0.188em 0.875em 0.125em rgba(0, 0, 0, 0.12),
    0 0.313em 0.313em -0.188em rgba(0, 0, 0, 0.3);
  margin: auto;

  h1 {
    text-align: center;
    color: #32495f;
    font-size: 1.4em;
    margin-bottom: 0.5em;
  }

  .animationIn {
    animation: animationIn 250ms;

    @keyframes animationIn {
      from {
        height: 0;
        transform: scaleY(0);
      }
    }
  }

  .animationOut {
    animation: animationOut 400ms;

    @keyframes animationOut {
      from {
        opacity: 1;
        transform: scaleY(1);
      }
      to {
        margin: 0;
        padding: 0;
        opacity: 0;
        height: 0;
        transform: scaleY(0);
      }
    }

    height: 0;
    opacity: 0;
    transform: scale(0);
  }

  .astronauta {
    position: absolute;
    top: -4em;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    align-items: center;
    justify-items: center;

    margin: auto;
    width: 8em;
    height: 8em;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 0.2em 0.2em rgba(0, 0, 0, 0.25);

    img {
      margin: auto;
      width: 7em;
      height: 7em;
    }
  }

  .form-separador {
    width: 100%;
    position: relative;
    z-index: 0;
    margin: 1.5em 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .form-separador::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.185em;
    z-index: -1;
    background: #eeeeee;
  }
  .form-separador h5 {
    background: #fff;
    position: absolute;
    text-transform: uppercase;
    padding: 0 1em;
    font-weight: 600;
    font-size: 0.8em;
    color: #999e9f;
  }
  .botao {
    margin-top: 1em;
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;

    button {
      width: 100%;
    }
    button:nth-child(2) {
      margin-bottom: 1.2em;
      margin-top: 0.8em;
    }
  }
`;
