import React from "react";
import { Form } from "~/componentes/CustomForm";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import PreviewFileInput from "~/componentes/CustomForm/FileInput/styles/PreviewFileInput";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import api from "~/services/api";
import { Materialize } from "~/styles/materializeGrid";
import setRegex from "~/utils/setRegex";
import ShowConfirmacao from "~/utils/ShowConfirmacao";

import { Container } from "./styles";

function AlterarDados() {
  const [filial, setFilial] = React.useState();

  React.useEffect(() => {
    api.get("filial/alterarDados/filial").then((r) => setFilial(r?.data));
  }, []);

  async function handleSubmit(formData = new FormData()) {
    ShowConfirmacao(() => api.put("filial/alterarDados/filial", formData), {
      message: "Tem certeza que deseja atualizar os dados?",
      successModal: "Dados atualizados com sucesso!",
    });
  }

  return (
    <Container>
      <Materialize>
        <Form isFormData className="row" onSubmit={handleSubmit}>
          <Label
            title="Nome Fantasia:"
            text={filial?.nomeFantasia || ""}
            className="col s12 m10 l10"
          />
          <Label
            title="Rede:"
            text={filial?.publico ? "Pública" : "Particular"}
            className="col s12 m2 l2"
          />
          <Label
            title="Razao Social:"
            text={filial?.razaoSocial || ""}
            className="col s12 m12 l12"
          />
          <Label title="Email:" className="col s12 m12 l8">
            <SistemaInput required defaultValue={filial?.email} name="email" />
          </Label>
          <Label
            required
            title="CNPJ:"
            text={setRegex.setCnpj(filial?.cnpj) || ""}
            className="col s12 m12 l4"
          />
          <Label title="Telefone 1:" className="col s12 m12 l4">
            <SistemaInput
              required
              defaultValue={setRegex.setPhone(filial?.telefone1)}
              name="telefone1"
            />
          </Label>
          <Label title="Telefone 2:" className="col s12 m12 l4">
            <SistemaInput
              defaultValue={setRegex.setPhone(filial?.telefone2)}
              name="telefone2"
            />
          </Label>
          <Label title="Whatsapp:" className="col s12 m12 l4">
            <SistemaInput defaultValue={filial?.whatsapp} name="whatsapp" />
          </Label>
          <Label title="Facebook:" className="col s12 m12 l6">
            <SistemaInput defaultValue={filial?.facebook} name="facebook" />
          </Label>
          <Label title="Youtube:" className="col s12 m12 l6">
            <SistemaInput defaultValue={filial?.youtube} name="youtube" />
          </Label>
          <Label title="Instagram:" className="col s12 m12 l6">
            <SistemaInput defaultValue={filial?.instagram} name="instagram" />
          </Label>
          <Label title="Site:" className="col s12 m12 l6">
            <SistemaInput defaultValue={filial?.site} name="site" />
          </Label>
          <Label
            title="Estado:"
            text={filial?.estado?.nome || ""}
            className="col s12 m12 l4"
          />
          <Label
            title="Cidade:"
            text={filial?.cidade?.nome || ""}
            className="col s12 m12 l4"
          />
          <Label title="Bairro:" text={filial?.bairro || ""} className="col s12 m12 l4" />
          <Label
            title="Logradouro:"
            text={filial?.logradouro || ""}
            className="col s12 m12 l6"
          />
          <Label
            title="Numero:"
            text={filial?.numeroLogradouro || ""}
            className="col s12 m12 l3"
          />
          <Label title="Cep:" text={filial?.cep || ""} className="col s12 m12 l3" />
          <Label title="Complemento:" className="col s12 m12 l12">
            <SistemaInput
              defaultValue={filial?.complementoLogradouro}
              name="complementoLogradouro"
            />
          </Label>
          <Label title="Sobre:" className="col s12 m12 l12">
            <SistemaTextArea defaultValue={filial?.sobre} name="sobre" />
          </Label>
          <Label title="Imagem da escola:" className="col s12 m6 l6">
            <PreviewFileInput
              name="imagemPerfilMatriculaCod"
              defaultPreview={filial?.imagemPerfilMatricula?.url}
            />
          </Label>
          <Label title="Logomarca:" className="col s12 m6 l6">
            <PreviewFileInput
              name="imagemLogoMatriculaCod"
              defaultPreview={filial?.imagemLogoMatricula?.url}
            />
          </Label>
          <ColoredButton greenLayout>Confirmar</ColoredButton>
        </Form>
      </Materialize>
    </Container>
  );
}

export default AlterarDados;
