import styled from "styled-components";

export const SistemaTableStyle = styled.table`
  width: 100%;
  margin: auto;
  max-width: 100%;
  border-collapse: collapse;

  th {
    background: #f6f6f6;
    color: #293f57;
    height: 2.5em;
    font-weight: 550;
  }

  td {
    border-bottom: solid 0.1em #f3f3f3;
    min-height: 2.6em;
    font-weight: 600;
    font-size: 0.95em;
    color: #7b898c;

    :empty {
      height: 2.6em;
    }
  }

  td,
  th {
    white-space: nowrap;
    line-break: none;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.56em;
    text-align: left;
  }

  /* th:not(:last-child),  */
  td:not(:last-child) {
    padding-right: 2em;
  }
`;

// export default SistemaTable;
