import React, { useEffect, memo } from "react";
import customListStore, { customListToggleModal } from "../../customListStore";
import CustomListPagination from "../CustomListPagination";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import CustomListLoadInitialData from "../../utils/CustomListLoadInitialData";
import CustomListModal from "../CustomListModal";

/**
 * @param {Array} data O dado do index em questão.
 * @param {Object} options
 * @param {Number} options.index Retorna o index do mapeamento.
 * @param {Function} options.showModal Abre o modal de visualização.
 * @param {Function} options.editModal Abre o modal de edição.
 * @param {Function} options.createModal Abre o modal de criação.
 * @param {Function} options.deleteModal Abre o modal de remoção.
 * @param {any} options.pagination Componente de paginação.
 */
// eslint-disable-next-line no-unused-vars
function setterProps({
  data,
  showModal,
  editModal,
  createModal,
  deleteModal,
  pagination,
}) {}

/**
 * Esse componente difere do comum, pois é renderizado através do resultado da função do atributo
 * renderFunction, no qual recebe como parâmetros o dado que seria da linha da tabela e o index.
 * @param {Object} props
 * @param {Function} props.innerRef Função que obtém a ref.
 * @param {Boolean} props.defaultStyle Se true, torna a o padrão de pagination como true, e atribui uma estilização no container.
 * @param {Object} props.setType Possui como padrão o componente SistemaComponenteInterno. Caso forneça outro, será substituído.
 * @param {Object} props.pagination Padrão true. Mostra o componente que realiza a paginação logo abaixo da lista.
 * @param {setterProps} props.setter Função utilizada para pegar os dados da tabela e suas configurações para que possa realizar o mapeamento.
 * @param {Array} props.finders Recebe um array de objetos contendo informações dos filtros que devem ser realizados.
 * @param {string} props.finders.key Informe a chave para retornar o valor, baseado no objeto da tabela.
 * @param {string} props.finders.search Informe um nome para identificar o input que será utilizado como busca.
 * @param {Object} props.finders.filter Informe as configurações de filtro.
 * @param {Array} props.finders.filter.list Informe a lista que será utilizada para popular um dropDown, aceita array, string com a url de um request, ou uma função.
 * @param {string} props.finders.filter.value Informe a chave que deve ser obtido o valor para a option
 * @param {string} props.finders.filter.label Informe a chave que deve ser obtido o label da option.
 * @param {string} props.finders.filter.outLabel Informe um nome para mostrar como label do dropdown.
 * @param {Function} props.getResize Aceita uma função onde retorna os dados do ResizeObserver.
 */
function TableCustomBody({
  finders,
  setType,
  setter,
  defaultStyle,
  innerRef,
  getResize,
  pagination,
  children,
  ...rest
}) {
  const { state, dispatch } = React.useContext(customListStore);
  const { filteredData, page } = state || {};
  const RenderWrapper = defaultStyle
    ? SistemaComponenteInterno
    : setType
    ? setType
    : "div";

  const observer = new ResizeObserver((element) => {
    getResize(element[0]);
  });

  const loadModal = React.useCallback(
    async (data, modal) => {
      await CustomListLoadInitialData({ data, state, dispatch });
      return dispatch(
        customListToggleModal(<CustomListModal bodyComponent={modal} />)
      );
    },
    [dispatch, state]
  );

  const mountProps = React.useMemo(() => {
    const checkPage = !isNaN(page) && page >= 0 ? page : 0;
    const pageData = filteredData && filteredData[checkPage];

    return {
      data: pageData,
      showModal: (thisData) => loadModal(thisData, { show: true }),
      editModal: (thisData) => loadModal(thisData, { edit: true }),
      createModal: (thisData) => loadModal(thisData, { create: true }),
      deleteModal: (thisData) => loadModal(thisData, { show: true }),
      pagination: CustomListPagination,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filteredData]);

  useEffect(() => {
    setter && setter(mountProps);
  }, [setter, mountProps]);

  return (
    <RenderWrapper
      ref={(e) => {
        innerRef && innerRef(e);
        getResize && observer.observe(e);
      }}
      {...rest}
    >
      {children}
      {(pagination || (defaultStyle && pagination !== false)) && (
        <CustomListPagination />
      )}
    </RenderWrapper>
  );
}

export default memo(TableCustomBody);
