import React, { useEffect, useState } from "react";

import { PageButtonIcon, PageButtonNumber, Pages } from "./styles";

import customListStore, { customListDataPage } from "../../customListStore";
import UserDevice from "~/componentes/UserDevice";

function CustomListPagination() {
  const { state, dispatch } = React.useContext(customListStore);
  const { filteredData, page } = state || {};
  const [buttonPageNumber, setButtonPageNumber] = useState();
  const [maxPage, setMaxPage] = useState();
  const [holdingButton, setHoldingButton] = useState(0);
  const [timeUp, setTimeUp] = useState(0);
  const { desktop, rotatedTablet } = UserDevice();

  useEffect(() => {
    setMaxPage(
      Array.isArray(filteredData) && filteredData.length > 0 ? filteredData.length : 1
    );
  }, [filteredData]);

  useEffect(() => {
    if (page >= maxPage) {
      dispatch(customListDataPage(maxPage - 1));
    } else if (maxPage > 0 && page >= 0) {
      let firstPage = page > 4 ? page - 4 : 0;
      let lastPage = firstPage + 4 > maxPage - 1 ? maxPage - 1 : firstPage + 4;

      let thisResult = [];
      for (let i = firstPage; i <= lastPage; i++) {
        thisResult.push(i);
      }
      setButtonPageNumber(thisResult);
    }
  }, [dispatch, maxPage, page]);

  useEffect(() => {
    let hold = false;
    if (!hold) {
      window.addEventListener("mouseup", () => {
        setTimeUp(0);
        setHoldingButton(0);
      });
      window.addEventListener("touchend", () => {
        setTimeUp(0);
        setHoldingButton(0);
      });
      window.addEventListener("blur", () => {
        setTimeUp(0);
        setHoldingButton(0);
      });
      if (holdingButton > 0) {
        hold = setInterval(() => {
          if (timeUp === 0) {
            setTimeUp(700);
          } else if (timeUp > 200) {
            setTimeUp(timeUp - 100);
          }
          page < maxPage - 1 && dispatch(customListDataPage(page + 1));
        }, timeUp);
      } else if (holdingButton < 0) {
        hold = setInterval(() => {
          if (timeUp === 0) {
            setTimeUp(700);
          } else if (timeUp > 200) {
            setTimeUp(timeUp - 100);
          }
          page > 0 && dispatch(customListDataPage(page - 1));
        }, timeUp);
      }
    }
    return () => {
      clearInterval(hold);
      window.removeEventListener("mouseup", () => {
        setTimeUp(0);
        setHoldingButton(0);
      });
      window.removeEventListener("touchend", () => {
        setTimeUp(0);
        setHoldingButton(0);
      });
      window.removeEventListener("blur", () => {
        setTimeUp(0);
        setHoldingButton(0);
      });
    };
  }, [dispatch, holdingButton, maxPage, page, timeUp]);

  return (
    <Pages
      summarydigits={String(page)?.length + String(maxPage)?.length}
      mobile={!desktop && !rotatedTablet}
    >
      {(desktop || rotatedTablet) && (
        <p className="CustomTable_Table_p_pageSummary">
          {page + 1} de {maxPage}
        </p>
      )}
      <PageButtonIcon
        onClick={() => page > 0 && dispatch(customListDataPage(0))}
        className="material-icons"
      >
        first_page
      </PageButtonIcon>
      <PageButtonIcon
        onMouseDown={() => page > 0 && setHoldingButton(-1)}
        onTouchStart={() => page > 0 && setHoldingButton(-1)}
        className="material-icons"
      >
        navigate_before
      </PageButtonIcon>
      {desktop || rotatedTablet ? (
        buttonPageNumber?.map((e) => (
          <PageButtonNumber
            numberdigits={
              String(buttonPageNumber[buttonPageNumber.length - 1] + 1).length
            }
            ativo={e === page ? 1 : undefined}
            key={e}
            onClick={() => dispatch(customListDataPage(e))}
          >
            {e + 1}
          </PageButtonNumber>
        ))
      ) : (
        <p className="CustomTable_Table_p_pageSummary">
          {page + 1} de {maxPage}
        </p>
      )}
      <PageButtonIcon
        onMouseDown={() => page < maxPage - 1 && setHoldingButton(1)}
        onTouchStart={() => page < maxPage - 1 && setHoldingButton(1)}
        className="material-icons"
      >
        navigate_next
      </PageButtonIcon>
      <PageButtonIcon
        onClick={() => page < maxPage - 1 && dispatch(customListDataPage(maxPage - 1))}
        className="material-icons"
      >
        last_page
      </PageButtonIcon>
    </Pages>
  );
}

export default CustomListPagination;
