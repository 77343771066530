import React, { useState } from "react";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";
import SistemaTable from "~/componentes/StyledComponents/Tables/SistemaTable";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import Label from "~/componentes/CustomForm/Label";
import IconButton from "~/componentes/CustomForm/Button/Styles/IconButton";
import Pagination from "~/componentes/Pagination";
import { useEffect } from "react";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import api from "~/services/api";

import { ThisAddDisciplinaTurma } from "./styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import ShowError from "~/utils/ShowError";
import { Materialize } from "~/styles/materializeGrid";
import { SubTitle } from "../ShowContent/styles";

function ADDNotaAlunoTurmaModal({ data, outClick, bimestre }) {
  const [dtMatriculada, setDtMatriculada] = useState([]);

  let alunosNotas = data?.t?.notas.filter((el)=> {
    if(el.periodo.periodoCod == parseInt(data.bimestre)){
      return el.notaAvaliacao
    }
  });

  function notaAluno(){
    let notas = alunosNotas?.reduce((acc, nota)=> acc + nota?.notaAvaliacao, 0)
    let media = (notas / data.tudo.quantidadeAvaliacoes).toFixed(1)

    return media
  }
  console.log(data, alunosNotas)
  return (
    <SistemaFixedModal
      title="Ver Notas"
      style={{ width: "50em", maxWidth: "100%" }}
      {...{ outClick }}
    >
        <Materialize>
          <SubTitle>Alunos</SubTitle>
          <div className="row">
            <Label
              title="NOME:"
              text={data?.t?.alunoTurma?.aluno.usuario.nome || ""}
              className="col s12 m12 l3"
            />
            <Label
              title="EMAIL:"
              text={data?.t?.alunoTurma?.aluno.usuario.email || ""}
              className="col s12 m12 l3"
            />
            <Label
              title="TELEFONE:"
              text={data?.t?.turma?.periodoLetivo?.telefone1 || ""}
              className="col s12 m12 l3"
            />
            <Label
              title="MÉDIA:"
              text={notaAluno() || ""}
              className="col s12 m12 l3"
            />
          </div>
          <SubTitle>Notas</SubTitle>
          <div className="row">
            {data?.t?.disciplinaTurma?.quantidadeAvaliacoes > 0 &&
              Array.from(Array(data?.t?.disciplinaTurma?.quantidadeAvaliacoes).keys()).map((index, dt) =>{
              let ava = 'Avaliação ' + (index + 1);
              return (<Label
                  key={index}
                  title={ava}
                  text={`${alunosNotas[index]?.notaAvaliacao  || 'nulo'}`}
                  className="col s12 m12 l3"
                />
                )
              })
            }
          </div>
        </Materialize>
    </SistemaFixedModal>
  );
}

export default ADDNotaAlunoTurmaModal;
