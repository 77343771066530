import styled from "styled-components";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";

export const Container = styled(SistemaFixedModal)`
  z-index: 999;
  width: 15em;
  font-size: 1.3em;

  .loadingContent {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.1em solid #f5f5f5;
    padding: 0.5em;
  }

  .loadingIcon {
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    animation: loading 2s linear infinite;
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
