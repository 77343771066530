import React from "react";
import IconButton from "~/componentes/CustomForm/Button/Styles/IconButton";
import Label from "~/componentes/CustomForm/Label";
import Modal from "~/componentes/Modal";

import { Container } from "./styles";

function RespostaImagem({ respostaQuestao, showResp }) {
  const [zoom, setZoom] = React.useState(false);

  return (
    <Label className="col s12 m12 l12" title="Resposta:" text={!showResp && ""}>
      {showResp && (
        <Container>
          {respostaQuestao?.arquivos?.map((e) => (
            <Modal
              outClose
              outClick={() => zoom === e?.cod && setZoom(false)}
              onClick={() =>
                setZoom((prev) => (prev === e?.cod ? false : e?.cod))
              }
              className={`RespostaImagem_list_imageDiv ${
                zoom === e?.cod && "zoom"
              }`}
            >
              <IconButton
                className="RespostaImagem_list_imageDiv_closeIcon"
                materialIcon="close"
              />
              <img src={e?.url} alt={e?.originalname || ""} />
            </Modal>
          ))}
        </Container>
      )}
    </Label>
  );
}

export default RespostaImagem;
