import React, { useState } from "react";
import { CLEditComponent } from "~/pages/Sistema/componentes/CustomList";
import api from "~/services/api";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import Label from "~/componentes/CustomForm/Label";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import { CLForm } from "~/pages/Sistema/componentes/CustomList/CustomListStyledComponents";

function EditModal({ cargos, modulos }) {
  const [cargo, setCargo] = useState();
  function handleSubmit(editedData, reload) {
    return ShowConfirmacao(
      async () => {
        const response = await api.put("filial/cargoFilial", {
          cod: cargos.cod,
          ...editedData,
        });

        if (response?.status === 200) {
          reload();
        }

        return response;
      },
      {
        message: "Tem certeza que deseja atualizar esse cargo?",
        successModal: "Cargo atualizada com sucesso!",
      }
    );
  }

  return (
    <CLEditComponent
      title="Atualizar Cargo"
      contentMount={setCargo}
      contentUnmount={() => {
        setCargo();
      }}
    >
      <CLForm defaultStyle onSubmit={handleSubmit}>
        <Label title="TÍTULO:">
          <SistemaInput name="nome" placeholder={cargo?.nome} />
        </Label>
        <Label title="CARGOS PADRÃO">
          <SistemaSelect
            list={{ list: cargos, value: "cod", label: "nome" }}
            name="cargoEmpresaCod"
            defaultValue={cargos?.cod}
            firstOption="Selecione"
          />
        </Label>
      </CLForm>
    </CLEditComponent>
  );
}

export default EditModal;
