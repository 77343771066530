import Inicio from "./Inicio";
import admin from "./admin";
import filial from "./filial";
import empresa from "./empresa";
import professor from "./professor";
import padrao from "./padrao";
import aluno from "./aluno";

export default {
  Inicio,
  empresa,
  admin,
  filial,
  professor,
  padrao,
  aluno
};
