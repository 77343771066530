import React from "react";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTableCell } from "~/pages/Sistema/componentes/SistemaList";
import { ThisTable } from "./styles";

function TableComponent() {
  return (
    <>
      <SistemaComponenteInterno>
        <ThisTable>
          <SLTableCell name="nome" inputfilter>
            Nome
          </SLTableCell>
          <SLTableCell name="estrelas">Quant. Estrelas</SLTableCell>
          <SLTableCell name="educabits">Valor EducaBits</SLTableCell>
        </ThisTable>
      </SistemaComponenteInterno>
    </>
  );
}

export default TableComponent;
