import { takeLatest, call, put, all } from "redux-saga/effects";

import api from "~/services/api";
import { signInSucces, signFailure } from "./actions";
import history from "~/services/history";

function handlePreferencia(matriculas, preferencias, localPreferencias) {
  if (Array.isArray(matriculas) && matriculas.length > 0) {
    const { tipo, matriculaCod, isProprietario } = localPreferencias || {};
    const localMat =
      tipo &&
      matriculas.find(
        (m) =>
          m?.tipo === tipo &&
          (matriculaCod === m?.matriculaCod ||
            m?.isProprietario === isProprietario)
      );

    return (
      localMat || preferencias?.matricula || matriculas?.[1] || matriculas[0]
    );
  }

  return undefined;
}

export function* signIn({ payload }) {
  try {
    const { email, senha, noRedirect } = payload;

    const response = yield call(api.post, "session", {
      email,
      senha,
    });

    const { perfil, matriculas, preferencias } = response.data;

    const matricula = handlePreferencia(matriculas, preferencias);

    if (matricula.token) {
      api.defaults.headers.Authorization = `Bearer ${matricula.token}`;

      yield put(signInSucces({ perfil, matriculas, matricula, preferencias }));

      !noRedirect && history.push("/sistema");
    } else {
      throw new Error("Essa conta não possui acesso ao sistema");
    }
  } catch (err) {
    yield put(signFailure({err: err.response.data}));
  }
}

export function* signUp({ payload }) {
  try {
    const { name, email, senha } = payload;

    yield call(api.post, "usuarios", {
      name,
      email,
      senha,
    });
  } catch (err) {
    yield put(signFailure());
  }
}

export function* setToken({ payload }) {
  if (!payload) return;
  const { token, preferencias: localPref } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;

    try {
      const response = yield call(api.post, "global/updateSession");

      const { perfil, matriculas, preferencias } = response.data;

      const matricula = handlePreferencia(matriculas, preferencias, localPref);

      if (matricula.token) {
        // api.defaults.headers.Authorization = `Bearer ${matricula.token}`;

        yield put(
          signInSucces({
            perfil,
            matriculas,
            matricula,
            // preferencias: {
            //   tipo: matricula?.tipo,
            //   matriculaCod: matricula?.matriculaCod,
            //   isProprietario: matricula?.isProprietario,
            // },
          })
        );
      } else {
        throw new Error("Essa conta não possui acesso ao sistema");
      }
    } catch (err) {
      yield put(signFailure());
    }
  }
}

export function signOut() {
  history.push("/entrar");
  localStorage.removeItem("modulo");
  localStorage.removeItem("menu");
}

export default all([
  takeLatest("persist/REHYDRATE", setToken),
  takeLatest("@auth/SIGN_IN_REQUEST", signIn),
  takeLatest("@auth/SIGN_UP_REQUEST", signUp),
  takeLatest("@auth/SIGN_OUT", signOut),
]);
