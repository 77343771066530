import styled from "styled-components";
import { Form as FormLib } from "~/componentes/CustomForm";

export const Container = styled.div`
  width: 60em;
  max-width: 90%;
  border-radius: ${(props) => (props.desktop || props.rotatedTablet ? "0 3em 3em 0" : "2em")};
  ${(props) => !props.desktop && !props.rotatedTablet && "margin: auto;"}
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  padding: ${(props) => (props.desktop || props.rotatedTablet ? "1.5em 4% 1.5em 5%" : "1.5em")};
  background: #fff;
  box-shadow: 0.375em 0.375em 1.25em rgba(134, 134, 134, 0.7);

  strong {
    font-size: ${(props) => (props.desktop || props.rotatedTablet ? "1.4em" : "1.3em")};
  }

  .inputs {
    min-width: 250px;
    width: 25em;
    ${(props) => !props.desktop && !props.rotatedTablet && "margin: auto;"}
  }

  .text-contato {
    font-size: 0.7em !important;
  }

  .dados {
    width: 15em;
    ${(props) => !props.desktop && !props.rotatedTablet && "margin: 2em auto 1em;"}

    ul {
      margin-top: 1em;
      li {
        font-weight: 550;
        list-style: none;
        position: relative;
        margin-top: 0.5em;
        margin-left: 1.5em;
        font-size: 0.9em;

        ::before {
          content: "";
          background: #1ca9d0;
          position: absolute;
          border-radius: 50%;
          width: 0.45em;
          height: 0.45em;
          left: -1em;
          top: 0.5em;
        }
      }
    }
  }
`;

export const Form = styled(FormLib)`
  label {
    font-weight: 550;
  }

  .inputDiv {
    position: relative;
    height: fit-content;
    margin-bottom: 1em;

    i {
      color: #fff;
      position: absolute;
      font-size: 1.5em;
      width: auto;
      top: 0.5em;
      left: 0.5em;
    }

    input,
    textarea {
      display: block;
      background: #256172;
      border: 0.15em solid #1f3346;
      box-sizing: border-box;
      border-radius: 0.3em;
      color: #fff;
      width: 100%;
      padding: 0.5em;

      ::placeholder {
        color: #fff;
      }
    }

    textarea {
      height: 8em;
    }

    .withIcon {
      padding-left: 2.5em;
    }
  }
`;
