import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { Container, SubTitle } from "./styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import SistemaTable from "~/componentes/StyledComponents/Tables/SistemaTable";
import { Materialize } from "~/styles/materializeGrid";
import { useState } from "react";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import { useEffect } from "react";
import api from "~/services/api";
import SistemaModalFooter from "~/componentes/Modal/Styleds/Footers/SistemaModalFooter";
import sistemaStore from "~/pages/Sistema/sistemaStore";

function ShowContent({ mountedData }) {
  const { setComponentPath } = React.useContext(sistemaStore);
  const [periodosLetivos, setPeriodosLetivos] = useState([]);
  const [turmas, setTurmas] = useState([]);

  useEffect(() => {
    api
      .get("filial/aluno/periodosLetivos", {
        params: { alunoCod: mountedData?.cod },
      })
      .then((r) => setPeriodosLetivos(r?.data || []));
  }, [mountedData]);

  return (
    <Container>
      <CustomScrollBar fixScrollMargin autoHeightMax="80vh">
        <Materialize>
          <div className="row">
            <Label
              title="NOME"
              text={mountedData?.usuario?.nome || ""}
              className="col s12 m12 l8 paddingRight"
            />
            <Label
              title="MATRÍCULA"
              text={mountedData?.matricula || ""}
              className="col s12 m12 l4"
            />
            <SubTitle>Contatos</SubTitle>
            <Label
              title="EMAIL"
              text={mountedData?.usuario?.email || ""}
              className="col s12 m12 l4 paddingRight"
            />
            <Label
              title="TELEFONE 1"
              text={mountedData?.usuario?.telefone1 || ""}
              className="col s12 m12 l4 paddingRight"
            />
            <Label
              title="TELEFONE 2"
              text={mountedData?.usuario?.telefone2 || ""}
              className="col s12 m12 l4"
            />
            <SubTitle>Endereço</SubTitle>
            <Label
              title="BAIRRO"
              text={mountedData?.usuario?.bairro || ""}
              className="col s12 m12 l4 paddingRight"
            />
            <Label
              title="ESTADO"
              text={mountedData?.usuario?.estado?.nome || ""}
              className="col s12 m12 l4 paddingRight"
            />
            <Label
              title="CIDADE"
              text={mountedData?.usuario?.cidade?.nome || ""}
              className="col s12 m12 l4"
            />
          </div>
        </Materialize>
        <SubTitle>Turmas</SubTitle>
        <SistemaSelect
          defaultValue={periodosLetivos?.[0]?.cod}
          list={{ list: periodosLetivos, value: "cod", label: "nome" }}
          setList={(e) => setTurmas(e?.turmas || [])}
        />
        <SistemaTable>
          <thead>
            <tr>
              <th>Turma</th>
              <th>Série</th>
              <th>Ensino</th>
            </tr>
          </thead>
          <tbody>
            {turmas?.map((t, i) => (
              <tr key={t?.cod || i}>
                <td>{t?.nome}</td>
                <td>{t?.serie?.nome}</td>
                <td>{t?.serie?.ensino?.nome}</td>
              </tr>
            ))}
          </tbody>
        </SistemaTable>
      </CustomScrollBar>
      <SistemaModalFooter
        submitButton={{
          type: "button",
          text: "Relatório",
          onClick: () =>
            setComponentPath({
              moduloPath: "Relatorios",
              menuPath: "Alunos",
              props: { aluno: mountedData },
            }),
        }}
      />
    </Container>
  );
}

export default ShowContent;
