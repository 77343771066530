import React from "react";
import { ThisSistemaListaIconButonStyle } from "../styles";

function ThisAprovarButton({ icon = "done", ...rest }) {
  return (
    <ThisSistemaListaIconButonStyle moldalType="aprovar" className={`material-icons`} {...rest}>
      {icon}
    </ThisSistemaListaIconButonStyle>
  );
}

export default ThisAprovarButton;
