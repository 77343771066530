import React, { useState } from "react";

import { Container } from "./styles";
import { useEffect } from "react";
import api from "~/services/api";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import ShowLoading from "~/utils/ShowLoading";
import SliderCheck from "~/componentes/CustomForm/Check/styles/SliderCheck";
import CheckWrapper from "~/componentes/CustomForm/CheckWrapper";
import { Form } from "~/componentes/CustomForm";

export default function CustomizarMatricula() {
  const [ensinos, setEnsinos] = useState([]);
  const [dadosMatriculas, setDadosMatriculas] = useState([]);
  const [ensino, setEnsino] = useState();
  const [configuracaoMatricula, setConfiguracaoMatricula] = useState();

  useEffect(() => {
    async function fetchInitialData() {
      await api.get("global/ensinos").then((thisResponse) => {
        setEnsinos(thisResponse.data);
        setEnsino(thisResponse.data[0]);
      });

      await api
        .get("filial/dadosMatriculaGrupos")
        .then((thisResponse) => setDadosMatriculas(thisResponse?.data));
    }

    fetchInitialData();
  }, []);

  async function handleEnsinoChange(thisEnsino) {
    setEnsino(thisEnsino);
    thisEnsino?.cod &&
      (await api
        .get("filial/configuracaoMatricula", {
          params: { ensinoCod: thisEnsino.cod },
        })
        .then((thisResponse) => setConfiguracaoMatricula(thisResponse?.data))
        .catch((err) => {
          setConfiguracaoMatricula();
          throw err;
        }));
  }

  async function submitForm(data) {
    await ShowLoading(
      async () => {
        let response;
        if (configuracaoMatricula) {
          response = await api.put("filial/configuracaoMatricula", {
            ...configuracaoMatricula,
            ...data,
          });
        } else {
          response = await api.post("filial/configuracaoMatricula", {
            ensinoCod: ensino.cod,
            ...data,
          });
        }

        if (response?.status === 200) {
          await handleEnsinoChange(ensino);

          return response;
        } else {
          throw new Error();
        }
      },
      {
        errorModal:
          "Ocorreu um erro ao alterar a configuração da matrícula, por favor, tente novamente.",
        successModal: "Atualização realizada com sucesso",
      }
    );
  }

  return (
    <Container>
      <h1>Customizar Matricula</h1>

      <div className="selectMatricula">
        <p>Escolha um ensino para customizar a matrícula: </p>
        <SistemaSelect
          teste="matriculas"
          setList={handleEnsinoChange}
          defaultValue={ensino?.cod}
          list={{ list: ensinos, value: "cod", label: "nome" }}
        />
      </div>

      <h2>{ensino && ensino.nome}</h2>

      <Form onSubmit={submitForm}>
        <CheckWrapper
          className="formularios"
          name="configuracoes"
          isArray
          reloadDependency={ensino}
        >
          {dadosMatriculas?.map((e) => (
            <div key={e.cod}>
              <h3>{e?.nome}</h3>
              <div className="formulario">
                {e?.dadosMatricula?.map((dado) => {
                  const thisChecked = configuracaoMatricula?.configuracoes?.find(
                    (t) => t?.cod === dado.cod
                  );

                  return (
                    <label name={dado.cod} key={dado.cod} className="switchButton">
                      <p>{dado.nome}: </p>
                      <SliderCheck
                        value={dado.cod}
                        assistChecked={dado.obrigatorio || !!thisChecked}
                        disabled={dado.obrigatorio}
                      />
                    </label>
                  );
                })}
              </div>
            </div>
          ))}
        </CheckWrapper>
        <div className="confirmar">
          <ColoredButton small greenLayout>
            Confirmar
          </ColoredButton>
        </div>
      </Form>
    </Container>
  );
}
