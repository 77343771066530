import React, { useState } from "react";
import { CLShowComponent } from "~/pages/Sistema/componentes/CustomList";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import Label from "~/componentes/CustomForm/Label";

function ShowModal() {
  const [funcionario, setFuncionario] = useState();

  return (
    <CLShowComponent
      title="Dados do Funcionário"
      contentMount={setFuncionario}
      contentUnmount={() => setFuncionario()}
    >
      <CustomScrollBar fixScrollMargin autoHeightMax="20em">
        <Label title="Nome" text={funcionario?.usuario?.nome || ""} />
        <Label title="Cargo" text={funcionario?.cargoFilial?.nome || ""} />
        <Label title="Estado" text={funcionario?.usuario?.estado?.nome || ""} />
        <Label title="Cidade" text={funcionario?.usuario?.cidade?.nome || ""} />
      </CustomScrollBar>
    </CLShowComponent>
  );
}

export default ShowModal;
