import React from "react";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";

import CustomScrollBar from "~/componentes/CustomScrollBar";
import { Materialize } from "~/styles/materializeGrid";
import { Container } from "./styles";
import { SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import api from "~/services/api";
import ShowConfirmacao from "~/utils/ShowConfirmacao";

function CreateEditContent({ mountedData }) {
  function handleSubmit(data, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let response;

        if (mountedData) {
          response = await api.put("admin/questaonivel", {
            ...data,
            cod: mountedData.cod,
          });
        } else {
          response = await api.post("admin/questaonivel", data);
        }

        if (response?.status === 200) {
          closeReload && closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao ${
            mountedData ? "atualizar" : "criar"
          } esse nível de questão, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja ${
          mountedData ? "atualizar" : "criar"
        } esse Nível de Questão?`,
        successModal: `Nível de Questão ${
          mountedData ? "atualizada" : "cadastrada"
        } com sucesso!`,
      }
    );
  }

  return (
    <Container onSubmit={handleSubmit}>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          <div className="row">
            <Label title="NOME:" className="col s12 m12 l6">
              <SistemaInput name="nome" defaultValue={mountedData?.nome} />
            </Label>
            <Label title="QUANT. ESTRELAS:" className="col s12 m12 l3">
              <SistemaInput
                name="estrelas"
                type="number"
                defaultValue={mountedData?.estrelas}
              />
            </Label>
            <Label title="VALOR EDUCABITS:" className="col s12 m12 l3">
              <SistemaInput
                name="educabits"
                type="number"
                defaultValue={mountedData?.educabits}
              />
            </Label>
          </div>
        </Materialize>
      </CustomScrollBar>
      <SLModalFooter />
    </Container>
  );
}

export default CreateEditContent;
