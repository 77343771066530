import { Scrollbars } from "react-custom-scrollbars";
import React from "react";
import { useState } from "react";
import { IsFunction } from "~/utils/CheckDataType";

/**
 * @param {Object} props
 * @param {Boolean} props.autoHeight
 * @param {string} props.autoHeightMax
 * @param {string} props.autoHeightMin
 * @param {Number} props.autoHideDuration
 * @param {Number} props.autoHideTimeout
 * @param {Boolean} props.autoHide
 * @param {Object} props.renderView Estiliza a div de renderização.
 * @param {string} props.fixScrollMargin Se true, adiciona uma div externa e interna no scroll
 *  ajustando 0.8em de margin, se for uma string, altera o tamanho da margin.
 * @param {Object} props.verticalScroll Propriedades do scroll vertical.
 * @param {string} props.verticalScroll.style
 * @param {string} props.verticalScroll.event
 */
function CustomScrollBar(props) {
  const {
    children,
    verticalScroll,
    autoHeight,
    autoHeightMax,
    autoHeightMin,
    renderView,
    fixScrollMargin,
    ...rest
  } = props;
  const [mouseOver, setMouseOver] = useState(false);

  function renderThumbVertical({ style, ...props }) {
    const { style: verticalStyle, event: verticalEvent, ...verticalRest } =
      verticalScroll || {};

    return (
      <div
        onMouseEnter={() => {
          setMouseOver(true);
        }}
        onMouseLeave={() => {
          setMouseOver(false);
        }}
        {...verticalEvent}
        style={{
          ...style,
          position: "absolute",
          right: 0,
          width: mouseOver ? "0.5em" : "0.25em",
          borderRadius: "0.25em",
          backgroundColor: mouseOver ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 0.2)",
          ...(IsFunction(verticalStyle)
            ? verticalStyle({ hover: mouseOver })
            : verticalStyle),
        }}
        {...verticalRest}
      />
    );
  }

  // function checkAutoHeight() {
  //   if (autoHeightMax || autoHeightMin || autoHeight) {
  //     let thisAutoHeight = { autoHeight: true };
  //     autoHeightMax && (thisAutoHeight.autoHeightMax = autoHeightMax);
  //     autoHeightMin && (thisAutoHeight.autoHeightMin = autoHeightMin);
  //     return thisAutoHeight;
  //   }
  //   return {};
  // }

  const scrollMargin = typeof fixScrollMargin === "string" ? fixScrollMargin : "0.8em";

  return fixScrollMargin ? (
    <div
      style={{
        width: `calc(100% + ${scrollMargin})`,
      }}
    >
      <Scrollbars
        renderThumbVertical={({ style, ...props }) =>
          renderThumbVertical({ style, ...props })
        }
        {...rest}
        autoHeight={autoHeight === false ? false : true}
        autoHeightMax={autoHeightMax || "auto"}
        autoHeightMin={autoHeightMin || "auto"}
        // {...checkAutoHeight()}
      >
        <div style={{ marginRight: scrollMargin }}>{children}</div>
      </Scrollbars>
    </div>
  ) : (
    <Scrollbars
      renderThumbVertical={({ style, ...thisProps }) =>
        renderThumbVertical({ style, ...thisProps })
      }
      {...rest}
      renderView={(thisProps) => (
        <div {...thisProps} style={{ ...thisProps.style, ...renderView }} />
      )}
      autoHeight={autoHeight === false ? false : true}
      autoHeightMax={autoHeightMax || "auto"}
      autoHeightMin={autoHeightMin || "auto"}
      // {...checkAutoHeight()}
    >
      {children}
    </Scrollbars>
  );
}

export default React.memo(CustomScrollBar);
