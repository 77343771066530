import React from "react";
import foguete from "~/assets/imagens_grandes/rocket_smooth.png";
import escola from "~/assets/icones/escola.png";
import professor from "~/assets/icones/professor.png";
import aluno from "~/assets/icones/aluno.png";
import pais from "~/assets/icones/pais.png";

import { Container, Content } from "./styles";
import UserDevice from "~/componentes/UserDevice";

export default function Matriculas() {
  const { rotatedTablet, desktop } = UserDevice();

  return (
    <Container rotatedTablet={rotatedTablet}>
      {(desktop || rotatedTablet) && <img className="foguete" src={foguete} alt="Foguete" />}
      <Content {...{ rotatedTablet, desktop }}>
        <h1>Benefícios</h1>
        <div className="content">
          <div className="box box1">
            <div className="boxImageContent">
              <img src={escola} alt="Escola" />
            </div>
            <div className="boxTextContent">
              <strong>Instituição de Ensino</strong>
              <p>- Gestão da Instituição</p>
              <p>- Matrículas online</p>
              <p>- Dashboard e Relatórios de acompanhamento dos alunos e professores</p>
            </div>
          </div>
          <div className="box box2">
            <div className="boxImageContent">
              <img src={professor} alt="Professor" />
            </div>
            <div className="boxTextContent">
              <strong>Professor</strong>
              <p>- Controle da disponibilização, acompanhamento e correção das atividades</p>
              <p>- Envio de comunicados e disponibilização de conteúdos</p>
              <p>- Documentação de reuniões e relatórios de status dos alunos</p>
            </div>
          </div>
          <div className="box box3">
            <div className="boxImageContent">
              <img src={aluno} alt="Aluno" />
            </div>
            <div className="boxTextContent">
              <strong>Aluno</strong>
              <p>- O aluno terá acesso pelo aplicativo</p>
              <p>- Atividades disponibilizadas pelo professor</p>
              <p>- Acompanhamento pelo feed de acordo com sua turma</p>
              <p>- O aplicativo é gamificado e terá desafios para conquistas de Educabits</p>
              <p>- Loja, Avatar, Conquistas e Ranking</p>
            </div>
          </div>
          <div className="box box4">
            <div className="boxImageContent">
              <img src={pais} alt="Pais" />
            </div>
            <div className="boxTextContent">
              <strong>Pais</strong>
              <p>
                - Busca por instituições de ensino com o período de matrícula aberta e o envio de documentos para a realização da matrícula online
              </p>
              <p>- Acompanhamento das atividades</p>
              <p>- Aproximação com a instituição de ensino</p>
            </div>
          </div>
        </div>
      </Content>
    </Container>
  );
}
