import * as GestaoDisciplinas from "./GestaoDisciplinas";
import * as GestaoTurmas from "./GestaoTurmas";
import * as Relatorios from "./Relatorios";
import * as Suporte from "./Suporte";
import * as Reunioes from "./Reunioes";
import { default as Videoconferencia } from "./Videoconferencia/main";
import { default as DashBoard } from "./DashBoard";
import { default as Comunicados } from "./Comunicados";

export default {
  DashBoard,
  Comunicados,
  GestaoDisciplinas,
  GestaoTurmas,
  Relatorios,
  Suporte,
  Reunioes,
  Videoconferencia,
};
