import React from "react";
import IconButton from "~/componentes/CustomForm/Button/Styles/IconButton";

import { Container, QuestaoPageButton } from "./styles";

function QuestoesPagination({
  page,
  questoes,
  maxPage,
  setNextPage,
  setPage,
  respostaQuestoes,
}) {
  const respostas = React.useMemo(() => Object.values(respostaQuestoes), [
    respostaQuestoes,
  ]);

  React.useEffect(() => {
    if (typeof setNextPage === "function" && typeof setPage === "function") {
      setNextPage(() => () => {
        if (page < maxPage) {
          setPage(page + 1);
        }
        if (page >= maxPage) {
          setPage(1);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxPage, page]);

  return (
    <Container>
      <IconButton
        materialIcon="chevron_left"
        onClick={() => setPage(page <= 1 ? 1 : page - 1)}
      >
        Anterior
      </IconButton>
      <div className="QuestoesPagination_div_pageButtons">
        {questoes?.map((e, i) => {
          const findResp = respostas?.find((resp) => resp.questaoCod === e.cod);
          return (
            <QuestaoPageButton
              status={findResp?.statusResposta}
              key={i}
              onClick={() => setPage(i + 1)}
            >
              {i + 1}
            </QuestaoPageButton>
          );
        })}
      </div>
      <IconButton
        materialIcon="chevron_right"
        onClick={() => setPage(page >= maxPage ? maxPage : page + 1)}
      >
        Próxima
      </IconButton>
    </Container>
  );
}

export default QuestoesPagination;
