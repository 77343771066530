import React from "react";
import { Bar } from "react-chartjs-2";

import { Container, ChartContainer } from "./styles";

const optionsData = {
  responsive: true,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },

        barThickness: 12, // number (pixels) or 'flex'
        maxBarThickness: 12, // number (pixels)
      },
    ],
  },
};

function BarChart({ stats }) {
  const { alunosMediaAnual } = stats || {};

  const BarData = React.useMemo(() => {
    if (alunosMediaAnual) {
      const labels = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ];
      const notaBaixa = Object.values(alunosMediaAnual).map((e) =>
        e?.reduce((total, current) => (current < 70 ? total + 1 : total), 0)
      );
      const notaAlta = Object.values(alunosMediaAnual).map((e) =>
        e?.reduce((total, current) => (current >= 70 ? total + 1 : total), 0)
      );

      return {
        labels,
        datasets: [
          { label: "Nota Baixa", backgroundColor: "#FF4031", data: notaBaixa || [] },
          { label: "Nota Alta", backgroundColor: "#4FA754", data: notaAlta || [] },
        ],
      };
    }
  }, [alunosMediaAnual]);

  return (
    <Container className="chart1">
      <div className="chart-container">
        <div className="chart-header">
          <h4>Desempenho dos alunos</h4>
        </div>
        <ChartContainer>
          <Bar data={BarData || {}} options={optionsData} />
        </ChartContainer>
      </div>
    </Container>
  );
}

export default BarChart;
