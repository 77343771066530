import React from "react";

import { Container } from "./styles";
import api from "~/services/api";
import { useDispatch } from "react-redux";
import { toggleDisableButton } from "~/store/modules/disableButtons/actions";
import { signInRequest } from "~/store/modules/auth/actions";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import CustomToast from "~/componentes/CustomToast";
import handleError from "~/utils/handleError";
import RecuperarSenha from "./RecuperarSenha";
import history from "~/services/history";
import ThisFormElements from "./ThisFormElements";
import PlanetasBackground from "./PlanetasBackground";

export default function Entrar() {
  const dispatch = useDispatch();
  const [cadastro, setCadastro] = React.useState(false);
  const [params, setParams] = React.useState();
  const [animationClass, setAnimationClass] = React.useState(false);

  const location = history?.location;

  React.useEffect(() => {
    if (!params && location?.search) {
      try {
        const handledParams = Object.fromEntries(new URLSearchParams(location.search));
        const { senhaToken, email } = handledParams || {};

        if (senhaToken && email) {
          setParams({ senhaToken, email });
        } else {
          throw new Error();
        }
      } catch {
        setParams({ failed: true });
      }
    }
  }, [location, params]);

  async function handleLogin({ email, senha }) {
    dispatch(toggleDisableButton());
    try {
      dispatch(signInRequest(email, senha));
      dispatch(toggleDisableButton());
    } catch {
      dispatch(toggleDisableButton());
    }
  }

  async function handleCadastro(data) {
    dispatch(toggleDisableButton());
    try {
      const response = await api.post("usuario", data);

      if (response.data) {
        CustomToast.success("Conta criada com sucesso!");
        // CustomToast.success("Foi enviado um email para alterar a senha.");
        setCadastro(false);
        dispatch(toggleDisableButton());
      } else {
        throw new Error();
      }
    } catch (err) {
      CustomToast.error(handleError(err, "Falha ao realizar o cadastor, por favor, tente novamente."));
      dispatch(toggleDisableButton());
    }
  }

  return (
    <>
      <PlanetasBackground />
      <Container onSubmit={cadastro ? handleCadastro : handleLogin}>
        <h1>{cadastro ? "Cadastre-se" : "Entrar"}</h1>

        <ThisFormElements {...{ animationClass, cadastro }} />

        <RecuperarSenha defaultData={params} resetDefault={() => setParams({ reseted: true })} />

        <div className="botao">
          <ColoredButton
            type="button"
            onClick={() => {
              if (cadastro) {
                setTimeout(() => setCadastro(false), 350);
              } else {
                setCadastro(true);
              }
              setAnimationClass(cadastro ? "animationOut" : "animationIn");
            }}
            {...(cadastro ? { yellowLayout: true } : { blueLayout: true })}
          >
            {cadastro ? "Voltar" : "Cadastre-se"}
          </ColoredButton>
          <div className="form-separador">
            <h5>ou</h5>
          </div>
          <ColoredButton type="submit" greenLayout>
            {cadastro ? "Cadastrar" : "Entrar"}
          </ColoredButton>
        </div>
      </Container>
    </>
  );
}
