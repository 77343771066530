import styled, { keyframes } from "styled-components";
import { Button } from "~/componentes/CustomForm";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import Modal from "~/componentes/Modal";

const startNavbar = keyframes`
  100% {
    top: 0;
  }
`;

export const Container = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  top: -4.5em;
  padding: 0.8em 2em;
  background-color: #fff;
  display: flex;
  align-content: center;
  align-items: center;
  height: 4.5em;
  display: flex;
  border-bottom-left-radius: 0.8em;
  border-bottom-right-radius: 0.8em;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  z-index: 500;
  animation: ${startNavbar} 0.3s ease forwards;
  transition: 0.25s ease;
`;

export const LogoButton = styled(Button)`
  img {
    height: 2.5em;
    margin-top: 0.5em;
  }
`;

export const DesktopMenuButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
`;

export const ThisColoredButton = styled(ColoredButton)`
  margin: 0.5em;
  min-width: 8em;
`;

export const Perfil = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5em;
  align-self: center;

  .letterButton {
    border: none;
    line-height: 1;
    background: #364859;
    font-size: 1.5em;
    height: 1.5em;
    max-height: 1.5em;
    width: 1.5em;
    max-width: 1.5em;
    border-radius: 50%;
    color: #fff;
    transition: 300ms;

    :hover {
      transform: scale(1.05);
    }
  }
`;

export const PerfilModal = styled(Modal)`
  position: absolute;
  right: 0;
  top: 2.5em;
  width: max-content;
  padding: 0.5em;
  box-shadow: 0 0 0.5em #0003;
  border-radius: 0.3em;
  background: #fff;

  button {
    border: none;
    background: none;
    outline: 0;
    cursor: pointer;
    display: block;
    font-size: 1.1em;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 0.05em solid #c0c0c0;
    transition: 300ms;

    .PerfilModal_Button_icon {
      margin-right: 0.5em;
    }

    :hover {
      transform: scale(1.05);
    }
  }

  button:not(:first-child) {
    margin-top: 0.5em;
  }
`;
