import React, { useState } from "react";

import { Container, ThisFooterButtons } from "./styles";
import { Form } from "~/componentes/CustomForm";
import Label from "~/componentes/CustomForm/Label";
import { EduquemeInput } from "~/componentes/CustomForm/Input/styles";
import { useDispatch } from "react-redux";
import { toggleDisableButton } from "~/store/modules/disableButtons/actions";
import { signInRequest } from "~/store/modules/auth/actions";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import api from "~/services/api";
import ThisFormElements from "./ThisFormElements";
import CustomToast from "~/componentes/CustomToast";

function ModalLogin({ setModalLogin }) {
  const dispatch = useDispatch();
  const [cadastro, setCadastro] = useState(false);
  const [animationClass, setAnimationClass] = React.useState(false);

  async function handleLogin({ email, senha }) {
    dispatch(toggleDisableButton());
    try {
      dispatch(signInRequest(email, senha, true));
      dispatch(toggleDisableButton());
    } catch {
      dispatch(toggleDisableButton());
    }
  }

  async function handleCadastro(data) {
    dispatch(toggleDisableButton());
    try {
      const response = await api.post("usuario", data);

      if (response.data) {
        //alert("Conta criada");
        CustomToast.success("Conta criada com sucesso!");
        setCadastro(false);
      } else {
        CustomToast.success("Erro ao criar conta!");
      }
      dispatch(toggleDisableButton());
    } catch(err) {
      if(err.response.data.error){
        if(err.response.data.error == "A senha está incorreta" || err.response.data.error == "Usuário não foi encontrado"){
          CustomToast.error(
            "Email ou senha incorretos."
          );
        } else {
          CustomToast.error(
            err.response.data.error
          );
        }
      } else {
        CustomToast.error(
          "Falha ao tentar entrar no sistema. Verifique o email e a senha."
        );
      }
      dispatch(toggleDisableButton());
    }
  }

  return (
    <Container title="Entrar" outClick={setModalLogin}>
      <p className="Matricula_ModalLogin_p_loginMsg">Efetue o login para escolher a instituição</p>
      <Form onSubmit={cadastro ? handleCadastro : handleLogin}>
        {/* <Label title="Email">
          <EduquemeInput materialIcon="email" name="email" />
        </Label>
        <Label title="Senha">
          <EduquemeInput materialIcon="lock" name="senha" type="password" />
        </Label> */}
        <ThisFormElements {...{ animationClass, cadastro }} />
        {/*<Button
          link="/entrar"
          type="button"
          className="Matricula_ModalLogin_Button_recSenha"
        >
          Esqueci minha senha
        </Button> */}
        <ThisFooterButtons>
          <ColoredButton type="submit" className="Matricula_ModalLogin_Button_footer" greenLayout>
            {cadastro ? "Cadastrar" : "Entrar"}
          </ColoredButton>
          <p>OU</p>
          <ColoredButton
            className="Matricula_ModalLogin_Button_footer" 
            type="button"
            onClick={() => {
              if (cadastro) {
                setTimeout(() => setCadastro(false), 350);
              } else {
                setCadastro(true);
              }
              setAnimationClass(cadastro ? "animationOut" : "animationIn");
            }}
            {...(cadastro ? { yellowLayout: true } : { blueLayout: true })}
          >
            {cadastro ? "Voltar" : "Cadastrar-se"}
          </ColoredButton>
        </ThisFooterButtons>
        {/* <ModalFooter closeModal={closeModal} /> */}
      </Form>
    </Container>
  );
}

export default ModalLogin;
