import React from "react";
import { useState } from "react";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTableCell } from "~/pages/Sistema/componentes/SistemaList";
import { ThisTable } from "./styles";

function TableComponent({ }) {

  return (
    <>
      <SistemaComponenteInterno>
        <ThisTable>
          <SLTableCell name="tipo" className="truncate" inputfilter>
            Tipo de Token
          </SLTableCell>
          <SLTableCell name="token" className="truncate" inputfilter>
            Token
          </SLTableCell>
        </ThisTable>
      </SistemaComponenteInterno>
    </>
  );
}

export default TableComponent;
