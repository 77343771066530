import React, { useState } from "react";
import DisciplinasFilialList from "../DisciplinasFilialList";
import FindOrCreateUser from "~/componentes/FindOrCreateUser";

import { Container } from "./styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import api from "~/services/api";

function CreateContent() {
  const [disciplinasFilial, setDisciplinasFilial] = useState([]);
  const [usuario, setUsuario] = useState();

  async function handleSubmit(data, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let response = await api.post("filial/professor", {
          ...data,
          disciplinasFilial,
          usuario,
        });

        if (response?.status === 200 && closeReload) {
          closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao atualizar esse professor, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja atualizar esse professor?`,
        successModal: `Professor atualizado com sucesso!`,
      }
    );
  }

  return (
    <Container defaultStyle onSubmit={handleSubmit}>
      <FindOrCreateUser setter={setUsuario} />
      <DisciplinasFilialList setter={setDisciplinasFilial} />
    </Container>
  );
}

export default CreateContent;
