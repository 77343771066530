import React from "react";

import { Container } from "./styles";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import Label from "~/componentes/CustomForm/Label";

function CreateContent() {
  return (
    <Container defaultStyle onSubmit={(data) => {}}>
      <Label title="TÍTULO">
        <SistemaInput name="titulo" placeholder="Título do chamado" />
      </Label>
      <Label title="Email de cópia">
        <SistemaInput name="EMAIL" placeholder="Email" />
      </Label>
      <Label title="DESCRIÇÃO">
        <SistemaTextArea name="descricao" />
      </Label>
      <Label name="TIPO" title="Tipo de solicitação">
        <SistemaSelect>
          <option>Selecione</option>
          <option>Selecione</option>
          <option>Selecione</option>
        </SistemaSelect>
      </Label>
    </Container>
  );
}

export default CreateContent;
