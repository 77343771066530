import React from "react";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import { Button } from "~/componentes/CustomForm";
import { ItemLabel } from "./styles";
import CheckBox from "~/componentes/CustomForm/Check/styles/CheckBox";

// AJUSTAR: performance do textArea do item.

function Item({ title, data, handleData, handleRemoveItem, ...rest }) {
  return (
    <ItemLabel
      className="col s12 m12 l6"
      title={`${title}: ${data?.gabarito === true ? "Verdadeiro" : "Falso"}`}
      {...rest}
    >
      <div className="Questoes_Item_enunciado">
        <SistemaTextArea
          value={data?.enunciado}
          onChange={(e) => {
            handleData({ ...data, enunciado: e.target.value });
          }}
          placeholder="Descrição do item"
        />
        <div className="Questoes_Item_div_icons">
          <div className="Questoes_Item_resposta">
            <CheckBox
              onChange={(e) => {
                const value = e.currentTarget.checked;
                handleData({ ...data, gabarito: value });
              }}
              defaultHtml
              defaultChecked={data?.gabarito}
            ></CheckBox>
          </div>
          <span className="span-divider">&nbsp;</span>
          <Button type="button" clean onClick={handleRemoveItem}>
            <i className="material-icons">delete_outline</i>
          </Button>
        </div>
      </div>
    </ItemLabel>
  );
}

export default Item;
