import React, { useState } from "react";

import { Container } from "./styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import Label from "~/componentes/CustomForm/Label";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";
import QuestoesList from "../QuestoesList";
import { Materialize } from "~/styles/materializeGrid";

function ShowContent({ mountedData, disciplinasFilial, questoesNiveis, tiposQuestoes }) {
  const [questaoContent, setQuestaoContent] = useState();

  return (
    <>
      {questaoContent && (
        <SistemaFixedModal
          centerDefault
          outClick={() => {
            setQuestaoContent();
          }}
          title="Questão"
        >
          {questaoContent}
        </SistemaFixedModal>
      )}
      <Container>
        <CustomScrollBar fixScrollMargin autoHeightMax="25em">
          <Materialize>
            <div className="row">
              <Label
                title="Nome"
                text={mountedData?.nome || ""}
                className="col s12 m12 l6"
              />
              <Label
                title="Nota Máx."
                text={mountedData?.notaMaxima || ""}
                className="col s12 m12 l2"
              />
              <Label
                title="Disciplina"
                text={mountedData?.disciplinaFilial?.nome || ""}
                className="col s12 m12 l4"
              />
              <Label
                title="Descrição"
                text={mountedData?.descricao || ""}
                className="col s12 m12 l12"
              />
              <QuestoesList
                className="col s12 m12 l12"
                showOnly
                {...{
                  defaultData: mountedData?.questoes || [],
                  setQuestaoContent,
                  disciplinaFilialCod: mountedData?.disciplinaFilialCod,
                  disciplinasFilial,
                  questoesNiveis,
                  tiposQuestoes,
                }}
              />
            </div>
          </Materialize>
        </CustomScrollBar>
      </Container>
    </>
  );
}

export default ShowContent;
