/* eslint-disable no-useless-computed-key */
import React, { useState, useLayoutEffect } from "react";

import api from "~/services/api";
import ShowContent from "./ShowContent";
import { SLShowModal } from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import TableComponent from "./TableComponent";
import Label from "~/componentes/CustomForm/Label";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";

function AdicionarNotas() {
  const [mountedData, setMountedData] = useState();
  const [periodosLetivos, setPeriodosLetivos] = useState([]);
  const [series, setSeries] = useState([]);
  const [tabFilter, setTabFilter] = useState({ ativo: true });
  const [bimestre, setBimestre] = useState(1);
  const [codBimestre, setCodBimestre] = useState(1);

  useLayoutEffect(() => {
    api.get("professor/series").then((response) => setSeries(response?.data || []));

    api
      .get("professor/periodosLetivos", { params: { ativo: true } })
      .then((response) => setPeriodosLetivos(response?.data || []));
  }, []);

  return (
    <Container
      requests={{
        findAll: () => api.get("professor/turmas"),
        findOne: (thisData) => api.get("professor/turma/alunos", { params: { cod: thisData.cod } }),
      }}
      inputs={[
        { filter: "turma.nome", label: "Nome" },
        { filter: "turma.serie.nome", label: "Série" },
        { filter: "turma.periodoLetivo.nome", label: "Período Letivo" },
        { filter: "disciplinaFilial.nome", label: "Disciplina" },
      ]}
      selects={[
        {
          filter: "turma.periodoLetivoCod",
          list: periodosLetivos,
          outLabel: "Período Letivo",
          value: "cod",
          label: "nome",
        },
        {
          filter: "turma.serieCod",
          list: series,
          outLabel: "Série",
          value: "cod",
          label: "nome",
        },
      ]}
      dataFilter={tabFilter}
    >
      {/* <Label
        title="Bimestre:"
        className="col s12 m12 l3">
        <SistemaInput name={'bimestre'} defaultValue={bimestre} required />
      </Label> */}
      <TableComponent {...{ setTabFilter, bimestre, codBimestre, setBimestre, setCodBimestre }} />

      <SLShowModal title="Turma" setter={setMountedData}>
        <ShowContent {...{ mountedData, bimestre, codBimestre, setMountedData}} />
      </SLShowModal>
    </Container>
  );
}

export default AdicionarNotas;
