import React from 'react';
import VideoconferenciaComponente from '.';
import {StreamContextProvider} from './context'

export default function Videoconferencia() {
    return (
        <StreamContextProvider>
            <VideoconferenciaComponente />
        </StreamContextProvider>
    )
}