/* eslint-disable eqeqeq */
import React from "react";
import { Bar } from "react-chartjs-2";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";

import { Container, ChartContainer } from "./styles";

const optionsData = {
  responsive: true,
  legend: {
    display: false,
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },

        barThickness: 12, // number (pixels) or 'flex'
        maxBarThickness: 12, // number (pixels)
      },
    ],
  },
};

function BarChart({ disciplinasTurmas, setSelectedDTM }) {
  const [disciplinaTurma, setDisciplinaturma] = React.useState("");

  React.useEffect(() => {
    typeof setSelectedDTM === "function" && setSelectedDTM(disciplinaTurma);
  }, [disciplinaTurma, setSelectedDTM]);

  React.useEffect(() => {
    setDisciplinaturma((prev) => {
      if (!disciplinasTurmas?.find((e) => e?.cod === prev?.cod)) {
        return "";
      } else {
        return prev;
      }
    });
  }, [disciplinasTurmas]);

  const labels = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  const datasets = React.useMemo(() => {
    const result = [
      {
        label: "Nota Baixa",
        backgroundColor: "#FF4031",
        data: labels.map(() => 0),
      },
      {
        label: "Nota Alta",
        backgroundColor: "#4FA754",
        data: labels.map(() => 0),
      },
    ];

    function handleMediaAnual(dtm) {
      if (dtm?.mediaAnual) {
        Object.values(dtm.mediaAnual).forEach((e, i) =>
          e.forEach((nota) => {
            if (nota < 70) {
              result[0].data[i] += 1;
            } else {
              result[1].data[i] += 1;
            }
          })
        );
      }
    }

    if (disciplinaTurma?.cod) {
      handleMediaAnual(disciplinaTurma);
    } else if (Array.isArray(disciplinasTurmas)) {
      disciplinasTurmas.forEach(handleMediaAnual);
    }
    return result;
  }, [disciplinaTurma, disciplinasTurmas, labels]);

  return (
    <Container className="chart1">
      <div className="chart-container">
        <div className="chart-header">
          <h4>Desempenho dos alunos</h4>
          <SistemaSelect
            firstOption="Todos"
            value={disciplinaTurma?.cod || ""}
            list={{
              list: disciplinasTurmas,
              value: "cod",
              label: "disciplinaFilial.nome",
            }}
            onChange={(e) => {
              const value = e.currentTarget.value;
              const findThis = disciplinasTurmas?.find((e) => e?.cod == value);
              setDisciplinaturma(findThis || "");
            }}
          />
        </div>
        <ChartContainer>
          <Bar data={{ labels, datasets }} options={optionsData} />
        </ChartContainer>
      </div>
    </Container>
  );
}

export default BarChart;
