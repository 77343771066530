import React from "react";
import { Switch } from "react-router-dom";
import CustomRoute from "./CustomRoute";

import * as Global from "~/pages/Global";
// import Sistema from "~/componentes/Layouts/Sistema";
import Sistema from "~/pages/Sistema";
import * as layouts from "~/componentes/Layouts";
// import ValidationRoutes from "./ValidationRoutes";

export default function Routes() {
  return (
    <Switch>
      {/* <ValidationRoutes /> */}
      {/* Rotas globais */}
      <CustomRoute
        path="/"
        exact
        layout={layouts.Principal}
        component={Global.Inicio}
      />
      <CustomRoute
        path="/matricula"
        layout={layouts.Principal}
        component={Global.Matricula}
      />
      <CustomRoute
        path="/blog"
        layout={layouts.Principal}
        component={Global.Blog}
      />
      <CustomRoute
        path="/contato"
        layout={layouts.Principal}
        component={Global.Contato}
      />
      <CustomRoute
        path="/entrar"
        layout={layouts.Principal}
        component={Global.Entrar}
      />

      {/* Sistema */}
      <CustomRoute path="/sistema" component={Sistema} privado />
    </Switch>
  );
}
