import styled from "styled-components";

export const Container = styled.div`
  width: 80em;
  max-width: 100%;

  textarea {
    height: 16em;
  }
`;
