import React from "react";

import { Container } from "./styles";
import sistemaImg from "~/assets/figuras/tela_sistema.png";

export default function Planos({ desktop }) {
  return (
    <Container desktop={desktop}>
      <div className="infor">
        <h1>Aquisição da Plataforma</h1>
        <p>
          Entre em contato com a Objetiveti agora mesmo e agende sua visita. Neste período podemos motivar os alunos a manter o desenvolvimento
          escolar mesmo de casa.
          <br></br>
          <b>DEMONSTRAÇÃO GRÁTIS POR 7 DIAS!!</b>
        </p>
        <h5>contato@objetiveti.com.br</h5>
        <h5>(85) 3047-7001</h5>
      </div>
      <div className="img-container">
        <img src={sistemaImg} alt="Sistema Eduque-me" />
      </div>
    </Container>
  );
}
