import React from "react";

const customListStore = React.createContext({
  state: {},
  dispatch: () => {},
});

export default customListStore;

export function customListSetProps(props) {
  return { type: "customListSetProps", payload: { props } };
}

export function customListUpdateChildrens(childrens) {
  return { type: "customListUpdateChildrens", payload: { childrens } };
}

export function customListSetData(data, requests) {
  return { type: "customListSetData", payload: { data, requests } };
}

export function customListDeleteData(data) {
  return { type: "customListDeleteData", payload: { data } };
}

export function customListUpdateTableData(filteredData) {
  return { type: "customListUpdateTableData", payload: { filteredData } };
}

export function customListUpdateRowData(rowData) {
  return { type: "customListUpdateRowData", payload: { rowData } };
}

export function customListToggleModal(modal) {
  return { type: "customListToggleModal", payload: { modal } };
}

export function customListEditData(rowData) {
  return { type: "customListEditData", payload: { rowData } };
}

export function customListSortData() {
  return { type: "customListSortData" };
}

export function customListFilters(filters) {
  return { type: "customListFilters", payload: { filters } };
}

export function customListFilterConfig(filtersConfig) {
  return { type: "customListFilterConfig", payload: { filtersConfig } };
}

export function customListDataPage(page) {
  return { type: "customListDataPage", payload: { page } };
}

export function customListModalChildrenProps(modalChildrenProps) {
  return { type: "customListModalChildrenProps", payload: { modalChildrenProps } };
}

// export function tableUpdatEditedData(editedData, deleteonnull) {
//   return { type: "tableUpdatEditedData", payload: { editedData, deleteonnull } };
// }
