import styled from "styled-components";

export const Container = styled.div`
  border-radius: 0.25em;
  background: #fff;
  box-shadow: 0 0 1.125em rgba(0, 0, 0, 0.03);
  padding: 0.5em;
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .filters {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .filter-item:not(:first-child) {
      margin-left: 0.8em;
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 5em;
  }
`;
