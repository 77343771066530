import styled from "styled-components";
import { SLForm } from "~/pages/Sistema/componentes/SistemaList";

export const Container = styled(SLForm)`
  width: 35em;
  max-width: 100%;
`;

export const SubTitle = styled.h4`
  color: #bbb5b5;
  line-height: 1.5em;
  font-weight: 600;
  font-size: 0.8em;
`;

export const Title = styled.h2`
  color: #293f57;
  line-height: 1.5em;
  font-weight: 600;
  text-align: center;
  font-size: 1.4em;
`;

export const AmountContainer = styled.div`
  background: #a3a3a35e;
  display: flex;
  justify-content: center;
  width: 8.5em;
  border-radius: 0.6em;
  margin-block: 20px;
`;

export const Amount = styled.h2`
  color: #293f57;
  line-height: 1.5em;
  font-weight: 600;
  text-align: center;
  font-size: 1.3em;
`;
