import React from "react";
import { Document } from "@react-pdf/renderer";

import {
  Content,
  Strong,
  Title,
  Paragraph,
  Card,
  TwoColumnCell,
  ColumnCell,
  FourColumnCell,
  Nota,
} from "./styles";
import handleDate from "~/utils/handleDate";

function PdfDoc({ avaliacoes, data, perfil }) {
  return (
    <Document>
      <Content>
        <Title>Boletim Escolar</Title>
        <Card>
          <Paragraph>
            <Strong>Nome:</Strong> {perfil?.nome}
          </Paragraph>
          <Paragraph>
            <Strong>Turma:</Strong> {data?.alunos[0].alunoTurmas[0].turma.nome}
          </Paragraph>
          <TwoColumnCell>
            <Strong>Email:</Strong> {perfil?.email}
          </TwoColumnCell>
          <TwoColumnCell>
            <Strong>Matrícula:</Strong> {data?.alunos[0]?.matricula}
          </TwoColumnCell>
          <TwoColumnCell>
            <Strong>Série:</Strong> {data?.alunos[0].alunoTurmas[0].turma.serie.nome}
          </TwoColumnCell>
          <TwoColumnCell>
            <Strong>Período Letivo:</Strong> {data?.alunos[0].alunoTurmas[0].turma.periodoLetivo.nome}
          </TwoColumnCell>
        </Card>
        <Card>
          {data?.alunos[0].alunoTurmas[0].adt.map((el)=>{
            console.log(el)
            return <>
              <Paragraph>
                {el.disciplinaTurma.disciplinaFilial.nome}
              </Paragraph>
              <FourColumnCell>
                <Paragraph>Bimestre 1: </Paragraph> 
                <Nota  style={{ fontWeight: 'bold'}}>
                  {
                    (el.notas.reduce((acc, atual)=> acc + (atual.periodo.periodoCod == 1? atual.notaAvaliacao : 0), 0 )
                    / el.disciplinaTurma.quantidadeAvaliacoes).toFixed(1)
                  }
                </Nota>
              </FourColumnCell>
              <FourColumnCell>
                <Paragraph>Bimestre 2: </Paragraph>
                <Nota  style={{ fontWeight: 'bold'}}>
                  {
                    (el.notas.reduce((acc, atual)=> acc + (atual.periodo.periodoCod == 2? atual.notaAvaliacao : 0), 0 )
                    / el.disciplinaTurma.quantidadeAvaliacoes).toFixed(1)
                  }
                </Nota>
              </FourColumnCell>
              <FourColumnCell>
                <Paragraph>Bimestre 3: </Paragraph>
                <Nota  style={{ fontWeight: 'bold'}}>
                  {
                    (el.notas.reduce((acc, atual)=> acc + (atual.periodo.periodoCod == 3? atual.notaAvaliacao : 0), 0 )
                    / el.disciplinaTurma.quantidadeAvaliacoes).toFixed(1)
                  }
                </Nota>
              </FourColumnCell>
              <FourColumnCell>
                <Paragraph>Bimestre 4: </Paragraph>
                <Nota  style={{ fontWeight: 'bold'}}>
                  {
                    (el.notas.reduce((acc, atual)=> acc + (atual.periodo.periodoCod == 4? atual.notaAvaliacao : 0), 0 )
                    / el.disciplinaTurma.quantidadeAvaliacoes).toFixed(1)
                  }
                </Nota>
              </FourColumnCell>
            </>
          })}
        </Card>
        {/* <Title>Avaliacoes</Title>
        {avaliacoes?.map?.((av, i) => (
          <Card key={av?.cod || i}>
            <TwoColumnCell>
              <Strong>Disciplina:</Strong> {av?.disciplinaFilial?.nome}
            </TwoColumnCell>
            <TwoColumnCell>
              <Strong>Turma:</Strong> {av?.turma?.nome}
            </TwoColumnCell>
            <TwoColumnCell>
              <Strong>Série:</Strong> {av?.serie?.nome}
            </TwoColumnCell>
            <TwoColumnCell>
              <Strong>Período Letivo:</Strong> {av?.periodoLetivo?.nome}
            </TwoColumnCell>
            <Paragraph>
              <Strong>Professor:</Strong> {av?.professor?.usuario?.nome}
            </Paragraph>
            <ColumnCell>
              <Strong>Corrigido:</Strong> {av?.corrigido ? "Sim" : "Não"}
            </ColumnCell>
            <ColumnCell>
              <Strong>Entregue:</Strong> {av?.entregue ? "Sim" : "Não"}
            </ColumnCell>
            <ColumnCell>
              <Strong>Nota:</Strong> {av?.nota}
            </ColumnCell>
            <ColumnCell>
              <Strong>Nota Máxima:</Strong> {av?.notaMaxima}
            </ColumnCell>
            <Paragraph>
              <Strong>Data da avaliação:</Strong>{" "}
              {handleDate(av?.dataInicio).dmyhm} até{" "}
              {handleDate(av?.dataFim).dmyhm}
            </Paragraph>
          </Card>
        ))} */}
      </Content>
    </Document>
  );
}

export default PdfDoc;
