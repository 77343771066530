import styled from "styled-components";

export const Container = styled.div`
  .card-style {
    background: #fff;
    box-shadow: 0 0.313em 0.938em rgba(0, 0, 0, 0.1);
    border-radius: 0.375em;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .card-container {
      padding: 0.8em;
    }
    .card-header {
      width: 100%;
      padding-bottom: 3em;

      h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 1.375em;
        line-height: 1.625em;
        color: #293f57;
      }
    }
    .one-line {
      width: 100%;
      text-align: center;
      margin-bottom: 0.5em;

      h5 {
        padding-bottom: 0.2em;
        font-style: normal;
        font-weight: 600;
        font-size: 1.5em;

        color: #293f57;
      }
      h6 {
        font-style: normal;
        font-weight: 600;
        font-size: 1em;
        line-height: 1.375em;

        color: #9ab1b6;
      }
    }
  }
`;

export const FirstSection = styled.div`
  display: grid;
  height: auto;
  max-height: 24em;
  grid-template-columns: 2fr 18em 18em;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "chart1 chart2 chart3"
    "chart1 chart2 chart3"
    "chart1 chart4 chart5"
    "chart1 chart4 chart6";
  grid-gap: 1em;

  .mini-card {
    background: #ffffff;
    box-shadow: 0 0.313em 0.938em rgba(0, 0, 0, 0.1);
    border-radius: 0.375em;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.8em;
    i {
      position: relative;
      padding: 0 0.8em 0 0.4em;
      z-index: 1;
    }
    i::after {
      content: "";
      position: absolute;
      width: 1.5em;
      height: 1.5em;
      background: #cdecf5;
      border-radius: 50%;
      left: 0.13em;
      top: -0.2em;
      z-index: -1;
    }
    .card-flex {
      display: flex;
      align-items: center;
      h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 1.375em;
        line-height: 1.375em;
        color: #293f57;
      }
      h6 {
        margin-bottom: 0.3em;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.375em;

        color: #9ab1b6;
      }
    }
  }
  .chart1 {
    grid-area: chart1;
  }
  .chart2 {
    grid-area: chart2;
  }
  .chart3 {
    grid-area: chart3;
  }
  .chart4 {
    grid-area: chart4;
  }
  .chart5 {
    grid-area: chart5;
  }
  .chart6 {
    grid-area: chart6;
  }

  @media only screen and (max-width: 993px) {
    grid-template-rows: 1fr 8em 6em;
    grid-template-columns: 2fr 1fr 1fr;
    max-height: initial;
    grid-template-areas:
      "chart1 chart4 chart4"
      "chart3 chart2 chart5"
      "chart3 chart2 chart6";
  }
  @media only screen and (max-width: 601px) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    max-height: initial;
    grid-template-areas:
      "chart1"
      "chart2"
      "chart3"
      "chart4"
      "chart5"
      "chart6";
  }
`;

export const SecondeSection = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;

  grid-template-areas:
    "chart7 chart8"
    "chart7 chart8";
  grid-gap: 1em;
  margin-top: 1em;

  .list-header {
    padding-bottom: 0.5em;
    border-bottom: solid #f5f5f5 0.125em;
    margin: 0 0.5em;
    padding: 0.8em;

    h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 1.375em;
      line-height: 1.625em;
      color: #293f57;
    }
  }
  .list-container {
    padding-right: 1.2em;
    padding-left: 0.5em;
  }
  .list-body {
    list-style: none;
    margin: 0 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: end;
    height: 20em;
    max-width: 100%;
    padding: 0.5em 0;
    overflow: hidden;
  }
  .list-body li {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0.75em 0;
    border-bottom: solid #f5f5f5 0.125em;
    span {
      color: #7b898c;
      cursor: pointer;
      position: relative;
    }
    span::before {
      content: "";
      position: absolute;
      background: rgba(28, 169, 208, 0.22);
      width: 1.5em;
      height: 1.5em;
      left: -0.25em;
      top: -0.25em;
      border-radius: 50%;
      transform: scale(0);
      transform-origin: center center;
      transition: 0.3s ease;
    }
    span:hover::before {
      transform: scale(1);
    }
    h3 {
      font-style: normal;
      font-weight: normal;
      font-size: 1.25em;
      color: #293f57;
    }
  }
  .list-page {
    display: flex;
    justify-content: flex-end;
    margin: 1em 0;
    div {
      align-items: center;
      display: flex;
      span {
        color: #7b898c;
        cursor: pointer;
        position: relative;
        margin: 0 1em;
      }
      span::before {
        content: "";
        position: absolute;
        background: rgba(28, 169, 208, 0.22);
        width: 1.5em;
        height: 1.5em;
        left: -0.25em;
        top: -0.25em;
        border-radius: 50%;
        transform: scale(0);
        transform-origin: center center;
        transition: 0.3s ease;
      }
      span:hover::before {
        transform: scale(1);
      }
    }
  }
  .card-field {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
  }

  .chart7 {
    grid-area: chart7;
  }
  .chart8 {
    grid-area: chart8;
  }

  @media only screen and (max-width: 993px) {
    grid-template-areas:
      "chart7 chart8"
      "chart7 chart8";
    grid-gap: 1em;
    margin-top: 1em;
  }
  @media only screen and (max-width: 601px) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    max-height: initial;
    grid-template-areas:
      "chart7"
      "chart8";
  }
`;

export const ChartConatiner = styled.div`
  width: ${(props) => `${props.width}%`};
  margin-left: ${(props) => (props.marginLeft ? `${props.marginLeft}em` : `auto`)};
`;
