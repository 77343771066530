import React from "react";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import Label from "~/componentes/CustomForm/Label";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import api from "~/services/api";
import ShowLoading from "~/utils/ShowLoading";
import { MaterializeContent } from "../styles";

import { Container } from "./styles";

function ThisFilter({ setData }) {
  const [periodosLetivos, setPeriodosLetivos] = React.useState([]);
  const [periodoLetivo, setPeriodoLetivo] = React.useState();
  const [serie, setSerie] = React.useState();
  const [turma, setTurma] = React.useState();

  React.useEffect(() => {
    api.get("filial/relatorios/turmas/periodosLetivosTurmas").then((r) => {
      setPeriodoLetivo(r?.data?.[0]);
      setSerie(r?.data?.[0]?.series?.[0]);
      setTurma(r?.data?.[0]?.series?.[0]?.turmas?.[0]);
      setPeriodosLetivos(r?.data || []);
    });
  }, []);

  async function fetchStats() {
    ShowLoading(() =>
      api
        .get("filial/relatorios/turmas/informacoesTurma", {
          params: {
            cod: turma?.cod,
          },
        })
        .then((r) => setData(r?.data))
        .catch(() => setData())
    );
  }

  return (
    <Container>
      <MaterializeContent>
        <div className="row">
          <div className="filters col s12 m12 l9">
            <Label title="Período Letivo" className="filter-item">
              <SistemaSelect
                list={{
                  list: periodosLetivos,
                  value: "cod",
                  label: "nome",
                }}
                onChange={(e) => {
                  const val = e.currentTarget.value;
                  const thisFind = periodosLetivos?.find((t) => t?.cod === val);
                  setPeriodoLetivo(thisFind);
                }}
                value={periodoLetivo?.cod}
              />
            </Label>
            <Label title="Série" className="filter-item">
              <SistemaSelect
                list={{
                  list: periodoLetivo?.series,
                  value: "cod",
                  label: "nome",
                }}
                onChange={(e) => {
                  const val = e.currentTarget.value;
                  const thisFind = periodoLetivo?.series?.find(
                    (t) => t?.cod === val
                  );
                  setSerie(thisFind);
                }}
                value={serie?.cod}
              />
            </Label>
            <Label title="Turma" className="filter-item">
              <SistemaSelect
                list={{
                  list: serie?.turmas,
                  value: "cod",
                  label: "nome",
                }}
                onChange={(e) => {
                  const val = e.currentTarget.value;
                  const thisFind = serie?.turmas?.find((t) => t?.cod === val);
                  setTurma(thisFind);
                }}
                value={turma?.cod}
              />
            </Label>
          </div>
          <div className="actions col s12 m12 l3">
            <ColoredButton
              // materialIcon={{ filterAlt: true }}
              yellowLayout
              onClick={fetchStats}
            >
              Procurar
            </ColoredButton>
          </div>
          {/* <div className="row">
            <Label
              className="col s12 m12 l6"
              title="Nome:"
              text={turma?.nome || ""}
            />
            <Label
              className="col s12 m12 l6"
              title="Email:"
              text={turma?.usuario?.email || ""}
            />
          </div> */}
        </div>
      </MaterializeContent>
    </Container>
  );
}

export default ThisFilter;
