import styled from "styled-components";
import ThisModalButtonHandler from "../../ThisMainComponent/ThisModalButtonHandler";

export const ThisSistemaListaIconButonStyle = styled(ThisModalButtonHandler)`
  position: relative;
  border: none;
  background: none;
  color: #7b898c;
  margin-left: 0.25em;
  margin-bottom: 0.25em;
  font-size: 1.4em;

  ::after {
    transition: 0.3s ease;
    content: "";
    position: absolute;
    background: rgba(28, 169, 208, 0.22);
    width: 1.5em;
    height: 1.5em;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
  }

  :hover::after {
    transform: translate(-50%, -50%) scale(1);
  }
`;
