/* eslint-disable eqeqeq */
import React from "react";
import { EduquemeSelect, SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import estados from "~/config/estados";

// import { Container } from './styles';

function Estado({ setDados, grupo, blueLayout, props }) {
  const ThisSelect = blueLayout ? EduquemeSelect : SistemaSelect;

  function handleChangeValue(e) {
    if (grupo?.chave && setDados) {
      const newValue = e.currentTarget.value;
      const thisEstado = estados?.find((est) => est?.cod == newValue);
      const { cidades, ...estRest } = thisEstado || {};

      setDados((prev) => ({
        ...prev,
        [grupo.chave]: {
          ...prev?.[grupo.chave],
          estado: estRest || "",
          cidade: "",
        },
      }));
    }
  }

  return (
    <ThisSelect
      firstOption
      {...props}
      list={{ list: estados, value: "cod", label: "nome" }}
      onChange={handleChangeValue}
    />
  );
}

export default Estado;
