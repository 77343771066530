import React from "react";

import { Container } from "./styles";
import Mensagem from "./Mensagem";
import Perguntas from "./Perguntas";
import UserDevice from "~/componentes/UserDevice";

export default function Contatos() {
  const userDevice = UserDevice();
  return (
    <Container userDevice={userDevice}>
      <Mensagem />
      <Perguntas />
    </Container>
  );
}
