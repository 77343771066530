/* eslint-disable eqeqeq */
import React from "react";
import IconButton from "~/componentes/CustomForm/Button/Styles/IconButton";

import { Container, QuestaoPageButton } from "./styles";

function QuestoesPagination({
  page,
  maxPage,
  // setNextPage,
  setPage,
  showResp,
  avaliacaoQuestao,
  respostaQuestoes,
}) {
  // React.useEffect(() => {
  //   if (typeof setNextPage === "function" && typeof setPage === "function") {
  //     setNextPage(() => () => {
  //       if (page < maxPage) {
  //         setPage(page + 1);
  //       }
  //       if (page >= maxPage) {
  //         setPage(1);
  //       }
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [maxPage, page]);

  return (
    <Container>
      <IconButton
        materialIcon="chevron_left"
        onClick={() => setPage(page <= 1 ? 1 : page - 1)}
      >
        Anterior
      </IconButton>
      <div className="QuestoesPagination_div_pageButtons">
        {avaliacaoQuestao?.map((qst, i) => {
          const thisResp = respostaQuestoes?.find((resp) => {
            return resp?.avaliacaoQuestaoCod === qst?.cod;
          });

          return (
            <QuestaoPageButton
              status={showResp ? String(thisResp?.statusResposta) : undefined}
              key={i}
              onClick={() => setPage(i + 1)}
            >
              {i + 1}
            </QuestaoPageButton>
          );
        })}
      </div>
      <IconButton
        materialIcon="chevron_right"
        onClick={() => setPage(page >= maxPage ? maxPage : page + 1)}
      >
        Próxima
      </IconButton>
    </Container>
  );
}

export default QuestoesPagination;
