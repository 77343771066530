import React from "react";
import { Document } from "@react-pdf/renderer";

import {
  Content,
  Strong,
  Title,
  Paragraph,
  Card,
  TwoColumnCell,
  ColumnCell,
} from "./styles";
import handleDate from "~/utils/handleDate";

function PdfDoc({ data, selectedDTM, avaliacoesDisponibilizadas }) {
  return (
    <Document>
      <Content>
        <Title>Relatório de {selectedDTM?.cod ? "Disciplina" : "Turma"}</Title>
        {selectedDTM?.cod && (
          <Paragraph>
            <Strong>Disciplina:</Strong> {selectedDTM?.disciplinaFilial?.nome}
          </Paragraph>
        )}
        <Paragraph>
          <Strong>Turma:</Strong> {data?.nome}
        </Paragraph>
        <Paragraph>
          <Strong>Série:</Strong> {data?.serie?.nome}
        </Paragraph>
        <Paragraph>
          <Strong>Período Letivo:</Strong> {data?.periodoLetivo?.nome}
        </Paragraph>
        <Paragraph>
          <Strong>Total de alunos:</Strong>{" "}
          {selectedDTM?.cod ? selectedDTM?.totalAlunos : data?.totalAlunos}
        </Paragraph>
        {!selectedDTM?.cod && (
          <>
            <Paragraph>
              <Strong>Vagas disponíveis:</Strong> {data?.vagasDisponiveis}
            </Paragraph>
            <Title>Disciplinas</Title>
            {data?.disciplinasTurmas?.map?.((dtm, dtmI) => (
              <Card key={dtm?.cod || dtmI}>
                <TwoColumnCell>
                  <Strong>Nome:</Strong> {dtm?.disciplinaFilial?.nome}
                </TwoColumnCell>
                <TwoColumnCell>
                  <Strong>Professor:</Strong> {dtm?.professor?.usuario?.nome}
                </TwoColumnCell>
                <ColumnCell>
                  <Strong>Alunos:</Strong> {dtm?.totalAlunos}
                </ColumnCell>
                <ColumnCell>
                  <Strong>Avaliações:</Strong>{" "}
                  {dtm?.totalAvaliacoesDisponibilizadas}
                </ColumnCell>
                <ColumnCell>
                  <Strong>Avaliações Corrigidas:</Strong> {dtm?.totalCorrigidas}
                </ColumnCell>
              </Card>
            ))}
          </>
        )}
        <Title>Avaliacoes</Title>
        {avaliacoesDisponibilizadas?.map?.((avt, i) => (
          <Card key={avt?.cod || i}>
            <TwoColumnCell>
              <Strong>Nome da Avaliação:</Strong> {avt?.avaliacao?.nome}
            </TwoColumnCell>
            {!selectedDTM?.cod && (
              <TwoColumnCell>
                <Strong>Disciplina:</Strong> {avt?.disciplina}
              </TwoColumnCell>
            )}
            <ColumnCell>
              <Strong>Corrigido:</Strong> {avt?.corrigido ? "Sim" : "Não"}
            </ColumnCell>
            <Paragraph>
              <Strong>Data da avaliação:</Strong>{" "}
              {handleDate(avt?.dataInicio).dmyhm} até{" "}
              {handleDate(avt?.dataFim).dmyhm}
            </Paragraph>
          </Card>
        ))}
      </Content>
    </Document>
  );
}

export default PdfDoc;
