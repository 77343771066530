import React from "react";

import { useState } from "react";
import { useEffect } from "react";
import api from "~/services/api";
import CustomList, {
  CLTableHeader,
  CLTableBody,
  CLTableCell,
} from "~/pages/Sistema/componentes/CustomList";
import CreateModal from "./CreateModal";
import ShowModal from "./ShowModal";
import EditModal from "./EditModal";

export default function Turmas() {
  const [cargos, setCargos] = useState([]);
  const [modulos, setModulos] = useState([]);

  useEffect(() => {
    async function findEmpresas() {
      const response = await api.get("filial/cargosEmpresa");
      setCargos(response?.data);
    }
    async function findMenus() {
      const menus = await api.get("filial/acessos");
      setModulos(menus.data);
    }

    findEmpresas();
    findMenus();
  }, []);
  return (
    <CustomList
      requests={{
        findAll: () => api.get("/filial/cargosFilial"),
        deleteOne: (cargo) =>
          api.delete("/filial/cargoFilial", { data: { cod: cargo.cod } }),
        findOne: (cargo) =>
          api.get("/filial/cargoFilial", { params: { cod: cargo.cod } }),
      }}
    >
      <CLTableHeader>
        <th style={{ width: "100%", textAlign: "left" }}>NOME</th>
      </CLTableHeader>

      <CLTableBody>
        <CLTableCell search={"Cargo"} name="nome" />
      </CLTableBody>

      {ShowModal({ modulos })}
      {EditModal({ cargos, modulos })}
      {CreateModal({ cargos, modulos })}
    </CustomList>
  );
}
