import styled from "styled-components";
import SistemaList from "~/pages/Sistema/componentes/SistemaList";

export const Container = styled(SistemaList)`
  .pai-planeta-card {
    display: flex;
    flex-wrap: wrap;
  }

  .pai-planeta-card .planeta-card {
    flex: 0 1 calc(33.33%);
    padding: 0.5em 0.5em;
  }

  @media (max-width: 1200px) {
    .pai-planeta-card .planeta-card {
      flex: 0 1 calc(50%);
    }
  }

  @media (max-width: 520px) {
    .pai-planeta-card .planeta-card {
      flex: 0 1 calc(100%);
    }
  }

  .pai-planeta-card .planeta-card .planeta-card-box {
    padding: 0.5em 1em;
    height: 28.2em;
    border-radius: 0.1em;
    box-shadow: 0px 0.05em 1em rgba(0, 0, 0, 0.09);
  }

  .pai-planeta-card .planeta-card-box .planeta-card-head {
    width: 100%;
    height: 4em;
    padding: 0.6em 0;
    border-bottom: 1px solid #dedeed;
  }

  .pai-planeta-card .planeta-card-box .planeta-card-head .title-card {
    font-weight: 600;
  }

  .pai-planeta-card .planeta-card-box .planeta-card-head .sub-title-card {
    font-weight: 500;
    color: #a0a0a0;
    font-size: 0.8em;
  }

  .pai-planeta-card .planeta-card-box .planeta-card-body {
    padding: 1em;
    height: 21em;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pai-planeta-card .planeta-card-box .planeta-card-body img {
    max-width: 100%;
    max-height: 100%;
  }

  .pai-planeta-card .planeta-card-box .planeta-card-footer {
    height: 2.7em;
  }

  .pai-planeta-card .planeta-card-box .planeta-card-footer .card-estrelas {
    min-width: 5em;
    border-radius: 0.6em;
    background: #1f3346;
    color: #fff;
    font-weight: 600;
    font-size: 1.1em;
    padding: 0.5em;
    float: left;
  }

  .pai-planeta-card .planeta-card-box .planeta-card-footer .card-estrelas p {
    line-height: 22px;
  }

  .pai-planeta-card .planeta-card-box .planeta-card-footer .card-estrelas img {
    margin-right: 0.6em;
    width: 1.2em;
    float: left;
  }

  .pai-planeta-card .planeta-card-box .planeta-card-footer .card-action {
    float: right;
    margin-top: 0.65em;
  }
`;
