import React from "react";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTable, SLTableCell } from "~/pages/Sistema/componentes/SistemaList";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";

function TableComponent({ setTabFilter }) {
  return (
    <>
      <TabsWrapper tabHeight={2.4}>
        <Tab
          materialIcon="check_circle_outline"
          onClick={() =>
            setTabFilter({
              valido: true,
            })
          }
        >
          Ativo
        </Tab>
        <Tab
          materialIcon="error_outline"
          onClick={() =>
            setTabFilter({
              valido: false,
            })
          }
        >
          Expirado
        </Tab>
      </TabsWrapper>

      <SistemaComponenteInterno>
        <SLTable>
          <SLTableCell name="nome" inputfilter>
            Nome
          </SLTableCell>
          <SLTableCell isDateOnly name="dataInicio">
            Início
          </SLTableCell>
          <SLTableCell isDateOnly name="dataFim">
            Término
          </SLTableCell>
        </SLTable>
      </SistemaComponenteInterno>
    </>
  );
}

export default TableComponent;
