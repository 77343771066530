import React, { useState, useEffect, useContext } from "react";

import { StyledTable } from "./styles";
import FindKeyValue from "~/utils/FindKeyValue";
import { IsObject, IsFunction } from "~/utils/CheckDataType";
import sistemaListStore, {
  sistemaListUpdateFilterConfig,
} from "../../ThisMainComponent/thisStore";
import { useMemo } from "react";
import { SLShowButton, SLDeleteButton, SLEditButton, SLRestoreButton, SLChangeStatusButton, SLExcelPDFButton } from "../..";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import Pagination from "~/componentes/Pagination";
import thisHandleSelectFilter from "../../utils/thisHandleSelectFilter";

function ThisTable({
  children,
  noActions,
  inputs: inputsProp,
  selects: selectsProp,
  noEdit,
  noExcel,
  noPDF,
  pageSize = 7,
  noDelete,
  noShow,
  noRestore,
  noChangeStatus,
  buttonIcon,
  data: dataProp,
  tabFilter = {},
  ...rest
}) {
  const { state, dispatch } = useContext(sistemaListStore);
  const { data: dataState, requests } = state || {};
  const [bodyCells, setBodyCells] = useState([]);
  const [pageData, setPageData] = useState([]);

  const data = useMemo(() => (pageSize ? pageData : dataProp || dataState), [
    dataProp,
    dataState,
    pageData,
    pageSize,
  ]);

  useEffect(() => {
    let thisCells = [];
    let inputs = Array.isArray(inputsProp) ? [...inputsProp] : [];
    const checkSelectsProp = thisHandleSelectFilter(selectsProp);
    let selects = Array.isArray(checkSelectsProp) ? [...checkSelectsProp] : [];

    React.Children.forEach(children, (child) => {
      const { name, inputfilter, selectfilter, children: childChildren, ...rest } =
        child?.props || {};

      if (name) {
        thisCells.push({ name, ...rest });

        function checkOutLabel(filt) {
          return typeof filt === "string"
            ? filt
            : typeof childChildren === "string" && childChildren;
        }

        if (inputfilter) {
          const thisInputLabel = checkOutLabel(inputfilter);

          thisInputLabel && inputs.push({ filter: name, label: thisInputLabel });
        }

        if (IsObject(selectfilter)) {
          const { outLabel, ...thisRest } = selectfilter;
          const thisOutLabel = checkOutLabel(outLabel);

          const thisCheckSelect = thisHandleSelectFilter({
            ...thisRest,
            filter: name,
            outLabel: thisOutLabel,
          });

          if (thisCheckSelect) {
            selects.push(thisCheckSelect);
          }
        }
      } else {
        thisCells.push({});
      }
    });

    setBodyCells(thisCells);
    dispatch(sistemaListUpdateFilterConfig({ inputs, selects }));
  }, [children, dispatch, inputsProp, selectsProp]);

  function handleTd(thisCell, obj) {
    const { name, isDateOnly, formatValue } = thisCell || {};

    let thisValue = name ? FindKeyValue(name, obj) : "";

    if (thisValue) {
      if (IsFunction(formatValue)) {
        return formatValue(thisValue);
      } else if (isDateOnly) {
        return new Date(thisValue).toLocaleDateString("pt-BR", { timeZone: "UTC" });
      }
    }

    return thisValue;
  }

  const emptyRows = useMemo(() => {
    let newRows = [];

    for (let rowIndex = data?.length || 0; rowIndex < pageSize; rowIndex++) {
      newRows.push(rowIndex);
    }

    return newRows;
  }, [data, pageSize]);

  return (
    <CustomScrollBar>
      <StyledTable {...rest}>
        <thead>
          <tr>
            {children}
            {!(
              noActions ||
              (!requests?.restoreOne && !requests?.deleteOne && noEdit && noShow)
            ) && <th className="CustomTable_th_options">-</th>}
          </tr>
        </thead>
        <tbody>
          {data?.map((thisData, i) => (
            <tr key={thisData?.cod || i}>
              {bodyCells?.map((thisCell, ci) => (
                <td key={thisCell.name || ci}>{handleTd(thisCell, thisData)}</td>
              ))}
              {!(
                noActions ||
                (!requests?.restoreOne && !requests?.deleteOne && noEdit && noShow)
              ) && (
                <td className="CustomTable_td_options">
                  {!noShow && <SLShowButton icon={buttonIcon?.show} data={thisData} />}
                  {!noEdit && <SLEditButton icon={buttonIcon?.edit} data={thisData} />}
                  {!noExcel && <SLExcelPDFButton isExcel icon={buttonIcon?.excel} data={thisData} />}
                  {!noPDF && <SLExcelPDFButton isPDF icon={buttonIcon?.pdf} data={thisData} />}
                  {(!noChangeStatus && tabFilter?.status == 1) && requests?.changeStatusOne && <SLChangeStatusButton icon={"lock"} data={thisData} />}
                  {(!noChangeStatus && tabFilter?.status == 0) && requests?.changeStatusOne && <SLChangeStatusButton icon={"lock_open"} data={thisData} />}
                  {!noDelete && !thisData?.deletedAtDate && requests?.deleteOne && (
                    <SLDeleteButton data={thisData} />
                  )}
                  {!noRestore && thisData?.deletedAtDate && requests?.restoreOne && (
                    <SLRestoreButton data={thisData} />
                  )}
                </td>
              )}
            </tr>
          ))}
          {emptyRows?.map((e) => (
            <tr key={e}>
              {bodyCells?.map((r, i) => (
                <td key={i} />
              ))}
              <td />
            </tr>
          ))}
        </tbody>
      </StyledTable>
      {pageSize && (
        <Pagination
          data={dataProp || dataState}
          size={pageSize}
          getInfo={({ pageData }) => setPageData(pageData)}
        />
      )}
    </CustomScrollBar>
  );
}

export default ThisTable;
