/* eslint-disable eqeqeq */
import React, { useState } from "react";

import api from "~/services/api";
import ShowContent from "./ShowContent";

import PadraoAlunosFiltro from "../../../../componentes/PadraoAlunosFiltro";
import { Container, ThisCard, ThisCardBody, ThisCardTitle } from "./styles";
import TextContent from "~/componentes/TextContent";
import ShowLoading from "~/utils/ShowLoading";
import Pagination from "~/componentes/Pagination";
import handleDate from "~/utils/handleDate";
import { Materialize } from "~/styles/materializeGrid";
import Label from "~/componentes/CustomForm/Label";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";

function AulasDisponibilizadas() {
  const [showModal, setShowModal] = useState(false);
  const [aulas, setAulas] = useState([]);
  const [pageData, setPageData] = useState();
  // const [usuario, setUsuario] = useState();
  const [aluno, setAluno] = useState();
  const [alunoTurma, setAlunoTurma] = useState();
  const [alunoDisciplinaTurma, setAlunoDisciplinaTurma] = useState();
  const [disponibilizacao, setDisponibilizacao] = useState();

  async function handleUsuarioChange(data) {
    await ShowLoading(
      () =>
        api
          .get("padrao/acompanhamentoEscolar/aulasDisponibilizadas", {
            params: { usuarioCod: data?.cod },
          })
          .then((r) => {
            setAulas(r?.data || []);
          }),
      { successModal: false, errorModal: false }
    );

    return data;
  }

  function getDisciplinaTurma(aula) {
    return aula?.disciplinasTurmas?.find((dtm) => dtm?.turma?.cod == alunoTurma?.turma?.cod);
  }

  const filteredAulas = React.useMemo(() => {
    return aulas.filter((aul) => {
      return aul?.disciplinasTurmas?.find((dtm) => {
        const disp = dtm?.AulaDisciplinaTurma?.dataDisponibilizacao;
        if (disp && disponibilizacao && new Date(disp).getUTCMonth() !== disponibilizacao) {
          return false;
        }
        return dtm?.alunoDisciplinaTurma?.find((adt) => {
          const alt = adt?.alunoTurma;
          const alu = alt?.aluno;
          if (alunoDisciplinaTurma?.cod) {
            return alunoDisciplinaTurma.cod === adt?.cod;
          } else if (alunoTurma?.cod) {
            return alunoTurma.cod === alt?.cod;
          } else if (aluno?.cod) {
            return aluno.cod === alu?.cod;
          }
          return true;
        });
      });
    });
  }, [aluno, alunoDisciplinaTurma, alunoTurma, aulas, disponibilizacao]);

  return (
    <>
      {showModal && <ShowContent mountedData={showModal} outClick={() => setShowModal(false)} />}
      <PadraoAlunosFiltro
        title="Filtrar Conteúdos"
        onChangeUsuario={handleUsuarioChange}
        // usuarioSetter={setUsuario}
        alunoSetter={setAluno}
        alunoTurmaSetter={setAlunoTurma}
        alunoDisciplinaTurmaSetter={setAlunoDisciplinaTurma}
      >
        <Materialize>
          <div className="row">
            <Label className="col s12 m6 l6">
              <SistemaInput
                onChange={(e) => {
                  const thisVal = e.currentTarget.value;
                  setDisponibilizacao(thisVal ? new Date(thisVal).getUTCMonth() : thisVal);
                }}
                type="month"
              />
            </Label>
          </div>
        </Materialize>
      </PadraoAlunosFiltro>

      <Container>
        {Array.isArray(aulas) && aulas.length > 0 ? (
          <>
            {pageData?.map((thisData, i) => {
              const { arquivos, filial } = thisData || {};
              const selectedDisciplinaTurma = getDisciplinaTurma(thisData);
              const { disciplinaFilial, turma, AulaDisciplinaTurma } = selectedDisciplinaTurma || {};
              const dataDisponibilizacao = handleDate(AulaDisciplinaTurma?.dataDisponibilizacao)?.dmyhm;

              return (
                <ThisCard
                  onClick={() => setShowModal({ ...thisData, dataDisponibilizacao, selectedDisciplinaTurma })}
                  data={thisData}
                  key={thisData?.cod || i}
                >
                  <ThisCardTitle>
                    <h3>
                      #{thisData?.cod}
                      {arquivos?.length > 0 && <i className="material-icons">attachment</i>}
                    </h3>
                    <span>{dataDisponibilizacao}</span>
                  </ThisCardTitle>
                  <ThisCardBody>
                    <div className="ThisCardBody_title_tur_disc_div">
                      <TextContent lines={1} setType="h2">
                        {thisData?.titulo}
                      </TextContent>
                      <TextContent lines={1} setType="p" className="ThisCardbody_turma_disciplina">
                        {filial?.nomeFantasia} / {turma?.nome} / {disciplinaFilial?.nome}
                      </TextContent>
                    </div>
                    <TextContent lines={2} className="ThisCardBody_TextContent_pre">
                      {thisData?.descricao}
                    </TextContent>
                  </ThisCardBody>
                </ThisCard>
              );
            })}
            <Pagination data={filteredAulas} size={6} getInfo={({ pageData }) => setPageData(pageData)} />
          </>
        ) : (
          <h4 style={{ textAlign: "center" }}>Não há aulas disponíveis.</h4>
        )}
      </Container>
    </>
  );
}

export default AulasDisponibilizadas;
