import React from "react";

import { Container } from "./styles";
import RankingAlunos from "./RankingAlunos";
import BarChart from "./BarChart";

function SecondSection({ stats }) {
  return (
    <Container>
      <BarChart {...{ stats }} />
      <RankingAlunos {...{ stats }} />
    </Container>
  );
}

export default SecondSection;
