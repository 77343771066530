import React, { useState, useContext } from "react";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import Label from "~/componentes/CustomForm/Label";

import sistemaListStore, {
  sistemaListConfirmSelectValues,
} from "../../thisStore";
import ThisModalsWrapper from "../../ThisModals/ThisModalsWrapper";
import { SLModalFooter } from "../../..";
import { useEffect } from "react";

function ThisFilterModal() {
  const { state, dispatch } = useContext(sistemaListStore);
  const { filter } = state?.modal || {};
  const { selects, selectValues } = state || {};
  const [newValues, setNewValues] = useState({});
  const [closeAnimation, setCloseAnimation] = useState(() => () => {});

  useEffect(() => {
    if (!filter) {
      setNewValues({});
    }
  }, [filter]);

  return filter ? (
    <ThisModalsWrapper
      outClickAnimation={setCloseAnimation}
      style={{ width: "30em" }}
      title="FILTRO"
    >
      <div className="SistemaTable_Modal_Body">
        {selects?.map((select, i) => {
          const { outLabel, filter, list, ...thisRest } = select || {};
          return (
            <Label key={i} title={outLabel}>
              <SistemaSelect
                firstOption
                defaultValue={selectValues?.[filter]}
                value={newValues?.[filter]}
                onChange={(e) => {
                  let thisValue = e.currentTarget.value;
                  setNewValues((prev) => ({
                    ...prev,
                    [select.filter]: thisValue,
                  }));
                }}
                list={list}
                {...thisRest}
              />
            </Label>
          );
        })}
      </div>
      <SLModalFooter
        // cancelButton={{ onClick: closeAnimation }}
        submitButton={{
          type: "button",
          onClick: () => {
            dispatch(sistemaListConfirmSelectValues(newValues));
            closeAnimation();
          },
        }}
      />
    </ThisModalsWrapper>
  ) : (
    <></>
  );
}

export default ThisFilterModal;
