import React, { useState, useLayoutEffect } from "react";

import api from "~/services/api";
import ShowContent from "./ShowContent";
import EditContent from "./EditContent";
import AprovarContent from "./AprovarContent";

import { Container } from "./styles";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import Pagination from "~/componentes/Pagination";
import CustomBodyContent from "../AprovarQuestoes/CustomBodyContent";
import { SLEditModal, SLShowModal, SLAprovarModal } from "~/pages/Sistema/componentes/SistemaList";

export default function AprovarQuestoes() {
  const [mountedData, setMountedData] = useState();
  const [data, setData] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [tabFilter, setTabFilter] = useState({ status: 1 });
  const [disciplinas, setDisciplinas] = useState([]);
  const [questoesNiveis, setQuestoesNiveis] = useState([]);
  const [filiais, setFiliais] = useState([]);
  const [series, setSeries] = useState([]);

  useLayoutEffect(() => {
    api.get("admin/disciplinas").then((response) => {
      const thisResult = response.data.sort((a, b) => a?.nome - b?.nome);
      setDisciplinas(thisResult);
    });
    api.get("admin/series").then((response) => response?.data && setSeries(response.data));
    api.get("admin/filiais").then((response) => response?.data && setFiliais(response.data));
    api.get("admin/questaoNiveis").then((response) => response?.data && setQuestoesNiveis(response.data));
  }, []);

  return (
    <Container
      inputs={[
        { filter: "cod", label: "Código" },
        { filter: "nome", label: "Nome" },
        { filter: "disciplina.nome", label: "Disciplina" },
      ]}
      selects={[
        {
          filter: "disciplinaCod",
          list: disciplinas,
          outLabel: "Disciplinas",
          value: "cod",
          label: "nome",
        },
        {
          filter: "questaoNivelCod",
          list: questoesNiveis,
          outLabel: "Níveis",
          value: "cod",
          label: "nome",
        },
        {
          filter: "filialCod",
          list: filiais,
          outLabel: "Filiais",
          value: "cod",
          label: "nomeFantasia",
        },
      ]}
      requests={{
        findAll: () => api.get("admin/questoeseduqueme"),
        findOne: (thisData) => api.get("admin/questaoeduqueme", { params: { cod: thisData.cod } }),
        deleteOne: (thisData) => api.delete("admin/questaoeduqueme", { data: { cod: thisData.cod } }),
      }}
      setter={setData}
      dataFilter={tabFilter}
    >
      <TabsWrapper tabHeight={2.4}>
        <Tab materialIcon="priority_high" onClick={() => setTabFilter({ ativo: 1, etapa: 0 })}>
          Pendente
        </Tab>
        <Tab materialIcon="done" onClick={() => setTabFilter({ ativo: 1, etapa: 1 })}>
          Aprovado
        </Tab>
        <Tab materialIcon="close" onClick={() => setTabFilter({ ativo: 0, etapa: 1 })}>
          Desativo
        </Tab>
      </TabsWrapper>

      <SLEditModal title="Editar Questão" contentMount={setMountedData} contentUnmount={setMountedData}>
        <EditContent {...{ mountedData, disciplinas, questoesNiveis, series }} />
      </SLEditModal>

      <SLAprovarModal title="Aprovar Questão" contentMount={setMountedData} contentUnmount={setMountedData}>
        <AprovarContent {...{ mountedData, disciplinas, questoesNiveis, series }} />
      </SLAprovarModal>

      <SLShowModal title="Visualizar Questão" contentMount={setMountedData} contentUnmount={setMountedData}>
        <ShowContent {...{ mountedData, disciplinas, questoesNiveis, series }} />
      </SLShowModal>

      <SistemaComponenteInterno defaultStyle setter={setMountedData}>
        <CustomBodyContent {...{ pageData }} />
        <Pagination
          data={data}
          getInfo={({ pageData }) => {
            setPageData(pageData);
          }}
          size={9}
        />
      </SistemaComponenteInterno>
    </Container>
  );
}

export { ShowContent as ShowQuestao, EditContent as EditQuestao };
