import React, { useState } from "react";

import api from "~/services/api";
import CreateEditContent from "./CreateEditContent";
import ShowContent from "./ShowContent";
import { SLShowModal, SLEditModal } from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import TableComponent from "./TableComponent";

function Modulos() {
  const [mountedData, setMountedData] = useState();
  const [tabFilter, setTabFilter] = useState({ status: 1 });

  return (
    <Container
      requests={{
        findAll: () => api.get("admin/modulos", { params: { paranoid: false } }),
        deleteOne: (thisData) => api.delete("admin/modulo", { data: { cod: thisData.cod } }),
        restoreOne: (thisData) => api.post("admin/modulo/restaura", { data: { cod: thisData.cod } }),
        findOne: (thisData) => api.get("admin/modulo", { params: { cod: thisData.cod } }),
      }}
      dataFilter={tabFilter}
      inputs={[{ filter: "nome", label: "Nome" }]}
      selects={[
        {
          filter: "tipo",
          list: [{ nome: "admin" }, { nome: "filial" }, { nome: "professor" }, { nome: "padrao" }],
          outLabel: "Visão",
          value: "nome",
        },
      ]}
    >
      <TableComponent {...{ setTabFilter }} />

      <SLShowModal title="Módulo" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLEditModal title="Atualizar Módulo" setter={setMountedData}>
        <CreateEditContent {...{ mountedData }} />
      </SLEditModal>

      {/* <SLCreateModal title="Novo Módulo">
        <CreateEditContent />
      </SLCreateModal> */}
    </Container>
  );
}

export default Modulos;
