import React, { useState } from "react";

import { Content, TriggerButton, FlexH5, AcessoButton } from "./styles";
import TextContent from "~/componentes/TextContent";
import { useDispatch, useSelector } from "react-redux";
import { changeToken } from "~/store/modules/auth/actions";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import { tipoAcesso, eduqueMeEmpresa } from "~/config/definicoesPadrao";

export default function Acessos({ matricula }) {
  const dispatch = useDispatch();
  const { matriculas } = useSelector((state) => state.auth);
  const [nameAccess, setNameAccess] = useState(matricula?.cargo);
  const [showModal, setShowModal] = useState(false);

  function configAcess(acesso) {
    dispatch(changeToken(acesso));
    setNameAccess(acesso?.cargo);
  }

  return (
    <>
      <TriggerButton onClick={() => setShowModal(!showModal)} clean>
        <TextContent setType="h6">({nameAccess})</TextContent>
        <i className="material-icons">supervised_user_circle</i>
      </TriggerButton>
      {showModal && (
        <Content outClose outClick={setShowModal}>
          <CustomScrollBar fixScrollMargin autoHeightMax="calc(100vh - 7.75em)">
            {matriculas?.map((acesso, index) => (
              <AcessoButton
                clean
                key={index}
                ativo={matricula?.token === acesso.token ? 1 : undefined}
                onClick={(e) => configAcess(acesso, e)}
              >
                <FlexH5>
                  <h5 title={acesso?.cargo}>{acesso?.cargo}</h5>
                  {acesso?.tipo !== tipoAcesso.padrao && (
                    <span>
                      (
                      {acesso?.tipo === tipoAcesso.filial
                        ? "Empresa"
                        : acesso.tipo}
                      )
                    </span>
                  )}
                </FlexH5>
                {acesso?.tipo !== tipoAcesso.padrao && (
                  <TextContent className="Acessos_localTrabalho" lines="1">
                    {acesso?.tipo === tipoAcesso.empresa
                      ? acesso?.empresa || "sem empresa"
                      : acesso?.tipo === tipoAcesso.admin
                      ? eduqueMeEmpresa
                      : acesso?.filial || "sem filial"}
                  </TextContent>
                )}
              </AcessoButton>
            ))}
          </CustomScrollBar>
        </Content>
      )}
    </>
  );
}
