import styled from "styled-components";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";

export const Container = styled(SistemaFixedModal)`
  width: 80em;
  max-width: 95%;
  z-index: 600;

  .modal-flex {
    display: flex;

    .flex-item-1 {
      width: 28em;
      margin: 0 0.4em;
      text-align: center;
      border-right: solid #efefef 0.125em;
      padding-right: 0.5em;
    }
    .flex-item-2 {
      width: 90%;
    }
  }
  @media (max-width: 30em) {
    .modal-flex {
      flex-direction: column;
    }
    .flex-item-1 {
      width: auto !important;
      margin: 0 0.4em 2em 0em !important;
      border-right: none !important;
      border-bottom: solid #efefef 0.125em !important;
      padding-bottom: 0.5em !important;
    }
    .flex-item-2 {
      width: auto !important;
    }
  }
`;
