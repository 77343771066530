import styled from "styled-components";
import { Button } from "~/componentes/CustomForm";
import Modal from "~/componentes/Modal";

export const TriggerButton = styled(Button)`
  background: none;
  border: none;
  color: #293f57;
  display: flex;
  align-items: center;
  margin: auto 0.5em;
`;

export const Container = styled.div``;

export const Content = styled(Modal)`
  position: absolute;
  top: 100%;
  right: 1em;
  z-index: 1;
  margin-right: 0.25em;
  width: max-content;
  padding: 0.5em 1em;
  background: #fff;
  border-radius: 0.5em;
  box-shadow: 0 0 0.5em #0003;

  button:not(:first-child) {
    margin-top: 0.5em;
  }

  button {
    border: none;
    outline: 0;
    background: none;
    padding: 0.25em;
    width: 100%;
    border-bottom: solid #f0f0f0 0.1em !important;
    display: flex;
    align-items: center;
    font-size: 1.1em;
    transition: 300ms;

    .PerfilModal_Button_icon {
      margin-right: 0.5em;
    }

    :hover {
      transform: scale(1.05);
    }
  }
`;
