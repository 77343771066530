/* eslint-disable eqeqeq */
import React from "react";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import Label from "~/componentes/CustomForm/Label";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import api from "~/services/api";
import ShowLoading from "~/utils/ShowLoading";
import { MaterializeContent } from "../styles";

import { Container } from "./styles";

function ThisFilter({ setData }) {
  const [periodosLetivos, setPeriodosLetivos] = React.useState([]);
  const [periodoLetivo, setPeriodoLetivo] = React.useState();
  const [turma, setTurma] = React.useState();
  const [disciplinasTurma, setDisciplinasTurma] = React.useState();

  React.useEffect(() => {
    api.get("professor/relatorios/disciplinas/disciplinasTurmas").then((r) => {
      setPeriodoLetivo(r?.data?.[0]);
      setTurma(r?.data?.[0]?.turmas?.[0]);
      setDisciplinasTurma(r?.data?.[0]?.turmas?.[0]?.disciplinasTurma?.[0]);
      setPeriodosLetivos(r?.data || []);
    });
  }, []);

  async function fetchStats() {
    ShowLoading(() =>
      api
        .get("professor/relatorios/disciplinas/disciplinaturma", {
          params: {
            cod: disciplinasTurma?.cod,
          },
        })
        .then((r) => setData(r?.data))
        .catch(() => setData())
    );
  }

  return (
    <Container>
      <MaterializeContent>
        <div className="row">
          <div className="filters col s12 m12 l9">
            <Label title="Período Letivo" className="filter-item">
              <SistemaSelect
                list={{
                  list: periodosLetivos,
                  value: "cod",
                  label: "nome",
                }}
                onChange={(e) => {
                  const val = e.currentTarget.value;
                  const thisFind = periodosLetivos?.find((t) => t?.cod == val);
                  setPeriodoLetivo(thisFind);
                  setTurma(thisFind?.turmas?.[0]);
                  setDisciplinasTurma(
                    thisFind?.turmas?.[0]?.disciplinasTurma?.[0]
                  );
                }}
                value={periodoLetivo?.cod}
              />
            </Label>
            <Label title="Série" className="filter-item">
              <SistemaSelect
                list={{
                  list: periodoLetivo?.turmas,
                  value: "cod",
                  label: "nome",
                }}
                onChange={(e) => {
                  const val = e.currentTarget.value;
                  const thisFind = periodoLetivo?.turmas?.find(
                    (t) => t?.cod == val
                  );
                  setTurma(thisFind);
                  setDisciplinasTurma(thisFind?.disciplinasTurma?.[0]);
                }}
                value={turma?.cod}
              />
            </Label>
            <Label title="Turma" className="filter-item">
              <SistemaSelect
                list={{
                  list: turma?.disciplinasTurma,
                  value: "cod",
                  label: "disciplinaFilial.nome",
                }}
                onChange={(e) => {
                  const val = e.currentTarget.value;
                  const thisFind = turma?.disciplinasTurma?.find(
                    (t) => t?.cod == val
                  );
                  setDisciplinasTurma(thisFind);
                }}
                value={disciplinasTurma?.cod}
              />
            </Label>
          </div>
          <div className="actions col s12 m12 l3">
            <ColoredButton yellowLayout onClick={fetchStats}>
              Procurar
            </ColoredButton>
          </div>
        </div>
      </MaterializeContent>
    </Container>
  );
}

export default ThisFilter;
