import React from "react";
import Label from "~/componentes/CustomForm/Label";

import { Container } from "./styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import AcessosList from "~/componentes/AcessosList";

function ShowContent({ mountedData, modulos }) {
  return (
    <Container>
      <CustomScrollBar fixScrollMargin autoHeightMax="27em">
        <Label title="TÍTULO:" text={mountedData?.nome} />
        <AcessosList
          showOnly
          filterMenuCod={mountedData?.acessos}
          modulos={modulos}
          title={`Acessos`}
        />
      </CustomScrollBar>
    </Container>
  );
}

export default ShowContent;
