import React, { useReducer } from "react";
import formStore, { formClearData } from "../formStore";
import formReducer from "../formStore/reducer";
import { useEffect } from "react";

/**
 * @param {Object} props
 * @param {Function} props.clearData Limpa o estado do formulário.
 * @param {Function} props.onSubmit
 * @param {Function} props.isFormData
 * @param {Function} props.setter Obtém o estado do formulário a cada alteração.
 */
export default function Form({
  children,
  isFormData,
  clearData,
  onSubmit,
  setter,
  ...rest
}) {
  const [state, dispatch] = useReducer(formReducer, {});
  const ref = React.useRef();

  function handleSubmit(e) {
    e.preventDefault();
    onSubmit && onSubmit(isFormData ? new FormData(ref.current) : state);
  }

  useEffect(() => {
    setter && setter(state);
  }, [setter, state]);

  useEffect(() => {
    clearData && clearData(() => dispatch(formClearData()));
  }, [clearData]);

  return (
    <formStore.Provider value={{ state, dispatch }}>
      <form ref={ref} {...rest} onSubmit={handleSubmit}>
        {children}
      </form>
    </formStore.Provider>
  );
}
