/* eslint-disable no-useless-computed-key */
import React, { useState, useLayoutEffect } from "react";

import api from "~/services/api";
import CreateEditContent from "./CreateEditContent";
import ShowContent from "./ShowContent";
import {
  SLCreateModal,
  SLShowModal,
  SLEditModal,
  SLEditButton,
  SLShowButton,
  SLDeleteButton,
  SLRestoreButton,
} from "~/pages/Sistema/componentes/SistemaList";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import Pagination from "~/componentes/Pagination";

import { Container } from "./styles";
import EstrelaImg from "~/assets/icones/estrelas.png";

function Planetas() {
  const [mountedData, setMountedData] = useState();
  const [data, setData] = useState([]);
  const [tabFilter, setTabFilter] = useState({ status: 1, ["versaoApp.ativo"]: true });
  const [pageData, setPageData] = useState([]);
  const [versaoApp, setVersaoApp] = useState([]);

  useLayoutEffect(() => {
    api
      .get("admin/versoesapp")
      .then((response) => response?.data && setVersaoApp(response.data));
  }, []);

  return (
    <Container
      inputs={[{ filter: "nome", label: "Nome" }]}
      selects={[
        {
          filter: "versaoAppCod",
          list: versaoApp,
          outLabel: "Versão App",
          value: "cod",
          label: "nome",
        },
      ]}
      requests={{
        findAll: () => api.get("admin/planetas", { params: { paranoid: false } }),
        deleteOne: (thisData) =>
          api.delete("admin/planeta", { data: { cod: thisData.cod } }),
        restoreOne: (thisData) =>
          api.post("admin/planeta/restaura", { data: { cod: thisData.cod } }),
        findOne: (thisData) =>
          api.get("admin/planeta", { params: { cod: thisData.cod } }),
      }}
      setter={setData}
      dataFilter={tabFilter}
    >
      <TabsWrapper tabHeight={2.4}>
        <Tab
          materialIcon="north"
          onClick={() => setTabFilter({ status: 1, ["versaoApp.ativo"]: true })}
        >
          Versão Atual
        </Tab>
        <Tab
          materialIcon="south"
          onClick={() => setTabFilter({ status: 1, ["versaoApp.ativo"]: false })}
        >
          Versões Anteriores
        </Tab>
        <Tab materialIcon="highlight_off" onClick={() => setTabFilter({ status: 0 })}>
          Desativo
        </Tab>
      </TabsWrapper>

      <SistemaComponenteInterno>
        <div className="pai-planeta-card">
          {pageData.map((e) => (
            <div className="planeta-card">
              <div className="planeta-card-box">
                <div className="planeta-card-head">
                  <p className="title-card">{e?.nome}</p>
                  <p className="sub-title-card">{e?.versaoApp?.nome}</p>
                </div>
                <div className="planeta-card-body">
                  {e?.arquivo?.url && <img src={e.arquivo.url} alt="imagem" />}
                </div>
                <div className="planeta-card-footer">
                  <div className="card-estrelas">
                    <p>
                      <img src={EstrelaImg} alt="imagem" />
                      {e?.estrelas}
                    </p>
                  </div>
                  <div className="card-action">
                    {e?.status === 1 && <SLShowButton data={e} />}
                    {e?.status === 1 && <SLEditButton data={e} />}
                    {e?.status === 1 && <SLDeleteButton data={e} />}
                    {e?.status === 0 && <SLRestoreButton data={e} />}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Pagination
          data={data}
          getInfo={({ pageData }) => {
            setPageData(pageData);
          }}
          size={9}
        />
      </SistemaComponenteInterno>
      <SLShowModal title="Planeta" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLEditModal title="Atualizar Planeta" setter={setMountedData}>
        <CreateEditContent {...{ mountedData }} />
      </SLEditModal>

      <SLCreateModal title="Novo Planeta">
        <CreateEditContent />
      </SLCreateModal>
    </Container>
  );
}

export default Planetas;
