// eslint-disable-next-line no-unused-vars
const returnFunc = {
  day: "",
  month: "",
  year: "",
  hour: "",
  min: "",
  sec: "",
  dmy: "",
  ymd: "",
  hms: "",
  hm: "",
  dmyhms: "",
  dmyhm: "",
};

/**
 * @param {Date} dateProp
 * @returns {returnFunc}
 */
export default function handleDate(dateProp, isUtc) {
  const date = typeof dateProp === "string" ? new Date(dateProp) : dateProp;

  if (date instanceof Date) {
    function handleNumb(e) {
      return e > 9 ? e : `0${e}`;
    }

    const day = handleNumb(isUtc ? date.getUTCDate() : date.getDate());
    const month = handleNumb(isUtc ? date.getUTCMonth() : date.getMonth() + 1);
    const year = isUtc ? date.getUTCFullYear() : date.getFullYear();
    const hour = handleNumb(isUtc ? date.getUTCHours() : date.getHours());
    const min = handleNumb(isUtc ? date.getUTCMinutes() : date.getMinutes());
    const sec = handleNumb(isUtc ? date.getUTCSeconds() : date.getSeconds());

    const ymd = `${year}/${month}/${day}`;
    const dmy = `${day}/${month}/${year}`;
    const hms = `${hour}:${min}:${sec}`;
    const hm = `${hour}:${min}`;
    const dmyhms = `${dmy} às ${hms}`;
    const dmyhm = `${dmy} às ${hm}`;

    return { day, month, year, hour, min, sec, dmy, hms, hm, dmyhms, dmyhm, ymd };
  } else {
    return undefined;
  }
}
