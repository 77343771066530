import styled from "styled-components";
import { Button } from "~/componentes/CustomForm";

export const ThisButton = styled(Button)`
  padding-left: 2em;
  height: 2em;
  width: 100%;
  display: flex;
  color: #d6d6d6;
  background: none;
  align-items: center;
  font-size: 1.2em;
  border: none;
  i {
    font-size: 1.3em;
    margin-right: 0.6em;
  }

  :hover {
    background: #1ca9d0;
  }
`;
