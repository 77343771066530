import React from "react";
import { CSVLink } from "react-csv";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import handleDate from "~/utils/handleDate";

// import { Container } from './styles';

const headers = [
  { label: "Disciplina", key: "disciplina" },
  { label: "Professor", key: "professor" },
  { label: "Corrigido", key: "corrigido" },
  { label: "Data início", key: "dataInicio" },
  { label: "Data término", key: "dataFim" },
];

function ThisExcelButton({ data, selectedDTM, avaliacoesDisponibilizadas }) {
  const filename = React.useMemo(() => {
    if (selectedDTM?.disciplinaFilial?.nome) {
      return `${selectedDTM.disciplinaFilial.nome} - ${data?.nome}`;
    } else {
      return `${data?.nome}`;
    }
  }, [data, selectedDTM]);

  return (
    <ColoredButton materialIcon="arrow_downward" greenLayout>
      <CSVLink
        style={{ color: "#fff" }}
        headers={headers}
        data={
          avaliacoesDisponibilizadas?.map?.((e) => ({
            ...e,
            dataInicio: handleDate(e?.dataInicio).dmyhm,
            dataFim: handleDate(e?.dataFim).dmyhm,
          })) || []
        }
        filename={`${filename}.csv`}
        separator={";"}
      >
        Excel
      </CSVLink>
    </ColoredButton>
  );
}

export default ThisExcelButton;
