/* eslint-disable eqeqeq */
import React from "react";

import { Container } from "./styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import DadosMatricula from "~/componentes/DadosMatricula";
import { SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import { Materialize } from "~/styles/materializeGrid";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import api from "~/services/api";
import Label from "~/componentes/CustomForm/Label";
import ShowError from "~/utils/ShowError";
import handleCpf from "~/utils/handleCpf";

function EditContent({ mountedData }) {
  const [grupoDados, setGrupoDados] = React.useState();
  const [atualizacaoDados, setAtualizacaoDados] = React.useState({});
  const [files, setFiles] = React.useState(new FormData());

  React.useEffect(() => {
    const {
      filialCod,
      serie: { ensinoCod },
    } = mountedData || {};

    api
      .get("padrao/matriculas/AcompanharMatriculas/dadosMatriculaEnsino", {
        params: { filialCod, ensinoCod },
      })
      .then((r) => setGrupoDados(r?.data));
  }, [mountedData]);

  async function handleSubmit(data, options) {
    const { closeReload } = options || {};

    const checkCpfs = [];
    atualizacaoDados?.responsavel?.cpf && checkCpfs.push(atualizacaoDados?.responsavel?.cpf);
    atualizacaoDados?.aluno?.cpf && checkCpfs.push(atualizacaoDados?.aluno?.cpf);
    if (checkCpfs.length > 0 && checkCpfs.some((e) => !handleCpf(e))) {
      ShowError.error("Cpf inválido");
    }

    await ShowConfirmacao(
      async () => {
        const newFD = new FormData();
        newFD.set("atualizacaoDados", JSON.stringify(atualizacaoDados));
        newFD.set("cod", mountedData?.cod);
        files.forEach((v, k) => newFD.set(k, v));

        const response = await api.put("padrao/matriculas/AcompanharMatriculas/matricula", newFD);

        if (closeReload && response?.status === 200) {
          closeReload();
        }

        return response;
      },
      {
        message: "Tem certeza que deseja alterar essa matrícula?",
        successModal: "Matricula alterada com sucesso.",
      }
    );
  }

  return (
    <Container onSubmit={handleSubmit}>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          <div className="row">
            <Label title="pendencias" text={mountedData?.pendencias || " "} className="col s12 m12 l12" />
          </div>
        </Materialize>
        <DadosMatricula
          grupos={grupoDados}
          defaultValue={mountedData?.atualizacaoDados || mountedData?.dados}
          setter={setAtualizacaoDados}
          fileSetter={setFiles}
        />
      </CustomScrollBar>
      <SLModalFooter />
    </Container>
  );
}

export default EditContent;
