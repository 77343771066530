import React, { useMemo, memo } from "react";
import { CheckBox } from "~/componentes/AcessosList/styles";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import { DisciplinaCardStyle } from "./styles";

function DisciplinaCard({
  disciplinaFilial,
  professores: professoresProp,
  disciplinasForm,
  setDisciplinasForm,
}) {
  const thisFormValue = useMemo(() => disciplinasForm?.[disciplinaFilial.cod] || {}, [
    disciplinasForm,
    disciplinaFilial,
  ]);

  const professores = React.useMemo(
    () =>
      professoresProp?.filter((p) =>
        p?.disciplinasFilial?.find((df) => df?.cod === disciplinaFilial?.cod)
      ) || [],
    [disciplinaFilial, professoresProp]
  );

  const { disciplinaFilialCod, professorCod } = thisFormValue || {};

  function handleData(newData) {
    setDisciplinasForm((prev) => ({
      ...prev,
      [disciplinaFilial.cod]: { ...thisFormValue, ...newData },
    }));
  }

  return (
    <DisciplinaCardStyle>
      <div className="SubjectHeader">
        <CheckBox
          defaultHtml
          checked={disciplinaFilialCod}
          value={disciplinaFilial.cod}
          onChange={(e) => {
            const check = e.currentTarget.checked;

            handleData({
              disciplinaFilialCod: check ? disciplinaFilial.cod : undefined,
            });
          }}
        />
        <h4>{disciplinaFilial.nome}</h4>
      </div>
      <div className="SubjectBody">
        <span>professor</span>
        <SistemaSelect
          firstOption
          isNumber
          disabled={!disciplinaFilialCod}
          defaultValue={professorCod}
          list={{ list: professores, value: "cod", label: "usuario.nome" }}
          setter={(e) =>
            disciplinaFilialCod && handleData({ professorCod: e, disciplinaFilialCod })
          }
        />
      </div>
    </DisciplinaCardStyle>
  );
}

export default memo(DisciplinaCard);
