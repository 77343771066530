import React from "react";

import { ThisMainButton } from "./styles";
import ThisModal from "./ThisModal";

function RecuperarSenha({ defaultData, resetDefault }) {
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    if (defaultData?.email && defaultData?.senhaToken) {
      setShowModal(true);
    }
  }, [defaultData]);

  return (
    <>
      <ThisMainButton
        className="RecuperarSenha_Button_esqueciSenha"
        type="button"
        onClick={() => setShowModal(true)}
      >
        Alterar senha de acesso
      </ThisMainButton>
      {showModal && (
        <ThisModal
          defaultData={defaultData}
          outClick={() => {
            setShowModal(false);
            resetDefault();
          }}
        />
      )}
    </>
  );
}

export default RecuperarSenha;
