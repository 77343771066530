import React, { useState, useEffect } from "react";
import Label from "~/componentes/CustomForm/Label";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";

import { Container } from "./styles";
import { Materialize } from "~/styles/materializeGrid";
import SistemaTable from "~/componentes/StyledComponents/Tables/SistemaTable";
import IconButton from "~/componentes/CustomForm/Button/Styles/IconButton";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import ShowError from "~/utils/ShowError";
import Pagination from "~/componentes/Pagination";

function ThisModal({ setShowModal, setTab, setTipoTituloSelected, tipoTitulo }) {
  const [pageData, setPageData] = useState([]);

  function handleSelect(disc) {
    ShowConfirmacao(
      () => {
        setTipoTituloSelected(disc);
        setTab(1);
        setShowModal(false);
      },
      { message: "Tem certeza que deseja adicionar esse tipo?" }
    );
  }

  return (
    <Container
      title="Tipo do Titulo"
      
      outClick={() => setShowModal(false)}
      scrollBar
    >
      <SistemaTable className="TurmasList_StyledTable">
        <thead>
          <tr>
            <th>Codigo</th>
            <th>Descrição</th>
            <th>-</th>
          </tr>
        </thead>
        <tbody>
          {pageData?.map((disc, i) => (
            <tr key={disc?.cod || i}>
              <td>{disc?.cod}</td>
              <td>{disc?.descricao}</td>
              <td>
                <IconButton
                  onClick={() => handleSelect(disc)}
                  materialIcon={"done"}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </SistemaTable>
      <Pagination
        size={4}
        data={tipoTitulo}
        getInfo={({ pageData }) => setPageData(pageData)}
      />
    </Container>
  );
}

export default ThisModal;
