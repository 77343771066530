import styled from "styled-components";
import { SLTable } from "~/pages/Sistema/componentes/SistemaList";

export const ThisTable = styled(SLTable)`
  table-layout: fixed;

  td:not(:first-child),
  th:not(:first-child) {
    text-align: center;
    width: 10em;
  }

  td:first-child,
  th:first-child {
    width: calc(100% - 30em);
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
