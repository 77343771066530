import React from "react";

import { useState } from "react";
import api from "~/services/api";
import ShowContent from "./ShowContent";
import CustomBodyContent from "./CustomBodyContent/index";
import SistemaList, {
  SLShowModal,
} from "~/pages/Sistema/componentes/SistemaList";
import { useEffect } from "react";

function Matriculas() {
  const [mountedData, setMountedData] = useState();
  const [tabFilter, setTabFilter] = useState();
  const [periodosLetivos, setPeriodosLetivos] = useState([]);
  const [series, setSeries] = useState([]);
  const [dadosEnsinos, setDadosEnsinos] = useState([]);

  useEffect(() => {
    api
      .get("filial/gestaoMatriculas/matriculas/periodosLetivos")
      .then((r) => setPeriodosLetivos(r?.data || []));

    api
      .get("filial/gestaoMatriculas/matriculas/series", {
        params: { ativo: true },
      })
      .then((r) => setSeries(r?.data || []));

    api
      .get("filial/gestaoMatriculas/matriculas/dadosMatriculaEnsinoController")
      .then((r) => setDadosEnsinos(r?.data || []));
  }, []);

  return (
    <SistemaList
      inputs={[{ filter: "dados.aluno.nome", label: "Aluno" }]}
      selects={[
        {
          list: periodosLetivos,
          value: "cod",
          label: "nome",
          filter: "periodoLetivo.cod",
          outLabel: "Período Letivo",
        },
        {
          list: series,
          value: "cod",
          label: "nome",
          filter: "serie.cod",
          outLabel: "Série",
        },
      ]}
      requests={{
        findAll: () => api.get("filial/gestaoMatriculas/matriculas"),
        findOne: (thisData) =>
          api.get("filial/gestaoMatriculas/matricula", {
            params: { cod: thisData?.cod },
          }),
        deleteOne: (thisData) =>
          api.delete("filial/gestaoMatriculas/matricula", {
            data: { cod: thisData?.cod },
          }),
      }}
      dataFilter={tabFilter}
    >
      <CustomBodyContent {...{ setTabFilter }} />

      <SLShowModal setter={setMountedData} title="Matrícula">
        <ShowContent {...{ mountedData, series, dadosEnsinos }} />
      </SLShowModal>
    </SistemaList>
  );
}

export default Matriculas;
