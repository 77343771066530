/* eslint-disable no-useless-computed-key */
import React from "react";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTableCell } from "~/pages/Sistema/componentes/SistemaList";
import { ThisTable } from "./styles";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";

function TableComponent({ setTabFilter }) {
  return (
    <>
      <TabsWrapper tabHeight={2.4}>
        <Tab
          materialIcon="share"
          onClick={() =>
            setTabFilter({ expirado: (data) => !data, corrigido: (data) => !data })
          }
        >
          Disponibilizadas
        </Tab>
        <Tab
          materialIcon="priority_high"
          onClick={() => setTabFilter({ expirado: true, corrigido: (data) => !data })}
        >
          Expiradas
        </Tab>
        <Tab materialIcon="done" onClick={() => setTabFilter({ corrigido: true })}>
          Corrigidas
        </Tab>
      </TabsWrapper>

      <SistemaComponenteInterno>
        <ThisTable noEdit>
          <SLTableCell name="avaliacao.nome">Nome</SLTableCell>
          <SLTableCell name="disciplinaTurma.disciplinaFilial.nome">
            Disciplina
          </SLTableCell>
          <SLTableCell name="disciplinaTurma.turma.nome">Turma</SLTableCell>
          <SLTableCell name="disciplinaTurma.turma.serie.nome">Série</SLTableCell>
        </ThisTable>
      </SistemaComponenteInterno>
    </>
  );
}

export default TableComponent;
