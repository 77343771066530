import styled from "styled-components";
import nuvem from "~/assets/imagens_grandes/nuvem.png";

export const Container = styled.div`
  background-image: url(${nuvem});
  background-position: top;
  background-repeat: repeat-x;
  margin-top: 2em;
  padding-top: 120px;

  ::before {
    content: "";
    display: block;
    height: calc(9em - 130px);
    background: #fff;
  }

  .copyRight {
    text-align: center;
  }
`;

export const Footer = styled.div`
  position: relative;
  background: #fff;
`;

export const Grid = styled.div`
  margin-top: -1em;
  padding: 5em 15% 3em 10%;
  display: flex;
  flex-wrap: wrap;

  .objetive {
    flex: 1 1 300px;
    margin: 40px 20px 40px 20px;
    position: relative;
    margin-right: 20px;

    .objetiveLogo {
      position: absolute;
      height: 6em;
      top: -7em;
      left: 1em;
    }
  }

  .listas {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    & > div {
      margin: 0 20px;
    }

    .mapa {
      min-width: fit-content;
      margin-bottom: 40px;

      & > div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
      }
    }

    .local li > p {
      min-width: fit-content;
      line-height: normal;
    }
  }
`;
