export function changeToken(matricula) {
  return {
    type: "@auth/CHANGE_TOKEN",
    payload: { matricula },
  };
}

export function signInRequest(email, senha, noRedirect) {
  return {
    type: "@auth/SIGN_IN_REQUEST",
    payload: { email, senha, noRedirect },
  };
}

export function signInSucces({ perfil, matriculas, matricula, preferencias }) {
  return {
    type: "@auth/SIGN_IN_SUCCESS",
    payload: { perfil, matriculas, matricula, preferencias },
  };
}

export function signUpRequest(name, email, senha) {
  return {
    type: "@auth/SIGN_UP_REQUEST",
    payload: { name, email, senha },
  };
}

export function signFailure(options) {
  const { preferencias, err } = options || {};

  return {
    type: "@auth/SIGN_IN_FAILURE",
    payload: { preferencias, err },
  };
}

export function signOut() {
  return {
    type: "@auth/SIGN_OUT",
  };
}
