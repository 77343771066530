import React, { useState, useEffect } from "react";
import Label from "~/componentes/CustomForm/Label";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import { SubjectsField, Filter } from "./styles";
import Pagination from "~/componentes/Pagination";
import DisciplinaCard from "./DisciplinaCard/DisciplinaCard";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";
import FilterList from "~/componentes/FilterList";
import api from "~/services/api";

/**
 * @param {Object} props
 * @param {Array} props.disciplinasTurma Recebe um array contendo as disciplinas de uma turma.
 * @param {Array} props.defaultData Lista de disciplinas da turma que iniciará como ativada cada disciplina deve conter disciplinaFilialCod, professorCod e professor.usuario.nome. Essa estrutura é o padrão do banco.
 * @param {Array} props.disciplinas Lista de disciplinas da filial
 * @param {Array} props.professores Lista de professores
 * @param {Function} props.setter Função que recebe como argumento um array contendo em cada item o disciplinaFialiCod e o professorCod
 * @param {Number} props.size Quantidade de cards por página.
 * @param {Number} props.rowSize Padrão 4 (máximo). Quantidade de cards por linha.
 * @param {Object} props.startTab Informa a tab inicial.
 * @param {Object} props.startTab.selecionadas Inicia na tab "selecionadas".
 * @param {Object} props.startTab.turma Inicia na tab "turma", válido apenas caso seja passado essa filtragem.
 */
function DisciplinasFilialList({ defaultData, setter, startTab = 0, size }) {
  const [disciplinas, setDisciplinas] = useState([]);
  const [professores, setProfessores] = useState([]);
  const [selecionadas, setSelecionadas] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [pageData, setPageData] = useState();
  const [tab, setTab] = useState();
  const [disciplinasForm, setDisciplinasForm] = useState({});

  useEffect(() => {
    async function fetchData() {
      await api
        .get("filial/professores")
        .then((r) => setProfessores(r?.data || []));

      await api
        .get("filial/disciplinasFilial")
        .then((r) => setDisciplinas(r?.data || []));
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (Array.isArray(defaultData) && defaultData.length > 0) {
      let thisFormData = {};

      defaultData.forEach((e) => {
        if (e.disciplinaFilialCod && e.professorCod) {
          thisFormData[e.disciplinaFilialCod] = {
            disciplinaFilialCod: e.disciplinaFilialCod,
            professorCod: e.professorCod,
          };
        }
      });
      setDisciplinasForm((prev) => ({ ...prev, ...thisFormData }));
    }
  }, [defaultData]);

  useEffect(() => {
    const setData = Object.values(disciplinasForm).filter(
      (e) => e.disciplinaFilialCod && e.professorCod
    );

    setter && setter(setData);
  }, [disciplinasForm, setter]);

  useEffect(() => {
    const selectData = disciplinas?.filter(
      (e) => !!disciplinasForm?.[e?.cod]?.disciplinaFilialCod
    );

    setSelecionadas(selectData);
  }, [disciplinasForm, disciplinas]);

  return (
    <div>
      <Label title="DISCIPLINAS:" />
      <FilterList
        setter={setFilteredData}
        data={tab === 1 ? selecionadas : disciplinas}
      >
        <Filter>
          <SistemaInput
            className="Turmas_Disciplinas_filter_input"
            placeholder="Procurar..."
            filter="nome"
          />
          <p>
            SELECIONADOS:
            {` ${selecionadas?.length || 0}/${
              disciplinas?.length || selecionadas?.length || 0
            }`}
          </p>
        </Filter>
      </FilterList>

      <TabsWrapper revertColor defaultTab={startTab}>
        <Tab onClick={() => setTab(0)} materialIcon="apps">
          Todos
        </Tab>
        <Tab onClick={() => setTab(1)} materialIcon="done_all">
          Selecionados
        </Tab>
      </TabsWrapper>
      <SubjectsField>
        {pageData?.map((disciplinaFilial) => (
          <DisciplinaCard
            key={disciplinaFilial.cod}
            {...{
              disciplinaFilial,
              professores,
              disciplinasForm,
              setDisciplinasForm,
            }}
          />
        ))}
      </SubjectsField>
      <Pagination
        data={filteredData}
        size={size || 8}
        getInfo={({ pageData }) => setPageData(pageData)}
      />
    </div>
  );
}

export default DisciplinasFilialList;
