import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { Container } from "./styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import { Materialize } from "~/styles/materializeGrid";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import ColoredFileInput from "~/componentes/CustomForm/FileInput/styles/ColoredFileInput";

function ShowContent({ mountedData, outClick }) {
  return (
    <Container title="Comunicado" outClick={outClick} scrollBar>
      <CustomScrollBar fixScrollMargin autoHeightMax="80vh">
        <Materialize>
          <div className="row">
            <Label
              title="Título:"
              text={mountedData?.titulo}
              className="col s12 m12 l12"
            />
            <Label title="Mensagem:" className="col s12 m12 l12">
              <SistemaTextArea readOnly value={mountedData?.mensagem} />
            </Label>
            {mountedData?.arquivos?.length > 0 && (
              <>
                <Label title="Arquivos" className="col s12 m12 12" />
                {mountedData?.arquivos?.map((arq, i) => (
                  <div
                    key={arq?.cod || i}
                    className={`Label_arquivos_file_input col s12 m12 ${
                      mountedData.arquivos.length > 1 ? "l6" : "l12"
                    }`}
                  >
                    <ColoredFileInput readOnly defaultFile={arq} />
                  </div>
                ))}
              </>
            )}
          </div>
        </Materialize>
      </CustomScrollBar>
    </Container>
  );
}

export default ShowContent;
