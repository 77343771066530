import React, { useState } from "react";

import api from "~/services/api";
import ShowContent from "./ShowContent";

import PadraoAlunosFiltro from "../../../../componentes/PadraoAlunosFiltro";
import { Container, ThisCard, ThisCardBody, ThisCardTitle } from "./styles";
import TextContent from "~/componentes/TextContent";
import ShowLoading from "~/utils/ShowLoading";
import Pagination from "~/componentes/Pagination";
import handleDate from "~/utils/handleDate";

function ConteudosDisponibilizados() {
  const [showModal, setShowModal] = useState(false);
  const [conteudos, setConteudos] = useState([]);
  const [pageData, setPageData] = useState();
  const [aluno, setAluno] = useState();
  const [alunoTurma, setAlunoTurma] = useState();
  const [alunoDisciplinaTurma, setAlunoDisciplinaTurma] = useState();

  async function handleUsuarioChange(data) {
    await ShowLoading(
      () =>
        api
          .get(
            "padrao/AcompanhamentoEscolar/conteudosDisponibilizados/conteudosDisponibilizados",
            {
              params: { usuarioCod: data?.cod },
            }
          )
          .then((r) => setConteudos(r?.data || [])),
      { successModal: false, errorModal: false }
    );

    return data;
  }

  const filteredConteudos = React.useMemo(() => {
    return conteudos.filter((av) => {
      return av?.disciplinaTurma?.alunoDisciplinaTurma?.find((adt) => {
        const alt = adt?.alunoTurma;
        const alu = alt?.aluno;
        if (alunoDisciplinaTurma?.cod) {
          return alunoDisciplinaTurma.cod === adt?.cod;
        } else if (alunoTurma?.cod) {
          return alunoTurma.cod === alt?.cod;
        } else if (aluno?.cod) {
          return aluno.cod === alu?.cod;
        }
        return true;
      });
    });
  }, [aluno, alunoDisciplinaTurma, alunoTurma, conteudos]);

  return (
    <>
      {showModal && (
        <ShowContent
          mountedData={showModal}
          outClick={() => setShowModal(false)}
        />
      )}
      <PadraoAlunosFiltro
        title="Filtrar Conteúdos"
        onChangeUsuario={handleUsuarioChange}
        alunoSetter={setAluno}
        alunoTurmaSetter={setAlunoTurma}
        alunoDisciplinaTurmaSetter={setAlunoDisciplinaTurma}
      />

      <Container>
        {Array.isArray(conteudos) && conteudos.length > 0 ? (
          <>
            {pageData?.map((thisData, i) => {
              const { disciplinaTurma, arquivos, filial } = thisData || {};
              const { disciplinaFilial, turma } = disciplinaTurma || {};

              return (
                <ThisCard
                  onClick={() => setShowModal(thisData)}
                  data={thisData}
                  key={thisData?.cod || i}
                >
                  <ThisCardTitle>
                    <h3>
                      #{thisData?.cod}
                      {arquivos?.length > 0 && (
                        <i className="material-icons">attachment</i>
                      )}
                    </h3>
                    <span>
                      {handleDate(thisData?.createdAtDate)?.dmy} às{" "}
                      {thisData?.createdAtHour}
                    </span>
                  </ThisCardTitle>
                  <ThisCardBody>
                    <div className="ThisCardBody_title_tur_disc_div">
                      <TextContent lines={1} setType="h2">
                        {thisData?.titulo}
                      </TextContent>
                      <TextContent
                        lines={1}
                        setType="p"
                        className="ThisCardbody_turma_disciplina"
                      >
                        {filial?.nomeFantasia} / {turma?.nome} /{" "}
                        {disciplinaFilial?.nome}
                      </TextContent>
                    </div>
                    <TextContent
                      lines={2}
                      className="ThisCardBody_TextContent_pre"
                    >
                      {thisData?.mensagem}
                    </TextContent>
                  </ThisCardBody>
                </ThisCard>
              );
            })}
            <Pagination
              data={filteredConteudos}
              size={6}
              getInfo={({ pageData }) => setPageData(pageData)}
            />
          </>
        ) : (
          <h4 style={{ textAlign: "center" }}>Não há conteudos disponíveis.</h4>
        )}
      </Container>
    </>
  );
}

export default ConteudosDisponibilizados;
