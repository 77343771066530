import React, { useState, useEffect, memo } from "react";
import customListStore from "../../../customListStore";
import CustomListCheckData from "../../../utils/CustomListCheckData";
import CustomListShowButton from "../../Buttons/CustomListShowButton";
import CustomListEditButton from "../../Buttons/CustomListEditButton";
import CustomListDeleteButton from "../../Buttons/CustomListDeleteButton";

/**
 * Aceita elementos do tipo Td com o atributo name igual a chave que deseja retornar o valor do objeto da tabela.
 * @param {Object} props
 * @param {Object} props.setter Recebe uma função que retorna os dados da linha clicada.
 */
function CustomListTableBody({ children, onClick, setter, ...rest }) {
  const [RenderChildren, setRenderChildren] = useState();
  const { state } = React.useContext(customListStore);
  const { filteredData, page, requests, rows, childrens } = state || {};
  const { deleteOne } = requests || {};

  useEffect(() => {
    const checkPage = !isNaN(page) && page >= 0 ? page : 0;
    const pageDate = filteredData && filteredData[checkPage];
    if (pageDate) {
      let renderRows = [];

      for (let i = 0; i < rows; i++) {
        let thisChildrens =
          children &&
          React.Children.map(children, (child) => {
            if (pageDate[i]) {
              const { name } = child.props || {};

              const elementValue = name && CustomListCheckData(name, pageDate[i]);

              if (elementValue && elementValue.value) {
                return React.cloneElement(child, {}, elementValue.value);
              }
              return child;
            }
            return <td />;
          });

        !Array.isArray(thisChildrens) && (thisChildrens = [thisChildrens]);

        let thisRowButtons = (childrens?.tableShowContent ||
          childrens?.tableEditContent ||
          deleteOne) && (
          <td style={{ textAlign: "end" }} className="CustomTable_td_options">
            {childrens?.tableShowContent && <CustomListShowButton data={pageDate[i]} />}
            {childrens?.tableEditContent && <CustomListEditButton data={pageDate[i]} />}
            {deleteOne && <CustomListDeleteButton data={pageDate[i]} />}
          </td>
        );

        renderRows.push(
          <tr
            onClick={(e) => {
              onClick && onClick(e);
              setter && setter(pageDate[i]);
            }}
            key={`TableBody_tr_${i}`}
          >
            {thisChildrens}
            {pageDate[i] ? thisRowButtons : <td />}
          </tr>
        );
      }

      setRenderChildren(renderRows);
    }
  }, [children, childrens, deleteOne, onClick, page, rows, setter, filteredData]);

  return <tbody {...rest}>{filteredData && RenderChildren}</tbody>;
}

export default memo(CustomListTableBody);
