import React from "react";
import FindKeyValue from "~/utils/FindKeyValue";

import { Container } from "./styles";
import IconButton from "../CustomForm/Button/Styles/IconButton";

function SingleValueList({ data, dataKey, materialIcon, iconClick, onClick, ...rest }) {
  return (
    <Container {...rest}>
      {data?.map((e, i) => (
        <div key={e?.cod || i} className="SingleValueList_div" {...{ onClick }}>
          {materialIcon && (
            <IconButton
              className="SingleValueList_div_IconButton"
              materialIcon={{ close: true }}
              onClick={() => iconClick && iconClick(e)}
            />
          )}
          <span>{FindKeyValue(dataKey, e)}</span>
        </div>
      ))}
    </Container>
  );
}

export default SingleValueList;
