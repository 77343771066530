import React, { useState } from "react";

import { Container } from "./styles";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import api from "~/services/api";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import QuestoesList from "../QuestoesList";
import { Materialize } from "~/styles/materializeGrid";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";

function CreateEditContent({
  mountedData,
  disciplinasFilial,
  questoesNiveis,
  tiposQuestoes,
}) {
  const [questoesAtivas, setQuestoesAtivas] = useState(
    mountedData ? mountedData?.questoes?.map((q) => q?.cod) : []
  );
  const [disciplinaFilialCod, setDisciplinaFilialCod] = useState(
    mountedData ? mountedData?.disciplinaFilialCod : disciplinasFilial?.[0]?.cod
  );
  const [questaoContent, setQuestaoContent] = useState();

  const disponibilizado = React.useMemo(() => mountedData?.avaliacoesTurmas?.length > 0, [
    mountedData,
  ]);

  async function handleSubmit(data, options) {
    const { closeReload } = options || {};

    await ShowConfirmacao(
      async () => {
        let response;

        if (mountedData) {
          if (disponibilizado) {
            response = await api.put("professor/avaliacao/changeActivity", {
              ativo: data?.ativo,
              cod: mountedData.cod,
            });
          } else {
            response = await api.put("professor/avaliacao", {
              ...data,
              cod: mountedData.cod,
            });
          }
        } else {
          response = await api.post("professor/avaliacao", {
            ...data,
          });
        }

        if (response?.status === 200) {
          closeReload && closeReload();
        }

        return response;
      },
      {
        successModal: `Avaliação ${mountedData ? "atualizada" : "criada"} com sucesso!`,
        texto: `Tem certeza que deseja ${
          mountedData ? "atualizar" : "criar"
        } essa avaliação?`,
        errorModal: {
          defaultMessage: `Ocorreu um erro ao ${
            mountedData ? "atualizar" : "criar"
          } essa avaliação, por favor, tente novamente.`,
        },
      }
    );
  }

  return (
    <>
      {questaoContent && (
        <SistemaFixedModal
          centerDefault
          outClick={() => {
            setQuestaoContent();
          }}
          title="Questão"
        >
          {questaoContent}
        </SistemaFixedModal>
      )}
      <Container defaultButtons defaultScroll onSubmit={handleSubmit}>
        <Materialize>
          <div className="row">
            <Label title="Nome" className="col s12 m12 l6">
              <SistemaInput
                name="nome"
                disabled={disponibilizado}
                defaultValue={mountedData ? mountedData?.nome : ""}
              />
            </Label>
            <Label title="Nota Máx." className="col s12 m12 l2">
              <SistemaInput
                type="number"
                name="notaMaxima"
                disabled={disponibilizado}
                defaultValue={mountedData ? mountedData?.notaMaxima : ""}
              />
            </Label>
            <Label title="Disciplina" className="col s12 m12 l4">
              <SistemaSelect
                isNumber
                disabled={disponibilizado}
                name="disciplinaFilialCod"
                setter={(data) => {
                  const ativas = [];
                  if (
                    mountedData?.disciplinaFilialCod === data &&
                    mountedData?.questoes
                  ) {
                    mountedData.questoes.forEach((q) => {
                      if (q?.disciplinaFilialCod === data && q?.cod) {
                        ativas.push(q.cod);
                      }
                    });
                  }
                  setQuestoesAtivas(ativas);
                  setDisciplinaFilialCod(data);
                }}
                defaultValue={
                  mountedData
                    ? mountedData?.disciplinaFilialCod
                    : disciplinasFilial?.[0]?.cod
                }
                list={{ list: disciplinasFilial, value: "cod", label: "nome" }}
              />
            </Label>
            <Label title="Descrição" className="col s12 m12 l12">
              <SistemaTextArea
                disabled={disponibilizado}
                name="descricao"
                defaultValue={mountedData ? mountedData?.descricao : ""}
              />
            </Label>
            <Label title="Ativo" className="col s12 m12 l4">
              <SistemaSelect
                name="ativo"
                defaultValue={
                  mountedData?.ativo === false || mountedData?.ativo === "false"
                    ? "false"
                    : "true"
                }
              >
                <option value="true">Ativo</option>
                <option value="false">Desativo</option>
              </SistemaSelect>
            </Label>
          </div>
          <QuestoesList
            className="col s12 m12 l12"
            showOnly={disponibilizado}
            {...{
              startSelected: !!mountedData,
              defaultData: mountedData?.questoes,
              questoesAtivas,
              setQuestaoContent,
              disciplinaFilialCod,
              disciplinasFilial,
              questoesNiveis,
              tiposQuestoes,
            }}
          />
        </Materialize>
      </Container>
    </>
  );
}

export default CreateEditContent;
