import styled from "styled-components";
const triangle = (side = "left", color = "#000") => `
  ::before {
    content: "";
    position: absolute;
    ${side}: -0.9em;
    top: 0;
    width: 0;
    height: 0;
    border-${side}: 1em solid transparent;
    border-top: 1em solid ${color};
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  align-items: center;
  width: 100vw;
  /* height: 90vh; */
  margin-bottom: 7em;

  .foguete {
    position: absolute;
    height: calc(100% + 10em);
    width: auto;
    top: calc(120px + 8em);
    left: ${(props) => (props.rotatedTablet ? "-15em" : "-10em")};
    z-index: 0;
  }
  :before {
    content: "";
  }
`;

export const Content = styled.div`
  width: 70%;
  border-radius: 3em;
  ${({ desktop, rotatedTablet }) => !(desktop || rotatedTablet) && "margin: auto; h1{text-align: center}"}
  padding: 
    ${({ desktop, rotatedTablet }) => (desktop || rotatedTablet ? `1em 1em` : `2em`)};
  background: #ffffff;
  box-shadow: 0.375em 0.375em 1.25em rgba(134, 134, 134, 0.7);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    padding: 0 0.5em;
    font-size: 1.5em;
    margin-bottom: 2em;
  }

  @media (min-width: 993px) {
    margin-right: 2em;
  }

  @media (max-width: 993px) {
    width: 90% !important;
    border-radius: 1em !important;
    margin-top: -1em;
    h1 {
      position: absolute;
      top: -2.7em;
      left: 0;
      color: #fff;
      font-size: 1.2em;
    }
  }

  .content {
    padding: ${(props) => {
      if (props.desktop) {
        return `1em 1em 1em 3em`;
      }
      if (props.rotatedTablet) {
        return "0em 1em";
      } else {
        return "1em";
      }
    }};
    ${(props) =>
      props.desktop
        ? `
        display: grid;
        grid-template-columns: auto auto;
        grid-row: auto auto;
        grid-row-gap: 3em;
    `
        : `
        display: flex;
        flex-direction: column;
    `}

    .box {
      color: #fff;
      padding: 0.5em 1em;
      width: ${(props) => (props.rotatedTablet ? `20em` : `20em`)};
      max-width: 100%;
      background: #1ca9d0;
      box-shadow: 0.2em 0.2em 0.5em #00000042;
      position: relative;

      .boxImageContent {
        position: absolute;
        top: -2em;
        background: #ffffff;
        box-shadow: 0 0.1em 0.5em #0006;
        border-radius: 50%;
        height: 4em;
        width: 4em;
        display: flex;
        align-items: center;
        justify-items: center;

        img {
          height: 60%;
          width: auto;
          margin: auto;
        }
      }

      strong {
        display: block;
        text-align: center;
        font-size: 1.2em;
      }

      p {
        font-size: 0.9em;
      }
    }

    .box1,
    .box3 {
      border-radius: 0 1.2em 1.2em 1.2em;
      margin-left: ${(props) => (props.desktop ? `5em` : `1.5em`)};

      .boxImageContent {
        left: ${(props) => (props.desktop ? `-5.5em` : `-2em`)};
      }
    }

    .box2,
    .box4 {
      border-radius: 1.2em 0 1.2em 1.2em;
      margin-left: auto;
      margin-right: ${(props) => (props.desktop ? `7em` : `1.5em`)};

      .boxImageContent {
        right: ${(props) => (props.desktop ? `-5.5em` : `-2em`)};
      }
    }

    .box2,
    .box3,
    .box4 {
      margin-top: ${(props) => {
        if (props.rotatedTablet) {
          return `1em`;
        }
        if (!props.desktop) {
          return `3em`;
        }
      }};
    }

    .box1 {
      background: #1ca9d0;

      ${(props) => props.desktop && triangle("left", "#1ca9d0")}
    }

    .box2 {
      background: #4ea654;

      ${(props) => props.desktop && triangle("right", "#4EA654")}
    }

    .box3 {
      background: #ff4031;

      ${(props) => props.desktop && triangle("left", "#FF4031")}
    }

    .box4 {
      background: #f9d233;

      ${(props) => props.desktop && triangle("right", "#f9d233")}
    }
  }
  @media (max-width: 400px) {
    .content {
      margin-left: -1em;
    }
  }
`;
