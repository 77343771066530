import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import SingleValueList from "~/componentes/SingleValueList";
import { useState } from "react";
import { useEffect } from "react";
import api from "~/services/api";

import { Container } from "./styles";

function DisciplinasFilialList({ defaultData, setter }) {
  const [disciplina, setDisciplina] = useState();
  const [disciplinasFilial, setDisciplinasFilial] = useState([]);
  const [dfSelected, setDfSelected] = useState([]);
  const [dfAvailable, setDfAvailable] = useState([]);

  useEffect(() => {
    api.get("filial/disciplinasFilial").then((r) => setDisciplinasFilial(r?.data || []));
  }, []);

  useEffect(() => {
    if (Array.isArray(defaultData)) {
      setDfSelected((prev) => {
        const newData =
          defaultData.filter((e) => !prev?.find((p) => p?.cod === e?.cod)) || [];

        return [...prev, ...newData];
      });
    }
  }, [defaultData]);

  useEffect(() => {
    const newData =
      disciplinasFilial.filter((e) => !dfSelected?.find((p) => p?.cod === e?.cod)) || [];

    setDfAvailable(newData);
  }, [dfSelected, disciplinasFilial]);

  useEffect(() => {
    if (setter) {
      const setterValue = dfSelected?.map((e) => e?.cod) || [];
      setter(setterValue);
    }
  }, [dfSelected, setter]);

  function handleAddDisciplina() {
    if (disciplina?.cod) {
      const checkDisciplina = dfSelected?.find((e) => e?.cod === disciplina?.cod);

      if (!checkDisciplina) {
        setDfSelected((prev) => [...prev, disciplina]);
      }
    }
  }

  function handleRemoveDisciplina(data) {
    setDfSelected((prev) => {
      const newPrev = [...prev];
      const checkIndex = prev.findIndex((e) => e?.cod === data?.cod);

      if (checkIndex >= 0) {
        newPrev.splice(checkIndex, 1);
      }

      return newPrev;
    });
  }

  return (
    <Container>
      <Label title="DISCIPLINAS" />
      <div className="Professores_div_addDisciplinas">
        <SistemaSelect
          firstOption
          list={{ list: dfAvailable, value: "cod", label: "nome" }}
          setList={setDisciplina}
        />
        <ColoredButton small blueLayout type="button" onClick={handleAddDisciplina}>
          ADICIONAR
        </ColoredButton>
      </div>
      <SingleValueList
        data={dfSelected}
        dataKey="nome"
        materialIcon="close"
        iconClick={handleRemoveDisciplina}
      />
    </Container>
  );
}

export default DisciplinasFilialList;
