import * as GestaoEscolar from "./GestaoEscolar";
import * as ControleAcesso from "./ControleAcesso";
import * as GestaoFinanceira from "./GestaoFinanceira";
import * as GestaoMatriculas from "./GestaoMatriculas";
import * as Relatorios from "./Relatorios";
import * as Suporte from "./Suporte";
import * as Reunioes from "./Reunioes";
import { default as DashBoard } from "./DashBoard";
import { default as AlterarDados } from "./AlterarDados";

export default {
  DashBoard,
  AlterarDados,
  GestaoEscolar,
  ControleAcesso,
  GestaoFinanceira,
  GestaoMatriculas,
  Relatorios,
  Suporte,
  Reunioes,
};
