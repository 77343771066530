import React, { useState } from "react";

import api from "~/services/api";
import CreateEditContent from "./CreateEditContent";
import ShowContent from "./ShowContent";
import {
  SLCreateModal,
  SLShowModal,
  SLEditModal,
} from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import TableComponent from "./TableComponent";
import { useEffect } from "react";

function PeriodoMatricula() {
  const [mountedData, setMountedData] = useState();
  const [series, setSeries] = useState([]);
  const [tabFilter, setTabFilter] = useState([]);

  useEffect(() => {
    api.get("global/series", { params: { turmasAtivas: true } }).then((r) => {
      setSeries(r?.data);
    });
  }, []);

  return (
    <Container
      requests={{
        findAll: () => api.get("filial/periodosMatricula"),
        deleteOne: (thisData) =>
          api.delete("filial/periodoMatricula", { data: { cod: thisData.cod } }),
        findOne: (thisData) =>
          api.get("filial/periodoMatricula", {
            params: { cod: thisData.cod },
          }),
      }}
      dataFilter={tabFilter}
    >
      <TableComponent {...{ setTabFilter }} />

      <SLShowModal title="Período de Matrícula" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLEditModal title="Atualizar Período de Matrícula" setter={setMountedData}>
        <CreateEditContent {...{ mountedData, series }} />
      </SLEditModal>

      <SLCreateModal title="Novo Período de Matrícula">
        <CreateEditContent {...{ series }} />
      </SLCreateModal>
    </Container>
  );
}

export default PeriodoMatricula;
