/**
 * @param {any} error Recebe uma string ou um error. Se string, será mostrado a mensagem
 *  informada, se for um erro, buscará por um padrão de erro do Youch, retornando a mensagem
 *  contida no primeiro erro que tenha como true a chave errorHandled.
 */
export function errorToggle(error, defaultMessage) {
  return {
    type: "@errorModal/errorToggle",
    payload: { error, defaultMessage },
  };
}
