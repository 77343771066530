/* eslint-disable eqeqeq */
import React from "react";
import IconButton from "~/componentes/CustomForm/Button/Styles/IconButton";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import handleDate from "~/utils/handleDate";
import ShowError from "~/utils/ShowError";

function AddAulaDisciplinaTurmaModal({ aulasDisciplinasTurmas, periodosLetivos: plData, setAulasDisciplinasTurmas, addNames }) {
  const [periodoLetivo, setPeriodoLetivo] = React.useState();
  const [turma, setTurma] = React.useState();
  const [disciplinaTurma, setDisciplinaTurma] = React.useState();
  const [dataDisponibilizacao, setDataDisponibilizacao] = React.useState();
  const [allowAdd, setAllowAdd] = React.useState(true);

  const periodosLetivos = React.useMemo(() => {
    const result = [];

    if (Array.isArray(aulasDisciplinasTurmas) && Array.isArray(plData)) {
      plData.forEach((pl) => {
        if (Array.isArray(pl?.turmas)) {
          const thisTurmas = [];
          pl.turmas.forEach((tur) => {
            const thisDisciplinasTurmas = tur?.disciplinasTurma?.filter(
              (dtm) => !aulasDisciplinasTurmas?.find((aulaDT) => aulaDT?.disciplinaTurmaCod == dtm?.cod)
            );
            if (thisDisciplinasTurmas.length > 0) {
              thisTurmas.push({ ...tur, disciplinasTurma: thisDisciplinasTurmas });
            }
          });

          if (thisTurmas.length > 0) {
            result.push({ ...pl, turmas: thisTurmas });
          }
        }
      });
    }

    const firstPl = result?.[0];
    // if (firstPl) {
    setPeriodoLetivo(firstPl);

    // if (firstPl?.turmas?.length === 1) {
    const thisTur = firstPl?.turmas?.[0];
    setTurma(thisTur);

    // if (thisTur?.disciplinasTurma?.length === 1) {
    setDisciplinaTurma(thisTur?.disciplinasTurma?.[0]);
    //     }
    //   }
    // }

    // if (firstPl) {
    //   setPeriodoLetivo((prev) => (!prev ? firstPl : prev));

    //   if (firstPl?.turmas?.length === 1) {
    //     const thisTur = firstPl.turmas[0];
    //     setTurma((prev) => (!prev ? thisTur : prev));

    //     if (thisTur?.disciplinasTurma?.length === 1) {
    //       setDisciplinaTurma((prev) => (!prev ? thisTur.disciplinasTurma[0] : prev));
    //     }
    //   }
    // }

    return result;
  }, [aulasDisciplinasTurmas, plData]);

  function defaultInputDate() {
    const { day, month, year } = handleDate(new Date());

    return `${year}-${month}-${day}`;
  }

  React.useEffect(() => {
    !allowAdd && setAllowAdd(true);
  }, [allowAdd]);

  function handleSubmit() {
    if (allowAdd) {
      if (disciplinaTurma?.cod && dataDisponibilizacao) {
        setAllowAdd(false);
        setAulasDisciplinasTurmas((prev) => [
          ...prev,
          {
            disciplinaTurmaCod: disciplinaTurma?.cod,
            dataDisponibilizacao,
            ...(addNames
              ? { disciplinaNome: disciplinaTurma?.disciplinaFilial.nome, turmaNome: turma?.nome, periodoLetivoNome: periodoLetivo?.nome }
              : {}),
          },
        ]);
        setPeriodoLetivo();
        setTurma();
        setDisciplinaTurma();
      } else {
        ShowError(`É necessário informar a ${!disciplinaTurma?.cod ? "disciplina" : "data de disponibilização"}`);
      }
    }
  }

  return (
    <tr>
      <th>
        <SistemaSelect
          firstOption
          style={{ width: "13em" }}
          onChange={(e) => {
            const thisVal = e.currentTarget.value;

            const findThis = periodosLetivos?.find((t) => t?.cod == thisVal);

            setPeriodoLetivo(findThis);
            setTurma();
            setDisciplinaTurma();
          }}
          value={periodoLetivo?.cod || ""}
          list={{ list: periodosLetivos, value: "cod", label: "nome" }}
        />
      </th>
      <th>
        <SistemaSelect
          firstOption
          style={{ width: "13em" }}
          onChange={(e) => {
            const thisVal = e.currentTarget.value;
            const findThis = periodoLetivo?.turmas?.find((t) => t?.cod == thisVal);

            setTurma(findThis);
            setDisciplinaTurma();
          }}
          value={turma?.cod || ""}
          list={{ list: periodoLetivo?.turmas, value: "cod", label: "nome" }}
        />
      </th>
      <th>
        <SistemaSelect
          firstOption
          style={{ width: "13em" }}
          onChange={(e) => {
            const thisVal = e.currentTarget.value;
            const findThis = turma?.disciplinasTurma?.find((t) => t?.cod == thisVal);

            setDisciplinaTurma(findThis);
          }}
          value={disciplinaTurma?.cod || ""}
          list={{ list: turma?.disciplinasTurma, value: "cod", label: "disciplinaFilial.nome" }}
        />
      </th>
      <th>
        <SistemaInput
          style={{ width: "13em" }}
          type="datetime-local"
          onChange={(e) => {
            const thisValue = e.currentTarget.value;

            setDataDisponibilizacao(thisValue);
          }}
          // setter={setDataDisponibilizacao}
          defaultValue={defaultInputDate()}
        />
      </th>

      <th style={{ width: "3.3em" }}>
        <IconButton
          // materialIcon="add_circle"
          type="button"
          materialIcon="add"
          onClick={handleSubmit}
        />
      </th>
    </tr>
  );
}

export default AddAulaDisciplinaTurmaModal;
