import styled from "styled-components";
import estrelas from "~/assets/wallpapers/ceu_corte_lateral.png";

export const Container = styled.div`
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  background: #203446;
  background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0) 50%
    ),
    url(${estrelas});
  background-repeat: repeat;
  background-size: 993px;

  main {
    margin-top: 7em;
  }
`;
