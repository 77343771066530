import React, { useEffect } from "react";
import CustomList, {
  CLTableBody,
  CLTableHeader,
  CLTableCell,
} from "~/pages/Sistema/componentes/CustomList";
import api from "~/services/api";
import { useState } from "react";
import EditModal from "./EditModal";
import CreateModal from "./CreateModal";
import ShowModal from "./ShowModal";

export default function Funcionarios() {
  const [cargos, setCargos] = useState([]);

  useEffect(() => {
    async function handleRequests() {
      const response = await api.get("filial/cargosFilial");

      if (response?.status === 200 && response?.data) {
        setCargos(response.data);
      }
    }

    handleRequests();
  }, []);

  return (
    <CustomList
      requests={{
        findAll: () => api.get("filial/funcionariosFilial"),
        findOne: (funcionario) =>
          api.get("filial/funcionarioFilial", { params: { cod: funcionario.cod } }),
        deleteOne: (funcionario) =>
          api.delete("filial/funcionarioFilial", { data: { cod: funcionario.cod } }),
      }}
    >
      <CLTableHeader>
        <th>NOME</th>
        <th>CARGO</th>
        <th>EMAIL</th>
      </CLTableHeader>

      <CLTableBody>
        <CLTableCell search="Nome" name="nome" />
        <CLTableCell
          filter={{ list: cargos, value: "nome", outLabel: "Cargo" }}
          name="cargoFilial"
        />
        <CLTableCell search="Email" name="email" />
      </CLTableBody>

      {ShowModal()}
      {CreateModal({ cargos })}
      {EditModal({ cargos })}
    </CustomList>
  );
}
