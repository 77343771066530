import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { Container } from "./styles";
import { Materialize } from "~/styles/materializeGrid";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import ColoredFileInput from "~/componentes/CustomForm/FileInput/styles/ColoredFileInput";
import AulasDisciplinaTurmaTable from "../AulasDisciplinaTurmaTable";
import AulaLinks from "../AulaLinks";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import api from "~/services/api";

function ShareContent({ mountedData, periodosLetivos }) {
  const [aulasDisciplinasTurmas, setAulasDisciplinasTurmas] = React.useState([]);

  React.useEffect(() => {
    if (Array.isArray(mountedData?.aulasDisciplinasTurmas)) {
      setAulasDisciplinasTurmas((prev) => (prev.length > 0 ? prev : [...mountedData.aulasDisciplinasTurmas]));
    }
  }, [mountedData]);

  async function handleSubmit(data, options) {
    const { closeReload } = options || {};

    ShowConfirmacao(
      async () =>
        await api.post("professor/gestaoDisciplinas/aula/share", { cod: mountedData?.cod, aulasDisciplinasTurmas }).then((r) => {
          if (r?.status === 200 && typeof closeReload === "function") {
            closeReload();
          }
          return r;
        }),
      {
        successModal: "Aula disponibilizada com sucesso",
        errorModal: {
          defaultMessage: "Falha ao disponibilizar a aula, por favor tente novamente.",
        },
        message: "Tem certeza que deseja disponibilizar essa aula?",
      }
    );
  }

  return (
    <Container defaultStyle onSubmit={handleSubmit}>
      <Materialize>
        <div className="row">
          <Label title="Título:" text={mountedData?.titulo || ""} className="col s12 m12 l12" />
          <Label title="Descrição:" className="col s12 m12 l12">
            <SistemaTextArea readOnly value={mountedData?.descricao || ""} />
          </Label>

          <AulaLinks links={mountedData?.links} />

          <AulasDisciplinaTurmaTable {...{ aulasDisciplinasTurmas, setAulasDisciplinasTurmas, periodosLetivos }} />

          {mountedData?.arquivos?.length > 0 && (
            <>
              <Label title="Arquivos" className="col s12 m12 12" />
              {mountedData?.arquivos?.map((arq, i) => (
                <div key={arq?.cod || i} className="Label_arquivos_file_input col s12 m12 l6">
                  <ColoredFileInput readOnly defaultFile={arq} />
                </div>
              ))}
            </>
          )}
        </div>
      </Materialize>
    </Container>
  );
}

export default ShareContent;
