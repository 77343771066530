import styled from "styled-components";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import CheckWrapper from "~/componentes/CustomForm/CheckWrapper";
import Check from "~/componentes/CustomForm/Check";
import { SLForm } from "~/pages/Sistema/componentes/SistemaList";

export const Container = styled(SLForm)`
  width: 55em;
  max-width: 100%;
`;

export const SubjectsField = styled(CheckWrapper)`
  display: flex;
  flex-wrap: wrap;

  .SubjectCard {
    cursor: pointer;
    width: 13.188em;
    height: 7.438em;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.09);
    border-radius: 0.375em;
    padding: 0.5em;

    flex: 0 1 100%;
    margin: 0.5em;

    @media (min-width: 30em) {
      flex: 0 1 calc(50% - 1.1em);
    }

    @media (min-width: 45em) {
      flex: 0 1 calc(33% - 1.1em);
    }

    @media (min-width: 60em) {
      flex: 0 1 calc(25% - 1.1em);
    }

    .SubjectHeader {
      display: flex;
      align-items: center;
      margin-bottom: 0.625em;
      padding-bottom: 0.625em;
      border-bottom: solid #f0f0f0 0.125em;

      h4 {
        margin-left: 0.75em;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;

        color: #293f57;
      }
    }
    .SubjectBody {
      span {
        font-style: normal;
        font-weight: 600;
        font-size: 0.75em;
        line-height: 1em;

        color: #293f57;
        text-transform: uppercase;
        padding: 0.25em 0;
      }
      select {
        width: 100%;
      }
    }
  }
`;

export const SistemaSelectCustom = styled(SistemaSelect)`
  :disabled {
    background: #dedede;
    border: none;
  }
`;

export const CheckInput = styled(Check)`
  margin: 0 0.75em 0 0;
  appearance: none;
  width: 1.25em;
  height: 1.25em;
  background: #d0d7db;
  border-radius: 0.125em;
  transition: 0.3s ease;
  cursor: pointer;
  outline: none;
  border: none;
  box-shadow: none;
  position: relative;

  :hover {
    box-shadow: none;
  }

  :checked {
    background: #1ca9d0;
  }
  :checked ~ h6 {
    color: #293f57;
  }

  ::before {
    position: absolute;
    content: "";
    width: 0.875em;
    height: 0.125em;
    background: #fff;
    -webkit-transform: rotate(-50deg);
    -ms-transform: rotate(-50deg);
    transform: rotate(-50deg);
    top: 0.563em;
    left: 0.313em;
    transition: 0.3s ease;
    opacity: 0;
  }
  ::after {
    position: absolute;
    content: "";
    width: 0.375em;
    height: 0.125em;
    background: #fff;
    -webkit-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    transform: rotate(50deg);
    top: 0.689em;
    left: 0.188em;
    transition: 0.3s ease;
    opacity: 0;
  }

  :checked::before,
  :checked::after {
    opacity: 1;
  }
`;

export const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .Turmas_Disciplinas_filter_input {
    width: 49%;
  }

  .Turmas_Disciplinas_filter_label {
    width: 49%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;
