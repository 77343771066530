import moment from "moment";
import React, { useMemo } from "react";
import LogoPadrao from "~/assets/logos/Escola_logo.png";

import { Container } from "./styles";

moment.locale();
function SchoolCard({ onClick, filial }) {
  const { matriculaInicio, matriculaFim } = useMemo(() => {
    const getDate = (date) => (date ? moment(new Date(date)).utc().format("DD/MM/YYYY") : "");

    return {
      matriculaInicio: getDate(filial?.matriculaInicio),
      matriculaFim: getDate(filial?.matriculaFim),
    };
  }, [filial]);

  return (
    <Container filial={filial}>
      <div className="School-field" onClick={onClick}>
        <div className="card-header">
          <div className="image-container">
            <img src={filial?.imagemLogoMatricula?.url || filial?.imagemPerfilMatricula?.url || LogoPadrao} alt="Imagem escola" />
          </div>
          <div>
            <h3>{filial?.nomeFantasia}</h3>
            <p>
              {filial?.cidade?.nome}, {filial?.cidade?.uf}
            </p>
          </div>
        </div>
        <div className="card-content">
          <div className="card-item">
            <i className="material-icons">date_range</i>
            <h4>
              Do dia{" "}
              <span>
                {matriculaInicio} á {matriculaFim}
              </span>
            </h4>
          </div>
          <div className="card-item">
            <i className="material-icons">school</i>
            <h4>
              Total de vagas: <span>{filial?.vagas}</span>
            </h4>
          </div>
        </div>
        <div className="card-footer">
          <div className="footer-tag yellow">{filial?.publico ? "Pública" : "Particular"}</div>
        </div>
      </div>
    </Container>
  );
}

export default SchoolCard;
