import styled from "styled-components";

export const DisciplinaCardStyle = styled.label`
  cursor: pointer;
  /* height: 7.5em; */
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.09);
  border-radius: 0.4em;
  padding: 0.5em;

  flex: 0 1 100%;
  margin: 0.5em;

  @media (min-width: 30em) {
    flex: 0 1 calc(50% - 1.1em);
  }
  @media (min-width: 45em) {
    flex: 0 1 calc(33% - 1.1em);
  }

  @media (min-width: 60em) {
    flex: 0 1 calc(25% - 1.1em);
  }

  .SubjectHeader {
    display: flex;
    align-items: center;
    margin-bottom: 0.625em;
    padding-bottom: 0.625em;
    border-bottom: solid #f0f0f0 0.125em;

    h4 {
      margin-left: 0.75em;
      font-style: normal;
      font-weight: 600;
      line-height: 1.3;

      color: #293f57;
    }
  }

  .SubjectBody {
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 0.75em;
      line-height: 1em;

      color: #293f57;
      text-transform: uppercase;
      padding: 0.25em 0;
    }
    select {
      width: 100%;
    }
  }
`;
