import React, { useEffect, useState } from "react";
import { useMercadopago } from "react-sdk-mercadopago/lib";

function Checkout({ mountedData }) {
    const mercadopago =  useMercadopago.v2(mountedData.filial.proprietarioEmp.tokenPagamentoPub, {
        locale: 'pt-BR'
    });

    useEffect(() => {/* 
        console.log(mountedData);
        console.log(mountedData.filial.proprietarioEmp.tokenPagamentoPub);
        console.log(mercadopago, mountedData.preferencia); */
        if (mercadopago && (mountedData.preferencia != "")) {
            mercadopago.checkout({
                preference: {
                    id: mountedData.preferencia
                },
                render: {
                    container: '.cho-container',
                    label: 'Pagar',
                }
            })
        }
    }, [mercadopago, mountedData.preferencia])

  return (
    <div className="cho-container"></div>
  );
}

export default Checkout;
