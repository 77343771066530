import React from "react";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTableCell } from "~/pages/Sistema/componentes/SistemaList";
import { ThisTable } from "./styles";

function TableComponent({ cargos }) {
  return (
    <>
      <SistemaComponenteInterno>
        <ThisTable>
          <SLTableCell name="usuario.nome" inputfilter>
            Nome
          </SLTableCell>
          <SLTableCell filter={{ list: cargos, value: "nome", outLabel: "Cargo" }} name="cargo.nome">
            Cargo
          </SLTableCell>
          <SLTableCell name="usuario.email" inputfilter>
            Email
          </SLTableCell>
        </ThisTable>
      </SistemaComponenteInterno>
    </>
  );
}

export default TableComponent;
