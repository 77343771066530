import React from "react";
import sistemaStore from "~/pages/Sistema/sistemaStore";
import Card from "./Card";

import { Container } from "./styles";

function FirstSection({ stats }) {
  const { setComponentPath } = React.useContext(sistemaStore);

  return (
    <Container>
      <Card
        title="Questões"
        subtitle="Questões cadastradas"
        color="#FF4031"
        cardNumber={1}
        number={stats?.totalQuestoes}
        onClick={() =>
          setComponentPath({
            moduloPath: "GestaoDisciplinas",
            menuPath: "Questoes",
          })
        }
      />
      <Card
        title="Avaliações"
        subtitle="Avaliações cadastradas"
        color="#4FA754"
        cardNumber={2}
        number={stats?.totalAvaliacoes}
        onClick={() =>
          setComponentPath({
            moduloPath: "GestaoDisciplinas",
            menuPath: "Avaliacao",
          })
        }
      />
      <Card
        title="Disponibilizadas"
        subtitle="Avaliações disponibilizadas"
        color="#1CA9D0"
        cardNumber={3}
        number={stats?.totalAvaDisp}
        onClick={() =>
          setComponentPath({
            moduloPath: "GestaoTurmas",
            menuPath: "AvaliacoesDisponibilizadas",
          })
        }
      />
      <Card
        title="Turmas"
        subtitle="Turmas no qual ensina"
        color="#FFDA44"
        cardNumber={4}
        number={stats?.totalTurmas}
        onClick={() =>
          setComponentPath({
            moduloPath: "GestaoTurmas",
            menuPath: "Turmas",
          })
        }
      />
    </Container>
  );
}

export default FirstSection;
