import React from "react";

import { Container } from "./styles";
import { Button } from "~/componentes/CustomForm";
import { useDispatch, useSelector } from "react-redux";
import { changeToken } from "~/store/modules/auth/actions";

export default function Inicio() {
  const dispatch = useDispatch();
  const { matriculas } = useSelector((state) => state.auth);

  return (
    <Container>
      <h3>Matriculas</h3>
      <div className="matriculas">
        {matriculas &&
          matriculas.map(
            (matricula, index) =>
              matricula.tipo !== "aluno" && (
                <Button
                  onClick={() => dispatch(changeToken(matricula))}
                  key={index}
                  className="matriculaCard"
                >
                  {matricula.cargo && <p>cargo: {matricula.cargo}</p>}
                  {matricula.tipo && <p>tipo: {matricula.tipo}</p>}
                  {matricula.empresa && <p>Empresa: {matricula.empresa}</p>}
                  {matricula.filial && !matricula.empresa && (
                    <p>Filial: {matricula.filial}</p>
                  )}
                </Button>
              )
          )}
      </div>
    </Container>
  );
}
