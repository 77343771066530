import React, { useEffect, useState } from "react";
import api from "~/services/api";
import EditContent from "./EditContent";
import CreateContent from "./CreateContent";
import ShowContent from "./ShowContent";
import { Container } from "./styles";
import TableComponent from "./TableComponent";
import {
  SLShowModal,
  SLEditModal,
  SLCreateModal,
} from "~/pages/Sistema/componentes/SistemaList";

export default function Funcionarios() {
  const [cargos, setCargos] = useState([]);
  const [modulos, setModulos] = useState([]);
  const [mountedData, setMountedData] = useState();

  useEffect(() => {
    // async function handleRequests() {
    api.get("admin/acessos").then((response) => setModulos(response.data));
    api.get("admin/cargos").then((response) => setCargos(response.data));
    // }

    // handleRequests();
  }, []);

  return (
    // <CustomList
    //   requests={{
    //     findAll: () => api.get("admin/administradores"),
    //     findOne: (thisData) => api.get("admin/administrador", { params: { cod: thisData.cod } }),
    //     deleteOne: (thisData) => api.delete("admin/administrador", { data: { cod: thisData.cod } }),
    //   }}
    // >
    //   <CLTableHeader>
    //     <th>NOME</th>
    //     <th>CARGO</th>
    //     <th>EMAIL</th>
    //   </CLTableHeader>

    //   <CLTableBody>
    //     <CLTableCell search="Nome" name="usuario.nome" />
    //     <CLTableCell filter={{ list: cargos, value: "nome", outLabel: "Cargo" }} name="cargo.nome" />
    //     <CLTableCell search="Email" name="usuario.email" />
    //   </CLTableBody>

    //   <CLShowComponent title="Dados do Funcionário" contentMountUnMount={setMountedData}>
    //     {mountedData && modulos && <ShowContent {...{ mountedData, modulos }} />}
    //   </CLShowComponent>

    //   <CLEditComponent title="Atualizar Funcionário" contentMountUnMount={setMountedData}>
    //     {mountedData && modulos && <EditContent {...{ cargos, modulos, mountedData }} />}
    //   </CLEditComponent>

    //   <CLCreateComponent>{modulos && <CreateContent {...{ modulos, cargos }} />}</CLCreateComponent>
    // </CustomList>
    <Container
      requests={{
        findAll: () => api.get("admin/administradores"),
        findOne: (thisData) =>
          api.get("admin/administrador", { params: { cod: thisData.cod } }),
        deleteOne: (thisData) =>
          api.delete("admin/administrador", { data: { cod: thisData.cod } }),
      }}
    >
      <TableComponent {...{ cargos }} />

      <SLShowModal title="Funcionário" setter={setMountedData}>
        {mountedData && modulos && (
          <ShowContent {...{ mountedData, modulos }} />
        )}
      </SLShowModal>

      <SLEditModal title="Atualizar Funcionário" setter={setMountedData}>
        {mountedData && modulos && (
          <EditContent {...{ cargos, modulos, mountedData }} />
        )}
      </SLEditModal>

      <SLCreateModal title="Novo Funcionário">
        {modulos && <CreateContent {...{ modulos, cargos }} />}
      </SLCreateModal>
    </Container>
  );
}
