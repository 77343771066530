import React from "react";
import foguete from "~/assets/imagens_grandes/rocket_smooth.png";
import atendente from "~/assets/icones/atendente.png";

import { Container } from "./styles";
import UserDevice from "~/componentes/UserDevice";
import { Scrollbars } from "react-custom-scrollbars";

export default function Perguntas() {
  const userDevice = UserDevice();
  const { desktop, rotatedTablet, mobile } = userDevice;
  return (
    <Container desktop={desktop} rotatedTablet={rotatedTablet} mobile={mobile}>
      {(userDevice.desktop || userDevice.rotatedTablet) && <img className="foguete" src={foguete} alt="Foguete" />}
      <div className="content">
        <h1>Perguntas Frequentes</h1>
        <div className="contentBox">
          <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={500}>
            <div className="scroll">
              <div className="baloes perguntaDiv">
                <p className="pergunta">O app tem pra Android e IOS?</p>
              </div>
              <div className="baloes respostaDiv">
                {mobile && (
                  <div className="atendenteDiv">
                    <img src={atendente} alt="Atendente" />
                  </div>
                )}
                <p className="resposta">No momento somente para android, a pretenção de liberação do app para o IOS é FEV/2021</p>
                {!mobile && (
                  <div className="atendenteDiv">
                    <img src={atendente} alt="Atendente" />
                  </div>
                )}
              </div>
              <div className="baloes perguntaDiv">
                <p className="pergunta">A plataforma só pode ser usada para escolas(instituição de ensino)?</p>
              </div>
              <div className="baloes respostaDiv">
                {mobile && (
                  <div className="atendenteDiv">
                    <img src={atendente} alt="Atendente" />
                  </div>
                )}
                <p className="resposta">
                  Não, a plataforma é adaptavél a sua necessidade, pode ser usada para cursos livres, professores particulares, etc a plataforma veio
                  para apoiar o SEU metodo de ensinar e não substituir.
                </p>
                {!mobile && (
                  <div className="atendenteDiv">
                    <img src={atendente} alt="Atendente" />
                  </div>
                )}
              </div>
              <div className="baloes perguntaDiv">
                <p className="pergunta">Como funciona a matrícula online?</p>
              </div>
              <div className="baloes respostaDiv">
                {mobile && (
                  <div className="atendenteDiv">
                    <img src={atendente} alt="Atendente" />
                  </div>
                )}
                <p className="resposta">
                  A escola vai informar o que precisa de documentação para fazer a matricula, e o pai/responsável envia os documentos de forma
                  digital.
                </p>
                {!mobile && (
                  <div className="atendenteDiv">
                    <img src={atendente} alt="Atendente" />
                  </div>
                )}
              </div>
              <div className="baloes perguntaDiv">
                <p className="pergunta">O que siginifica APP GAMIFICADO?</p>
              </div>
              <div className="baloes respostaDiv">
                {mobile && (
                  <div className="atendenteDiv">
                    <img src={atendente} alt="Atendente" />
                  </div>
                )}
                <p className="resposta">
                  É a plataforma do aluno é um aplicativo que terá jogos entre alunos/máquina onde terá tempo para resposta, no aplicativo terá opção
                  de Loja, compra de Avatares, rancking e trófeus, a nossa moeda educabits são as conquistas para aluno aprender brincando.
                </p>
                {!mobile && (
                  <div className="atendenteDiv">
                    <img src={atendente} alt="Atendente" />
                  </div>
                )}
              </div>
            </div>
          </Scrollbars>
        </div>
      </div>
    </Container>
  );
}
