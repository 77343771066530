import React, { useState } from "react";

import { FiltroContainer } from "./styles";
import CustomListSearch from "./CustomListSearch";
import CustomListFilter from "./CustomListFilter";
import { useEffect } from "react";
import customListStore, { customListFilters } from "../../customListStore";
import CustomListCreateButton from "./CustomListCreateButton";
import UserDevice from "~/componentes/UserDevice";

function CustomListTopComponent() {
  const { state, dispatch } = React.useContext(customListStore);
  const { filtersConfig, childrens } = state || {};
  const { search, filter } = filtersConfig || {};
  const [searchValue, setSearchValue] = useState({});
  const [filtersValue, setFiltersValue] = useState({});
  const { desktop, rotatedTablet } = UserDevice();

  useEffect(() => {
    dispatch(customListFilters({ ...filtersValue, ...searchValue }));
  }, [dispatch, filtersValue, searchValue]);

  return (
    ((Array.isArray(search) && search.length > 0) ||
      (Array.isArray(filter) && filter.length > 0)) && (
      <FiltroContainer mobile={!desktop && !rotatedTablet}>
        {Array.isArray(search) && search.length > 0 && (
          <CustomListSearch setParentState={setSearchValue} inputs={search} />
        )}
        <div className="CustomTable_TableFiltro_buttons_div">
          {Array.isArray(filter) && filter.length > 0 && (
            <CustomListFilter
              filters={filter}
              filtersValue={filtersValue}
              setParentState={setFiltersValue}
            />
          )}
          {childrens?.tableCreateForm && (
            <CustomListCreateButton className="CustomTable_TableFiltro_createButton" />
          )}
        </div>
      </FiltroContainer>
    )
  );
}

export default CustomListTopComponent;
