import React, { useEffect, useState } from "react";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import Label from "~/componentes/CustomForm/Label";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";

// import { Container } from './styles';

function InputFornecedor({ fornecedorSelected, setFornecedorSelected }) {

  return (
    <SistemaInput
      name="fornecedorId"
      defaultValue={fornecedorSelected}
      setter={(fornecedor) => setFornecedorSelected(fornecedor || "")}
      required
    />
  );
}

export default InputFornecedor;
