import React from "react";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTable, SLTableCell } from "~/pages/Sistema/componentes/SistemaList";
import { useState } from "react";

function TableComponent({ setTabFilter }) {
  const [tab, setTab] = useState(0);

  return (
    <>
      <TabsWrapper tab={tab} tabHeight={2.4}>
        <Tab
          materialIcon="check_circle_outline"
          onClick={() => {
            setTab(0);
            setTabFilter({
              deletedAtDate: (data) => !data,
            });
          }}
        >
          Ativo
        </Tab>
        <Tab
          materialIcon="error_outline"
          onClick={() => {
            setTab(1);
            setTabFilter({
              deletedAtDate: (data) => !!data,
            });
          }}
        >
          Desativo
        </Tab>
      </TabsWrapper>

      <SistemaComponenteInterno>
        <SLTable noDelete={tab === 1} noRestore={tab === 0} noEdit={tab === 1}>
          <SLTableCell name="usuario.nome" inputfilter>
            Nome
          </SLTableCell>
          <SLTableCell name="usuario.email" inputfilter>
            Email
          </SLTableCell>
        </SLTable>
      </SistemaComponenteInterno>
    </>
  );
}

export default TableComponent;
