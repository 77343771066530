import React, { useState } from "react";
import { CLShowComponent } from "~/pages/Sistema/componentes/CustomList";
import Label from "~/componentes/CustomForm/Label";

function ShowModal({ modulos }) {
  const [cargo, setCargo] = useState();

  return (
    <CLShowComponent
      title="Visualizar Cargo"
      contentMount={setCargo}
      contentUnmount={() => setCargo()}
    >
      <Label title="TÍTULO:" text={cargo?.nome} />
    </CLShowComponent>
  );
}

export default ShowModal;
