import React from "react";

import { ThisButtonStyle } from "./styles";
import { useMemo } from "react";
import { IsObject } from "~/utils/CheckDataType";

const icons = {
  sistemaShow: "vision",
  sistemaEdit: "create",
  delete: "delete",
  close: "close",
};

/**
 * @param {Object} props
 * @param {Boolean} props.defaultHover Ativa uma animação padrão no hover.
 * @param {any} props.as Altera o elemento do botão.
 * @param {icons} props.materialIcon Se passado uma string, usará para buscar um ícone no material-icons.
 */
function IconButton({ materialIcon, defaultHover, children, className, ...rest }) {
  const cIcon = useMemo(() => {
    if (materialIcon) {
      if (typeof materialIcon === "string") {
        return materialIcon;
      } else if (IsObject(materialIcon)) {
        const thisKey = Object.keys(materialIcon)[0];

        if (icons?.[thisKey]) {
          return thisKey;
        }
      }
    }
  }, [materialIcon]);

  return (
    <ThisButtonStyle
      type="button"
      defaulthover={defaultHover ? "true" : undefined}
      {...{ ...rest }}
      className={`material-icons ${className}`}
    >
      {cIcon}
    </ThisButtonStyle>
  );
}

export default IconButton;
