import React, { useState } from "react";
import api from "~/services/api";
import Label from "~/componentes/CustomForm/Label";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";

import { Container } from "./styles";
import AcessosList from "~/componentes/AcessosList";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import FindOrCreateUser from "~/componentes/FindOrCreateUser";
import CustomScrollBar from "~/componentes/CustomScrollBar";

function CreateContent({ cargos, modulos }) {
  const [disabledData, setDisabledData] = useState([]);
  const [usuario, setUsuario] = useState();

  function handleSubmit(data, options) {
    const { closeReload } = options || {};
    ShowConfirmacao(
      () =>
        api
          .post("filial/funcionarioFilial", {
            ...data,
            usuario,
          })
          .then((r) => {
            typeof closeReload === "function" && closeReload();
            return r;
          }),
      { successModal: "Funcionário cadastrado com sucesso." }
    );
  }

  return (
    <Container defaultStyle onSubmit={handleSubmit}>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <FindOrCreateUser setter={setUsuario} />
        <Label title="CARGO">
          <SistemaSelect
            disabled={!usuario}
            setList={(data) => setDisabledData(data?.acessos || [])}
            list={{ list: cargos, value: "cod", label: "nome" }}
            name="cargoFilialCod"
            firstOption
          />
        </Label>
        <AcessosList
          reloadDependency={disabledData}
          defaultDisabledData={disabledData}
          modulos={modulos}
        />
      </CustomScrollBar>
    </Container>
  );
}

export default CreateContent;
