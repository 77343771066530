import CustomListCheckDataType from "../utils/CustomListCheckDataType";

const initialState = {
  initialProps: {},
  filteredData: [],
  rowData: {},
  primaryKey: "",
  rowClick: {},
  editedData: {},
  requests: {},
  children: [],
  filtros: {},
};

export default function customListReducer(state = initialState, { type, payload }) {
  switch (type) {
    case "customListSetData":
      return {
        ...state,
        data: payload.data,
        requests: payload.requests || state.requests,
      };
    case "customListSetProps": {
      return { ...state, ...payload.props };
    }
    case "customListDeleteData": {
      const pk = state.primaryKey;
      const thisData = pk && payload.data && payload.data[pk];
      if (thisData) {
        let newState = state.data.filter((e) => e[pk] !== thisData);
        return { ...state, data: newState };
      }
      return state;
    }
    case "customListEditData":
      let { rowData } = payload || state || {};
      let { primaryKey, data, sortOnEdit } = state || {};

      let newData = [...data];
      let newRowData = { ...rowData };

      let newRowDataIndex = newData.findIndex(
        (e) => e[primaryKey] === rowData[primaryKey]
      );

      if (newRowDataIndex >= 0) {
        newData[newRowDataIndex] = newRowData;
      }

      if (CustomListCheckDataType(sortOnEdit, "String")) {
        newData = newData.sort((a, b) => {
          let x = a[sortOnEdit];
          let y = b[sortOnEdit];
          if (CustomListCheckDataType(a[sortOnEdit], "String")) {
            x = x.toLowerCase();
            y = y.toLowerCase();
          }
          return x < y ? -1 : x > y ? 1 : 0;
        });
      }

      return {
        ...state,
        data: [...newData],
        rowData: { ...newRowData },
      };
    case "customListSortData": {
      const { sortOnEdit } = state || {};

      if (CustomListCheckDataType(sortOnEdit, "String")) {
        let newState = state.data.sort((a, b) => {
          let x = a[sortOnEdit];
          let y = b[sortOnEdit];
          if (CustomListCheckDataType(a[sortOnEdit], "String")) {
            x = x.toLowerCase();
            y = y.toLowerCase();
          }
          return x < y ? -1 : x > y ? 1 : 0;
        });

        return { ...state, data: newState };
      }
      return state;
    }
    case "customListToggleModal":
      return { ...state, modal: payload.modal };
    case "customListUpdateChildrens":
      return { ...state, childrens: payload.childrens || state.childrens };
    case "customListUpdateTableData":
      return { ...state, filteredData: payload.filteredData };
    case "customListUpdateRowData":
      return { ...state, rowData: payload.rowData };
    case "customListFilters":
      return { ...state, filters: payload.filters };
    case "customListFilterConfig":
      return { ...state, filtersConfig: payload.filtersConfig };
    case "customListDataPage":
      return { ...state, page: payload.page || 0 };
    case "customListModalChildrenProps":
      return { ...state, modalChildrenProps: payload.modalChildrenProps || {} };
    default:
      return state;
  }
}

//     case "tableUpdatEditedData": {
//       const { primaryKey } = state;
//       if (CustomListCheckDataType(payload.editedData, "Object")) {
//         let thisData = payload.editedData;

//         let editedData = {
//           ...state.editedData,
//           ...thisData,
//         };

//         if (payload.deleteonnull) {
//           let thisKey = Object.keys(thisData)[0];
//           if (
//             thisKey &&
//             (thisData[thisKey] === null ||
//               thisData[thisKey] === undefined ||
//               thisData[thisKey] === "")
//           ) {
//             delete editedData[thisKey];
//           }
//         }

//         return {
//           ...state,
//           editedData: { ...editedData, [primaryKey]: state.rowData[primaryKey] },
//         };
//       }
//       return { ...state };
//     }
