import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { signOut } from "~/store/modules/auth/actions";
import { BiLogOut, BiLogIn } from "react-icons/bi";

import Logo from "~/assets/logos/eduque_me_negativo.png";
import { Button } from "~/componentes/CustomForm";
import {
  Container,
  LogoButton,
  DesktopMenuButtonDiv,
  ThisColoredButton,
  Perfil,
  PerfilModal,
} from "./styles";

function DesktopMenu() {
  const dispatch = useDispatch();
  const {
    auth: { token },
    user: { perfil },
  } = useSelector((state) => state) || {};
  const [showModal, setShowModal] = React.useState(false);

  return (
    <Container>
      <LogoButton clean link="/">
        <img className="eduque-me" src={Logo} alt="Eduque-me" />
      </LogoButton>

      <DesktopMenuButtonDiv>
        <ThisColoredButton link="/matricula" redLayout>
          Matrícula
        </ThisColoredButton>
        <ThisColoredButton link="/contato" blueLayout>
          Contato
        </ThisColoredButton>
        {token && perfil?.nome ? (
          <Perfil>
            <button
              onClick={() => setShowModal(!showModal)}
              className="letterButton"
            >
              {perfil?.nome[0]}
            </button>
            {showModal && (
              <PerfilModal outClose outClick={() => setShowModal(false)}>
                <Button link="/sistema">
                  <BiLogIn className="PerfilModal_Button_icon" />
                  Entrar no sistema
                </Button>
                <Button onClick={() => dispatch(signOut())}>
                  <BiLogOut className="PerfilModal_Button_icon" />
                  Sair da conta
                </Button>
              </PerfilModal>
            )}
          </Perfil>
        ) : (
          <ThisColoredButton link="/entrar" yellowLayout>
            Entrar
          </ThisColoredButton>
        )}
      </DesktopMenuButtonDiv>
    </Container>
  );
}

export default DesktopMenu;
