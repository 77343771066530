import React from "react";

import BarChart from "./BarChart";
import CardList from "./CardList";
import { Container } from "./styles";

function FirstSection({ data, selectedDTM, setSelectedDTM }) {
  return (
    <Container>
      <BarChart
        disciplinasTurmas={data?.disciplinasTurmas}
        setSelectedDTM={setSelectedDTM}
      />
      <CardList stats={selectedDTM || data} />
    </Container>
  );
}

export default FirstSection;
