import React, { useEffect, useState } from "react";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import Label from "~/componentes/CustomForm/Label";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";

// import { Container } from './styles';

function InputCentroCusto({ centroCustoSelected, setCentroCustoSelected }) {

  return (
    <SistemaInput
      name="centrocusto"
      defaultValue={centroCustoSelected?.codigo || ""}
      setter={(cc) => setCentroCustoSelected({...centroCustoSelected, codigo: cc || ""})}
      required
    />
  );
}

export default InputCentroCusto;
