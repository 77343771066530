import React from "react";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import Label from "~/componentes/CustomForm/Label";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import SistemaModalFooter from "~/componentes/Modal/Styleds/Footers/SistemaModalFooter";
import Pagination from "~/componentes/Pagination";
import api from "~/services/api";
import { Materialize } from "~/styles/materializeGrid";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import QuestoesPagination from "./QuestoesPagination";
import RespostaQuestao from "./RespostaQuestao";

import { Container } from "./styles";

function EntregueModal({ avaliacaoAluno, reloadData, mountedData, showOnly, outClick }) {
  const { usuario } = avaliacaoAluno?.alunoDisciplinaTurma?.alunoTurma?.aluno || {};
  const { avaliacao, disciplinaTurma } = mountedData || {};
  const { disciplinaFilial } = disciplinaTurma || {};
  const { questoes } = avaliacao || {};

  const [questao, setQuestao] = React.useState();
  const [nextPage, setNextPage] = React.useState(() => () => {});
  const [respostaQuestoes, setRespostaQuestoes] = React.useState({});
  const [animationClose, setAnimationClose] = React.useState({});

  React.useEffect(() => {
    if (Array.isArray(avaliacaoAluno?.respostaQuestoes)) {
      let defaultPostData = {};
      avaliacaoAluno.respostaQuestoes.forEach((resp) => {
        let sugestaoPonto = 0;
        let sugestaoStatus = "";

        const questaoReal = questoes?.find(
          (q) => q?.cod === resp?.avaliacaoQuestao?.questaoCod
        );

        if (questaoReal) {
          if (questaoReal?.questaoTipo?.chave === "MUL") {
            let pontMax = resp?.avaliacaoQuestao?.pontuacao;
            let corretas = 0;
            let acertos = 0;
            let erros = 0;

            if (Array.isArray(questaoReal?.itens)) {
              questaoReal.itens.forEach((item) => {
                const thisResp = resp?.respostasItens?.find(
                  (ri) => ri?.questaoItemCod === item.cod
                );

                if (item?.gabarito) {
                  corretas++;
                  if (thisResp) {
                    acertos++;
                  }
                  //  else {
                  //   erros++;
                  // }
                } else if (thisResp) {
                  erros++;
                }
              });
            }

            let itemPonto = pontMax / corretas;
            const acertosPontos = itemPonto * acertos;
            const errosPontos = itemPonto * erros;
            sugestaoPonto = acertosPontos - errosPontos;

            if (sugestaoPonto < 0) {
              sugestaoPonto = 0;
            } else if (sugestaoPonto > pontMax) {
              sugestaoPonto = pontMax;
            }

            if (sugestaoPonto === 0) {
              sugestaoStatus = 0;
            } else if (sugestaoPonto === pontMax) {
              sugestaoStatus = 2;
            } else {
              sugestaoStatus = 1;
            }
          }
        }
        if (resp?.cod) {
          defaultPostData[resp.cod] = {
            cod: resp.cod,
            questaoCod: questaoReal?.cod,
            correcao: resp?.correcao || "",
            pontuacao: parseFloat(resp?.pontuacao) > 0 ? resp?.pontuacao : sugestaoPonto,
            statusResposta:
              parseInt(resp?.statusResposta) >= 0 ? resp?.statusResposta : sugestaoStatus,
          };
        }
      });

      setRespostaQuestoes(defaultPostData);
    }
  }, [avaliacaoAluno, questoes]);

  const respostaQuestao = React.useMemo(() => {
    return avaliacaoAluno?.respostaQuestoes?.find((e) => {
      return e?.avaliacaoQuestao?.questaoCod === questao?.cod;
    });
  }, [avaliacaoAluno, questao]);

  async function handleSubmit() {
    let corrigidas = [];
    let naoCorrigidas = [];

    try {
      Object.values(respostaQuestoes).forEach((e) => {
        if (parseInt(e.statusResposta) >= 0) {
          corrigidas.push(e);
        } else {
          naoCorrigidas.push(e);
        }
      });
    } catch {}

    let message = "Tem certeza que deseja finalizar essa avaliação?";

    if (naoCorrigidas.length > 0) {
      message =
        "Há questões não corrigidas. Deseja finalizar apenas as que foram atualizadas?";
    }

    ShowConfirmacao(
      () =>
        api
          .put("professor/avaliacaoDisponibilizada", {
            cod: avaliacaoAluno?.cod,
            respostaQuestoes: corrigidas,
          })
          .then((r) => {
            if (r?.data?.cod) {
              avaliacaoAluno.corrigido = r.data.corrigido;

              if (Array.isArray(r?.data?.respostaQuestoes)) {
                r.data.respostaQuestoes.forEach((resp) => {
                  const thisResp = avaliacaoAluno.respostaQuestoes.find(
                    (e) => e?.cod === resp?.cod
                  );

                  if (thisResp?.cod) {
                    thisResp.correcao = resp.correcao;
                    thisResp.pontuacao = resp.pontuacao;
                    thisResp.statusResposta = resp.statusResposta;
                  }
                });
              }
            }
            typeof reloadData === "function" && reloadData();
            typeof animationClose === "function" && animationClose();

            return r;
          }),
      {
        message,
        successModal: false,
      }
    );
  }

  function handleChange(data) {
    setRespostaQuestoes((prev) => ({
      ...prev,
      [respostaQuestao?.cod]: {
        ...prev?.[respostaQuestao?.cod],
        ...data,
      },
    }));
  }

  return (
    <Container
      outClickAnimation={setAnimationClose}
      // footerButtons={{ submitButton: { onClick: handleSubmit } }}
      title={usuario?.nome || "Avaliacao"}
      {...{ outClick }}
    >
      <CustomScrollBar fixScrollMargin autoHeightMax="70vh">
        <Pagination
          size={1}
          data={questoes}
          getInfo={({ pageData }) => setQuestao(pageData?.[0])}
        >
          {({ page, maxPage, setPage, setHoldingButton }) => (
            <QuestoesPagination
              {...{
                page,
                maxPage,
                setPage,
                setNextPage,
                questoes,
                respostaQuestoes,
                setHoldingButton,
              }}
            />
          )}
        </Pagination>
        <Materialize>
          <div className="row">
            <Label
              className="col s12 m12 l4"
              title="Título:"
              text={questao?.nome || ""}
            />
            <Label
              className="col s12 m12 l4"
              title="Tipo de Questão:"
              text={questao?.questaoTipo?.nome || ""}
            />
            <Label
              className="col s12 m12 l4"
              title="Disciplina:"
              text={disciplinaFilial?.nome || ""}
            />
            <Label className="col s12 m12 l12" title="Enunciado:">
              <SistemaTextArea readOnly value={questao?.enunciado || ""} />
            </Label>
            {questao?.gabarito && (
              <Label className="col s12 m12 l12" title="Gabarito:">
                <SistemaTextArea readOnly value={questao?.gabarito || ""} />
              </Label>
            )}
            <RespostaQuestao
              {...{ handleChange, questao, respostaQuestoes, respostaQuestao }}
            />
          </div>
        </Materialize>
      </CustomScrollBar>
      <SistemaModalFooter>
        <ColoredButton type="button" yellowLayout onClick={() => nextPage()}>
          Próxima
        </ColoredButton>
        <ColoredButton type="button" redLayout onClick={() => animationClose()}>
          Voltar
        </ColoredButton>
        <ColoredButton type="button" greenLayout onClick={handleSubmit}>
          Corrigir
        </ColoredButton>
      </SistemaModalFooter>
    </Container>
  );
}

export default EntregueModal;
