import React from "react";

import { Container } from "./styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import handleDate from "~/utils/handleDate";

function RankingAlunos({ stats }) {
  return (
    <Container className="ranking">
      <div className="ranking-header">
        <h5>Atividade</h5>
      </div>
      <CustomScrollBar autoHeightMax="24em">
        <ul>
          {stats?.atividades?.map((atividade, index) => (
            <li key={atividade?.cod || index}>
              <div className="td-header">
                <h4>{atividade?.avaliacao?.nome}</h4>
              </div>
              <div className="td-body">
                <div className="title-disciplina">
                  <h6>{atividade?.disciplinaTurma?.disciplinaFilial?.nome}</h6>
                </div>
                <div className="title-data">
                  <h6>{handleDate(atividade?.dataFim).dmy}</h6>
                  <span className="material-icons">access_time</span>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </CustomScrollBar>
    </Container>
  );
}

export default RankingAlunos;
