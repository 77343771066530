import React, { memo, useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import formHandleOnChange from "~/componentes/CustomForm/utils/formHandleOnChange";
import formStore from "../formStore";
import { useSelector } from "react-redux";
import formCheckDisabled from "../utils/formCheckDisabled";
import FindKeyValue from "~/utils/FindKeyValue";
import { useMemo } from "react";
import FilterListStore, {
  FilterListStoreHandleSelect,
} from "~/componentes/FilterList/FilterListStore";

/**
 * @param {Object} props Aceita todas as props de um componente React.
 * @param {Boolean} props.filter Caso seja uma string, substituirá o name na filtragem. Usado caso esteja envolto do componente FilterList. Se true, será utilizado o name e o value para atualizar a lista do FilterList.
 * @param {Boolean} props.notForm Padrão false. Caso esteja envolto pelo componente Form, o padrão é sempre informar o value caso possua um name. Se passado essa prop, não será passado o valor para o form, independente de ter o name.
 * @param {string} props.defaultValue
 * @param {string} props.value
 * @param {Object} props.list Objeto contendo as configurações para listar as options.
 * @param {Array} props.list.list Recebe um array e lista as options.
 * @param {string} props.list.value Informe o nome da chave que servirá como o value da option.
 * @param {string} props.list.label Informe o nome da chave que servirá como o label da option.
 *  Tentará usar o value caso não seja informado um label.
 * @param {string} props.name Caso informado um name, o value será enviado para o form como um objeto,
 *  com o name como chave. O name utiliza a notação por pontos, logo, caso utilize por exemplo "pessoa.profissao",
 *  será enviado para o form {pessoa: {profissao: "value"}}.
 * @param {Function} props.onChange Por padrão, já possui um onChange que altera o estado da edição,
 *  Caso seja fornecido outro, ambos serão utilizados.
 * @param {Object} props.setList É usado junto do onChange, apenas caso seja passado a lista através do atributo list,
 *  porém, já recebe o valor de forma já tratada. Pode ser fornecido um objeto contendo o setter e o name
 *  para adicionar um objeto ao estado do setter.
 * @param {Function} props.setList.setter Recebe um setState, que receberá o valor do elemento.
 *  Diferente do setter comum, esse é voltado para listas no qual pode ser utilizado para gerar DropsDowns
 *  com dependência.
 * @param {string} props.setList.name Transforma o estado em um objeto contendo o name.
 * @param {Object} props.setter É usado junto do onChange, porém, já recebe o valor de forma já tratada.
 *  Pode ser fornecido um objeto contendo o setter e o name para adicionar um objeto ao estado do setter.
 *  Ao informar um name, segue o padrão de um form, porém utilizando um setter.
 * @param {Function} props.setter.setter Recebe um setState, que receberá o valor do elemento.
 * @param {string} props.setter.name Transforma o estado em um objeto contendo o name, mesmo padrão do name para Form.
 * @param {Function} props.allowNull Se true, usará dados vazios.
 * @param {Function} props.noLoading Se true, manterá o elemento ativo mesmo que um loading esteja ocorrendo.
 * @param {string} props.firstOption Insere uma option vazia.
 * @param {Function} props.changeValue Recebe como argumento o value, e passará a usar como novo
 *  valor o retorno da função, muito usado para definir um tipo de dado.
 * @param {Boolean} props.isBoolean converterá o value em um Boolean antes de enviar para o form.
 * @param {Boolean} props.isNumber converterá o value em um Number antes de enviar para o form.
 * @param {Boolean} props.isString converterá o value em um String antes de enviar para o form.
 * @param {Boolean} props.isDate converterá o value em um Date antes de enviar para o form.
 */
function Select(props) {
  const {
    list: listProps = false,
    setList,
    setter,
    filter,
    notForm,
    children,
    defaultValue,
    value,
    assistedValue,
    changeValue,
    isBoolean,
    isDate,
    isNumber,
    isString,
    name,
    style,
    firstOption,
    defaultColor = true,
    noLoading,
    allowNull,
    ...rest
  } = props || {};
  const { dispatch: dispatchForm } = React.useContext(formStore);
  const { dispatch: dispatchFilter } = React.useContext(FilterListStore);

  const disableButtons = useSelector((state) => state.disableButtons);
  const [selectedOption, setSelectedOption] = useState();
  const [loadedDefault, setLoadedDefault] = useState(false);
  const [loadedValue, setLoadedValue] = useState(false);
  const [loadedList, setLoadedList] = useState(false);
  const [loadedAssistedValue, setLoadedAssistedValue] = useState(false);

  const list = useMemo(() => {
    if (listProps?.list) {
      return listProps;
    } else if (Array.isArray(listProps)) {
      return { list: listProps };
    }
  }, [listProps]);

  const handleDataChange = useCallback(
    (thisValue) => {
      if (filter) {
        dispatchFilter &&
          dispatchFilter(
            FilterListStoreHandleSelect({
              key: typeof filter === "string" ? filter : name,
              value: thisValue,
            })
          );
      }
      // if (!notForm) {
      formHandleOnChange(thisValue, props, dispatchForm);
      // }
    },
    [dispatchFilter, dispatchForm, filter, name, props]
  );

  useEffect(() => {
    if (!loadedList) {
      if (list?.list?.length > 0) {
        setLoadedList(true);
      } else if (!listProps) {
        setLoadedList(true);
      }
    }
  }, [list, listProps, loadedList]);

  useEffect(() => {
    if (!loadedDefault && loadedList && defaultValue) {
      setLoadedDefault(true);
      handleDataChange(defaultValue);
      setSelectedOption(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, loadedDefault, loadedList]);

  useEffect(() => {
    if (loadedList) {
      if (value && !loadedValue) {
        setLoadedValue(true);
      }
      if (loadedValue) {
        handleDataChange(value);
        setSelectedOption(value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedList, loadedValue, value]);

  useEffect(() => {
    if (loadedList) {
      if (assistedValue && !loadedAssistedValue) {
        setLoadedAssistedValue(true);
      }
      if (loadedAssistedValue) {
        handleDataChange(assistedValue);
        setSelectedOption(assistedValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedList, loadedAssistedValue, assistedValue]);

  return (
    <select
      {...rest}
      name={name}
      value={selectedOption}
      disabled={formCheckDisabled(props, disableButtons)}
      style={{
        ...style,
        ...(defaultColor &&
          (!selectedOption || selectedOption === "") && { color: "#837879" }),
      }}
      onChange={(e) => {
        if (!loadedValue) {
          const eventVal = e.target.value;
          setSelectedOption(eventVal);
          handleDataChange(eventVal);
        }
        props?.onChange && props.onChange(e);
      }}
    >
      {firstOption && (
        <option style={{ color: "#bbb" }} value="">
          {typeof firstOption === "string" ? firstOption : "Selecione..."}
        </option>
      )}
      {children}
      {list?.list?.map?.((e, i) => {
        const { value: listValue, label: listLabel } = list || {};
        const thisListValue = (listValue && FindKeyValue(listValue, e)) || e;
        const thisListLabel = (listLabel && FindKeyValue(listLabel, e)) || thisListValue;

        return (
          <option key={i} value={thisListValue}>
            {thisListLabel}
          </option>
        );
      })}
    </select>
  );
}

export default memo(Select);
