import React, { useEffect } from "react";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";

import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import { useState } from "react";

import { Container, FlexDiv, SubTitle } from "./styles";
import { SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import { Materialize } from "~/styles/materializeGrid";
import api from "~/services/api";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import setRegex, { setMoney } from "~/utils/setRegex";
import FindOrCreateUser from "~/componentes/FindOrCreateUser";
import UserDevice from "~/componentes/UserDevice";

function CreateEditContent({ mountedData }) {

  function handleSubmit(data, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let response;

        if (mountedData) {
          response = await api.put("/filial/empresa/changetoken", {
            ...data,
            cod: mountedData.codEmp,
          });
        }

        if (response?.status === 200) {
          closeReload && closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao ${
            mountedData ? "atualizar" : "criar"
          } esse token, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja ${
          mountedData ? "atualizar" : "criar"
        } esse Token?`,
        successModal: `Token ${
          mountedData ? "atualizado" : "cadastrado"
        } com sucesso!`,
      }
    );
  }

  return (
    <Container onSubmit={handleSubmit}>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          <div className="row">
            <SubTitle>Dados do Token</SubTitle>
            <Label title="TIPO:" className="col s12 m12 l3">
              <SistemaInput
                name="tipo"
                disabled={true}
                defaultValue={mountedData?.tipo}
              />
            </Label>
            <Label title="TOKEN:" className="col s12 m12 l5">
              <SistemaInput
                name="token"
                defaultValue={mountedData?.token}
              />
            </Label>
          </div>
        </Materialize>
      </CustomScrollBar>
      <SLModalFooter />
    </Container>
  );
}

export default CreateEditContent;
