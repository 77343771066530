import React, { useEffect, useState } from "react";

import api from "~/services/api";
import ShowContent from "./ShowContent";

import { Container, ThisCard, ThisCardBody, ThisCardTitle } from "./styles";
import TextContent from "~/componentes/TextContent";
import ShowLoading from "~/utils/ShowLoading";
import Pagination from "~/componentes/Pagination";

import PadraoAlunosFiltro from "../../../../componentes/PadraoAlunosFiltro";

function ComunicadoEscolar() {
  const [showModal, setShowModal] = useState(false);
  const [comunicados, setComunicados] = useState();
  const [pageData, setPageData] = useState();
  const [usuario, setUsuario] = useState();
  const [aluno, setAluno] = useState();
  const [alunoTurma, setAlunoTurma] = useState();

  async function handleUsuarioChange(data) {
    let thisResult;
    await ShowLoading(
      () =>
        api
          .get("padrao/AcompanhamentoEscolar/comunicadoEscolar/comunicados", {
            params: { usuarioCod: data?.cod },
          })
          .then((r) => (thisResult = r?.data)),
      { successModal: false, errorModal: false }
    );

    return thisResult;
  }

  useEffect(() => {
    setComunicados(() => {
      let thisComunicados = {};

      function addComunicados(thisData) {
        if (Array.isArray(thisData?.comunicados)) {
          thisData.comunicados.forEach((c) => {
            if (c?.cod) {
              thisComunicados[c.cod] = c;
            }
          });
        }
      }

      if (alunoTurma) {
        addComunicados(alunoTurma);
      } else if (aluno) {
        if (Array.isArray(aluno?.alunoTurmas)) {
          aluno.alunoTurmas.forEach(addComunicados);
        }
      } else if (usuario) {
        if (Array.isArray(usuario?.alunos)) {
          usuario.alunos.forEach((a) => {
            if (Array.isArray(a?.alunoTurmas)) {
              a.alunoTurmas.forEach(addComunicados);
            }
          });
        }
      }

      let thisResult = Object.values(thisComunicados);

      return thisResult.length > 0 ? thisResult : undefined;
    });
  }, [aluno, alunoTurma, usuario]);

  function handleDate(thisData) {
    const thisDate = thisData?.createdAtDate && new Date(thisData.createdAtDate);

    return thisDate ? `${thisDate.toLocaleDateString()} às ${thisData.createdAtHour}` : "";
  }

  return (
    <>
      {showModal && <ShowContent mountedData={showModal} outClick={() => setShowModal(false)} />}
      <PadraoAlunosFiltro
        title="Filtrar Comunicado"
        onChangeUsuario={handleUsuarioChange}
        usuarioSetter={setUsuario}
        alunoSetter={setAluno}
        alunoTurmaSetter={setAlunoTurma}
      />

      <Container>
        {Array.isArray(comunicados) && comunicados.length > 0 ? (
          <>
            {pageData?.map((thisData, i) => (
              <ThisCard onClick={() => setShowModal(thisData)} data={thisData} key={thisData?.cod || i}>
                <ThisCardTitle>
                  <h3>
                    #{thisData?.cod}
                    {thisData?.arquivos?.length > 0 && <i className="material-icons">attachment</i>}
                  </h3>
                  <span>{handleDate(thisData)}</span>
                </ThisCardTitle>
                <ThisCardBody>
                  <div className="ThisCardBody_title_tur_disc_div">
                    <TextContent lines={1} setType="h2">
                      {thisData?.titulo}
                    </TextContent>
                    <TextContent lines={1} setType="p" className="ThisCardbody_turma_disciplina">
                      {thisData?.usuarioNome} / {thisData?.usuarioCargo}
                    </TextContent>
                  </div>
                  <TextContent lines={3} className="ThisCardBody_TextContent_pre">
                    {thisData?.mensagem}
                  </TextContent>
                </ThisCardBody>
              </ThisCard>
            ))}
            <Pagination data={comunicados} size={6} getInfo={({ pageData }) => setPageData(pageData)} />
          </>
        ) : (
          <h4 style={{ textAlign: "center" }}>Não há comunicados disponíveis.</h4>
        )}
      </Container>
    </>
  );
}

export default ComunicadoEscolar;
