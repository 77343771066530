/* eslint-disable eqeqeq */
import React, { useEffect } from "react";

import { useState } from "react";
import Label from "~/componentes/CustomForm/Label";
import { SistemaInput, SistemaSenhaInput } from "~/componentes/CustomForm/Input/styles";
import ReactCustomScrollbars from "react-custom-scrollbars";
import { Materialize } from "~/styles/materializeGrid";
import { useSelector } from "react-redux";

import { Content, TriggerButton, PerfilAvata, ThisForm } from "./styles";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import estados from "~/config/estados";
import setRegex from "~/utils/setRegex";
import ThisConfirmModal from "./ThisConfirmModal";

export default function Perfil({ desktop }) {
  const { perfil } = useSelector((state) => state.user) || {};

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [updateSenha, setUpdateSenha] = useState("false");
  const [showModal, setShowModal] = useState(false);
  const [loadedPerfil, setLoadedPerfil] = useState(false);
  const [estado, setEstado] = useState();
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (!loadedPerfil && perfil?.cod) {
      setLoadedPerfil(true);
      setFormData(perfil);
    }
  }, [loadedPerfil, perfil]);

  function handleOnChange(e) {
    const { value, name } = e.currentTarget;

    setFormData((prev) => ({ ...prev, [name]: value }));
  }

  return (
    <>
      <TriggerButton
        desktop={desktop ? "true" : undefined}
        onClick={() => {
          setShowModal(!showModal);
        }}
        className="material-icons"
      >
        create
      </TriggerButton>
      {showModal && (
        <Content
          footerButtons={{
            submitButton: {
              type: "button",
              onClick: (closeModal) => setShowConfirmation(() => closeModal),
            },
          }}
          title="Editar Perfil"
          outClick={() => setShowModal(false)}
          className="editarPerfilModal toggleModalClasse"
        >
          {showConfirmation && (
            <ThisConfirmModal
              closeParentModal={showConfirmation}
              updateSenha={updateSenha}
              formData={formData}
              outClick={() => setShowConfirmation(false)}
            />
          )}
          <div className="Perfil_SistemaFixedModal_body">
            <PerfilAvata>
              <div className="editarPerfilAvatar">E</div>
              <div className="editarPerfilAvatarMudar">Mudar</div>
            </PerfilAvata>
            <ReactCustomScrollbars style={{ height: "18em" }}>
              <ThisForm>
                <Materialize>
                  <div className="row">
                    <Label title="Nome" className="col s12 m12 l8">
                      <SistemaInput
                        defaultValue={formData?.nome}
                        name="nome"
                        defaultHtml
                        onChange={handleOnChange}
                      />
                    </Label>
                    <Label title="Cpf" className="col s12 m12 l4">
                      <SistemaInput
                        name="cpf"
                        defaultHtml
                        onChange={(e) => {
                          setRegex.setCpf(e);
                          handleOnChange(e);
                        }}
                        defaultValue={setRegex.setCpf(formData?.cpf)}
                      />
                    </Label>
                    <Label title="Email" className="col s12 m12 l8">
                      <SistemaInput
                        name="email"
                        type="email"
                        defaultValue={formData?.email}
                        defaultHtml
                        onChange={handleOnChange}
                      />
                    </Label>
                    <Label title="Nascimento" className="col s12 m12 l4 ">
                      <SistemaInput
                        name="nascimento"
                        type="date"
                        defaultValue={formData?.nascimento}
                        defaultHtml
                        onChange={handleOnChange}
                      />
                    </Label>
                    <Label title="Estado" className="col s12 m12 l6">
                      <SistemaSelect
                        firstOption
                        name="estadoCod"
                        notForm
                        defaultValue={formData?.estadoCod}
                        list={{ list: estados, value: "cod", label: "nome" }}
                        onChange={(e) => {
                          const v = e.currentTarget.value;
                          const thisFind = estados.find((est) => est?.cod == v);
                          setEstado(thisFind);
                          setFormData((prev) => ({
                            ...prev,
                            estadoCod: v,
                            cidadeCod: undefined,
                          }));
                        }}
                      />
                    </Label>
                    <Label title="Cidade" className="col s12 m12 l6">
                      <SistemaSelect
                        firstOption
                        name="cidadeCod"
                        notForm
                        value={(estado?.cidades && formData?.cidadeCod) || ""}
                        list={{ list: estado?.cidades, value: "cod", label: "nome" }}
                        onChange={handleOnChange}
                      />
                    </Label>
                    <Label title="Bairro" className="col s12 m12 l6">
                      <SistemaInput
                        name="bairro"
                        defaultValue={formData?.bairro}
                        defaultHtml
                        onChange={handleOnChange}
                      />
                    </Label>
                    <Label title="Cep" className="col s12 m12 l4">
                      <SistemaInput
                        name="cep"
                        defaultValue={formData?.cep}
                        defaultHtml
                        onChange={handleOnChange}
                      />
                    </Label>
                    <Label title="Nº" className="col s12 m3 l2">
                      <SistemaInput
                        name="numeroLogradouro"
                        type="number"
                        defaultValue={formData?.numeroLogradouro}
                        defaultHtml
                        onChange={handleOnChange}
                      />
                    </Label>
                    <Label title="Logradouro" className="col s12 m9 l6">
                      <SistemaInput
                        name="logradouro"
                        defaultValue={formData?.logradouro}
                        defaultHtml
                        onChange={handleOnChange}
                      />
                    </Label>
                    <Label title="Complemento" className="col s12 m12 l6 ">
                      <SistemaInput
                        name="complementoLogradouro"
                        defaultValue={formData?.complementoLogradouro}
                        defaultHtml
                        onChange={handleOnChange}
                      />
                    </Label>
                    <Label title="Alterar Senha" className="col s12 m12 l4">
                      <SistemaSelect
                        as="select"
                        onChange={(e) => {
                          const v = e.currentTarget.value;
                          setUpdateSenha(v);
                        }}
                      >
                        <option value="false">Não</option>
                        <option value="true">Sim</option>
                      </SistemaSelect>
                    </Label>
                    <Label
                      title="Nova senha"
                      className="col s12 m12 l4 Perfil_Label_input_senha"
                    >
                      <SistemaSenhaInput
                        disabled={updateSenha !== "true" && updateSenha !== true}
                        name="novaSenha"
                        defaultHtml
                        onChange={handleOnChange}
                      />
                    </Label>
                    <Label
                      title="Repetir nova senha"
                      className="col s12 m12 l4 Perfil_Label_input_senha"
                    >
                      <SistemaSenhaInput
                        disabled={updateSenha !== "true" && updateSenha !== true}
                        name="confirmNovaSenha"
                        defaultHtml
                        onChange={handleOnChange}
                      />
                    </Label>
                  </div>
                </Materialize>
              </ThisForm>
            </ReactCustomScrollbars>
          </div>
        </Content>
      )}
    </>
  );
}
