import styled from "styled-components";
import Check from "~/componentes/CustomForm/Check";

export const SubjectsField = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CheckInput = styled(Check)`
  margin: 0 0.75em 0 0;
  appearance: none;
  width: 1.25em;
  height: 1.25em;
  background: #d0d7db;
  border-radius: 0.125em;
  transition: 0.3s ease;
  cursor: pointer;
  outline: none;
  border: none;
  box-shadow: none;
  position: relative;

  :hover {
    box-shadow: none;
  }

  :checked {
    background: #1ca9d0;
  }
  :checked ~ h6 {
    color: #293f57;
  }

  ::before {
    position: absolute;
    content: "";
    width: 0.875em;
    height: 0.125em;
    background: #fff;
    -webkit-transform: rotate(-50deg);
    -ms-transform: rotate(-50deg);
    transform: rotate(-50deg);
    top: 0.563em;
    left: 0.313em;
    transition: 0.3s ease;
    opacity: 0;
  }
  ::after {
    position: absolute;
    content: "";
    width: 0.375em;
    height: 0.125em;
    background: #fff;
    -webkit-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    transform: rotate(50deg);
    top: 0.689em;
    left: 0.188em;
    transition: 0.3s ease;
    opacity: 0;
  }

  :checked::before,
  :checked::after {
    opacity: 1;
  }
`;

export const Filter = styled.div`
  margin-top: -0.5em;
  margin-bottom: 1em;

  p {
    font-weight: 600;
    font-size: 0.8em;
    margin-right: 0.5em;
  }

  .Turmas_Disciplinas_filter_input {
    width: 100%;
  }

  .Turmas_Disciplinas_filter_label {
    width: fit-content;
    display: flex;
    align-items: center;

    .Turmas_Disciplinas_filter_label_span {
      margin-top: 0.5em;
      margin-right: 0.5em;
    }
  }

  @media (min-width: 600px) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Turmas_Disciplinas_filter_input {
      width: 49%;
    }

    .Turmas_Disciplinas_filter_label_span {
      margin-top: 0;
    }
  }
`;
