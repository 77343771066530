import React from "react";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import { useState } from "react";

import { Container } from "./styles";
import { SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import api from "~/services/api";
import TurmasList from "../TurmasList";
import Label from "~/componentes/CustomForm/Label";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";

function EditContent({ mountedData, reloadTableData }) {
  const [turmas, setTurmas] = useState([]);

  async function handleSubmit(data, options) {
    const { closeReload } = options || {};
    console.log("sgsjdigosjgoi");
    console.log(data);
    console.log(mountedData?.cod);
    console.log(turmas);

    let turmasFormated = turmas;

    if(turmas.length > 0){
      let disciplinas = turmas[0].disciplinasTurma.map(dis => {
        return {
          ...dis,
          professor: {
            ...dis.professor,
            usuario: {
              ...dis.professor.usuario,
              preferencias: {}
            }
          }
        }
      })
  
      turmasFormated = [
        {
          ...turmas[0],
          disciplinasTurma: disciplinas
        }
      ]
    }
    console.log(turmasFormated);
    
    ShowConfirmacao(
      async () => {
        console.log("ef                sgsjdigosjgoi");
        await api
          .put("filial/aluno", { ...data, cod: mountedData?.cod, turmas: turmasFormated })
          .then((r) => {
            if (r?.status === 200 && closeReload) {
              closeReload();
            }
          });
      },
      { message: "Tem certeza que deseja atualizar esse aluno?" }
    );
  }

  return (
    <Container onSubmit={handleSubmit}>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Label title="Matrícula" className="col s12 m12 l4">
          <SistemaInput required name="matricula" defaultValue={mountedData?.matricula} />
        </Label>
        <TurmasList
          reloadTableData={reloadTableData}
          alunoCod={mountedData?.cod}
          setter={setTurmas}
        />
      </CustomScrollBar>
      <SLModalFooter submitButton={handleSubmit} />
    </Container>
  );
}

export default EditContent;
