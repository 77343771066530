import React, { useEffect, useState } from "react";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import Label from "~/componentes/CustomForm/Label";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";

// import { Container } from './styles';

function InputTipoTitulo({ tipoTituloSelected, setTipoTituloSelected }) {

  return (
    <SistemaInput
      name="tipotitulo"
      defaultValue={tipoTituloSelected?.codigo || ""}
      setter={(tipoTitulo) => setTipoTituloSelected({...tipoTituloSelected, codigo: tipoTitulo || ""})}
      required
    />
  );
}

export default InputTipoTitulo;
