/* eslint-disable eqeqeq */
import React from "react";

import { Container, MaterializeContent } from "./styles.js";
import FirstSection from "./FirstSection";
import ThisTable from "./ThisTable";
import Pagination from "~/componentes/Pagination";
import ThisFilter from "./ThisFilter";
import ThisPdfButton from "./ThisPdfButton";
import ThisExcelButton from "./ThisExcelButton";

function Alunos() {
  const [dados, setDados] = React.useState();
  const [filtro, setFiltro] = React.useState();

  const { avaliacoes, stats } = React.useMemo(() => {
    const { periodoLetivoCod } = filtro || {};
    const thisAvaliacoes = [];
    const thisStats = {
      cards: {
        pendentes: 0,
        entregues: 0,
        total: 0,
      },
      chart: {
        labels: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        datasets: [
          {
            label: "Nota Baixa",
            backgroundColor: "#FF4031",
            data: new Array(12),
          },
          {
            label: "Nota Alta",
            backgroundColor: "#4FA754",
            data: new Array(12),
          },
        ],
      },
    };

    if (Array.isArray(dados?.avaliacoesTurmaAluno)) {
      dados.avaliacoesTurmaAluno.forEach((e) => {
        if (e?.periodoLetivo?.cod == periodoLetivoCod) {
          thisAvaliacoes.push(e);
          if (e?.entregue) {
            thisStats.cards.entregues++;
          } else if (e?.expirado && !e?.entregue) {
            thisStats.cards.pendentes++;
          }
          thisStats.cards.total++;

          const thisMonth = e?.dataFim && new Date(e.dataFim).getMonth();
          const [baixa, alta] = thisStats.chart.datasets;

          if (e?.entregue && e?.corrigido && (thisMonth >= 0 || thisMonth < 12)) {
            if (e?.nota >= 7) {
              if (alta.data[thisMonth] > 0) {
                alta.data[thisMonth]++;
              } else {
                alta.data[thisMonth] = 1;
              }
            } else {
              if (baixa.data[thisMonth] > 0) {
                baixa.data[thisMonth]++;
              } else {
                baixa.data[thisMonth] = 1;
              }
            }
          }
        }
      });
    }

    return { avaliacoes: thisAvaliacoes, stats: thisStats };
  }, [dados, filtro]);

  return (
    <Container>
      {/* <ThisFilter {...{ dados, setDados, setFiltro }} />

      <FirstSection stats={stats} /> */}
      <div className="table-content">
        <ThisTable usuario={dados?.usuario} data={avaliacoes} />

        <div className="table-action-buttons">
          <MaterializeContent>
            <div className="row">
              <div className="col s12 m12 l6 paginator">
                <Pagination />
              </div>
              <div className="col s12 m12 l6 actions-paginator">
                <ThisPdfButton data={dados} avaliacoes={avaliacoes} />
                <ThisExcelButton data={dados} avaliacoes={avaliacoes} />
              </div>
            </div>
          </MaterializeContent>
        </div>
      </div>
    </Container>
  );
}

export default Alunos;
