import styled from "styled-components";

export const Container = styled.div`
  color: #293f57;
  border-bottom: solid 0.1em #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5em;

  .CustomTable_Modal_Button_fechar {
    position: relative;
    border: none;
    background: none;
    height: 100%;
    color: #293f57;
  }
  .CustomTable_Modal_Button_fechar::after {
    transition: 0.3s ease;
    content: "";
    position: absolute;
    background: rgba(28, 169, 208, 0.22);
    width: 1.5em;
    height: 1.5em;
    left: -0.25em;
    top: -0.25em;
    border-radius: 50%;
    transform: scale(0);
    transform-origin: center center;
  }
  .CustomTable_Modal_Button_fechar:hover::after {
    transform: scale(1);
  }
`;
