import { createContext } from "react";

const sistemaStore = createContext({
  moduloPath: "",
  menuPath: "",
  props: "",
  setComponentPath: ({ moduloPath, menuPath, props }) => {},
});

export default sistemaStore;
