import React, { useEffect } from "react";
import FileInput from "../..";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import { useState } from "react";

import { Container, FileName } from "./styles";
import { Button } from "~/componentes/CustomForm";

function ColoredFileInput({ formFile, readOnly, blueLayout, onDefaultFileRemove, preventCancelClick = true, defaultFile, innerRef, ...rest }) {
  const [file, setFile] = useState();
  const [thisRef, setThisRef] = useState();
  const [defaultPreview, setDefaultPreview] = useState();
  const [loadedDefault, setLoadedDefault] = useState(false);

  // React.useEffect(() => {
  //   if (defaultPreview && defaultFile?.originalname !== file) {
  //     setDefaultPreview();
  //   }
  // }, [defaultFile, defaultPreview, file]);

  const changeFile = React.useCallback(
    (newFilename, preview) => {
      setFile(typeof newFilename === "string" ? newFilename : "");
      if (loadedDefault && defaultPreview === defaultFile?.url && !preview && typeof onDefaultFileRemove === "function") {
        onDefaultFileRemove(defaultFile);
      }
      setDefaultPreview(preview);
    },
    [defaultFile, defaultPreview, loadedDefault, onDefaultFileRemove]
  );

  useEffect(() => {
    const { originalname, url } = defaultFile || {};
    if (!loadedDefault && originalname && url) {
      setLoadedDefault(true);
      changeFile(originalname, url);
    }
  }, [changeFile, defaultFile, loadedDefault]);

  return (
    <Container>
      {!readOnly && (
        <>
          <FileInput
            innerRef={(e) => {
              setThisRef(e);
              innerRef && innerRef(e);
            }}
            preventCancelClick={preventCancelClick}
            style={{ display: "none" }}
            formFile={(data) => {
              const thisFile = data?.get?.(rest?.name || "file");
              if (thisFile?.name) {
                changeFile(thisFile.name);
              }
              formFile && formFile(data);
            }}
            {...rest}
          />
          <ColoredButton className="ColoredFileInput_Container_button" yellowLayout small onClick={() => thisRef?.click()} type="button">
            Arquivo
          </ColoredButton>
        </>
      )}
      <FileName
        // blueLayout={blueLayout}
        relativeHeight={false}
        lines={1}
        lineHeight={2.5}
        onClick={() => !readOnly && thisRef?.click()}
        style={{
          color: !file && "#cedde0",
        }}
      >
        {file ? file : readOnly ? "Não há arquivo" : "Escolha o arquivo"}
      </FileName>
      {!readOnly && (
        <Button
          type="button"
          className="ColoredFileInput_Container_icons"
          onClick={() => {
            if (thisRef) {
              thisRef.value = "";
            }
            changeFile("");
          }}
          clean
        >
          <i className="material-icons">delete</i>
        </Button>
      )}
      {defaultPreview && (
        <Button type="button" className="ColoredFileInput_Container_icons" onClick={() => window.open(defaultPreview, "_blank")} clean>
          <i className="material-icons">visibility</i>
        </Button>
      )}
    </Container>
  );
}

export default ColoredFileInput;
