import React from "react";
import { EduquemeInput, SistemaInput } from "../Input/styles";

import { Container } from "./styles";

/**
 * Componente para padronizar o Label tanto para entradas de dados como visualização.
 * @param {Object} props
 * @param {Object} props.eduquemeLayout
 * @param {string} props.title Adiciona um elemento do tipo "p" com o título.
 * @param {string} props.text Se true, adiciona a estilização do texto para o children, se string, irá adiciona-la como um texto estilizado.
 * @param {Boolean} props.noInput Remove o padrão do css dos inputs.
 * @param {string} props.setType Padrão label. Altera o tipo.
 * @param {string} props.noBorder Remove a borda ao aplicar um texto.
 */
function Label({
  title,
  children,
  noInput,
  noBorder,
  text = false,
  setType,
  eduquemeLayout,
  ...resto
}) {
  function childrenText() {
    if (text && typeof text !== "string" && typeof text !== "number") {
      return <div className="Label_text">{children}</div>;
    }
    return children;
  }

  const TextElement = eduquemeLayout ? EduquemeInput : SistemaInput;

  return (
    <Container
      {...{ setType, noInput, noBorder, eduquemeLayout }}
      text={text !== false}
      {...resto}
    >
      {title && <p className="Label_title_p">{title}</p>}
      {childrenText()}
      {(typeof text === "string" || typeof text === "number") && (
        <TextElement defaultHtml readOnly value={text} />
      )}
    </Container>
  );
}

export default Label;
