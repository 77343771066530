import styled from "styled-components";
import { SLTable } from "~/pages/Sistema/componentes/SistemaList";

export const ThisTable = styled(SLTable)`
  table-layout: fixed;

  td:not(:last-child),
  th:not(:last-child) {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  td:first-child,
  th:first-child {
    width: 20%;
  }

  td:nth-child(2),
  th:nth-child(2) {
    width: 20%;
  }

  td:nth-child(3),
  th:nth-child(3) {
    width: 12%;
    text-align: center;
    padding-right: 0em;
  }

  td:nth-child(5),
  th:nth-child(5) {
    width: 20%;
  }

  td:nth-child(6),
  th:nth-child(6) {
    width: 23%;
  }
`;
