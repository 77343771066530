import React, { useState, useEffect } from "react";
import Label from "~/componentes/CustomForm/Label";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";

import { Container } from "./styles";
import { Materialize } from "~/styles/materializeGrid";
import SistemaTable from "~/componentes/StyledComponents/Tables/SistemaTable";
import IconButton from "~/componentes/CustomForm/Button/Styles/IconButton";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import ShowError from "~/utils/ShowError";
import Pagination from "~/componentes/Pagination";

function ThisModal({ setShowModal, setTab, setPlNovos, plDisponiveis }) {
  const [periodoLetivo, setPeriodoLetivo] = useState();
  const [serieSelect, setSerieSelect] = useState();
  const [serie, setSerie] = useState();
  const [turma, setTurma] = useState();
  const [pageData, setPageData] = useState([]);

  function handleDelete(disc) {
    let findDisc = turma?.disciplinasTurma?.findIndex((e) => e?.cod === disc?.cod);

    if (findDisc >= 0) {
      setTurma((prev) => {
        let newDisc = [...prev?.disciplinasTurma];
        newDisc.splice(findDisc, 1);

        return { ...prev, disciplinasTurma: newDisc };
      });
    }
  }

  useEffect(() => {
    let thisSeries = {};
    if (Array.isArray(periodoLetivo?.turmas)) {
      periodoLetivo.turmas.forEach((t) => {
        const s = t?.serie;

        if (s) {
          if (Array.isArray(thisSeries?.[s?.cod]?.turmas)) {
            thisSeries[s.cod].turmas.push(t);
          } else {
            thisSeries[s.cod] = { ...s, turmas: [t] };
          }
        }
      });
    }
    setSerieSelect(Object.values(thisSeries));
  }, [periodoLetivo]);

  function handleSubmit(closeModal) {
    if (turma) {
      if (
        !Array.isArray(turma?.disciplinasTurma) ||
        !(turma?.disciplinasTurma?.length > 0)
      ) {
        ShowError("É necessário adicionar pelo menos uma disciplina.");
      } else {
        ShowConfirmacao(
          () => {
            setPlNovos((prev) => {
              let newPrev = Array.isArray(prev) ? [...prev] : [];
              if (periodoLetivo?.cod && serie?.cod && turma?.cod) {
                const thisPl = newPrev?.find?.((e, i) => e.cod === periodoLetivo?.cod);

                if (thisPl) {
                  const thisTurma = thisPl?.turmas?.find?.((e) => e.cod === turma?.cod);

                  if (!thisTurma) {
                    if (Array.isArray(thisPl?.turmas)) {
                      thisPl.turmas.push(turma);
                    } else {
                      thisPl.turmas = [turma];
                    }
                  }
                } else {
                  newPrev = [...newPrev, { ...periodoLetivo, turmas: [turma] }];
                }
                return newPrev;
              }
            });
            setTab(1);
            closeModal && closeModal();
          },
          { message: "Tem certeza que deseja adicionar essa turma?" }
        );
      }
    } else {
      closeModal && closeModal();
    }
  }

  return (
    <Container
      title="Nova Matricula"
      footerButtons={{
        submitButton: {
          type: "button",
          onClick: handleSubmit,
        },
      }}
      outClick={() => setShowModal(false)}
      scrollBar
    >
      <Materialize>
        <div className="row">
          <Label title="PERIODO LETIVO" className="col s12 m12 l4">
            <SistemaSelect
              firstOption
              list={{ list: plDisponiveis, value: "cod", label: "nome" }}
              defaultValue={plDisponiveis?.[0]?.cod}
              setList={(e) => {
                setPeriodoLetivo(e);
                setSerie();
                setTurma();
              }}
            />
          </Label>
          <Label title="SÉRIE" className="col s12 m12 l4">
            <SistemaSelect
              firstOption
              list={{ list: serieSelect, value: "cod", label: "nome" }}
              value={serie?.cod || ""}
              onChange={(e) => {
                let thisValue = e.currentTarget.value;
                let thisSerie = serieSelect?.find?.(
                  (s) => String(s?.cod) === String(thisValue)
                );
                setSerie(thisSerie);
                setTurma();
              }}
            />
          </Label>
          <Label title="TURMA" className="col s12 m12 l4">
            <SistemaSelect
              firstOption
              value={turma?.cod || ""}
              list={{ list: serie?.turmas, value: "cod", label: "nome" }}
              onChange={(e) => {
                let thisValue = e.currentTarget.value;
                let thisTurma = serie?.turmas?.find?.(
                  (t) => String(t?.cod) === String(thisValue)
                );
                setTurma(thisTurma);
              }}
            />
          </Label>
        </div>
      </Materialize>
      <SistemaTable className="TurmasList_StyledTable">
        <thead>
          <tr>
            <th>Disciplina</th>
            <th>Professor</th>
            <th>-</th>
          </tr>
        </thead>
        <tbody>
          {pageData?.map((disc, i) => (
            <tr key={disc?.cod || i}>
              <td>{disc?.disciplinaFilial?.nome}</td>
              <td>{disc?.professor?.usuario?.nome}</td>
              <td>
                <IconButton
                  onClick={() => handleDelete(disc)}
                  materialIcon={{ delete: true }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </SistemaTable>
      <Pagination
        size={4}
        data={turma?.disciplinasTurma}
        getInfo={({ pageData }) => setPageData(pageData)}
      />
    </Container>
  );
}

export default ThisModal;
