import React from "react";

// import CustomListDefaultModalBody from "../../Styles/CustomListDefaultModalBody";
import ThisModalsWrapper from "../ThisModalsWrapper";
import sistemaListStore from "../../thisStore";

/**
 * Esse componente gerencia o modal de criação de um registro. Pode ser utilizado os componentes de
 * formulário da tabela passando apenas um name para automatizar o processo de criação. Mas também
 * pode ser passado a propriedade formData para customizar os dados caso este possua uma maior complexidade.
 * @param {Object} props
 * @param {Boolean} props.customModal Se true. Remove a estilização inicial do modal.
 * @param {Function} props.outClick Se informado recebe como argumento uma função para fechar o modal.
 * @param {Object} props.closeCondition Padrão true. Apenas fecha o modal caso essa propriedade seja true.
 * @param {Function} props.setter Aceita uma função que recebe o valor do registro (caso haja) ao ser montado e valor undefined ao ser desmontado. Pode ser chamado outras vezes caso haja alteração no registro.
 * @param {Function} props.contentMount Aceita uma função que será chamada quando o componente for montado.
 * @param {Function} props.contentUnmount Aceita uma função que será chamada quando o componente for desmontado.
 * @param {string} props.title Altera o titulo padrão.
 * @param {Object} props.modalStyle Aceita propriedades de style para alterar a estilização do componente do Modal.
 * @param {any} props.loadCondition Padrão true. Apenas carregará o children caso true. Recebe Boolean, função, ou Array. No caso do array, é um array de dependencias, será apenas verificado se cada um possui algum tipo de valor, enquanto algum for undefined, null ou false, o children não irá carregar.
 */
function ThisEditModal({ children, closeCondition = true, title = "ATUALIZAR", ...rest }) {
  const { state } = React.useContext(sistemaListStore);

  return state?.modal?.edit || !closeCondition ? (
    <ThisModalsWrapper closeCondition={closeCondition} title={title} {...rest}>
      {children}
    </ThisModalsWrapper>
  ) : (
    <></>
  );
}

export default ThisEditModal;
