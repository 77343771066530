import styled from "styled-components";
import { SLForm } from "~/pages/Sistema/componentes/SistemaList";

export const Container = styled(SLForm)`
  width: 70em;
  max-width: 100%;

  .Label_inputWithButton {
    display: flex;
    align-items: center;

    button {
      padding: 0.25em 0.5em;
      margin-bottom: 0.4em;
      margin-left: 0.25em;
    }
  }

  .fileInputDiv {
    margin-bottom: 1em;
  }
`;
