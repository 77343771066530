/* eslint-disable no-useless-computed-key */
import React from "react";

import api from "~/services/api";
import ShowContent from "./ShowContent";
import { SLCreateModal, SLEditModal, SLShareModal, SLShowModal } from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import CreateContent from "./CreateContent";
import EditContent from "./EditContent";
import ShareContent from "./ShareContent";
import CustomBodyContent from "./CustomBodyContent";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";
import FilterModal from "./FilterModal";

function Aulas() {
  const [mountedData, setMountedData] = React.useState();
  const [periodosLetivos, setPeriodosLetivos] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [tabFilter, setTabFilter] = React.useState({ reutilizavel: true });
  const [showFilterModal, setShowFilterModal] = React.useState(false);

  React.useEffect(() => {
    api.get("professor/gestaoDisciplinas/aula/filterData").then((response) => {
      setPeriodosLetivos(response?.data?.periodosLetivos || []);
    });
  }, []);

  return (
    <Container
      requests={{
        findAll: () => api.get("professor/gestaoDisciplinas/aulas"),
        findOne: (thisData) => api.get("professor/gestaoDisciplinas/aula", { params: { cod: thisData.cod } }),
        deleteOne: (thisData) => api.delete("professor/gestaoDisciplinas/aula", { data: { cod: thisData.cod } }),
      }}
      inputs={[{ filter: "titulo", label: "Título" }]}
      dataFilter={tabFilter}
      setter={setData}
      setCustomFilterModal={setShowFilterModal}
    >
      <TabsWrapper tabHeight={2.4}>
        <Tab materialIcon="check_circle_outline" onClick={() => setTabFilter({ reutilizavel: true })}>
          Reutilizavel
        </Tab>
        <Tab materialIcon="highlight_off" onClick={() => setTabFilter({ reutilizavel: false })}>
          Não reutilizavel
        </Tab>
      </TabsWrapper>

      {showFilterModal && <FilterModal {...{ tabFilter, setTabFilter }} outClick={() => setShowFilterModal(false)} />}

      <CustomBodyContent {...{ data, tabFilter }} />

      <SLCreateModal title="Nova Aula" setter={setMountedData}>
        <CreateContent {...{ periodosLetivos }} />
      </SLCreateModal>

      <SLEditModal title="Atualizar Aula" setter={setMountedData}>
        <EditContent {...{ mountedData, periodosLetivos }} />
      </SLEditModal>

      <SLShareModal title="Disponibilizar Aula" setter={setMountedData}>
        <ShareContent {...{ mountedData, periodosLetivos }} />
      </SLShareModal>

      <SLShowModal title="Aula" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>
    </Container>
  );
}

export default Aulas;
