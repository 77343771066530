import React from "react";

import { Container } from "./styles";
import { Materialize } from "~/styles/materializeGrid";

import api from "~/services/api";
import { Form } from "~/componentes/CustomForm";
import SistemaModalFooter from "~/componentes/Modal/Styleds/Footers/SistemaModalFooter";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import handleCpf from "~/utils/handleCpf";
import CustomToast from "~/componentes/CustomToast";
import DadosMatricula from "~/componentes/DadosMatricula";

function FormContent({ filial, outClick, serie, dadosMatricula, periodoLetivo }) {
  const [closeModal, setCloseModal] = React.useState(() => () => {});
  const [dados, setDados] = React.useState({});
  const [files, setFiles] = React.useState(new FormData());

  async function handleSubmit() {
    if (dadosMatricula) {
      const checkCpfs = [];
      // dados?.responsavel?.cpf && checkCpfs.push(dados?.responsavel?.cpf);
      dados?.aluno?.cpf && checkCpfs.push(dados?.aluno?.cpf);

      if (checkCpfs.length > 0 && checkCpfs.some((e) => !handleCpf(e))) {
        CustomToast.error("Cpf inválido");
      } else {
        const newFD = new FormData();
        newFD.set("dados", JSON.stringify(dados));
        newFD.set("periodoLetivoCod", periodoLetivo?.cod);
        newFD.set("filialCod", filial?.cod);
        newFD.set("serieCod", serie?.cod);
        files.forEach((v, k) => newFD.set(k, v));

        await api
          .post("global/enviarRequisicaoMatricula", newFD)
          .then(() => {
            CustomToast.success("Matrícula enviada com sucesso!");
            closeModal();
          })
          .catch((err) => {
            CustomToast.error(err?.response?.data?.msg || "Ocorreu um erro, por favor tente novamente.");
          });
      }
    } else {
      CustomToast.error("Escolha a série");
    }
  }

  return (
    <Container title="Matricula" outClickAnimation={(data) => setCloseModal(data)} outClick={() => outClick && outClick(false)}>
      <Form onSubmit={handleSubmit}>
        <CustomScrollBar autoHide fixScrollMargin autoHeightMax="75vh">
          <Materialize>
            <DadosMatricula setter={setDados} fileSetter={setFiles} loadResponsavel grupos={dadosMatricula} blueLayout />
          </Materialize>
        </CustomScrollBar>
        <SistemaModalFooter cancelButton={{ onClick: closeModal }} />
      </Form>
    </Container>
  );
}

export default FormContent;
