import React, { useEffect, useState } from "react";
import Label from "~/componentes/CustomForm/Label";
import { Amount, AmountContainer, Container, SubTitle, Title } from "./styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import { Materialize } from "~/styles/materializeGrid";
import { SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import { useMercadopago } from "react-sdk-mercadopago/lib";
import api from "~/services/api";
import Checkout from "./Checkout";

function ShowContent({ mountedData }) {

  return (
    <Container>
      <CustomScrollBar fixScrollMargin autoHeightMax="80vh">
        <Materialize>
          <div className="row" style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Title>Valor a Pagar</Title>
            <AmountContainer>
              <Amount>R$ {mountedData.valor}</Amount>
            </AmountContainer>
            {mountedData && <Checkout {...{mountedData}}/>}
          </div>
        </Materialize>
      </CustomScrollBar>
    </Container>
  );
}

export default ShowContent;
