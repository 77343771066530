/* import React from "react";
import { ThisSistemaListaIconButonStyle } from "../styles";

function ThisChangeStatusButton({ icon = "lock", ...rest }) {
  return (
    <ThisSistemaListaIconButonStyle moldalType="changeStatus" className={`material-icons`} {...rest}>
      {icon}
    </ThisSistemaListaIconButonStyle>
  );
}

export default ThisChangeStatusButton; */

import React from "react";
import { ThisSistemaListaIconButonStyle } from "../styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import { useContext } from "react";
import sistemaListStore from "../../../ThisMainComponent/thisStore";

function ThisChangeStatusButton({ icon = "lock", data, onClick, ...rest }) {
  const { state } = useContext(sistemaListStore);
  const { requests, reloadData } = state || {};
  const { changeStatusOne } = requests || {};

  async function handleClick() {
    await ShowConfirmacao(async () => {
      const response = await changeStatusOne(data);

      if (response?.status === 200) {
        reloadData && reloadData();
      }

      return response;
    });

    onClick && onClick();
  }

  return (
    <ThisSistemaListaIconButonStyle
      moldalType="changeStatus"
      className={`material-icons`}
      onClick={handleClick}
      {...rest}
    >
      {icon}
    </ThisSistemaListaIconButonStyle>
  );
}

export default ThisChangeStatusButton;
