import React from "react";
import {
  EduquemeInput,
  SistemaInput,
} from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";
import {
  EduquemeSelect,
  SistemaSelect,
} from "~/componentes/CustomForm/Select/styles";
import * as dadosComponentes from "../dados";
import maskValue from "./maskValue";
import ThisFileInput from "./ThisFileInput";

function ThisElement({ isSelect, dadoConfig, blueLayout, props, ...rest }) {
  const { tipoDado, selectOptions } = dadoConfig || {};

  const ThisSelect = blueLayout ? EduquemeSelect : SistemaSelect;

  const ThisInput = blueLayout ? EduquemeInput : SistemaInput;

  return isSelect || tipoDado?.nome === "select" ? (
    <ThisSelect firstOption list={selectOptions} {...props} />
  ) : tipoDado?.nome === "file" ? (
    <ThisFileInput {...{ props, blueLayout, ...rest }} />
  ) : (
    <ThisInput type={tipoDado?.nome} {...props} />
  );
}

function DadoComponent({
  dadoConfig,
  readOnly,
  dados,
  value,
  grupo,
  defaultValue,
  blueLayout,
  isSelect,
  className,
  title,
  setDados,
  setFiles,
  ...rest
}) {
  const { nome, chave, componente, tipoDado, atributos = {} } =
    dadoConfig || {};
  // const [loadedDV, setLoadedDV] = React.useState(false);

  function handleChange(data, setFile) {
    if (grupo?.chave && chave) {
      if (setFile || tipoDado?.nome !== "file") {
        setDados((prev) => ({
          ...prev,
          [grupo.chave]: { ...prev?.[grupo.chave], [chave]: data },
        }));
      }
    }
  }

  const thisValue = React.useMemo(() => {
    const findData = dados?.[grupo?.chave]?.[chave];
    return (readOnly ? findData?.nome : findData?.cod) || findData || "";
  }, [dados, readOnly, chave, grupo]);

  // React.useEffect(() => {
  //   if (!loadedDV && defaultValue) {
  //     setLoadedDV(true);
  //     handleChange(defaultValue, true);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [defaultValue, loadedDV]);

  const RenderFormElement = dadosComponentes?.[componente] || ThisElement;
  const ThisInput = blueLayout ? EduquemeInput : SistemaInput;

  return (
    <Label title={title || nome} className={className}>
      {readOnly && tipoDado?.nome !== "file" ? (
        <ThisInput type={tipoDado?.nome === "select" ? "text" : tipoDado?.nome} value={thisValue} notForm readOnly required={false} />
      ) : (
        <RenderFormElement
          {...{
            isSelect,
            dados,
            setDados,
            setFiles,
            grupo,
            dadoConfig,
            blueLayout,
            props: {
              ...rest,
              readOnly,
              onChange: (e) => {
                const newValue = e.currentTarget.value;
                handleChange(newValue);
              },
              defaultValue,
              notForm: true,
              name: chave,
              value: maskValue(thisValue, chave),
              ...atributos,
            },
          }}
        />
      )}
    </Label>
  );
}

export default DadoComponent;
