import styled from "styled-components";
import layout from "./layout";
import { Button } from "~/componentes/CustomForm";

export const StyleColoredButton = styled(Button)`
  border: none;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  outline: none;
  min-width: fit-content;
  padding: ${({ nochildren }) => (nochildren ? "0.25em" : "0.5em 1em")};
  font-weight: 600;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: 0.1s;

  background-color: ${(props) => layout(props).background};
  color: ${(props) => layout(props).color};
  box-shadow: 0 0.3em 0 ${(props) => layout(props).shadowColor};
  border-radius: ${(props) => (props.small ? "0.2em" : "0.3em")};

  &:active {
    box-shadow: none;
    transform: translateY(0.3em);
  }

  &:hover {
    background-color: ${(props) => layout(props).hoverBackground};
  }
`;
