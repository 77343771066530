const initialState = {};

export default function thisReducer(state = initialState, { type, payload }) {
  switch (type) {
    case "sistemaListUpdateGlobalData":
      return { ...state, globalData: payload?.globalData || [] };
    case "sistemaListCreateModalButton":
      return {
        ...state,
        createModalButton: payload?.createModalButton || false,
      };
    case "sistemaListUpdateEntityData":
      return { ...state, entityData: payload?.entityData };
    case "sistemaListCloseModalDate":
      return { ...state, closeModalDate: payload?.closeModalDate };
    case "sistemaListShowModal":
      const { entityData, modal } = payload || {};

      return { ...state, modal, entityData };
    case "sistemaListUpdateData":
      return { ...state, data: payload?.data || [] };
    case "sistemaListUpdateProps":
      return { ...state, ...payload };
    case "sistemaListConfirmSelectValues":
      return { ...state, selectValues: payload.selectValues };
    case "sistemaListUpdateFilterConfig":
      const inputs = Array.isArray(payload?.inputs) ? payload.inputs : [];
      const selects = Array.isArray(payload?.selects) ? payload.selects : [];
      const setCustomFilterModal = payload?.setCustomFilterModal;

      return { ...state, inputs, selects, setCustomFilterModal };
    default:
      return state;
  }
}
