import React from "react";
import CountUp from "react-countup";

// import { Container } from './styles';

function Card({
  cardNumber,
  title,
  number,
  subtitle,
  color = "#FFDA44",
  onClick,
}) {
  return (
    <div
      style={{ borderBottom: `solid ${color} 0.4em` }}
      className={`card${cardNumber} card-style`}
      {...{ onClick }}
    >
      <div className="card-header">
        <h2>{title}</h2>
      </div>
      <div className="card-body">
        <h1 style={{ color: color }}>
          <CountUp end={number || 0} duration={3} />
        </h1>
      </div>
      <div className="card-footer">
        <h6>{subtitle}</h6>
      </div>
    </div>
  );
}

export default Card;
