import React from "react";

import { Container } from "./styles";
import getPlayStoreImg from "~/assets/figuras/badge_play_store.png";

export default function DesktopLayout({ children }) {
  return (
    <>
      {children}
      <Container>
        <h1>Confira nosso aplicativo</h1>
        <p className="p1">Venha conhecer e fazer parte da nossa plataforma de ensino, aprendendo brincando.</p>
        <div className="arrows">
          <span className="material-icons arrow">keyboard_arrow_down</span>
        </div>
        <a href="https://play.google.com/store/apps/details?id=br.com.eduque_me.objetiveti">
          <img src={getPlayStoreImg} alt="Disponível na PlayStore" />
        </a>
        {/*<p>ou</p>
         <div className="btn-field">
          <ColoredButton greenLayout style={{ width: "10em" }}>
            <span className="material-icons and">android</span>
            DOWNLOAD
          </ColoredButton>
        </div> */}
      </Container>
    </>
  );
}
