import React, { useState } from "react";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";

import CustomScrollBar from "~/componentes/CustomScrollBar";
import { Materialize } from "~/styles/materializeGrid";
import { Container } from "./styles";
import { SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import api from "~/services/api";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import ColoredFileInput from "~/componentes/CustomForm/FileInput/styles/ColoredFileInput";
import handleFormData from "~/utils/handleFormData";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";

function CreateEditContent({ mountedData, categoriaLojaVirtual }) {
  const [file, setFile] = useState([]);

  function handleSubmit(data, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let response;
        let thisDataRequest = handleFormData(file, {
          ...data,
          ...(mountedData ? { cod: mountedData.cod } : {}),
        });

        if (mountedData) {
          response = await api.put("admin/lojavirtual", thisDataRequest);
        } else {
          response = await api.post("admin/lojavirtual", thisDataRequest);
        }

        if (response?.status === 200) {
          closeReload && closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao ${
            mountedData ? "atualizar" : "criar"
          } esse Produto da loja virtual, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja ${
          mountedData ? "atualizar" : "criar"
        } esse Produto da loja virtual?`,
        successModal: `Produto da loja virtual ${
          mountedData ? "atualizado" : "cadastrado"
        } com sucesso!`,
      }
    );
  }

  return (
    <Container onSubmit={handleSubmit}>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          <div className="row">
            <Label title="NOME:" className="col s12 m12 l12">
              <SistemaInput name="nome" defaultValue={mountedData?.nome} />
            </Label>
            <Label title="IMAGEM:" className="col s12 m12 l6">
              <ColoredFileInput formFile={setFile} />
            </Label>
            <Label title="EDUCABITS:" className="col s12 m12 l3">
              <SistemaInput
                name="valor"
                type="number"
                defaultValue={mountedData?.valor}
              />
            </Label>
            <Label title="CATEGORIA:" className="col s12 m12 l3">
              <SistemaSelect
                name="categoriaLojaVirtualCod"
                firstOption
                list={{ list: categoriaLojaVirtual, value: "cod", label: "nome" }}
                defaultValue={mountedData?.categoriaLojaVirtualCod}
              />
            </Label>
          </div>
        </Materialize>
      </CustomScrollBar>
      <SLModalFooter />
    </Container>
  );
}

export default CreateEditContent;
