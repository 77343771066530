import styled from "styled-components";
import { Button } from "~/componentes/CustomForm";

export const ThisButtonStyle = styled(Button)`
  transition: 0.1s ease;
  height: ${(props) => (props.tabheight > 2.1 ? `${props.tabheight}em` : "2.1em")};
  min-width: ${(props) => (props.tabheight > 2.1 ? `${props.tabheight}em` : "2.1em")};
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  justify-content: left;
  font-weight: 600;
  font-size: 1em;
  position: relative;
  border: none;
  border-radius: 0.4em 0.4em 0 0em;
  background: ${(props) =>
    props.ativo
      ? props.revertcolor
        ? "#1ca9d0"
        : "#fff"
      : props.revertcolor
      ? "#fff"
      : "#1ca9d0"};
  color: ${(props) =>
    props.ativo
      ? props.revertcolor
        ? "#fff"
        : "#293f57"
      : props.revertcolor
      ? "#293f57"
      : "#fff"};

  & + & {
    margin-left: ${(props) =>
      props.tabheight > 2.1 ? `${props.tabheight * 0.62}em` : "1.24em"};
  }

  .TabsWrapper_Tab_DefaultTabIconStyle {
    ${(props) =>
      props.ismobile
        ? `
      margin: auto;
      font-size: 1.5em;
    `
        : `
      margin-right: 0.5em;
    `};
  }

  &:active {
    transform: scale(0.97);
  }

  ::before {
    z-index: -1;
    position: absolute;
    content: "";
    width: ${(props) => (props.tabheight > 2.1 ? `${props.tabheight}em` : "2.1em")};
    height: 100%;
    box-shadow: 0.313em -0.125em 0.25em rgb(0 0 0 / 4%);
    border-radius: 0.2em 0.375em;
    transform: skew(32deg);
    right: ${(props) =>
      props.tabheight > 2.1 ? `-${props.tabheight * 0.315}em` : "-0.63em"};
    background: ${(props) =>
      props.ativo
        ? props.revertcolor
          ? "#1ca9d0"
          : "#fff"
        : props.revertcolor
        ? "#fff"
        : "#1ca9d0"};
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  z-index: 0;
`;
