import React from "react";
import Card from "../Card";

import { Container } from "./styles";

function ProfessorGlobalCards({ data }) {
  return (
    <Container>
      <Card
        title="Questões"
        subtitle="Total de questões cadastradas"
        color="#FF4031"
        cardNumber={1}
        number={data?.totalQuestoes}
      />
      <Card
        title="Avaliações"
        subtitle="Total de avaliações cadastradas"
        color="#4FA754"
        cardNumber={2}
        number={data?.totalAvaliacoes}
      />
      <Card
        title="Alunos"
        subtitle="Total de alunos que ensina"
        color="#1CA9D0"
        cardNumber={3}
        number={data?.totalAlunos}
      />
      <Card
        title="Turmas"
        subtitle="Total de turmas que ensina"
        color="#FFDA44"
        cardNumber={4}
        number={data?.totalTurmas}
      />
    </Container>
  );
}

export default ProfessorGlobalCards;
