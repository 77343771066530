import React, { useState, useEffect } from "react";
import { Materialize } from "~/styles/materializeGrid";
import SistemaTopico from "~/componentes/StyledComponents/Textos/SistemaTopico";

import { Container } from "./styles";
import DadoComponent from "./DadoComponent";
import { useSelector } from "react-redux";

function DadosMatricula({ grupos, setter, fileSetter, isFormData, defaultValue: defaultValueProp, loadResponsavel, readOnly, blueLayout }) {
  const [dados, setDados] = useState({});
  const [files, setFiles] = useState(new FormData());

  const {
    user: { perfil },
  } = useSelector((state) => state) || {};

  React.useEffect(() => {
    let result = defaultValueProp || {};
    if (loadResponsavel && perfil && Array.isArray(grupos)) {
      grupos.forEach((g) => {
        if (Array.isArray(g?.dados) && g?.chave !== "aluno") {
          g.dados.forEach((d) => {
            const value = perfil?.[d?.chave];
            if (value) {
              if (result?.[g?.chave]) {
                result[g.chave][d.chave] = value;
              } else {
                result[g.chave] = { [d.chave]: value };
              }
            }
          });
        }
      });
    }
    setDados((prev) => ({ ...prev, ...result }));
  }, [defaultValueProp, grupos, loadResponsavel, perfil]);

  // const defaultValue = React.useMemo(() => {
  //   let result = defaultValueProp || {};
  //   if (loadResponsavel && perfil && Array.isArray(grupos)) {
  //     grupos.forEach((g) => {
  //       if (Array.isArray(g?.dados) && g?.chave !== "aluno") {
  //         g.dados.forEach((d) => {
  //           const value = perfil?.[d?.chave];
  //           if (value) {
  //             if (result?.[g?.chave]) {
  //               result[g.chave][d.chave] = value;
  //             } else {
  //               result[g.chave] = { [d.chave]: value };
  //             }
  //           }
  //         });
  //       }
  //     });
  //   }
  //   return result;
  // }, [defaultValueProp, grupos, loadResponsavel, perfil]);

  useEffect(() => {
    if (fileSetter) {
      fileSetter(files);
    }
    if (setter) {
      let setterData = dados;
      if (isFormData) {
        setterData = new FormData();
        files.forEach((v, k) => setterData.append(k, v));
        setterData.append("dados", dados);
      }
      setter(setterData);
    }
  }, [dados, fileSetter, files, isFormData, setter]);

  return (
    <Container>
      {grupos?.map((grupo, gi) => {
        const thisDados = grupo?.dados?.filter((d) => {
          const blockKeys = ["email", "nome", "cpf", "nascimento"];

          if (loadResponsavel && grupo?.chave === "responsavel" && blockKeys.find((k) => k === d?.chave)) {
            return false;
          }
          return true;
        });

        return thisDados?.length > 0 ? (
          <Materialize key={grupo?.cod || gi}>
            <SistemaTopico>{grupo?.nome}</SistemaTopico>
            <div className="row">
              {thisDados?.map((dado, fi) => (
                <DadoComponent
                  {...{
                    dados,
                    setDados,
                    setFiles,
                    grupo,
                    readOnly,
                    blueLayout,
                  }}
                  key={dado?.cod || fi}
                  className={`col s12 m6 l${dado?.largeColumnSize || 6}`}
                  // setter={setDados}
                  // defaultValue={defaultValue?.[grupo?.chave]?.[dado?.chave]}
                  dadoConfig={dado}
                />
              ))}
            </div>
          </Materialize>
        ) : null;
      })}
    </Container>
  );
}

export default DadosMatricula;
