import styled from "styled-components";
import Label from "~/componentes/CustomForm/Label";

export const ItemLabel = styled(Label)`
  color: #000;
  font-weight: 500;

  .Questoes_Item_enunciado {
    display: flex;

    textArea {
      max-width: 100%;
      height: 6em;
      resize: none;
    }

    .preview-input-item {
      height: 9em;
      width: 9em;
      border: dashed 0.1em #dae6e8;
      border-radius: 0.5em;

      button {
        border: none;
      }

      img {
        display: block;
        height: auto;
        max-height: 100%;
        border: none;
        max-width: 100%;
      }
    }

    .Questoes_Item_div_icons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 5em;
      padding: 0.5em;

      .span-divider {
        display: block;
        height: 0.1em;
        width: 1em;
        margin: auto;
        background: #d0d0d0;
      }

      i {
        font-size: 1.75em;
        color: #666;
      }
    }
  }

  .Questoes_Item_resposta {
    display: flex;
  }
`;
