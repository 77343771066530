import { combineReducers } from "redux";

import auth from "./auth/reducer";
import user from "./user/reducer";
import disableButtons from "./disableButtons/reducer";
import errorModal from "./errorModal/reducer";
import loadingModal from "./loadingModal/reducer";
import confirmacaoModal from "./confirmacaoModal/reducer";
import successModal from "./successModal/reducer";

export default combineReducers({
  auth,
  user,
  disableButtons,
  errorModal,
  loadingModal,
  confirmacaoModal,
  successModal,
});
