import React, { useEffect } from "react";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";
import { useMemo } from "react";

function ThisTab({
  plMatriculados,
  plDesativos,
  plNovos,
  periodoLetivoCod,
  setFilteredData,
  tab,
  setTab,
}) {
  const checkMatriculas = useMemo(
    () => plDesativos?.length > 0 || plMatriculados?.length > 0,
    [plDesativos, plMatriculados]
  );

  useEffect(() => {
    let checkData;

    if (checkMatriculas) {
      checkData = tab === 0 ? plMatriculados : tab === 2 ? plDesativos : plNovos;
    } else {
      checkData = plNovos;
    }
    let thisTurmas = checkData?.find((e) => String(e?.cod) === String(periodoLetivoCod))
      ?.turmas;

    setFilteredData(Array.isArray(thisTurmas) ? [...thisTurmas] : []);
  }, [
    checkMatriculas,
    periodoLetivoCod,
    plDesativos,
    plMatriculados,
    plNovos,
    setFilteredData,
    tab,
  ]);

  return (
    <>
      {checkMatriculas && (
        <TabsWrapper tab={tab} revertColor>
          <Tab onClick={() => setTab(0)}>Matriculadas</Tab>
          <Tab onClick={() => setTab(1)}>Novas</Tab>
          <Tab onClick={() => setTab(2)}>Desativas</Tab>
        </TabsWrapper>
      )}
    </>
  );
}

export default ThisTab;
