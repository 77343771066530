import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { Container } from "./styles";
import { Materialize } from "~/styles/materializeGrid";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import api from "~/services/api";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import ColoredFileInput from "~/componentes/CustomForm/FileInput/styles/ColoredFileInput";

function CreateContent({ disciplinas }) {
  const [disciplinasTurmas, setDisciplinasTurmas] = React.useState();

  async function handleSubmit(data, options) {
    const { closeReload } = options || {};

    ShowConfirmacao(
      async () =>
        await api
          .post(
            "professor/gestaoTurmas/avaliacoesDisponibilizadas/conteudoDisponibilizado",
            data
          )
          .then((r) => {
            if (r?.status === 200 && typeof closeReload === "function") {
              closeReload();
            }
            return r;
          }),
      {
        successModal: "Conteúdo enviado com sucesso",
        errorModal: {
          defaultMessage: "Falha ao enviar o conteúdo, por favor tente novamente.",
        },
        message: "Tem certeza que deseja enviar esse conteúdo?",
      }
    );
  }

  return (
    <Container isFormData defaultStyle onSubmit={handleSubmit}>
      <Materialize>
        <div className="row">
          <Label title="Título:" className="col s12 m12 l12">
            <SistemaInput name="titulo" />
          </Label>
          <Label title="Mensagem:" className="col s12 m12 l12">
            <SistemaTextArea name="mensagem" />
          </Label>
          <Label title="Disciplina:" className="col s12 m12 l6">
            <SistemaSelect
              firstOption
              setList={(e) => setDisciplinasTurmas(e?.disciplinasTurmas || [])}
              list={{ list: disciplinas, value: "cod", label: "nome" }}
            />
          </Label>
          <Label title="Turma:" className="col s12 m12 l6">
            <SistemaSelect
              firstOption
              name="disciplinaTurmaCod"
              list={{ list: disciplinasTurmas, value: "cod", label: "turma.nome" }}
            />
          </Label>
          <Label title="Arquivos" className="col s12 m12 12" />
          <div className="col s12 m12 l6">
            <ColoredFileInput name="file1" />
          </div>
          <div className="col s12 m12 l6">
            <ColoredFileInput name="file2" />
          </div>
          <div className="col s12 m12 l6">
            <ColoredFileInput name="file3" />
          </div>
          <div className="col s12 m12 l6">
            <ColoredFileInput name="file4" />
          </div>
        </div>
      </Materialize>
    </Container>
  );
}

export default CreateContent;
