import React from "react";
import { CSVLink } from "react-csv";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";

// import { Container } from './styles';

const headers = [
  { label: "Periodo Letivo", key: "periodoLetivo.nome" },
  { label: "Disciplina", key: "disciplinaFilial.nome" },
  { label: "Série", key: "serie.nome" },
  { label: "Turma", key: "turma.nome" },
  { label: "Nota", key: "nota" },
  { label: "Nota Máxima", key: "notaMaxima" },
  { label: "Entregue", key: "entregue" },
  { label: "Expirado", key: "expirado" },
];

function ThisExcelButton({ avaliacoes, data }) {
  return (
    <ColoredButton materialIcon="arrow_downward" greenLayout>
      <CSVLink
        style={{ color: "#fff" }}
        headers={headers}
        data={avaliacoes}
        filename={`${data?.usuario?.nome}-${data?.usuario?.email}.csv`}
        separator={";"}
      >
        Excel
      </CSVLink>
    </ColoredButton>
  );
}

export default ThisExcelButton;
