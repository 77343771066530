import styled from "styled-components";

const SistemaTopico = styled.h4`
  color: #bbb5b5;
  line-height: 1.5em;
  font-weight: 600;
  font-size: 0.95em;
`;

export default SistemaTopico;
