import React from "react";
import { CSVLink } from "react-csv";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import handleDate from "~/utils/handleDate";

// import { Container } from './styles';

const headers = [
  { label: "Nome", key: "avaliacao.nome" },
  { label: "Corrigido", key: "corrigido" },
  { label: "Data início", key: "dataInicio" },
  { label: "Data término", key: "dataFim" },
];

function ThisExcelButton({ data, avaliacoesTurma }) {
  return (
    <ColoredButton materialIcon="arrow_downward" greenLayout>
      <CSVLink
        style={{ color: "#fff" }}
        headers={headers}
        data={
          avaliacoesTurma?.map?.((e) => ({
            ...e,
            dataInicio: handleDate(e?.dataInicio).dmyhm,
            dataFim: handleDate(e?.dataFim).dmyhm,
          })) || []
        }
        filename={`${data?.disciplinaFilial?.nome} - ${data?.turma?.nome}.csv`}
        separator={";"}
      >
        Excel
      </CSVLink>
    </ColoredButton>
  );
}

export default ThisExcelButton;
