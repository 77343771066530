import React from "react";

import Card from "../../Card";
import { Container } from "./styles";

function CardList({ stats }) {
  return (
    <Container>
      <Card
        title="Alunos"
        subtitle="Total de alunos."
        color="#FF4031"
        cardNumber={1}
        number={stats?.totalAlunos}
      />
      <Card
        title="Avaliações disponibilizadas"
        subtitle="Total de avaliações da turma."
        color="#4FA754"
        cardNumber={2}
        number={stats?.totalAvaliacoesDisponibilizadas}
      />
      <Card
        title="Avaliações corrigidas"
        subtitle="Total de avaliações corrigidas."
        color="#1CA9D0"
        cardNumber={3}
        number={stats?.totalCorrigidas}
      />
    </Container>
  );
}

export default CardList;
