import styled, { keyframes } from "styled-components";
const animateOpacity = keyframes`
    to{
      opacity: 1;
    }
`;
export const Container = styled.div`
  display: flex;
  margin-top: 2.2em;
  opacity: 0;
  animation: ${animateOpacity} 0.5s ease forwards;

  .infor {
    width: 35em;
    padding: 1em 2.5em;
    h1 {
      font-size: 1.4em;
      margin-bottom: 0.5em;
    }
    p {
      font-size: 1em;
      margin-bottom: 1.5em;
    }
    h5 {
      font-size: 1em;
    }
  }
  .img-container {
    overflow: hidden;
    background: #fff;
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.5);
    padding: 1em;
    border-top-left-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    img {
      width: 45em;
    }
  }

  @media (max-width: 993px) {
    .img-container {
      display: none;
    }
    .infor {
      h1 {
        position: absolute;
        top: 5em;
        left: 1em;
        font-size: 1.2em;
        color: #fff;
      }
    }
  }
`;
