import styled from "styled-components";
import Label from "~/componentes/CustomForm/Label";
import Check from "~/componentes/CustomForm/Check";

export const Container = styled.div``;

export const LabelCustom = styled(Label)`
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75em;
  color: #293f57;
  text-transform: uppercase;
  p {
    font-size: 0.8em;
  }
`;
export const AcessField = styled.ul`
  background: #ffffff;
  box-shadow: 0 0.063em 0.625em rgba(0, 0, 0, 0.13);
  border-radius: 0.25em;
  margin: 0.75em 0.125em 0.75em 0.5em;

  .li_primary {
    margin: 0 0.25em 0.75em 0.25em;
    border-bottom: solid #f5f5f5 0.125em;
  }
`;
export const FlexAcess = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5em;

  i.green {
    position: relative;
    color: #4fa754;
    z-index: 1;
    cursor: pointer;
  }
  i.green:hover::before {
    opacity: 1;
  }

  i.green::before {
    position: absolute;
    content: "";
    width: 1.4em;
    height: 1.4em;
    background: #5ba26026;
    left: -0.19em;
    top: -0.188em;
    border-radius: 50%;
    z-index: -1;
    transition: 0.3s ease;
    opacity: 0;
  }

  i.red {
    position: relative;
    color: #ff4031;
    z-index: 1;
    cursor: pointer;
  }
  i.red:hover::before {
    opacity: 1;
  }

  i.red::before {
    position: absolute;
    content: "";
    width: 1.4em;
    height: 1.4em;
    background: #ff403112;
    left: -0.19em;
    top: -0.188em;
    border-radius: 50%;
    z-index: -1;
    transition: 0.3s ease;
    opacity: 0;
  }

  i.hide {
    display: none;
  }
`;
export const CheckBox = styled.div`
  display: flex;
  align-items: center;
  h6 {
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 0.938em;
    line-height: 1em;
    color: #203346ad;
  }
`;
export const CheckInput = styled(Check)`
  margin: 0 0.75em 0 0;
  appearance: none;
  width: 1.25em;
  height: 1.25em;
  background: #d0d7db;
  border-radius: 0.125em;
  transition: 0.3s ease;
  cursor: pointer;
  outline: none;
  border: none;
  box-shadow: none;
  position: relative;

  :hover {
    box-shadow: none;
  }

  :checked {
    background: #1ca9d0;
  }
  :checked ~ h6 {
    color: #293f57;
  }

  ::before {
    position: absolute;
    content: "";
    width: 0.875em;
    height: 0.125em;
    background: #fff;
    -webkit-transform: rotate(-50deg);
    -ms-transform: rotate(-50deg);
    transform: rotate(-50deg);
    top: 9px;
    left: 5px;
    transition: 0.3s ease;
    opacity: 0;
  }
  ::after {
    position: absolute;
    content: "";
    width: 0.375em;
    height: 0.125em;
    background: #fff;
    -webkit-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    transform: rotate(50deg);
    top: 0.689em;
    left: 0.188em;
    transition: 0.3s ease;
    opacity: 0;
  }

  :checked::before,
  :checked::after {
    opacity: 1;
  }
`;
export const SubItens = styled.ul`
  list-style: none;
  overflow-y: hidden;
  height: 0;
  padding: 0 0 0 0;

  &.ativo {
    padding: 0 0 0.5em 1.625em;
    height: auto;
  }

  &.ativo li {
    margin: 0.75em 0;
  }
`;
