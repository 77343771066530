import styled from "styled-components";
import Label from "~/componentes/CustomForm/Label";

export const Container = styled.div`
  .RespostaObjetiva_h4_naoRespondido {
    text-align: center;
  }
`;

export const QuestaoItem = styled(Label)`
  textarea {
    resize: none;
    height: auto;

    ${({ marcado, correto }) => {
      if (correto) {
        return "border-color: #4FA754 !important;";
      } else if (marcado) {
        return "border-color: #FF4031 !important;";
      }
    }}
  }
`;
