import React, { useState } from "react";
import api from "~/services/api";
import { Container } from "./styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";

import CustomScrollBar from "~/componentes/CustomScrollBar";
import { Materialize } from "~/styles/materializeGrid";
import { SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import SistemaTopico from "~/componentes/StyledComponents/Textos/SistemaTopico";
import ItensDiv from "./ItensDiv";

function CaptarContent({ mountedData, series }) {
  const [itens, setItens] = useState(mountedData?.itens);
  const initialItemData = mountedData
    ? { questaoCod: mountedData.cod, enunciado: "", gabarito: false }
    : { questaoCod: 0, enunciado: "", gabarito: false };

  function handleSubmit(data, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let response;
        let thisDataRequest = {
          ...data,
          itens,
          enunciado: mountedData?.enunciado,
          ...(mountedData ? { cod: mountedData.cod } : {}),
        };

        response = await api.post("admin/questao", thisDataRequest);

        if (response?.status === 200) {
          closeReload && closeReload();
        }

        return response;
      },
      {
        successModal: `Questão ${mountedData ? "atualizada" : "criada"} com sucesso.`,
        texto: `Deseja ${mountedData ? "atualizar" : "criar"} essa Questão?`,
        errorModal: {
          defaultMessage: `Não foi possível ${mountedData ? "atualizar" : "criar"} essa questão, por favor, tente novamente.`,
        },
      }
    );
  }
  return (
    <Container onSubmit={handleSubmit}>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          <div className="row">
            <SistemaTopico>Dados Questão</SistemaTopico>
            <Label title="TÍTULO:" className="col s12 m12 l8">
              <SistemaInput name="nome" defaultValue={mountedData?.nome} required readOnly />
            </Label>
            <Label title="EMPRESA: (NOME FANTASIA)" text={mountedData?.filial?.nomeFantasia || ""} className="col s12 m12 l4" />
            <Label title="DISCIPLINA:" className="col s12 m12 l4">
              <SistemaSelect
                name="disciplinaCod"
                list={{
                  list: [
                    {
                      cod: mountedData?.disciplinaFilial?.disciplina?.cod,
                      nome: mountedData?.disciplinaFilial?.disciplina?.nome,
                    },
                  ],
                  value: "cod",
                  label: "nome",
                }}
                defaultValue={mountedData?.disciplinaFilial?.disciplina?.cod}
                required
                readOnly
              />
            </Label>
            <Label title="NÍVEL DA QUESTÃO:" className="col s12 m12 l4">
              <SistemaSelect
                name="questaoNivelCod"
                list={{
                  list: [{ cod: mountedData?.nivel?.cod, nome: mountedData?.nivel?.nome }],
                  value: "cod",
                  label: "nome",
                }}
                defaultValue={mountedData?.nivel?.cod}
                required
                readOnly
              />
            </Label>
            <Label title="SÉRIE:" className="col s12 m12 l4">
              <SistemaSelect
                name="serieCod"
                firstOption
                list={{
                  list: series,
                  value: "cod",
                  label: "nome",
                }}
                required
              />
            </Label>
            <Label title="ENUNCIADO:" className={mountedData?.arquivo?.url ? "col s12 m12 l8" : "col s12 m12 l12"}>
              <SistemaTextArea name="enunciado" defaultValue={mountedData?.enunciado} required readOnly />
            </Label>
            {mountedData?.arquivo?.url && (
              <Label title="IMAGEM:" className="col s12 m12 l4 label_preview">
                <img className="label_preview_image" src={mountedData?.arquivo?.url} alt="ARQUIVO" />
              </Label>
            )}
            <SistemaTopico>Dados Itens</SistemaTopico>
            <Label className="col s12 m12 l12 questoes_lista_item">
              <ItensDiv itens={itens} initialItemData={initialItemData} setItens={setItens} />
            </Label>
          </div>
        </Materialize>
      </CustomScrollBar>
      <SLModalFooter />
    </Container>
  );
}

export default CaptarContent;
