import styled from "styled-components";
import { Scrollbars } from "react-custom-scrollbars";
import Banner from "~/assets/wallpapers/ceu.png";

const bg_color1 = "#293F57"; // #fff
const bg_color2 = "#fff"; // #fff
const fn_color1 = "#fff"; // #7b898c
const fn_color2 = "#fff"; // #293F57
const fn_color3 = "#293F57"; // #293F57

export const Container = styled.div`
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  background: #f7f8fa;
  ${(props) =>
    props.desktop &&
    `
    display: grid;
    grid-template-columns: auto 1fr;
  `}
  color: #293f57;

  .usuario {
    display: flex;
    ${(props) =>
      props.desktop ? "justify-content: space-between;" : "font-size: 0.8em;"}
    align-items: center;

    .avatar {
      border: none;
      border-radius: 50%;
      color: #fff;
      background: #ff4031;
      font-size: 1.5em;
      min-width: 1.2em;
      line-height: 1;
      height: 1.5em;
      width: 1.5em;
      display: flex;
      ${(props) => !props.desktop && "margin: auto 0.5em;"}
      justify-content: center;
      align-items: center;
      transition: 200ms;
    }

    .identificacao {
      width: ${(props) => (props.desktop ? "12em" : "8em")};
      color: ${fn_color2};

      > p {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .cargo {
        font-size: 0.9em;
      }
    }
    @media (max-width: 993px) {
      .identificacao {
        display: none !important;
      }
    }
  }
`;

export const Content = styled.div`
  height: 100%;

  .topMenu {
    background: ${bg_color2};
    height: 4em;
    display: flex;
    align-items: center;
    box-shadow: 1em -0.625em 1.125em rgba(0, 0, 0, 0.25);
    position: relative;
    padding: 0.5em;
    color: ${fn_color3};

    strong {
      display: flex;
      margin-left: 0.5em;
    }
    p {
      display: flex;
      margin-left: 0.5em;
    }
    .logo {
      height: 50%;
      margin: auto;
    }

    .topMenu_div_botoes {
      display: flex;
      height: 2em;
      width: fit-content;
      justify-content: space-between;
      align-items: center;
      margin-left: -1em;
    }

    .modulos {
      position: absolute;
      overflow-x: hidden;
      overflow-y: hidden;
      top: 100%;
      left: 0;
      padding-top: 1em;
      padding-bottom: 0.5em;
      width: 100%;
      height: 90vh;
      background: ${bg_color1};

      box-shadow: 0.3em 0.3em 0.3em #0003;
    }
    @media (max-width: 993px) {
      strong,
      .brackpoints {
        display: none !important;
      }
    }
  }
`;

export const Main = styled(Scrollbars)`
  display: flex;
  justify-content: center;
  /* background-image: url(${Banner}); */

  .mainDiv {
    width: 97%;
    position: relative;
    z-index: 0;
    margin: 1em auto;
    margin-bottom: 5em;
  }
`;

export const LeftMenu = styled.div`
  background: ${bg_color1};
  box-shadow: 2px 0px 18px rgba(0, 0, 0, 0.25);
  max-height: 100vh;
  display: flex;
  /* z-index: 2; */
  flex-flow: column;
  width: ${(props) => (props.showLateralMenu ? "20em" : "5em")};

  .usuario,
  .menuPrincipal {
    margin: 1em 1em 0.75em;
  }

  .menuPrincipal {
    margin: 1em 1.5em 1em;
    color: ${fn_color1};
    font-size: 0.8em;
  }
`;
