import styled from "styled-components";
import { SLForm } from "~/pages/Sistema/componentes/SistemaList";

export const Container = styled(SLForm)`
  width: 65em;
  max-width: 100%;
`;

export const SubTitle = styled.h4`
  color: #bbb5b5;
  line-height: 1.5em;
  font-weight: 600;
  font-size: 0.8em;
`;

export const FlexDiv = styled.div`
  display: flex;
  gap: 20px;

  .wrapper-input {
    margin: 0 0.25em;
    width: 100%;
  }

  .btn-search {
    display: flex;

    align-items: center;

    justify-content: center;
    width: 3.325em;
    height: 2em;
    margin-left: 1em;
    border: none;
    outline: none;
    padding: 0 0.5em;
    background-color: #ffda44;
    color: #fff;
    box-shadow: 0 0.3em 0 #cba81c;
    border-radius: 0.2em;
  }
  .btn-search:active {
    transform: translateY(0.3em);

    box-shadow: none;
  }
`;