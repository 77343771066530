import styled from "styled-components";

export const Container = styled.div`
  width: 40em;
  max-width: 100%;

  .Label_inputWithButton {
    display: flex;
    align-items: center;

    button {
      padding: 0.25em 0.5em;
      margin-bottom: 0.4em;
      margin-left: 0.25em;
    }
  }
`;
