import styled from "styled-components";
import Select from "../..";

const ThisSistemaSelect = styled(Select)`
  font-size: 1em;
  line-height: 1;
  height: 2.4em;
  border-radius: 0.375em;
  border: 0.125em solid #cedde0;
  width: fit-content;
  max-width: 100%;
  background: #fff;
  padding: 0em 0.5em;

  :focus,
  :hover {
    box-shadow: 0 0 0.5em #00000011;
  }

  :disabled {
    background: #dedede;
    border: none;
  }
`;

export default ThisSistemaSelect;
