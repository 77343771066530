import React from "react";
import PreviewFileInput from "~/componentes/CustomForm/FileInput/styles/PreviewFileInput";
import Label from "~/componentes/CustomForm/Label";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import Pagination from "~/componentes/Pagination";
import { Materialize } from "~/styles/materializeGrid";
import QuestoesPagination from "./QuestoesPagination";
import RespostaQuestao from "./RespostaQuestao";

import { Container } from "./styles";

function EntregueModal({ avaliacaoQuestao: avaliacaoQuestaoProp, mountedData, outClick }) {
  const [questao, setQuestao] = React.useState();
  // const [nextPage, setNextPage] = React.useState(() => () => {});
  const { respostaQuestoes, corrigido, expirado } = mountedData || {};

  const avaliacaoQuestao = React.useMemo(() => avaliacaoQuestaoProp?.filter((e) => e?.cod && e?.questao) || [], [avaliacaoQuestaoProp]);

  const respostaQuestao = React.useMemo(() => {
    return respostaQuestoes?.find((e) => {
      return e?.avaliacaoQuestaoCod === questao?.avaliacaoQuestaoCod;
    });
  }, [respostaQuestoes, questao]);

  const showResp = React.useMemo(() => {
    return corrigido && expirado;
  }, [corrigido, expirado]);

  const respostaPontuacao = React.useMemo(() => {
    const respPont = respostaQuestao?.pontuacao || 0;
    const qstPont = questao?.pontuacao || 0;

    if (respPont < 0) {
      return 0;
    } else if (respPont > qstPont) {
      return qstPont;
    } else {
      return respPont;
    }
  }, [questao, respostaQuestao]);

  return (
    <Container scrollBar footerButtons={{ cancelButton: { text: "Voltar" }, submitButton: false }} title="Avaliação" {...{ outClick }}>
      <Pagination
        size={1}
        data={avaliacaoQuestao}
        getInfo={({ pageData }) => {
          const avq = pageData?.[0];
          if (avq?.questao && avq?.cod) {
            setQuestao({
              ...avq.questao,
              avaliacaoQuestaoCod: avq.cod,
              pontuacao: avq?.pontuacao,
            });
          } else {
            setQuestao();
          }
        }}
      >
        {({ page, maxPage, setPage, setHoldingButton }) => (
          <QuestoesPagination
            {...{
              page,
              maxPage,
              setPage,
              showResp,
              // setNextPage,
              avaliacaoQuestao,
              respostaQuestoes,
              setHoldingButton,
            }}
          />
        )}
      </Pagination>
      <Materialize>
        <div className="row">
          <Label className={`col s12 m6 ${showResp ? "l6" : "l9"}`} title="Título:" text={questao?.nome || ""} />
          {showResp && <Label className="col s12 m3 l3" title="Pontuação:" text={respostaPontuacao} />}
          <Label className="col s12 m3 l3" title="Pontuação Máx.:" text={questao?.pontuacao || "0"} />
          <Label className={`col s12 m12 ${questao?.arquivo?.url ? "l8" : "l12"}`} title="Enunciado:">
            <SistemaTextArea style={questao?.arquivo?.url && { height: "10em" }} readOnly value={questao?.enunciado || ""} />
          </Label>
          {questao?.arquivo?.url && (
            <Label title="Imagem:" className="col s12 m12 l4 label_preview">
              <PreviewFileInput maxHeight="10em" defaultPreview={questao?.arquivo?.url} readOnly />
            </Label>
          )}
          {showResp && respostaQuestao?.correcao && (
            <Label className="col s12 m12 l12" title="Correção:">
              <SistemaTextArea
                readOnly
                value={respostaQuestao?.correcao || ""}
              />
            </Label>
          )}
          <RespostaQuestao
            {...{ questao, showResp, respostaQuestao, mountedData }}
          />
        </div>
      </Materialize>
    </Container>
  );
}

export default EntregueModal;
