/* eslint-disable no-useless-computed-key */
import React, { useState, useLayoutEffect } from "react";

import api from "~/services/api";
import ShowContent from "./ShowContent";
import { SLShowModal } from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import TableComponent from "./TableComponent";

function TipoConquista() {
  const [mountedData, setMountedData] = useState();
  const [periodosLetivos, setPeriodosLetivos] = useState([]);
  const [series, setSeries] = useState([]);
  const [tabFilter, setTabFilter] = useState({ ativo: true });

  useLayoutEffect(() => {
    api.get("professor/series").then((response) => setSeries(response?.data || []));

    api
      .get("professor/periodosLetivos", { params: { ativo: true } })
      .then((response) => setPeriodosLetivos(response?.data || []));
  }, []);

  return (
    <Container
      requests={{
        findAll: () => api.get("professor/turmas"),
        findOne: (thisData) => api.get("professor/turma/alunos", { params: { cod: thisData.cod } }),
      }}
      inputs={[
        { filter: "nome", label: "Nome" },
        { filter: "serie.nome", label: "Série" },
        { filter: "periodoLetivo.nome", label: "Período Letivo" },
      ]}
      selects={[
        {
          filter: "periodoLetivoCod",
          list: periodosLetivos,
          outLabel: "Período Letivo",
          value: "cod",
          label: "nome",
        },
        {
          filter: "serieCod",
          list: series,
          outLabel: "Série",
          value: "cod",
          label: "nome",
        },
      ]}
      dataFilter={tabFilter}
    >
      <TableComponent {...{ setTabFilter }} />

      <SLShowModal title="Turma" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>
    </Container>
  );
}

export default TipoConquista;
