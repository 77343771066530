import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import formCheckDisabled from "../utils/formCheckDisabled";
import { memo } from "react";
import CheckWrapperStore, {
  CheckWrapperHandleData,
} from "../CheckWrapper/CheckWrapperStore";
import formFindKeyValue from "../utils/formFindKeyValue";
import { IsFunction } from "~/utils/CheckDataType";

/**
 * @param {Object} props Aceita todas as props de um componente React.
 * @param {string} props.name É utilizado como chave de um JSON. Caso não tenha um name, toda alteração de Check,
 *  irá sobrepor o valor do CheckWrapper. Caso seja passado um name, ele apenas irá sobrepor caso possua o mesmo name.
 *  Sendo utilizado para formulários de múltiplas escolhas.
 * @param {string} props.value Informe o valor que será utilizado quando o elemento for selecionado.
 * @param {Boolean} props.defaultHtml Transformará o Check em um checkBox padrão do html, logo, não aceita as demais props customizadas.
 * @param {string} props.type Padrão "checkBox". Usado apenas se for passado o defaultHtml.
 * @param {Boolean} props.checked Torna o estado de check controlado de forma externa.
 * @param {Boolean} props.defaultChecked Configura apenas o estado do checked de renderização.
 * @param {Boolean} props.assistChecked Semelhante ao checked, porém não toma controle completo, apenas altera o estado do checked caso o valor do assistedCheck altere. A propriedade reloadDependency é utilizada para restaurar o estado do checked igual ao informado no assistChecked, podendo servir como um defaultChecked com uma condição de reload.
 * @param {any} props.reloadDependency Reinicia os estados de check.
 * @param {Function} props.onChange
 * @param {Function} props.checkedCondition Recebe o estado dos dados como argumento. Caso não seja passado a propriedade checked, pode ser adicionada uma condição que auxiliará a alteração de estados.
 * @param {Boolean} props.checkAllArray Caso o check possua um array como seu value, ele se mantém marcado caso algum de seus valores exista no estado. Caso essa opção seja true, só será marcado caso todos os valores do array existam no estado.
 * @param {Boolean} props.disabled Impede que o estado seja alterado.
 * @param {Function} props.setter Função que recebe o valor do check caso esteja confirmado ou null caso não esteja.
 * @param {Boolean} props.noLoading Se true, manterá o elemento ativo mesmo que um loading esteja ocorrendo.
 * @param {Object} props.inputProps Utilizado caso esteja usando um Check estilizado. Suas versões estilizadas utilizam um input e um span dentro de um elemento label.
 * @param {Object} props.spanProps Utilizado caso esteja usando um Check estilizado. Suas versões estilizadas utilizam um input e um span dentro de um elemento label.
 */
function Check({
  defaultHtml,
  setStyle,
  type = "checkBox",
  inputClass,
  className,
  labelProps,
  spanProps,
  ...rest
}) {
  const RenderCheck = defaultHtml ? DefaultCheck : CustomCheck;

  return setStyle ? (
    <label className={className} {...labelProps}>
      <RenderCheck
        type={defaultHtml ? type : "checkBox"}
        {...rest}
        className={`Check_styledInput ${inputClass}`}
      />
      <span {...spanProps} className={`Check_styledSpan ${spanProps?.className}`} />
    </label>
  ) : (
    <RenderCheck type={defaultHtml ? type : "checkBox"} className={className} {...rest} />
  );
}

function DefaultCheck(props) {
  const { setter, checked, innerRef, defaultChecked, onChange, ...rest } = props || {};

  return (
    <input
      ref={(e) => IsFunction(innerRef) && innerRef(e)}
      checked={checked}
      defaultChecked={defaultChecked}
      onChange={(e) => {
        setter && setter(e.currentTarget.checked ? props?.value : null);
        onChange && onChange(e);
      }}
      {...rest}
    />
  );
}

function CustomCheck(props) {
  const {
    name,
    value = true,
    checked,
    innerRef,
    assistChecked,
    defaultChecked,
    checkedCondition,
    checkAllArray,
    isArray: isArrayProp,
    setter,
    disabled,
    // reloadDependency: propsReload,
    ...rest
  } = props || {};

  const { state, dispatch } = React.useContext(CheckWrapperStore);
  const disableButtons = useSelector((reduxState) => reduxState.disableButtons);
  const { unselectable, reloadDependency, isArray, data, disabledData } = state;
  const [selected, setSelected] = useState(false);
  const [loadedDefaultChecked, setLoadedDefaultChecked] = useState(false);
  const [loadedChecked, setLoadedChecked] = useState(false);
  const [loadedAssistChecked, setLoadedAssistChecked] = useState(false);

  const handleData = React.useCallback(
    (check, event) => {
      check = event && unselectable ? true : check;
      dispatch(CheckWrapperHandleData({ value, name, isArrayProp }, check));
      !event && setter && setter(check ? value : null);
    },
    [dispatch, isArrayProp, name, setter, unselectable, value]
  );

  useEffect(() => {
    setLoadedAssistChecked(false);
    setLoadedDefaultChecked(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadDependency]);

  const checkValue = React.useCallback(
    (thisData) => {
      if (loadedChecked || checked) {
        return !!checked;
      } else {
        const checkObjArray = formFindKeyValue(name, thisData);
        if (IsFunction(checkedCondition)) {
          return checkedCondition(thisData);
        }

        if (Array.isArray(checkObjArray)) {
          if (Array.isArray(value)) {
            if (checkAllArray) {
              return value.every((r) => checkObjArray.includes(r));
            } else {
              return value.some((r) => checkObjArray.includes(r));
            }
          } else if (isArray || isArrayProp) {
            return checkObjArray.includes(value);
          }
          return false;
        }

        return checkObjArray === value;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkAllArray, checkedCondition, isArray, isArrayProp, name, value]
  );

  const checkSelect = React.useMemo(() => checkValue(data), [checkValue, data]);
  const checkDisabled = React.useMemo(() => disabledData && checkValue(disabledData), [
    checkValue,
    disabledData,
  ]);

  useEffect(() => {
    if (!loadedChecked && !checked) {
      if (selected && !checkSelect) {
        setSelected(false);
      } else if (!selected && checkSelect) {
        setSelected(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkSelect]);

  useEffect(() => {
    if (!loadedChecked && checked) {
      setLoadedChecked(true);
    } else if (loadedChecked) {
      handleData(checked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, loadedChecked]);

  useEffect(() => {
    if (!loadedDefaultChecked && defaultChecked && !!defaultChecked !== !!selected) {
      handleData(!!defaultChecked);
      setLoadedDefaultChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultChecked, loadedDefaultChecked]);

  useEffect(() => {
    if (!loadedAssistChecked && assistChecked) {
      setLoadedAssistChecked(true);
    } else if (loadedAssistChecked && assistChecked !== selected) {
      handleData(assistChecked);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assistChecked, loadedAssistChecked]);

  return (
    <input
      {...rest}
      ref={(e) => IsFunction(innerRef) && innerRef(e)}
      name={name}
      value={value}
      checked={loadedChecked || checked ? checked : selected}
      disabled={checkDisabled || formCheckDisabled(props, disableButtons)}
      onChange={(e) => {
        const newCheck = e.currentTarget.checked;
        if (!loadedChecked) {
          handleData(newCheck, true);
        }
        setter && setter(newCheck ? value : null);
        props?.onChange && props.onChange(e);
      }}
    />
  );
}

export default memo(Check);
