import React from "react";

import { Container } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { succesToggle } from "~/store/modules/successModal/actions";
import { modalTimeout } from "~/config/definicoesPadrao";

export default function SuccessModal(props) {
  const [closeAnimation, setCloseAnimation] = React.useState(() => () => {});
  const { ativo, message } = useSelector((state) => state.successModal);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setTimeout(() => ativo && closeAnimation(), modalTimeout);
  }, [ativo, closeAnimation]);

  return (
    <Container
      checkRedux={false}
      outClickAnimation={setCloseAnimation}
      outClick={() => dispatch(succesToggle())}
      {...props}
    >
      <div className="successContent">
        <div className="successIcon">
          <i className="material-icons">check</i>
        </div>
        <p className="SuccessModal_p_message">{message}</p>
      </div>
    </Container>
  );
}
