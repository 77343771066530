import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin-bottom: 1em;

  @media only screen and (max-width: 993px) {
    grid-template-columns: 1fr;
  }
`;
