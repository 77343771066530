import React from "react";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";
import getYoutubeEmbebedUrl from "~/utils/getYoutubeEmbebedUrl";

// import { Container } from './styles';

function AulaLinks({ readonly, links, setLinks }) {
  const [loadedDefault, setLoadedDefault] = React.useState(false);
  const editable = !readonly && setLinks && typeof setLinks === "function";
  const showVideos = editable || (Array.isArray(links) && links.length > 0);

  React.useEffect(() => {
    if (!loadedDefault) {
      setLoadedDefault(true);
      if (!(links?.length > 0) && editable) {
        setLinks([{ link: "" }]);
      }
    }
  }, [editable, links, loadedDefault, setLinks]);

  return (
    <div>
      {showVideos && (
        <div style={{ marginTop: "0.5em" }} className="col s12 m12 l12 Label_inputWithButton">
          <Label style={{ display: "flex" }} title="Vídeos do Youtube:" />
          {editable && (
            <ColoredButton
              style={{ display: "flex", marginLeft: "auto" }}
              blueLayout
              type="button"
              materialIcon="add_circle"
              onClick={() =>
                setLinks((prev) => [
                  ...prev,
                  {
                    link: undefined,
                  },
                ])
              }
            />
          )}
        </div>
      )}
      {links?.map?.((link, index) => {
        const { embebedUrl } = getYoutubeEmbebedUrl(link?.link);

        return editable ? (
          <div key={index} className="col s12 m12 l12 Label_inputWithButton">
            <SistemaInput
              style={{ width: "100%", marginBottom: "0.25em" }}
              // required
              // pattern="^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$"
              // title="Informe a url corretamente"
              // value={link?.link || ""}
              value={link?.link}
              placeholder="Exemplo: https://www.youtube.com/"
              // value={link?.link || "https://www.youtube.com/"}
              onChange={(e) => {
                const value = e.currentTarget.value;
                const newLink = [...links];

                newLink[index] = {
                  link: value,
                };
                setLinks(newLink);
              }}
            />
            <ColoredButton
              redLayout
              type="button"
              materialIcon="remove_circle_outline"
              onClick={() => setLinks((prev) => prev.splice(index, 1) && [...prev])}
            />
          </div>
        ) : (
          embebedUrl && (
            <div style={{ width: "100%" }}>
              <iframe style={{ margin: "auto", display: "block" }} width="420" height="315" title={link.link} src={embebedUrl}></iframe>
            </div>
            // <a key={index} className="col s12 m12 l12 Label_inputWithButton" href={`//${link.link}`} rel="noopener noreferrer" target="_blank">
            //   {link.link}
            // </a>
          )
        );
      })}
    </div>
  );
}

export default AulaLinks;
