import styled from "styled-components";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";

export const Container = styled(SistemaComponenteInterno)`
  height: 100%;

  h1,
  h2 {
    text-align: center;
    margin-bottom: 1em;
  }

  .selectMatricula {
    display: flex;
    align-items: center;
    margin-bottom: 2em;

    p {
      margin-right: 0.5em;
    }

    /* select {
      border: 0.1em solid #1f3346;
      color: #1f3346;
      font-weight: 550;
      background: #fff;
      text-align: center;
      height: 2.5em;
      font-size: 0.8em;
      padding-left: 0.5em;
      padding-top: 0.1em;
      border-radius: 0.5em;
    } */
  }

  .formularios {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    > div {
      flex: 1;
      margin: 1em 2em 2em 0;
      flex-basis: 40%;
      max-width: 100%;

      h3 {
        margin-bottom: 1em;
      }
    }
  }

  .formulario {
    display: flex;
    flex-flow: row wrap;
    flex: 1;
  }

  .switchButton {
    padding: 0 1em;
    width: fit-content;
    display: flex;
    flex: 1;
    width: 12em;
    max-width: 12em;
    justify-content: space-between;
    margin: 1em 0.5em;
    align-items: center;
    border-bottom: 0.1em solid #eee;

    p {
      width: 7em;
    }
  }

  .confirmar {
    border-top: solid 0.1em #dedede;
    padding-top: 0.5em;
    display: flex;
    justify-content: center;
  }
`;
