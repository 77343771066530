import styled, { keyframes } from "styled-components";
const arrow1 = keyframes`
  from{
    opacity: 0;
    transform: translateY(0em);
      }
  to{
    opacity: 1;
    transform: translateY(0.2em)
  }
`;

export const Container = styled.div`
  width: 22em;
  padding-top: 1em;
  display: flex;
  flex-direction: column;
  color: #fff;
  text-align: center;
  border-radius: 1em;

  span.arrow,
  h1,
  p {
    color: #333;
    margin-bottom: 0.5em;
  }
  h1 {
    transform: translateX(-1em);
    font-size: 1.6em;
    margin-bottom: 0.5em;
  }
  p {
    transform: translateX(-1em);
  }
  .arrows {
    position: relative;
    width: 18em;
    height: 0.1em;
    margin-left: 0em;
  }
  span.arrow {
    font-size: 1.8em;
    position: absolute;
    animation: ${arrow1} 1.4s ease infinite;
  }

  img {
    transform: translateX(-1em);
    margin-top: 2.5em;
    height: 4.2em;
  }
  .btn-field {
    width: 90%;
    display: flex;
    justify-content: center;

    .and {
      margin-right: 0.4em;
    }
  }

  @media (max-width: 993px) {
    .arrows {
      position: relative;
      width: 17em;
      height: 0.1em;
    }
    & {
      padding-left: 2em;
      align-items: center;
    }
    img {
      margin-top: 2em;
    }
    h1 {
      position: absolute;
      top: -3em;
      left: 1em;
      font-size: 1.2em;
      color: #fff;
    }
    p.p1 {
      margin-top: 5em;
    }
  }
  @media (max-width: 350px) {
    p.p1 {
      width: 90%;
    }
  }
`;
