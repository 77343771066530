import React, { useReducer, memo } from "react";
import CheckWrapperReducer from "./CheckWrapperStore/reducer";
import CheckWrapperStore, {
  CheckWrapperReloadDependency,
  CheckWrapperDefaultData,
  CheckWrapperDisabledList,
  CheckWrapperSelectedDisabledList,
} from "./CheckWrapperStore";
import { useEffect } from "react";
import formStore from "../formStore";
import formHandleOnChange from "../utils/formHandleOnChange";
import PassByValue from "~/utils/PassByValue";

/**
 * É uma div (padrão) utilizada para envolver os componentes Check de forma que gerencia seus estados
 * par que seja possível enviar para o Form, além de poder ser utilizada junto de um setter sem um Form.
 * @param {Object} props
 * @param {string} props.name Usado como chave do objeto caso esteja envolvido por um Form. Caso não seja passado um name, ele não fará parte do Form.
 * @param {Boolean} props.allowNull Usado caso esteja envolvido por um Form, se true, a chave é apagada do objeto caso esteja com value null.
 * @param {Function} props.setter Recebe uma função que é usada como argumento o estado dessos Checks.
 * @param {Boolean} props.isArray Adiciona os Checks seguindo uma estrutura de array. Não precisa passar name no check.
 * @param {Boolean} props.unselectable Remove a seleção apenas se outra for selecionada.
 * @param {any} props.setType Padrão "div". Caso seja adicionado outro, irá alterar o elemento.
 * @param {Boolean} props.reloadDependency Adiciona um estado como dependencia forçando que o os Checks voltem ao seu estado inicial.
 * @param {Function} props.handleFormData Recebe como argumento o estado, e será enviado para o form apenas o retorno dessa função.
 * @param {Boolean} props.keepEmptyArray Se true, Array vazio não será tratado como null.
 * @param {any} props.defaultData Informa o estado inicial que será usado tanto pro form como para preencher os checks.
 * @param {any} props.disabledData Informa o estado inicial que será usado para preencher os disableds dos checks.
 * @param {any} props.defaultDisabledData Informa o estado inicial que será usado para preencher os check com disabled e select.
 */
function CheckWrapper(props) {
  const {
    defaultData,
    notForm,
    disabledData,
    defaultDisabledData,
    children,
    name,
    setter,
    keepEmptyArray,
    reloadDependency,
    isArray,
    allowNull,
    handleFormData,
    setType,
    unselectable,
    ...rest
  } = props;
  const { dispatch: formDispatch } = React.useContext(formStore);
  const ThisElement = setType ? setType : "div";

  const [state, dispatch] = useReducer(CheckWrapperReducer, {
    unselectable,
    isArray,
    reloadDependency,
    data: isArray ? [] : null,
  });

  useEffect(() => {
    let thisVal = defaultData;

    if (!thisVal) {
      if (defaultDisabledData) {
        thisVal = defaultDisabledData;
      } else if (isArray) {
        thisVal = [];
      }
    }

    dispatch(CheckWrapperDefaultData(PassByValue(thisVal)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultData, reloadDependency]);

  useEffect(() => {
    if (defaultDisabledData) {
      dispatch(CheckWrapperSelectedDisabledList(PassByValue(defaultDisabledData)));
    }
  }, [defaultDisabledData]);

  const disableds = React.useMemo(() => defaultDisabledData || disabledData, [
    disabledData,
    defaultDisabledData,
  ]);

  useEffect(() => {
    if (disableds) {
      dispatch(CheckWrapperDisabledList(PassByValue(disableds)));
    }
  }, [disableds]);

  useEffect(() => {
    let newData = PassByValue(state.data);

    if (!keepEmptyArray && !(newData?.length > 0)) {
      newData = null;
    }

    formHandleOnChange(newData, props, formDispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    dispatch(CheckWrapperReloadDependency(reloadDependency));
  }, [reloadDependency]);

  return (
    <ThisElement {...rest}>
      <CheckWrapperStore.Provider value={{ state, dispatch }}>
        {children}
      </CheckWrapperStore.Provider>
    </ThisElement>
  );
}

export default memo(CheckWrapper);
