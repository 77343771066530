import styled from "styled-components";
import SistemaList from "~/pages/Sistema/componentes/SistemaList";

export const Container = styled(SistemaList)`
  .pai-lojavirtual-card {
    display: flex;
    flex-wrap: wrap;
  }

  .pai-lojavirtual-card .lojavirtual-card {
    flex: 0 1 calc(33.33%);
    padding: 0.5em 0.5em;
  }

  @media (max-width: 1200px) {
    .pai-lojavirtual-card .lojavirtual-card {
      flex: 0 1 calc(50%);
    }
  }

  @media (max-width: 520px) {
    .pai-lojavirtual-card .lojavirtual-card {
      flex: 0 1 calc(100%);
    }
  }

  .pai-lojavirtual-card .lojavirtual-card .lojavirtual-card-box {
    padding: 0.5em 1em;
    height: 25em;
    border-radius: 0.5em;
    box-shadow: 0px 0.05em 1em rgba(0, 0, 0, 0.09);
  }

  .pai-lojavirtual-card .lojavirtual-card .lojavirtual-card-box.desativo {
    background: #e0e0e0;
  }

  .pai-lojavirtual-card .lojavirtual-card-box .lojavirtual-card-head {
    width: 100%;
    height: 4em;
    padding: 0.6em 0;
    border-bottom: 0.01em solid #dedeed;
  }

  .pai-lojavirtual-card .lojavirtual-card-box.desativo .lojavirtual-card-head {
    border-bottom: 0.01em solid #c5c5c5;
  }

  .pai-lojavirtual-card .lojavirtual-card-box .lojavirtual-card-head .title-card {
    font-weight: 600;
  }

  .pai-lojavirtual-card .lojavirtual-card-box .lojavirtual-card-head .sub-title-card {
    font-weight: 500;
    color: #a0a0a0;
    font-size: 0.8em;
  }

  .pai-lojavirtual-card .lojavirtual-card-box .lojavirtual-card-body {
    padding: 1em;
    height: 16em;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pai-lojavirtual-card .lojavirtual-card-box .lojavirtual-card-body img {
    max-width: 100%;
    max-height: 100%;
  }

  .pai-lojavirtual-card .lojavirtual-card-box .lojavirtual-card-footer {
    border-top: 0.01em solid #dedeed;
    height: 4em;
    padding: 0.7em 0;
  }

  .pai-lojavirtual-card .lojavirtual-card-box.desativo .lojavirtual-card-footer {
    border-top: 0.01em solid #c5c5c5;
  }

  .pai-lojavirtual-card .lojavirtual-card-box .lojavirtual-card-footer .card-estrelas {
    min-width: 5em;
    border-radius: 0.6em;
    background: #1f3346;
    color: #fff;
    font-weight: 600;
    font-size: 1.1em;
    padding: 0.5em;
    float: left;
  }

  .pai-lojavirtual-card .lojavirtual-card-box .lojavirtual-card-footer .card-estrelas p {
    line-height: 22px;
  }

  .pai-lojavirtual-card .lojavirtual-card-box .lojavirtual-card-footer .card-estrelas img {
    margin-right: 0.6em;
    float: left;
  }

  .pai-lojavirtual-card .lojavirtual-card-box .lojavirtual-card-footer .card-action {
    float: right;
    margin-top: 0.65em;
  }
`;
