import styled from "styled-components";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";

export const Container = styled(SistemaFixedModal)`
  z-index: 999;
  width: 20em;

  .errorContent {
    display: flex;
    border-bottom: 0.1em solid #f5f5f5;
    margin: 0.5em;
    padding: 0.5em;
  }

  .errorIcon {
    min-width: 3em;
    width: 3em;
    min-height: 3em;
    height: 3em;
    border-radius: 50%;
    color: #9fb0af;
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1em;

    i {
      font-size: 2.5em;
      line-height: 1;
    }
  }

  .ErrorModal_p_message {
    display: flex;
    align-items: center;
  }
  /* } */
`;
