import React from "react";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import api from "~/services/api";
import Label from "~/componentes/CustomForm/Label";
import AcessosList from "~/componentes/AcessosList";

import { Container } from "./styles";

function CreateEditContent({ modulos, mountedData }) {
  function handleSubmit(editedData, reload) {
    return ShowConfirmacao(
      async () => {
        let response;

        if (mountedData) {
          response = await api.put("admin/cargo", {
            ...editedData,
            cod: mountedData.cod,
          });
        } else {
          response = await api.post("admin/cargo", {
            ...editedData,
          });
        }

        if (response?.status === 200) {
          reload();
        }
        return response;
      },
      {
        message: `Tem certeza que deseja ${
          mountedData ? "atualizar" : "criar"
        } esse cargo?`,
        errorModal: {
          defaultMessage: `Ocorreu um erro ao ${
            mountedData ? "atualizar" : "criar"
          } esse cargo, por favor, tente novamente.`,
        },
        successModal: `Cargo ${mountedData ? "atualizado" : "criado"} com sucesso!`,
      }
    );
  }

  return (
    <Container defaultStyle onSubmit={handleSubmit}>
      <Label title="NOME:">
        <SistemaInput name="nome" placeholder={mountedData?.nome} />
      </Label>
      <AcessosList defaultSelect={mountedData?.acessos} modulos={modulos} />
    </Container>
  );
}

export default CreateEditContent;
