import React, { useState } from "react";

import api from "~/services/api";
import CreateEditContent from "./CreateEditContent";
import ShowContent from "./ShowContent";
import {
  SLCreateModal,
  SLShowModal,
  SLEditModal,
} from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import TableComponent from "./TableComponent";

function CategoriaLojaVirtual() {
  const [mountedData, setMountedData] = useState();
  const [tabFilter, setTabFilter] = useState({ status: 1 });

  return (
    <Container
      requests={{
        findAll: () =>
          api.get("admin/categoriaslojavirtual", { params: { paranoid: false } }),
        deleteOne: (thisData) =>
          api.delete("admin/categorialojavirtual", { data: { cod: thisData.cod } }),
        restoreOne: (thisData) =>
          api.post("admin/categorialojavirtual/restaura", {
            data: { cod: thisData.cod },
          }),
        findOne: (thisData) =>
          api.get("admin/categorialojavirtual", { params: { cod: thisData.cod } }),
      }}
      dataFilter={tabFilter}
    >
      <TableComponent {...{ setTabFilter }} />

      <SLShowModal title="Categoria da Loja Virtual" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLEditModal title="Atualizar Categoria da Loja Virtual" setter={setMountedData}>
        <CreateEditContent {...{ mountedData }} />
      </SLEditModal>

      <SLCreateModal title="Nova Categoria da Loja Virtual">
        <CreateEditContent />
      </SLCreateModal>
    </Container>
  );
}

export default CategoriaLojaVirtual;
