export default function CheckDataType(data, type) {
  return Object.prototype.toString.call(data) === `[object ${type}]`;
}

export function GetType(data) {
  return Object.prototype.toString.call(data);
}

export function IsObject(data) {
  return CheckDataType(data, "Object");
}

export function IsFunction(data) {
  return CheckDataType(data, "Function");
}

export function IsArray(data) {
  return CheckDataType(data, "Array");
}

export function IsNumber(data) {
  return CheckDataType(data, "Number");
}

export function IsString(data) {
  return CheckDataType(data, "String");
}

export function IsBoolean(data) {
  return CheckDataType(data, "Boolean");
}

export function IsNull(data) {
  return CheckDataType(data, "Null");
}

export function IsUndefined(data) {
  return CheckDataType(data, "Undefined");
}

export function IsDate(data) {
  return CheckDataType(data, "Date");
}

export function IsFormData(data) {
  return CheckDataType(data, "FormData");
}

export function IsPromise(data) {
  return CheckDataType(data, "Promise");
}

export function IsJson(data) {
  try {
    return JSON.parse(data) && !!data;
  } catch {
    return false;
  }
}
