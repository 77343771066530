import React from "react";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import { useState } from "react";
import { useEffect } from "react";
import ThisModal from "./ThisModal";

function CreateModal({ mobile, setPlNovos, setTab, periodosLetivos, plNovos }) {
  const [showModal, setShowModal] = useState(false);
  const [plDisponiveis, setPlDisponiveis] = useState([]);

  useEffect(() => {
    let newPl = [];
    if (Array.isArray(periodosLetivos)) {
      periodosLetivos.forEach((plGlobal) => {
        const plMat = plNovos?.find((e) => String(e?.cod) === String(plGlobal?.cod));

        if (plMat) {
          const thisTurmas = plGlobal?.turmas?.filter(
            (tGlobal) =>
              !plMat.turmas.find((e) => String(e?.cod) === String(tGlobal?.cod))
          );
          if (thisTurmas.length > 0) {
            newPl.push({ ...plGlobal, turmas: thisTurmas || [] });
          }
        } else if (plGlobal?.turmas?.length > 0) {
          newPl.push(plGlobal);
        }
      });
    }
    setPlDisponiveis(newPl);
  }, [periodosLetivos, plNovos]);

  return (
    <>
      <ColoredButton
        className="TurmasList_div_header_button"
        blueLayout
        type="button"
        onClick={() => setShowModal(!showModal)}
        materialIcon={{ addCircle: true }}
      >
        {!mobile && "NOVO"}
      </ColoredButton>
      {showModal && (
        <ThisModal {...{ setTab, setShowModal, plDisponiveis, setPlNovos }} />
      )}
    </>
  );
}

export default CreateModal;
