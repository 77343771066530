import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    outline: none;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
  }
  
  html, body, #root {
    overflow-x: hidden;
    width: 100vw;
    height: 100vh;
  }

  body, button, input, a, textarea, li, select, option, label {
    font-family: 'Open Sans', sans-serif;
    color: #333;
    -webkit-font-smoothing: antialiased;
    line-height: 1.5;
    font-size: 2.5vh;
  }

  a {
    text-decoration: none;
    color: #666;
  }

  button {
    cursor: pointer;
  }

  .material-icons {
    font-size: 1.5em;
  }


.Toastify__toast--rtl {
  direction: rtl;
}
.Toastify__toast--dark {
  background: #121212;
  color: #fff;
}
.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}
.Toastify__toast--info {
  background: #1CA9D0 !important;
}
.Toastify__toast--success {
  background: #4EA654 !important;
}
.Toastify__toast--warning {
  background: #FFDA44 !important;
}
.Toastify__toast--error {
  background: #FF4031 !important;
}
.Toastify__close-button {
  display: none;
}
.toast-conatiner {
  display: flex;
   align-items: center;
}
.toast-conatiner span {
  padding-right: 0.3em;
  font-size: 2em;
}
.Toastify__toast-container--top-right {
  top: 6em;
  scroll-margin-right: 1em;
  right: 2.7em;
}
@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }
  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}
@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
  }
}

`;
