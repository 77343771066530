import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { Container } from "./styles";
import { Materialize } from "~/styles/materializeGrid";
import CustomScrollBar from "~/componentes/CustomScrollBar";

function ShowContent({ mountedData }) {
  return (
    <Container>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          <div className="row">
            <Label
              title="NOME:"
              text={mountedData?.nome || ""}
              className="col s12 m12 l12"
            />
            <Label
              title="FAIXA INICIAL:"
              text={mountedData?.faixaInicial || ""}
              className="col s12 m12 l6"
            />
            <Label
              title="FAIXA FINAL:"
              text={mountedData?.faixaFinal || ""}
              className="col s12 m12 l6"
            />
            <Label
              title="VALOR:"
              text={mountedData?.valorPorAluno || ""}
              className="col s12 m12 l6"
            />
            <Label
              title="CONDIÇÃO DE PAGAMENTO:"
              text={mountedData?.condicaoPagamento?.nome || ""}
              className="col s12 m12 l6"
            />
          </div>
        </Materialize>
      </CustomScrollBar>
    </Container>
  );
}

export default ShowContent;
