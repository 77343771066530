import styled from "styled-components";
import { SistemaComponenteInterno } from "..";

export const Container = styled(SistemaComponenteInterno)`
  width: 100%;
  height: 20em;
  display: flex;
  align-items: center;
  justify-content: center;

  .loadingIcon {
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    animation: loading 2s linear infinite;
  }

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
