/* eslint-disable no-useless-computed-key */
import React, { useState, useLayoutEffect } from "react";

import api from "~/services/api";
import ShowContent from "./ShowContent";
import { SLShowModal } from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import TableComponent from "./TableComponent";
import Label from "~/componentes/CustomForm/Label";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
import FileSaver from 'file-saver';

function Chamada() {
  const [mountedData, setMountedData] = useState();
  const [periodosLetivos, setPeriodosLetivos] = useState([]);
  const [series, setSeries] = useState([]);
  const [tabFilter, setTabFilter] = useState({ ativo: true });
  const [bimestre, setBimestre] = useState(1);
  const [codBimestre, setCodBimestre] = useState(1);

  const [data, setData] = useState(`${(new Date()).getFullYear()}-${String((new Date()).getMonth() + 1).padStart(2, '0')}-${String((new Date()).getDate()).padStart(2, '0')}`);

  useLayoutEffect(() => {
    api.get("professor/series").then((response) => setSeries(response?.data || []));

    api
      .get("professor/periodosLetivos", { params: { ativo: true } })
      .then((response) => setPeriodosLetivos(response?.data || []));
  }, []);

  async function createFile(){
    let response = await fetch('http://localhost:3000/excel_eduqueme.xlsx');
    let data = await response.blob();
    let metadata = {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    let file = new File([data], "excel_eduqueme.xlsx", metadata);
    // ... do something with the file or return it
    return file;
  }

  async function updateExcelFile(filePath, data) {
    console.log(data);
    let alunos = (await api.get("professor/turma/alunos", { params: { cod: data.cod } })).data;
    if(alunos?.cod){
      let file = await createFile();
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (e) => {
        console.log('DataURL:', reader.result);
        const buffer = reader.result;

        const workbook = new ExcelJS.Workbook();
        workbook.xlsx
          .load(buffer)
          .then(async () => {
            const sheet = workbook.getWorksheet(1);
            console.log(sheet);
          
            // Update the data in the sheet
            sheet.getCell('A1').value = data.createdAtDate;
            console.log(sheet.getCell('A1').value);

            let cellStart = 13;
            for (let index = 0; index < alunos.alunoDisciplinaTurma.length; index++) {
              const aluno = alunos.alunoDisciplinaTurma[index];
              
              sheet.getCell(`B${cellStart}`).value = aluno.alunoTurma.aluno.usuario.nome;
              cellStart++;
            }
          
            // Save the changes to the file
            workbook.xlsx.writeBuffer()
            .then(buffer => FileSaver.saveAs(new Blob([buffer]), `eduqueme-temp.xlsx`))
            .catch(err => console.log('Error writing excel export', err))
            //await workbook.xlsx.writeFile('./eduqueme-temp.xlsx');
          })
          .catch((error) => {
            console.log(error);
          });
      };
    }
  }

  return (
    <Container
      requests={{
        findAll: () => api.get("professor/turmas"),
        findOne: (thisData) => api.get("professor/turma/alunos", { params: { cod: thisData.cod } }),
        onExcel: async (thisData) => await updateExcelFile("http://localhost:3000/excel_eduqueme.xlsx", thisData),
        onPDF: () => {},
      }}
      inputs={[
        { filter: "turma.nome", label: "Nome" },
        { filter: "turma.serie.nome", label: "Série" },
        { filter: "turma.periodoLetivo.nome", label: "Período Letivo" },
        { filter: "disciplinaFilial.nome", label: "Disciplina" },
      ]}
      selects={[
        {
          filter: "turma.periodoLetivoCod",
          list: periodosLetivos,
          outLabel: "Período Letivo",
          value: "cod",
          label: "nome",
        },
        {
          filter: "turma.serieCod",
          list: series,
          outLabel: "Série",
          value: "cod",
          label: "nome",
        },
      ]}
      dataFilter={tabFilter}
    >
      {/* <Label
        title="Bimestre:"
        className="col s12 m12 l3">
        <SistemaInput name={'bimestre'} defaultValue={bimestre} required />
      </Label> */}
      <TableComponent {...{ setTabFilter, data, setData }} />

      <SLShowModal title="Turma" setter={setMountedData}>
        <ShowContent {...{ mountedData, bimestre, codBimestre, setMountedData, data}} />
      </SLShowModal>
    </Container>
  );
}

export default Chamada;
