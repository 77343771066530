import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserDevice from "~/componentes/UserDevice";
import api from "~/services/api";
import { signOut } from "~/store/modules/auth/actions";
import acessos from "../../acessos";

function useMenuHandler({ setShowLateralMenu }) {
  const {
    user: { perfil, matricula },
    auth: { token },
  } = useSelector((state) => state) || {};
  const reduxDispatch = useDispatch();

  const { desktop } = UserDevice();
  const [loaded, setLoaded] = useState(false);
  const [modulos, setModulos] = useState([]);
  /**
   * @type {[{moduloPath: string, menuPath: string, props: {}}, Function]}
   */
  const [componentPath, setComponentPath] = useState();

  useEffect(() => {
    async function fetchModulos() {
      const response = await api.get(`${matricula?.tipo}/acessos`).catch(() => undefined);

      if (!response || response?.status !== 200 || !Array.isArray(response?.data) || !(response?.data?.length > 0)) {
        reduxDispatch(signOut());
      } else {
        const moduloPath = localStorage.getItem("modulo");
        const menuPath = localStorage.getItem("menu");

        setComponentPath({ moduloPath, menuPath });
        setModulos(response.data);
        setLoaded(true);
      }
    }

    fetchModulos();
  }, [token, matricula, reduxDispatch]);

  const { RenderedComponent, currentMenu, currentModulo } = useMemo(() => {
    if (loaded) {
      const { moduloPath, menuPath } = componentPath || {};

      let thisModulo = modulos?.find?.((e) => e?.path === moduloPath);
      let thisMenu = thisModulo?.menus?.find?.((e) => e?.path === menuPath);

      if (thisModulo?.noMenu ? !thisModulo?.path : !thisMenu?.path) {
        thisModulo = modulos?.[0];
        thisMenu = thisModulo?.menus?.[0];
      }

      let thisComponent = acessos?.[matricula?.tipo]?.[thisModulo?.path];

      if (!thisModulo?.noMenu) {
        thisComponent = thisComponent?.[thisMenu?.path];
      }

      return {
        currentModulo: thisModulo,
        currentMenu: thisModulo?.noMenu ? false : thisMenu,
        RenderedComponent: thisComponent,
      };
    } else {
      return {
        currentModulo: undefined,
        currentMenu: undefined,
        RenderedComponent: undefined,
      };
    }
  }, [componentPath, loaded, matricula, modulos]);

  useEffect(() => {
    if (loaded) {
      localStorage.setItem("modulo", currentModulo?.path);
      localStorage.setItem("menu", currentMenu?.path);
    }
  }, [currentMenu, currentModulo, loaded]);

  useEffect(() => {
    setShowLateralMenu(!!desktop);
  }, [desktop, setShowLateralMenu]);

  return {
    desktop,
    modulos,
    perfil,
    componentPath,
    setComponentPath,
    RenderedComponent,
    currentMenu,
    currentModulo,
    matricula,
  };
}

export default useMenuHandler;
