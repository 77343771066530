import React from "react";

import { Container } from "./styles";
import ThisCloseButton from "../../ThisButtons/ThisCloseButton";

/**
 * @param {Object} props
 * @param {Object} props.title Apenas adiciona um <h3>title</h3>.
 * @param {Function} props.closeModal Se passado uma função, irá utilizar ela no lugar do closeModal padrão.
 */
function ThisModalHeader({ title, children, closeCondition, ...rest }) {
  return (
    <Container {...rest}>
      {title && <h3>{title}</h3>}
      <ThisCloseButton
        className="SistemaList_ModalHeader_closeButton"
        closeCondition={closeCondition}
      />
      {children}
    </Container>
  );
}

export default ThisModalHeader;
