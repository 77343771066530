import { customListSetData, customListUpdateRowData } from "../customListStore";

/**
 * @param {Object} options
 * @param {any} options.data Informe o dado da linha clicada.
 * @param {Object} options.state Informe o estado do context
 * @param {Object} options.dispatch Informe o dispatch do context
 */
async function CustomListLoadInitialData({ data, state, dispatch }) {
  const { requests, reloadTable, rowClick } = state || {};
  const { findOne, findAll } = requests || {};

  if (reloadTable && findAll) {
    findAll().then((result) => {
      dispatch(customListSetData(result.data));
    });
  }

  if (data) {
    try {
      const response = await findOne(data);

      if (response?.data) {
        dispatch(customListUpdateRowData(response.data));
        rowClick && rowClick(response.data);
        return response.data;
      } else {
        throw new Error();
      }
    } catch {
      dispatch(customListUpdateRowData(data));
      rowClick && rowClick(data);
      return data;
    }
  }
}

export default CustomListLoadInitialData;
