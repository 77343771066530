import React, { useState } from "react";
import api from "~/services/api";
import { Container } from "./styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import handleFormData from "~/utils/handleFormData";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";

import CustomScrollBar from "~/componentes/CustomScrollBar";
import { Materialize } from "~/styles/materializeGrid";
import { SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import PreviewFileInput from "~/componentes/CustomForm/FileInput/styles/PreviewFileInput";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import SistemaTopico from "~/componentes/StyledComponents/Textos/SistemaTopico";
import ItensDiv from "./ItensDiv";

function EditContent({ mountedData, disciplinasFilial, questoesNiveis, tiposQuestoes }) {
  const [itens, setItens] = useState(mountedData?.itens ? mountedData.itens : []);
  const [file, setFile] = useState();

  const [fileExist, setFileExist] = useState();
  const initialItemData = mountedData
    ? { questaoCod: mountedData.cod, enunciado: "", gabarito: false }
    : { questaoCod: 0, enunciado: "", gabarito: false };

  function handleSubmit(data, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let response;
        if (mountedData?.avaliacoesQuestao?.length > 0) {
          response = await api.put("professor/questao/desativar", {
            ...data,
            cod: mountedData.cod,
            avaliacoesQuestao: mountedData?.avaliacoesQuestao,
          });
        } else {
          let thisDataRequest = handleFormData(file, {
            ...data,
            itens,
            fileExist: fileExist,
            cod: mountedData.cod,
            avaliacoesQuestao: mountedData?.avaliacoesQuestao,
          });

          response = await api.put("professor/questao", thisDataRequest);
        }

        if (response?.status === 200) {
          closeReload && closeReload();
        }

        return response;
      },
      {
        successModal: `Questão atualizada com sucesso.`,
        texto: `Deseja atualizar essa Questão?`,
        errorModal: {
          defaultMessage: `Não foi possível atualizar essa questão, por favor, tente novamente.`,
        },
      }
    );
  }

  return (
    <Container onSubmit={handleSubmit}>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          {mountedData?.avaliacoesQuestao?.length === 0 && (
            <div className="row">
              <SistemaTopico>Dados Questão</SistemaTopico>

              <Label title="TÍTULO:" className="col s12 m12 l6">
                <SistemaInput name="nome" defaultValue={mountedData?.nome} required />
              </Label>
              <Label title="DISCIPLINA:" className="col s12 m12 l3">
                <SistemaSelect
                  name="disciplinaFilialCod"
                  firstOption
                  list={{ list: disciplinasFilial, value: "cod", label: "nome" }}
                  required
                  defaultValue={mountedData?.disciplinaFilialCod}
                />
              </Label>
              <Label title="NÍVEL DA QUESTÃO:" className="col s12 m12 l3">
                <SistemaSelect
                  name="questaoNivelCod"
                  firstOption
                  list={{ list: questoesNiveis, value: "cod", label: "nome" }}
                  required
                  defaultValue={mountedData?.questaoNivelCod}
                />
              </Label>
              <Label title="TIPO DE QUESTÃO:" className="col s12 m12 l3">
                <SistemaSelect
                  name="questaoTipoCod"
                  list={{ list: tiposQuestoes, value: "cod", label: "nome" }}
                  required
                  setter={(e) => {
                    let existe = tiposQuestoes.some(function (el, i) {
                      return el.cod === parseInt(e) && el.chave === "MUL";
                    });
                    setItens(
                      existe
                        ? mountedData?.itens?.length > 0
                          ? mountedData?.itens
                          : [initialItemData]
                        : false
                    );
                  }}
                  defaultValue={mountedData?.questaoTipoCod}
                />
              </Label>
              <Label title="POSSUI TEMPO:" className="col s12 m12 l3">
                <SistemaSelect
                  name="possuiTempo"
                  list={{
                    list: [
                      { possuiTempo: "true", nome: "Sim" },
                      { possuiTempo: "false", nome: "Não" },
                    ],
                    value: "possuiTempo",
                    label: "nome",
                  }}
                  defaultValue={mountedData?.possuiTempo.toString()}
                  required
                />
              </Label>
              <Label title="TEMPO: (Min:Seg)" className="col s12 m12 l3">
                <SistemaSelect
                  name="tempo"
                  firstOption
                  list={{
                    list: [
                      { tempo: "00:01:00", nome: "01:00" },
                      { tempo: "00:02:00", nome: "02:00" },
                      { tempo: "00:03:00", nome: "03:00" },
                      { tempo: "00:04:00", nome: "04:00" },
                      { tempo: "00:05:00", nome: "05:00" },
                      { tempo: "00:06:00", nome: "06:00" },
                      { tempo: "00:07:00", nome: "07:00" },
                      { tempo: "00:08:00", nome: "08:00" },
                      { tempo: "00:09:00", nome: "09:00" },
                      { tempo: "00:10:00", nome: "10:00" },
                      { tempo: "00:11:00", nome: "11:00" },
                      { tempo: "00:12:00", nome: "12:00" },
                      { tempo: "00:13:00", nome: "13:00" },
                      { tempo: "00:14:00", nome: "14:00" },
                      { tempo: "00:15:00", nome: "15:00" },
                      { tempo: "00:16:00", nome: "16:00" },
                      { tempo: "00:17:00", nome: "17:00" },
                      { tempo: "00:18:00", nome: "18:00" },
                      { tempo: "00:19:00", nome: "19:00" },
                      { tempo: "00:20:00", nome: "20:00" },
                      { tempo: "00:21:00", nome: "21:00" },
                      { tempo: "00:22:00", nome: "22:00" },
                      { tempo: "00:23:00", nome: "23:00" },
                      { tempo: "00:24:00", nome: "24:00" },
                      { tempo: "00:25:00", nome: "25:00" },
                      { tempo: "00:26:00", nome: "26:00" },
                      { tempo: "00:27:00", nome: "27:00" },
                      { tempo: "00:28:00", nome: "28:00" },
                      { tempo: "00:29:00", nome: "29:00" },
                      { tempo: "00:30:00", nome: "30:00" },
                      { tempo: "00:31:00", nome: "31:00" },
                      { tempo: "00:32:00", nome: "32:00" },
                      { tempo: "00:33:00", nome: "33:00" },
                      { tempo: "00:34:00", nome: "34:00" },
                      { tempo: "00:35:00", nome: "35:00" },
                      { tempo: "00:36:00", nome: "36:00" },
                      { tempo: "00:37:00", nome: "37:00" },
                      { tempo: "00:38:00", nome: "38:00" },
                      { tempo: "00:39:00", nome: "39:00" },
                      { tempo: "00:40:00", nome: "40:00" },
                      { tempo: "00:41:00", nome: "41:00" },
                      { tempo: "00:42:00", nome: "42:00" },
                      { tempo: "00:43:00", nome: "43:00" },
                      { tempo: "00:44:00", nome: "44:00" },
                      { tempo: "00:45:00", nome: "45:00" },
                      { tempo: "00:46:00", nome: "46:00" },
                      { tempo: "00:47:00", nome: "47:00" },
                      { tempo: "00:48:00", nome: "48:00" },
                      { tempo: "00:49:00", nome: "49:00" },
                      { tempo: "00:50:00", nome: "50:00" },
                      { tempo: "00:51:00", nome: "51:00" },
                      { tempo: "00:52:00", nome: "52:00" },
                      { tempo: "00:53:00", nome: "53:00" },
                      { tempo: "00:54:00", nome: "54:00" },
                      { tempo: "00:55:00", nome: "55:00" },
                      { tempo: "00:56:00", nome: "56:00" },
                      { tempo: "00:57:00", nome: "57:00" },
                      { tempo: "00:58:00", nome: "58:00" },
                      { tempo: "00:59:00", nome: "59:00" },
                      { tempo: "01:00:00", nome: "60:00" },
                    ],
                    value: "tempo",
                    label: "nome",
                  }}
                  defaultValue={mountedData?.tempo}
                />
              </Label>
              <Label title="PRIVACIDADE:" className="col s12 m12 l3">
                <SistemaSelect
                  name="privado"
                  list={{
                    list: [
                      { privado: "true", nome: "Privado" },
                      { privado: "false", nome: "Público" },
                    ],
                    value: "privado",
                    label: "nome",
                  }}
                  defaultValue={mountedData?.privado.toString()}
                  required
                />
              </Label>
              <Label title="ENUNCIADO:" className="col s12 m12 l8">
                <SistemaTextArea
                  name="enunciado"
                  defaultValue={mountedData?.enunciado}
                  required
                />
              </Label>
              <Label title="IMAGEM:" className="col s12 m12 l4 label_preview">
                <PreviewFileInput
                  maxHeight="15em"
                  defaultPreview={mountedData?.arquivo?.url}
                  formFile={setFile}
                  checkPreview={setFileExist}
                />
              </Label>
              {itens && <SistemaTopico>Dados Itens</SistemaTopico>}
              {itens && (
                <Label className="col s12 m12 l12 questoes_lista_item">
                  <ItensDiv
                    itens={itens}
                    initialItemData={initialItemData}
                    setItens={setItens}
                  />
                </Label>
              )}
              {!itens && (
                <Label title="OBSERVAÇÕES PARA CORREÇÃO:" className="col s12 m12 l12">
                  <SistemaTextArea
                    name="gabarito"
                    defaultValue={mountedData?.gabarito}
                    required
                  />
                </Label>
              )}
            </div>
          )}

          {mountedData?.avaliacoesQuestao?.length > 0 && (
            <div className="row">
              <Label title="Ativo:" className="col s12 m12 l12">
                <SistemaSelect
                  name="ativo"
                  list={{
                    list: [
                      { valor: "true", nome: "Sim" },
                      { valor: "false", nome: "Não" },
                    ],
                    value: "valor",
                    label: "nome",
                  }}
                  defaultValue={mountedData?.ativo.toString()}
                  required
                />
              </Label>
            </div>
          )}
        </Materialize>
      </CustomScrollBar>
      <SLModalFooter />
    </Container>
  );
}

export default EditContent;
