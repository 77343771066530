import styled from "styled-components";
import { SLTable } from "~/pages/Sistema/componentes/SistemaList";

export const ThisTable = styled(SLTable)`
  table-layout: fixed;

  td:not(:first-child),
  th:not(:first-child) {
    text-align: center;
    width: 7em;
    padding-right: 0em;
    padding-left: 0em;
  }

  td:first-child,
  th:first-child {
    width: calc(100% - 30em);
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  td:nth-child(2),
  th:nth-child(2) {
    width: 14em;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
`;
