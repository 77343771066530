import React, { useState } from "react";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";
import SistemaTable from "~/componentes/StyledComponents/Tables/SistemaTable";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import Label from "~/componentes/CustomForm/Label";
import IconButton from "~/componentes/CustomForm/Button/Styles/IconButton";
import Pagination from "~/componentes/Pagination";
import { useEffect } from "react";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import api from "~/services/api";

import { ThisAddDisciplinaTurma } from "./styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import ShowError from "~/utils/ShowError";
import { Materialize } from "~/styles/materializeGrid";
import { SubTitle } from "../ShowContent/styles";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import { SLForm, SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import sistemaListStore, { sistemaListShowModal } from "~/pages/Sistema/componentes/SistemaList/ThisMainComponent/thisStore";

function ADDNotaAlunoTurmaModalEdit({ data, outClick }) {
  const [dtMatriculada, setDtMatriculada] = useState([]);
  const { state, dispatch } = React.useContext(sistemaListStore);

  console.log("periodocod", data)
  let alunosNotas = data?.t?.notas.filter((el)=> {
    if(data.tudo.turma.periodo[0].cod == parseInt(data.bimestre)){
      return el.notaAvaliacao
    }
  });

  let periodoCod = data?.tudo.turma.periodo.find((el)=> el.cod == parseInt(data.bimestre));
  function notaAluno(){
    let notas = alunosNotas?.reduce((acc, nota)=> acc + nota?.notaAvaliacao, 0)
    let media = (notas / data.tudo.quantidadeAvaliacoes).toFixed(1)

    return media
  }
  let endSubmit = false;
  function handleSubmit(dataPut, options){
    console.log(options);
    console.log(state);
    let submitData = {
      notasCreate: [],
      notasUpdate: []
    }

    Object.entries(dataPut).forEach(element => {
      const [key, value] = element;
      if (key.includes('novo') && value == 'nulo') {
        return
      }
      if (!key.includes('novo')) {
        submitData.notasUpdate.push({cod: key, nota: value})
      } else {
        submitData.notasCreate.push({
          AVPCod: 3,
          alunoDisciplinaTurmaCod: data?.t?.cod,
          nota: value,
          periodoNota: periodoCod?.cod || null
        })
      }
      console.log("SubmitData", submitData);
    });
    ShowConfirmacao(
      async () => {
        let responseUpdate;
        let responseCreate;

        if (submitData.notasUpdate.length != 0) {
          responseUpdate = await api.put(
            "professor/turma/alunos/notas",
            {notasUpdate: submitData.notasUpdate}
          );
        }
        if (submitData.notasCreate.length != 0) {
          responseCreate = await api.post(
            "professor/turma/alunos/notas",
            {notasCreate: submitData.notasCreate,
              cod: data?.t?.disciplinaTurmaCod}
          )
        }
        options.reloadData();
        //options.closeModal();
        endSubmit = true;
        //options.closeReload();
        //return responseCreate
      },
      {
        errorModal: {defaultMessage: "Não foi possível atualizar a matrícula dessa turma, por favor, tente novamente mais tarde."},
        successModal: false,
        message: "Tem certeza que deseja atualizar a nota desse aluno?",
      }
    );
  }

  function onSubmit(closeModal){
    document.querySelector("#BTN_SUBMIT").click();
    let timer = setInterval(() => {
      if(endSubmit){
        closeModal()
        clearInterval(timer);
      }
    }, 1000)
  }
    console.log(data?.tudo)
  return (
    <SistemaFixedModal
      title="Adicionar Notas"
      style={{ width: "50em", maxWidth: "100%" }}
      {...{ outClick }}
      footerButtons={{submitButton: {onClick: onSubmit}}}
    >
      <SLForm id="FORM_EDIT_NOTAS" onSubmit={handleSubmit}>
        <Materialize>
          <SubTitle>Alunos</SubTitle>
          <div className="row">
            <Label
              title="NOME:"
              text={data?.t?.alunoTurma?.aluno.usuario.nome || ""}
              className="col s12 m12 l3"
            />
            <Label
              title="EMAIL:"
              text={data?.t?.alunoTurma?.aluno.usuario.email || ""}
              className="col s12 m12 l3"
            />
            <Label
              title="TELEFONE:"
              text={data?.t?.turma?.periodoLetivo?.telefone1 || ""}
              className="col s12 m12 l3"
            />
            <Label
              title="MÉDIA:"
              text={notaAluno() || ""}
              className="col s12 m12 l3"
            />
          </div>
          <SubTitle>Notas</SubTitle>
          <div className="row">
            {data?.tudo?.quantidadeAvaliacoes > 0 &&
              Array.from(Array(data?.tudo?.quantidadeAvaliacoes).keys()).map((index, dt) =>{
              let ava = 'Avaliação ' + (index + 1);
              console.log(alunosNotas, alunosNotas[index]?.cod, alunosNotas[index]?.notaAvaliacao);
              return (<Label
                  key={index}
                  title={ava}
                  className="col s12 m12 l3"
                >
                  <SistemaInput name={`${alunosNotas[index]?.cod || 'novo-' + index}`} defaultValue={`${alunosNotas[index]?.notaAvaliacao  || 'nulo'}`} required />  
                </Label>)
              })
            }
          </div>
          <input type="submit" id="BTN_SUBMIT" style={{opacity: 0}} />
        </Materialize>
        {/* <SLModalFooter cancelButton={false}  /> */}
      </SLForm>
    </SistemaFixedModal>
  );
}

export default ADDNotaAlunoTurmaModalEdit;
