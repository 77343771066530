import React from "react";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import Label from "~/componentes/CustomForm/Label";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import api from "~/services/api";
import ShowLoading from "~/utils/ShowLoading";
import { MaterializeContent } from "../styles";

import { Container } from "./styles";

function ThisFilter({ setData }) {
  const [professor, setProfessor] = React.useState("");
  const [periodosLetivos, setPeriodosLetivos] = React.useState([]);
  const [periodoLetivo, setPeriodoLetivo] = React.useState();

  React.useEffect(() => {
    api
      .get("filial/relatorios/professores/periodosLetivosProfessores")
      .then((r) => {
        setPeriodoLetivo(r?.data?.[0]);
        setProfessor(r?.data?.[0]?.professores?.[0]);
        setPeriodosLetivos(r?.data || []);
      });
  }, []);

  async function fetchStats() {
    ShowLoading(() =>
      api
        .get("filial/relatorios/professores/informacoesProfessor", {
          params: {
            professorCod: professor?.cod,
            periodoLetivoCod: periodoLetivo?.cod,
          },
        })
        .then((r) => setData(r?.data))
        .catch(() => setData())
    );
  }

  return (
    <Container>
      <MaterializeContent>
        <div className="row">
          <div className="filters col s12 m12 l6">
            <Label title="Período Letivo" className="filter-item">
              <SistemaSelect
                list={{
                  list: periodosLetivos,
                  value: "cod",
                  label: "nome",
                }}
                onChange={(e) => {
                  const val = e.currentTarget.value;
                  const thisFind = periodosLetivos?.find((t) => t?.cod === val);
                  setPeriodoLetivo(thisFind);
                }}
                value={periodoLetivo?.cod}
              />
            </Label>
            <Label title="Professores" className="filter-item">
              <SistemaSelect
                list={{
                  list: periodoLetivo?.professores,
                  value: "cod",
                  label: "usuario.nome",
                }}
                onChange={(e) => {
                  const val = e.currentTarget.value;
                  const thisFind = periodoLetivo?.professores?.find(
                    (t) => t?.cod === val
                  );
                  setProfessor(thisFind);
                }}
                value={professor?.cod}
              />
            </Label>
          </div>
          <div className="actions col s12 m12 l6">
            <ColoredButton
              // materialIcon={{ filterAlt: true }}
              yellowLayout
              onClick={fetchStats}
            >
              Procurar
            </ColoredButton>
          </div>
          <div className="row">
            <Label
              className="col s12 m12 l6"
              title="Nome:"
              text={professor?.usuario?.nome || ""}
            />
            <Label
              className="col s12 m12 l6"
              title="Email:"
              text={professor?.usuario?.email || ""}
            />
          </div>
        </div>
      </MaterializeContent>
    </Container>
  );
}

export default ThisFilter;
