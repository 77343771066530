import React from "react";

import { Materialize } from "~/styles/materializeGrid";
import Label from "~/componentes/CustomForm/Label";
import SistemaTopico from "~/componentes/StyledComponents/Textos/SistemaTopico";

function ThisLabel({ text, title, size }) {
  const thisSize = Number(size);
  const validSize = thisSize >= 3 && thisSize <= 12;

  return (
    <>
      {typeof title === "string" && title.length > 0 && typeof text === "string" && text.length > 0 && (
        <Label eduquemeLayout {...{ text, title }} className={`col m12 s12 l${validSize ? thisSize : 12}`} />
      )}
    </>
  );
}

function EscolaDados({ filial }) {
  return (
    <Materialize>
      <SistemaTopico>DADOS DA ESCOLA</SistemaTopico>
      <div className="row">
        <ThisLabel title="RAZÃO SOCIAL" text={filial?.razaoSocial} size={6} />
        <ThisLabel title="CNPJ" text={filial?.cnpj} size={6} />
      </div>
      <SistemaTopico>ENDEREÇO</SistemaTopico>
      <div className="row">
        <ThisLabel title="ESTADO" text={filial?.estado?.nome} size={4} />
        <ThisLabel title="CIDADE" text={filial?.cidade?.nome} size={4} />
        <ThisLabel title="BAIRRO" text={filial?.bairro} size={4} />
        <ThisLabel title="LOGRADOURO" text={filial?.logradouro} size={6} />
        <ThisLabel title="NÚMERO" text={filial?.numeroLogradouro} size={3} />
        <ThisLabel title="CEP" text={filial?.cep} size={3} />
        <ThisLabel title="COMPLEMENTO" text={filial?.complementoLogradouro} size={12} />
      </div>
      <SistemaTopico>CONTATOS</SistemaTopico>
      <div className="row">
        <ThisLabel title="EMAIL" text={filial?.email} size={12} />
        <ThisLabel title="TELEFONE 1" text={filial?.telefone1} size={4} />
        <ThisLabel title="TELEFONE 2" text={filial?.telefone2} size={4} />
        <ThisLabel title="WHATSAPP" text={filial?.whatsapp} size={4} />
      </div>
      {/* <SistemaTopico>REDES SOCIAIS</SistemaTopico>
      <div className="row">
        <Label
          eduquemeLayout
          title="FACEBOOK"
          text={filial?.facebook || ""}
          className="col m12 s12 l6"
        />
        <Label
          eduquemeLayout
          title="YOUTUBE"
          text={filial?.youtube || ""}
          className="col m12 s12 l6"
        />
        <Label
          eduquemeLayout
          title="INSTAGRAM"
          text={filial?.instagram || ""}
          className="col m12 s12 l6"
        />
        <Label
          eduquemeLayout
          title="SITE"
          text={filial?.site || ""}
          className="col m12 s12 l6"
        />
      </div> */}
    </Materialize>
  );
}

export default EscolaDados;
