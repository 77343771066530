import React from "react";

import { Container } from "./styles";
import api from "~/services/api";
import FirstSection from "./FirstSection";
import SecondSection from "./SecondSection";

function DashBoard() {
  const [stats, setStats] = React.useState();

  React.useEffect(() => {
    async function fetchData() {
      await api.get("professor/dashBoard").then((r) => setStats(r?.data));
    }
    fetchData();
  }, []);

  return (
    <Container>
      <FirstSection {...{ stats }} />
      <SecondSection {...{ stats }} />
    </Container>
  );
}

export default DashBoard;
