import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { Container, SubTitle } from "./styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import { Materialize } from "~/styles/materializeGrid";

function ShowContent({ mountedData }) {
  return (
    <Container>
      <CustomScrollBar fixScrollMargin autoHeightMax="80vh">
        <Materialize>
          <div className="row">
            <SubTitle>Dados do Titulo</SubTitle>
            <Label title="PREFIXO:" className="col s12 m12 l1" text={mountedData?.prefixo || ""} />
            <Label title="CÓDIGO:" className="col s12 m12 l2" text={mountedData?.codigo || ""} />
            <Label title="PARCELA:" className="col s12 m12 l1" text={mountedData?.parcela || ""} />
            <Label title="TIPO DE TÍTULO:" className="col s12 m12 l3" text={mountedData?.tipoTitulo.codigo || ""} />
            <Label title="NATUREZA:" className="col s12 m12 l3" text={mountedData?.natureza.codigo || ""} />
            <Label title="EMISSÃO:" className="col s12 m12 l3" text={mountedData?.emissao || ""} />
            <Label title="FORNECEDOR:" className="col s12 m12 l3" text={mountedData?.fornecedor.cod || ""} />
            <Label title="NOME FORNECEDOR:" className="col s12 m12 l3" text={mountedData?.fornecedor.nome || ""} />
            <Label title="VENCIMENTO:" className="col s12 m12 l3" text={mountedData?.vencimento || ""} />
            <Label title="VALOR:" className="col s12 m12 l3" text={mountedData?.valor || ""} />
            <Label title="DESCONTO:" className="col s12 m12 l3" text={mountedData?.descontoValor || ""} />
            <Label title="HISTORICO:" className="col s12 m12 l3" text={mountedData?.historico || ""} />
          </div>
          <div className="row">
            <SubTitle>Dados do Banco</SubTitle>
            <Label title="CENTRO DE CUSTO:" className="col s12 m12 l3" text={mountedData?.centroCusto.codigo || ""} />
            <Label title="BANCO:" className="col s12 m12 l3" text={mountedData?.bancoCod || ""} />
            <Label title="Agencia:" className="col s12 m12 l3" text={mountedData?.agenciaPortador || ""} />
            <Label title="Conta:" className="col s12 m12 l3" text={mountedData?.contaPortador || ""} />
          </div>
        </Materialize>
      </CustomScrollBar>
    </Container>
  );
}

export default ShowContent;
