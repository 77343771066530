import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { Container, SubTitle } from "./styles";
import { Materialize } from "~/styles/materializeGrid";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import SistemaTable from "~/componentes/StyledComponents/Tables/SistemaTable";
import { useState } from "react";
import IconButton from "~/componentes/CustomForm/Button/Styles/IconButton";
import { SLForm, SLModalFooter, SLShowModal } from "~/pages/Sistema/componentes/SistemaList";
import ADDNotaAlunoTurmaModal from "../ADDNotaAlunoTurmaModal";
import ADDNotaAlunoTurmaModalEdit from "../ADDNotaAlunoTurmaModalEdit";
import api from "~/services/api";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import { useEffect } from "react";
import sistemaListStore from "~/pages/Sistema/componentes/SistemaList/ThisMainComponent/thisStore";
import ChamadaSelecionados from "../ChamadaSelecionados";

function ShowContent({ mountedData, bimestre, setMountedData, data}) {
  const [editAlunoTurmaModal, setEditAlunoTurmaModal] = useState();
  const [editAlunoTurmaModalEdit, setEditAlunoTurmaModalEdit] = useState();
  const [chamadaData, setChamadaData] = useState();
  const [chamadaSelecionados, setChamadaSelecionados] = useState();
  const [isSelected, setIsSelected] = useState(0);
  
  const { state, dispatch } = React.useContext(sistemaListStore);

  useEffect(() => {
    (async () => {
      let res = await api.get("professor/turma/alunos", { params: { cod: mountedData.cod } });
      setMountedData(res.data)
      let res2 = await api.get("/professor/chamada", { params: { matriculaCod: res.data.professorCod, disciplinaTurmaCod: res.data.alunoDisciplinaTurma[0].disciplinaTurmaCod, data } });
      setChamadaData(res2.data)
    })()
  }, [editAlunoTurmaModal, editAlunoTurmaModalEdit])
//console.log(mountedData);

  let alunosChamadas = mountedData.alunoDisciplinaTurma.map((aluno)=>{
    if(chamadaData){
      let alunoChamada = chamadaData.find(cha => cha.alunoTurmaCod == aluno.alunoTurmaCod);
      //console.log('alunoChamada', alunoChamada);
      //console.log('chamadaData', chamadaData);

      if (alunoChamada) {
        return {aluno: alunoChamada, status: 'Chamada Feita'}
      } else {
        return {aluno: null, status: "Chamada Pendente"};
      }
    }
  })
  const getDataAlunos = (i) => {
    //console.log(alunosChamadas[i]);
    return alunosChamadas[i];
  }
  function openChamadaSelecionados(dataPut, options){
    let checkboxs = document.querySelectorAll('input[type="checkbox"]');

    let idsSelected = [];
    for (let index = 0; index < checkboxs.length; index++) {
      const checkbox = checkboxs[index];
      if(checkbox.checked == true){
        let id = checkbox.getAttribute('data-id');
        let alunoData = mountedData?.alunoDisciplinaTurma?.find(alunoD => Number(alunoD.cod) == Number(id));
        //console.log('data-id', mountedData?.alunoDisciplinaTurma);
        //console.log('data-id', alunoData);
        idsSelected.push(alunoData);
      }
    }

    setChamadaSelecionados({...mountedData, idsSelected})
  }
  function selecionarTudo() {
    let checkboxs = document.querySelectorAll('input[type="checkbox"]');
    if(isSelected < checkboxs.length){
      //console.log(checkboxs);
      for (let index = 0; index < checkboxs.length; index++) {
        const checkbox = checkboxs[index];
        
        checkbox.checked = true;
      }
      
      setIsSelected(checkboxs.length);
    } else {
      let checkboxs = document.querySelectorAll('input[type="checkbox"]');
      //console.log(checkboxs);
      for (let index = 0; index < checkboxs.length; index++) {
        const checkbox = checkboxs[index];
        
        checkbox.checked = false;
      }
    
      setIsSelected(0);
    }
    
  }
  function toggleDisableChamada(e) {
    let checkboxs = document.querySelectorAll('input[type="checkbox"]');
    let countChecked = e.target.checked ? (isSelected + 1) : (isSelected - 1);
    e.persist()
    //console.log(e.target.checked);
    setIsSelected(countChecked);
  }
  //console.log(mountedData)
  //let periodoAtual = mountedData.turma.periodo.find((periodo, i) => periodo.cod == bimestre)
  //console.log(periodoAtual)

  return (
    <div>
    {editAlunoTurmaModal && (<ADDNotaAlunoTurmaModal
      data={editAlunoTurmaModal}
      outClick={() => setEditAlunoTurmaModal()}
    />)}
    {editAlunoTurmaModalEdit && (<ADDNotaAlunoTurmaModalEdit
      data={editAlunoTurmaModalEdit}
      dataDia={data}
      outClick={() => setEditAlunoTurmaModalEdit()}
    />)}
    {chamadaSelecionados && (<ChamadaSelecionados
      data={chamadaSelecionados}
      outClick={() => setChamadaSelecionados()}
      dataDia={data}
    />)}
    <SLForm>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          <div className="row">
            
            <Label
              title="NOME:"
              text={mountedData?.turma?.nome || ""}
              className="col s12 m12 l3"
            />
            <Label
              title="SÉRIE:"
              text={mountedData?.turma?.serie?.nome || ""}
              className="col s12 m12 l3"
            />
            <Label
              title="PERÍODO LETIVO:"
              text={mountedData?.turma?.periodoLetivo?.nome || ""}
              className="col s12 m12 l3"
            />
            <Label
              title="DISCIPLINA:"
              text={mountedData?.disciplinaFilial?.nome || ""}
              className="col s12 m12 l2"
            />
            <div className="col s12 m12 l12">
              <SubTitle>Alunos</SubTitle>
              <SistemaTable>
                <thead>
                  <tr>
                    <th> - </th>
                    <th>Matricula</th>
                    <th>Nome</th>
                    <th>Email</th>
                    <th>Telefone</th>
                    <th>Estado</th>
                    <th> - </th>
                  </tr>
                </thead>
                <tbody>
                  {mountedData?.alunoDisciplinaTurma?.map((t, i) => (
                    <tr key={t?.alunoTurma?.aluno?.cod || i}>
                      <td>
                        <input type="checkbox" onChange={(e) => toggleDisableChamada(e)} data-id={`${t?.cod}`} id={`${t?.alunoTurma?.aluno?.cod || i}`}/>
                      </td>
                      <td>{t?.alunoTurma?.aluno?.matricula}</td>
                      <td>{t?.alunoTurma?.aluno?.usuario?.nome}</td>
                      <td>{t?.alunoTurma?.aluno?.usuario?.email}</td>
                      <td>
                        {t?.alunoTurma?.aluno?.usuario?.telefone1
                          ? t?.alunoTurma?.aluno?.usuario?.telefone1
                          : ""}
                      </td>
                      <td style={{}}>
                        <strong>{alunosChamadas[i]?.status}</strong>
                      </td>
                      <td>
                        <IconButton
                          onClick={() => setEditAlunoTurmaModalEdit({t, turma: mountedData?.turma,  tudo: mountedData,bimestre})}
                          materialIcon="person_raised_hand"
                          style={{width: '35px'}}
                        />
                        {alunosChamadas[i]?.aluno && (
                          <IconButton
                          onClick={() => setEditAlunoTurmaModal({t, turma: mountedData?.turma, tudo: mountedData, bimestre, aluno: getDataAlunos(i)})}
                          materialIcon="visibility"
                        />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </SistemaTable>
            </div>
          </div>
        </Materialize>
      </CustomScrollBar>
      <SLModalFooter cancelButton={false} submitButton={false}>
          <ColoredButton type="button" greenLayout disabled={!(isSelected > 0)} small onClick={() => openChamadaSelecionados()}>
              Fazer Chamada Selecionados
          </ColoredButton> 
          <ColoredButton type="button" blueLayout small onClick={() => selecionarTudo()}>
              {(()=>{
                let checkboxs = document.querySelectorAll('input[type="checkbox"]');
                if(isSelected < checkboxs.length){
                  return true;
                }
                if(isSelected == checkboxs.length){
                  return false;
                }
              })() ? 'Selecionar Tudo' : 'Deselecionar Tudo'}
          </ColoredButton>
      </SLModalFooter>
    </SLForm>
    </div>
  );
}

export default ShowContent;
