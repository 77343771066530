import styled from "styled-components";
import Scrollbars from "react-custom-scrollbars";

export const Container = styled(Scrollbars)`
  margin-bottom: 1em;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  .sub-menu {
    font-size: 0.82em;
  }
  .acessoNome {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.9em;
  }
`;
