import React from "react";

import { Container } from "./styles";
import IconButton from "~/componentes/CustomForm/Button/Styles/IconButton";
import TextContent from "~/componentes/TextContent";

/**
 * @param {Object} props
 * @param {Object} props.title Apenas adiciona um <h3>title</h3>.
 * @param {Function} props.closeClick Função chamada ao clicar no botão de fechar.
 */
function SistemaModalHeader({ title, children, closeClick, ...rest }) {
  return (
    <Container {...rest}>
      {title && (
        <TextContent
          className="SistemaModalHeader_h4_title"
          lines={1}
          relativeHeight={false}
        >
          {title}
        </TextContent>
      )}
      <IconButton
        materialIcon={{ close: true }}
        className="SistemaList_ModalHeader_closeButton"
        onClick={() => closeClick && closeClick()}
      />
      {children}
    </Container>
  );
}

export default SistemaModalHeader;
