import React, { useState } from "react";
import { CLCreateComponent } from "~/pages/Sistema/componentes/CustomList";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import api from "~/services/api";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import Label from "~/componentes/CustomForm/Label";
import { CLForm } from "~/pages/Sistema/componentes/CustomList/CustomListStyledComponents";
import {
  AcessField,
  FlexAcess,
  CheckBox,
  SubItens,
  CheckInput,
} from "../styles";

function CreateModal({ cargos, modulos }) {
  const [checkArray] = useState([]);

  function handleCollapse(e) {
    if (e.currentTarget.classList.contains("green")) {
      e.currentTarget.parentNode.children[1].classList.add("hide");
      e.currentTarget.parentNode.children[2].classList.remove("hide");
    } else {
      e.currentTarget.parentNode.children[1].classList.remove("hide");
      e.currentTarget.parentNode.children[2].classList.add("hide");
    }
    e.currentTarget.parentNode.parentNode.children[1].classList.toggle("ativo");
  }

  function handleCheckValues(cod) {
    const contains = checkArray.includes(cod);
    if (contains) {
      const index = checkArray.indexOf(cod);
      checkArray.splice(index, 1);
    } else {
      checkArray.push(cod);
    }
  }

  function handleCheckAll(e) {
    if (e.currentTarget.checked) {
      const checks =
        e.currentTarget.parentNode.parentNode.parentNode.children[1].children;
      let i = 0;
      while (i < checks.length) {
        checks[i].children[0].children[0].checked = true;
        handleCheckValues(checks[i].children[0].children[0].value);
        i++;
      }
    } else {
      const checks =
        e.currentTarget.parentNode.parentNode.parentNode.children[1].children;
      let i = 0;
      while (i < checks.length) {
        checks[i].children[0].children[0].checked = false;
        handleCheckValues(checks[i].children[0].children[0].value);
        i++;
      }
    }
  }

  function handleSubmit(editedData, reload) {
    return ShowConfirmacao(
      async () => {
        const response = await api.post("filial/cargoFilial", {
          ...editedData,
        });
        if (response?.status === 200) {
          reload();
        }
        return response;
      },
      {
        message: "Tem certeza que deseja criar esse cargo?",
        successModal: "Cargo criado com sucesso!",
      }
    );
  }
  return (
    <CLCreateComponent title="Novo Cargo">
      <CLForm defaultStyle onSubmit={handleSubmit}>
        <Label title="TÍTULO:">
          <SistemaInput name="nome" />
        </Label>
        <Label title="CARGOS PADRÃO">
          <SistemaSelect
            firstOption
            name="cargoEmpresaCod"
            list={{ list: cargos, value: "cod", label: "nome" }}
          />
        </Label>
        <Label title="ACESSOS" />
        <AcessField>
          {modulos?.map((item) => (
            <li key={item?.path} className="li_primary">
              <FlexAcess>
                <CheckBox>
                  <CheckInput onChange={(e) => handleCheckAll(e)}></CheckInput>
                  <h6>{item?.nome}</h6>
                </CheckBox>
                <i
                  onClick={(e) => handleCollapse(e)}
                  className="material-icons green"
                >
                  add
                </i>
                <i
                  onClick={(e) => handleCollapse(e)}
                  className="material-icons red hide"
                >
                  remove
                </i>
              </FlexAcess>
              <SubItens>
                {item?.menus.map((menu) => (
                  <li key={menu?.path} className="li_secundary">
                    <CheckBox>
                      <CheckInput
                        onChange={() => handleCheckValues(menu?.cod)}
                        name={menu?.path}
                        value={menu?.cod}
                      ></CheckInput>
                      <h6>{menu?.nome}</h6>
                    </CheckBox>
                  </li>
                ))}
              </SubItens>
            </li>
          ))}
        </AcessField>
        <SistemaInput
          type="hidden"
          name="acessos"
          value={checkArray}
        ></SistemaInput>
      </CLForm>
    </CLCreateComponent>
  );
}

export default CreateModal;
