import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  align-items: center;
  width: 100%;

  .planetaDiv {
    height: 25em;
    width: 25%;
    margin-left: 5%;
    display: flex;
    flex-direction: row-reverse;
    margin-left: 10%;
    position: relative;
    animation: floating 5s ease infinite;

    img {
      position: absolute;
      width: auto;
      height: 135%;
      z-index: 0;
      right: -30em;
    }

    .toggleAnimation {
      right: 15em;
      top: -4em;
      animation: toggleAnimation 0.3s ease forwards;
    }

    @keyframes toggleAnimation {
      from {
        right: -40em;
      }
      to {
        right: -15em;
      }
    }
    @keyframes toggleAnimationEnd {
      from {
        right: -15em;
      }
      to {
        right: -40em;
      }
    }
  }
  @keyframes floating {
    0% {
      transform: translateY(0em);
    }

    50% {
      transform: translateY(2em);
    }
    100% {
      transform: translateY(0em);
    }
  }
`;

export const Content = styled.div`
  z-index: 1;
  width: ${(props) => (props.desktop || props.rotatedTablet ? "60%" : "80%")};
  min-height: 25em;
  border-radius: 3em;
  ${(props) => !props.desktop && !props.rotatedTablet && "margin: auto; text-align: center;"}
  padding: ${(props) => (props.desktop || props.rotatedTablet ? "2em 6em 3em 5em" : "2em")};
  background: #ffffff;
  box-shadow: 0.375em 0.375em 1.25em rgba(134, 134, 134, 0.7);
  margin-left: 2em;
  h1 {
    font-size: 1.8em;
    color: #333333;
    margin-bottom: 3vh;
  }

  p {
    padding: 0 0.5em;
    font-size: 1.2em;
  }

  @media (max-width: 993px) {
    p {
      font-size: 1em;
      text-align: left;
    }
    & {
      border-radius: 1em;
      width: 90%;
      margin-top: -3.5em;
      margin-left: auto;
    }
    h1 {
      position: absolute;
      top: -6em;
      left: 2em;
      font-size: 1.2em;
      color: #fff;
    }
  }
`;

export const Navegacao = styled.button`
  background: none;
  border: none;
  ${(props) => props.selection && "border-bottom: 0.7vh solid #1CA9D0; color: #1CA9D0;"}
  font-weight: 700;
  margin-right: 3vw;
  font-size: 1.2em;
  padding: 0 1vw;
  margin-bottom: 8vh;

  @media (max-width: 700px) {
    font-size: 1em;
    ${(props) => props.selection && "border-bottom: 0.5vh solid #1CA9D0; color: #1CA9D0;"}
  }
  @media (max-width: 350px) {
    font-size: 0.8em;
    ${(props) => props.selection && "border-bottom: 0.3vh solid #1CA9D0; color: #1CA9D0;"}
  }
`;
