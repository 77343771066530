import * as AcompanhamentoEscolar from "./AcompanhamentoEscolar";
import * as GestaoFinanceira from "./GestaoFinanceira";
import { default as DashBoard } from "./DashBoard";
import * as Matriculas from "./Matriculas";

export default {
  DashBoard,
  AcompanhamentoEscolar,
  GestaoFinanceira,
  Matriculas,
};
