import React, { useState } from "react";

import api from "~/services/api";
import ShowContent from "./ShowContent";

import PadraoAlunosFiltro from "../../../../componentes/PadraoAlunosFiltro";
import { Container, ThisCard, ThisCardBody, ThisCardTitle, ThisCardFooter, ThisTag } from "./styles";
import TextContent from "~/componentes/TextContent";
import ShowLoading from "~/utils/ShowLoading";
import Pagination from "~/componentes/Pagination";
import handleDate from "~/utils/handleDate";

function ThisStatus({ thisData }) {
  const status = React.useMemo(() => {
    const { expirado, entregue, dataInicio } = thisData || {};
    const checkInicio = dataInicio && new Date(dataInicio).getTime();

    if (checkInicio && new Date().getTime() < checkInicio) {
      return <ThisTag status={0}>Aguardando início</ThisTag>;
    } else if (entregue) {
      return <ThisTag status={1}>Entregue</ThisTag>;
    } else if (expirado) {
      return <ThisTag status={2}>Não entregue</ThisTag>;
    } else {
      return <ThisTag>Disponível</ThisTag>;
    }
  }, [thisData]);

  return status;
}

function AvaliacoesDisponibilizadas() {
  const [showModal, setShowModal] = useState(false);
  const [avaliacoes, setAvaliacoes] = useState([]);
  const [pageData, setPageData] = useState();
  const [usuario, setUsuario] = useState();
  const [aluno, setAluno] = useState();
  const [alunoTurma, setAlunoTurma] = useState();
  const [alunoDisciplinaTurma, setAlunoDisciplinaTurma] = useState();

  async function handleUsuarioChange(data) {
    await ShowLoading(
      () =>
        api
          .get("padrao/AcompanhamentoEscolar/avaliacoesDisponibilizadas/avaliacoesTurmas", {
            params: { usuarioCod: data?.cod },
          })
          .then((r) => setAvaliacoes(r?.data || [])),
      { successModal: false, errorModal: false }
    );

    return data;
  }

  async function handleModal(thisData) {
    ShowLoading(
      () =>
        api
          .get("padrao/AcompanhamentoEscolar/avaliacoesDisponibilizadas/avaliacaoTurma", {
            params: { cod: thisData?.cod },
          })
          .then((r) => setShowModal(r?.data)),
      { successModal: false }
    );
  }

  const filteredAvaliacoes = React.useMemo(() => {
    return avaliacoes.filter((av) => {
      const adt = av?.alunoDisciplinaTurma;
      const alt = adt?.alunoTurma;
      const alu = alt?.aluno;
      if (alunoDisciplinaTurma?.cod) {
        return alunoDisciplinaTurma.cod === adt?.cod;
      } else if (alunoTurma?.cod) {
        return alunoTurma.cod === alt?.cod;
      } else if (aluno?.cod) {
        return aluno.cod === alu?.cod;
      }
      return true;
    });
  }, [aluno, alunoDisciplinaTurma, alunoTurma, avaliacoes]);

  return (
    <>
      {showModal && <ShowContent mountedData={showModal} usuario={usuario} outClick={() => setShowModal(false)} />}
      <PadraoAlunosFiltro
        title="Filtrar Avaliação"
        onChangeUsuario={handleUsuarioChange}
        usuarioSetter={setUsuario}
        alunoSetter={setAluno}
        alunoTurmaSetter={setAlunoTurma}
        alunoDisciplinaTurmaSetter={setAlunoDisciplinaTurma}
      />

      <Container>
        {Array.isArray(avaliacoes) && avaliacoes.length > 0 ? (
          <>
            {pageData?.map((thisData, i) => {
              const { dataInicio, dataFim, avaliacaoTurma, filial } = thisData || {};
              const { avaliacao, disciplinaTurma } = avaliacaoTurma || {};
              const { disciplinaFilial } = disciplinaTurma || {};
              const { turma } = thisData?.alunoDisciplinaTurma?.alunoTurma || {};

              return (
                <ThisCard onClick={() => handleModal(thisData)} data={thisData} key={thisData?.cod || i}>
                  <ThisCardTitle>
                    <h3>#{thisData?.cod}</h3>
                    <span>
                      {handleDate(dataInicio)?.dmy} até {handleDate(dataFim)?.dmy}
                    </span>
                  </ThisCardTitle>
                  <ThisCardBody>
                    <div className="ThisCardBody_title_tur_disc_div">
                      <TextContent lines={1} setType="h2">
                        {avaliacao?.nome}
                      </TextContent>
                      <TextContent lines={1} setType="p" className="ThisCardbody_turma_disciplina">
                        {filial?.nomeFantasia} / {turma?.nome} / {disciplinaFilial?.nome}
                      </TextContent>
                    </div>
                    <TextContent lines={2} className="ThisCardBody_TextContent_pre">
                      {avaliacao?.descricao}
                    </TextContent>
                  </ThisCardBody>
                  <ThisCardFooter>
                    <ThisStatus {...{ thisData }} />
                    {thisData?.corrigido && thisData?.expirado && <ThisTag status={1}>Corrigido</ThisTag>}
                  </ThisCardFooter>
                </ThisCard>
              );
            })}
            <Pagination data={filteredAvaliacoes} size={6} getInfo={({ pageData }) => setPageData(pageData)} />
          </>
        ) : (
          <h4 style={{ textAlign: "center" }}>Não há avaliações disponíveis.</h4>
        )}
      </Container>
    </>
  );
}

export default AvaliacoesDisponibilizadas;
