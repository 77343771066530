import React from "react";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import ChangeIndexOrder from "~/utils/ChangeIndexOrder";

import { Container, QuestaoItem } from "./styles";

function RespostaObjetiva({ handleChange, questao, respostaQuestoes, respostaQuestao }) {
  const [respostas, setRespostas] = React.useState([]);
  const [respondido, setRespondido] = React.useState([]);

  React.useEffect(() => {
    let thisResult = [];
    let checkResposta = false;
    if (Array.isArray(questao?.itens)) {
      questao.itens.forEach((item) => {
        const thisResp = respostaQuestao?.respostasItens?.find(
          (ri) => ri?.questaoItemCod === item.cod
        );

        const marcado = thisResp ? "true" : undefined;

        if (marcado) {
          checkResposta = true;
        }

        thisResult.push({
          marcado,
          enunciado: item?.enunciado || "",
          correto: item?.gabarito ? "true" : undefined,
        });
      });
    }

    setRespostas(thisResult);
    setRespondido(checkResposta);
  }, [questao, respostaQuestao]);

  return (
    <Container>
      {respondido ? (
        respostas?.map((item, i) => (
          <QuestaoItem
            key={item?.cod || i}
            marcado={item.marcado}
            correto={item.correto}
            className="col s12 m12 l6"
            title={`${ChangeIndexOrder(i)}) ${item.marcado ? "Marcado" : ""}`}
          >
            <SistemaTextArea rows="5" readOnly value={item.enunciado} />
          </QuestaoItem>
        ))
      ) : (
        <h4 className="RespostaObjetiva_h4_naoRespondido">Questão não respondida.</h4>
      )}
    </Container>
  );
}

export default RespostaObjetiva;
