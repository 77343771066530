import React from "react";
import { useSelector } from "react-redux";
import Carregando from "~/pages/Sistema/componentes/Carregando";
import ConfirmacaoInterna from "~/pages/Sistema/componentes/ConfirmacaoInterna";
import ErrorModal from "~/pages/Sistema/componentes/ErrorModal";
import SuccessModal from "~/pages/Sistema/componentes/SuccessModal";

// import { Container } from './styles';

function GlobalModals() {
  const { errorModal, loadingModal, confirmacaoModal, successModal } =
    useSelector((state) => state) || {};
  return (
    <>
      {errorModal?.ativo && <ErrorModal />}
      {successModal?.ativo && <SuccessModal />}
      {loadingModal && <Carregando />}
      {confirmacaoModal?.ativo && <ConfirmacaoInterna />}
    </>
  );
}

export default GlobalModals;
