import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { Container } from "./styles";
import { Materialize } from "~/styles/materializeGrid";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import ColoredFileInput from "~/componentes/CustomForm/FileInput/styles/ColoredFileInput";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import AulaLinks from "./AulaLinks";

function ShowContent({ mountedData, outClick }) {
  const { dataDisponibilizacao, selectedDisciplinaTurma, arquivos, filial, professor, links } = mountedData || {};
  const { disciplinaFilial, turma } = selectedDisciplinaTurma || {};

  return (
    <Container title="Avaliação" outClick={outClick}>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          <div className="row">
            <Label title="Título:" text={mountedData?.titulo || ""} className="col s12 m12 l12" />
            <Label title="Escola:" text={filial?.nomeFantasia || ""} className="col s12 m12 l4" />
            <Label title="Turma:" text={turma?.nome || ""} className="col s12 m12 l4" />
            <Label title="Disciplina:" text={disciplinaFilial?.nome || ""} className="col s12 m12 l4" />
            <Label title="Professor:" text={professor?.usuario?.nome || ""} className="col s12 m12 l8" />
            <Label title="Disponibilização:" text={dataDisponibilizacao} className="col s12 m12 l4" />
            <Label title="Descrição:" className="col s12 m12 l12">
              <SistemaTextArea readOnly value={mountedData?.descricao || ""} />
            </Label>

            <AulaLinks {...{ links }} />

            {arquivos?.length > 0 && (
              <>
                <Label title="Arquivos" className="col s12 m12 12" />
                {arquivos?.map((arq, i) => (
                  <div key={arq?.cod || i} className="Label_arquivos_file_input col s12 m12 l6">
                    <ColoredFileInput readOnly defaultFile={arq} />
                  </div>
                ))}
              </>
            )}
          </div>
        </Materialize>
      </CustomScrollBar>
    </Container>
  );
}

export default ShowContent;
