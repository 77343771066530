import React from "react";

import BarChart from "./BarChart";
import CardList from "./CardList";
import { Container } from "./styles";

function FirstSection({ filteredData }) {
  return (
    <Container>
      <BarChart stats={filteredData?.mediaAnual} />
      <CardList stats={filteredData} />
    </Container>
  );
}

export default FirstSection;
