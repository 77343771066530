import React from "react";
import { Container, Card } from "./styles";
import TextContent from "~/componentes/TextContent";
import { SLEditButton, SLDeleteButton, SLShowButton, SLShareButton } from "~/pages/Sistema/componentes/SistemaList";
import { useSelector } from "react-redux";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import Pagination from "~/componentes/Pagination";
import handleDate from "~/utils/handleDate";

function CustomBodyContent({ data, tabFilter }) {
  const [pageData, setPageData] = React.useState([]);
  const {
    user: { matricula },
  } = useSelector((state) => state);

  return (
    <SistemaComponenteInterno>
      <Container>
        {pageData?.map((thisData, i) => {
          const isProfessor = matricula?.matriculaCod === thisData?.professorCod;
          return (
            <Card nivel={thisData?.nivel?.nome} key={thisData?.cod || i}>
              <div>
                <TextContent relativeHeight={false} lines={1} className="Avaliacao_Card_titulo">
                  {thisData?.titulo}
                </TextContent>
                <TextContent relativeHeight={false} className="Avaliacao_Card_enunciado" lines={4}>
                  {thisData?.descricao}
                </TextContent>
              </div>
              <div className="Avaliacao_Card_footer">
                {/* <div>{thisData?.arquivos?.length > 0 && <i className="material-icons">attachment</i>}</div> */}
                <TextContent relativeHeight={false} className="Avaliacao_Card_enunciado" lines={1}>
                  {handleDate(thisData?.createdAtDate)?.dmy}
                </TextContent>
                <div className="Avaliacao_Card_footer_buttons">
                  <SLShowButton data={thisData} />
                  {thisData.disponibilizado && tabFilter?.reutilizavel && <SLShareButton data={thisData} />}
                  {isProfessor && <SLEditButton data={thisData} />}
                  {!thisData?.disponibilizado && isProfessor && <SLDeleteButton data={thisData} />}
                </div>
              </div>
            </Card>
          );
        })}
      </Container>

      <Pagination
        data={data}
        getInfo={({ pageData }) => {
          setPageData(pageData);
        }}
        size={6}
      />
    </SistemaComponenteInterno>
  );
}

export default CustomBodyContent;
