import styled from "styled-components";
import { SLTable } from "~/pages/Sistema/componentes/SistemaList";

export const ThisTable = styled(SLTable)`
  table-layout: fixed;

  td:not(:last-child),
  th:not(:last-child) {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  td:first-child,
  th:first-child {
    width: 35%;
  }

  td:nth-child(2),
  th:nth-child(2) {
    width: 22%;
  }

  td:nth-child(3),
  th:nth-child(3) {
    width: 25%;
  }

  td:nth-child(4),
  th:nth-child(4) {
    width: 20%;
  }
`;
