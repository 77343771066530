import React, { useEffect, useState } from "react";

import api from "~/services/api";
import EditContent from "./EditContent";
import ShowContent from "./ShowContent";
import CreateContent from "./CreateContent";
import SistemaList, {
  SLCreateModal,
  SLEditModal,
  SLShowModal,
  SLTable,
  SLTableCell,
} from "~/pages/Sistema/componentes/SistemaList";

export default function Funcionarios() {
  const [cargos, setCargos] = useState([]);
  const [modulos, setModulos] = useState([]);
  const [mountedData, setMountedData] = useState();

  useEffect(() => {
    api
      .get("filial/cargosFilial")
      .then((response) => setCargos(response?.data));
    api.get("filial/acessos").then((response) => setModulos(response?.data));
  }, []);

  return (
    <SistemaList
      requests={{
        findAll: () => api.get("filial/funcionariosFilial"),
        findOne: (funcionario) =>
          api.get("filial/funcionarioFilial", {
            params: { cod: funcionario.cod },
          }),
        deleteOne: (funcionario) =>
          api.delete("filial/funcionarioFilial", {
            data: { cod: funcionario.cod },
          }),
      }}
      defaultStyle
    >
      <SLTable>
        <SLTableCell inputfilter="Nome" name="nome">
          NOME
        </SLTableCell>
        <SLTableCell
          selectfilter={{ list: cargos, value: "nome", outLabel: "Cargo" }}
          name="cargoFilial"
        >
          CARGO
        </SLTableCell>
        <SLTableCell inputfilter="Email" name="email">
          EMAIL
        </SLTableCell>
      </SLTable>

      <SLShowModal title="Dados do Funcionário" setter={setMountedData}>
        {mountedData && modulos && (
          <ShowContent {...{ mountedData, modulos }} />
        )}
      </SLShowModal>

      <SLEditModal title="Atualizar Funcionário" setter={setMountedData}>
        {mountedData && modulos && (
          <EditContent {...{ cargos, mountedData, modulos }} />
        )}
      </SLEditModal>

      <SLCreateModal title="Cadastrar Funcionário">
        {modulos && <CreateContent {...{ cargos, modulos }} />}
      </SLCreateModal>
    </SistemaList>
  );
}
