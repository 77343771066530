import React, { useState, useEffect } from "react";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import { FlexDiv } from "~/componentes/StyledComponents/FlexDiv";
import Label from "~/componentes/CustomForm/Label";
import { CheckCustom, Filter } from "../styles";

function QuestoesFilter({
  questoesDisciplina,
  showOnly,
  questoesAtivas,
  startSelected,
  setQuestoesFiltradas,
}) {
  const [selecionadas, setSelecionadas] = useState(startSelected);
  const [questaoFiltro, setQuestaoFiltro] = useState({ key: "nome", value: "" });

  useEffect(() => {
    setQuestoesFiltradas(() => {
      if ((showOnly || !selecionadas) && (!questaoFiltro?.value || questaoFiltro?.value === "")) {
        return questoesDisciplina;
      } else {
        return questoesDisciplina.filter((e) => {
          if (selecionadas && !questoesAtivas.find((q) => q === e.cod)) {
            return false;
          }
          return e?.[questaoFiltro?.key]?.toString()?.toLowerCase()?.includes?.(questaoFiltro?.value);
        });
      }
    });
  }, [questaoFiltro, selecionadas, questoesDisciplina, setQuestoesFiltradas, questoesAtivas, showOnly]);

  return (
    <Label
      style={{ padding: "0.6em" }}
      title={
        !showOnly
          ? `Selecione as questões: ${questoesAtivas?.length}/${questoesDisciplina.length}`
          : "Questões"
      }
    >
      <FlexDiv>
        <Filter>
          <SistemaSelect
            setter={(key) => setQuestaoFiltro((prev) => ({ ...prev, key }))}
            list={{
              list: [
                { label: "Nome", value: "nome" },
                { label: "Código", value: "cod" },
              ],
              value: "value",
              label: "label",
            }}
            defaultValue={questaoFiltro?.key}
          />
          <SistemaInput
            setter={(value) => setQuestaoFiltro((prev) => ({ ...prev, value }))}
            placeholder="Procurar Questão"
          />
        </Filter>
        {!showOnly && (
          <div style={{ width: "fit-content", display: "flex", alignItems: "center" }}>
            Selecionadas
            <CheckCustom
              defaultHtml
              checked={selecionadas}
              onChange={(e) => setSelecionadas(e.target.checked)}
            />
          </div>
        )}
      </FlexDiv>
    </Label>
  );
}

export default QuestoesFilter;
