import React, { useLayoutEffect, useState } from "react";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";

import CustomScrollBar from "~/componentes/CustomScrollBar";
import { Materialize } from "~/styles/materializeGrid";
import { Container } from "./styles";
import { SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import api from "~/services/api";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import { useSelector } from "react-redux";

function CreateContent({ professor }) {
  const {
    user: { perfil },
  } = useSelector((state) => state) || {};
  const [alunos, setAlunos] = useState([]);
  const [responsavel, setResponsavel] = useState();

  function handleSubmit(data, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let { nomeSecretario, ...resto } = data;
        let response = await api.post("professor/reuniaoaluno", { ...resto });
        if (response?.status === 200) {
          closeReload && closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao criar essa Reunião, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja criar essa Reunião?`,
        successModal: `Reunião cadastrada com sucesso!`,
      }
    );
  }

  useLayoutEffect(() => {
    api.get("professor/alunos").then((r) => setAlunos(r?.data || []));
  }, []);

  return (
    <Container onSubmit={handleSubmit}>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          <div className="row">
            <Label title="DATA INICIO:" className="col s12 m12 l3">
              <SistemaInput name="dataInicio" type="date" required />
            </Label>
            <Label title="HORA INICIO:" className="col s12 m12 l3">
              <SistemaInput name="horaInicio" type="time" required />
            </Label>
            <Label title="DATA FINAL:" className="col s12 m12 l3">
              <SistemaInput name="dataFinal" type="date" required />
            </Label>
            <Label title="HORA FINAL:" className="col s12 m12 l3">
              <SistemaInput name="horaFinal" type="time" required />
            </Label>
            <Label title="MINISTRO DA REUNIÃO:" className="col s12 m12 l6">
              <SistemaSelect
                name="usuarioMinistroCod"
                firstOption
                list={{
                  list: professor,
                  value: "usuario.cod",
                  label: "usuario.nome",
                }}
                defaultValue={perfil?.cod}
                required
              />
            </Label>
            <Label title="SECRETARIO RESPONSÁVEL:" className="col s12 m12 l6">
              <SistemaInput
                name="nomeSecretario"
                defaultValue={perfil?.nome}
                readOnly
              />
            </Label>
            <Label title="ALUNO:" className="col s12 m12 l6">
              <SistemaSelect
                name="alunoCod"
                firstOption
                list={{
                  list: alunos,
                  value: "cod",
                  label: "usuario.nome",
                }}
                onChange={(e) => {
                  let thisValue = e.currentTarget.value;
                  let thisAluno = alunos?.find?.(
                    (a) => String(a?.cod) === String(thisValue)
                  );
                  setResponsavel(thisAluno?.responsavel);
                }}
                required
              />
            </Label>
            <Label title="RESPONSAVEL PELO ALUNO:" className="col s12 m12 l6">
              <SistemaInput
                name="nomeResponsavel"
                defaultValue={responsavel?.nome}
                readOnly
              />
            </Label>
            <Label title="DESCRIÇÃO:" className="col s12 m12 l12">
              <SistemaTextArea name="descricao" required />
            </Label>
          </div>
        </Materialize>
      </CustomScrollBar>
      <SLModalFooter />
    </Container>
  );
}

export default CreateContent;
