import React, { useEffect } from "react";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";

import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import { useState } from "react";

import { Container, SubTitle } from "./styles";
import { SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import { Materialize } from "~/styles/materializeGrid";
import api from "~/services/api";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import setRegex from "~/utils/setRegex";
import FindOrCreateUser from "~/componentes/FindOrCreateUser";

function CreateEditContent({ mountedData, estados, condicoesPagamentos }) {
  const [cidades, setCidades] = useState([]);
  const [usuario, setUsuario] = useState();
  const [planos, setPlanos] = useState([]);

  useEffect(() => {
    mountedData &&
      api
        .get("admin/planos", {
          params: { empresaCod: mountedData?.cod, ativo: "true" },
        })
        .then((r) => setPlanos(r?.data || []));
  }, [mountedData]);

  function handleSubmit(data, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let response;

        if (mountedData) {
          response = await api.put("admin/empresa", {
            ...data,
            cod: mountedData.cod,
            usuario,
          });
        } else {
          response = await api.post("admin/empresa", { ...data, usuario });
        }

        if (response?.status === 200) {
          closeReload && closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao ${
            mountedData ? "atualizar" : "criar"
          } esse cliente, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja ${
          mountedData ? "atualizar" : "criar"
        } esse Cliente?`,
        successModal: `Cliente ${
          mountedData ? "atualizado" : "cadastrado"
        } com sucesso!`,
      }
    );
  }

  return (
    <Container onSubmit={handleSubmit}>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          <div className="row">
            {!mountedData && (
              <div>
                <SubTitle>Dados do Proprietário</SubTitle>
                <FindOrCreateUser
                  className="no-margin-bottom"
                  setter={setUsuario}
                />
              </div>
            )}
            <SubTitle>Dados da Empresa</SubTitle>
            <Label title="RAZÃO SOCIAL: *" className="col s12 m12 l5">
              <SistemaInput
                name="razaoSocial"
                maxLength={80}
                defaultValue={mountedData?.razaoSocial}
                required
              />
            </Label>
            <Label title="NOME FANTASIA: *" className="col s12 m12 l4">
              <SistemaInput
                name="nomeFantasia"
                maxLength={80}
                defaultValue={mountedData?.nomeFantasia}
                required
              />
            </Label>
            <Label title="CNPJ: *" className="col s12 m12 l3">
              <SistemaInput
                name="cnpj"
                onChange={setRegex.setCnpj}
                defaultValue={setRegex.setCnpj(mountedData?.cnpj)}
                required
              />
            </Label>
            <Label title="EMAIL DE CONTATO: *" className="col s12 m12 l6">
              <SistemaInput
                name="email"
                defaultValue={mountedData?.email}
                required
              />
            </Label>
            <Label title="TELEFONE 1: *" className="col s12 m12 l3">
              <SistemaInput
                name="telefone1"
                onChange={setRegex.setPhone}
                defaultValue={setRegex.setPhone(mountedData?.telefone1)}
                required
              />
            </Label>
            <Label title="TELEFONE 2:" className="col s12 m12 l3">
              <SistemaInput
                name="telefone2"
                onChange={setRegex.setPhone}
                defaultValue={setRegex.setPhone(mountedData?.telefone2)}
              />
            </Label>
            <Label title="SITE:" className="col s12 m12 l12">
              <SistemaInput name="site" defaultValue={mountedData?.site} />
            </Label>
            <Label title="CEP: *" className="col s12 m12 l3">
              <SistemaInput
                name="cep"
                onChange={setRegex.setCep}
                defaultValue={setRegex.setCep(mountedData?.cep)}
                required
              />
            </Label>
            <Label title="PAÍS: *" className="col s12 m12 l3">
              <SistemaInput
                name="pais"
                defaultValue={mountedData?.pais}
                required
              />
            </Label>
            <Label title="ESTADO: *" className="col s12 m12 l3">
              <SistemaSelect
                name="estadoCod"
                list={{ list: estados, value: "cod", label: "nome" }}
                setList={(e) => setCidades(e?.cidades || [])}
                defaultValue={mountedData?.estadoCod}
                required
              />
            </Label>
            <Label title="CIDADE: *" className="col s12 m12 l3">
              <SistemaSelect
                name="cidadeCod"
                list={{ list: cidades, value: "cod", label: "nome" }}
                defaultValue={mountedData?.cidadeCod}
                required
              />
            </Label>
            <Label title="BAIRRO: *" className="col s12 m12 l3">
              <SistemaInput
                name="bairro"
                maxLength={80}
                defaultValue={mountedData?.bairro}
                required
              />
            </Label>
            <Label title="LOGRADOURO: *" className="col s12 m12 l7">
              <SistemaInput
                name="logradouro"
                maxLength={120}
                defaultValue={mountedData?.logradouro}
                required
              />
            </Label>
            <Label title="NÚMERO: *" className="col s12 m12 l2">
              <SistemaInput
                type="number"
                name="numeroLogradouro"
                defaultValue={mountedData?.numeroLogradouro}
                required
              />
            </Label>
            <Label title="COMPLEMENTO:" className="col s12 m12 l7">
              <SistemaInput
                name="complementoLogradouro"
                maxLength={50}
                defaultValue={mountedData?.complementoLogradouro}
              />
            </Label>
            {!mountedData && (
              <div>
                <Label title="COND. PAGAMENTO: *" className="col s12 m12 l2">
                  <SistemaSelect
                    name="condicaoPagamentoCod"
                    list={{
                      list: condicoesPagamentos,
                      value: "cod",
                      label: "nome",
                    }}
                    defaultValue={mountedData?.plano?.condicaoPagamentoCod}
                    firstOption
                    required
                  />
                </Label>
                <Label
                  title="QUANTIDADE DE ALUNOS: *"
                  className="col s12 m12 l3"
                >
                  <SistemaInput
                    type="number"
                    isNumber
                    name="quantidadeAlunos"
                    defaultValue={mountedData?.plano?.quantidadeAlunos}
                    required
                  />
                </Label>
              </div>
            )}
            {mountedData && (
              <div>
                <Label title="PLANO: *" className="col s12 m12 l5">
                  <SistemaSelect
                    name="planoCod"
                    list={{ list: planos, value: "cod", label: "nome" }}
                    defaultValue={mountedData?.planoCod}
                    required
                  />
                </Label>
              </div>
            )}
            {mountedData && (
              <div>
                <Label title="TOKEN DE PAGAMENTO PUBLICO: *" className="col s12 m12 l5">
                  <SistemaInput
                    name="tokenPagamentoPub"
                    defaultValue={mountedData?.tokenPagamentoPub}
                    required
                  />
                </Label>
                <Label title="TOKEN DE PAGAMENTO PRIVADO: *" className="col s12 m12 l5">
                  <SistemaInput
                    name="tokenPagamentoPriv"
                    defaultValue={mountedData?.tokenPagamentoPriv}
                    required
                  />
                </Label>
              </div>
            )}
          </div>
        </Materialize>
      </CustomScrollBar>
      <SLModalFooter />
    </Container>
  );
}

export default CreateEditContent;
