/* eslint-disable eqeqeq */
import React from "react";

import { Container } from "./styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import DadosMatricula from "~/componentes/DadosMatricula";
import { SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import { Materialize } from "~/styles/materializeGrid";
import Label from "~/componentes/CustomForm/Label";
import Pendencias from "./Pendencias";

function ShowContent({ mountedData, series, dadosEnsinos }) {
  const [grupoDados, setGrupoDados] = React.useState();
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    const thisData = dadosEnsinos?.find(
      (e) => e?.cod === mountedData?.serie?.ensinoCod
    );

    setGrupoDados(thisData?.configuracoesMatriculaFilial);
  }, [dadosEnsinos, mountedData]);

  return (
    <Container>
      {showModal && (
        <Pendencias
          outClick={() => setShowModal(false)}
          {...{ mountedData, grupoDados, series }}
        />
      )}
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          <div className="row">
            <Label
              title="pendencias"
              text={mountedData?.pendencias}
              className="col s12 m12 l12"
            />
          </div>
        </Materialize>
        <DadosMatricula
          readOnly
          grupos={grupoDados}
          defaultValue={mountedData?.dados}
        />
      </CustomScrollBar>
      <SLModalFooter
        submitButton={{
          onClick: () => setShowModal(true),
          text: "Atualizar",
        }}
      />
    </Container>
  );
}

export default ShowContent;
