import styled, { keyframes } from "styled-components";

const floatingPla1 = keyframes`
  0%, 10%, 30%, 90% {
    transform: translateX(-0.2em) translateY(-0.3em);
  }
  10% {
    transform: translateX(-0.1em) translateY(0.1em);
  }
  20% {
    transform: translateX(0.2em) translateY(0.2em);
  }
  30% {
    transform: translateX(0em) translateY(0.2em);
  }
  40% {
    transform: translateX(0em) translateY(-0.2em);
  }
  50% {
    transform: translateX(-0.2em) translateY(-0.2em);
  }
  60% {
    transform: translateX(0.2em) translateY(-0.2em);
  }
  100% {
    transform: translateX(-0.2em) translateY(-0.3em);
  }
  
`;

const floatingPla2 = keyframes`
  0%, 10%, 30%, 90% {
    transform: translateX(0.2em) translateY(0.3em);
  }
  10% {
    transform: translateX(-0.1em) translateY(0.1em);
  }
  20% {
    transform: translateX(-0.2em) translateY(0.2em);
  }
  30% {
    transform: translateX(0em) translateY(-0.2em);
  }
  40% {
    transform: translateX(0em) translateY(-0.2em);
  }
  50% {
    transform: translateX(0.2em) translateY(-0.2em);
  }
  60% {
    transform: translateX(-0.2em) translateY(-0.2em);
  }
  100% {
    transform: translateX(0.2em) translateY(0.3em);
  }
  
`;

const floatingPla3 = keyframes`
  0%, 10%, 30%, 90% {
    transform: translateX(-0.2em) translateY(-0.1em);
  }
  10% {
    transform: translateX(0.1em) translateY(0.15em);
  }
  20% {
    transform: translateX(0.1em) translateY(-0.1em);
  }
  30% {
    transform: translateX(0em) translateY(0.2em);
  }
  40% {
    transform: translateX(0em) translateY(-0.2em);
  }
  50% {
    transform: translateX(0.1em) translateY(-0.1em);
  }
  60% {
    transform: translateX(0.2em) translateY(-0.2em);
  }
  100% {
    transform: translateX(-0.2em) translateY(-0.1em);
  }
  
`;

const floatingPla4 = keyframes`
  0%, 10%, 30%, 90% {
    transform: translateX(0) translateY(-0.1em);
  }
  10% {
    transform: translateX(0) translateY(0.1em);
  }
  20% {
    transform: translateX(0.1em) translateY(-0.1em);
  }
  30% {
    transform: translateX(0em) translateY(0.2em);
  }
  40% {
    transform: translateX(0.1em) translateY(-0.2em);
  }
  50% {
    transform: translateX(-0.1em) translateY(-0.1em);
  }
  60% {
    transform: translateX(0.2em) translateY(-0.2em);
  }
  100% {
    transform: translateX(0) translateY(-0.1em);
  }
  
`;

export const Container = styled.div`
  .planeta1 {
    position: fixed;
    height: 75%;
    right: -8em;
    bottom: -12em;
    animation: ${floatingPla1} 15s ease infinite;
  }
  .planeta2 {
    position: fixed;
    height: 35%;
    left: 28em;
    top: 6em;
    animation: ${floatingPla2} 15s ease infinite;
  }
  .planeta3 {
    position: fixed;
    height: 15%;
    left: 24em;
    top: 32em;
    animation: ${floatingPla3} 20s ease infinite;
  }
  .planeta4 {
    position: fixed;
    height: 65%;
    left: -14em;
    top: 8em;
    animation: ${floatingPla4} 15s ease infinite;
  }
  .planeta5 {
    position: fixed;
    height: 15%;
    left: 64em;
    top: 11em;
    animation: ${floatingPla4} 15s ease infinite;
  }
  @media (max-width: 600px) {
    .planeta1,
    .planeta2,
    .planeta3,
    .planeta4,
    .planeta5 {
      display: none;
    }
  }
  @media (max-width: 800px) {
    .planeta2 {
      display: none;
    }
    .planeta3 {
      left: 4em;
    }
    .planeta4 {
      height: 70%;
      right: -13em;
      bottom: -12em;
    }
  }
`;
