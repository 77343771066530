import React from "react";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTableCell } from "~/pages/Sistema/componentes/SistemaList";
import { ThisTable } from "./styles";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";

function TableComponent({ setTabFilter }) {
  return (
    <>
      <TabsWrapper tabHeight={2.4}>
        <Tab materialIcon="north" onClick={() => setTabFilter({ ativo: true })}>
          Versão Atual
        </Tab>
        <Tab materialIcon="south" onClick={() => setTabFilter({ ativo: false })}>
          Versões Anteriores
        </Tab>
      </TabsWrapper>

      <SistemaComponenteInterno>
        <ThisTable>
          <SLTableCell name="nome" inputfilter>
            Nome
          </SLTableCell>
        </ThisTable>
      </SistemaComponenteInterno>
    </>
  );
}

export default TableComponent;
