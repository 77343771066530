import React, { useState } from "react";

// import { Container } from "./styles";

export default function CarrocelRoleta({
  className,
  children,
  leftButton,
  rightButton,
  ...rest
}) {
  const [[Esquerda, Centro, Direita], setContentOrder] = useState(children);

  function changeOrder(direita) {
    if (direita) {
      setContentOrder([Direita, Esquerda, Centro]);
    } else {
      setContentOrder([Centro, Direita, Esquerda]);
    }
  }

  function createElement(element, props = {}) {
    const { className, onClick } = props;
    return React.createElement(element.type, {
      ...element.props,
      onClick,
      className: element.props.className
        ? element.props.className + ` ${className}`
        : className,
    });
  }

  function checkCreateElement(firstTryElement, elseElement, props = {}) {
    if (firstTryElement) {
      return createElement(firstTryElement, {
        ...props,
        className: props.className.firstTry,
      });
    }

    return createElement(elseElement, {
      ...props,
      className: props.className.secondTry,
    });
  }

  return (
    <div props={{ ...rest }} className={`${className} carrocelRoleta`}>
      {checkCreateElement(leftButton, Esquerda, {
        className: {
          firstTry: "carrocelRoletaLeftButton",
          secondTry: "carrocelRoletaContent0",
        },
        onClick: () => changeOrder(false),
      })}
      {createElement(Centro, {
        className: "carrocelRoletaContent1",
      })}
      {checkCreateElement(rightButton, Direita, {
        className: {
          firstTry: "carrocelRoletaRightButton",
          secondTry: "carrocelRoletaContent2",
        },
        onClick: () => changeOrder(true),
      })}
    </div>
  );
}
