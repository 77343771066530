import React from "react";
import Label from "~/componentes/CustomForm/Label";
import getYoutubeEmbebedUrl from "~/utils/getYoutubeEmbebedUrl";

function AulaLinks({ links }) {
  const videos = React.useMemo(() => {
    const result = [];

    if (Array.isArray(links)) {
      links.forEach((e) => {
        const { embebedUrl } = getYoutubeEmbebedUrl(e?.link);

        if (embebedUrl) {
          result.push(embebedUrl);
        }
      });
    }

    return result;
  }, [links]);

  return (
    <>
      {videos?.length > 0 && (
        <Label col s12 m12 l12 title="Vídeos:">
          {videos?.map?.((link, index) => (
            <div key={index} style={{ width: "100%" }}>
              <iframe style={{ margin: "auto", display: "block" }} width="420" height="315" title={link} src={link}></iframe>
            </div>
          ))}
        </Label>
      )}
    </>
  );
}

export default AulaLinks;
