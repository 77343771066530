import styled from "styled-components";
import Input from "../..";

const ThisEduquemeInput = styled(Input)`
  background-color: #256172;
  color: #fff !important;
  font-size: 1em;
  line-height: 1;
  height: 2.4em;
  border-radius: 0.3em;
  border: 0.15em solid #1f3346;
  padding: 0em 0.5em;
  align-self: center;
  width: 10em;
  max-width: 100%;

  &::placeholder {
    color: #fff;
  }

  &:disabled {
    background-color: #256172aa;
  }

  ::-webkit-calendar-picker-indicator {
    display: none;
  }

  :-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #256172 inset !important;
    box-shadow: 0 0 0px 1000px #256172 inset !important;
    -webkit-text-fill-color: #fff !important;
  }
`;

export default ThisEduquemeInput;
