import React from "react";
import { Link } from "react-router-dom";

import LogoPadrao from "~/assets/logos/Escola_logo.png";
import facebookIcon from "~/assets/icones/facebook.svg";
import instagramIcon from "~/assets/icones/instagram.svg";
import urlClickIcon from "~/assets/icones/urlClick.svg";
import youtubeIcon from "~/assets/icones/youtube.svg";
import { Container } from "./styles";

function LogoIcon({ logo, link }) {
  return (
    <>
      {logo && typeof link === "string" && (
        <Link target="_blank" to={`//${link}`}>
          <img style={{ height: "2em", width: "auto", margin: "0 0.5em" }} src={logo} alt="Facebook icon" />
        </Link>
      )}
    </>
  );
}

function FormContent({ filial }) {
  return (
    <Container>
      <img src={filial?.imagemPerfilMatricula?.url || LogoPadrao} alt="" />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <LogoIcon link={filial?.facebook} logo={facebookIcon} />
        <LogoIcon link={filial?.youtube} logo={youtubeIcon} />
        <LogoIcon link={filial?.instagram} logo={instagramIcon} />
        <LogoIcon link={filial?.site} logo={urlClickIcon} />
      </div>
      <h3>{filial?.nomeFantasia}</h3>
      <p>{filial?.sobre || "Não há descrições no momento..."}</p>
    </Container>
  );
}

export default FormContent;
