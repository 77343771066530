import React from "react";
import customListStore, { customListToggleModal } from "../../customListStore";
import { useSelector } from "react-redux";
import { Container } from "./styles";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";

/**
 * @param {Object} props
 * @param {Object} props.cancelButton Aceita todas as props de um button e ColoredButton.
 * @param {Object} props.cancelButton.label Altera o texto do botão.
 * @param {Object} props.cancelButton.onClick Se true, altera o padrão do closeModal para false.
 * @param {Boolean} props.cancelButton.closeModal Padrão true, caso não seja passado um onClick, e false caso seja. Se true o modal será fechado ao clicar no cancelButton.
 * @param {Object} props.confirmButton Aceita todas as props de um button e ColoredButton.
 * @param {Object} props.confirmButton.label Altera o texto do botão.
 */
function StyledCustomListModalButtons(props) {
  const { children, cancelButton = {}, confirmButton = {}, className, ...rest } =
    props || {};
  const { dispatch } = React.useContext(customListStore);
  const {
    confirmacaoModal,
    errorModal: checkErrorModal,
    successModal: checkSuccessModal,
  } = useSelector((state) => state) || {};

  function closeModal(e, confirm) {
    const { closeModal, onClick } = (confirm ? confirmButton : cancelButton) || {};

    const cancelButtonCondition = closeModal || (!onClick && closeModal !== false);
    const checkCloseModal = confirm ? closeModal : cancelButtonCondition;

    if (
      checkCloseModal &&
      !confirmacaoModal?.ativo &&
      !checkErrorModal?.ativo &&
      !checkSuccessModal?.ativo
    ) {
      dispatch(customListToggleModal(false));
    }

    onClick && onClick(e);
  }

  return (
    <Container {...rest}>
      <ColoredButton
        redLayout
        small
        type="button"
        onClick={(e) => closeModal(e, false)}
        {...cancelButton}
      >
        {cancelButton?.label || "Cancelar"}
      </ColoredButton>
      <ColoredButton
        type="submit"
        greenLayout
        small
        onClick={(e) => closeModal(e, true)}
        {...confirmButton}
      >
        {confirmButton?.label || "Confirmar"}
      </ColoredButton>
    </Container>
  );
}

export default StyledCustomListModalButtons;
