import React, { useState } from "react";

import api from "~/services/api";
import CreateEditContent from "./CreateEditContent";
import ShowContent from "./ShowContent";
import { SLCreateModal, SLShowModal, SLEditModal } from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import TableComponent from "./TableComponent";
import { useEffect } from "react";
import ShowConfirmacao from "~/utils/ShowConfirmacao";

function TituloReceber() {
  const [mountedData, setMountedData] = useState();
  const [tabFilter, setTabFilter] = useState({ baixaTitulo: "NB" });

  return (
    <Container
      requests={{
        findAll: () => api.get("filial/titulosreceber", { params: { paranoid: false } }),
        deleteOne: (thisData) => api.delete("filial/tituloreceber", { data: { cod: thisData.cod } }),
        findOne: (thisData) => api.get("filial/tituloreceber", { params: { cod: thisData.cod } }),
        changeStatusOne: (thisData) => api.put("/filial/tituloreceber/change", { params: { cod: thisData.cod } })
      }}
      dataFilter={tabFilter}
    >
      <TableComponent {...{ setTabFilter, tabFilter }} />

      <SLShowModal title="Titulo" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLEditModal title="Atualizar Titulo" setter={setMountedData}>
        <CreateEditContent {...{ mountedData }} />
      </SLEditModal>

      <SLCreateModal title="Novo Titulo">
        <CreateEditContent />
      </SLCreateModal>
    </Container>
  );
}

export default TituloReceber;
