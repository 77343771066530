import React, { useEffect, useRef, useCallback } from "react";

import { useSelector } from "react-redux";

import { ModalStyle } from "./styles";
import { IsObject } from "~/utils/CheckDataType";
import { useMemo } from "react";

/**
 * @param {Object} props
 * @param {Function} props.outClick Aceita uma função que será realiza ao clicar fora do modal.
 * @param {Object} props.checkRedux Padrão true, ativa todas as checagens do redux. Se false, não checará se existe modais do redux ativos como loading, confirmação ou erro.
 * @param {Boolean} props.checkRedux.confirm Ativa a checagem de confirmação.
 * @param {Boolean} props.checkRedux.error Ativa a checagem de error.
 * @param {Boolean} props.checkRedux.loading Ativa a checagem de loading.
 * @param {Boolean} props.checkRedux.success Ativa a checagem de success.
 */
function Modal({ children, checkRedux = true, outClick, outClose, ...rest }) {
  const { errorModal, loadingModal, confirmacaoModal, successModal } =
    useSelector((state) => state) || {};

  const modalRef = useRef();

  const reduxCheck = useMemo(() => {
    if (checkRedux) {
      if (IsObject(checkRedux)) {
        const thisConfirm = checkRedux.confirm ? !confirmacaoModal?.ativo : true;
        const thisError = checkRedux.error ? !errorModal?.ativo : true;
        const thisLoading = checkRedux.loading ? !loadingModal?.ativo : true;
        const thisSuccess = checkRedux.success ? !successModal?.ativo : true;

        return thisConfirm && thisError && thisLoading && thisSuccess;
      } else {
        return (
          !confirmacaoModal?.ativo &&
          !errorModal?.ativo &&
          !loadingModal?.ativo &&
          !successModal?.ativo
        );
      }
    } else {
      return true;
    }
  }, [checkRedux, confirmacaoModal, errorModal, loadingModal, successModal]);

  const handleClick = useCallback(
    (e) => {
      // if (!modalRef?.current?.contains(e.target) && reduxCheck) {
      if (outClose && !e?.path?.includes?.(modalRef?.current) && reduxCheck) {
        outClick && outClick(false);
      }
    },
    [outClick, outClose, reduxCheck]
  );

  useEffect(() => {
    const init = setTimeout(() => window.addEventListener("click", handleClick), 50);

    return () => {
      window.removeEventListener("click", handleClick);
      clearTimeout(init);
    };
  }, [handleClick]);

  return (
    <ModalStyle ref={modalRef} {...rest}>
      {children}
    </ModalStyle>
  );
}

export default Modal;
