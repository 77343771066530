import React from "react";
import {
  EduquemeInput,
  SistemaInput,
} from "~/componentes/CustomForm/Input/styles";
import handleCep from "~/utils/handleCep";
import { IsObject } from "~/utils/CheckDataType";
import CustomToast from "~/componentes/CustomToast";
import estados from "~/config/estados";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";

// import { Container } from './styles';

function Isaluno({ setDados, grupo, blueLayout, props }) {
  const ThisInput = blueLayout ? EduquemeInput : SistemaInput;
  const inputRef = React.useRef(null);

  async function handleChangeValue(e) {
    if (grupo?.chave && setDados) {
      const newValue = e.currentTarget.checked;

      let user = localStorage.getItem('persist:eduqueMe');
      user = JSON.parse(user);
      user = JSON.parse(user.user);

      if(newValue) {
        setDados((prev) => ({
          ...prev,
          [grupo.chave]: { 
          ...prev?.[grupo.chave], 
          nome: user.perfil.nome, 
          email: user.perfil.email, 
          cpf: user.perfil.cpf, 
          nascimento: user.perfil.nascimento, 
          estado: user.perfil.estado || "",
          cidade: user.perfil.cidade || "",
          bairro: user.perfil.bairro || "",
          logradouro: user.perfil.logradouro || "",
          telefone1: user.perfil.telefone1 || "",
          telefone2: user.perfil.telefone2 || "",
          whatsapp: user.perfil.whatsapp || "", },
        }));
      }
      
      setDados((prev) => ({
        ...prev,
        [grupo.chave]: { ...prev?.[grupo.chave], isaluno: newValue },
      }));

      if(inputRef.current){
        let form = inputRef.current.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;
        form.querySelectorAll('input').forEach(e => {
          let elementPai = e.parentElement;
          let label = elementPai.querySelector('p');
          label && (label = label.innerText)
          if(label && (label == "NOME" || label == "EMAIL" || label == "CPF" || label == "DATA DE NASCIMENTO")){
              console.log(e);
              newValue && e.setAttribute('disabled', 'true');
              !newValue && e.removeAttribute('disabled');
          }
        })
      }
    }
  }

  return (
    <div className="Label_inputWithButton" ref={inputRef}>
      <ThisInput style={{width: "initial", marginRight: "10px", marginLeft: "15px"}} type="checkbox" {...props} onChange={handleChangeValue} />
      <p>Você é o aluno?</p>
    </div>
  );
}

export default Isaluno;
