import styled from "styled-components";
import Button from "~/componentes/CustomForm/Button";

export const Container = styled.div`
  max-width: 100%;
  height: 13em;
  max-height: ${({ maxHeight }) =>
    maxHeight
      ? `calc(${typeof maxHeight === "number" ? `${maxHeight}em` : maxHeight} + 0.5em)`
      : "13em"};
  border-radius: 0.5em;
  margin: auto;
  border: dashed #dae6e8 0.125em;

  .PreviewFileInput_Button {
    display: flex;
    justify-content: center;
    height: 2em;
  }

  .PreviewFileStyle_Img {
    width: 100%;
    height: calc(100% - 2.5em);
  }
`;

export const PreviewImageStyle = styled.img`
  cursor: pointer;
  max-width: 100%;
  height: auto;
  max-height: ${({ maxHeight }) =>
    maxHeight
      ? `${typeof maxHeight === "number" ? `${maxHeight}em` : maxHeight}`
      : "12em"};
  display: block;
  margin: auto;
  /* border: solid 0.1em #dae6e8; */
  border-radius: 0.5em;
`;

export const FileButton = styled(Button)`
  color: #7b898c;
  display: block;
  display: flex;
  align-items: center;
  border: none;
  background: inherit;
  /* margin-left: 1em; */

  i {
    position: relative;
  }

  i::before {
    content: "";
    position: absolute;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    background: #1b9cc022;
    -webkit-transform: translate(-50%, -50%) scale(0);
    -ms-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
  }

  i:hover::before {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }
`;

export const AddFile = styled(Button)`
  cursor: pointer;
  background: #ffffff;
  /* border-radius: 0.5em; */
  transition: 0.1s ease;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25em;
  height: 15em;
  max-width: 100%;
  max-height: 100%;
  margin: auto;

  h4 {
    display: flex;
    align-items: center;
    color: #c4c4c4;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875em;
    line-height: 1.188em;
    text-transform: uppercase;
    transition: 0.3s ease;
  }
`;
