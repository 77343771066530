export const cargoPadrao = {
  proprietarioEmpresa: "Proprietário",
  proprietarioFilial: "Proprietário",
  professor: "Professor",
  aluno: "Aluno",
  padrao: "Padrao",
  outros: "Outros",
};

export const tipoAcesso = {
  admin: "admin",
  empresa: "empresa",
  filial: "filial",
  aluno: "aluno",
  professor: "professor",
  padrao: "padrao",
};

export const tipoQuestao = {
  multiplaEscolha: "Multipla Escolha",
  dissertativa: "Dissertativa",
};

export const eduqueMeEmpresa = "Objetive TI Soluções Tecnológicas";

export const modalTimeout = 2000;

const definicoesPadrao = { cargoPadrao, tipoAcesso, tipoQuestao, eduqueMeEmpresa };

export default definicoesPadrao;
