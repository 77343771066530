import styled from "styled-components";
import { Form } from "~/componentes/CustomForm";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";

export const TriggerButton = styled.button`
  background: none;
  border: none;
  color: ${({ desktop }) => (desktop ? "#fff" : "#293f57")};
  display: flex;
  align-items: center;
`;

export const Content = styled(SistemaFixedModal)`
  z-index: 10;
  width: 55em;
  max-width: 98vw;
  padding: 0.5em 1em 1em;

  .Perfil_SistemaFixedModal_body {
    border-bottom: 0.1em solid #efefefee;
    display: flex;
    align-items: center;
    padding: 0.2em 0;
  }
`;

export const PerfilAvata = styled.div`
  margin-right: 1.5em;
  min-width: 9em;
  width: 9em;
  height: 9em;
  border-radius: 50%;
  background: linear-gradient(to bottom, #cedde0, #cedde0 75%, #9ab1b6 75%, #9ab1b6 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  .editarPerfilAvatar {
    font-size: 4em;
    padding-bottom: 0.25em;
    display: flex;
    align-items: center;
    color: #9ab1b6;
  }

  .editarPerfilAvatarMudar {
    height: 25%;
    font-size: 0.8em;
    display: flex;
    align-items: center;
    color: #293f57;
    position: absolute;
    bottom: 0;
  }
`;

export const ThisForm = styled(Form)`
  padding-right: 1em;
  width: 100%;

  .input {
    display: block;
    input {
      margin-top: 0.25em;
      border: 2px solid #cedde0;
      border-radius: 0.2em;
      display: block;
      width: 100%;
      height: 2.5em;
    }
  }
`;
