import React from "react";

import Card from "./Card";
import { Container } from "./styles";

function CardList({ stats }) {
  return (
    <Container>
      <Card
        title="Pendentes"
        subtitle="Quantidade de atividaes que faltam entregar"
        color="#FF4031"
        cardNumber={1}
        number={stats?.pendentes}
      />
      <Card
        title="Entregues"
        subtitle="Quantidade de atividades finalizadas"
        color="#4FA754"
        cardNumber={2}
        number={stats?.entregues}
      />
      <Card
        title="Total"
        subtitle="Total de atividades"
        color="#1CA9D0"
        cardNumber={3}
        number={stats?.total}
      />
    </Container>
  );
}

export default CardList;
