/**
 * @param {any} message Se nada for informado, usará um texto de erro padrão,
 *  se for passado uma string, ela será usada como mensagem,
 *  se passado um erro do Youch, será retornado a mensagem do primeiro erro
 *  que tenha como true a chave errorHandled.
 */
export function succesToggle(message) {
  return {
    type: "@successModal/succesToggle",
    payload: { message },
  };
}
