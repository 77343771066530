/* eslint-disable eqeqeq */
import React from "react";

import { Container, MaterializeContent } from "./styles";
import FirstSection from "./FirstSection";
import ThisTable from "./ThisTable";
import Pagination from "~/componentes/Pagination";
import ThisFilter from "./ThisFilter";
import ThisPdfButton from "./ThisPdfButton";
import ThisExcelButton from "./ThisExcelButton";
import ProfessorGlobalCards from "./ProfessorGlobalCards";
import SecondFilter from "./SecondFilter";

function Professores() {
  const [data, setData] = React.useState();
  const [filteredData, setFilteredData] = React.useState();
  const [atividades, setAtividades] = React.useState([]);

  return (
    <Container>
      <ThisFilter {...{ setData }} />

      <ProfessorGlobalCards {...{ data }} />

      <SecondFilter {...{ data, setFilteredData }} />

      <FirstSection {...{ filteredData }} />
      <div className="table-content">
        <ThisTable {...{ filteredData, atividades }} />

        <div className="table-action-buttons">
          <MaterializeContent>
            <div className="row">
              <div className="col s12 m12 l6 paginator">
                <Pagination
                  data={filteredData?.avaliacoesDisponibilizadas}
                  getInfo={({ pageData }) => {
                    setAtividades(pageData);
                  }}
                  size={4}
                />
              </div>
              <div className="col s12 m12 l6 actions-paginator">
                <ThisPdfButton {...{ data }} />
                <ThisExcelButton {...{ data }} />
              </div>
            </div>
          </MaterializeContent>
        </div>
      </div>
    </Container>
  );
}

export default Professores;
