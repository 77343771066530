import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { Container } from "./styles";
import { Materialize } from "~/styles/materializeGrid";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";

function ShowContent({ mountedData }) {
  return (
    <Container>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          <div className="row">
            <Label title="DATA INICIO:" className="col s12 m12 l3">
              <SistemaInput name="dataInicio" type="date" defaultValue={mountedData?.dataInicio} readOnly />
            </Label>
            <Label title="HORA INICIO:" text={mountedData?.horaInicio || ""} className="col s12 m12 l3" />
            <Label title="DATA FINAL:" className="col s12 m12 l3">
              <SistemaInput name="dataInicio" type="date" defaultValue={mountedData?.dataFinal} readOnly />
            </Label>
            <Label title="HORA FINAL:" text={mountedData?.horaFinal || ""} className="col s12 m12 l3" />
            <Label
              title="MINISTRO DA REUNIÃO:"
              text={mountedData?.usuarioMinistro?.nome || ""}
              className="col s12 m12 l4"
            />
            <Label
              title="SECRETARIO RESPONSÁVEL:"
              text={mountedData?.usuarioSecretaria?.nome || ""}
              className="col s12 m12 l4"
            />
            <Label
              title="PROFESSOR:"
              text={mountedData?.reuniaoProfessor?.professor?.usuario?.nome || ""}
              className="col s12 m12 l4"
            />
            <Label title="DESCRIÇÃO:" className="col s12 m12 l12">
              <SistemaTextArea name="descricao" defaultValue={mountedData?.descricao} readOnly />
            </Label>
          </div>
        </Materialize>
      </CustomScrollBar>
    </Container>
  );
}

export default ShowContent;
