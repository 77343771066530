import styled from "styled-components";
import Input from "~/componentes/CustomForm/Input";

const ThisSistemaInput = styled(Input)`
  font-size: 1em;
  line-height: 1;
  height: 2.4em;
  border-radius: 0.375em;
  border: 0.15em solid #cedde0;
  padding: 0em 0.5em;
  align-self: center;
  width: 10em;
  max-width: 100%;

  &:disabled {
    background-color: #dedede;
    border: none;
  }

  &:read-only {
    border: 0.1em solid #e4e4e4;
    background: #ededed;
  }
`;

export default ThisSistemaInput;
