import React from "react";

export function CustomListTableHeader({ children, ...rest }) {
  return (
    <thead {...rest}>
      <tr>
        {children}
        <th className="CustomTable_th_options" />
      </tr>
    </thead>
  );
}

export default React.memo(CustomListTableHeader);
