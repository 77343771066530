/* eslint-disable eqeqeq */
import styled from "styled-components";
import { Button } from "~/componentes/CustomForm";

export const Container = styled.div`
  border-bottom: 0.1em solid #f0f0f0;
  display: flex;
  align-content: center;
  justify-content: space-between;

  .QuestoesPagination_div_pageButtons {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
  }
`;

export const QuestaoPageButton = styled(Button)`
  border: none;
  padding: 0 0.5em !important;
  min-width: 2.5em !important;
  border-radius: 0.2em;
  margin: auto 0.5em 0.5em;
  color: #fff;

  background: ${({ status }) => {
    let sr = parseInt(status);

    if (sr == "0") {
      return "#FF4031";
    } else if (sr == "1") {
      return "#FFDA44";
    } else if (sr == "2") {
      return "#4FA754";
    } else {
      return "#C4C4C4";
    }
  }};

  :focus {
    outline: 0;
  }

  :hover {
    box-shadow: 0 0 0.25em #0003;
  }
`;
