import React from "react";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import { useState } from "react";

import { Container } from "./styles";
import { SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import api from "~/services/api";
import TurmasList from "../TurmasList";
import Label from "~/componentes/CustomForm/Label";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import SistemaTopico from "~/componentes/StyledComponents/Textos/SistemaTopico";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import ShowError from "~/utils/ShowError";
import { Materialize } from "~/styles/materializeGrid";
import FindOrCreateUser from "~/componentes/FindOrCreateUser";
function CreateContent() {
  const [turmas, setTurmas] = useState([]);
  const [usuario, setUsuario] = useState();

  async function handleSubmit(data, options) {
    const { closeReload } = options || {};
    if (!(turmas?.length > 0)) {
      ShowError("É necessário informar no mínimo uma turma");
    } else {
      ShowConfirmacao(
        async () => {
          await api
            .post("filial/aluno", { ...data, usuario, turmas })
            .then((r) => {
              if (r?.status === 200 && closeReload) {
                closeReload();
              }
            });
        },
        { message: "Tem certeza que deseja matricular esse aluno?" }
      );
    }
  }

  return (
    <Container onSubmit={handleSubmit}>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <SistemaTopico>Dados do Usuário</SistemaTopico>
        {/* <ThisFindOrCreateUser  /> */}
        <FindOrCreateUser setter={setUsuario} />
        <Materialize>
          <div className="row">
            <div className="col s12 m6 l6">
              <Label title="Matrícula">
                <SistemaInput required name="matricula" disabled={!usuario} />
              </Label>
            </div>
          </div>
        </Materialize>
        <TurmasList setter={setTurmas} />
      </CustomScrollBar>
      <SLModalFooter />
    </Container>
  );
}

export default CreateContent;
