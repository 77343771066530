import { pdf } from "@react-pdf/renderer";

/**
 * @param {Object} data
 * @param {import("react").ReactElement} data.document
 * @param {String} data.filename
 */
async function PdfDownloader({ document: documento, filename }) {
  try {
    return pdf(documento)
      ?.toBlob()
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);

        a.href = url;
        a.setAttribute("download", `${filename}.pdf` || "eduqueMe.pdf");
        a.click();
        window.URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
      });
  } catch {}
}

export default PdfDownloader;
