import React from "react";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import api from "~/services/api";
import ShowLoading from "~/utils/ShowLoading";
import { MaterializeContent } from "../styles";
import sistemaStore from "~/pages/Sistema/sistemaStore";

import { Container } from "./styles";

function ThisFilter({ dados, setDados, setFiltro }) {
  const props = React.useContext(sistemaStore)?.props || {};
  const matriculaDefault = props?.aluno?.matricula || props?.matricula;
  const [matricula, setMatricula] = React.useState("");

  async function fetchStats(mat) {
    ShowLoading(() =>
      api
        .get("filial/relatorios/alunoAtividades/atividadesAluno", {
          params: { matricula: mat },
        })
        .then((r) => {
          setFiltro({ periodoLetivoCod: r?.data?.periodosLetivos?.[0]?.cod });
          setDados(r?.data);
        })
        .catch(() => setDados())
    );
  }

  React.useEffect(() => {
    if (typeof matriculaDefault === "string") {
      setMatricula(matriculaDefault);
      fetchStats(matriculaDefault);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matriculaDefault]);

  return (
    <Container>
      <MaterializeContent>
        <div className="row">
          <div className="filters col s12 m12 l6">
            <Label title="Período Letivo" className="filter-item">
              <SistemaSelect
                list={{
                  list: dados?.periodosLetivos,
                  value: "cod",
                  label: "nome",
                }}
                setter={(e) => setFiltro((prev) => ({ ...prev, periodoLetivoCod: e }))}
                defaultValue={dados?.periodosLetivos?.[0]?.cod}
              />
            </Label>
            <Label title="Matrícula" className="filter-item">
              <SistemaInput
                onChange={(e) => {
                  const v = e.currentTarget.value;
                  setMatricula(v);
                }}
                value={matricula || ""}
                name="matricula"
                onKeyDown={(e) => e.key === "Enter" && fetchStats(matricula)}
                notForm
              />
            </Label>
          </div>
          <div className="actions col s12 m12 l6">
            <ColoredButton
              // materialIcon={{ filterAlt: true }}
              yellowLayout
              onClick={() => fetchStats(matricula)}
            >
              Procurar
            </ColoredButton>
          </div>
          <div className="row">
            <Label className="col s12 m12 l6" title="Nome:" text={dados?.usuario?.nome || ""} />
            <Label className="col s12 m12 l6" title="Email:" text={dados?.usuario?.email || ""} />
          </div>
        </div>
      </MaterializeContent>
    </Container>
  );
}

export default ThisFilter;
