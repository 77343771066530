export default function TableCheckData(path, obj, split) {
  const thisPath = path.split(split || ".");
  let thisValue = obj;
  let thisKey = [thisPath[thisPath.length - 1]];

  thisPath.forEach((e) => {
    if (thisValue && thisValue[e]) {
      thisValue = thisValue[e];
    } else {
      thisValue = null;
    }
  });
  return { value: thisValue, key: thisKey };
}
