import styled from "styled-components";
import Check from "../index";

const SliderCheck = styled(Check).attrs((props) => ({ setStyle: true }))`
  display: inline-block;
  position: relative;
  width: 2em;
  height: 1.1em;

  .Check_styledInput {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .Check_styledSpan {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${(props) =>
      props.inactiveBackground ? props.inactiveBackground : "#bbb"};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 1em;
  }

  .Check_styledSpan:before {
    position: absolute;
    content: "";
    height: 1em;
    width: 1em;
    left: 0.1em;
    bottom: 0.05em;
    background: ${(props) => (props.inputColor ? props.inputColor : "#fff")};
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  .Check_styledInput:checked + .Check_styledSpan {
    background: ${(props) =>
      props.activeBackground ? props.activeBackground : "#1CA9D0"};
  }

  .Check_styledInput:focus + .Check_styledSpan {
    box-shadow: 0 0 0.1em
      ${(props) => (props.activeBackground ? props.activeBackground : "#1CA9D0")};
  }

  .Check_styledInput:checked + .Check_styledSpan:before {
    -webkit-transform: translateX(0.85em);
    -ms-transform: translateX(0.85em);
    transform: translateX(0.85em);
  }
`;

export default SliderCheck;
