import React, { useState } from "react";
import Label from "~/componentes/CustomForm/Label";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import Pagination from "~/componentes/Pagination";

import { Container, ThisTable } from "./styles";
import { useEffect } from "react";
import IconButton from "~/componentes/CustomForm/Button/Styles/IconButton";
import ThisModal from "./ThisModal";
import ThisEditModal from "./ThisEditModal";

function ThisSeriesList({ series, defaultData, setter }) {
  const [seriesAtivas, setSeriesAtivas] = useState([]);
  const [seriesDisponiveis, setSeriesDisponiveis] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    if (Array.isArray(defaultData) && defaultData?.length > 0) {
      setSeriesAtivas((prev) => {
        let newPrev = [...prev];

        defaultData.forEach((e) => {
          const thisCheck = newPrev.find((t) => t?.cod === e?.serie?.cod);
          if (!thisCheck && e?.serie?.cod && e?.quantidadeAlunos) {
            newPrev.push({ ...e?.serie, quantidadeAlunos: e?.quantidadeAlunos });
          }
        });

        return newPrev;
      });
    }
  }, [defaultData]);

  useEffect(() => {
    const thisSeries =
      series?.filter((s) => !seriesAtivas?.find((sa) => sa?.cod === s?.cod)) || [];

    setSeriesDisponiveis(thisSeries);
  }, [series, seriesAtivas]);

  useEffect(() => {
    const handleSeries =
      seriesAtivas?.map((e) => ({
        serieCod: e?.cod,
        quantidadeAlunos: e?.quantidadeAlunos,
      })) || [];

    setter && setter(handleSeries);
  }, [seriesAtivas, setter]);

  function removeSerie(data) {
    setSeriesAtivas((prev) => {
      const newPrev = [...prev];
      const thisIndex = newPrev.findIndex((e) => e?.cod === data?.cod);
      if (thisIndex >= 0) {
        newPrev.splice(thisIndex, 1);
      }
      return newPrev;
    });
  }

  return (
    <Container>
      {showModal && (
        <ThisModal {...{ setShowModal, setSeriesAtivas, seriesDisponiveis }} />
      )}
      {editModal && <ThisEditModal {...{ editModal, setSeriesAtivas, setEditModal }} />}
      <div className="PeriodoMatricula_FilterList_div">
        <Label title="SÉRIES" />
        <ColoredButton
          type="button"
          materialIcon={{ addCircle: true }}
          onClick={() => setShowModal(true)}
          small
          blueLayout
        >
          Adicionar
        </ColoredButton>
      </div>
      <ThisTable>
        <thead>
          <tr>
            <th>Série</th>
            <th>Qnt. Alunos</th>
            <th>-</th>
          </tr>
        </thead>
        <tbody>
          {pageData?.map((e, i) => (
            <tr key={e?.cod || i}>
              <td>{e?.nome}</td>
              <td>{e?.quantidadeAlunos}</td>
              <td>
                <IconButton materialIcon="create" onClick={() => setEditModal(e)} />
                <IconButton
                  materialIcon={{ delete: true }}
                  onClick={() => removeSerie(e)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </ThisTable>
      <Pagination
        data={seriesAtivas}
        size={4}
        getInfo={({ pageData }) => setPageData(pageData || [])}
      />
    </Container>
  );
}

export default ThisSeriesList;
