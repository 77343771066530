import React from "react";
import {
  EduquemeInput,
  SistemaInput,
} from "~/componentes/CustomForm/Input/styles";
import handleCep from "~/utils/handleCep";
import { IsObject } from "~/utils/CheckDataType";
import CustomToast from "~/componentes/CustomToast";
import estados from "~/config/estados";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";

// import { Container } from './styles';

function Cep({ setDados, grupo, blueLayout, props }) {
  const ThisInput = blueLayout ? EduquemeInput : SistemaInput;

  async function fetchCep() {
    if (grupo?.chave) {
      const result = await handleCep(props?.value);

      if (IsObject(result)) {
        const { localidade, bairro: thisBairro, uf, logradouro } = result;

        const thisEstado = uf && estados?.find((e) => e?.uf === uf);

        const thisCidade = localidade && thisEstado?.cidades?.find((e) => e?.nome?.toLowerCase() === localidade?.toLowerCase());

        setDados &&
          setDados((prev) => ({
            ...prev,
            [grupo.chave]: {
              ...prev?.[grupo.chave],
              estado: thisEstado || prev?.estado || "",
              cidade: thisCidade || prev?.cidade || "",
              bairro: thisBairro || prev?.bairro || "",
              logradouro: logradouro || prev?.logradouro || "",
            },
          }));
      } else {
        CustomToast.error("Cep não encontrado");
      }
    }
  }

  async function handleChangeValue(e) {
    if (grupo?.chave && setDados) {
      const newValue = e.currentTarget.value;

      setDados((prev) => ({
        ...prev,
        [grupo.chave]: { ...prev?.[grupo.chave], cep: newValue },
      }));
    }
  }

  return (
    <div className="Label_inputWithButton">
      <ThisInput {...props} onChange={handleChangeValue} />
      <ColoredButton type="button" greenLayout small materialIcon="check" onClick={fetchCep} />
    </div>
  );
}

export default Cep;
