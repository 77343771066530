import React, { useState } from "react";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTable, SLTableCell } from "~/pages/Sistema/componentes/SistemaList";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";

function TableComponent({ setTabFilter }) {
  const [tab, setTab] = useState();

  return (
    <>
      <TabsWrapper tabHeight={2.4}>
        <Tab
          materialIcon="check_circle_outline"
          onClick={() => {
            setTab(0);
            setTabFilter(() => (data) =>
              data?.turmas?.some?.(
                (t) => t?.ativo && t?.AlunoTurma?.ativo && t?.periodoLetivo?.ativo
              )
            );
          }}
        >
          Matriculados
        </Tab>
        <Tab
          materialIcon="highlight_off"
          onClick={() => {
            setTab(1);
            setTabFilter(() => (data) =>
              data?.turmas?.every?.(
                (t) => !(t?.ativo && t?.AlunoTurma?.ativo && t?.periodoLetivo?.ativo)
              )
            );
          }}
        >
          Desativos
        </Tab>
      </TabsWrapper>

      <SistemaComponenteInterno>
        <SLTable
          pageSize={6}
          {...(tab === 1 && { noDelete: true, buttonIcon: { edit: "restore" } })}
        >
          <SLTableCell name="matricula" inputfilter>
            MATRICULA
          </SLTableCell>
          <SLTableCell name="usuario.nome" inputfilter>
            NOME
          </SLTableCell>
          <SLTableCell name="usuario.email" inputfilter>
            EMAIL
          </SLTableCell>
        </SLTable>
      </SistemaComponenteInterno>
    </>
  );
}

export default TableComponent;
