import React from "react";
import { ThisSistemaListaIconButonStyle } from "../styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ThisExcelPDFButton({ icon = {}, ...rest }) {
  return (
    <ThisSistemaListaIconButonStyle className={`material-icons`} {...rest}>
      {/*<i class={icon} aria-hidden="true"></i>*/}
      <FontAwesomeIcon icon={icon} />
    </ThisSistemaListaIconButonStyle>
  );
}

export default ThisExcelPDFButton;
