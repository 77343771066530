import styled from "styled-components";
import { Button } from "~/componentes/CustomForm";
import Check from "~/componentes/CustomForm/Check";
import CheckWrapper from "~/componentes/CustomForm/CheckWrapper";

export const AddQuestion = styled.div`
  cursor: pointer;
  background: #ffffff;
  border-radius: 0.25em;
  transition: 0.1s ease;
  border: dashed #dae6e8 0.125em;
  display: flex;
  justify-content: center;
  align-items: center;

  h4 {
    display: flex;
    align-items: center;
    color: #c4c4c4;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875em;
    line-height: 1.188em;
    text-transform: uppercase;
    transition: 0.3s ease;
  }
`;

export const Filter = styled.div`
  display: flex;

  select {
    width: fit-content;
  }
  input {
    margin: 0 0.2em;
    width: 10em;
  }
`;

export const ButtonForm = styled(Button)`
  display: flex;
  align-items: center;
  background-color: #4fa754;
  color: #fff;
  border: none;
  box-shadow: 0 0.3em 0 #2d7a32;
  border-radius: 0.2em;
  font-family: "Doppio One";
  text-transform: uppercase;
  outline: none;
  margin-bottom: 0.3em;
  padding: 0 0.5em;
  font-size: 2.5vh;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 2.1em;

  :active {
    margin-top: 0.3em;
    box-shadow: none;
    margin-bottom: 0;
  }

  i {
    padding-left: 0.8em;
  }
`;

export const ButtonField = styled.div`
  display: flex;
  justify-content: center;
`;

export const ListTurmas = styled(CheckWrapper).attrs(({ as, ...rest }) => ({
  as: as || CheckWrapper,
}))`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > div {
    margin: 0.5em;
    flex: 0 1 95%;
    width: 13.5em;
    height: 4em;

    @media (min-width: 500px) {
      flex: 0 1 calc(50% - 1.1em);
    }

    @media (min-width: 800px) {
      flex: 0 1 calc(33% - 1.1em);
    }

    @media (min-width: 1200px) {
      flex: 0 1 calc(25% - 1.1em);
    }
  }
`;

export const TurmaFooter = styled.div`
  display: flex;
  justify-content: ${(props) => (props.useCheckBox ? "space-between" : "flex-end")};
  margin-top: 0.5em;
  padding: 0;
  width: 100%;
`;

export const CheckCustom = styled(Check)`
  margin: 0 0.5em;
  position: relative;
  appearance: none;
  width: 1.5em !important;
  height: 1.5em !important;
  border: solid 0.14em #bdbdbd;
  background: #fff;
  border-radius: 0.15em;
  cursor: pointer;
  transition: all 0.1s ease;

  &::before {
    content: "";
    position: absolute;
    width: 0.6em;
    height: 0.16em;
    background: #fff;
    top: 0.75em;
    left: 0em;
    transform: rotate(45deg);
    clip-path: inset(0 0 0 20%);
    transition: 0.1s ease;
  }
  &::after {
    content: "";
    position: absolute;
    width: 1em;
    height: 0.16em;
    background: #fff;
    top: 0.55em;
    left: 0.28em;
    transform: rotate(-52deg);
    clip-path: inset(0 100% 0 0);
    transition: 0.2s ease;
  }

  &:checked {
    background: #1ca9d0;
    border-color: #1ca9d0;
  }
  &:checked::before,
  &:checked::after {
    transition-delay: 0.1s;
    clip-path: inset(0 0 0 0);
  }
  &:disabled {
    border: solid 0.14em #b1b1b1;

    cursor: not-allowed;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  i {
    cursor: pointer;
    margin: 0 0.2em;
    color: #7b898c;
    transition: 0.2s;
  }
  i:hover {
    color: #657578;
  }
`;

export const TurmaCard = styled.div`
  margin: 0.5em;
  padding: 0.5em 1em;
  border-radius: 0.1em;
  box-shadow: 0px 0.05em 1em rgba(0, 0, 0, 0.09);
  flex: 0 1 95%;

  span {
    color: #293f57;
    font-weight: 600;
    margin-left: 0.2em;
  }

  @media (min-width: 500px) {
    flex: 0 1 calc(50% - 1em);
  }

  @media (min-width: 1200px) {
    flex: 0 1 calc(33% - 1em);
  }

  .Turma_Card_check {
    /* margin: 0.5em 0; */
    /* padding-bottom: 0.5em; */
    color: #1ca9d0;
    /* border-bottom: solid #f0f0f0 2px; */
    float: left;
    width: 2.6em;
    padding: 0.5em 0em;
  }

  .Turma_Card_title {
    width: 8em;
    float: left;
    padding: 0em 0.5em;

    span {
      margin-left: 0;
    }
  }

  .Turma_Card_nomeProfessor {
    font-size: 0.8em;
    color: #9ab1b6;
    text-align: left;
  }

  .Turma_Card_titulo {
    margin: 0.8em 0;
    font-weight: 600;
  }

  .Turma_Card_enunciado {
    font-size: 0.95em;
    color: #8e98a4;
  }

  .Turma_Card_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 0.1em solid #f0f0f0;
    margin-top: 1em;
    padding-top: 0.5em;

    .Turma_Card_footer_dificuldade {
      background: ${(props) => {
        switch (props.nivel) {
          case "Difícil":
            return "#FF4031";
          case "Intermediário":
            return "#FFDA44";
          default:
            return "#4fa754";
        }
      }};
      font-size: 0.9em;
      border-radius: 0.2em;
      color: #fff;
      padding: 0.25em 1em;
    }

    .Turma_Card_footer_buttons {
      i {
        position: relative;
        margin-left: 0.5em;
        font-size: 1.6em;
      }
      i::after {
        transition: 0.3s ease;
        content: "";
        position: absolute;
        background: rgba(28, 169, 208, 0.22);
        width: 1.5em;
        height: 1.5em;
        left: -0.25em;
        top: -0.25em;
        border-radius: 50%;
        transform: scale(0);
        transform-origin: center center;
      }
      i:hover::after {
        transform: scale(1);
      }
    }
  }
`;
