import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;

  .SingleValueList_div {
    display: flex;
    align-items: center;
    background: #1ca9d0;
    color: #fff;
    width: fit-content;
    padding: 0.5em;
    margin: 0.5em;
    margin-bottom: 0;
    border-radius: 0.5em;

    .SingleValueList_div_IconButton {
      color: #fff;
      font-size: 1em;
      margin-right: 0.5em;
    }
  }
`;
