import React from "react";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import { useState } from "react";
import { useEffect } from "react";
import ThisModal from "./ThisModal";

function ClienteModal({ mobile, setClienteSelected, setTab, clientes, setNomeClienteSelected }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <ColoredButton
        className="TurmasList_div_header_button"
        blueLayout
        type="button"
        onClick={() => setShowModal(!showModal)}
        materialIcon={"search"}
      >
      </ColoredButton>
      {showModal && (
        <ThisModal {...{ setTab, setShowModal, setClienteSelected, clientes, setNomeClienteSelected }} />
      )}
    </>
  );
}

export default ClienteModal;
