import React from "react";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";

import CustomScrollBar from "~/componentes/CustomScrollBar";
import { Materialize } from "~/styles/materializeGrid";
import { Container } from "./styles";
import { SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import api from "~/services/api";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";

function CreateEditContent({ mountedData }) {
  function handleSubmit(data, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let response;

        if (mountedData) {
          response = await api.put("admin/configuracaodesafio", {
            ...data,
            cod: mountedData.cod,
          });
        } else {
          response = await api.post("admin/configuracaodesafio", data);
        }

        if (response?.status === 200) {
          closeReload && closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao ${
            mountedData ? "atualizar" : "criar"
          } essa Configuração do Desafio, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja ${
          mountedData ? "atualizar" : "criar"
        } essa Configuração do Desafio?`,
        successModal: `Configuração do Desafio ${
          mountedData ? "atualizada" : "cadastrada"
        } com sucesso!`,
      }
    );
  }

  return (
    <Container onSubmit={handleSubmit}>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          <div className="row">
            <Label title="NOME:" className="col s12 m12 l8">
              <SistemaInput name="nome" defaultValue={mountedData?.nome} />
            </Label>
            <Label title="TEMPO DO DESAFIO (Min:Seg):" className="col s12 m12 l4">
              <SistemaSelect
                name="tempo"
                list={{
                  list: [
                    { valor: "00:02:00", nome: "02:00" },
                    { valor: "00:02:30", nome: "02:30" },
                    { valor: "00:03:00", nome: "03:00" },
                    { valor: "00:03:30", nome: "03:30" },
                    { valor: "00:04:00", nome: "04:00" },
                    { valor: "00:04:30", nome: "04:30" },
                    { valor: "00:05:00", nome: "05:00" },
                    { valor: "00:05:30", nome: "05:30" },
                    { valor: "00:06:00", nome: "06:00" },
                  ],
                  value: "valor",
                  label: "nome",
                }}
                defaultValue={mountedData?.tempo}
                required
              />
            </Label>
            <Label title="QUANT. QUESTÕES:" className="col s12 m12 l3">
              <SistemaInput
                name="quantidadeQuestoes"
                defaultValue={mountedData?.quantidadeQuestoes}
              />
            </Label>
            <Label title="QUANT. ACERTOS:" className="col s12 m12 l3">
              <SistemaInput
                name="quantidadeAcertoVitoria"
                defaultValue={mountedData?.quantidadeAcertoVitoria}
              />
            </Label>
            <Label title="TOTAL DE VIDAS:" className="col s12 m12 l3">
              <SistemaInput name="totalVidas" defaultValue={mountedData?.totalVidas} />
            </Label>
            <Label title="ATIVO:" className="col s12 m12 l3">
              <SistemaSelect
                name="ativo"
                list={{
                  list: [
                    { valor: "true", nome: "Sim" },
                    { valor: "false", nome: "Não" },
                  ],
                  value: "valor",
                  label: "nome",
                }}
                defaultValue={mountedData?.ativo.toString()}
                required
              />
            </Label>
          </div>
        </Materialize>
      </CustomScrollBar>
      <SLModalFooter />
    </Container>
  );
}

export default CreateEditContent;
