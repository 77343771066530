import React from "react";
import { ThisSistemaListaIconButonStyle } from "../styles";

/**
 * @param {Object} props
 * @param {any} props.data
 */
function ThisShareButton({ icon = "share", ...rest }) {
  return (
    <ThisSistemaListaIconButonStyle moldalType="share" className={`material-icons`} {...rest}>
      {icon}
    </ThisSistemaListaIconButonStyle>
  );
}

export default ThisShareButton;
