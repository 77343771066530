import React, { useState, useEffect } from "react";
import {
  CLModalButtons,
  CLModalHeader,
  CLForm,
} from "~/pages/Sistema/componentes/CustomList/CustomListStyledComponents";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import { IsFunction } from "~/utils/CheckDataType";
import { Container } from "../styles";

function StyledCustomListCreateData({
  title,
  getUserData,
  onSubmit,
  children,
  data,
  setData,
  successMessage,
  setConfirmData,
  ...rest
}) {
  const [success, setSucces] = useState(false);

  useEffect(() => {
    getUserData && getUserData(data);

    return () => getUserData && getUserData();
  }, [data, getUserData]);

  async function handleSubmit(formData, reload) {
    if (IsFunction(onSubmit)) {
      await ShowConfirmacao(
        async () => {
          const response = await onSubmit(formData, data);
          if (response?.status === 200) {
            setSucces(true);
            reload();
          } else {
            throw new Error();
          }
        },
        {
          successModal: false,
        }
      );
    }
  }

  return (
    <Container {...rest}>
      {!success && (
        <CLForm onSubmit={handleSubmit}>
          <CLModalHeader>{title || "Cadastro"}</CLModalHeader>
          {children}

          <CLModalButtons
            cancelButton={{
              label: "VOLTAR",
              onClick: () => {
                setData();
                setConfirmData(false);
              },
            }}
          />
        </CLForm>
      )}
      {success && (
        <div>
          {typeof successMessage === "string"
            ? successMessage
            : "Operação realizada com sucesso."}
        </div>
      )}
    </Container>
  );
}

export default StyledCustomListCreateData;
