import * as GestaoApp from "./GestaoApp";

import * as GestaoSistema from "./GestaoSistema";
import * as GestaoQuestoes from "./GestaoQuestoes";
import * as GestaoSite from "./GestaoSite";
import * as GestaoUsuario from "./GestaoUsuario";
import * as GestaoVenda from "./GestaoVenda";
import * as Relatorios from "./Relatorios";
import * as Suporte from "./Suporte";
import { default as DashBoard } from "./DashBoard";

export default {
  DashBoard,
  GestaoApp,
  GestaoSistema,
  GestaoSite,
  GestaoUsuario,
  GestaoVenda,
  GestaoQuestoes,
  Relatorios,
  Suporte,
};
