import React from "react";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";
import { Container } from "./styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import api from "~/services/api";
import { IsFunction } from "~/utils/CheckDataType";
import { Materialize } from "~/styles/materializeGrid";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";

function EditContent({ mountedData }) {
  function handleSubmit(data, options) {
    const { closeReload } = options || {};
    ShowConfirmacao(async () => {
      const response = await api.put("filial/periodoLetivo", {
        ...data,
        cod: mountedData.cod,
      });

      if (response?.status === 200 && IsFunction(closeReload)) {
        closeReload();
      }

      return response;
    });
  }

  return (
    <Container defaultStyle onSubmit={handleSubmit}>
      <Materialize>
        <div className="row">
          <Label title="NOME:">
            <SistemaInput name="nome" defaultValue={mountedData?.nome} />
          </Label>
          <Label title="INÍCIO:" className="col s12 m12 l6">
            <SistemaInput type="date" name="dataInicio" defaultValue={mountedData?.dataInicio} required />
          </Label>
          <Label title="TÉRMINO:" className="col s12 m12 l6">
            <SistemaInput type="date" name="dataFim" defaultValue={mountedData?.dataFim} required />
          </Label>
          <Label title="INÍCIO PERIODO DE MATRICULA:" className="col s12 m12 l6">
            <SistemaInput type="date" name="matriculaInicio" defaultValue={mountedData?.matriculaInicio} required />
          </Label>
          <Label title="TÉRMINO PERIODO DE MATRICULA:" className="col s12 m12 l6">
            <SistemaInput type="date" name="matriculaFim" defaultValue={mountedData?.matriculaFim} required />
          </Label>
        </div>
        <div className="desativarPeriodoLetivo_div">
          <Label title="Ativar / Desativar">
            <SistemaSelect name="ativo" defaultValue={mountedData?.ativo ? "true" : "false"}>
              <option value="true">Ativo</option>
              <option value="false">Desativo</option>
            </SistemaSelect>
          </Label>
          <p>Ao desativar um período letivo, também será desativado todas as turmas e disciplinas desta.</p>
        </div>
      </Materialize>
    </Container>
  );
}

export default EditContent;
