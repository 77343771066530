import styled from "styled-components";

export const Container = styled.div`
  height: 12.5em;
  background: #ffffff;
  box-shadow: 0 0.063em 0.75em rgba(0, 0, 0, 0.22);
  border-radius: 0.625em 0.375em 0.375em 0.625em;
  padding: 0.7em;
  display: flex;
  margin: 1em;
  cursor: pointer;
  transition: 0.3s ease;
  width: calc(100% - 1em);

  @media (min-width: 700px) {
    width: calc(50% - 2em);
  }
  @media (min-width: 1150px) {
    width: calc(33% - 2em);
  }
  @media (min-width: 1300px) {
    width: calc(25% - 2em);
  }

  &:hover {
    transform: scale(1.05);
  }
  .School-field {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .card-header {
      margin-left: 0.8em;
      display: flex;
      padding-bottom: 0.8em;
      border-bottom: solid #e2e2e2 0.125em;

      .image-container {
        display: flex;
        width: 2.5em;
        height: 2.5em;
        overflow: hidden;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        margin-right: 0.75em;
        border-radius: 50%;
        img {
          height: 7vh;
        }
      }
      h3 {
        text-align: initial;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        line-height: 1.2;
        max-width: 16em;
        font-weight: 600;
        font-size: 0.875em;
        line-height: 1.188em;
        /* identical to box height */

        color: #333333;
      }
      p {
        font-style: normal;
        font-weight: 600;
        font-size: 0.75em;
        line-height: 1.375e;

        color: #696969;
      }
    }

    .card-content {
      margin-left: 0.8em;

      .card-item {
        display: flex;
        align-items: center;
        margin: 0 0 0.4em 0;
        color: #696969;

        i {
          margin-right: 0.2em;
        }
        span {
          color: #1ca9d0;
        }
        h4 {
          line-height: 1.2;
          max-width: 100%;
          font-size: 0.9em;
        }
      }
    }

    .card-footer {
      display: flex;
      justify-content: flex-end;
      margin-left: 0.8em;

      .footer-tag {
        width: fit-content;
        height: 1.75em;
        padding: 0.4em;
        border-radius: 0.4em;
        margin: 0 0.4em;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        background-color: ${({ filial }) => (filial?.publico ? "#ffda44" : "#4ea654")};
      }
    }
  }
`;
