import React from "react";

import { Container } from "./styles";
import loading from "~/assets/icones/loading.png";
import LoadingDots from "~/componentes/LoadingDots";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toggleDisableButton } from "~/store/modules/disableButtons/actions";

export default function Carregando() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleDisableButton());

    return () => {
      dispatch(toggleDisableButton());
    };
  }, [dispatch]);

  return (
    <Container>
      <div className="loadingContent">
        <LoadingDots>Carregando</LoadingDots>
        <img className="loadingIcon" src={loading} alt="loading" />
      </div>
    </Container>
  );
}
