import React, { useState } from "react";

import { Container, ModalContainer } from "./styles";
import { IsNumber, IsObject } from "~/utils/CheckDataType";
import SistemaModalHeader from "../Headers/SistemaModalHeader";
import SistemaModalFooter from "../Footers/SistemaModalFooter";
import { memo } from "react";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import { useEffect } from "react";
import { useCallback } from "react";
import { useSelector } from "react-redux";

/**
 * @param {Object} props
 * @param {Function} props.outClick Função chamada ao clicar fora do modal.
 * @param {string} props.title Adiciona um header com o titulo informado.
 * @param {Boolean} props.scrollBar Ativa um scrollBar padrão.
 * @param {Function} props.outClickAnimation Função que utiliza o outClick informado, porém com animação.
 * @param {Object} props.footerButtons Adiciona um footer com um botão de confirmar e cancelar.
 * @param {Object} props.footerButtons.cancelButton Propriedades do botão de cancelar.
 * @param {Object} props.footerButtons.submitButton Propriedades do botão de confirmar.
 * @param {Object} props.checkRedux Padrão true, ativa todas as checagens do redux. Se false, não checará se existe modais do redux ativos como loading, confirmação ou erro.
 * @param {Boolean} props.checkRedux.confirm Ativa a checagem de confirmação.
 * @param {Boolean} props.checkRedux.error Ativa a checagem de error.
 * @param {Boolean} props.checkRedux.loading Ativa a checagem de loading.
 * @param {Boolean} props.checkRedux.success Ativa a checagem de success.
 */
function SistemaFixedModal({
  title,
  outClose,
  footerButtons,
  backgroundProps,
  children,
  outClick,
  checkRedux,
  outClickAnimation,
  forceCenter,
  scrollBar,
  ...rest
}) {
  const [close, setClose] = useState(false);
  const [top, setTop] = useState();
  const [left, setLeft] = useState();
  const { errorModal, loadingModal, confirmacaoModal, successModal } =
    useSelector((state) => state) || {};

  const { cancelButton, submitButton } = footerButtons || {};

  const reduxCheck = React.useMemo(() => {
    if (checkRedux) {
      if (IsObject(checkRedux)) {
        const thisConfirm = checkRedux.confirm ? !confirmacaoModal?.ativo : true;
        const thisError = checkRedux.error ? !errorModal?.ativo : true;
        const thisLoading = checkRedux.loading ? !loadingModal?.ativo : true;
        const thisSuccess = checkRedux.success ? !successModal?.ativo : true;

        return thisConfirm && thisError && thisLoading && thisSuccess;
      } else {
        return (
          !confirmacaoModal?.ativo &&
          !errorModal?.ativo &&
          !loadingModal?.ativo &&
          !successModal?.ativo
        );
      }
    } else {
      return true;
    }
  }, [checkRedux, confirmacaoModal, errorModal, loadingModal, successModal]);

  const handleCloseModal = useCallback(
    (e) => {
      if (reduxCheck && outClick) {
        setClose(true);
        setTimeout(() => outClick(e), 300);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    outClickAnimation && outClickAnimation(() => handleCloseModal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleCloseModal]);

  return (
    <Container
      {...backgroundProps}
      ref={(e) => {
        if (e) {
          let rect = e?.getBoundingClientRect();
          let thisTop = rect?.top;
          let thisLeft = rect?.left;
          let checkTop = IsNumber(thisTop) && thisTop !== top;
          let checkLeft = IsNumber(thisLeft) && thisLeft !== left;

          if (checkTop) {
            if (thisTop < top) {
              let thisValue = top + thisTop;
              thisValue !== top && setTop(thisValue);
            } else {
              setTop(thisTop);
            }
          }
          if (checkLeft) {
            if (thisLeft < left) {
              let thisValue = left + thisLeft;
              thisValue !== left && setLeft(thisValue);
            } else {
              setLeft(thisLeft);
            }
          }
        }
      }}
      left={left}
      top={top}
      close={close}
    >
      <ModalContainer
        close={close}
        outClick={handleCloseModal}
        checkRedux={checkRedux}
        {...rest}
      >
        {title && (
          <SistemaModalHeader
            title={title}
            closeClick={(e) => {
              if (outClick) {
                setClose(true);
                setTimeout(() => outClick(e), 300);
              }
            }}
          />
        )}
        {scrollBar ? (
          <CustomScrollBar
            fixScrollMargin
            autoHeightMax={`${95 - (title ? 10 : 0) - (footerButtons ? 10 : 0)}vh`}
          >
            {children}
          </CustomScrollBar>
        ) : (
          children
        )}
        {footerButtons && (
          <SistemaModalFooter
            cancelButton={{
              ...cancelButton,
              onClick: () => {
                if (cancelButton?.onClick) {
                  cancelButton.onClick(handleCloseModal);
                } else {
                  handleCloseModal();
                }
              },
            }}
            submitButton={
              submitButton !== false && {
                ...submitButton,
                onClick: () => {
                  if (submitButton?.onClick) {
                    submitButton.onClick(handleCloseModal);
                  }
                },
              }
            }
          />
        )}
      </ModalContainer>
    </Container>
  );
}

export default memo(SistemaFixedModal);
