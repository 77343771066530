import React from "react";

import { useState } from "react";
import { useLayoutEffect } from "react";
import api from "~/services/api";
import CreateEditContent from "./CreateEditContent";
import ShowContent from "./ShowContent";
import SistemaList, {
  SLShowModal,
  SLEditModal,
  SLCreateModal,
} from "~/pages/Sistema/componentes/SistemaList";
import TableComponent from "./TableComponent";

export default function Turmas() {
  const [series, setSeries] = useState([]);
  const [periodosLetivos, setPeriodosLetivos] = useState([]);
  const [mountedData, setMountedData] = useState();
  const [tabFilter, setTabFilter] = useState();

  useLayoutEffect(() => {
    api.get("global/series").then((response) => setSeries(response?.data || []));

    api
      .get("filial/periodosLetivos", { params: { ativo: true } })
      .then((response) => setPeriodosLetivos(response?.data || []));
  }, []);

  return (
    <SistemaList
      requests={{
        findAll: () => api.get("filial/turmas", { params: { vigente: true } }),
        deleteOne: (thisData) =>
          api.delete("filial/turma", { data: { cod: thisData.cod } }),
        findOne: (thisData) => api.get("filial/turma", { params: { cod: thisData.cod } }),
      }}
      dataFilter={tabFilter}
    >
      <TableComponent {...{ series, periodosLetivos, setTabFilter }} />

      <SLShowModal title="Turma" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLEditModal title="Atualizar Turma" setter={setMountedData}>
        <CreateEditContent {...{ mountedData, series, periodosLetivos }} />
      </SLEditModal>

      <SLCreateModal title="Nova Turma">
        <CreateEditContent {...{ series, periodosLetivos }} />
      </SLCreateModal>
    </SistemaList>
  );
}
