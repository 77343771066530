import React from "react";
import { QuestaoItensContainer } from "./styles";
import Item from "./Item";
import ChangeIndexOrder from "~/utils/ChangeIndexOrder";

function ItensDiv({ deleteRequest, initialItemData, itens, setItens }) {
  return (
    <QuestaoItensContainer>
      {itens?.map((e, i) => (
        <Item
          key={i}
          title={`Item (${ChangeIndexOrder(i)})`}
          data={e}
          handleData={(data) => {
            setItens((prev) => {
              let newState = [...prev];
              newState[i] = data;
              return newState;
            });
          }}
        />
      ))}
    </QuestaoItensContainer>
  );
}

export default ItensDiv;
