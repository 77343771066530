/* eslint-disable no-useless-computed-key */
import React, { useState } from "react";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTableCell } from "~/pages/Sistema/componentes/SistemaList";
import { ThisTable } from "./styles";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";
import Label from "~/componentes/CustomForm/Label";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import { faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons'

function TableComponent({ setTabFilter, data, setData }) {
  return (
    <>
      <TabsWrapper tabHeight={2.4}>
        <Tab materialIcon="check_circle_outline" onClick={() => setTabFilter({ ["turma.ativo"]: true })}>
          Ativo
        </Tab>
        <Tab materialIcon="highlight_off" onClick={() => setTabFilter({ ["turma.ativo"]: false })}>
          Desativo
        </Tab>
      </TabsWrapper>

      <SistemaComponenteInterno>
        <Label
          title="Data:"
          className="col s12 m12 l3">
          <SistemaInput type="date" name={'data'} defaultValue={data} onChange={e => setData(e.target.value)} required />
        </Label>
        <ThisTable /* buttonIcon={{excel: faFileExcel, pdf: faFilePdf}} */ noExcel={false} noPDF={false} noEdit={true}>
          <SLTableCell name="turma.nome" inputfilter>
            Nome
          </SLTableCell>
          <SLTableCell name="disciplinaFilial.nome" inputfilter>
            Disciplina
          </SLTableCell>
          <SLTableCell name="turma.serie.nome" inputfilter>
            Série
          </SLTableCell>
          <SLTableCell name="turma.periodoLetivo.nome" inputfilter>
            Período Letivo
          </SLTableCell>
        </ThisTable>
      </SistemaComponenteInterno>
    </>
  );
}

export default TableComponent;
