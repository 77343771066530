import React from "react";
import { Bar } from "react-chartjs-2";

import { Container, ChartContainer } from "./styles";

const optionsData = {
  responsive: true,
  legend: {
    display: false,
  },
  onHover: (event, chartElement) => {
    event.target.style.cursor = chartElement[0] ? "pointer" : "default";
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },

        barThickness: 12, // number (pixels) or 'flex'
        maxBarThickness: 12, // number (pixels)
      },
    ],
  },
};

function BarChart({ stats }) {
  const labels = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];

  const datasets = React.useMemo(() => {
    const { desempenho } = stats || {};

    if (desempenho) {
      const notaBaixa = Object.values(desempenho).map((e) => e?.reduce((total, current) => (current < 70 ? total + 1 : total), 0));
      const notaAlta = Object.values(desempenho).map((e) => e?.reduce((total, current) => (current >= 70 ? total + 1 : total), 0));

      return [
        {
          label: "Nota Baixa",
          backgroundColor: "#FF4031",
          data: notaBaixa || [],
        },
        {
          label: "Nota Alta",
          backgroundColor: "#4FA754",
          data: notaAlta || [],
        },
      ];
    }
  }, [stats]);

  return (
    <Container className="chart1">
      <div className="chart-container">
        <div className="chart-header">
          <h4>Desempenho dos alunos</h4>
        </div>
        <ChartContainer>
          <Bar data={{ labels, datasets }} options={optionsData} />
        </ChartContainer>
      </div>
    </Container>
  );
}

export default BarChart;
