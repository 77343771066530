import React from "react";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTable, SLTableCell } from "~/pages/Sistema/componentes/SistemaList";

function TableComponent({ setTabFilter }) {
  return (
    <>
      <TabsWrapper tabHeight={2.4}>
        <Tab
          materialIcon="check_circle_outline"
          onClick={() =>
            setTabFilter({
              ativo: true,
              dataFim: (data) =>
                !data || new Date(data).getTime() >= new Date().getTime(),
            })
          }
        >
          Ativo
        </Tab>
        <Tab
          materialIcon="error_outline"
          onClick={() =>
            setTabFilter({
              ativo: true,
              dataFim: (data) => !data || new Date(data).getTime() < new Date().getTime(),
            })
          }
        >
          Expirado
        </Tab>
        <Tab
          materialIcon="error_outline"
          onClick={() =>
            setTabFilter({
              ativo: (data) => !data,
            })
          }
        >
          Desativo
        </Tab>
      </TabsWrapper>

      <SistemaComponenteInterno>
        <SLTable>
          <SLTableCell name="nome" inputfilter>
            Nome
          </SLTableCell>
          <SLTableCell isDateOnly name="dataInicio">
            Início
          </SLTableCell>
          <SLTableCell isDateOnly name="dataFim">
            Término
          </SLTableCell>
        </SLTable>
      </SistemaComponenteInterno>
    </>
  );
}

export default TableComponent;
