import styled from "styled-components";
import CarrocelRoleta from "~/componentes/CarrocelRoleta";

export const Container = styled(CarrocelRoleta)`
  margin: auto;
  width: ${(props) => (props.rotatedTablet || props.desktop ? "80em" : "fit-content")};
  height: auto;
  max-width: 90vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  .card {
    width: 25em;
    position: relative;
    height: 26em;
    margin: 0 5em;
    ${(props) =>
      (props.rotatedTablet || props.desktop) &&
      `
    z-index: 0;
    `}

    padding: 2em;
    background: #fff;
    border-radius: 2em;
    box-shadow: 0.375em 0.375em 1.25em rgba(134, 134, 134, 0.7);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    img {
      height: 8em;
      width: auto;
      max-width: 100%;
      margin: auto;
    }

    strong {
      margin: 1em auto;
    }

    .card-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    @media (max-width: 800px) {
      strong {
        margin: 0em auto 1em auto;
        text-align: center;
        font-size: 1.2em;
      }
      .card-content {
        padding-bottom: 3em;
        display: flex;
        flex-direction: column;
      }
      .card-content p {
        font-size: 1em;
        text-align: left;
        padding-left: 1em;
      }
    }
  }

  .carrocelRoletaContent2 {
    right: 0;
  }

  .carrocelRoletaContent1 {
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;
  }

  .carrocelRoletaLeftButton,
  .carrocelRoletaRightButton {
    position: absolute;
    z-index: 2;
    border-radius: 50%;
    background: #ffda44;
    border: none;
    font-size: 2.5em;
  }

  .carrocelRoletaLeftButton {
    left: 0;
  }
  .carrocelRoletaRightButton {
    right: 0;
  }
  @media (max-width: 700px) {
    .carrocelRoletaContent2 img {
      height: 10em;
      margin-bottom: 2em;
    }
    .carrocelRoletaContent1 img {
      height: 10em;
      margin-bottom: 2em;
    }
    .carrocelRoletaLeftButton {
      left: 2em !important;
      bottom: 0.4em !important;
    }
    .carrocelRoletaRightButton {
      right: 2em !important;
      bottom: 0.4em !important;
    }
  }
  @media (max-width: 993px) {
    & {
      margin-top: -1.2em;
    }

    .carrocelRoletaContent1 {
      width: 100%;
      height: 20em;
    }
    .card {
      border-radius: 1em;
    }
    .carrocelRoletaLeftButton {
      bottom: 6em;
      left: 3em;
    }
    .carrocelRoletaRightButton {
      bottom: 6em;
      right: 3em;
    }
  }
  @media (max-width: 732px) {
    .carrocelRoletaContent1 {
      width: 100%;
      height: auto;
    }
  }
`;

export const H1 = styled.h1`
  position: absolute;
  top: -2.5em;
  left: 0;
  font-size: 1.2em;
  color: #fff;
`;
