import React from "react";

import { Container } from "./styles";
import LoadingDots from "~/componentes/LoadingDots";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toggleDisableButton } from "~/store/modules/disableButtons/actions";

export default function LoadingPage({ blockButtons }) {
  const dispatch = useDispatch();

  useEffect(() => {
    blockButtons && dispatch(toggleDisableButton());

    return () => {
      blockButtons && dispatch(toggleDisableButton());
    };
  }, [blockButtons, dispatch]);

  return (
    <Container>
      <LoadingDots>Carregando</LoadingDots>
    </Container>
  );
}
