import produce from "immer";

const INITIAL_STATE = {
  perfil: null,
  matricula: [],
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@auth/SIGN_IN_SUCCESS": {
        draft.perfil = action.payload.perfil;
        draft.matricula = action.payload.matricula;
        break;
      }
      case "@auth/CHANGE_TOKEN": {
        draft.matricula = action.payload.matricula;
        break;
      }
      case "@user/UPDATE_PERFIL_SUCCESS": {
        draft.perfil = action.payload.perfil;
        break;
      }
      case "@user/UPDATE_MATRICULA_SUCCESS": {
        draft.matricula = action.payload.matricula;
        break;
      }
      case "@auth/SIGN_OUT": {
        draft.perfil = null;
        draft.matricula = null;
        break;
      }
      default:
    }
  });
}
