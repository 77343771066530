import React from "react";
import TextContent from "~/componentes/TextContent";

import { Container, ThisCard, ThisCardTitle, ThisCardBody } from "./styles";

function CustomBodyContent({ bodyData }) {
  function handleDate(thisData) {
    const thisDate = thisData?.createdAtDate && new Date(thisData.createdAtDate);

    return thisDate
      ? `${thisDate.toLocaleDateString()} às ${thisData.createdAtHour}`
      : "";
  }

  return (
    <Container>
      {bodyData?.map((thisData, i) => (
        <ThisCard data={thisData} key={i}>
          <ThisCardTitle>
            <h3>
              #{thisData?.cod}
              {thisData?.arquivos?.length > 0 && (
                <i className="material-icons">attachment</i>
              )}
            </h3>
            <span>{handleDate(thisData)}</span>
          </ThisCardTitle>
          <ThisCardBody>
            <div className="ThisCardBody_title_tur_disc_div">
              <TextContent lines={1} setType="h2">
                {thisData?.titulo}
              </TextContent>
              <TextContent
                lines={1}
                setType="p"
                className="ThisCardbody_turma_disciplina"
              >
                {thisData?.disciplinaTurma?.disciplinaFilial?.nome} /{" "}
                {thisData?.disciplinaTurma?.turma?.nome}
              </TextContent>
            </div>
            <TextContent lines={3} className="ThisCardBody_TextContent_pre">
              {thisData?.mensagem}
            </TextContent>
          </ThisCardBody>
        </ThisCard>
      ))}
    </Container>
  );
}

export default CustomBodyContent;
