export default function ChangeIndexOrder(index, options) {
  const { alphabetical = true, startPoint = 0 } = options || {};
  let thisIndex = index + startPoint < 0 ? 0 : index + startPoint;

  if (alphabetical) {
    const { lowerCase } = alphabetical;
    const alphabetUpper = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"; // 26
    const alphabetLower = "abcdefghijklmnopqrstuvwxyz";

    const checkCase = (i) => (lowerCase ? alphabetLower[i] : alphabetUpper[i]);

    if (thisIndex > 25) {
      let divisor = parseInt(thisIndex / 26) - 1;
      let diference = thisIndex % 26;

      return checkCase(divisor) + checkCase(diference);
    }

    return checkCase(thisIndex);
  }
}
