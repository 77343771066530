import React from "react";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import PdfDownloader from "~/componentes/PdfDownloader";
import PdfDoc from "./PdfDoc";

function ThisPdfButton({ data }) {
  const { nome, email } = data?.usuario || {};

  return (
    <ColoredButton
      onClick={() =>
        PdfDownloader({
          document: <PdfDoc {...{ data }} />,
          filename: `${nome}-${email}`,
        })
      }
      materialIcon="arrow_downward"
      redLayout
    >
      PDF
    </ColoredButton>
  );
}

export default ThisPdfButton;
