import React, { useState } from "react";

import api from "~/services/api";
import EditContent from "./EditContent";
import CreateContent from "./CreateContent";
import ShowContent from "./ShowContent";
import {
  SLCreateModal,
  SLShowModal,
  SLEditModal,
} from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import TableComponent from "./TableComponent";

function PeriodoLetivo() {
  const [mountedData, setMountedData] = useState();
  const [tabFilter, setTabFilter] = useState();
  const [periodosLetivos, setPeriodosLetivos] = useState([]);

  return (
    <Container
      requests={{
        findAll: () => api.get("filial/periodosLetivos"),
        deleteOne: (thisData) =>
          api.delete("filial/periodoLetivo", { data: { cod: thisData.cod } }),
        findOne: (thisData) =>
          api.get("filial/periodoLetivo", {
            params: { cod: thisData.cod },
          }),
      }}
      dataFilter={tabFilter}
      setter={(thisData) => setPeriodosLetivos(thisData || [])}
    >
      <TableComponent {...{ setTabFilter }} />

      <SLShowModal title="Período Letivo" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLEditModal title="Atualizar Período Letivo" setter={setMountedData}>
        <EditContent {...{ mountedData }} />
      </SLEditModal>

      <SLCreateModal title="Novo Período Letivo">
        <CreateContent {...{ periodosLetivos }} />
      </SLCreateModal>
    </Container>
  );
}

export default PeriodoLetivo;
