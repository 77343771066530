import styled from "styled-components";

export const Container = styled.div`
  .TurmasList_div_header {
    display: flex;

    .TurmasList_div_header_input {
      max-width: ${(props) => (props.mobile ? `100%` : `60%`)};
    }

    .TurmasList_div_header_button {
      height: 2.5em;
      margin-top: auto;
      margin-bottom: 0.75em;
      margin-left: 1em;
      ${(props) =>
        props.mobile &&
        `
      width: fit-content;
      height: fit-content;
      margin-bottom: 0.6em;
      padding: 0.5em 0.75em;
      i {
        margin: auto;
      }
    `}
    }
  }

  .TurmasList_StyledTable {
    table-layout: auto;

    td:last-child,
    th:last-child {
      width: 5em;
      text-align: center;
    }
  }
`;

export const SubjectsField = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
