import styled from "styled-components";

export const Container = styled.div`
  .matriculas {
    flex-wrap: wrap;
    display: flex;

    .matriculaCard {
      background: #ddd;
      text-align: left;
      margin: 1em;
      width: 18em;
      height: 8em;
      border-radius: 1em;
      padding: 1em;
      border: 0.1em solid #666;
    }
  }

  .flexDiv {
    display: flex;
    flex-wrap: wrap;

    .card {
      margin: 0.5em;
      padding: 0.5em;
      border: 0.1em solid #666;
      background: #fff;
    }
  }
`;
