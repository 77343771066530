import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { Container, SubTitle } from "./styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import { Materialize } from "~/styles/materializeGrid";

function ShowContent({ mountedData }) {
  return (
    <Container>
      <CustomScrollBar fixScrollMargin autoHeightMax="80vh">
        <Materialize>
          <div className="row">
            <SubTitle>Dados do Token</SubTitle>
            <Label title="TIPO:" className="col s12 m12 l3" text={mountedData?.tipo || ""} />
            <Label title="TOKEN:" className="col s12 m12 l5" text={mountedData?.token || ""} />
          </div>
        </Materialize>
      </CustomScrollBar>
    </Container>
  );
}

export default ShowContent;
