import styled from "styled-components";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";

export const Container = styled(SistemaFixedModal)`
  width: 30em;
  max-width: 98%;

  .Label_inputWithButton {
    display: flex;
    align-items: center;

    button {
      padding: 0.36em 1em;
      margin-bottom: 0.3em;
      margin-left: 0.25em;
    }
  }

  .RecuperarSenha_p_info {
    color: #999;
  }
`;
