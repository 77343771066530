import React from "react";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import { useState } from "react";
import { useEffect } from "react";
import ThisModal from "./ThisModal";

function CreateModal({ mobile, setBancoSelected, setTab, bancos, bancoSelected, setAgenciaSelected, setContaSelected }) {
  const [showModal, setShowModal] = useState(false);

  /* useEffect(() => {
    let newPl = [];
    if (Array.isArray(bancos)) {
      bancos.forEach((plGlobal) => {
        const plMat = plNovos?.find((e) => String(e?.cod) === String(plGlobal?.cod));

        if (plMat) {
          const thisTurmas = plGlobal?.turmas?.filter(
            (tGlobal) =>
              !plMat.turmas.find((e) => String(e?.cod) === String(tGlobal?.cod))
          );
          if (thisTurmas.length > 0) {
            newPl.push({ ...plGlobal, turmas: thisTurmas || [] });
          }
        } else if (plGlobal?.turmas?.length > 0) {
          newPl.push(plGlobal);
        }
      });
    }
    setPlDisponiveis(newPl);
  }, [bancos, plNovos]); */

  return (
    <>
      <ColoredButton
        className="TurmasList_div_header_button"
        blueLayout
        type="button"
        onClick={() => setShowModal(!showModal)}
        materialIcon={"search"}
      >
      </ColoredButton>
      {showModal && (
        <ThisModal {...{ setTab, setShowModal, bancos, setBancoSelected, setAgenciaSelected, setContaSelected }} />
      )}
    </>
  );
}

export default CreateModal;
