import styled from "styled-components";
import { Button } from "~/componentes/CustomForm";

const TableButtonStyle = styled(Button)`
  background: none;
  border: none;

  i {
    color: #7b898c;
    font-size: 1.2em;
  }
`;

export default TableButtonStyle;
