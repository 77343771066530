import React, { useState } from "react";
import api from "~/services/api";
import { Container } from "./styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import handleFormData from "~/utils/handleFormData";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";

import CustomScrollBar from "~/componentes/CustomScrollBar";
import { Materialize } from "~/styles/materializeGrid";
import { SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import PreviewFileInput from "~/componentes/CustomForm/FileInput/styles/PreviewFileInput";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import SistemaTopico from "~/componentes/StyledComponents/Textos/SistemaTopico";
import ItensDiv from "./ItensDiv";

function CreateEditContent({ mountedData, disciplinas, questoesNiveis, series }) {
  const [itens, setItens] = useState(mountedData?.itens ? mountedData.itens : []);
  const [file, setFile] = useState();

  const [fileExist, setFileExist] = useState();
  const initialItemData = mountedData
    ? { questaoCod: mountedData.cod, enunciado: "", gabarito: false }
    : { questaoCod: 0, enunciado: "", gabarito: false };

  function handleSubmit(data, options) {
    const { closeReload } = options || {};

    return ShowConfirmacao(
      async () => {
        let response;
        let thisDataRequest = handleFormData(file, {
          ...data,
          itens,
          fileExist: fileExist,
          ...(mountedData ? { cod: mountedData.cod } : {}),
        });

        if (mountedData) {
          response = await api.put("admin/questaoeduqueme", thisDataRequest);
        } else {
          response = await api.post("admin/questaoeduqueme", thisDataRequest);
        }

        if (response?.status === 200) {
          closeReload && closeReload();
        }

        return response;
      },
      {
        successModal: `Questão ${mountedData ? "atualizada" : "criada"} com sucesso.`,
        texto: `Deseja ${mountedData ? "atualizar" : "criar"} essa Questão?`,
        errorModal: {
          defaultMessage: `Não foi possível ${
            mountedData ? "atualizar" : "criar"
          } essa questão, por favor, tente novamente.`,
        },
      }
    );
  }
  return (
    <Container onSubmit={handleSubmit}>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          <div className="row">
            <SistemaTopico>Dados Questão</SistemaTopico>
            <Label title="TÍTULO:" className="col s12 m12 l12">
              <SistemaInput name="nome" defaultValue={mountedData?.nome} required />
            </Label>
            <Label title="DISCIPLINA:" className="col s12 m12 l4">
              <SistemaSelect
                name="disciplinaCod"
                firstOption
                list={{ list: disciplinas, value: "cod", label: "nome" }}
                defaultValue={mountedData?.disciplinaCod}
                required
              />
            </Label>
            <Label title="NÍVEL DA QUESTÃO:" className="col s12 m12 l4">
              <SistemaSelect
                name="questaoNivelCod"
                firstOption
                list={{ list: questoesNiveis, value: "cod", label: "nome" }}
                defaultValue={mountedData?.nivel?.cod}
                required
              />
            </Label>
            <Label title="SÉRIE:" className="col s12 m12 l4">
              <SistemaSelect
                name="serieCod"
                firstOption
                list={{
                  list: series,
                  value: "cod",
                  label: "nome",
                }}
                defaultValue={mountedData?.series[0]?.serieCod}
                required
              />
            </Label>
            <Label title="ENUNCIADO:" className="col s12 m12 l8">
              <SistemaTextArea
                name="enunciado"
                defaultValue={mountedData?.enunciado}
                required
              />
            </Label>
            <Label title="IMAGEM:" className="col s12 m12 l4 label_preview">
              <PreviewFileInput
                maxHeight="15em"
                defaultPreview={mountedData?.arquivo?.url}
                formFile={setFile}
                checkPreview={setFileExist}
              />
            </Label>
            <SistemaTopico>Dados Itens</SistemaTopico>
            <Label className="col s12 m12 l12 questoes_lista_item">
              <ItensDiv
                itens={itens}
                initialItemData={initialItemData}
                setItens={setItens}
              />
            </Label>
          </div>
        </Materialize>
      </CustomScrollBar>
      <SLModalFooter />
    </Container>
  );
}

export default CreateEditContent;
