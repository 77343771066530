import React from "react";
import { CLCreateComponent } from "~/pages/Sistema/componentes/CustomList";
import api from "~/services/api";
import Label from "~/componentes/CustomForm/Label";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import { CLFindBeforeCreate } from "~/pages/Sistema/componentes/CustomList/CustomListStyledComponents";

function CreateModal({ cargos }) {
  return (
    <CLCreateComponent>
      <CLFindBeforeCreate
        onSubmit={(formData, userData) =>
          api.post("filial/funcionarioFilial", {
            ...formData,
            usuarioCod: userData?.cod,
          })
        }
        title="Cadastrar Funcionário"
      >
        <Label title="Turma">
          <SistemaSelect
            list={{ list: cargos, value: "cod", label: "nome" }}
            name="cargoFilialCod"
            firstOption
          />
        </Label>
      </CLFindBeforeCreate>
    </CLCreateComponent>
  );
}

export default CreateModal;
