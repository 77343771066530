import styled from "styled-components";

export const Container = styled.div`
  width: 80em;
  max-width: 100%;

  .Matriculas_ShowContent_SistemaFixedModal {
    width: 50em;
    max-width: 100%;
  }

  .Matriculas_ShowContent_div_avisos {
    border-bottom: solid 0.1em #f7f8fa;

    p {
      text-indent: 1em;
      font-size: 0.9em;
      color: #444;
    }
  }
`;
