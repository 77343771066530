import React from "react";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import Label from "~/componentes/CustomForm/Label";

import { Container } from "./styles";
import AcessosList from "~/componentes/AcessosList";

function ShowContent({ mountedData, modulos }) {
  return (
    <Container>
      <CustomScrollBar fixScrollMargin autoHeightMax="27em">
        <Label title="Nome" text={mountedData?.usuario?.nome || ""} />
        <Label title="Cargo" text={mountedData?.cargoFilial?.nome || ""} />
        <Label title="Estado" text={mountedData?.usuario?.estado?.nome || ""} />
        <Label title="Cidade" text={mountedData?.usuario?.cidade?.nome || ""} />
        <AcessosList
          showOnly
          filterMenuCod={mountedData?.acessos}
          modulos={modulos}
          title={`Acessos`}
        />
      </CustomScrollBar>
    </Container>
  );
}

export default ShowContent;
