import React from "react";
import { Input, TextArea } from "~/componentes/CustomForm";

import { Container, Form } from "./styles";
import UserDevice from "~/componentes/UserDevice";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import api from "~/services/api";
import CustomToast from "~/componentes/CustomToast";
import handleError from "~/utils/handleError";

export default function Mensagem() {
  const userDevice = UserDevice();
  const { desktop, rotatedTablet } = userDevice;
  const [formData, setFormData] = React.useState({
    nome: "",
    email: "",
    mensagem: "",
  });

  async function handleSubmit() {
    await api
      .post("enviarEmailContato", formData)
      .then(() => {
        setFormData({ email: "", nome: "", mensagem: "" });
        CustomToast.success("Email enviado com sucesso!");
      })
      .catch((err) =>
        CustomToast.error(
          handleError(
            err,
            "Falha ao envair o email, por favor, tente novamente"
          )
        )
      );
  }

  function handleChange(e) {
    const { value, name } = e.currentTarget || {};

    name && setFormData((prev) => ({ ...prev, [name]: value }));
  }

  return (
    <Container desktop={desktop} rotatedTablet={rotatedTablet}>
      <div className="inputs">
        <strong>Tire suas dúvidas aqui</strong>
        <Form onSubmit={handleSubmit}>
          <div>
            <label to="nome">Nome</label>
            <div className="inputDiv">
              <i className="material-icons">account_box</i>
              <Input
                className="withIcon"
                name="nome"
                onChange={handleChange}
                value={formData?.nome || ""}
                id="nome"
                type="text"
                placeholder="Nome"
                required
              />
            </div>
          </div>
          <div>
            <label to="email">Email</label>
            <div className="inputDiv">
              <i className="material-icons">contact_mail</i>
              <Input
                className="withIcon"
                name="email"
                onChange={handleChange}
                value={formData?.email || ""}
                id="email"
                type="email"
                placeholder="Digite seu email"
                required
              />
            </div>
          </div>
          <div>
            <label to="mensagem">Mensagem</label>
            <div className="inputDiv">
              <TextArea
                id="mensagem"
                name="mensagem"
                onChange={handleChange}
                value={formData?.mensagem || ""}
                placeholder="Digite aqui sua mensagem..."
                required
              />
            </div>
          </div>
          <ColoredButton blueLayout>Enviar</ColoredButton>
        </Form>
      </div>
      <div className="dados">
        <strong>Dados de Contato</strong>
        <ul>
          <li>facebook.com/eduquemee</li>
          <li>instagram.com/eduqueme</li>
          <li>contato@objetiveti.com.br</li>
          <li className="text-contato">
            <p>Objetive TI</p>
            <p>R. Nunes Valente - 1901</p>
            <p>Fortaleza - CE - 60125-035</p>
          </li>
        </ul>
      </div>
    </Container>
  );
}
