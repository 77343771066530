import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const QuestaoCard = styled.div`
  margin: 0.5em;
  padding: 0.5em 1em;
  border-radius: 0.1em;
  box-shadow: 0px 0.05em 1em rgba(0, 0, 0, 0.09);
  flex: 0 1 95%;

  span {
    color: #293f57;
    font-weight: 600;
    margin-left: 0.2em;
  }

  @media (min-width: 500px) {
    flex: 0 1 calc(50% - 1em);
  }

  @media (min-width: 1200px) {
    flex: 0 1 calc(33% - 1em);
  }

  .Questoes_Card_cod {
    margin: 0.5em 0;
    padding-bottom: 0.5em;
    color: #1ca9d0;
    border-bottom: solid #f0f0f0 2px;
  }

  .Questoes_Card_nomeProfessor {
    font-size: 0.8em;
    color: #9ab1b6;
  }

  .Questoes_Card_titulo {
    margin: 0.8em 0;
    font-weight: 600;
  }

  .Questoes_Card_enunciado {
    font-size: 0.95em;
    color: #8e98a4;
  }

  .Questoes_Card_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 0.1em solid #f0f0f0;
    margin-top: 1em;
    padding-top: 0.5em;

    .Questoes_Card_footer_dificuldade {
      background: ${(props) => {
        switch (props.nivel) {
          case "Difícil":
            return "#FF4031";
          case "Intermediário":
            return "#FFDA44";
          default:
            return "#4fa754";
        }
      }};
      font-size: 0.9em;
      border-radius: 0.2em;
      color: #fff;
      padding: 0.25em 1em;
    }

    .Questoes_Card_footer_buttons {
      i {
        position: relative;
        margin-left: 0.5em;
        font-size: 1.6em;
      }
      i::after {
        transition: 0.3s ease;
        content: "";
        position: absolute;
        background: rgba(28, 169, 208, 0.22);
        width: 1.5em;
        height: 1.5em;
        left: -0.25em;
        top: -0.25em;
        border-radius: 50%;
        transform: scale(0);
        transform-origin: center center;
      }
      i:hover::after {
        transform: scale(1);
      }
    }
  }
`;
