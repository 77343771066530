export default function handleError(err, defaultMessage) {
  let handleError = defaultMessage || "Não foi possível realizar esta ação";

  const thisError = err;
  const errorObject = thisError?.response?.data || thisError?.data;

  if (typeof thisError === "string") {
    handleError = thisError;
  } else if (errorObject) {
    if (Array.isArray(errorObject?.errors)) {
      for (const error of errorObject.errors) {
        if (error.errorHandled && error.message) {
          handleError = error.message;
          break;
        }
      }
    } else if (
      errorObject?.errorHandled &&
      (errorObject?.message || errorObject?.msg)
    ) {
      handleError = errorObject.message || errorObject?.msg;
    }
  }

  return handleError;
}
