import React from "react";
import IconButton from "~/componentes/CustomForm/Button/Styles/IconButton";
import Label from "~/componentes/CustomForm/Label";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import SistemaTable from "~/componentes/StyledComponents/Tables/SistemaTable";
import handleDate from "~/utils/handleDate";
import FormContent from "../FormContent";

// import { Container } from './styles';

function TabelaSeries({ filial, dadosMatriculas, periodosLetivos }) {
  const [periodoLetivo, setPeriodoLetivo] = React.useState();
  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    setPeriodoLetivo((prev) => (periodosLetivos?.find((pl) => pl?.cod === prev?.cod) ? prev : periodosLetivos?.[0]));
  }, [periodosLetivos]);

  // const series = React.useMemo(() => {
  //   const result = {};

  //   if (Array.isArray(periodosLetivos)) {
  //     periodosLetivos.forEach((pl) => {
  //       const { series: thisSeries, ...plRest } = pl || {};

  //       if (Array.isArray(pl?.series)) {
  //         pl.series.forEach((s) => {
  //           if (s?.cod && s?.ensinoCod) {
  //             if (Array.isArray(result?.[s.cod]?.periodosLetivos)) {
  //               result[s.cod].periodosLetivos.push(plRest);
  //             } else {
  //               result[s.cod] = { ...s, periodosLetivos: [plRest] };
  //             }
  //           }
  //         });
  //       }
  //     });
  //   }

  //   return Object.values(result);
  // }, [periodosLetivos]);

  function handleSerieConfirm(serie) {
    if (serie?.cod) {
      let dadosMatricula = dadosMatriculas?.find((e) => e?.cod === serie?.ensinoCod)?.configuracoesMatriculaFilial;

      let alunoIndex = dadosMatricula.findIndex(d => d.chave == "aluno");

      let dataset = {
        atributos: {
          required: true
        },
        chave: "isaluno",
        cod: 99,
        componente: "Verificação de Aluno",
        createdAtDate: "2020-10-22",
        createdAtHour: "10:51:54.220237",
        createdByUserCod: null,
        deletedAtDate: null,
        grupoCod: 2,
        largeColumnSize: 6,
        listPosition: 0,
        mediumColumnSize: 6,
        nome: "Verificação de Aluno",
        obrigatorio: true,
        selectDataBase: null,
        selectOptions: null,
        status: 1,
        componente: "Isaluno",
        tipoDado: {
          cod: 1,
          createdAtDate: "2020-10-22",
          createdAtHour: "10:51:54.208237",
          createdByUserCod: null,
          deletedAtDate: null,
          nome: "checkbox",
          status: 1
        },
        tipoDadoCod: 1
      };

      let dadosMatriculaNew = [dataset];

      dadosMatricula[alunoIndex].dados.forEach((data) => {
        if(data.chave != 'isaluno'){
          let datanew = data;
          datanew.listPosition = (datanew.listPosition + 1);
          dadosMatriculaNew.push(datanew);
        }
      });

      dadosMatricula[alunoIndex].dados = dadosMatriculaNew;

      setShowModal({ dadosMatricula, serie, periodoLetivo });
    }
  }

  return (
    <div style={{ margin: "1em" }}>
      {showModal && <FormContent outClick={() => setShowModal(false)} {...{ filial, periodoLetivo, ...showModal }} />}
      {periodosLetivos?.lenght > 1 && (
        <Label title="Período Letivo">
          <SistemaSelect defaultValue={periodoLetivo} setList={setPeriodoLetivo} list={{ list: periodosLetivos, label: "nome", value: "cod" }} />
        </Label>
      )}
      <SistemaTable>
        <thead>
          <tr>
            <th>Série</th>
            <th>Vagas</th>
            <th>Término da matrícula</th>
            <th>-</th>
          </tr>
        </thead>
        <tbody>
          {periodoLetivo?.series?.map?.((s) => (
            <tr key={s?.cod}>
              <td>{s?.nome}</td>
              <td>{s?.vagas > 0 ? s?.vagas : 0}</td>
              <td>{handleDate(periodoLetivo?.matriculaFim)?.dmy}</td>
              <td>
                <IconButton materialIcon="add" onClick={() => handleSerieConfirm(s)} />
              </td>
            </tr>
          ))}
        </tbody>
      </SistemaTable>
    </div>
  );
}

export default TabelaSeries;
