import styled from "styled-components";

export const Container = styled.div`
  width: 40em;
  max-width: 100%;
`;

export const StyledTable = styled.table`
  width: 100%;
  margin: auto;
  max-width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  th {
    background: #e6e6e6;
    color: #293f57;
    height: 2.5em;
    font-weight: 550;
  }

  td {
    color: #7b898c;
    border-bottom: solid 0.1em #e3e3e3;
    min-height: 2.6em;
    font-weight: 600;
    font-size: 0.875em;
  }

  td,
  th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-break: none;
    padding: 0.56em;
    text-align: left;
  }
`;
