import React from "react";
import { Route, Redirect } from "react-router-dom";

import { store } from "~/store";

export default function CustomRoute({
  component: Componente,
  layout: Layout,
  privado,
  footer,
  ...resto
}) {
  const { logado } = store.getState().auth;

  if (!logado && privado) {
    return <Redirect to="/" />;
  }

  // if (logado && !privado) {
  //   return <Redirect to="/sistema" />;
  // }

  return (
    <Route
      {...resto}
      render={(props) =>
        Layout ? (
          <Layout footer={footer}>
            <Componente {...props} />
          </Layout>
        ) : (
          <Componente {...props} />
        )
      }
    />
  );
}
