import styled from "styled-components";

export const Container = styled.div`
  .col {
    height: 5em;
  }

  .Label_inputWithButton {
    display: flex;
    align-items: center;

    button {
      padding: 0.36em 1em;
      margin-bottom: 0.3em;
      margin-left: 0.25em;
    }
  }
`;
