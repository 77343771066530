import React from "react";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import Label from "~/componentes/CustomForm/Label";

import { Container } from "./styles";
import SingleValueList from "~/componentes/SingleValueList";

function ShowContent({ mountedData, modulos }) {
  return (
    <Container>
      <CustomScrollBar fixScrollMargin autoHeightMax="27em">
        <Label title="Nome" text={mountedData?.usuario?.nome || ""} />
        <Label title="EMAIL" text={mountedData?.usuario?.email || ""} />
        <Label title="Estado" text={mountedData?.usuario?.estado?.nome || ""} />
        <Label title="Cidade" text={mountedData?.usuario?.cidade?.nome || ""} />
        <Label title="DISCIPLINAS" />
        <SingleValueList data={mountedData?.disciplinasFilial} dataKey="nome" />
      </CustomScrollBar>
    </Container>
  );
}

export default ShowContent;
