/* eslint-disable no-useless-computed-key */
import React, { useState, useLayoutEffect } from "react";

import api from "~/services/api";
import { SLCreateModal, SLShowModal } from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import CreateContent from "./CreateContent";
import ShowContent from "./ShowContent";
import CustomBodyContent from "./CustomBodyContent";

function DisponibilizarConteudo() {
  const [mountedData, setMountedData] = useState();
  const [disciplinas, setDisciplinas] = useState([]);
  const [bodyData, setBodyData] = useState([]);

  useLayoutEffect(() => {
    api
      .get("professor/gestaoTurmas/avaliacoesDisponibilizadas/professorDisciplinas")
      .then((r) => setDisciplinas(r?.data || []));
  }, []);

  return (
    <Container
      defaultStyle
      requests={{
        findAll: () =>
          api.get(
            "professor/gestaoTurmas/avaliacoesDisponibilizadas/conteudosDisponibilizados"
          ),
        findOne: (thisData) =>
          api.get(
            "professor/gestaoTurmas/avaliacoesDisponibilizadas/conteudoDisponibilizado",
            {
              params: { cod: thisData.cod },
            }
          ),
        deleteOne: (thisData) =>
          api.get(
            "professor/gestaoTurmas/avaliacoesDisponibilizadas/conteudoDisponibilizado",
            {
              params: { cod: thisData.cod },
            }
          ),
      }}
      pageSize={6}
      setter={setBodyData}
      inputs={[{ filter: "titulo", label: "Título" }]}
      selects={[
        {
          filter: "disciplinaTurma.disciplinaFilialCod",
          list: disciplinas,
          outLabel: "Disciplina",
          value: "cod",
          label: "nome",
        },
      ]}
    >
      <CustomBodyContent bodyData={bodyData} />

      <SLShowModal title="Conteúdo Disponibilizado" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLCreateModal title="Conteúdo Disponibilizado">
        <CreateContent {...{ disciplinas }} />
      </SLCreateModal>
    </Container>
  );
}

export default DisponibilizarConteudo;
