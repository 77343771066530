import React, { useLayoutEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { io } from "socket.io-client";
import { 
        Container, 
        ModalCentral, 
        VideoChatContainer, 
        RemoteVideoChat, 
        RemoteVideo, 
        RemoteVideoPrimary, 
        ContainerVideos, 
        AsideContainer,
        AsideCode,
        TeacherVideoContainer,
        DescriptionContainer,
        Description,
        Actions,
        Avatar,
        Name,
        VideoCamPaused,
        PlaceholderVideoCam,
        DescriptionRemoteVideo,
        AsideTeacherList,
        AsideTeacherListCard,
        Separator
      } from "./styles";
import PeerBuilder from "./scripts/PeerBuilder"
import PeerRoomBuilder from "./scripts/PeerRoomBuilder"
import MediaStreamBuilder from "./scripts/MediaStreamBuilder"
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import Label from "~/componentes/CustomForm/Label";
import { EduquemeInput } from "~/componentes/CustomForm/Input/styles";
import ShowError from "~/utils/ShowError";
import CustomToast from "~/componentes/CustomToast";
import { useEffect } from "react";
import { useContext } from "react";
import { StreamContext } from "./context";

//obs: erro de conexao com tres pessoas
//const socket = io('http://10.0.0.222:3336');
export default function VideoconferenciaComponente() {
  const {
    user, 
    setCodigoSala, 
    codigoSala,
    videoIsPaused, 
    setVideoIsPaused, 
    audioIsPaused, 
    setAudioIsPaused,
    roomIsReady, 
    allUsersConnected, 
    remoteVideoChatRef,
    screenShared,
    setScreenShared,
    onJoin,
    toggleMute,
    unpublishOwnFeed,
    publishOwnFeed,
    localVideoChatRef,
    onCreateRoom,
    toggleScreen
  } = useContext(StreamContext);

  return (
    <ModalCentral>
      {!roomIsReady ? (
        <Container onSubmit={({codigo_sala}) => {
          setCodigoSala(codigo_sala);
          let user = localStorage.getItem('persist:eduqueMe');
          user = JSON.parse(user);
          console.log(codigo_sala);
          onJoin();
        }}>
          <h1>Digite o código da sala professor!</h1>
  
          <Label className="col s12 m12 l12" title="Código">
            <EduquemeInput type="text" name="codigo_sala" id="codigo_sala" required />
          </Label>
  
          <div className="botao">
            <ColoredButton type="submit" greenLayout>
              Entrar
            </ColoredButton>
            <div className="form-separador">
              <h5>ou</h5>
            </div>
            <ColoredButton type="button" blueLayout onClick={() => {
              let user = localStorage.getItem('persist:eduqueMe');
              user = JSON.parse(user);
              console.log('join room');
              onCreateRoom();
            }}>
              Criar Nova Sala
            </ColoredButton>
          </div>
        </Container>
      ) : (
        <VideoChatContainer>
          <RemoteVideoPrimary>
            <TeacherVideoContainer ref={localVideoChatRef} id="localVideoChat">
              <div className="panel-body" id="videolocal" style={{width: '100%', height: '100%'}}>
                
              </div>
              <DescriptionContainer id="description">
                <Description>
                  <Avatar>{user && user.perfil.nome[0]}</Avatar>
                  <Name>{user && user.perfil.nome}</Name>
                </Description>
                <Actions>
                  {audioIsPaused ? (
                    <i onClick={() => {
                      setAudioIsPaused(false);
                      toggleMute();
                    }} className="material-icons">mic</i>
                  ) : (
                    <i onClick={() => {
                      setAudioIsPaused(true);
                      toggleMute();
                    }} className="material-icons">mic_off</i>
                  )}
                  <i onClick={() => {
                    window.location.reload();
                  }} className="material-icons" id='disconnectIcon'>call_end</i>
                  {videoIsPaused ? (
                    <i onClick={() => {
                      setVideoIsPaused(false);
                      toggleScreen(true, false, true);
                    }} className="material-icons">videocam</i>
                  ) : (
                    <i onClick={() => {
                      setVideoIsPaused(true);
                      setScreenShared(false);
                      toggleScreen(true, false, false);
                    }} className="material-icons">videocam_off</i>
                  )}
                  {!screenShared ? (
                    <i onClick={() => {
                      setScreenShared(true);
                      /* unpublishOwnFeed();
                      publishOwnFeed(true, true); */
                      toggleScreen(true, true, false);
                    }} className="material-icons">screen_share</i>
                  ) : (
                    <i onClick={() => {
                      setScreenShared(false);
                      /* unpublishOwnFeed();
                      publishOwnFeed(true); */
                      toggleScreen(true, false, true);
                    }} className="material-icons">stop_screen_share</i>
                  )}
                </Actions>
              </DescriptionContainer>
            </TeacherVideoContainer>
            <AsideContainer>
              <AsideCode>
                <p>Código da Sala: {codigoSala}</p>
              </AsideCode>
                <AsideTeacherList>
                  <h3>Professores</h3>
                  {allUsersConnected && allUsersConnected.filter(users => users.tipo == "professor").map(user => {
                    console.log(user);
                    return (
                      <AsideTeacherListCard key={user.nome}>
                        <div id="AsideTeacherListCard-containerName">
                          <Avatar>{user.nome[0]}</Avatar>
                          <p>{user.nome}</p>
                        </div>
                        <i className="material-icons">mic</i>
                      </AsideTeacherListCard>
                    )
                  })}
                  <h3>Alunos</h3>
                  {allUsersConnected && allUsersConnected.filter(users => users.tipo == "aluno").map(user => {
                    console.log(user);
                    return (
                      <AsideTeacherListCard key={user.nome}>
                        <div id="AsideTeacherListCard-containerName">
                          <Avatar>{user.nome[0]}</Avatar>
                          <p>{user.nome}</p>
                        </div>
                      </AsideTeacherListCard>
                    )
                  })}
                </AsideTeacherList>
              
            </AsideContainer>
          </RemoteVideoPrimary>
          <ContainerVideos>
          
          <RemoteVideoChat ref={remoteVideoChatRef}>
          </RemoteVideoChat>
          </ContainerVideos>
          
        </VideoChatContainer>
      )}
    </ModalCentral>
  );
}
