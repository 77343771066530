import styled from "styled-components";
import { SLTable } from "~/pages/Sistema/componentes/SistemaList";

export const ThisTable = styled(SLTable)`
  table-layout: fixed;

  td,
  th {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 0px;
    text-align: center;
  }

  td:nth-child(1),
  th:nth-child(1) {
    text-align: left;
    width: 20em;
  }

  td:nth-child(2),
  th:nth-child(2) {
    width: 11em;
  }
`;
