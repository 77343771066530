import React from "react";
import SistemaTable from "~/componentes/StyledComponents/Tables/SistemaTable";
import IconButton from "~/componentes/CustomForm/Button/Styles/IconButton";
import api from "~/services/api";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import Pagination from "~/componentes/Pagination";
import { useState } from "react";
import EditAlunoTurmaModal from "./EditAlunoTurmaModal";

function ThisTableComponent({
  tab,
  reloadMatriculas,
  reloadTableData,
  filteredData,
  setPlNovos,
}) {
  const [pageData, setPageData] = useState([]);
  const [editAlunoTurmaModal, setEditAlunoTurmaModal] = useState();

  async function handleDelete(data) {
    const checkCod = data?.alunoTurma;
    if (checkCod?.cod) {
      ShowConfirmacao(
        async () => {
          await api
            .put("filial/alunoTurma", {
              cod: checkCod?.cod,
              ativo: !checkCod?.ativo,
            })
            .then(async (r) => {
              if (r?.status === 200) {
                typeof reloadTableData === "function" && reloadTableData();
                typeof reloadMatriculas === "function" && (await reloadMatriculas());
              }
              return r;
            });
        },
        {
          errorModal: {
            defaultMessage: `Ocorreu um problema ao ${
              tab === 2 ? "reativar" : "desativar"
            } essa turma, tente novamente mais tarde.`,
          },
          successModal: false,
          message: `Tem certeza que deseja ${
            tab === 2 ? "reativar" : "desativar"
          } essa turma?`,
        }
      );
    } else {
      setPlNovos((prev) => {
        let newPrev = Array.isArray(prev) ? [...prev] : [];

        newPrev.forEach((pl, plI) => {
          if (Array.isArray(pl?.turmas)) {
            pl.turmas.forEach((t, tI) => {
              if (t?.cod === data?.cod) {
                if (pl.turmas.lenght === 1) {
                  newPrev.splice(plI, 1);
                } else {
                  delete pl.turmas.splice(tI, 1);
                }
              }
            });
          }
        });
        return newPrev;
      });
      await reloadMatriculas();
    }
  }

  return (
    <CustomScrollBar>
      {editAlunoTurmaModal && (
        <EditAlunoTurmaModal
          data={editAlunoTurmaModal}
          outClick={() => setEditAlunoTurmaModal()}
          reloadMatriculas={reloadMatriculas}
        />
      )}
      <SistemaTable className="TurmasList_StyledTable">
        <thead>
          <tr>
            <th>Turma</th>
            <th>Série</th>
            <th>Ensino</th>
            <th>-</th>
          </tr>
        </thead>
        <tbody>
          {pageData?.map((turma, i) => (
            <tr key={turma?.cod || i}>
              <td>{turma?.nome}</td>
              <td>{turma?.serie?.nome}</td>
              <td>{turma?.serie?.ensino?.nome}</td>
              <td>
                {turma?.ativo && turma?.vigente && (
                  <>
                    {tab === 0 && (
                      <IconButton
                        onClick={() => setEditAlunoTurmaModal(turma)}
                        materialIcon="create"
                      />
                    )}
                    <IconButton
                      onClick={() => handleDelete(turma)}
                      materialIcon={tab === 2 ? "undo" : { delete: true }}
                    />
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </SistemaTable>
      <Pagination
        size={4}
        data={filteredData}
        getInfo={({ pageData }) => setPageData(pageData)}
      />
    </CustomScrollBar>
  );
}

export default ThisTableComponent;
