import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { CLModalButtons } from "../../..";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import { FlexDiv } from "~/componentes/StyledComponents/FlexDiv";

function StyledCustomListCheckUser({ data, setConfirmData }) {
  return (
    <>
      <CustomScrollBar autoHeightMax="10em">
        <FlexDiv rightAlign>
          <Label title="Nome" text={data?.nome || ""} />
          <Label title="Estado" text={data?.estado?.nome || ""} />
        </FlexDiv>
        <FlexDiv rightAlign>
          <Label title="Cidade" text={data?.cidade?.nome || ""} />
          <Label title="Bairro" text={data?.bairro || ""} />
        </FlexDiv>
      </CustomScrollBar>
      <CLModalButtons
        confirmButton={{
          type: "button",
          onClick: () => setConfirmData(true),
        }}
      />
    </>
  );
}

export default StyledCustomListCheckUser;
