import React from "react";
import FilterList from "~/componentes/FilterList";

import { SistemaTableFilter } from "./styles";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import FilterInputList from "../../../FilterInputList";
import sistemaListStore from "../thisStore";
import ThisFilterModal from "../ThisModals/ThisFilterModal";
import ThisModalButtonHandler from "../ThisModalButtonHandler";

function ThisTopComponent({ data, setFilteredData }) {
  const { state } = React.useContext(sistemaListStore);
  const { inputs, selects, selectValues, createModalButton, setCustomFilterModal } = state || {};

  return (
    <>
      {(inputs?.length > 0 || selects?.length > 0 || createModalButton) && (
        <SistemaTableFilter>
          {(inputs?.length > 0 || selects?.length > 0) && (
            <FilterList selectFilters={selectValues} data={data} singleInput setter={setFilteredData}>
              {inputs?.length > 0 && <FilterInputList className="ThisTopComponent_FilterInputList" inputs={inputs} />}
            </FilterList>
          )}
          {(selects?.length > 0 || createModalButton) && (
            <div className="ThisTopComponent_buttons">
              {setCustomFilterModal ? (
                <ColoredButton small yellowLayout className="ThisTopComponent_buttons_firstButton" onClick={() => setCustomFilterModal?.(true)}>
                  <i className="material-icons">filter_alt</i>
                  Filtrar
                </ColoredButton>
              ) : (
                selects?.length > 0 && (
                  <ThisModalButtonHandler
                    moldalType="filter"
                    setType={ColoredButton}
                    small
                    yellowLayout
                    className="ThisTopComponent_buttons_firstButton"
                  >
                    <i className="material-icons">filter_alt</i>
                    Filtrar
                  </ThisModalButtonHandler>
                )
              )}
              {createModalButton && (
                <ThisModalButtonHandler moldalType="create" setType={ColoredButton} small blueLayout>
                  <i className="material-icons">add_circle</i>
                  Novo
                </ThisModalButtonHandler>
              )}
            </div>
          )}
          {!setCustomFilterModal && <ThisFilterModal />}
        </SistemaTableFilter>
      )}
    </>
  );
}

export default ThisTopComponent;
