import React, { useState } from "react";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";
import SistemaTable from "~/componentes/StyledComponents/Tables/SistemaTable";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import Label from "~/componentes/CustomForm/Label";
import IconButton from "~/componentes/CustomForm/Button/Styles/IconButton";
import Pagination from "~/componentes/Pagination";
import { useEffect } from "react";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import api from "~/services/api";

import { ThisAddDisciplinaTurma } from "./styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import ShowError from "~/utils/ShowError";
import { Materialize } from "~/styles/materializeGrid";
import { SubTitle } from "../ShowContent/styles";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import { SLForm, SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import sistemaListStore, { sistemaListShowModal } from "~/pages/Sistema/componentes/SistemaList/ThisMainComponent/thisStore";
import ColoredFileInput from "~/componentes/CustomForm/FileInput/styles/ColoredFileInput";

function ChamadaSelecionados({ data, outClick, dataDia }) {
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState('');
  const { state, dispatch } = React.useContext(sistemaListStore);

  //console.log("periodocod", data)
  let alunosNotas = data?.t?.notas.filter((el)=> {
    if(data.tudo.turma.periodo[0].cod == parseInt(data.bimestre)){
      return el.notaAvaliacao
    }
  });
  
  let endSubmit = false;
  async function handleSubmit(dataPut, options){
    //console.log(dataPut);
    //console.log(options);
    //console.log(state);

    ShowConfirmacao(
      async () => {
        let responseUpdate;
        let responseCreate;

        let idsSelected = data?.idsSelected;
        for (let index = 0; index < idsSelected.length; index++) {
          const idAluno = idsSelected[index];
          
          

          let chamada = await api.get("professor/chamada/one", { params: { alunoTurmaCod: idAluno?.alunoTurmaCod, data: dataDia } });
          chamada = chamada.data;
          //console.log('chamada', chamada);
          //console.log('idAluno', idAluno);
          if (dataPut instanceof FormData) {
            !dataPut.get('descricao_faltajustificada') && dataPut.set("descricao_faltajustificada", "-");
            dataPut.set("disciplinaTurmaCod", idAluno?.disciplinaTurmaCod);
            dataPut.set("alunoTurmaCod", idAluno?.alunoTurmaCod);
            data?.professorCod && dataPut.set("professorCod", data?.professorCod);
            dataPut.set("data", dataDia);
          }

          if (chamada.length > 0) {
            responseUpdate = await api.post(
              "/professor/chamada/alterar",
              dataPut
            );
          }
          if (chamada.length == 0) {
            responseCreate = await api.post(
              "/professor/chamada",
              dataPut
            );
          }
        }

        options.reloadData();
        endSubmit = true;
      },
      {
        errorModal: {defaultMessage: "Não foi possível atualizar a matrícula dessa turma, por favor, tente novamente mais tarde."},
        successModal: false,
        message: "Tem certeza que deseja atualizar a nota desse aluno?",
      }
    );
  }

  function onSubmit(closeModal){
    document.querySelector("#BTN_SUBMIT").click();
    let timer = setInterval(() => {
      if(endSubmit){
        closeModal()
        clearInterval(timer);
      }
    }, 1000)
  }
    //console.log(data?.tudo)
  return (
    <SistemaFixedModal
      title="Fazer Chamada Selecionados"
      style={{ width: "50em", maxWidth: "100%" }}
      {...{ outClick }}
      footerButtons={{submitButton: {onClick: onSubmit}}}
    >
      <SLForm id="FORM_EDIT_NOTAS" isFormData onSubmit={handleSubmit}>
        <Materialize>
          <SubTitle>Alunos Selecionados</SubTitle>
          <div className="row">
            <Label
              title="Quantidade de Alunos:"
              text={`${data?.idsSelected?.length} aluno${data?.idsSelected?.length > 1 && 's'} selecionados` || ""}
              className="col s12 m12 l6"
            />
          </div>
          <SubTitle>Presença</SubTitle>
          <div className="row">
            <Label
              title={'Status'}
              className="col s12 m12 l3"
            >
              <SistemaSelect name={`tipo_chamada`} value={status} onChange={(thiss) => {setStatus(thiss.target.value);}}>
                <option value={'P'}>Presente</option>
                <option value={'F'}>Falta</option>
                <option value={'FJ'}>Falta Justificada</option>
              </SistemaSelect>
              {/* <SistemaInput name={`${alunosNotas[index]?.cod || 'novo-' + index}`} defaultValue={`${alunosNotas[index]?.notaAvaliacao  || 'nulo'}`} required />   */}
            </Label>
          </div>
          {status == 'FJ' && (
            <>
            <SubTitle>Falta Justificada</SubTitle>
            <div className="row">
              <Label
                title={'Descrição'}
                className="col s12 m12 l6"
              >
                <SistemaInput name={`descricao_faltajustificada`} defaultValue={``} required />  
              </Label>
              <Label
                title={'Arquivo'}
                className="col s12 m12 l6"
              >
                <ColoredFileInput name="files" formFile={setFiles} />
              </Label>
            </div>
            </>
          )}
          <input type="submit" id="BTN_SUBMIT" style={{opacity: 0}} />
        </Materialize>
        {/* <SLModalFooter cancelButton={false}  /> */}
      </SLForm>
    </SistemaFixedModal>
  );
}

export default ChamadaSelecionados;
