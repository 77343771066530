import styled from "styled-components";

export const ModuloNameDiv = styled.div`
  display: flex;
  vertical-align: center;

  i {
    font-size: 1.3em;
    margin-right: 0.6em;
  }

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.9em;
  }
`;

export const ThisButton = styled.button`
  height: 2.5em;
  font-size: 1.2em;
  background: none;
  border: none;
  position: relative;
  width: 100%;
  padding: 0 0.5em 0 1em;
  transition: 0.2s;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: space-between;

  ${({ selected }) => {
    if (selected) {
      return `
      color: #fff;
      background: #1CA9D0;

      ::before {
        content: "";
        position: absolute;
        left: -0.5em;
        width: 0.75em;
        height: 0.75em;
        background: #fff;
        border-radius: 50%;
      }`;
    } else {
      return `
        color: #D6D6D6;
      `;
    }
  }}

  :hover {
    background: #1ca9d0;
    color: #fff;
  }
`;
