import React, { useState, useLayoutEffect } from "react";

import api from "~/services/api";
import ShowContent from "./ShowContent";
import CreateContent from "./CreateContent";
import EditContent from "./EditContent";

import { Container } from "./styles";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import Pagination from "~/componentes/Pagination";
import CustomBodyContent from "./CustomBodyContent";
import { SLCreateModal, SLEditModal, SLShowModal } from "~/pages/Sistema/componentes/SistemaList";
import { Badge, Calendar, Col, DatePicker, Row, Select, Collapse } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { ThisSistemaListaIconButonStyle } from "~/pages/Sistema/componentes/SistemaList/ThisStyledComponents/ThisButtons/styles";
dayjs.locale('pt-br');

export default function Calendario() {
  const [mountedData, setMountedData] = useState({});
  const [data, setData] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [DTM, setDTM] = useState([]);
  const [turma, setTurma] = useState([]);
  const [tipos, setTipos] = useState([{cod: "Atividade"}]);
  const [tabFilter, setTabFilter] = useState({ ativo: true });

  const { Panel } = Collapse;

  const [expandIconPosition, setExpandIconPosition] = useState('start');
  const onPositionChange = (newExpandIconPosition) => {
    setExpandIconPosition(newExpandIconPosition);
  };
  const onChange = (key) => {
    console.log(key);
  };

  useLayoutEffect(() => {
    api.get("aluno/calendario/turma").then((response) => {
      setTurma(response.data.disciplinaTurma);

      let disciplinaTurma = response.data.disciplinaTurma;
      console.log(disciplinaTurma);
      api.get("/aluno/calendario", {params: {disciplinaTurma}}).then((response) => {
        setData(response.data);
        let now = dayjs();
        onSelect(now, response.data, true);
      });
    });
  }, []);

  const onSelect = (newValue, dataFirstload = [], firstLoad = false) => {
    let dataValue = newValue.format('YYYY-MM-DD');
    console.log(dataValue);
    
    let dataForFilter = firstLoad ? dataFirstload : data;
    let newData = dataForFilter.filter(d => d.data == dataValue);
    console.log(newData);
    setDataFiltered(newData);
  };
  const getCellData = (value) => {
    let date = value.format('YYYY-MM-DD');
    return data.filter(d => d.data == date).map(d => {
      return {
        type: 'warning',
        content: d.titulo,
      }
    });
  };
  const dateCellRender = (value) => {
    const listData = getCellData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };
  const cellRender = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    return info.originNode;
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
      <Calendar mode="month" locale={{lang: {locale: 'pt_BR'}}} onSelect={onSelect} cellRender={cellRender}
        headerRender={({ value, type, onChange, onTypeChange }) => {
          const start = 0;
          const end = 12;
          const monthOptions = [];
          let current = value.clone();
          const localeData = value.localeData();
          const months = [];
          for (let i = 0; i < 12; i++) {
            current = current.month(i);
            months.push(localeData.monthsShort(current));
          }
          for (let i = start; i < end; i++) {
            monthOptions.push(
              <Select.Option key={i} value={i} className="month-item">
                {months[i]}
              </Select.Option>,
            );
          }
          const year = value.year();
          const month = value.month();
          const options = [];
          for (let i = year - 10; i < year + 10; i += 1) {
            options.push(
              <Select.Option key={i} value={i} className="year-item">
                {i}
              </Select.Option>,
            );
          }
          return (
            <div
              style={{
                padding: 8,
              }}
            >
              <Row gutter={8}>
                <Col>
                  <Select
                    size="small"
                    dropdownMatchSelectWidth={false}
                    className="my-year-select"
                    value={year}
                    onChange={(newYear) => {
                      const now = value.clone().year(newYear);
                      onChange(now);
                    }}
                  >
                    {options}
                  </Select>
                </Col>
                <Col>
                  <Select
                    size="small"
                    dropdownMatchSelectWidth={false}
                    value={month}
                    onChange={(newMonth) => {
                      const now = value.clone().month(newMonth);
                      onChange(now);
                    }}
                  >
                    {monthOptions}
                  </Select>
                </Col>
              </Row>
            </div>
          );
        }}
      />
      <div>
        <TabsWrapper tabHeight={2.4}>
          <Tab materialIcon="check_circle_outline" onClick={() => setTabFilter({ ativo: true })}>
            Lembretes
          </Tab>
        </TabsWrapper>

        {dataFiltered &&
          (<div>
            <Collapse
              defaultActiveKey={['1']}
              onChange={onChange}
              expandIconPosition={expandIconPosition}
            >
              {dataFiltered.map(d => {
                return (
                  <Panel className="lembretes" header={`${d.titulo} - ${d.tipo}`} key={d.cod} data-cod={d.cod}>
                    <div>{d.descricao}</div>
                    <div style={{fontSize: "12px", color: "gray"}}>{d.data}</div>
                  </Panel>
                )
              })}
            </Collapse>
          </div>)
        }
        {dataFiltered.length == 0 && (
          <div style={{backgroundColor: "#FFF", textAlign: "center"}}><p>Sem lembretes por aqui 👀...</p></div>
        )}
        
        
      </div>
    </div>
  );
}

export { ShowContent as ShowQuestao, CreateContent as CreateQuestao, EditContent as EditQuestao };
