import React, { useState, useEffect } from "react";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import api from "~/services/api";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import Label from "~/componentes/CustomForm/Label";
import { Container } from "./styles";
import DisciplinasFilialList from "../DisciplinasFilialList";
import { Materialize } from "~/styles/materializeGrid";

function CreateEditContent({ series, periodosLetivos, mountedData }) {
  const [disciplinasAtivas, setDisciplinasAtivas] = useState();
  const [periodosLetivosValidos, setPeriodosLetivosValidos] = useState([]);

  useEffect(() => {
    if (Array.isArray(periodosLetivos)) {
      const thisValidos = periodosLetivos.filter(
        (e) => e.valido || mountedData?.periodoLetivoCod === e?.cod
      );

      setPeriodosLetivosValidos(thisValidos || []);
    }
  }, [mountedData, periodosLetivos]);

  function handleSubmit(editedData, options) {
    const { closeReload } = options || {};
    return ShowConfirmacao(
      async () => {
        let response;

        if (mountedData) {
          response = await api.put("filial/turma", {
            ...editedData,
            disciplinasTurma: disciplinasAtivas,
            cod: mountedData.cod,
          });
        } else {
          response = await api.post("filial/turma", {
            ...editedData,
            disciplinasTurma: disciplinasAtivas,
          });
        }

        if (response?.status === 200) {
          closeReload && closeReload();
        }

        return response;
      },
      {
        message: `Tem certeza que deseja ${
          mountedData ? "atualizar" : "criar"
        } essa turma?`,
        errorModal: {
          defaultMessage: `Ocorreu um erro ao ${
            mountedData ? "atualizar" : "criar"
          } a turma, por favor, tente novamente.`,
        },
        successModal: `Turma ${
          mountedData ? "atualizado" : "criado"
        } com sucesso!`,
      }
    );
  }

  return (
    <Container defaultStyle onSubmit={handleSubmit}>
      <Materialize>
        <div className="row">
          <Label title="NOME:" className="col s12 m12 l9">
            <SistemaInput
              required
              name="nome"
              defaultValue={mountedData?.nome}
            />
          </Label>
          <Label title="QUANTIDADE ALUNOS:" className="col s12 m12 l3">
            <SistemaInput
              required
              name="quantidadeAlunos"
              defaultValue={mountedData?.quantidadeAlunos}
              type="number"
            />
          </Label>
          <Label title="PERÍODO LETIVO:" className="col s12 m12 l4">
            <SistemaSelect
              required
              name="periodoLetivoCod"
              list={{
                list: periodosLetivosValidos,
                value: "cod",
                label: "nome",
              }}
              defaultValue={
                mountedData?.periodoLetivoCod ||
                periodosLetivosValidos?.[0]?.cod
              }
            />
          </Label>
          <Label title="SÉRIE:" className="col s12 m12 l4">
            <SistemaSelect
              required
              name="serieCod"
              list={{ list: series, value: "cod", label: "nome" }}
              defaultValue={mountedData?.serieCod || series?.[0]?.cod}
            />
          </Label>
          <Label title="ATIVO:" className="col s12 m12 l4">
            <SistemaSelect
              required
              name="ativo"
              isBoolean
              list={{
                list: [
                  { value: "true", label: "Sim" },
                  { value: "false", label: "Não" },
                ],
                value: "value",
                label: "label",
              }}
              defaultValue={mountedData?.ativo === false ? "false" : "true"}
            />
          </Label>
        </div>
      </Materialize>
      <DisciplinasFilialList
        {...{
          startTab: mountedData?.cod ? 1 : 0,
          setter: setDisciplinasAtivas,
          defaultData: mountedData?.disciplinasTurma,
        }}
      />
    </Container>
  );
}

export default CreateEditContent;
