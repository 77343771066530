import styled from "styled-components";

export const Container = styled.div`
  margin: 0 0.4em;
  text-align: center;
  padding-right: 0.5em;

  img {
    height: 14em;
    width: 14em;
    border-radius: 100%;
    margin: 0.5em 0;
  }

  h3 {
    font-weight: normal;
    font-size: 1.875em;
    line-height: 1.75em;

    color: #333333;
  }
  p {
    font-weight: normal;
    font-size: 0.875em;
    line-height: 1.488em;
    text-align: center;

    color: #1f3346;
  }

  @media (max-width: 30em) {
    .flex-item-1 {
      width: auto !important;
      margin: 0 0.4em 2em 0em !important;
      border-right: none !important;
      border-bottom: solid #efefef 0.125em !important;
      padding-bottom: 0.5em !important;
    }
  }
`;
