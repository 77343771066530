import styled from "styled-components";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";

export const SistemaTableFilter = styled(SistemaComponenteInterno)`
  margin-bottom: 1em;

  @media (min-width: 65em) {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .ThisTopComponent_FilterInputList {
    margin-right: 1em;
    max-width: 100%;

    input {
      width: 18em;
    }

    @media (max-width: 64.999em) {
      margin-bottom: 1em;
      margin-right: 0;

      input {
        width: 100%;
      }
    }
  }

  .ThisTopComponent_buttons {
    display: flex;

    @media (max-width: 64.999em) {
      justify-content: center;
    }

    i {
      margin-right: 0.25em;
      font-size: 1.4em;
    }

    .ThisTopComponent_buttons_firstButton {
      margin-right: 1em;
    }
  }
`;
