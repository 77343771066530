import React from "react";

import { Container } from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { errorToggle } from "~/store/modules/errorModal/actions";
import { modalTimeout } from "~/config/definicoesPadrao";

export default function ErrorModal(props) {
  const [closeAnimation, setCloseAnimation] = React.useState(() => () => {});
  const { ativo, message } = useSelector((state) => state.errorModal);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setTimeout(() => ativo && closeAnimation(), modalTimeout);
  }, [ativo, closeAnimation]);

  return (
    <Container
      checkRedux={false}
      outClickAnimation={setCloseAnimation}
      outClick={() => ativo && dispatch(errorToggle())}
      {...props}
    >
      <div className="errorContent">
        <div className="errorIcon">
          <i className="material-icons">error_outline</i>
        </div>
        <p className="ErrorModal_p_message">{message}</p>
      </div>
    </Container>
  );
}
