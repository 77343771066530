import React from "react";
import { Document } from "@react-pdf/renderer";

import {
  Content,
  Strong,
  Title,
  Paragraph,
  Card,
  TwoColumnCell,
  ColumnCell,
} from "./styles";
import handleDate from "~/utils/handleDate";

function PdfDoc({ data }) {
  return (
    <Document>
      <Content>
        <Paragraph>
          <Strong>Nome:</Strong> {data?.usuario?.nome}
        </Paragraph>
        <Paragraph>
          <Strong>Email:</Strong> {data?.usuario?.email}
        </Paragraph>
        <Title>Avaliacoes</Title>
        {data?.atividades?.map?.((av, i) => (
          <Card key={av?.cod || i}>
            <TwoColumnCell>
              <Strong>Disciplina:</Strong> {av?.disciplinaFilial?.nome}
            </TwoColumnCell>
            <TwoColumnCell>
              <Strong>Turma:</Strong> {av?.turma?.nome}
            </TwoColumnCell>
            <TwoColumnCell>
              <Strong>Série:</Strong> {av?.serie?.nome}
            </TwoColumnCell>
            <ColumnCell>
              <Strong>Corrigido:</Strong> {av?.corrigido ? "Sim" : "Não"}
            </ColumnCell>
            <Paragraph>
              <Strong>Data da avaliação:</Strong>{" "}
              {handleDate(av?.dataInicio).dmyhm} até{" "}
              {handleDate(av?.dataFim).dmyhm}
            </Paragraph>
          </Card>
        ))}
      </Content>
    </Document>
  );
}

export default PdfDoc;
