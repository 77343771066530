import React, { useState } from "react";

import { Container, LeftMenu, Content, Main } from "./styles";
// import Notificacoes from "./componentes/Notificacoes";
import MenuLista from "./componentes/MenuLista";

import Logo from "~/assets/logos/eduque_me_negativo.png";
import Perfil from "./componentes/Perfil";
// import Logout from "./componentes/Logout";
import Modal from "~/componentes/Modal";
import Acessos from "./componentes/Acessos";
import TextContent from "~/componentes/TextContent";
import LeaveSystem from "./componentes/LeaveSystem";
import sistemaStore from "./sistemaStore";
import useMenuHandler from "./sistemaHooks/useMenuHandler";

export default function Principal() {
  const [showLateralMenu, setShowLateralMenu] = useState();
  const {
    RenderedComponent,
    componentPath,
    desktop,
    modulos,
    perfil,
    setComponentPath,
    currentMenu,
    currentModulo,
    matricula,
  } = useMenuHandler({ setShowLateralMenu });

  const usuario = (
    <div className="usuario">
      <button
        onClick={() => setShowLateralMenu(!showLateralMenu)}
        className="avatar"
      >
        {perfil?.nome[0]}
      </button>
      {(showLateralMenu || !desktop) && (
        <>
          <div className="identificacao">
            <TextContent style={{ textAlign: "left" }} className="nome">
              {perfil?.nome}
            </TextContent>
            <TextContent style={{ textAlign: "left" }} className="cargo">
              {matricula?.cargo}
            </TextContent>
          </div>
          <Perfil desktop={desktop} className="editarPerfil" />
        </>
      )}
    </div>
  );

  return (
    <Container desktop={desktop}>
      {desktop && (
        <LeftMenu showLateralMenu={showLateralMenu}>
          {usuario}
          {showLateralMenu && (
            <div className="menuPrincipal">Menu Principal</div>
          )}
          <MenuLista
            tipo={matricula?.tipo}
            {...{
              modulos,
              setComponentPath,
              currentModulo,
              currentMenu,
              showLateralMenu,
              setShowLateralMenu,
            }}
          />
        </LeftMenu>
      )}
      <Content desktop={desktop}>
        <div className="topMenu">
          {!desktop && (
            <>
              {usuario}
              {showLateralMenu && (
                <Modal
                  checkRedux={false}
                  outClick={() => setShowLateralMenu(!showLateralMenu)}
                >
                  <div className="modulos">
                    <MenuLista
                      tipo={matricula?.tipo}
                      {...{
                        modulos,
                        setComponentPath,
                        currentModulo,
                        currentMenu,
                        showLateralMenu,
                        setShowLateralMenu,
                      }}
                    />
                  </div>
                </Modal>
              )}
            </>
          )}
          {currentMenu?.path ? (
            <p className="brackpoints">
              {currentModulo?.nome}
              <strong>
                <span className="material-icons">navigate_next</span>{" "}
                {currentMenu?.nome}
              </strong>
            </p>
          ) : (
            <strong>{currentModulo?.nome}</strong>
          )}
          <img className="logo" src={Logo} alt="Logo" />
          <div className="topMenu_div_botoes">
            <Acessos {...{ matricula }} />
            {/* <Notificacoes /> */}
            {/* <Logout /> */}
            <LeaveSystem />
          </div>
        </div>
        <Main autoHide autoHideTimeout={1000} autoHideDuration={500}>
          <main className="mainDiv">
            {RenderedComponent && (
              <sistemaStore.Provider
                value={{
                  ...componentPath,
                  setComponentPath: ({ moduloPath, menuPath, props }) => {
                    setComponentPath({ moduloPath, menuPath, props });
                  },
                }}
              >
                <RenderedComponent />
              </sistemaStore.Provider>
            )}
            {/* <acessos.filial.GestaoMatriculas.Matriculas /> */}
          </main>
        </Main>
      </Content>
    </Container>
  );
}
