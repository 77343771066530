import styled, { keyframes } from "styled-components";
import CarrocelRoleta from "~/componentes/CarrocelRoleta";

const animateLeft = keyframes`
    to{
      opacity: 1;
      transform: translateX(0em)
    }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0;
  transform: translateX(-1em);
  animation: ${animateLeft} 0.5s ease forwards;

  .conteudo {
    margin-top: 2em;
    margin-left: 2.5em;
    display: flex;
    flex-direction: ${(props) => (props.desktopAndRotatedTablet ? "row" : "column")};
    justify-content: space-between;
    align-items: center;
  }
`;

export const Carrocel = styled(CarrocelRoleta)`
  width: 24em;
  max-width: 90%;
  height: 20em;
  position: relative;

  img {
    border-radius: 0.5em;
    width: 12em;
    height: auto;
    position: absolute;
    box-shadow: 0 0 0.5em 0.1em #6666666b;
    cursor: pointer;
  }

  .carrocelRoletaContent0,
  .carrocelRoletaContent2 {
    top: 1em;
    filter: brightness(60%);
  }

  .carrocelRoletaContent2 {
    right: 0;
  }

  .carrocelRoletaContent1 {
    z-index: 1;
    top: 0;
    width: 13.4em;
    left: 0;
    right: 0;
    margin: auto;
  }
`;
