import React from "react";

import { useState } from "react";
import { useEffect } from "react";
import api from "~/services/api";
import CustomList, {
  CLTableHeader,
  CLTableBody,
  CLTableCell,
  CLShowComponent,
  CLEditComponent,
  CLCreateComponent,
} from "~/pages/Sistema/componentes/CustomList";
import ShowContent from "./ShowContent";
import CreateEditContent from "./CreateEditContent";

export default function Turmas() {
  const [modulos, setModulos] = useState([]);
  const [mountedData, setMountedData] = useState();

  useEffect(() => {
    async function handleRequests() {
      api.get("admin/acessos").then((response) => setModulos(response.data));
    }

    handleRequests();
  }, []);

  return (
    <CustomList
      requests={{
        findAll: () => api.get("admin/cargos"),
        deleteOne: (cargo) => api.delete("admin/cargo", { data: { cod: cargo.cod } }),
        findOne: (cargo) => api.get("admin/cargo", { params: { cod: cargo.cod } }),
      }}
    >
      <CLTableHeader>
        <th>NOME</th>
      </CLTableHeader>

      <CLTableBody>
        <CLTableCell search={"Cargo"} name="nome" />
      </CLTableBody>

      <CLShowComponent title="Visualizar Cargo" contentMountUnMount={setMountedData}>
        {mountedData && modulos && <ShowContent {...{ mountedData, modulos }} />}
      </CLShowComponent>

      <CLEditComponent title="Atualizar Cargo" contentMountUnMount={setMountedData}>
        {mountedData && modulos && <CreateEditContent {...{ mountedData, modulos }} />}
      </CLEditComponent>

      <CLCreateComponent title="Novo Cargo">
        {modulos && <CreateEditContent {...{ modulos }} />}
      </CLCreateComponent>
    </CustomList>
  );
}
