import React, { useState, useEffect } from "react";

import api from "~/services/api";
import CreateEditContent from "./CreateEditContent";
import ShowContent from "./ShowContent";

import { Container } from "./styles";
import TableComponent from "./TableComponent";
import {
  SLCreateModal,
  SLEditModal,
  SLShowModal,
} from "~/pages/Sistema/componentes/SistemaList";

export default function Planos() {
  const [modulos, setModulos] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [mountedData, setMountedData] = useState();
  const [tabFilter, setTabFilter] = useState({ status: 1 });
  const [condicoesPagamentos, setCondicoesPagamentos] = useState([]);

  useEffect(() => {
    api
      .get("admin/modulos", {
        params: { arrayTipo: ["empresa", "filial", "professor"] },
      })
      .then((response) => response?.data && setModulos(response.data));
    api
      .get("admin/empresas")
      .then((response) => response?.data && setEmpresas(response.data));
    api
      .get("admin/condicoespagamentos")
      .then((r) => setCondicoesPagamentos(r?.data || []));
  }, []);

  return (
    <Container
      requests={{
        findAll: () => api.get("admin/planos"),
        deleteOne: (thisData) =>
          api.delete("admin/plano", { data: { cod: thisData.cod } }),
        findOne: (thisData) =>
          api.get("admin/plano", { params: { cod: thisData.cod } }),
      }}
      dataFilter={tabFilter}
    >
      <TableComponent {...{ setTabFilter, empresas }} />

      <SLShowModal title="Plano" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLEditModal title="Atualizar Plano" setter={setMountedData}>
        <CreateEditContent {...{ mountedData, modulos }} />
      </SLEditModal>

      <SLCreateModal title="Novo Plano">
        <CreateEditContent {...{ modulos, empresas, condicoesPagamentos }} />
      </SLCreateModal>
    </Container>
  );
}
