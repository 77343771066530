import React, { useLayoutEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { 
  Container, 
  ModalCentral, 
  VideoChatContainer, 
  RemoteVideoChat, 
  LocalVideoChat, 
  RemoteVideo, 
  RemoteVideoPrimary, 
  ContainerVideos, 
  TeacherVideoContainer,
  DescriptionContainer,
  Description,
  Actions,
  Avatar,
  Name,
  VideoCamPaused,
  PlaceholderVideoCam,
  DescriptionRemoteVideo,
  DescriptionContainerLocal
} from "./styles";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import Label from "~/componentes/CustomForm/Label";
import { EduquemeInput } from "~/componentes/CustomForm/Input/styles";

import { useEffect } from "react";
import { StreamContext } from "./context";
import { useContext } from "react";
//https://websocket.eduqueme.com.br/

export default function VideoconferenciaComponente() {

  const {
    camIsReady, 
    roomIsReady, 
    videoIsPaused, 
    audioIsPaused, 
    remoteVideoChatRef, 
    remoteVideoPrimaryRef, 
    setCodigoSala, 
    setVideoIsPaused, 
    setAudioIsPaused,
    localVideoChatRef,
    onJoin,
    toggleMute,
    unpublishOwnFeed,
    publishOwnFeed,
    toggleCam
  } = useContext(StreamContext);

  
  const videoLocalRef = useRef(null);

  return (
    <ModalCentral>
      {!roomIsReady ? (
        <Container onSubmit={({codigo_sala}) => {
          setCodigoSala(codigo_sala);
          localStorage.setItem('eduqueMe:roomCode', codigo_sala);
          let user = localStorage.getItem('persist:eduqueMe');
          user = JSON.parse(user);
          onJoin();
        }}>
          <h1>Digite o código da sala!</h1>
  
          <Label className="col s12 m12 l12" title="Código">
            <EduquemeInput type="text" name="codigo_sala" id="codigo_sala" required />
          </Label>
  
          <div className="botao">
            <ColoredButton type="submit" greenLayout>
              Entrar
            </ColoredButton>
          </div>
        </Container>
      ) : (
        <VideoChatContainer>
          <RemoteVideoPrimary ref={remoteVideoPrimaryRef}>
            
          </RemoteVideoPrimary>
          <ContainerVideos>
            <RemoteVideoChat ref={remoteVideoChatRef}>
            </RemoteVideoChat>
            <LocalVideoChat ref={localVideoChatRef} id="localVideoChat">
              <div className="panel-body" id="videolocal" style={{width: '100%', height: '100%'}}>
                
              </div>
              <DescriptionContainerLocal id="description">
                <Actions>
                  {audioIsPaused ? (
                    <i onClick={() => {
                      setAudioIsPaused(false);
                      toggleMute();
                    }} className="material-icons">mic</i>
                  ) : (
                    <i onClick={() => {
                      setAudioIsPaused(true);
                      toggleMute();
                    }} className="material-icons">mic_off</i>
                  )}
                  <i onClick={() => {
                    window.location.reload();
                  }} className="material-icons" id='disconnectIcon'>call_end</i>
                  {videoIsPaused ? (
                    <i onClick={() => {
                      setVideoIsPaused(false);
                      toggleCam(true);
                    }} className="material-icons">videocam</i>
                  ) : (
                    <i onClick={() => {
                      setVideoIsPaused(true);
                      toggleCam(true, false);
                    }} className="material-icons">videocam_off</i>
                  )}
                  
                </Actions>
              </DescriptionContainerLocal>
            </LocalVideoChat>
          </ContainerVideos>
          
        </VideoChatContainer>
      )}
    </ModalCentral>
  );
}
