import React from "react";

import { Container } from "./styles";
import Label from "~/componentes/CustomForm/Label";
import CustomScrollBar from "~/componentes/CustomScrollBar";

function ShowContent({ mountedData }) {
  return (
    <Container>
      <CustomScrollBar fixScrollMargin autoHeightMax="28em">
        <Label title="Título" text="Chamado número 1" />
        <Label title="E-mail de cópia" text="emailcopia@gmail.com" />
        <Label title="Descrição" text>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
          Ipsum has been the industry's standard dummy text ever since the 1500s, when an
          unknown printer took a galley of type and scrambled it to make a type specimen
          book. a type specimen book. a type specimen book. Lorem Ipsum is simply dummy
          text of the printing and typesetting industry. Lorem Ipsum has been the
          industry's standard dummy text ever since the 1500s, when an unknown printer
          took a galley of type and scrambled it to make a type specimen book."
        </Label>
        <Label tile="Tipo de Solicitação" text="Suporte" />
      </CustomScrollBar>
    </Container>
  );
}

export default ShowContent;
