import styled from "styled-components";

export const Container = styled.div`
  grid-area: ranking;
  background: #ffffff;
  box-shadow: 0 0.313em 0.938em rgba(0, 0, 0, 0.1);
  border-radius: 0.375em;
  height: auto;
  padding-bottom: 0.5em;

  .ranking-header {
    border-bottom: solid #f5f5f5 0.125em;
    margin: 0 1em;
    padding: 0.5em 0;

    h5 {
      font-style: normal;
      font-weight: 600;
      font-size: 1.175em;
      line-height: 1.625em;
      color: #293f57;
    }
  }

  ul {
    padding-left: 2em;
    padding-right: 1.75em;
    list-style: none;
    position: relative;

    li:first-child {
      margin-top: 0.5em;
    }

    li {
      width: 100%;
      height: 3.4em;
      margin-bottom: 0.5em;
      margin-top: 0.5em;
      position: relative;
      border-bottom: solid #f5f5f5 0.125em;
      .td-header {
        h4 {
          font-size: 1em;
          font-weight: 600;
          color: #293f57;
          margin: 0.2em 0;
        }
      }
      .td-body {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .title-disciplina h6 {
          font-size: 0.8em;
          color: #4fa754;
        }
        .title-data {
          display: flex;
          align-items: center;
          color: #1ca9d0;
          h6 {
            margin: 0.2em 0;
            font-size: 0.8em;
          }
          span {
            margin-left: 0.4em;
          }
        }
      }
    }
  }
`;
