import React from "react";
import Aplicativo from "./Aplicativo";
import astro from "~/assets/imagens_grandes/astro3.png";

import { Container, Content, CircleButton } from "./styles";
import UserDevice from "~/componentes/UserDevice";
import { useState } from "react";
import Planos from "./Planos";
// import Beneficios from "./Beneficios";
// import Blog from "./Blog";
import devicesWidth from "~/config/devicesWidth";

export default function Amostra() {
  const [component, setComponent] = useState(0);
  const [allowChange, setAllowChange] = useState(true);
  const userDevice = UserDevice(devicesWidth);

  const { desktop, screenHeight, screenWidth } = userDevice;
  const desktopAndRotatedTablet = desktop && screenWidth / screenHeight > 1.4;
  const toggleAstro = screenWidth > 1000 && screenWidth > screenHeight;

  function renderComponent() {
    if (component === 1) {
      return <Planos desktop={desktop} />;
    }
    // else if (component === 2) {
    //   return <Beneficios desktopAndRotatedTablet={desktopAndRotatedTablet} />;
    // } else if (component === 3) {
    //   return <Blog desktopAndRotatedTablet={desktopAndRotatedTablet} />;
    // }
    else {
      return <Aplicativo desktopAndRotatedTablet={desktopAndRotatedTablet} />;
    }
  }

  React.useEffect(() => {
    if (allowChange) {
      const timeOut = setTimeout(() => setComponent(component === 0 ? 1 : 0), 4000);

      return () => clearTimeout(timeOut);
    }
  }, [component, allowChange]);

  return (
    <Container>
      {toggleAstro && (
        <img className="astronauta" src={astro} alt="atronauta preguiçoso" />
      )}
      <Content
        onMouseEnter={() => setAllowChange(false)}
        onMouseLeave={() => setAllowChange(true)}
        desktopAndRotatedTablet={desktopAndRotatedTablet}
        toggleAstro={toggleAstro}
        userDevice={userDevice}
      >
        {renderComponent()}
        <div className="botoes">
          <CircleButton
            color="#FFDA44"
            selected={component === 0}
            onClick={() => setComponent(0)}
          />
          <CircleButton
            color="#1CA9D0"
            selected={component === 1}
            onClick={() => setComponent(1)}
          />
          {/* <CircleButton color="#FF4031" selected={beneficios} onClick={() => setComponent([false, false, true, false])} /> */}
          {/* <CircleButton
            color="#4EA654"
            selected={blog}
            onClick={() => setComponent([false, false, false, true])}
          /> */}
        </div>
      </Content>
    </Container>
  );
}
