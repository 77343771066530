import styled from "styled-components";

export const Container = styled.div`
  max-width: 100%;

  .card-style {
    background: #fff;
    box-shadow: 0 0.2em 0.2em -0.08em rgba(0, 0, 0, 0.2), 0 0.2em 0.15em 0 rgba(0, 0, 0, 0.14), 0 0.08em 0.5em 0 rgba(0, 0, 0, 0.12);
    border-radius: 0.375em;
    padding: 0.5em;
    transition: 500ms;
    cursor: pointer;

    :hover {
      box-shadow: 0 0.2em 0.2em -0.08em rgba(0, 0, 0, 0.2), 0 0.2em 0.5em 0 rgba(0, 0, 0, 0.14), 0 0.05em 1em 0 rgba(0, 0, 0, 0.12);
      transform: translateY(-0.4em);
    }
  }
`;
