import styled from "styled-components";
import Check from "~/componentes/CustomForm/Check";
import CheckWrapper from "~/componentes/CustomForm/CheckWrapper";

export const Container = styled.div`
  width: 100%;
  max-width: 100%;

  .AcessosList_title_p {
    margin-bottom: -0.25em;
    max-width: 100%;
    font-weight: 600;
    font-size: 0.8em;
    line-height: 2.8em;
    text-transform: uppercase;
  }

  ${(props) => props.minimizable && `position: relative;`}

  .AcessosList_Button_minimizable {
    position: absolute;
    top: 0.75em;
    right: 0.75em;
    border: none;
    background: inherit;

    i.green {
      color: #4fa754;
    }

    i.red {
      color: #ff4031;
    }
  }
`;

export const AcessosContent = styled(CheckWrapper)`
  background: #ffffff;
  box-shadow: 0 0.063em 0.625em rgba(0, 0, 0, 0.13);
  border-radius: 0.25em;
  margin: 0.75em 0.25em 0.75em 0.25em;
`;

export const BoxContainer = styled.div`
  margin: 0 0.25em 0.75em 0.25em;
  border-bottom: solid #f5f5f5 0.125em;
`;

export const ModuloDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5em;
  cursor: pointer;

  .Acessos_Box_selecionados_icon_div {
    display: flex;

    i,
    p {
      align-self: center;
      height: fit-content;
    }

    p {
      margin-right: 0.5em;
    }

    i.green {
      color: #4fa754;
    }

    i.red {
      color: #ff4031;
    }
  }
`;

export const CheckBoxLabel = styled.label`
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;

  h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.938em;
    line-height: 1em;
    color: #203346ad;
  }
`;

export const MenusDiv = styled.div`
  list-style: none;
  padding: 0 0 0.5em 1.625em;

  .menu {
    margin: 0.75em 0;
  }
`;

export const CheckBox = styled(Check)`
  margin: 0 0.75em 0 0;
  appearance: none;
  width: 1.25em;
  height: 1.25em;
  background: #d0d7db;
  border-radius: 0.125em;
  transition: 0.3s ease;
  outline: none;
  border: none;
  box-shadow: none;
  position: relative;

  :hover {
    box-shadow: none;
  }

  :checked {
    background: #1ca9d0;
  }
  :checked ~ h6 {
    color: #293f57;
  }

  ::before {
    position: absolute;
    content: "";
    width: 0.875em;
    height: 0.125em;
    background: #fff;
    -webkit-transform: rotate(-50deg);
    -ms-transform: rotate(-50deg);
    transform: rotate(-50deg);
    top: 0.563em;
    left: 0.313em;
    transition: 0.3s ease;
    opacity: 0;
  }
  ::after {
    position: absolute;
    content: "";
    width: 0.375em;
    height: 0.125em;
    background: #fff;
    -webkit-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    transform: rotate(50deg);
    top: 0.689em;
    left: 0.188em;
    transition: 0.3s ease;
    opacity: 0;
  }

  :checked::before,
  :checked::after {
    opacity: 1;
  }

  :disabled {
    background: #dedede;
    color: #ffffff;
    border: none;
  }
`;
