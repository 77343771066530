import React from "react";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTableCell } from "~/pages/Sistema/componentes/SistemaList";
import { ThisTable } from "./styles";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";

function TableComponent({ setTabFilter }) {
  return (
    <>
      <TabsWrapper tabHeight={2.4}>
        <Tab materialIcon="check_circle_outline" onClick={() => setTabFilter({ status: 1 })}>
          Ativo
        </Tab>
        <Tab materialIcon="highlight_off" onClick={() => setTabFilter({ status: 0 })}>
          Desativo
        </Tab>
      </TabsWrapper>

      <SistemaComponenteInterno>
        <ThisTable noEdit={true}>
          <SLTableCell name="reuniaoAluno.aluno.usuario.nome" inputfilter>
            Aluno
          </SLTableCell>
          <SLTableCell name="reuniaoAluno.nomeResponsavel" inputfilter>
            Responsável
          </SLTableCell>
          <SLTableCell isDateOnly name="dataInicio" inputfilter>
            Dt Inicio
          </SLTableCell>
          <SLTableCell name="horaInicio" inputfilter>
            Hr Inicio
          </SLTableCell>
          <SLTableCell isDateOnly name="dataFinal" inputfilter>
            Dt Final
          </SLTableCell>
          <SLTableCell name="horaFinal" inputfilter>
            Hr Final
          </SLTableCell>
        </ThisTable>
      </SistemaComponenteInterno>
    </>
  );
}

export default TableComponent;
