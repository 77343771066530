import React, { useState } from "react";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import api from "~/services/api";
import Label from "~/componentes/CustomForm/Label";

import { Container } from "./styles";
import AcessosList from "~/componentes/AcessosList";
import { useEffect } from "react";
import { Materialize } from "~/styles/materializeGrid";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import { SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";

function CreateEditContent({
  mountedData,
  modulos,
  empresas,
  condicoesPagamentos,
}) {
  const [handledModulos, setHandledModulos] = useState({});
  const [handledDefaultModulos, setHandledDefaultModulos] = useState({});

  useEffect(() => {
    if (modulos) {
      let thisModulos = {};

      function handleModulo(data) {
        const thisTipo = data?.tipo;
        if (thisTipo) {
          Array.isArray(thisModulos?.[thisTipo])
            ? thisModulos[thisTipo].push(data)
            : (thisModulos[thisTipo] = [data]);
        }
      }

      modulos.forEach((e) => handleModulo(e));
      setHandledModulos(thisModulos);
    }
  }, [modulos]);

  useEffect(() => {
    if (Array.isArray(mountedData?.menus) && mountedData?.menus?.length > 0) {
      let thisDefaultModulos = {};

      function handleModulo(data) {
        const thisTipo = data?.modulo?.tipo;
        const thisCod = data?.cod;
        if (thisTipo && thisCod) {
          Array.isArray(thisDefaultModulos?.[thisTipo])
            ? thisDefaultModulos[thisTipo].push(thisCod)
            : (thisDefaultModulos[thisTipo] = [thisCod]);
        }
      }

      mountedData.menus.forEach((e) => handleModulo(e));
      setHandledDefaultModulos(thisDefaultModulos);
    }
  }, [mountedData]);

  function handleSubmit(data, options) {
    const { closeReload } = options || {};
    const { acessos, ...restData } = data || {};

    let handledAcessos = [];
    Object.values(acessos).map((e) => handledAcessos.push(...e));

    return ShowConfirmacao(
      async () => {
        let response;

        if (mountedData) {
          response = await api.put("admin/plano", {
            ...restData,
            acessos: handledAcessos,
            cod: mountedData.cod,
          });
        } else {
          response = await api.post("admin/plano", {
            ...restData,
            acessos: handledAcessos,
          });
        }

        if (response?.status === 200) {
          closeReload && closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao ${
            mountedData ? "atualizar" : "criar"
          } esse plano, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja ${
          mountedData ? "atualizar" : "criar"
        } esse Plano?`,
        successModal: `Plano ${
          mountedData ? "atualizado" : "cadastrado"
        } com sucesso!`,
      }
    );
  }

  return (
    <Container onSubmit={handleSubmit}>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          <div className="row">
            <Label title="NOME:" className="col s12 m12 l9">
              <SistemaInput name="nome" defaultValue={mountedData?.nome} />
            </Label>
            {!mountedData && (
              <Label title="EMPRESA:" className="col s12 m12 l3">
                <SistemaSelect
                  name="empresaCod"
                  list={{ list: empresas, value: "cod", label: "razaoSocial" }}
                  defaultValue={mountedData?.empresaCod}
                  required
                  firstOption
                />
              </Label>
            )}
            {!mountedData && (
              <Label title="CONDIÇÃO DE PAGAMENTO:" className="col s12 m12 l6">
                <SistemaSelect
                  name="condicaoPagamentoCod"
                  list={{
                    list: condicoesPagamentos,
                    value: "cod",
                    label: "nome",
                  }}
                  defaultValue={mountedData?.condicaoPagamentoCod}
                  required
                  firstOption
                />
              </Label>
            )}
            <Label title="QUANTIDADE DE ALUNOS:" className="col s12 m12 l3">
              <SistemaInput
                name="quantidadeAlunos"
                type="number"
                isNumber
                defaultValue={mountedData?.quantidadeAlunos}
              />
            </Label>
            <Label title="VALOR TOTAL:" className="col s12 m12 l3">
              <SistemaInput
                name="valor"
                type="number"
                step="0.01"
                isNumber
                defaultValue={mountedData?.valor}
              />
            </Label>
            <Label title="DATA DE INICIO:" className="col s12 m12 l4">
              <SistemaInput
                name="inicio"
                type="date"
                defaultValue={mountedData?.inicio}
              />
            </Label>
            <Label title="DATA DE TERMINO:" className="col s12 m12 l4">
              <SistemaInput
                name="termino"
                type="date"
                defaultValue={mountedData?.termino}
              />
            </Label>
            <Label title="ATIVO:" className="col s12 m12 l4">
              <SistemaSelect
                name="ativo"
                list={{
                  list: [
                    { cod: "true", nome: "Sim" },
                    { cod: "true", nome: "Não" },
                  ],
                  value: "cod",
                  label: "nome",
                }}
                defaultValue={
                  mountedData?.ativo !== undefined
                    ? mountedData?.ativo.toString()
                    : "true"
                }
                required
              />
            </Label>
            {Object.entries(handledModulos)?.map(([key, value]) => (
              <AcessosList
                className="col s12 m12 l12"
                key={key}
                modulos={value}
                title={`Acessos ${key}`}
                minimizable
                defaultSelect={handledDefaultModulos?.[key]}
                name={`acessos.${key}`}
              />
            ))}
          </div>
        </Materialize>
      </CustomScrollBar>
      <SLModalFooter />
    </Container>
  );
}

export default CreateEditContent;
