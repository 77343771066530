import React, { useState } from "react";

import { Container, MaterializeContent } from "./styles";
import ThisTable from "./ThisTable";
import Pagination from "~/componentes/Pagination";
import ThisPdfButton from "./ThisPdfButton";
import api from "~/services/api";
import { useLayoutEffect } from "react";
import { useSelector } from "react-redux";

function Boletim() {
  const { perfil } = useSelector((state) => state.user) || {};
  const [dados, setDados] = React.useState();
  const [filtro, setFiltro] = React.useState();
  // setDados(dados.usuario = perfil)

  useLayoutEffect(() => {
    (async () => {
      await api
        .get("aluno/turma/alunos", { params: { cod:perfil.cod} })
        .then((response) => setDados(response?.data || []));
    })();
    }, []);
    console.log({dados, perfil})
  
  return (
    <Container Page={{orientation: 'landscape'}}>
      <div className="table-content">
        <div className="table-action-buttons">
          <MaterializeContent>
            <div className="row">
              <div className="col s12 m12 l12 actions-paginator">
                <h3>DOWNLOAD DE BOLETIM ESCOLAR</h3>
                <ThisPdfButton avaliacoes={dados} data={dados} perfil={perfil}/>
              </div>
            </div>
          </MaterializeContent>
        </div>
      </div>
    </Container>
  );
}

export default Boletim;
