import React, { useState, useLayoutEffect } from "react";

import api from "~/services/api";
import CreateEditContent from "./CreateEditContent";
import ShowContent from "./ShowContent";
import {
  SLCreateModal,
  SLShowModal,
  SLEditModal,
  SLEditButton,
  SLShowButton,
  SLDeleteButton,
  SLRestoreButton,
} from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import EducaBitsImg from "~/assets/icones/educa_bits.png";

function LojaVirtual() {
  const [mountedData, setMountedData] = useState();
  const [data, setData] = useState([]);
  const [categoriaLojaVirtual, setCategoriaLojaVirtual] = useState([]);

  useLayoutEffect(() => {
    api
      .get("admin/categoriaslojavirtual")
      .then((response) => response?.data && setCategoriaLojaVirtual(response.data));
  }, []);

  return (
    <Container
      defaultStyle
      pageSize={9}
      inputs={[{ filter: "nome", label: "Nome" }]}
      selects={[
        {
          filter: "categoriaLojaVirtualCod",
          list: categoriaLojaVirtual,
          outLabel: "Categoria",
          value: "cod",
          label: "nome",
        },
      ]}
      requests={{
        findAll: () => api.get("admin/lojasvirtual", { params: { paranoid: false } }),
        deleteOne: (thisData) =>
          api.delete("admin/lojavirtual", { data: { cod: thisData.cod } }),
        restoreOne: (thisData) =>
          api.post("admin/lojavirtual/restaura", { data: { cod: thisData.cod } }),
        findOne: (thisData) =>
          api.get("admin/lojavirtual", { params: { cod: thisData.cod } }),
      }}
      setter={setData}
    >
      <div className="pai-lojavirtual-card">
        {data.map((e) => (
          <div className="lojavirtual-card">
            <div
              className={
                e?.status === 1 ? "lojavirtual-card-box" : "lojavirtual-card-box desativo"
              }
            >
              <div className="lojavirtual-card-head">
                <p className="title-card">{e?.nome}</p>
                <p className="sub-title-card">{e?.categoria?.nome}</p>
              </div>
              <div className="lojavirtual-card-body">
                {e?.arquivo?.url && <img src={e.arquivo.url} alt="imagem" />}
              </div>
              <div className="lojavirtual-card-footer">
                <div className="card-estrelas">
                  <p>
                    <img src={EducaBitsImg} alt="imagem" />
                    {e?.valor}
                  </p>
                </div>
                <div className="card-action">
                  {e?.status === 1 && <SLShowButton data={e} />}
                  {e?.status === 1 && <SLEditButton data={e} />}
                  {e?.status === 1 && <SLDeleteButton data={e} />}
                  {e?.status === 0 && <SLRestoreButton data={e} />}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <SLShowModal title="Produto da Loja Virtual" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLEditModal title="Atualizar produto da Loja Virtual" setter={setMountedData}>
        <CreateEditContent {...{ mountedData, categoriaLojaVirtual }} />
      </SLEditModal>

      <SLCreateModal title="Novo produto da Loja Virtual">
        <CreateEditContent {...{ categoriaLojaVirtual }} />
      </SLCreateModal>
    </Container>
  );
}

export default LojaVirtual;
