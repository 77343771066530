import React, { useState, useEffect } from "react";
import { Container } from "./styles";
import api from "~/services/api";
import SistemaTopico from "~/componentes/StyledComponents/Textos/SistemaTopico";
import UserDevice from "~/componentes/UserDevice";
import CreateModal from "./CreateModal";
import ThisTableComponent from "./ThisTableComponent";
import { useCallback } from "react";
import ThisTab from "./ThisTab";
import ThisSelectFilter from "./ThisSelectFilter";

/**
 * @param {Object} props
 * @param {Array} props.disciplinasTurma Recebe um array contendo as disciplinas de uma turma.
 * @param {Array} props.defaultData Lista de disciplinas da turma que iniciará como ativada cada disciplina deve conter disciplinaFilialCod, professorCod e professor.usuario.nome. Essa estrutura é o padrão do banco.
 * @param {Array} props.disciplinas Lista de disciplinas da filial
 * @param {Array} props.professores Lista de professores
 * @param {Function} props.setter Função que recebe como argumento um array contendo em cada item o disciplinaFialiCod e o professorCod
 * @param {Number} props.size Quantidade de cards por página.
 * @param {Number} props.rowSize Padrão 4 (máximo). Quantidade de cards por linha.
 * @param {Object} props.startTab Informa a tab inicial.
 * @param {Object} props.startTab.selecionadas Inicia na tab "selecionadas".
 * @param {Object} props.startTab.turma Inicia na tab "turma", válido apenas caso seja passado essa filtragem.
 */
function TurmasList({ alunoCod, setter, reloadTableData }) {
  const [periodoLetivoCod, setPeriodoLetivoCod] = useState([]);
  const [plMatriculados, setPlMatriculados] = useState([]);
  const [plDesativos, setPlDesativos] = useState([]);
  const [plNovos, setPlNovos] = useState([]);
  const [periodosLetivos, setPeriodosLetivos] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const { mobile } = UserDevice();
  const [tab, setTab] = useState(0);

  const reloadMatriculas = useCallback(async () => {
    if (alunoCod) {
      await api
        .get("filial/aluno/periodosLetivos", {
          params: { alunoCod, alunoTumaAtivo: true },
        })
        .then((r) => setPlMatriculados(r?.data || []));

      await api
        .get("filial/aluno/periodosLetivos", {
          params: { alunoCod, alunoTumaAtivo: false },
        })
        .then((r) => setPlDesativos(r?.data || []));
    }
  }, [alunoCod]);

  useEffect(() => {
    let setterData = [];

    if (Array.isArray(plNovos)) {
      plNovos.forEach((pl) => pl?.turmas?.forEach((t) => setterData.push(t)));
    }
    setter && setter(setterData);
  }, [plNovos, setter]);

  useEffect(() => {
    async function fetchData() {
      await api
        .get("filial/periodosLetivos", {
          params: {
            disciplinasTurmas: true,
            ativo: true,
            vagasDisponiveis: true,
            notAlunoCod: alunoCod,
          },
        })
        .then((r) => setPeriodosLetivos(r?.data || []));

      await reloadMatriculas();
    }

    fetchData();
  }, [alunoCod, reloadMatriculas]);

  return (
    <Container mobile={mobile}>
      <SistemaTopico>MATRICULAS</SistemaTopico>
      <div className="TurmasList_div_header">
        <ThisSelectFilter
          {...{ plMatriculados, plNovos, plDesativos, setPeriodoLetivoCod }}
        />
        <CreateModal
          {...{
            setTab,
            mobile,
            setPlNovos,
            periodosLetivos,
            plNovos,
          }}
        />
      </div>

      <ThisTab
        {...{
          tab,
          setTab,
          plMatriculados,
          plDesativos,
          plNovos,
          periodoLetivoCod,
          setFilteredData,
        }}
      />
      <ThisTableComponent
        {...{ tab, reloadMatriculas, reloadTableData, setPlNovos, filteredData }}
      />
    </Container>
  );
}

export default TurmasList;
