/* eslint-disable eqeqeq */
import React from "react";

import { Container } from "./styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import DadosMatricula from "~/componentes/DadosMatricula";
import api from "~/services/api";

function ShowContent({ mountedData }) {
  const [grupoDados, setGrupoDados] = React.useState();

  React.useEffect(() => {
    const {
      filialCod,
      serie: { ensinoCod },
    } = mountedData || {};

    api
      .get("padrao/matriculas/AcompanharMatriculas/dadosMatriculaEnsino", {
        params: { filialCod, ensinoCod },
      })
      .then((r) => setGrupoDados(r?.data));
  }, [mountedData]);

  return (
    <Container>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <DadosMatricula
          readOnly
          grupos={grupoDados}
          defaultValue={mountedData?.dados}
        />
      </CustomScrollBar>
    </Container>
  );
}

export default ShowContent;
