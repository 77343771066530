import styled from "styled-components";

export const Container = styled.div.attrs(({ setType = "div", ...rest }) => ({
  as: setType,
  ...rest,
}))`
  line-height: 2;
  display: block;
  margin-bottom: 0.5em;
  width: 100%;
  max-width: 100%;

  ${(props) =>
    !props.noBorder &&
    props.text &&
    `
    box-sizing: border-box;
    border-radius: 0.25em;`}

  .Label_title_p {
    text-align: left;
    margin-bottom: -0.25em;
    max-width: 100%;
    font-weight: 600;
    font-size: 0.8em;
    line-height: 2.8em;
    text-transform: uppercase;
  }

  ${(props) =>
    !props.noInput &&
    `select, textArea,
      input {
      width: 100%;
      max-width: 100%;
    }`}

  .Label_text {
    text-align: left;
    font-weight: 500;
    text-transform: none;
    height: 2.4em;
    padding: 0em 0.5em;
    color: #1e2b3a;
    border: 0.1em solid #e4e4e4;
    background: #ededed;
    border-radius: 0.375em;
    font-size: 1em;
    vertical-align: middle;

    ${({ eduquemeLayout }) => {
      if (eduquemeLayout) {
        return `
          color: #fff;
          border-color:  #1f3346;
          background-color: #256172;
        `;
      }
    }};
  }
`;
