import React from "react";

import SistemaTable from "~/componentes/StyledComponents/Tables/SistemaTable";
import handleDate from "~/utils/handleDate";
import { Container } from "./styles";

function ThisTable({ data, usuario }) {
  return (
    <Container>
      <SistemaTable>
        <thead>
          <tr>
            <th>Disciplina</th>
            <th>Nota</th>
            <th>Entregue</th>
            <th>Corrigido</th>
            <th>Professor</th>
            <th>Turma</th>
            <th>Série</th>
            <th>Período Letivo</th>
            <th>Data Início</th>
            <th>Data Fim</th>
          </tr>
        </thead>
        <tbody>
          {data?.map?.((e, i) => (
            <tr key={e?.cod || i}>
              <td>{e?.disciplinaFilial?.nome}</td>
              <td>{e?.corrigido ? e?.nota || 0 : " - "}</td>
              <td>{e?.entregue ? "Sim" : "Não"}</td>
              <td>{e?.corrigido ? "Sim" : "Não"}</td>
              <td>{e?.professor?.usuario?.nome}</td>
              <td>{e?.turma?.nome}</td>
              <td>{e?.serie?.nome}</td>
              <td>{e?.periodoLetivo?.nome}</td>
              <td>{handleDate(e?.dataInicio).dmy}</td>
              <td>{handleDate(e?.dataFim).dmy}</td>
            </tr>
          ))}
        </tbody>
      </SistemaTable>
    </Container>
  );
}

export default ThisTable;
