import React from "react";
import { Container, QuestaoCard } from "./styles";
import TextContent from "~/componentes/TextContent";
import {
  SLEditButton,
  SLDeleteButton,
  SLShowButton,
} from "~/pages/Sistema/componentes/SistemaList";
import { useSelector } from "react-redux";

function CustomBodyContent({ pageData }) {
  const {
    user: { matricula },
  } = useSelector((state) => state);

  return (
    <Container>
      {pageData?.map((thisData, i) => {
        return (
          <QuestaoCard nivel={thisData?.nivel?.nome} key={thisData?.cod || i}>
            <p className="Questoes_Card_cod">
              #{thisData?.cod} <span>{thisData?.disciplinaFilial?.nome}</span>
              <p className="Questoes_Card_nomeProfessor">
                {thisData?.professor?.usuario?.nome}
              </p>
            </p>
            <p className="Questoes_Card_titulo">{thisData?.nome}</p>
            <TextContent
              relativeHeight={false}
              className="Questoes_Card_enunciado"
              lines={4}
            >
              {thisData?.enunciado}
            </TextContent>
            <div className="Questoes_Card_footer">
              <p className="Questoes_Card_footer_dificuldade">{thisData?.nivel?.nome}</p>
              <div className="Questoes_Card_footer_buttons">
                {thisData?.status === 1 && <SLShowButton data={thisData} />}
                {thisData?.status === 1 &&
                  matricula?.matriculaCod === thisData?.professorCod && (
                    <SLEditButton data={thisData} />
                  )}
                {thisData?.status === 1 && thisData?.avaliacoesQuestao?.length === 0 && (
                  <SLDeleteButton data={thisData} />
                )}
              </div>
            </div>
          </QuestaoCard>
        );
      })}
    </Container>
  );
}

export default CustomBodyContent;
