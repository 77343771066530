import React from "react";
import { Container, QuestaoCard } from "./styles";
import TextContent from "~/componentes/TextContent";
import { SLCaptarButton } from "~/pages/Sistema/componentes/SistemaList";

function CustomBodyContent({ pageData }) {
  return (
    <Container>
      {pageData?.map((thisData, i) => {
        return (
          <QuestaoCard nivel={thisData?.nivel?.nome} key={thisData?.cod || i}>
            <div className="Questoes_head">
              <p className="Questoes_Card_cod">
                #{thisData?.cod} <span>{thisData?.disciplinaFilial?.nome}</span>
              </p>
              <p className="Questoes_Card_subTittle">{thisData?.filial?.nomeFantasia}</p>
            </div>
            <p className="Questoes_Card_titulo">{thisData?.nome}</p>
            <TextContent relativeHeight={false} className="Questoes_Card_enunciado" lines={4}>
              {thisData?.enunciado}
            </TextContent>
            <div className="Questoes_Card_footer">
              <p className="Questoes_Card_footer_dificuldade">{thisData?.nivel?.nome}</p>
              <div className="Questoes_Card_footer_buttons">
                <SLCaptarButton data={thisData} />
              </div>
            </div>
          </QuestaoCard>
        );
      })}
    </Container>
  );
}

export default CustomBodyContent;
