import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { Container } from "./styles";
import { Materialize } from "~/styles/materializeGrid";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import SistemaTable from "~/componentes/StyledComponents/Tables/SistemaTable";
import IconButton from "~/componentes/CustomForm/Button/Styles/IconButton";
import EntregueModal from "./EntregueModal";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import api from "~/services/api";

function ShowContent({ reloadData, mountedData }) {
  const [entregueModal, setEntregueModal] = React.useState(false);
  const { avaliacao, disciplinaTurma } = mountedData || {};
  const { turma, disciplinaFilial } = disciplinaTurma || {};

  function handleDate(date) {
    const thisDate = date && new Date(date);
    return thisDate
      ? `${thisDate.toLocaleDateString()} às ${thisDate.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}`
      : "Não definido";
  }

  function handleNaoEntregue(avu) {
    ShowConfirmacao(
      () =>
        api
          .put("professor/avaliacaoDisponibilizada", {
            cod: avu?.cod,
            corrigido: true,
          })
          .then((r) => {
            if (r?.data?.cod) {
              avu.corrigido = r.data.corrigido;
            }
            typeof reloadData === "function" && reloadData();
            return r;
          }),
      {
        message:
          "O aluno não entregou a avaliação dentro do prazo, deseja preencher como corrígido?",
        successModal: false,
      }
    );
  }

  return (
    <Container>
      {entregueModal && (
        <EntregueModal
          reloadData={reloadData}
          mountedData={mountedData}
          avaliacaoAluno={entregueModal}
          outClick={() => setEntregueModal(false)}
        />
      )}
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          <div className="row">
            <Label
              title="NOME:"
              text={avaliacao?.nome || ""}
              className="col s12 m9 l9"
            />
            <Label
              title="Nota max.:"
              text={avaliacao?.notaMaxima || 0}
              className="col s12 m3 l3"
            />
            <Label
              title="Disciplina:"
              text={disciplinaFilial?.nome || ""}
              className="col s12 m12 l4"
            />
            <Label
              title="Turma:"
              text={turma?.nome || 0}
              className="col s12 m12 l4"
            />
            <Label
              title="Série:"
              text={turma?.serie?.nome || 0}
              className="col s12 m12 l4"
            />
            <Label
              title="Descrição :"
              text={avaliacao?.descricao || 0}
              className="col s12 m12 l12"
            />
          </div>
        </Materialize>
        <Label title="Avaliações dos alunos" />
        <SistemaTable>
          <thead>
            <tr>
              <th>Aluno</th>
              <th>Entregue</th>
              <th>Corrigido</th>
              <th>Início</th>
              <th>Término</th>
              <th>-</th>
            </tr>
          </thead>
          <tbody>
            {mountedData?.avaliacoesTurmasAlunos?.map((avu, i) => (
              <tr key={avu?.cod || i}>
                <td>
                  {avu?.alunoDisciplinaTurma?.alunoTurma?.aluno?.usuario?.nome}
                </td>
                <td>{avu?.entregue ? "Sim" : "Não"}</td>
                <td>{avu?.corrigido ? "Sim" : "Não"}</td>
                <td>{handleDate(avu?.dataInicio)}</td>
                <td>{handleDate(avu?.dataFim)}</td>
                <td>
                  {avu?.entregue ? (
                    <IconButton
                      onClick={() => setEntregueModal(avu)}
                      materialIcon="create"
                    />
                  ) : (
                    !avu?.corrigido &&
                    mountedData?.expirado &&
                    avu?.expirado && (
                      <IconButton
                        onClick={() => handleNaoEntregue(avu)}
                        materialIcon="event_busy"
                      />
                    )
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </SistemaTable>
      </CustomScrollBar>
    </Container>
  );
}

export default ShowContent;
