import React, { useState } from "react";

import api from "~/services/api";
import CreateEditContent from "./CreateEditContent";
import ShowContent from "./ShowContent";
import { SLCreateModal, SLShowModal, SLEditModal } from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import TableComponent from "./TableComponent";
import { useEffect } from "react";
import ShowConfirmacao from "~/utils/ShowConfirmacao";

function ConfigPag() {
  const [mountedData, setMountedData] = useState();

  return (
    <Container
      requests={{
        findAll: () => api.get("/filial/empresa/gettoken", { params: { paranoid: false } })
      }}
    >
      <TableComponent />

      <SLShowModal title="Token Mercado Pago" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLEditModal title="Atualizar Token" setter={setMountedData}>
        <CreateEditContent {...{ mountedData }} />
      </SLEditModal>
    </Container>
  );
}

export default ConfigPag;
