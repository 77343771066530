import React, { useState, useEffect } from "react";

import { TurmaCard, CheckCustom, ListTurmas } from "./styles";
import Pagination from "~/componentes/Pagination";
import api from "~/services/api";
import { IsNumber } from "~/utils/CheckDataType";
import TurmasFilter from "./TurmasFilter";
import TextContent from "~/componentes/TextContent";

/**
 * @param {Object} props
 * @param {Object} props.turmas Caso seja informado uma lista de questões, o componente não mais fará o request ao banco.
 * @param {Object} props.startSelected Inicia com o filtro de selecionados ativado.
 * @param {Function} props.setQuestaoContent Recebe uma função de setState no qual Recebe o conteúdo para gerar um Crud de questão.
 * @param {Object} props.showOnly Habilita apenas o visualizar do Crud e ative o noCheckBox.
 * @param {Array} props.turmasAtivas Recebe um array de códigos de questões para utilizar como valor inicial do Check.
 * @param {Number} props.disciplinaFilialCod Caso seja fornecido um código de uma disciplinaFilial, ele será usado como filtro.
 * @param {Object} props.size Padrão 7 caso create seja true, e 8 caso contrário. Quantidade de cards que serão geradas.
 * @param {string} props.name Padrão "turmas". Nome da chave que será enviada pro Form.
 */
function TurmasList(props) {
  const {
    showOnly,
    startSelected,
    turmasAtivas: turmasAtivasProp,
    disciplinaFilialCod,
    size,
    name = "turmas",
  } = props || {};
  const [turmasAtivas, setTurmasAtivas] = useState([]);
  const [turmasFiltradas, setTurmasFiltradas] = useState([]);
  const [pageData, setPageData] = useState([]);
  const [turmas, setTurmas] = useState([]);

  useEffect(() => {
    Array.isArray(turmasAtivasProp) && setTurmasAtivas(turmasAtivasProp);
  }, [turmasAtivasProp]);

  useEffect(() => {
    api
      .get("professor/turmas", { params: { disciplinaFilialCod, ativoTurmas: true } })
      .then((response) => {
        setTurmas(response?.data);
      });
  }, [disciplinaFilialCod]);

  return (
    <div>
      <TurmasFilter
        {...{
          turmas,
          turmasAtivas,
          showOnly,
          startSelected,
          setTurmasFiltradas,
        }}
      />
      <ListTurmas
        {...(showOnly
          ? { as: "div" }
          : {
              name: name,
              isArray: true,
              keepEmptyArray: true,
              setter: (data) => data && setTurmasAtivas(data),
            })}
      >
        {pageData?.map((thisData, i) => {
          const selected = turmasAtivas.includes(thisData?.cod);

          return (
            <TurmaCard key={thisData?.cod || i} selected={selected}>
              <div className="Turma_Card_check">
                {!showOnly && (
                  <CheckCustom assistChecked={!!selected} value={thisData?.cod} />
                )}
              </div>
              <div className="Turma_Card_title">
                <span>#{thisData?.cod} </span>
                <span>{thisData?.turma?.nome}</span>
                <TextContent
                  lines={2}
                  relativeHeight={false}
                  className="Turma_Card_nomeProfessor"
                >
                  {thisData?.turma?.serie.nome}
                </TextContent>
              </div>
            </TurmaCard>
          );
        })}
      </ListTurmas>
      <Pagination
        data={turmasFiltradas}
        size={IsNumber(size) ? size : showOnly ? 8 : 7}
        getInfo={({ pageData }) => setPageData(pageData)}
      />
    </div>
  );
}

export default TurmasList;
