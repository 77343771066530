import React from "react";
import customListStore, { customListToggleModal } from "../../../customListStore";
import CustomListModal from "../../CustomListModal";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";

function CustomListCreateContent({ filters, ...rest }) {
  const { dispatch } = React.useContext(customListStore);

  function handleClick() {
    dispatch(customListToggleModal(<CustomListModal bodyComponent={{ create: true }} />));
  }

  return (
    <div {...rest}>
      <ColoredButton
        small
        blueLayout
        className="CustomTable_TableFiltro_buttons_div_button"
        onClick={handleClick}
      >
        <i className="material-icons CustomTable_TableFiltro_buttons_div_button_icon">
          add_circle
        </i>
        NOVO
      </ColoredButton>
    </div>
  );
}

export default React.memo(CustomListCreateContent);
