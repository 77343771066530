import styled from "styled-components";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";

export const Container = styled(SistemaFixedModal)`
  width: 100%;

  .col {
    height: 5em;
  }

  .Label_inputWithButton {
    display: flex;
    align-items: center;

    button {
      padding: 0.36em 1em;
      margin-bottom: 0.3em;
      margin-left: 0.25em;
    }
  }

  .Matriculas_FormContent_Label_disabledInput:disabled {
    background-color: #256172;
  }
`;
