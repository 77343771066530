import setRegex from "~/utils/setRegex";

export default function maskValue(value, name) {
  if (name === "cpf") {
    return setRegex.setCpf(value);
  } else if (name === "cep") {
    return setRegex.setCep(value);
  } else if (name === "cnpj") {
    return setRegex.setCnpj(value);
  } else if (name === "telefone1" || name === "telefone2") {
    return setRegex.setPhone(value);
  } else {
    return value;
  }
}
