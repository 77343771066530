import React, { useState, useEffect } from "react";

import api from "~/services/api";
import ShowContent from "./ShowContent";
import CreateEditContent from "./CreateEditContent";
import SistemaList, {
  SLCreateModal,
  SLEditModal,
  SLShowModal,
  SLTable,
  SLTableCell,
} from "~/pages/Sistema/componentes/SistemaList";

export default function Turmas() {
  const [cargosEmpresa, setCargosEmpresa] = useState([]);
  const [modulos, setModulos] = useState([]);
  const [mountedData, setMountedData] = useState();

  useEffect(() => {
    api
      .get("filial/cargosEmpresa")
      .then((response) => setCargosEmpresa(response?.data));
    api.get("filial/acessos").then((response) => setModulos(response?.data));
  }, []);

  return (
    <SistemaList
      requests={{
        findAll: () => api.get("/filial/cargosFilial"),
        deleteOne: (thisData) =>
          api.delete("/filial/cargoFilial", { data: { cod: thisData.cod } }),
        findOne: (thisData) =>
          api.get("/filial/cargoFilial", { params: { cod: thisData.cod } }),
      }}
      defaultStyle
    >
      <SLTable>
        <SLTableCell inputfilter="Cargo" name="nome">
          NOME
        </SLTableCell>
      </SLTable>

      <SLShowModal setter={setMountedData} title="Cargo">
        {mountedData && modulos && (
          <ShowContent {...{ mountedData, modulos }} />
        )}
      </SLShowModal>

      <SLEditModal setter={setMountedData} title="Atualizar Cargo">
        {mountedData && modulos && (
          <CreateEditContent {...{ cargosEmpresa, modulos, mountedData }} />
        )}
      </SLEditModal>

      <SLCreateModal title="Novo Cargo">
        {modulos && <CreateEditContent {...{ cargosEmpresa, modulos }} />}
      </SLCreateModal>
    </SistemaList>
  );
}
