import React from "react";
import ThisModalButtonHandler from "../../../ThisMainComponent/ThisModalButtonHandler";
import { ThisSistemaListaIconButonStyle } from "../styles";

function ThisShowButton({ icon = "visibility", asDiv, children, className, ...rest }) {
  return asDiv ? (
    <ThisModalButtonHandler
      moldalType="show"
      setType="div"
      className={className}
      {...rest}
    >
      {children}
    </ThisModalButtonHandler>
  ) : (
    <ThisSistemaListaIconButonStyle
      moldalType="show"
      className={`material-icons ${className}`}
      {...rest}
    >
      {icon}
    </ThisSistemaListaIconButonStyle>
  );
}

export default ThisShowButton;
