import React from "react";
import { useState } from "react";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTableCell } from "~/pages/Sistema/componentes/SistemaList";
import { ThisTable } from "./styles";

function TableComponent({ setTabFilter, tabFilter }) {
  let tabFilterStatus = { status: tabFilter.baixaTitulo == "NB" ? 1 : 0  };

  return (
    <>
      <TabsWrapper tabHeight={2.4}>
        <Tab materialIcon="priority_high" onClick={() => setTabFilter({ baixaTitulo: "NB" })}>
          Pendentes
        </Tab>
        <Tab materialIcon="done" onClick={() => setTabFilter({ baixaTitulo: "ND" })}>
          Pagos
        </Tab>
      </TabsWrapper>

      <SistemaComponenteInterno>
        <ThisTable tabFilter={tabFilterStatus} noEdit>
          <SLTableCell name="codigo" className="truncate" inputfilter>
            Código
          </SLTableCell>
          <SLTableCell name="valor" className="truncate">
            Valor
          </SLTableCell>
          <SLTableCell name="vencimento" className="truncate" isDateOnly>
            Vencimento
          </SLTableCell>
          <SLTableCell name="emissao" className="truncate" isDateOnly>
            Emissão
          </SLTableCell>
        </ThisTable>
      </SistemaComponenteInterno>
    </>
  );
}

export default TableComponent;
