import React from "react";

import { useState } from "react";
import api from "~/services/api";
import CustomList, {
  CLCustomBody,
  CLShowComponent,
  CLEditComponent,
  CLCreateComponent,
} from "~/pages/Sistema/componentes/CustomList";
import CreateContent from "./CreateContent";
import ShowContent from "./ShowContent";
import EditContent from "./EditContent";
import CustomBodyContent from "./CustomBodyContent/index";

export default function Chamados() {
  const [mountedData, setMountedData] = useState();
  const [customBodyData, setCustomBodyData] = useState();

  return (
    <CustomList
      rows={5}
      requests={{
        findAll: () => api.get("filial/alunos"),
        deleteOne: aluno => api.delete("filial/aluno", { data: { cod: aluno.cod } }),
        findOne: aluno => api.get("filial/aluno", { params: { cod: aluno.cod } }),
      }}
    >
      <CLCustomBody finders={[{ search: "Código", key: "cod" }]} setter={setCustomBodyData}>
        {customBodyData && <CustomBodyContent customBodyData={customBodyData} />}
      </CLCustomBody>

      <CLShowComponent title="Chamado">
        <ShowContent {...{ mountedData }} />
      </CLShowComponent>

      <CLEditComponent title="Atualizar Aluno" contentMountUnMount={setMountedData}>
        <EditContent {...{ mountedData }} />
      </CLEditComponent>

      <CLCreateComponent title="Novo Chamado">
        <CreateContent />
      </CLCreateComponent>
    </CustomList>
  );
}
