import React from "react";

import { Container } from "./styles";
import Amostra from "./Amostra";
import Conceito from "./Conceito";
import Vantagens from "./Vantagens";
// import Matriculas from "./Matriculas";
import Beneficios from "./Beneficios";

export default function Inicio() {
  return (
    <Container>
      <Amostra />
      <Conceito />
      <Vantagens />
      <Beneficios />
      {/* <Matriculas /> */}
    </Container>
  );
}
