/* eslint-disable no-useless-computed-key */
import React, { useState, useLayoutEffect } from "react";

import api from "~/services/api";
import CreateEditContent from "./CreateEditContent";
import ShowContent from "./ShowContent";
import { SLCreateModal, SLShowModal, SLEditModal } from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import TableComponent from "./TableComponent";

function TabelaPrecoAluno() {
  const [mountedData, setMountedData] = useState();
  const [tabFilter, setTabFilter] = useState({ ["versaoApp.ativo"]: true });
  const [condicaoPagamento, setCondicaoPagamento] = useState([]);

  useLayoutEffect(() => {
    api.get("admin/condicoespagamentos").then((response) => response?.data && setCondicaoPagamento(response.data));
  }, []);

  return (
    <Container
      requests={{
        findAll: () => api.get("admin/tabelasprecoaluno", { params: { paranoid: false } }),
        deleteOne: (thisData) => api.delete("admin/tabelaprecoaluno", { data: { cod: thisData.cod } }),
        restoreOne: (thisData) => api.post("admin/tabelaprecoaluno/restaura", { data: { cod: thisData.cod } }),
        findOne: (thisData) => api.get("admin/tabelaprecoaluno", { params: { cod: thisData.cod } }),
      }}
      inputs={[{ filter: "nome", label: "Nome" }]}
      selects={[
        {
          filter: "condicaoPagamentoCod",
          list: condicaoPagamento,
          outLabel: "Condição de Pagamento",
          value: "cod",
          label: "nome",
        },
      ]}
      dataFilter={tabFilter}
    >
      <TableComponent {...{ setTabFilter }} />

      <SLShowModal title="Tabela de Preço" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLEditModal title="Atualizar tabela de preço" setter={setMountedData}>
        <CreateEditContent {...{ mountedData, condicaoPagamento }} />
      </SLEditModal>

      <SLCreateModal title="Nova tabela de preço">
        <CreateEditContent {...{ condicaoPagamento }} />
      </SLCreateModal>
    </Container>
  );
}

export default TabelaPrecoAluno;
