import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { Container } from "./styles";
import { Materialize } from "~/styles/materializeGrid";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import ColoredFileInput from "~/componentes/CustomForm/FileInput/styles/ColoredFileInput";
import CustomScrollBar from "~/componentes/CustomScrollBar";

function ShowContent({ mountedData }) {
  return (
    <Container>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          <div className="row">
            <Label
              title="Título:"
              text={mountedData?.titulo || ""}
              className="col s12 m12 l12"
            />
            <Label title="Mensagem:" className="col s12 m12 l12">
              <SistemaTextArea readOnly value={mountedData?.mensagem || ""} />
            </Label>
            <Label
              title="Disciplina:"
              text={mountedData?.disciplinaTurma?.disciplinaFilial?.nome || ""}
              className="col s12 m12 l6"
            />
            <Label
              title="Turma:"
              text={mountedData?.disciplinaTurma?.turma?.nome || ""}
              className="col s12 m12 l6"
            />
            {mountedData?.arquivos?.length > 0 && (
              <>
                <Label title="Arquivos" className="col s12 m12 12" />
                {mountedData?.arquivos?.map((arq, i) => (
                  <div
                    key={arq?.cod || i}
                    className="Label_arquivos_file_input col s12 m12 l6"
                  >
                    <ColoredFileInput readOnly defaultFile={arq} />
                  </div>
                ))}
              </>
            )}
          </div>
        </Materialize>
      </CustomScrollBar>
    </Container>
  );
}

export default ShowContent;
