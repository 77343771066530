import React from "react";

import { Container, Carrocel } from "./styles";
import inicioImage from "~/assets/mobile_ss/inicio.png";
import feedImage from "~/assets/mobile_ss/feed.png";
import atividades from "~/assets/mobile_ss/atividades.png";
import DesktopLayout from "./DesktopLayout";

export default function Aplicativo({ desktopAndRotatedTablet }) {
  const mobileImages = (
    <Carrocel>
      <img src={feedImage} alt="Feed" />
      <img src={inicioImage} alt="Inicio" />
      <img src={atividades} alt="Atividades" />
    </Carrocel>
  );

  return (
    <Container desktopAndRotatedTablet={desktopAndRotatedTablet}>
      <div className="conteudo">
        <DesktopLayout>{mobileImages}</DesktopLayout>
      </div>
    </Container>
  );
}
