import styled from "styled-components";

const triangle = (lateralSide = "left", horizontalSide = "bottom", color = "#000") => `
  ::before {
    content: "";
    position: absolute;
    ${lateralSide}: -0.9em;
    ${horizontalSide}: 0;
    width: 0;
    height: 0;
    border-${lateralSide}: 1em solid transparent;
    border-${horizontalSide}: 1em solid ${color};
  }
`;

export const Container = styled.div`
  margin-top: 7em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  align-items: center;
  width: 100vw;
  height: 75vh;
  margin-bottom: 7em;

  .foguete {
    position: absolute;
    height: calc(100% + 17em);
    width: auto;
    top: 3em;
    left: ${(props) => (props.rotatedTablet ? "-15em" : "-10em")};
    z-index: 0;
  }

  :before {
    content: "";
  }

  .content {
    width: ${(props) => (props.desktop || props.rotatedTablet ? "60%" : "90%")};
    ${(props) => !props.desktop && !props.rotatedTablet && "margin: auto;"}

    h1 {
      color: #fff;
      text-align: center;
      font-size: 1.5em;
      margin-bottom: 1em;
    }

    .contentBox {
      width: 100%;
      height: 75vh;
      border-radius: ${(props) =>
        props.desktop || props.rotatedTablet ? "3em 0 0 3em" : "2em"};
      background: #ffffff;
      box-shadow: 0 0 1em #fff;
      box-shadow: 0.375em 0.375em 1.25em rgba(134, 134, 134, 0.7);
      padding: ${(props) =>
        props.desktop || props.rotatedTablet ? "1em" : "1em 0.5em 1em 1em"};

      .scroll {
        padding-right: 0.25em;
        width: 100%;
        height: 100%;
        border-radius: ${(props) =>
          props.desktop || props.rotatedTablet ? "3em 0 0 3em" : "2em"};
        ${(props) => !props.desktop && !props.rotatedTablet && "margin: auto;"}

        .baloes {
          position: relative;
          color: #fff;
          padding: ${(props) =>
            props.desktop || props.rotatedTablet ? "1em 2em" : "1em"};
          width: fit-content;
          max-width: ${(props) => (props.desktop || props.rotatedTablet ? "60%" : "85%")};
        }

        .perguntaDiv {
          background: #1ca9d0;
          box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.62);
          border-radius: 2em 2em 2em 0;
          margin: ${(props) =>
            props.desktop || props.rotatedTablet
              ? "1em auto 1em 2em"
              : "1em auto 1em 1em"};
          ${triangle("left", "bottom", "#1ca9d0")}
        }

        .respostaDiv {
          background: #1f3346;
          box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.62);
          border-radius: 2em
            ${(props) => (props.desktop || props.rotatedTablet ? "2em 0" : "0 2em")} 2em;
          margin: ${(props) =>
            props.desktop || props.rotatedTablet
              ? "2em 4.5em 2em auto"
              : "3.5em 2em 2em auto"};
          position: relative;
          ${(props) =>
            (props.desktop || props.rotatedTablet) &&
            triangle("right", "bottom", "#1f3346")}

          .atendenteDiv {
            width: 3em;
            height: 3em;
            position: absolute;
            right: ${(props) =>
              props.desktop || props.rotatedTablet ? "-4em" : "-1.5em"};
            ${(props) => !props.desktop && !props.rotatedTablet && "top: -1.5em;"}
            border-radius: 50%;
            background: #1f3346;
            display: flex;
            border: 0.2em solid #fff;
            box-shadow: 0 0.1em 0.1em rgba(0, 0, 0, 0.25);

            img {
              display: block;
              width: 60%;
              height: auto;
              margin: auto;
            }
          }
        }
      }
    }
  }
`;
