import styled from "styled-components";
import { SLForm } from "~/pages/Sistema/componentes/SistemaList";

export const Container = styled(SLForm)`
  width: 80em;
  max-width: 100%;

  textarea {
    height: 16em;
  }
`;
