const INITIAL_STATE = {
  ativo: false,
  message: "Não foi possível realizar esta ação",
};

export default function errorModal(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case "@errorModal/errorToggle": {
      let handleError = payload.defaultMessage || "Não foi possível realizar esta ação";
      const thisError = payload?.error;
      const errorObject = thisError?.response?.data || thisError?.data;

      if (typeof thisError === "string") {
        handleError = thisError;
      } else if (errorObject) {
        if (Array.isArray(errorObject?.errors)) {
          for (const error of errorObject.errors) {
            if (error.errorHandled && error.message) {
              handleError = error.message;
              break;
            }
          }
        } else if (
          errorObject?.errorHandled &&
          (errorObject?.message || errorObject?.msg)
        ) {
          handleError = errorObject.message || errorObject?.msg;
        }
      }

      return {
        ativo: !state.ativo,
        message: handleError,
      };
    }
    default: {
      return state;
    }
  }
}
