import SistemaList from "./ThisMainComponent";

export { default as SLEditModal } from "./ThisMainComponent/ThisModals/ThisEditModal";
export { default as SLCreateModal } from "./ThisMainComponent/ThisModals/ThisCreateModal";
export { default as SLShowModal } from "./ThisMainComponent/ThisModals/ThisShowModal";
export { default as SLCaptarModal } from "./ThisMainComponent/ThisModals/ThisCaptarModal";
export { default as SLShareModal } from "./ThisMainComponent/ThisModals/ThisShareModal";
export { default as SLAprovarModal } from "./ThisMainComponent/ThisModals/ThisAprovarModal";
export { default as SLTable } from "./ThisStyledComponents/ThisTable";
export { default as SLTableCell } from "./ThisStyledComponents/ThisTable/ThisCell";
export { default as SLModalHeader } from "./ThisStyledComponents/ThisModalComponents/ThisModalHeader";
export { default as SLModalFooter } from "./ThisStyledComponents/ThisModalComponents/ThisModalFooter";
export { default as SLForm } from "./ThisStyledComponents/ThisModalComponents/ThisForm";
export { default as SLCloseButton } from "./ThisStyledComponents/ThisButtons/ThisCloseButton";
export { default as SLDeleteButton } from "./ThisStyledComponents/ThisButtons/ThisDeleteButton";
export { default as SLEditButton } from "./ThisStyledComponents/ThisButtons/ThisEditButton";
export { default as SLExcelPDFButton } from "./ThisStyledComponents/ThisButtons/ThisExcelPDFButton";
export { default as SLChangeStatusButton } from "./ThisStyledComponents/ThisButtons/ThisChangeStatusButton";
export { default as SLShowButton } from "./ThisStyledComponents/ThisButtons/ThisShowButton";
export { default as SLRestoreButton } from "./ThisStyledComponents/ThisButtons/ThisRestoreButton";
export { default as SLCaptarButton } from "./ThisStyledComponents/ThisButtons/ThisCaptarButton";
export { default as SLShareButton } from "./ThisStyledComponents/ThisButtons/ThisShareButton";
export { default as SLAprovarButton } from "./ThisStyledComponents/ThisButtons/ThisAprovarButton";

export default SistemaList;
