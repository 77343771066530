import styled from "styled-components";
import { SLForm } from "~/pages/Sistema/componentes/SistemaList";

export const Container = styled(SLForm)`
  width: 35em;
  max-width: 100%;

  .desativarPeriodoLetivo_div {
    border-top: solid 0.1em #f7f8fa;

    p {
      font-size: 0.9em;
      color: #444;
    }
  }
`;
