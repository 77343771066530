/* eslint-disable eqeqeq */
import React from "react";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";
import { Container } from "./styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import api from "~/services/api";
import { Materialize } from "~/styles/materializeGrid";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import SistemaTopico from "~/componentes/StyledComponents/Textos/SistemaTopico";
import ShowError from "~/utils/ShowError";
import ColoredFileInput from "~/componentes/CustomForm/FileInput/styles/ColoredFileInput";
import LoadingDots from "~/componentes/LoadingDots";

function CreateContent({ disciplinasFilial }) {
  const [disciplinaFilial, setDisciplinaFilial] = React.useState();
  const [disciplinaTurma, setDisciplinaTurma] = React.useState();
  const [alunoTurma, setAlunoTurma] = React.useState();

  function handleSubmit(data, options) {
    const { closeReload } = options || {};

    const alunosTurmasCod = [];

    if (alunoTurma?.cod) {
      alunosTurmasCod.push(alunoTurma.cod);
    } else if (disciplinaTurma?.cod) {
      if (Array.isArray(disciplinaTurma?.alunosTurmas)) {
        disciplinaTurma.alunosTurmas.forEach(
          (at) => at?.cod && alunosTurmasCod.push(at.cod)
        );
      }
    } else if (disciplinaFilial?.cod) {
      if (Array.isArray(disciplinaFilial?.disciplinasTurmas)) {
        disciplinaFilial.disciplinasTurmas.forEach((t) => {
          if (Array.isArray(t?.alunosTurmas)) {
            t.alunosTurmas.forEach((at) => at?.cod && alunosTurmasCod.push(at.cod));
          }
        });
      }
    } else if (Array.isArray(disciplinasFilial)) {
      disciplinasFilial.forEach((s) => {
        if (Array.isArray(s?.disciplinasTurmas)) {
          s.disciplinasTurmas.forEach((t) => {
            if (Array.isArray(t?.alunosTurmas)) {
              t.alunosTurmas.forEach((at) => at?.cod && alunosTurmasCod.push(at.cod));
            }
          });
        }
      });
    }

    if (alunosTurmasCod.length > 0) {
      if (data instanceof FormData) {
        data.set("alunosTurmasCod", JSON.stringify(alunosTurmasCod));
      }
      ShowConfirmacao(
        async () => {
          const response = await api.post("professor/comunicados/comunicado", data);

          if (response?.status === 200 && typeof closeReload === "function") {
            closeReload();
          }

          return response;
        },
        {
          successModal: "Comunicado enviado com sucesso",
          message: "Tem certeza que deseja enviar esse comunicado?",
        }
      );
    } else {
      ShowError("Não há disciplinas com turmas e/ou alunos ativos.");
    }
  }

  return (
    <Container isFormData defaultStyle onSubmit={handleSubmit}>
      <Materialize>
        <div className="row">
          <Label title="Título:" className="col s12 m12 l12">
            <SistemaInput name="titulo" />
          </Label>
          <Label title="Mensagem:" className="col s12 m12 l12">
            <SistemaTextArea name="mensagem" />
          </Label>

          <SistemaTopico>Destinatários</SistemaTopico>
          {Array.isArray(disciplinasFilial) ? (
            <>
              <Label title="Disciplinas" className="col s12 m12 l6">
                <SistemaSelect
                  list={{ list: disciplinasFilial, value: "cod", label: "nome" }}
                  firstOption="Todas"
                  value={disciplinaFilial?.cod || ""}
                  onChange={(e) => {
                    const thisVal = e.currentTarget.value;
                    const findThis = disciplinasFilial?.find((e) => e?.cod == thisVal);
                    setDisciplinaFilial(findThis);
                    setDisciplinaTurma();
                    setAlunoTurma();
                  }}
                />
              </Label>
              <Label title="Turma" className="col s12 m12 l6">
                <SistemaSelect
                  list={{
                    list: disciplinaFilial?.disciplinasTurmas,
                    value: "cod",
                    label: "turma.nome",
                  }}
                  firstOption="Todas"
                  value={disciplinaTurma?.cod || ""}
                  onChange={(e) => {
                    const thisVal = e.currentTarget.value;
                    const findThis = disciplinaFilial?.disciplinasTurmas?.find(
                      (e) => e?.cod == thisVal
                    );
                    setDisciplinaTurma(findThis);
                    setAlunoTurma();
                  }}
                />
              </Label>
              <Label title="Aluno" className="col s12 m12 l12">
                <SistemaSelect
                  list={{
                    list: disciplinaTurma?.alunosTurmas,
                    value: "cod",
                    label: "aluno.usuario.nome",
                  }}
                  firstOption="Todos"
                  value={alunoTurma?.cod || ""}
                  onChange={(e) => {
                    const thisVal = e.currentTarget.value;
                    const findThis = disciplinaTurma?.alunosTurmas?.find(
                      (e) => e?.cod == thisVal
                    );
                    setAlunoTurma(findThis);
                  }}
                />
              </Label>
              <SistemaTopico style={{ marginTop: "1em", marginBottom: "0.5em" }}>
                Arquivos
              </SistemaTopico>
              <div
                style={{ paddingRight: "1em", marginBottom: "1em" }}
                className="col s12 m12 l6"
              >
                <ColoredFileInput name="files" />
              </div>
              <div
                style={{ paddingLeft: "1em", marginBottom: "1em" }}
                className="col s12 m12 l6"
              >
                <ColoredFileInput name="files" />
              </div>
            </>
          ) : (
            <LoadingDots className="col s12 m12 l12">
              Carregando lista destinatários
            </LoadingDots>
          )}
        </div>
      </Materialize>
    </Container>
  );
}

export default CreateContent;
