import styled from "styled-components";

export const CheckBoxStyle = styled.label`
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  display: flex;
  width: fit-content;
  justify-items: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin: 0.25em;

  :hover::before {
    content: "";
    position: absolute;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s ease;
    background: ${(props) => (props.indeterminate ? "#1b9cc022" : "#1b9cc022")};
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    font-size: 1.5em;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    color: ${(props) => (props.indeterminate ? "#1b9cc0" : "#828d90")};
  }

  input:checked + span {
    ${(props) => !props.indeterminate && "color: #1b9cc0;"};
  }

  input:disabled + span {
    color: #cccccc;
  }
`;
