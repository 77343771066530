import React, { useState, useEffect } from "react";
import SistemaList, {
  SLCreateModal,
  SLEditModal,
  SLShowModal,
  SLTable,
  SLTableCell,
} from "~/pages/Sistema/componentes/SistemaList";

import api from "~/services/api";
// import CustomList, {
//   CLTableHeader,
//   CLTableBody,
//   CLTableCell,
//   CLShowComponent,
//   CLCreateComponent,
//   CLEditComponent,
// } from "~/pages/Sistema/componentes/CustomList";
import CreateEditContent from "./CreateEditContent";
import ShowContent from "./ShowContent";

export default function Disciplinas() {
  const [disciplinas, setDisciplinas] = useState([]);
  const [mountedData, setMountedData] = useState();

  useEffect(() => {
    api
      .get("global/disciplinas")
      .then((response) => response?.data && setDisciplinas(response.data));
  }, []);

  return (
    <SistemaList
      requests={{
        findAll: () => api.get("filial/disciplinasFilial"),
        deleteOne: (thisData) =>
          api.delete("filial/disciplinaFilial", { data: { cod: thisData.cod } }),
        findOne: (thisData) =>
          api.get("filial/disciplinaFilial", { params: { cod: thisData.cod } }),
      }}
      defaultStyle
    >
      <SLTable>
        <SLTableCell name="nome" inputfilter="Nome">
          DISCIPLINAS
        </SLTableCell>
      </SLTable>

      <SLShowModal title="Disciplina" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLEditModal title="Atualizar Disciplina" setter={setMountedData}>
        <CreateEditContent {...{ disciplinas, mountedData }} />
      </SLEditModal>

      <SLCreateModal title="Nova Disciplina">
        <CreateEditContent {...{ disciplinas }} />
      </SLCreateModal>
    </SistemaList>
  );
}
