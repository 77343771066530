import React from "react";
import Label from "~/componentes/CustomForm/Label";

// import { Container } from './styles';

function RespostaAudio({ handleChange, questao, respostaQuestoes, respostaQuestao }) {
  return (
    <Label className="col s12 m12 l12" title="Resposta:">
      <audio controls src={respostaQuestao?.audio?.url} />
    </Label>
  );
}

export default RespostaAudio;
