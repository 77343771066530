import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { Container } from "./styles";
import { Materialize } from "~/styles/materializeGrid";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import handleDate from "~/utils/handleDate";
import EntregueModal from "./EntregueModal";

function ShowContent({ mountedData, usuario, outClick }) {
  const [showQuestao, setShowQuestao] = React.useState(false);
  const {
    dataInicio,
    dataFim,
    corrigido,
    expirado,
    avaliacaoTurma,
    respostaQuestoes,
    filial,
  } = mountedData || {};
  const { avaliacao, disciplinaTurma } = avaliacaoTurma || {};
  const { disciplinaFilial, avaliacaoQuestao } = avaliacao || {};
  const { professor, turma } = disciplinaTurma || {};

  const nota = React.useMemo(() => {
    let thisNota = 0;
    if (!expirado) {
      return 0;
    }
    if (Array.isArray(respostaQuestoes)) {
      respostaQuestoes.forEach((e) => {
        if (e?.pontuacao > 0) {
          thisNota += e.pontuacao;
        }
      });
    }

    if (thisNota < 0) {
      return 0;
    } else if (thisNota > avaliacao?.notaMaxima) {
      return avaliacao?.notaMaxima;
    }

    return thisNota;
  }, [avaliacao.notaMaxima, expirado, respostaQuestoes]);

  return (
    <Container
      footerButtons={{
        submitButton: {
          type: "button",
          text: "Questoes",
          onClick: () => setShowQuestao(true),
        },
      }}
      title="Avaliação"
      outClick={outClick}
      scrollBar
    >
      {showQuestao && (
        <EntregueModal
          {...{
            avaliacaoQuestao,
            outClick: () => setShowQuestao(false),
            mountedData,
          }}
        />
      )}
      <Materialize>
        <div className="row">
          <Label
            title="Escola:"
            text={filial?.nomeFantasia || ""}
            className="col s12 m12 l6"
          />
          <Label
            title="Início:"
            text={handleDate(dataInicio)?.dmyhm || ""}
            className="col s12 m12 l3"
          />
          <Label
            title="Término:"
            text={handleDate(dataFim)?.dmyhm || ""}
            className="col s12 m12 l3"
          />
          <Label
            title="Professor:"
            text={professor?.usuario?.nome || ""}
            className="col s12 m12 l6"
          />
          <Label
            title="Turma:"
            text={turma?.nome || ""}
            className="col s12 m12 l3"
          />
          <Label
            title="Disciplina:"
            text={disciplinaFilial?.nome || ""}
            className="col s12 m12 l3"
          />
          <Label
            title="Avaliacao:"
            text={avaliacao?.nome || ""}
            className="col s12 m12 l12"
          />
          <Label title="Descrição:" className="col s12 m12 l12">
            <SistemaTextArea readOnly value={avaliacao?.descricao} />
          </Label>
          <Label title="Nota:" text={nota} className="col s12 m4 l4" />
          <Label
            title="Nota Máx.:"
            text={avaliacao?.notaMaxima || "0"}
            className="col s12 m4 l4"
          />
          <Label
            title="Corrigido"
            text={
              corrigido ? (expirado ? "Sim" : "Aguardando o término") : "Não"
            }
            className="col s12 m4 l4"
          />
        </div>
      </Materialize>
    </Container>
  );
}

export default ShowContent;
