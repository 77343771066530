import { Janus } from "./janus";

export default class JanusBuilder {

    janus = null;
    sfutest = null;
    opaqueId = "videoroomtest-"+Janus.randomString(12);

    myroom = 123455;
    myusername = null;
    myid = null;
    mystream = null;
    // We use this other ID just to map our subscriptions to us
    mypvtid = null;

    remoteFeed = null;
    feeds = {};
    feedStreams = {};
    subStreams = {};
    slots = {};
    mids = {};
    subscriptions = {};
    localTracks = {};
    localVideos = 0;
    remoteTracks = {};
    bitrateTimer = [];
    simulcastStarted = {};
    svcStarted = {};

    doSimulcast = (this.getQueryStringValue("simulcast") === "yes" || this.getQueryStringValue("simulcast") === "true");
    doSvc = this.getQueryStringValue("svc");
    /* if(doSvc === "")
        doSvc = null; */
    acodec = (this.getQueryStringValue("acodec") !== "" ? this.getQueryStringValue("acodec") : null);
    vcodec = (this.getQueryStringValue("vcodec") !== "" ? this.getQueryStringValue("vcodec") : null);
    subscriber_mode = (this.getQueryStringValue("subscriber-mode") === "yes" || this.getQueryStringValue("subscriber-mode") === "true");
    use_msid = (this.getQueryStringValue("msid") === "yes" || this.getQueryStringValue("msid") === "true");

    
    creatingSubscription = false;

    constructor() {

    }

    getQueryStringValue(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        let regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(window.location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }
}