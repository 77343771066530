import styled from "styled-components";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";

export const Container = styled(SistemaFixedModal)`
  /* position: absolute;
  width: 100%;
  height: 100%;
  margin: -1em -2em; */
  z-index: 2;

  /* .successFloat {
    position: sticky;
    background: #fff;
    border-radius: 0.5em;
    box-shadow: 0 0 1em #ccc, 0 0 0 100vmax #0006;
    top: calc(50% - 4em);
    left: calc(50% - 7.5em); */
  width: 20em;
  padding: 0.25em;

  .successContent {
    display: flex;
    border-bottom: 0.1em solid #f5f5f5;
    margin: 0.5em;
    padding: 0.5em;
  }

  .successIcon {
    min-width: 3em;
    width: 3em;
    min-height: 3em;
    height: 3em;
    border-radius: 50%;
    color: #9fb0af;
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1em;

    i {
      font-size: 2.5em;
      line-height: 1;
    }
  }

  .SuccessModal_p_message {
    display: flex;
    align-items: center;
  }
  /* } */
`;
