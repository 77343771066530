import React from "react";
import { useSelector } from "react-redux";
import formCheckDisabled from "../utils/formCheckDisabled";

/**
 * @param {FormData} FormData
 * @returns {FormData}
 */
// eslint-disable-next-line no-unused-vars
function formFileDoc(FormData) {}

/**
 * Esse input não possui ligação com o Form.
 * @param {Object} props
 * @param {Function} props.innerRef Função que recebe a ref como parâmetro.
 * @param {Function} props.previewFile Recebe uma função onde a utiliza passando como argumento um objeto do tipo file no formato de preview para html.
 * @param {formFileDoc} props.formFile Recebe uma função que será utiliza passando como argumento um objeto do tipo file no formato utilizado para form.
 * @param {FormData} props.formDataSetter FormData que será usado para adicionar o arquivo.
 * @param {FormData} props.preventCancelClick Mantém o arquivo anterior mesmo que o usuário aperte cancelar.
 */
export default function FileInput(props) {
  const {
    children,
    previewFile,
    preventCancelClick,
    formDataSetter,
    formFile,
    onChange,
    innerRef,
    ...rest
  } = props || {};
  const disableButtons = useSelector((state) => state.disableButtons);
  const ref = React.useRef();
  const [prevFiles, setPrevFiles] = React.useState([]);

  function setPreviewFile(file) {
    if (file) {
      let reader = new FileReader();

      reader.onloadend = function () {
        previewFile(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      previewFile("");
    }
  }

  function setFormFile(file) {
    if (formDataSetter) {
      formDataSetter((prev) => {
        const thisName = props?.name || "file";
        const newFD = new FormData();

        if (prev instanceof FormData) {
          prev.forEach((v, k) => {
            if (k !== thisName) {
              newFD.append(k, v);
            }
          });
        }

        newFD.append(thisName, file);

        return newFD;
      });
    }

    if (formFile) {
      const thisFormFile = new FormData();
      thisFormFile.append(props?.name || "file", file);

      formFile(thisFormFile);
    }
  }

  function handleFile(e) {
    const thisFiles = e.currentTarget.files;
    let thisFile = e.currentTarget.files[0];

    if (preventCancelClick && prevFiles.length > 0 && thisFiles.length < 1) {
      ref.current.files = prevFiles;
      thisFile = prevFiles[0];
    }

    previewFile && setPreviewFile(thisFile);

    setFormFile(thisFile);

    onChange && onChange(e);
  }

  return (
    <input
      {...rest}
      ref={(e) => {
        if (e) {
          ref.current = e;
        }
        innerRef && innerRef(e);
      }}
      onChange={handleFile}
      onClick={(e) => {
        const checkFiles = ref?.current?.files;
        if (checkFiles) {
          setPrevFiles(checkFiles);
        }
        props?.onClick && props.onClick(e);
      }}
      type="file"
      disabled={formCheckDisabled(props, disableButtons)}
    >
      {children}
    </input>
  );
}
