import React from "react";

import { Container, FirstSection, SecondeSection, ChartConatiner } from "./styles";
import { Bar, Line, Doughnut, HorizontalBar, Radar } from "react-chartjs-2";
import { Scrollbars } from "react-custom-scrollbars";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";

function DashBoard() {
  function generateColor() {
    let hexadecimais = "0123456789ABCDEF";
    let cor = "#";

    // Pega um número aleatório no array acima
    for (var i = 0; i < 6; i++) {
      //E concatena à variável cor
      cor += hexadecimais[Math.floor(Math.random() * 16)];
    }
    return cor;
  }
  const BarData = {
    labels: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun"],
    datasets: [
      {
        label: "Africa",
        backgroundColor: "#4FA754",
        data: [2000, 221, 783, 2478, 900, 1200],
      },
      {
        label: "Europe",
        backgroundColor: "#FF4031",
        data: [1000, 547, 675, 2034, 200, 800],
      },
    ],
  };
  const optionsData = {
    responsive: true,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
          },

          barThickness: 12, // number (pixels) or 'flex'
          maxBarThickness: 12, // number (pixels)
        },
      ],
    },
  };
  const LineData = {
    labels: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      80,
      50,
      60,
      70,
      80,
    ],
    datasets: [
      {
        data: [
          Math.random(),
          Math.random(),
          Math.random(),
          Math.random(),
          Math.random(),
          Math.random(),
          Math.random(),
          Math.random(),
          Math.random(),
          Math.random(),
          Math.random(),
          Math.random(),
        ],
        borderColor: "#1CA9D0",
        fill: false,
      },
    ],
  };
  const optionsLine = {
    responsive: true,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          display: false,
        },
      ],
      yAxes: [
        {
          display: false,
        },
      ],
    },
  };
  const pieData = {
    weight: 10,
    labels: [
      "Fortaleza",
      "Canindé",
      "Itatíra",
      "Quixadá",
      "Mombaça",
      "Pentecoste",
      "Apuiarés",
      "Boa Viagem",
      "Caucaia",
    ],
    datasets: [
      {
        label: "Population (millions)",
        backgroundColor: [
          generateColor(),
          generateColor(),
          generateColor(),
          generateColor(),
          generateColor(),
          generateColor(),
          generateColor(),
          generateColor(),
          generateColor(),
        ],
        data: [2478, 5267, 734, 600, 3000, 2120, 1600, 2350, 1440],
      },
    ],
  };
  const optionsPie = {
    responsive: true,
    legend: {
      display: false,
      align: "center",
    },
    title: {
      display: false,
      text: "",
    },
  };
  const horizontalData = {
    labels: ["Africa", "Asia", "Europe", "Latin America", "North America"],
    datasets: [
      {
        label: "Population (millions)",
        backgroundColor: ["#1CA9D0", "#FFDA44", "#4FA754", "#293F57", "#FF4031"],
        data: [80, 50, 55, 80, 100, 20],
      },
    ],
  };
  const horizontalOptions = {
    responsive: true,
    legend: { display: false },
    title: {
      display: false,
      text: "Predicted world population (millions) in 2050",
    },
    scales: {
      xAxes: [
        {
          display: false,
        },
      ],
      yAxes: [
        {
          display: false,
        },
      ],
    },
  };
  const radarData = {
    labels: ["Loja 1", "Loja 2", "Loja 3", "Loja 4", "Loja 5"],
    datasets: [
      {
        label: "1950",
        fill: true,
        backgroundColor: " rgba(255, 64, 49, 0.41)",
        borderColor: "#FF4031",
        pointBorderColor: "#fff",
        pointBackgroundColor: "#FF4031",
        data: [8.77, 55.61, 31.69, 6.62, 22.82],
      },
      {
        label: "2050",
        fill: true,
        backgroundColor: "rgba(28, 169, 208, 0.22)",
        borderColor: "#3e95cd",
        pointBorderColor: "#fff",
        pointBackgroundColor: "#3e95cd",
        data: [45.48, 54.16, 7.61, 8.06, 4.45],
      },
    ],
  };
  const radarOptions = {
    responsive: true,
    legend: { display: false },
    title: {
      display: true,
      text: "Distribuição em % por ano",
    },
  };

  return (
    <Container>
      <FirstSection>
        <div className="chart1 card-style">
          <div className="card-container">
            <div className="card-header">
              <h4>Título</h4>
            </div>
            <ChartConatiner width={100}>
              <Bar data={BarData} options={optionsData} />
            </ChartConatiner>
          </div>
        </div>
        <div className="chart2 card-style">
          <div className="card-container">
            <div className="one-line">
              <h5>$10,00</h5>
              <h6>Mês com mais gastos</h6>
            </div>
            <ChartConatiner width={70} marginLeft={2}>
              <Line data={LineData} options={optionsLine} />
            </ChartConatiner>
          </div>
        </div>
        <div className="chart3 card-style">
          <div className="card-container">
            <div className="one-line">
              <h5>$32,690</h5>
              <h6>Mês com mais gastos</h6>
            </div>
            <ChartConatiner width={70} marginLeft={-0.1}>
              <HorizontalBar data={horizontalData} options={horizontalOptions} />
            </ChartConatiner>
          </div>
        </div>
        <div className="chart4 card-style">
          <div className="card-container">
            <div className="one-line">
              <h5>Cidades</h5>
              <h6>Total de Vendas</h6>
            </div>
            <ChartConatiner width={65} marginLeft={3}>
              <Doughnut data={pieData} options={optionsPie} />
            </ChartConatiner>
          </div>
        </div>
        <div className="chart5 mini-card">
          <div className="card-flex">
            <i className="material-icons">person</i>
            <div>
              <h6>Total de Funcionários</h6>
              <h4>1.200,00</h4>
            </div>
          </div>
        </div>
        <div className="chart6 mini-card">
          <div className="card-flex">
            <i className="material-icons">attach_money</i>
            <div>
              <h6>Total de Gastos</h6>
              <h4>$32.000,00</h4>
            </div>
          </div>
          <span className="material-icons">trending_up</span>
        </div>
      </FirstSection>
      <SecondeSection>
        <div className="chart7 card-style">
          <div className="list-header">
            <h4>Clientes</h4>
          </div>
          <ul className="list-body">
            <Scrollbars style={{ overflowX: "hiddem" }}>
              <div className="list-container">
                <li>
                  <h3>Edson Dantas</h3>
                  <span className="material-icons">visibility</span>
                </li>
                <li>
                  <h3>Edson Dantas</h3>
                  <span className="material-icons">visibility</span>
                </li>
                <li>
                  <h3>Edson Dantas</h3>
                  <span className="material-icons">visibility</span>
                </li>
                <li>
                  <h3>Edson Dantas</h3>
                  <span className="material-icons">visibility</span>
                </li>
                <li>
                  <h3>Edson Dantas</h3>
                  <span className="material-icons">visibility</span>
                </li>
                <li>
                  <h3>Edson Dantas</h3>
                  <span className="material-icons">visibility</span>
                </li>
                <li>
                  <h3>Edson Dantas</h3>
                  <span className="material-icons">visibility</span>
                </li>
              </div>
            </Scrollbars>
          </ul>
          <div className="list-page">
            <div>
              <span className="material-icons">chevron_left</span>
              <h3>1</h3>
              <span className="material-icons">chevron_right</span>
            </div>
          </div>
        </div>
        <div className="chart8 card-style">
          <div className="card-container">
            <div className="card-field">
              <SistemaSelect
                list={{
                  list: [{ nome: "2019", cod: 1 }],
                  value: "cod",
                  label: "nome",
                }}
                firstOption="2020"
              />
            </div>
            <ChartConatiner width={100}>
              <Radar data={radarData} options={radarOptions} />
            </ChartConatiner>
          </div>
        </div>
      </SecondeSection>
    </Container>
  );
}

export default DashBoard;
