import styled from "styled-components";

export const Container = styled.div`
  width: 65em;
  max-width: 100%;
`;

export const SubTitle = styled.h4`
  color: #bbb5b5;
  line-height: 1.5em;
  font-weight: 600;
  font-size: 0.8em;
`;
