import React, { useState } from "react";

import api from "~/services/api";
import CreateEditContent from "./CreateEditContent";
import ShowContent from "./ShowContent";
import {
  SLCreateModal,
  SLShowModal,
  SLEditModal,
} from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import TableComponent from "./TableComponent";

function ConfiguracaoDesafio() {
  const [mountedData, setMountedData] = useState();
  const [tabFilter, setTabFilter] = useState({ ativo: 1 });

  return (
    <Container
      requests={{
        findAll: () => api.get("admin/configuracoesdesafio"),
        findOne: (thisData) =>
          api.get("admin/configuracaodesafio", { params: { cod: thisData.cod } }),
      }}
      dataFilter={tabFilter}
    >
      <TableComponent {...{ setTabFilter }} />

      <SLShowModal title="Configuração do Desafio" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLEditModal title="Atualizar Configurações do Desafio" setter={setMountedData}>
        <CreateEditContent {...{ mountedData }} />
      </SLEditModal>

      <SLCreateModal title="Nova Configuração do Desafio">
        <CreateEditContent />
      </SLCreateModal>
    </Container>
  );
}

export default ConfiguracaoDesafio;
