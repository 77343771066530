import React, { useState } from "react";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import Label from "~/componentes/CustomForm/Label";
import ShowError from "~/utils/ShowError";

// import { Container } from './styles';

function ThisModal({ setShowModal, setSeriesAtivas, seriesDisponiveis }) {
  const [serie, setSerie] = useState();
  const [quantidadeAlunos, setQuantidadeAlunos] = useState();

  function addSerie(closeModal) {
    const thisSerie =
      serie && serie !== "" && seriesDisponiveis?.find((e) => e?.cod === Number(serie));

    if (!thisSerie) {
      ShowError("Informe uma série");
    } else if (!(quantidadeAlunos && Number(quantidadeAlunos) > 0)) {
      ShowError("A quantidade de alunos deve ser no mínimo 1");
    } else {
      setSeriesAtivas((prev) => {
        const newPrev = [...prev];
        newPrev.push({ ...thisSerie, quantidadeAlunos });
        setSerie();
        return newPrev;
      });
      closeModal && closeModal();
    }
  }

  return (
    <SistemaFixedModal
      title="Adicionar Série"
      outClick={() => setShowModal(false)}
      footerButtons={{ submitButton: { type: "button", onClick: addSerie } }}
    >
      <Label title="SÉRIES">
        <SistemaSelect
          firstOption
          list={{ list: seriesDisponiveis, value: "cod", label: "nome" }}
          setter={setSerie}
        />
      </Label>
      <Label title="QUANTIDADE DE ALUNOS">
        <SistemaInput type="number" min="0" step="1" setter={setQuantidadeAlunos} />
      </Label>
    </SistemaFixedModal>
  );
}

export default ThisModal;
