import React from "react";

import { Container, ThisButtonStyle } from "./styles";
import { useState } from "react";
import { useEffect } from "react";
import UserDevice from "~/componentes/UserDevice";
import { useMemo } from "react";

export function Tab({ children, materialIcon, icon: Icon, ...rest }) {
  const { rotatedTablet, desktop } = UserDevice();

  const isMobile = useMemo(() => !rotatedTablet && !desktop, [desktop, rotatedTablet]);

  return (
    <ThisButtonStyle ismobile={isMobile ? "true" : undefined} {...rest}>
      {materialIcon && (
        <i className="material-icons TabsWrapper_Tab_DefaultTabIconStyle">
          {materialIcon}
        </i>
      )}
      {!materialIcon && Icon && <Icon className="TabsWrapper_Tab_DefaultTabIconStyle" />}
      {(!isMobile || (!materialIcon && !Icon)) && children}
    </ThisButtonStyle>
  );
}

/**
 * Os elementos informados no children não são utilizados, por completo, são obtidos apenas
 * os seus atributos e recriado utilizando um componente apropriado. Aceita todas as
 * propriedades passadas normalmente pra um componente.
 * @param {Object} props
 * @param {Boolean} props.revertColor
 * @param {Boolean} props.noFirstClick Se true, não realiza o onClick do children da tab inicial ao ser renderizado.
 * @param {Number} props.tab Encare cada children como um index de um array, logo 0 ativará o primeiro e 2 o terceiro. Funciona tal qual um value de um input, se informado, terá de ser alterado e forma manual.
 * @param {Number} props.defaultTab Encare cada children como um index de um array, logo 0 ativará o primeiro e 2 o terceiro. Funciona tal qual um value de um input, se informado, irá apenas atribuir qual tab irá iniciar ativa.
 * @param {Number} props.tabHeight O height dos childrens não devem ser alterado através
 *  do style, e sim por aqui. Aceita apenas números, no qual é utilizado o "em" na estilização.
 *  Logo, para alterar o tamanho de cada número, basta passar um font-size diferente.
 *  Utiliza por padrão {fontSize: 1}.
 */
function TabsWrapper({
  children,
  noFirstClick,
  initialClick,
  tabHeight = 2.1,
  tab,
  revertColor,
  defaultTab,
  ...rest
}) {
  const [ativo, setAtivo] = useState();
  const [loaded, setLoaded] = useState(false);
  const [firstClick, setFirstClick] = useState(false);

  useEffect(() => {
    if (tab || tab === 0) {
      setAtivo(tab);
    }
  }, [tab]);

  useEffect(() => {
    if (!loaded && !tab) {
      setLoaded(true);
      setAtivo(defaultTab || 0);
    }
  }, [tab, defaultTab, loaded]);

  useEffect(() => {
    if (!firstClick && !noFirstClick) {
      let clicked = false;
      React.Children.forEach(children, (child, i) => {
        if (ativo === i && !clicked && child?.props?.onClick) {
          setFirstClick(true);
          clicked = true;
          child.props.onClick();
        }
      });
    }
  }, [children, firstClick, noFirstClick, ativo]);

  return (
    <Container {...rest}>
      {React.Children.map(children, (child, i) => {
        const { onClick, children: childChildren, ...childRest } = child?.props || {};

        return (
          <Tab
            revertcolor={revertColor ? "true" : undefined}
            tabheight={tabHeight}
            onClick={(e) => {
              if (!tab && tab !== 0) {
                setAtivo(i);
              }
              onClick && onClick(e);
            }}
            ativo={ativo === i ? "true" : undefined}
            type="button"
            {...childRest}
          >
            {childChildren}
          </Tab>
        );
      })}
    </Container>
  );
}

export default TabsWrapper;
