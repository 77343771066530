import React, { useEffect, useState } from "react";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import Label from "~/componentes/CustomForm/Label";

// import { Container } from './styles';

function ThisSelectFilter({ plMatriculados, plNovos, plDesativos, setPeriodoLetivoCod }) {
  const [plSelectList, setPlSelectList] = useState([]);

  useEffect(() => {
    let newPlSelectList = plMatriculados || [];
    let checkNovos =
      plNovos?.filter((e) => !newPlSelectList?.find((t) => t?.cod === e?.cod)) || [];

    newPlSelectList = [...newPlSelectList, ...checkNovos];

    let checkDesativos =
      plDesativos?.filter((e) => !newPlSelectList?.find((t) => t?.cod === e?.cod)) || [];

    setPlSelectList([...newPlSelectList, ...checkDesativos]);
  }, [plMatriculados, plDesativos, plNovos]);

  return (
    <Label title="PERIODO LETIVO">
      <SistemaSelect
        className="TurmasList_div_header_input"
        // firstOption={!(plSelectList?.length < 0)}
        defaultValue={plSelectList?.[0]?.cod}
        list={{ list: plSelectList, value: "cod", label: "nome" }}
        setter={(pl) => setPeriodoLetivoCod(pl || "")}
      />
    </Label>
  );
}

export default ThisSelectFilter;
