import React from "react";

const FilterListStore = React.createContext({
  state: {},
  dispatch: () => {},
});

export function FilterListStoreLoadData(data) {
  return {
    type: "FilterListStoreLoadData",
    payload: { data },
  };
}

export function FilterListStoreUpdateSelects(selects) {
  return {
    type: "FilterListStoreUpdateSelects",
    payload: { selects },
  };
}

export function FilterListStoreHandleSelect({ key, value }) {
  return {
    type: "FilterListStoreHandleSelect",
    payload: { key, value },
  };
}

export function FilterListStoreHandleInput({ key, value }) {
  return {
    type: "FilterListStoreHandleInput",
    payload: { key, value },
  };
}

export default FilterListStore;
