import styled from "styled-components";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";

export const FiltroContainer = styled(SistemaComponenteInterno)`
  margin-bottom: 1em;
  display: flex;

  ${(props) =>
    props.mobile ? "flex-direction: column;" : "justify-content: space-between;"}
  align-items: center;

  .CustomTable_Filter_Search {
    display: flex;
    align-items: center;

    .CustomTable_Filter_Search_select {
      margin-right: 0.5em;
    }

    .CustomTable_Filter_Search_p {
      font-weight: 600;
      margin-right: 0.5em;
    }
  }

  .CustomTable_TableFiltro_buttons_div {
    display: flex;

    .CustomTable_TableFiltro_createButton {
      margin-left: 1em;
    }

    .CustomTable_TableFiltro_buttons_div_button {
      button {
        display: flex;
        align-items: center;
      }

      .CustomTable_TableFiltro_buttons_div_button_icon {
        padding-right: 0.3em;
      }
    }
  }
`;
