import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { Container } from "./styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";

function ShowContent({ mountedData }) {
  return (
    <Container>
      <CustomScrollBar fixScrollMargin autoHeightMax="80vh">
        <Label title="NOME:" text={mountedData?.nome || ""} />
        <Label title="ÍNICIO:" text={mountedData?.dataInicio || ""} />
        <Label title="TÉRMINO:" text={mountedData?.dataFim || ""} />
      </CustomScrollBar>
    </Container>
  );
}

export default ShowContent;
