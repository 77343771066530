import React from "react";
import { CSVLink } from "react-csv";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";

// import { Container } from './styles';

const headers = [
  { label: "Disciplina", key: "disciplinaFilial.nome" },
  { label: "Série", key: "serie.nome" },
  { label: "Turma", key: "turma.nome" },
  { label: "Corrigido", key: "corrigido" },
  { label: "Data início", key: "dataInicio" },
  { label: "Data término", key: "dataFim" },
];

function ThisExcelButton({ data }) {
  return (
    <ColoredButton materialIcon="arrow_downward" greenLayout>
      <CSVLink
        style={{ color: "#fff" }}
        headers={headers}
        data={data?.atividades || []}
        filename={`${data?.usuario?.nome}-${data?.usuario?.email}.csv`}
        separator={";"}
      >
        Excel
      </CSVLink>
    </ColoredButton>
  );
}

export default ThisExcelButton;
