import React, { useState, useEffect } from "react";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import { FlexDiv } from "~/componentes/StyledComponents/FlexDiv";
import Label from "~/componentes/CustomForm/Label";
import { CheckCustom, Filter } from "../styles";

function TurmasFilter({
  turmas,
  showOnly,
  turmasAtivas,
  startSelected,
  setTurmasFiltradas,
}) {
  const [selecionadas, setSelecionadas] = useState(startSelected);
  const [turmaFiltro, setTurmaFiltro] = useState({ key: "nome", value: "" });

  useEffect(() => {
    setTurmasFiltradas(() => {
      if (
        (showOnly || !selecionadas) &&
        (!turmaFiltro?.value || turmaFiltro?.value === "")
      ) {
        return turmas;
      } else {
        return turmas.filter((e) => {
          if (selecionadas && !turmasAtivas.find((q) => q === e.cod)) {
            return false;
          }

          return e?.turma?.[turmaFiltro?.key]
            ?.toString()
            ?.toLowerCase()
            ?.includes?.(turmaFiltro?.value ? turmaFiltro?.value?.toLowerCase() : "");
        });
      }
    });
  }, [turmaFiltro, selecionadas, turmas, setTurmasFiltradas, turmasAtivas, showOnly]);

  return (
    <Label
      style={{ padding: "0.6em" }}
      title={
        !showOnly
          ? `Selecione as Turmas: ${turmasAtivas?.length}/${turmas.length}`
          : "Turmas"
      }
    >
      <FlexDiv>
        <Filter>
          <SistemaSelect
            setter={(key) => setTurmaFiltro((prev) => ({ ...prev, key }))}
            list={{
              list: [
                { label: "Nome", value: "nome" },
                { label: "Código", value: "cod" },
              ],
              value: "value",
              label: "label",
            }}
            defaultValue={turmaFiltro?.key}
          />
          <SistemaInput
            setter={(value) => setTurmaFiltro((prev) => ({ ...prev, value }))}
            placeholder="Procurar Turma"
          />
        </Filter>
        {!showOnly && (
          <div style={{ width: "fit-content", display: "flex", alignItems: "center" }}>
            Selecionadas
            <CheckCustom
              defaultHtml
              checked={selecionadas}
              onChange={(e) => setSelecionadas(e.target.checked)}
            />
          </div>
        )}
      </FlexDiv>
    </Label>
  );
}

export default TurmasFilter;
