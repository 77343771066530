import React from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import CustomToast from "~/componentes/CustomToast";
import PeerRoomBuilder from "./scripts/PeerRoomBuilder"
import MediaStreamBuilder from "./scripts/MediaStreamBuilder"
import ReactDOM from "react-dom";
import { 
    Container, 
    ModalCentral, 
    VideoChatContainer, 
    RemoteVideoChat, 
    LocalVideoChat, 
    RemoteVideo, 
    RemoteVideoPrimary, 
    ContainerVideos, 
    TeacherVideoContainer,
    DescriptionContainer,
    Description,
    Actions,
    Avatar,
    Name,
    VideoCamPaused,
    PlaceholderVideoCam,
    DescriptionRemoteVideo,
    DescriptionContainerLocal
  } from "./styles";
  import { io } from "socket.io-client";
import { createContext } from 'react';
import { useLayoutEffect } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useCallback } from 'react';
import ScreenStreamBuilder from './scripts/ScreenStreamBuilder';

import { Janus } from './janus/janus';
import JanusBuilder from './janus/JanusBuilder';
import $ from 'jquery';

export const StreamContext = createContext({});

let janusBuilder = new JanusBuilder();
export function StreamContextProvider({children}) {
    const [user, setUser] = useState(null);
    const [codigoSala, setCodigoSala] = useState("")
    const [videoIsPaused, setVideoIsPaused] = useState(false)
    const [audioIsPaused, setAudioIsPaused] = useState(false)
    const [screenShared, setScreenShared] = useState(false)
    //const [screenStream, setScreenStream] = useState(null)
    const [codigoSalaCriado, setCodigoSalaCriado] = useState("")
    const [camIsReady, setCamIsReady] = useState(false)
    const [roomIsReady, setRoomIsReady] = useState(false)
    const [allUsersConnected, setAllUsersConnected] = useState([]);
    const remoteVideoChatRef = useRef(null);
    const localVideoChatRef = useRef(null);

    //Cria um ponto de usuario em sala
    /* const room = useMemo(() => {
        return new PeerRoomBuilder({
            offerOptions: {
              offerToReceiveAudio: true,
              offerToReceiveVideo: true,
              iceRestart: true,
            },
            onStateChange: (state, peer) => {
              const peerId = peer.peerId;
              console.log(state, peerId);
              console.log(socket.id);
              if (state === 'failed' || state === 'disconnected') {
                //peerVideo.remove();
              } else if (state === 'connecting' || state === 'connected') {
                //peerOnMenu && peerOnMenu.remove();
              }
            },
            onRemoteStream: (stream, peer) => {
              const peerId = peer.peerId;
              const userPeer = peer.peer.user.user;
              if(allUsersInRoomConnected.findIndex(user => user.user.matricula.cod == userPeer.matricula.cod) == -1){
                setAllUsersConnected(old => [...old, peer.peer.user]);
                allUsersInRoomConnected.push(peer.peer.user);
              }
        
              const videoId = `video-${peerId}`;
              const checkVideo = document.getElementById(videoId);
              if (!checkVideo && peerId !== socket.id) {
                const CreateVideo = () => {
                  const videoRef = useRef(null);
                  useLayoutEffect(() => {
                    if(videoRef.current){
                      console.log("stream do aluno", stream);
                      videoRef.current.srcObject = stream;
                    }
                  }, [])
                  return (    
                    <RemoteVideo>
                      <video
                        ref={videoRef}
                        id={videoId}
                        autoPlay
                        muted
                        style={{}}
                      />
                      <DescriptionRemoteVideo id="description-remotevideo">
                        <span>{userPeer.perfil.nome[0]}</span>
                        <p>{userPeer.perfil.nome}</p>
                      </DescriptionRemoteVideo>
                    </RemoteVideo>
                  )
                }
                if(remoteVideoChatRef.current){
                  let elementNoOverwrite = document.createElement('div')
                  ReactDOM.render(<CreateVideo/>, elementNoOverwrite);
                  remoteVideoChatRef.current.appendChild(elementNoOverwrite);
                }
              }
            },
          });
    }, []) */
    
    let janus = null;

    useEffect(() => {
        Janus.init({debug: "all", callback: function() {
            // Make sure the browser supports WebRTC
            if(!Janus.isWebrtcSupported()) {
                CustomToast.error("No WebRTC support... ");
                return;
            }
            // Create session
            janus = new Janus(
                {
                    server: ['https://eduqueme.apiobjetiveti.com.br/janus'],
                    iceServers: [{url: "turn:objetiveti.dyndns.org:50260", username: "username", credential: "password"}],
                    //iceServers: iceServers,
                    // Should the Janus API require authentication, you can specify either the API secret or user token here too
                    //		token: "mytoken",
                    //	or
                    //		apisecret: "serversecret",
                    success: function() {
                        // Attach to video room test plugin
                        janus.attach(
                            {
                                plugin: "janus.plugin.videoroom",
                                opaqueId: janusBuilder.opaqueId,
                                success: function(pluginHandle) {
                                    //$('#details').remove();
                                    janusBuilder.sfutest = pluginHandle;
                                    Janus.log("Plugin attached! (" + janusBuilder.sfutest.getPlugin() + ", id=" + janusBuilder.sfutest.getId() + ")");
                                    Janus.log("  -- This is a publisher/manager");
                                    // Prepare the username registration
                                    //$('#videojoin').removeClass('hide').show();
                                    //$('#registernow').removeClass('hide').show();
                                    //$('#register').click(registerUsername);
                                    //add registerUsername a acao de entrar na sala
                                    /* $('#username').focus();
                                    $('#start').removeAttr('disabled').html("Stop")
                                        .click(function() {
                                            $(this).attr('disabled', true);
                                            janus.destroy();
                                        }); */
                                },
                                error: function(error) {
                                    Janus.error("  -- Error attaching plugin...", error);
                                    CustomToast.error("Error attaching plugin... " + error);
                                },
                                consentDialog: function(on) {
                                    Janus.debug("Consent dialog should be " + (on ? "on" : "off") + " now");
                                    /* if(on) {
                                        // Darken screen and show hint
                                        $.blockUI({
                                            message: '<div><img src="up_arrow.png"/></div>',
                                            css: {
                                                border: 'none',
                                                padding: '15px',
                                                backgroundColor: 'transparent',
                                                color: '#aaa',
                                                top: '10px',
                                                left: (navigator.mozGetUserMedia ? '-100px' : '300px')
                                            } });
                                    } else {
                                        // Restore screen
                                        $.unblockUI();
                                    } */
                                },
                                iceState: function(state) {
                                    Janus.log("ICE state changed to " + state);
                                },
                                mediaState: function(medium, on, mid) {
                                    Janus.log("Janus " + (on ? "started" : "stopped") + " receiving our " + medium + " (mid=" + mid + ")");
                                },
                                webrtcState: function(on) {
                                    Janus.log("Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
                                    /* $("#videolocal").parent().parent().unblock();
                                    if(!on)
                                        return;
                                    $('#publish').remove();
                                    // This controls allows us to override the global room bitrate cap
                                    $('#bitrate').parent().parent().removeClass('hide').show();
                                    $('#bitrate a').click(function() {
                                        let id = $(this).attr("id");
                                        let bitrate = parseInt(id)*1000;
                                        if(bitrate === 0) {
                                            Janus.log("Not limiting bandwidth via REMB");
                                        } else {
                                            Janus.log("Capping bandwidth to " + bitrate + " via REMB");
                                        }
                                        $('#bitrateset').html($(this).html() + '<span class="caret"></span>').parent().removeClass('open');
                                        sfutest.send({ message: { request: "configure", bitrate: bitrate }});
                                        return false;
                                    }); */
                                },
                                slowLink: function(uplink, lost, mid) {
                                    Janus.warn("Janus reports problems " + (uplink ? "sending" : "receiving") +
                                        " packets on mid " + mid + " (" + lost + " lost packets)");
                                },
                                onmessage: function(msg, jsep) {
                                    Janus.debug(" ::: Got a message (publisher) :::", msg);
                                    let event = msg["videoroom"];
                                    Janus.debug("Event: " + event);
                                    if(event != undefined && event != null) {
                                        if(event === "joined") {
                                            // Publisher/manager created, negotiate WebRTC and attach to existing feeds, if any
                                            janusBuilder.myid = msg["id"];
                                            janusBuilder.mypvtid = msg["private_id"];
                                            Janus.log("Successfully joined room " + msg["room"] + " with ID " + janusBuilder.myid);
                                            if(janusBuilder.subscriber_mode) {
                                                setRoomIsReady(true);
                                            } else {
                                                publishOwnFeed(true);
                                            }
                                            console.log(msg);
                                            // Any new feed to attach to?
                                            if(msg["publishers"]) {
                                                let list = msg["publishers"];
                                                Janus.debug("Got a list of available publishers/feeds:", list);
                                                let sources = null;
                                                for(let f in list) {
                                                    if(list[f]["dummy"])
                                                        continue;
                                                    let id = list[f]["id"];
                                                    let display = list[f]["display"];
                                                    let streams = list[f]["streams"];
                                                    for(let i in streams) {
                                                        let stream = streams[i];
                                                        stream["id"] = id;
                                                        stream["display"] = display;
                                                    }
                                                    let slot = janusBuilder.feedStreams[id] ? janusBuilder.feedStreams[id].slot : null;
                                                    let remoteVideos = janusBuilder.feedStreams[id] ? janusBuilder.feedStreams[id].remoteVideos : 0;
                                                    janusBuilder.feedStreams[id] = {
                                                        id: id,
                                                        display: display,
                                                        streams: streams,
                                                        slot: slot,
                                                        remoteVideos: remoteVideos
                                                    }
                                                    Janus.debug("  >> [" + id + "] " + display + ":", streams);
                                                    if(!sources)
                                                        sources = [];
                                                    sources.push(streams);
                                                }
                                                if(sources)
                                                    subscribeTo(sources);
                                            }
                                        } else if(event === "destroyed") {
                                            // The room has been destroyed
                                            Janus.warn("The room has been destroyed!");
                                            CustomToast.error("The room has been destroyed", function() {
                                                window.location.reload();
                                            });
                                        } else if(event === "event") {
                                            // Any info on our streams or a new feed to attach to?
                                            if(msg["streams"]) {
                                                let streams = msg["streams"];
                                                for(let i in streams) {
                                                    let stream = streams[i];
                                                    stream["id"] = janusBuilder.myid;
                                                    stream["display"] = janusBuilder.myusername;
                                                }
                                                janusBuilder.feedStreams[janusBuilder.myid] = {
                                                    id: janusBuilder.myid,
                                                    display: janusBuilder.myusername,
                                                    streams: streams
                                                }
                                            } else if(msg["publishers"]) {
                                                let list = msg["publishers"];
                                                Janus.debug("Got a list of available publishers/feeds:", list);
                                                let sources = null;
                                                for(let f in list) {
                                                    if(list[f]["dummy"])
                                                        continue;
                                                    let id = list[f]["id"];
                                                    let display = list[f]["display"];
                                                    let streams = list[f]["streams"];
                                                    for(let i in streams) {
                                                        let stream = streams[i];
                                                        stream["id"] = id;
                                                        stream["display"] = display;
                                                    }
                                                    let slot = janusBuilder.feedStreams[id] ? janusBuilder.feedStreams[id].slot : null;
                                                    let remoteVideos = janusBuilder.feedStreams[id] ? janusBuilder.feedStreams[id].remoteVideos : 0;
                                                    janusBuilder.feedStreams[id] = {
                                                        id: id,
                                                        display: display,
                                                        streams: streams,
                                                        slot: slot,
                                                        remoteVideos: remoteVideos
                                                    }
                                                    Janus.debug("  >> [" + id + "] " + display + ":", streams);
                                                    if(!sources)
                                                        sources = [];
                                                    sources.push(streams);
                                                }
                                                if(sources)
                                                    subscribeTo(sources);
                                            } else if(msg["leaving"]) {
                                                // One of the publishers has gone away?
                                                let leaving = msg["leaving"];
                                                Janus.log("Publisher left: " + leaving);
                                                console.log(leaving);
                                                unsubscribeFrom(leaving);
                                            } else if(msg["unpublished"]) {
                                                // One of the publishers has unpublished?
                                                let unpublished = msg["unpublished"];
                                                console.log(unpublished);
                                                Janus.log("Publisher left: " + unpublished);
                                                if(unpublished === 'ok') {
                                                    // That's us
                                                    janusBuilder.sfutest.hangup();
                                                    return;
                                                }
                                                unsubscribeFrom(unpublished);
                                            } else if(msg["error"]) {
                                                if(msg["error_code"] === 426) {
                                                    // This is a "no such room" error: give a more meaningful description
                                                    CustomToast.error(
                                                        "<p>Apparently room <code>" + janusBuilder.myroom + "</code> (the one this demo uses as a test room) " +
                                                        "does not exist...</p><p>Do you have an updated <code>janus.plugin.videoroom.cfg</code> " +
                                                        "configuration file? If not, make sure you copy the details of room <code>" + janusBuilder.myroom + "</code> " +
                                                        "from that sample in your current configuration file, then restart Janus and try again."
                                                    );
                                                } else {
                                                    CustomToast.error(msg["error"]);
                                                }
                                            }
                                        }
                                    }
                                    if(jsep) {
                                        Janus.debug("Handling SDP as well...", jsep);
                                        janusBuilder.sfutest.handleRemoteJsep({ jsep: jsep });
                                        // Check if any of the media we wanted to publish has
                                        // been rejected (e.g., wrong or unsupported codec)
                                        let audio = msg["audio_codec"];
                                        if(janusBuilder.mystream && janusBuilder.mystream.getAudioTracks() && janusBuilder.mystream.getAudioTracks().length > 0 && !audio) {
                                            // Audio has been rejected
                                            CustomToast.error("Our audio stream has been rejected, viewers won't hear us");
                                        }
                                        let video = msg["video_codec"];
                                        if(janusBuilder.mystream && janusBuilder.mystream.getVideoTracks() && janusBuilder.mystream.getVideoTracks().length > 0 && !video) {
                                            // Video has been rejected
                                            CustomToast.error("Our video stream has been rejected, viewers won't see us");
                                            // Hide the webcam video
                                            if(localVideoChatRef.current){
                                                $(localVideoChatRef.current.id+" video").hide();
                                                $(localVideoChatRef.current.id).append(
                                                    '<div class="no-video-container">' +
                                                        '<i class="fa fa-video-camera fa-5 no-video-icon" style="height: 100%;"></i>' +
                                                        '<span class="no-video-text" style="font-size: 16px;">Video rejected, no webcam</span>' +
                                                    '</div>');
                                            }
                                        }
                                    }
                                },
                                onlocaltrack: function(track, on) {
                                    Janus.debug(" ::: Got a local track event :::");
                                    Janus.debug("Local track " + (on ? "added" : "removed") + ":", track);
                                    let getUser = localStorage.getItem('persist:eduqueMe');
                                    getUser = JSON.parse(getUser);
                                    getUser = JSON.parse(getUser.user)
                                    let username = getUser.perfil.nome;
                                    // We use the track ID as name of the element, but it may contain invalid characters
                                    let trackId = track.id.replace(/[{}]/g, "");
                                    if(!on) {
                                        // Track removed, get rid of the stream and the rendering
                                        let stream = janusBuilder.localTracks[trackId];
                                        if(stream) {
                                            try {
                                                let tracks = stream.getTracks();
                                                for(let i in tracks) {
                                                    let mst = tracks[i];
                                                    if(mst)
                                                        mst.stop();
                                                }
                                            } catch(e) {}
                                        }
                                        if(track.kind === "video") {
                                            $('#myvideo' + trackId).remove();
                                            janusBuilder.localVideos--;
                                            if(janusBuilder.localVideos === 0) {
                                                // No video, at least for now: show a placeholder
                                                if($('#localVideoChat .no-video-container').length === 0) {
                                                    const CreatePlaceholder = () => {
                                                        return (
                                                            <VideoCamPaused id="placeholderVideo">
                                                                <PlaceholderVideoCam>{username && username[0]}</PlaceholderVideoCam>
                                                            </VideoCamPaused>
                                                        )
                                                    }
                                            
                                                    if(localVideoChatRef.current){
                                                        let elementNoOverwrite = document.createElement('div')
                                                        elementNoOverwrite.className = "no-video-container";
                                                        ReactDOM.render(<CreatePlaceholder/>, elementNoOverwrite);
                                                        localVideoChatRef.current.appendChild(elementNoOverwrite);
                                                    }
                                                }
                                            }
                                        }
                                        delete janusBuilder.localTracks[trackId];
                                        return;
                                    }
                                    // If we're here, a new track was added
                                    let stream = janusBuilder.localTracks[trackId];
                                    if(stream) {
                                        // We've been here already
                                        return;
                                    }
                                    $('#videos').removeClass('hide').show();
                                    /* if($('#mute').length === 0) {
                                        // Add a 'mute' button
                                        $('#videolocal').append('<button class="btn btn-warning btn-xs" id="mute" style="position: absolute; bottom: 0px; left: 0px; margin: 15px;">Mute</button>');
                                        $('#mute').click(toggleMute);
                                        // Add an 'unpublish' button
                                        $('#videolocal').append('<button class="btn btn-warning btn-xs" id="unpublish" style="position: absolute; bottom: 0px; right: 0px; margin: 15px;">Unpublish</button>');
                                        $('#unpublish').click(unpublishOwnFeed);
                                    } */
                                    if(track.kind === "audio") {
                                        // We ignore local audio tracks, they'd generate echo anyway
                                        if(janusBuilder.localVideos === 0) {
                                            // No video, at least for now: show a placeholder
                                            if($('#localVideoChat .no-video-container').length === 0) {
                                                const CreatePlaceholder = () => {
                                                    return (
                                                        <VideoCamPaused id="placeholderVideo">
                                                            <PlaceholderVideoCam>{username && username[0]}</PlaceholderVideoCam>
                                                        </VideoCamPaused>
                                                    )
                                                }
                                        
                                                if(localVideoChatRef.current){
                                                    let elementNoOverwrite = document.createElement('div')
                                                    elementNoOverwrite.className = "no-video-container";
                                                    ReactDOM.render(<CreatePlaceholder/>, elementNoOverwrite);
                                                    localVideoChatRef.current.appendChild(elementNoOverwrite);
                                                }
                                            }
                                        }
                                    } else {
                                        // New video track: create a stream out of it
                                        janusBuilder.localVideos++;
                                        $('#localVideoChat .no-video-container').remove();
                                        let stream = new MediaStream([track]);
                                        janusBuilder.localTracks[trackId] = stream;
                                        Janus.log("Created local stream:", stream);
                                        Janus.log(stream.getTracks());
                                        Janus.log(stream.getVideoTracks());
                                        $('#videolocal').append('<video class="rounded centered" id="myvideo' + trackId + '" width=100% autoplay playsinline muted="muted"/>');
                                        Janus.attachMediaStream($('#myvideo' + trackId).get(0), stream);
                                    }
                                    if(janusBuilder.sfutest.webrtcStuff.pc.iceConnectionState !== "completed" &&
                                        janusBuilder.sfutest.webrtcStuff.pc.iceConnectionState !== "connected") {
                                        /* $("#videolocal").parent().parent().block({
                                            message: '<b>Publishing...</b>',
                                            css: {
                                                border: 'none',
                                                backgroundColor: 'transparent',
                                                color: 'white'
                                            }
                                        }); */
                                    }
                                },
                                // eslint-disable-next-line no-unused-vars
                                onremotetrack: function(track, mid, on) {
                                    // The publisher stream is sendonly, we don't expect anything here
                                },
                                oncleanup: function() {
                                    Janus.log(" ::: Got a cleanup notification: we are unpublished now :::");
                                    janusBuilder.mystream = null;
                                    delete janusBuilder.feedStreams[janusBuilder.myid];
                                    //$(localVideoChatRef.current.id).html('<button id="publish" class="btn btn-primary">Publish</button>');
                                    //$('#publish').click(function() { publishOwnFeed(true); });
                                    //$("#videolocal").parent().parent().unblock();
                                    $('#bitrate').parent().parent().addClass('hide');
                                    $('#bitrate a').unbind('click');
                                    janusBuilder.localTracks = {};
                                    janusBuilder.localVideos = 0;
                                }
                            });
                    },
                    error: function(error) {
                        Janus.error(error);
                        CustomToast.error(error, function() {
                            window.location.reload();
                        });
                    },
                    destroyed: function() {
                        window.location.reload();
                    }
                });
        }});
    }, [])

    function toggleMute() {
        let muted = janusBuilder.sfutest.isAudioMuted();
        Janus.log((muted ? "Unmuting" : "Muting") + " local stream...");
        if(muted)
            janusBuilder.sfutest.unmuteAudio();
        else
            janusBuilder.sfutest.muteAudio();
        muted = janusBuilder.sfutest.isAudioMuted();
        $('#mute').html(muted ? "Unmute" : "Mute");
    }
    
    function unpublishOwnFeed() {
        // Unpublish our stream
        //$('#unpublish').attr('disabled', true).unbind('click');
        let unpublish = { request: "unpublish" };
        $('#videolocal video').remove();
        janusBuilder.sfutest.send({ message: unpublish });
    }

    function unsubscribeFrom(id) {
        // Unsubscribe from this publisher
        let feed = janusBuilder.feedStreams[id];
        if(!feed)
            return;
        Janus.debug("Feed " + id + " (" + feed.display + ") has left the room, detaching");
        if(janusBuilder.bitrateTimer[feed.slot])
            clearInterval(janusBuilder.bitrateTimer[feed.slot]);
        janusBuilder.bitrateTimer[feed.slot] = null;
        $('#remote' + feed.slot).empty().hide();
        $('#videoremote' + feed.slot).parent().parent().remove();
        delete janusBuilder.simulcastStarted[feed.slot];
        delete janusBuilder.svcStarted[feed.slot];
        $('#simulcast' + feed.slot).remove();
        delete janusBuilder.feeds[feed.slot];
        janusBuilder.feeds.slot = 0;
        delete janusBuilder.feedStreams[id];
        // Send an unsubscribe request
        let unsubscribe = {
            request: "unsubscribe",
            streams: [{ feed: id }]
        };
        if(janusBuilder.remoteFeed != null)
            janusBuilder.remoteFeed.send({ message: unsubscribe });
        delete janusBuilder.subscriptions[id];
    }

    function subscribeTo(sources) {
        // Check if we're still creating the subscription handle
        if(janusBuilder.creatingSubscription) {
            // Still working on the handle, send this request later when it's ready
            setTimeout(function() {
                subscribeTo(sources);
            }, 500);
            return;
        }
        // If we already have a working subscription handle, just update that one
        if(janusBuilder.remoteFeed) {
            // Prepare the streams to subscribe to, as an array: we have the list of
            // streams the feeds are publishing, so we can choose what to pick or skip
            let added = null, removed = null;
            for(let s in sources) {
                let streams = sources[s];
                for(let i in streams) {
                    let stream = streams[i];
                    // If the publisher is VP8/VP9 and this is an older Safari, let's avoid video
                    if(stream.type === "video" && Janus.webRTCAdapter.browserDetails.browser === "safari" &&
                            (stream.codec === "vp9" || (stream.codec === "vp8" && !Janus.safariVp8))) {
                        CustomToast.error("Publisher is using " + stream.codec.toUpperCase +
                            ", but Safari doesn't support it: disabling video stream #" + stream.mindex);
                        continue;
                    }
                    if(stream.disabled) {
                        Janus.log("Disabled stream:", stream);
                        // Unsubscribe
                        if(!removed)
                            removed = [];
                        removed.push({
                            feed: stream.id,	// This is mandatory
                            mid: stream.mid		// This is optional (all streams, if missing)
                        });
                        delete janusBuilder.subscriptions[stream.id][stream.mid];
                        continue;
                    }
                    if(janusBuilder.subscriptions[stream.id] && janusBuilder.subscriptions[stream.id][stream.mid]) {
                        Janus.log("Already subscribed to stream, skipping:", stream);
                        continue;
                    }
                    // Find an empty slot in the UI for each new source
                    if(!janusBuilder.feedStreams[stream.id].slot) {
                        let slot;
                        for(let i=1;i<6;i++) {
                            if(!janusBuilder.feeds[i]) {
                                slot = i;
                                janusBuilder.feeds[slot] = stream.id;
                                janusBuilder.feedStreams[stream.id].slot = slot;
                                janusBuilder.feedStreams[stream.id].remoteVideos = 0;
                                //codigo abaixo add o nome ao site
                                //$('#remote' + slot).removeClass('hide').html(escapeXmlTags(stream.display)).show();
                                break;
                            }
                        }
                    }
                    // Subscribe
                    if(!added)
                        added = [];
                    added.push({
                        feed: stream.id,	// This is mandatory
                        mid: stream.mid		// This is optional (all streams, if missing)
                    });
                    if(!janusBuilder.subscriptions[stream.id])
                        janusBuilder.subscriptions[stream.id] = {};
                    janusBuilder.subscriptions[stream.id][stream.mid] = true;
                }
            }
            if((!added || added.length === 0) && (!removed || removed.length === 0)) {
                // Nothing to do
                return;
            }
            let update = { request: 'update' };
            if(added)
                update.subscribe = added;
            if(removed)
                update.unsubscribe = removed;
            janusBuilder.remoteFeed.send({ message: update });
            // Nothing else we need to do
            return;
        }
        // If we got here, we're creating a new handle for the subscriptions (we only need one)
        janusBuilder.creatingSubscription = true;
        janus.attach(
            {
                plugin: "janus.plugin.videoroom",
                opaqueId: janusBuilder.opaqueId,
                success: function(pluginHandle) {
                    janusBuilder.remoteFeed = pluginHandle;
                    janusBuilder.remoteTracks = {};
                    Janus.log("Plugin attached! (" + janusBuilder.remoteFeed.getPlugin() + ", id=" + janusBuilder.remoteFeed.getId() + ")");
                    Janus.log("  -- This is a multistream subscriber");
                    // Prepare the streams to subscribe to, as an array: we have the list of
                    // streams the feed is publishing, so we can choose what to pick or skip
                    let subscription = [];
                    console.log(sources);
                    for(let s in sources) {
                        let streams = sources[s];
                        for(let i in streams) {
                            let stream = streams[i];
                            // If the publisher is VP8/VP9 and this is an older Safari, let's avoid video
                            if(stream.type === "video" && Janus.webRTCAdapter.browserDetails.browser === "safari" &&
                                    (stream.codec === "vp9" || (stream.codec === "vp8" && !Janus.safariVp8))) {
                                CustomToast.error("Publisher is using " + stream.codec.toUpperCase +
                                    ", but Safari doesn't support it: disabling video stream #" + stream.mindex);
                                continue;
                            }
                            if(stream.disabled) {
                                Janus.log("Disabled stream:", stream);
                                // TODO Skipping for now, we should unsubscribe
                                continue;
                            }
                            Janus.log("Subscribed to " + stream.id + "/" + stream.mid + "?", janusBuilder.subscriptions);
                            if(janusBuilder.subscriptions[stream.id] && janusBuilder.subscriptions[stream.id][stream.mid]) {
                                Janus.log("Already subscribed to stream, skipping:", stream);
                                continue;
                            }
                            // Find an empty slot in the UI for each new source
                            if(!janusBuilder.feedStreams[stream.id].slot) {
                                let slot;
                                for(let i=1;i<6;i++) {
                                    if(!janusBuilder.feeds[i]) {
                                        slot = i;
                                        janusBuilder.feeds[slot] = stream.id;
                                        janusBuilder.feedStreams[stream.id].slot = slot;
                                        janusBuilder.feedStreams[stream.id].remoteVideos = 0;
                                        //$('#remote' + slot).removeClass('hide').html(escapeXmlTags(stream.display)).show();
                                        break;
                                    }
                                }
                            }
                            subscription.push({
                                feed: stream.id,	// This is mandatory
                                mid: stream.mid		// This is optional (all streams, if missing)
                            });
                            if(!janusBuilder.subscriptions[stream.id])
                                janusBuilder.subscriptions[stream.id] = {};
                            janusBuilder.subscriptions[stream.id][stream.mid] = true;
                        }
                    }
                    // We wait for the plugin to send us an offer
                    let subscribe = {
                        request: "join",
                        room: janusBuilder.myroom,
                        ptype: "subscriber",
                        streams: subscription,
                        use_msid: janusBuilder.use_msid,
                        private_id: janusBuilder.mypvtid
                    };
                    janusBuilder.remoteFeed.send({ message: subscribe });
                },
                error: function(error) {
                    Janus.error("  -- Error attaching plugin...", error);
                    //bootbox.CustomToast.error("Error attaching plugin... " + error);
                },
                iceState: function(state) {
                    Janus.log("ICE state (remote feed) changed to " + state);
                },
                webrtcState: function(on) {
                    Janus.log("Janus says this WebRTC PeerConnection (remote feed) is " + (on ? "up" : "down") + " now");
                },
                slowLink: function(uplink, lost, mid) {
                    Janus.warn("Janus reports problems " + (uplink ? "sending" : "receiving") +
                        " packets on mid " + mid + " (" + lost + " lost packets)");
                },
                onmessage: function(msg, jsep) {
                    Janus.debug(" ::: Got a message (subscriber) :::", msg);
                    let event = msg["videoroom"];
                    Janus.debug("Event: " + event);
                    if(msg["error"]) {
                        CustomToast.error(msg["error"]);
                    } else if(event) {
                        if(event === "attached") {
                            // Now we have a working subscription, next requests will update this one
                            janusBuilder.creatingSubscription = false;
                            Janus.log("Successfully attached to feed in room " + msg["room"]);
                        } else if(event === "event") {
                            
                        } else {
                            // What has just happened?
                        }
                    }
                    if(msg["streams"]) {
                        // Update map of subscriptions by mid
                        for(let i in msg["streams"]) {
                            let mid = msg["streams"][i]["mid"];
                            janusBuilder.subStreams[mid] = msg["streams"][i];
                            let feed = janusBuilder.feedStreams[msg["streams"][i]["feed_id"]];
                            if(feed && feed.slot) {
                                janusBuilder.slots[mid] = feed.slot;
                                janusBuilder.mids[feed.slot] = mid;
                            }
                        }
                    }
                    if(jsep) {
                        Janus.debug("Handling SDP as well...", jsep);
                        // Answer and attach
                        janusBuilder.remoteFeed.createAnswer(
                            {
                                jsep: jsep,
                                // We only specify data channels here, as this way in
                                // case they were offered we'll enable them. Since we
                                // don't mention audio or video tracks, we autoaccept them
                                // as recvonly (since we won't capture anything ourselves)
                                tracks: [
                                    { type: 'data' }
                                ],
                                success: function(jsep) {
                                    Janus.debug("Got SDP!");
                                    Janus.debug(jsep);
                                    let body = { request: "start", room: janusBuilder.myroom };
                                    janusBuilder.remoteFeed.send({ message: body, jsep: jsep });
                                },
                                error: function(error) {
                                    Janus.error("WebRTC error:", error);
                                    Janus.error("WebRTC error... " + error.message);
                                }
                            });
                    }
                },
                // eslint-disable-next-line no-unused-vars
                onlocaltrack: function(track, on) {
                    // The subscriber stream is recvonly, we don't expect anything here
                },
                onremotetrack: function(track, mid, on, metadata) {
                    Janus.debug(
                        "Remote track (mid=" + mid + ") " +
                        (on ? "added" : "removed") +
                        (metadata ? " (" + metadata.reason + ") ": "") + ":", track
                    );
                    // Which publisher are we getting on this mid?
                    let sub = janusBuilder.subStreams[mid];
                    let feed = janusBuilder.feedStreams[sub.feed_id];
                    console.log(sub, feed);
                    Janus.debug(" >> This track is coming from feed " + sub.feed_id + ":", feed);
                    let slot = janusBuilder.slots[mid];
                    if(feed && !slot) {
                        slot = feed.slot;
                        janusBuilder.slots[mid] = feed.slot;
                        janusBuilder.mids[feed.slot] = mid;
                    }
                    Janus.debug(" >> mid " + mid + " is in slot " + slot);
                    let name = feed ? feed?.display?.split('-')[0] : "";
                    let tipo = feed ? feed?.display?.split('-')[1] : "";
                    if(!on) {
                        // Track removed, get rid of the stream and the rendering
                        $('#remotevideo' + slot + '-' + mid).remove();
                        if(track.kind === "video" && feed) {
                            feed.remoteVideos--;
                            if(feed.remoteVideos === 0) {
                                //codigo abaixo põe um placeholder se não houver a camera remota
                                // No video, at least for now: show a placeholder
                                if($('#videoremote' + slot + ' .no-video-container').length === 0) {
                                    const CreatePlaceholder = () => {
                                        return (
                                            <VideoCamPaused id="placeholderVideo">
                                                <PlaceholderVideoCam style={{fontSize: "1rem",padding: "15px 25px"}}>{name && name[0]}</PlaceholderVideoCam>
                                            </VideoCamPaused>
                                        )
                                    }
                            
                                    let elementNoOverwrite = document.createElement('div')
                                    elementNoOverwrite.className = "no-video-container";
                                    ReactDOM.render(<CreatePlaceholder/>, elementNoOverwrite);
                                    $('#videoremote' + slot).append(elementNoOverwrite);
                                }
                            }
                        }
                        delete janusBuilder.remoteTracks[mid];
                        delete janusBuilder.slots[mid];
                        delete janusBuilder.mids[slot];
                        return;
                    }
                    // If we're here, a new track was added
                    if(feed.spinner) {
                        feed.spinner.stop();
                        feed.spinner = null;
                    }
                    if($('#remotevideo' + slot + '-' + mid).length > 0)
                        return;

                    if($('#videoremote' + slot).length == 0){
                        const CreateVideo = () => {
                            return (
                                <RemoteVideo>
                                    <div className="panel-body relative" id={`videoremote${slot}`} style={{width: '100%', height: '100%', position: 'relative'}}>
                                        
                                    </div>
                                    <DescriptionRemoteVideo id='description-remotevideo'>
                                      <span>{name[0]}</span>
                                      <p>{name}</p>
                                    </DescriptionRemoteVideo>
                                </RemoteVideo>
                            )
                        }
                
                        if(remoteVideoChatRef.current){
                            let elementNoOverwrite = document.createElement('div')
                            ReactDOM.render(<CreateVideo/>, elementNoOverwrite);
                            remoteVideoChatRef.current.appendChild(elementNoOverwrite);
                        }
                    }
                    if(track.kind === "audio") {
                        // New audio track: create a stream out of it, and use a hidden <audio> element
                        let stream = new MediaStream([track]);
                        janusBuilder.remoteTracks[mid] = stream;
                        Janus.log("Created remote audio stream:", stream);
                        $('#videoremote' + slot+" audio").remove();
                        $('#videoremote' + slot).append('<audio class="hide" id="remotevideo' + slot + '-' + mid + '" autoplay playsinline/>');
                        Janus.attachMediaStream($('#remotevideo' + slot + '-' + mid).get(0), stream);
                        if(feed.remoteVideos === 0) {
                            // No video, at least for now: show a placeholder
                            if($('#videoremote' + slot + ' .no-video-container').length === 0) {
                                const CreatePlaceholder = () => {
                                    return (
                                        <VideoCamPaused id="placeholderVideo">
                                            <PlaceholderVideoCam style={{fontSize: "1rem",padding: "15px 25px"}}>{name && name[0]}</PlaceholderVideoCam>
                                        </VideoCamPaused>
                                    )
                                }
                        
                                let elementNoOverwrite = document.createElement('div')
                                elementNoOverwrite.className = "no-video-container";
                                ReactDOM.render(<CreatePlaceholder/>, elementNoOverwrite);
                                $('#videoremote' + slot).append(elementNoOverwrite);
                            }
                        }
                    } else {
                        // New video track: create a stream out of it
                        feed.remoteVideos++;
                        $('#videoremote' + slot + ' .no-video-container').remove();
                        let stream = new MediaStream([track]);
                        janusBuilder.remoteTracks[mid] = stream;
                        Janus.log("Created remote video stream:", stream);
                        $('#videoremote' + slot).append('<video class="rounded centered" id="remotevideo' + slot + '-' + mid + '" width=100% autoplay playsinline/>');
                        $('#videoremote' + slot).append(
                            '<span class="label label-primary hide" id="curres'+slot+'" style="position: absolute; bottom: 0px; left: 0px; margin: 15px;"></span>' +
                            '<span class="label label-info hide" id="curbitrate'+slot+'" style="position: absolute; bottom: 0px; right: 0px; margin: 15px;"></span>');
                        Janus.attachMediaStream($('#remotevideo' + slot + '-' + mid).get(0), stream);
                        // Note: we'll need this for additional videos too
                        
                    }
                    listAllInRoom();
                },
                oncleanup: function() {
                    Janus.log(" ::: Got a cleanup notification (remote feed) :::");
                    console.log(janusBuilder);
                    for(let i=1;i<=janusBuilder.feeds.length;i++) {
                        const feed = janusBuilder.feeds[i];
                        $('#videoremote'+i).empty();
                        /* if(bitrateTimer[i])
                            clearInterval(bitrateTimer[i]); */
                        console.log(janusBuilder);
                        if(janusBuilder){
                            janusBuilder.bitrateTimer[i] = null;
                            janusBuilder.feedStreams[feed] = {
                                ...janusBuilder.feedStreams[feed],
                                simulcastStarted: false,
                                svcStarted: false,
                                remoteVideos: 0
                            };
                        }
                        //$('#simulcast'+i).remove();
                    }
                    janusBuilder.remoteTracks = {};
                }
            }
        );
    }

    function publishOwnFeed(useAudio, isShareScreen = false, useVideo = true) {
        // Publish our stream
        //$('#publish').attr('disabled', true).unbind('click');
        //setVideoIsPaused(false);
    
        // We want sendonly audio and video (uncomment the data track
        // too if you want to publish via datachannels as well)
        let tracks = [];
        if(useAudio) {
            tracks.push({ type: 'audio', capture: true, recv: false });
        }
        if(isShareScreen){
          tracks.push({ type: 'screen', capture: true, recv: false,
              // We may need to enable simulcast or SVC on the video track
              simulcast: janusBuilder.doSimulcast,
              // We only support SVC for VP9 and (still WIP) AV1
              svc: ((janusBuilder.vcodec === 'vp9' || janusBuilder.vcodec === 'av1') && janusBuilder.doSvc) ? janusBuilder.doSvc : null
          });

        } 
        if(useVideo) {
          tracks.push({ type: 'video', capture: true, recv: false,
              // We may need to enable simulcast or SVC on the video track
              simulcast: janusBuilder.doSimulcast,
              // We only support SVC for VP9 and (still WIP) AV1
              svc: ((janusBuilder.vcodec === 'vp9' || janusBuilder.vcodec === 'av1') && janusBuilder.doSvc) ? janusBuilder.doSvc : null
          });
        }
        console.log(tracks);
        //~ tracks.push({ type: 'data' });
        janusBuilder.sfutest.createOffer(
            {
                tracks: tracks,
                success: function(jsep) {
                    Janus.debug("Got publisher SDP!");
                    Janus.debug(jsep);
                    let publish = { request: "configure", audio: useAudio, video: true };
                    // You can force a specific codec to use when publishing by using the
                    // audiocodec and videocodec properties, for instance:
                    // 		publish["audiocodec"] = "opus"
                    // to force Opus as the audio codec to use, or:
                    // 		publish["videocodec"] = "vp9"
                    // to force VP9 as the videocodec to use. In both case, though, forcing
                    // a codec will only work if: (1) the codec is actually in the SDP (and
                    // so the browser supports it), and (2) the codec is in the list of
                    // allowed codecs in a room. With respect to the point (2) above,
                    // refer to the text in janus.plugin.videoroom.cfg for more details
                    if(janusBuilder.acodec)
                        publish["audiocodec"] = janusBuilder.acodec;
                    if(janusBuilder.vcodec)
                        publish["videocodec"] = janusBuilder.vcodec;
                    janusBuilder.sfutest.send({ message: publish, jsep: jsep });
                },
                error: function(error) {
                    Janus.error("WebRTC error:", error);
                    if (useAudio) {
                        publishOwnFeed(false);
                    } else {
                        CustomToast.error("WebRTC error... " + error.message);
                        //$('#publish').removeAttr('disabled').click(function() { publishOwnFeed(true); });
                    }
                }
            });
    }

    function escapeXmlTags(value) {
        if(value) {
            let escapedValue = value.replace(new RegExp('<', 'g'), '&lt');
            escapedValue = escapedValue.replace(new RegExp('>', 'g'), '&gt');
            return escapedValue;
        }
    }

    function registerUsername() {
        let user = localStorage.getItem('persist:eduqueMe');
        user = JSON.parse(user);
        user = JSON.parse(user.user)
        setUser(user);
        let username = `${user.perfil.nome}-${user.matricula.tipo}`;
        let register = {
            request: "join",
            room: janusBuilder.myroom,
            ptype: "publisher",
            display: username
        };
        janusBuilder.myusername = escapeXmlTags(username);
        janusBuilder.sfutest.send({ message: register });
        setRoomIsReady(true);
        listAllInRoom();
    }

    function onJoin() {
        var codigo_sala = $('#codigo_sala').val();
        setCodigoSala(codigo_sala);
        janusBuilder.myroom = parseInt(codigo_sala);
        console.log(parseInt(codigo_sala));
        console.log(codigo_sala);
        registerUsername()
    }

    async function onCreateRoom() {
      console.log("create room");
      let roomId = Math.floor(100000 + Math.random() * 900000);
      let create = {
        request: "create",
        ptype: "publisher",
        room: roomId,
        admin_key: "supersecret",
        publishers: 1000
      };
      let resp = new Promise(function(fulfill, reject) {
        janusBuilder.sfutest.send({
          message: create,
          success: fulfill,
          error: reject
        })
      })
      let res = await resp;
      console.log(res)
      if(res.videoroom == "created"){
        setCodigoSala(roomId);
        $('#codigo_sala').val(roomId);
        onJoin();
      } else {
        CustomToast.error(res.error);
      }
    }

    async function listAllInRoom() {
      let list = {
        request: "listparticipants",
        room: janusBuilder.myroom
      };
      let resp = new Promise(function(fulfill, reject) {
        janusBuilder.sfutest.send({
          message: list,
          success: fulfill,
          error: reject
        })
      })
      let res = await resp;
      let participants = res.participants.map(p => {
        let nome = p.display.split('-')[0];
        let tipo = p.display.split('-')[1];
        return {
          nome,
          tipo
        }
      })
      setAllUsersConnected(participants);
      console.log(res);
    }

    async function toggleScreen(isAudio, isScreen, isVideo) {
      unpublishOwnFeed();
      let closeTimer = false;
      let timer = setTimeout(() => {
        console.log('timer');
        if(janusBuilder.localVideos == 0){
          console.log('teste');
          publishOwnFeed(isAudio, isScreen, isVideo);
          //$('#videolocal video').remove();
          closeTimer = true;
        } else {
          if (closeTimer) {
            clearTimeout(timer);
          }
        }
      }, 1000);
    }

    return (
        <StreamContext.Provider 
            value={
                {   
                    user, 
                    setUser, 
                    codigoSala,
                    setCodigoSala, 
                    videoIsPaused, 
                    setVideoIsPaused, 
                    audioIsPaused, 
                    setAudioIsPaused,
                    roomIsReady, 
                    setRoomIsReady,
                    allUsersConnected, 
                    remoteVideoChatRef,
                    screenShared,
                    setScreenShared,
                    onJoin,
                    toggleMute,
                    unpublishOwnFeed,
                    publishOwnFeed,
                    localVideoChatRef,
                    onCreateRoom,
                    toggleScreen
                }
            }
        >
            {children}
        </StreamContext.Provider>
    )
}