/* eslint-disable no-useless-computed-key */
import React from "react";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTableCell } from "~/pages/Sistema/componentes/SistemaList";
import { ThisTable } from "./styles";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";

function TableComponent({ setTabFilter }) {
  return (
    <>
      <TabsWrapper tabHeight={2.4}>
        <Tab materialIcon="check_circle_outline" onClick={() => setTabFilter({ ["turma.ativo"]: true })}>
          Ativo
        </Tab>
        <Tab materialIcon="highlight_off" onClick={() => setTabFilter({ ["turma.ativo"]: false })}>
          Desativo
        </Tab>
      </TabsWrapper>

      <SistemaComponenteInterno>
        <ThisTable noEdit={true}>
          <SLTableCell name="turma.nome" inputfilter>
            Nome
          </SLTableCell>
          <SLTableCell name="disciplinaFilial.nome" inputfilter>
            Disciplina
          </SLTableCell>
          <SLTableCell name="turma.serie.nome" inputfilter>
            Série
          </SLTableCell>
          <SLTableCell name="turma.periodoLetivo.nome" inputfilter>
            Período Letivo
          </SLTableCell>
        </ThisTable>
      </SistemaComponenteInterno>
    </>
  );
}

export default TableComponent;
