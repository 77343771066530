import styled from "styled-components";

export const QuestaoItensContainer = styled.div`
  h3 {
    margin: 0.5em 0;
  }

  .Questoes_itens_addItem {
    margin-left: auto;
  }
`;
