import React, { useState } from "react";

import api from "~/services/api";
import ShowContent from "./ShowContent";
import CustomBodyContent from "./CustomBodyContent/index";
import SistemaList, {
  SLEditModal,
  SLShowModal,
} from "~/pages/Sistema/componentes/SistemaList";
import EditContent from "./EditContent";

function AcompanharMatriculas() {
  const [mountedData, setMountedData] = useState();
  const [tabFilter, setTabFilter] = useState();

  return (
    <SistemaList
      inputs={[{ filter: "dados.aluno.nome", label: "Aluno" }]}
      selects={[
        {
          list: [
            { value: "true", label: "Sim" },
            { value: "false", label: "Não" },
          ],
          value: "value",
          label: "label",
          filter: "atualizacaoPendencias",
          outLabel: "Atualizados",
        },
      ]}
      requests={{
        findAll: () =>
          api.get("padrao/matriculas/AcompanharMatriculas/matriculas"),
        findOne: (thisData) =>
          api.get("padrao/matriculas/AcompanharMatriculas/matricula", {
            params: { cod: thisData?.cod },
          }),
        deleteOne: (thisData) =>
          api.delete("padrao/matriculas/AcompanharMatriculas/matricula", {
            data: { cod: thisData?.cod },
          }),
      }}
      dataFilter={tabFilter}
    >
      <CustomBodyContent {...{ setTabFilter }} />
      <SLShowModal setter={setMountedData} title="Dados Atuais">
        <ShowContent {...{ mountedData }} />
      </SLShowModal>
      <SLEditModal
        setter={setMountedData}
        title="Requisitar Alteração de dados"
      >
        <EditContent {...{ mountedData }} />
      </SLEditModal>
    </SistemaList>
  );
}

export default AcompanharMatriculas;
