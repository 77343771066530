import styled from "styled-components";

export const Container = styled.div`
  width: 70em;
  max-width: 100%;

  .Label_inputWithButton {
    display: flex;
    align-items: center;

    button {
      padding: 0.25em 0.5em;
      margin-bottom: 0.4em;
      margin-left: 0.25em;
    }
  }

  .Label_arquivos_file_input {
    margin-bottom: 1em;
  }
`;

export const Text = styled.pre`
  font-size: 1em;
  line-height: 1.25;
  border-radius: 0.3em;
  border: 0.1em solid #e4e4e4;
  background: #ededed;

  align-self: center;
  padding: 0.25em 0.5em;
  /* width: 10em; */
  /* height: 10em; */
  max-width: 100%;

  ::-webkit-scrollbar {
    width: 0.5em;
  }

  ::-webkit-scrollbar-track {
    :hover {
      width: 0.75em;
      cursor: pointer;
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 0.1em solid slategrey;
    border-radius: 0.5em;
  }
`;
