/* eslint-disable eqeqeq */
import React from "react";

import api from "~/services/api";

import { Container } from "./styles";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import ShowLoading from "~/utils/ShowLoading";
import { Materialize } from "~/styles/materializeGrid";
import Label from "~/componentes/CustomForm/Label";
import SistemaTopico from "~/componentes/StyledComponents/Textos/SistemaTopico";
import { useSelector } from "react-redux";

/**
 * @callback onChangeData
 * @param {any} newValue Novo valor
 * @param {Object} options
 * @param {any} options.previousData Valor anterior
 */

/**
 * @param {Object} props
 * @param {onChangeData} props.onChangeUsuario
 * @param {onChangeData} props.onChangeAluno
 * @param {onChangeData} props.onChangeAlunoTurma
 * @param {onChangeData} props.onChangeAlunoDisciplinaTurma
 * @param {boolean} props.specify Se true, os selects não terão a opção "todas"
 * @param {Function} props.usuarioSetter
 * @param {Function} props.alunoSetter
 * @param {Function} props.alunoTurmaSetter
 * @param {Function} props.alunoDisciplinaTurmaSetter
 */
function PadraoAlunosFiltro({
  title,
  specify,
  onChangeUsuario,
  onChangeAluno,
  onChangeAlunoTurma,
  onChangeAlunoDisciplinaTurma,
  usuarioSetter,
  alunoSetter,
  alunoTurmaSetter,
  alunoDisciplinaTurmaSetter,
  hideAlunoDisciplinaTurma,
  children,
  ...rest
}) {
  const [loaded, setLoaded] = React.useState(false);
  const [usuariosVinculados, setUsuariosVinculados] = React.useState();
  const [usuario, setUsuario] = React.useState();
  const [aluno, setAluno] = React.useState();
  const [alunoTurma, setAlunoTurma] = React.useState();
  const [alunoDisciplinaTurma, setAlunoDisciplinaTurma] = React.useState();

  const showUsuario = useSelector((state) => {
    if (Array.isArray(usuariosVinculados)) {
      const leng = usuariosVinculados?.length;
      if (leng > 1 || (leng === 1 && usuario?.cod !== state.user.perfil?.cod)) {
        return true;
      }
    }
    return false;
  });

  async function handleUsuarioChange(data) {
    let thisData = data;
    if (typeof onChangeUsuario === "function") {
      thisData = await onChangeUsuario(data, { previousData: usuario });
    }
    setUsuario(thisData);

    if (typeof usuarioSetter === "function") {
      usuarioSetter(thisData);
    }

    const thisAluno = thisData?.alunos?.[0];
    // const thisAlunoTurma = thisAluno?.alunoTurmas?.[0];
    // const thisAlunoDisciplinasTurma = thisAlunoTurma?.alunoDisciplinasTurma?.[0];
    handleAlunoChange(thisAluno);
    // handleAlunoTurmaChange(thisAlunoTurma);
    // handleAlunoDisciplinaTurmaChange(thisAlunoDisciplinasTurma);
  }

  async function handleAlunoChange(data) {
    let thisData = data;

    if (typeof onChangeAluno === "function") {
      thisData = await onChangeAluno(data, { previousData: aluno });
    }
    setAluno(thisData);

    if (typeof alunoSetter === "function") {
      alunoSetter(thisData);
    }

    const thisAlunoTurma = thisData?.alunoTurmas?.[0];
    // const thisAlunoDisciplinasTurma = thisAlunoTurma?.alunoDisciplinasTurma?.[0];
    handleAlunoTurmaChange(thisAlunoTurma);
    // handleAlunoDisciplinaTurmaChange();
  }

  async function handleAlunoTurmaChange(data) {
    let thisData = data;
    if (typeof onChangeAlunoTurma === "function") {
      thisData = await onChangeAlunoTurma(data, { previousData: alunoTurma });
    }
    setAlunoTurma(thisData);

    if (typeof alunoTurmaSetter === "function") {
      alunoTurmaSetter(thisData);
    }

    const thisAlunoDisciplinasTurma = thisData?.alunoDisciplinasTurma?.[0];
    handleAlunoDisciplinaTurmaChange(thisAlunoDisciplinasTurma);
  }

  async function handleAlunoDisciplinaTurmaChange(data) {
    let thisData = data;
    if (typeof onChangeAlunoDisciplinaTurma === "function") {
      thisData = await onChangeAlunoDisciplinaTurma(data, {
        previousData: alunoDisciplinaTurma,
      });
    }
    setAlunoDisciplinaTurma(thisData);

    if (typeof alunoDisciplinaTurmaSetter === "function") {
      alunoDisciplinaTurmaSetter(thisData);
    }
  }

  React.useEffect(() => {
    async function fetchInitialData() {
      await ShowLoading(() =>
        api
          .get("padrao/usuariosVinculados")
          .then((r) => {
            setUsuariosVinculados(r?.data || []);
            const thisUsuario = r?.data?.[0];
            // setUsuario(thisUsuario);

            // if (!(r?.data?.length > 1) && thisUsuario) {
            handleUsuarioChange(thisUsuario);
            setLoaded(true);
            // }
          })
          .catch(() => {
            setLoaded(true);
          })
      );
    }

    fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const alunoTurmas = React.useMemo(() => {
    let thisList = [];

    function eachAlu(thisAluno) {
      if (Array.isArray(thisAluno?.alunoTurmas)) {
        thisList = [...thisList, ...thisAluno.alunoTurmas];
      }
    }

    if (aluno?.cod) {
      eachAlu(aluno);
    } else if (Array.isArray(usuario?.alunos)) {
      usuario.alunos.forEach((alu) => {
        eachAlu(alu);
      });
    }

    return thisList;
  }, [aluno, usuario]);

  const alunoDisciplinasTurmas = React.useMemo(() => {
    let thisList = [];

    function eachAlt(thisAlt) {
      if (Array.isArray(thisAlt?.alunoDisciplinasTurma)) {
        thisList = [...thisList, ...thisAlt.alunoDisciplinasTurma];
      }
    }

    function eachAlu(thisAlu) {
      if (Array.isArray(thisAlu?.alunoTurmas)) {
        thisAlu.alunoTurmas.forEach((at) => eachAlt(at));
      }
    }

    if (alunoTurma?.cod) {
      eachAlt(alunoTurma);
    } else if (aluno?.cod) {
      eachAlu(aluno);
    } else if (Array.isArray(usuario?.alunos)) {
      usuario.alunos.forEach((alu) => eachAlu(alu));
    }

    return thisList;
  }, [aluno, alunoTurma, usuario]);

  const renderFiltro = React.useMemo(() => {
    return showUsuario || usuario?.alunos?.length > 1 || alunoTurmas?.length > 1 || alunoDisciplinasTurmas?.length > 0;
  }, [alunoDisciplinasTurmas, alunoTurmas, showUsuario, usuario]);

  const { usuLC, aluLC, altLC, adtLC } = React.useMemo(() => {
    const usu = showUsuario;
    const alu = usuario?.alunos?.length > 1;
    const alt = alunoTurmas?.length > 1;
    const adt = alunoDisciplinasTurmas?.length > 1;

    let quant = 0;
    usu && quant++;
    alu && quant++;
    alt && quant++;
    adt && quant++;

    const checkQ = quant === 2 || quant === 3 || quant === 4;

    return {
      usuLC: quant === 2 || quant === 4 ? "l6" : "l12",
      aluLC: checkQ ? "l6" : "l12",
      altLC: checkQ ? "l6" : "l12",
      adtLC: checkQ ? "l6" : "l12",
    };
  }, [alunoDisciplinasTurmas, alunoTurmas, showUsuario, usuario]);

  return (
    <>
      {loaded && renderFiltro && (
        <Container {...rest}>
          <SistemaTopico>{title || "Filtro"}</SistemaTopico>
          <Materialize>
            <div className="row">
              {showUsuario && (
                <Label title="Aluno:" className={`col s12 m12 ${usuLC || "l12"}`}>
                  <SistemaSelect
                    // defaultValue={usuario?.cod}
                    value={usuario?.cod}
                    list={{ list: usuariosVinculados, value: "cod", label: "nome" }}
                    // setList={handleUsuarioChange}
                    onChange={(e) => {
                      let thisValue = e.currentTarget.value;
                      let thisFind = usuariosVinculados?.find((alu) => alu?.cod == thisValue);
                      handleUsuarioChange(thisFind);
                    }}
                  />
                </Label>
              )}
              {usuario?.alunos?.length > 1 && (
                <Label title="Escola:" className={`col s12 m12 ${aluLC || "l12"}`}>
                  <SistemaSelect
                    value={aluno?.cod || ""}
                    list={{
                      list: usuario?.alunos,
                      value: "cod",
                      label: "filial.nomeFantasia",
                    }}
                    onChange={(e) => {
                      let thisValue = e.currentTarget.value;
                      let thisFind = usuario?.alunos?.find((alu) => alu?.cod == thisValue);
                      handleAlunoChange(thisFind);
                    }}
                  />
                </Label>
              )}
              {alunoTurmas?.length > 1 && (
                <Label title="Turma:" className={`col s12 m12 ${altLC || "l12"}`}>
                  <SistemaSelect
                    firstOption={!specify && "Todas"}
                    value={alunoTurma?.cod || ""}
                    list={{
                      list: alunoTurmas,
                      value: "cod",
                      label: "turma.nome",
                    }}
                    onChange={(e) => {
                      let thisValue = e.currentTarget.value;
                      let thisFind = alunoTurmas?.find((alt) => alt?.cod == thisValue);
                      handleAlunoTurmaChange(thisFind);
                    }}
                  />
                </Label>
              )}
              {!hideAlunoDisciplinaTurma && alunoDisciplinasTurmas?.length > 0 && (
                <Label title="Disciplina:" className={`col s12 m12 ${adtLC || "l12"}`}>
                  <SistemaSelect
                    firstOption={!specify && "Todas"}
                    value={alunoDisciplinaTurma?.cod || ""}
                    list={{
                      list: alunoDisciplinasTurmas,
                      value: "cod",
                      label: "disciplinaTurma.disciplinaFilial.nome",
                    }}
                    onChange={(e) => {
                      let thisValue = e.currentTarget.value;
                      let thisFind = alunoDisciplinasTurmas?.find((adt) => adt?.cod == thisValue);
                      handleAlunoDisciplinaTurmaChange(thisFind);
                    }}
                  />
                </Label>
              )}
            </div>
          </Materialize>
          {children}
        </Container>
      )}
    </>
  );
}

export default PadraoAlunosFiltro;
