import React from "react";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";
import { Container } from "./styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import api from "~/services/api";
import { IsFunction } from "~/utils/CheckDataType";
import { Materialize } from "~/styles/materializeGrid";
import ThisSeriesList from "../ThisSeriesList";
import { useState } from "react";

function CreateEditContent({ series: seriesProp, mountedData }) {
  const [series, setSeries] = useState([]);
  function handleSubmit(data, options) {
    const { closeReload } = options || {};
    ShowConfirmacao(async () => {
      let response;
      if (mountedData) {
        response = await api.put("filial/periodoMatricula", {
          ...data,
          series,
          cod: mountedData?.cod,
        });
      } else {
        response = await api.post("filial/periodoMatricula", { ...data, series });
      }

      if (response?.status === 200 && IsFunction(closeReload)) {
        closeReload();
      }

      return response;
    });
  }

  return (
    <Container defaultStyle onSubmit={handleSubmit}>
      <Materialize>
        <div className="row">
          <Label title="NOME:">
            <SistemaInput name="nome" defaultValue={mountedData?.nome} />
          </Label>
          <Label title="INÍCIO:" className="col s12 m12 l6">
            <SistemaInput
              type="date"
              name="dataInicio"
              defaultValue={mountedData?.dataInicio}
            />
          </Label>
          <Label title="TÉRMINO:" className="col s12 m12 l6">
            <SistemaInput
              type="date"
              name="dataFim"
              defaultValue={mountedData?.dataFim}
            />
          </Label>
        </div>
      </Materialize>
      <ThisSeriesList
        series={seriesProp}
        defaultData={mountedData?.periodoMatriculaSeries}
        setter={setSeries}
      />
    </Container>
  );
}

export default CreateEditContent;
