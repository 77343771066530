import React from "react";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTableCell } from "~/pages/Sistema/componentes/SistemaList";
import { ThisTable } from "./styles";

function TableComponent({ setTabFilter, empresas }) {
  return (
    <>
      <TabsWrapper tabHeight={2.4}>
        <Tab materialIcon="check_circle_outline" onClick={() => setTabFilter({ ativo: true })}>
          Ativo
        </Tab>
        <Tab materialIcon="highlight_off" onClick={() => setTabFilter({ ativo: false })}>
          Desativo
        </Tab>
      </TabsWrapper>

      <SistemaComponenteInterno>
        <ThisTable>
          <SLTableCell name="nome" className="truncate" inputfilter>
            Nome
          </SLTableCell>
          <SLTableCell name="termino" isDateOnly className="truncate" inputfilter>
            Dt. Termino
          </SLTableCell>
          <SLTableCell name="valor" inputfilter>
            Valor
          </SLTableCell>
          <SLTableCell name="quantidadeAlunos" inputfilter>
            Qnt. Alunos
          </SLTableCell>
          <SLTableCell name="condicaoPagamento.nome" inputfilter>
            Cond. Pag.
          </SLTableCell>
          <SLTableCell name="empresa.razaoSocial" className="truncate">
            Razão Social
          </SLTableCell>
        </ThisTable>
      </SistemaComponenteInterno>
    </>
  );
}

export default TableComponent;
