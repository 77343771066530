import React, { useEffect } from "react";
import api from "~/services/api";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import Label from "~/componentes/CustomForm/Label";
import ShowConfirmacao from "~/utils/ShowConfirmacao";

import { Container } from "./styles";
import AcessosList from "~/componentes/AcessosList";
import { useState } from "react";

function EditContent({ cargos, mountedData, modulos }) {
  const [disabledData, setDisabledData] = useState([]);

  useEffect(() => {
    const findAccessos = cargos?.find?.(
      (e) => e.cod === mountedData?.cargoFilialCod
    );
    setDisabledData(findAccessos?.acessos || []);
  }, [cargos, mountedData]);

  function handleSubmit(editedData, options) {
    const { closeReload } = options || {};
    return ShowConfirmacao(
      async () => {
        let response = await api.put("filial/funcionarioFilial", {
          ...editedData,
          cod: mountedData.cod,
        });

        if (closeReload && response?.status === 200) {
          closeReload();
        }
        return response;
      },
      {
        errorModal: {
          defaultMessage: `Ocorreu um erro ao atualizar esse funcionário, por favor, tente novamente mais tarde.`,
        },
        message: `Tem certeza que deseja atualizar esse funcionário?`,
        successModal: `Funcionário atualizado com sucesso!`,
      }
    );
  }

  return (
    <Container defaultStyle onSubmit={handleSubmit}>
      <Label title="Cargos:">
        <SistemaSelect
          setList={(data) => setDisabledData(data?.acessos || [])}
          list={{ list: cargos, value: "cod", label: "nome" }}
          defaultValue={mountedData?.cargoFilialCod}
          name="cargoFilialCod"
          firstOption
        />
      </Label>

      <AcessosList
        defaultDisabledData={disabledData}
        defaultSelect={mountedData?.acessos}
        modulos={modulos}
      />
    </Container>
  );
}

export default EditContent;
