import React from "react";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import api from "~/services/api";
import { Form } from "~/componentes/CustomForm";
import Label from "~/componentes/CustomForm/Label";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import { CLModalButtons } from "../../..";

function StyledCustomListCreateUser({ email, setData }) {
  async function handleSubmit(data) {
    ShowConfirmacao(
      () =>
        api
          .post("usuario", { ...data, email, senha: "123123", confirmaSenha: "123123" })
          .then((response) => {
            setData(response?.data);
            return response;
          }),
      {
        successModal: "Usuário criado com sucesso!",
        message: "Tem certeza que deseja criar o acesso desse usuário?",
        errorModal: {
          defaultMessage:
            "Não foi possível criar o acesso desse usuário, por favor tente novamente.",
        },
      }
    );
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Label title="Nome">
        <SistemaInput name="nome" />
      </Label>
      <Label title="CPF">
        <SistemaInput name="cpf" />
      </Label>
      <Label title="Data de Nascimento">
        <SistemaInput type="Date" name="nascimento" />
      </Label>
      <CLModalButtons />
    </Form>
  );
}

export default StyledCustomListCreateUser;
