import React from "react";
import RespostaAudio from "./RespostaAudio";
import RespostaDissertativa from "./RespostaDissertativa";
import RespostaImagem from "./RespostaImagem";
import RespostaObjetiva from "./RespostaObjetiva";

// import { Container } from './styles';

function RespostaQuestao({ questao, respostaQuestao, showResp, mountedData }) {
  const Element = React.useMemo(() => {
    switch (questao?.questaoTipo?.chave) {
      case "DIS":
        return RespostaDissertativa;
      case "MUL":
        return RespostaObjetiva;
      case "AUD":
        return RespostaAudio;
      case "IMG":
        return RespostaImagem;
      default:
        return false;
    }
  }, [questao]);

  return Element ? (
    <Element {...{ questao, respostaQuestao, showResp, mountedData }} />
  ) : (
    <h4>"Questão não respondida"</h4>
  );
}

export default RespostaQuestao;
