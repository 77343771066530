import React, { useState } from "react";
import api from "~/services/api";
import { Container } from "./styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import handleFormData from "~/utils/handleFormData";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";

import CustomScrollBar from "~/componentes/CustomScrollBar";
import { Materialize } from "~/styles/materializeGrid";
import { SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import PreviewFileInput from "~/componentes/CustomForm/FileInput/styles/PreviewFileInput";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import SistemaTopico from "~/componentes/StyledComponents/Textos/SistemaTopico";
import ItensDiv from "./ItensDiv";
import dayjs from "dayjs";

function CreateContent({ turmas, tipos, DTM, setData, onSelect }) {
  const [itens, setItens] = useState([]);
  const [file, setFile] = useState();

  const [fileExist, setFileExist] = useState();
  const initialItemData = { questaoCod: 0, enunciado: "", gabarito: false };

  function handleSubmit(data, options) {
    const { closeReload } = options || {};
    console.log(data);
    if(typeof data.turmaId === 'string' || data.turmaId instanceof String) {
      data.turmaId = DTM.filter(d => d.turma.cod == parseInt(data.turmaId))[0].cod;
    }
    return ShowConfirmacao(
      async () => {
        let response;
        
        response = await api.post("professor/calendario", data);

        if (response?.status === 200) {
          closeReload && closeReload();
          api.get("/professor/calendario").then((response) => {
            setData(response.data);
            let now = dayjs();
            onSelect(now, response.data, true);
          });
        }

        return response;
      },
      {
        successModal: `Lembrete criado com sucesso.`,
        texto: `Deseja criar esse Lembrete?`,
        errorModal: {
          defaultMessage: `Não foi possível criar esse lembrete, por favor, tente novamente.`,
        },
      }
    );
  }
  return (
    <Container onSubmit={handleSubmit}>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          <div className="row">
            <SistemaTopico>Dados Lembrete</SistemaTopico>
            <Label title="TÍTULO:" className="col s12 m12 l6">
              <SistemaInput name="titulo" required />
            </Label>
            <Label title="TIPO:" className="col s12 m12 l3">
              <SistemaSelect
                name="tipo"
                firstOption
                list={{ list: tipos, value: "cod", label: "cod" }}
                required
              />
            </Label>
            <Label title="TURMA:" className="col s12 m12 l3">
              <SistemaSelect
                name="turmaId"
                firstOption
                list={{ list: turmas, value: "cod", label: "nome" }}
                required
              />
            </Label>
            <Label title="DATA:" className="col s12 m12 l3">
              <SistemaInput name="data" type="date" required />
            </Label>
            <Label title="DESCRIÇÃO:" className="col s12 m12 l8">
              <SistemaTextArea name="descricao" required />
            </Label>
          </div>
        </Materialize>
      </CustomScrollBar>
      <SLModalFooter />
    </Container>
  );
}

export default CreateContent;
