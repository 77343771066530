import React from "react";

import { Container } from "./styles";
import Label from "~/componentes/CustomForm/Label";
import api from "~/services/api";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import TurmasList from "../TurmasList";
import { Materialize } from "~/styles/materializeGrid";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";

function ShareContent({ mountedData }) {
  async function handleSubmit(data, options) {
    const { closeReload } = options || {};

    await ShowConfirmacao(
      async () => {
        let response;

        response = await api.post("professor/avaliacao/disponibilizar", {
          ...data,
          cod: mountedData.cod,
        });

        if (response?.status === 200) {
          closeReload && closeReload();
        }

        return response;
      },
      {
        successModal: `Avaliação ${
          mountedData ? "atualizada" : "criada"
        } com sucesso!`,
        texto: `Tem certeza que deseja ${
          mountedData ? "atualizar" : "criar"
        } essa avaliação?`,
        errorModal: {
          defaultMessage: `Ocorreu um erro ao ${
            mountedData ? "atualizar" : "criar"
          } essa avaliação, por favor, tente novamente.`,
        },
      }
    );
  }

  return (
    <>
      <Container defaultScroll defaultButtons onSubmit={handleSubmit}>
        <Materialize>
          <div className="row">
            <Label
              title="Nome"
              text={mountedData?.nome || ""}
              className="col s12 m12 l6"
            />
            <Label
              title="Nota Máx."
              text={mountedData?.notaMaxima || ""}
              className="col s12 m12 l2"
            />
            <Label
              title="Disciplina"
              text={mountedData?.disciplinaFilial?.nome || ""}
              className="col s12 m12 l4"
            />
            <Label title="Data inicio Disponivel:" className="col s12 m12 l3">
              <SistemaInput type="date" name="dataInicio" required />
            </Label>
            <Label title="Hora inicio Disponivel:" className="col s12 m12 l3">
              <SistemaInput type="time" name="horaInicio" required />
            </Label>
            <Label title="Data fim Disponivel:" className="col s12 m12 l3">
              <SistemaInput type="date" name="dataFim" required />
            </Label>
            <Label title="Hora fim Disponivel:" className="col s12 m12 l3">
              <SistemaInput type="time" name="horaFim" required />
            </Label>
            <TurmasList
              className="col s12 m12 l12"
              {...{
                startSelected: false,
                disciplinaFilialCod: mountedData?.disciplinaFilial?.cod,
              }}
            />
          </div>
        </Materialize>
      </Container>
    </>
  );
}

export default ShareContent;
