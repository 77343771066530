export default [
  {
    nome: "Minas Gerais",
    uf: "MG",
    regiaoCod: 3,
    cod: 13,
    cidades: [
      {
        nome: "Presidente Bernardes",
        uf: "MG",
        estadoCod: 13,
        cod: 2864,
      },
      {
        nome: "Abadia dos Dourados",
        uf: "MG",
        estadoCod: 13,
        cod: 2245,
      },
      {
        nome: "Abaeté",
        uf: "MG",
        estadoCod: 13,
        cod: 2246,
      },
      {
        nome: "Abre Campo",
        uf: "MG",
        estadoCod: 13,
        cod: 2247,
      },
      {
        nome: "Acaiaca",
        uf: "MG",
        estadoCod: 13,
        cod: 2248,
      },
      {
        nome: "Açucena",
        uf: "MG",
        estadoCod: 13,
        cod: 2249,
      },
      {
        nome: "Água Boa",
        uf: "MG",
        estadoCod: 13,
        cod: 2250,
      },
      {
        nome: "Água Comprida",
        uf: "MG",
        estadoCod: 13,
        cod: 2251,
      },
      {
        nome: "Aguanil",
        uf: "MG",
        estadoCod: 13,
        cod: 2252,
      },
      {
        nome: "Águas Formosas",
        uf: "MG",
        estadoCod: 13,
        cod: 2253,
      },
      {
        nome: "Águas Vermelhas",
        uf: "MG",
        estadoCod: 13,
        cod: 2254,
      },
      {
        nome: "Aimorés",
        uf: "MG",
        estadoCod: 13,
        cod: 2255,
      },
      {
        nome: "Aiuruoca",
        uf: "MG",
        estadoCod: 13,
        cod: 2256,
      },
      {
        nome: "Alagoa",
        uf: "MG",
        estadoCod: 13,
        cod: 2257,
      },
      {
        nome: "Albertina",
        uf: "MG",
        estadoCod: 13,
        cod: 2258,
      },
      {
        nome: "Além Paraíba",
        uf: "MG",
        estadoCod: 13,
        cod: 2259,
      },
      {
        nome: "Alfenas",
        uf: "MG",
        estadoCod: 13,
        cod: 2260,
      },
      {
        nome: "Alfredo Vasconcelos",
        uf: "MG",
        estadoCod: 13,
        cod: 2261,
      },
      {
        nome: "Almenara",
        uf: "MG",
        estadoCod: 13,
        cod: 2262,
      },
      {
        nome: "Alpercata",
        uf: "MG",
        estadoCod: 13,
        cod: 2263,
      },
      {
        nome: "Alpinópolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2264,
      },
      {
        nome: "Alterosa",
        uf: "MG",
        estadoCod: 13,
        cod: 2265,
      },
      {
        nome: "Alto Caparaó",
        uf: "MG",
        estadoCod: 13,
        cod: 2266,
      },
      {
        nome: "Alto Rio Doce",
        uf: "MG",
        estadoCod: 13,
        cod: 2267,
      },
      {
        nome: "Alvarenga",
        uf: "MG",
        estadoCod: 13,
        cod: 2268,
      },
      {
        nome: "Alvinópolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2269,
      },
      {
        nome: "Alvorada de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2270,
      },
      {
        nome: "Amparo do Serra",
        uf: "MG",
        estadoCod: 13,
        cod: 2271,
      },
      {
        nome: "Andradas",
        uf: "MG",
        estadoCod: 13,
        cod: 2272,
      },
      {
        nome: "Cachoeira de Pajeú",
        uf: "MG",
        estadoCod: 13,
        cod: 2273,
      },
      {
        nome: "Andrelândia",
        uf: "MG",
        estadoCod: 13,
        cod: 2274,
      },
      {
        nome: "Angelândia",
        uf: "MG",
        estadoCod: 13,
        cod: 2275,
      },
      {
        nome: "Antônio Carlos",
        uf: "MG",
        estadoCod: 13,
        cod: 2276,
      },
      {
        nome: "Antônio Dias",
        uf: "MG",
        estadoCod: 13,
        cod: 2277,
      },
      {
        nome: "Antônio Prado de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2278,
      },
      {
        nome: "Araçaí",
        uf: "MG",
        estadoCod: 13,
        cod: 2279,
      },
      {
        nome: "Aracitaba",
        uf: "MG",
        estadoCod: 13,
        cod: 2280,
      },
      {
        nome: "Araçuaí",
        uf: "MG",
        estadoCod: 13,
        cod: 2281,
      },
      {
        nome: "Araguari",
        uf: "MG",
        estadoCod: 13,
        cod: 2282,
      },
      {
        nome: "Arantina",
        uf: "MG",
        estadoCod: 13,
        cod: 2283,
      },
      {
        nome: "Araponga",
        uf: "MG",
        estadoCod: 13,
        cod: 2284,
      },
      {
        nome: "Araporã",
        uf: "MG",
        estadoCod: 13,
        cod: 2285,
      },
      {
        nome: "Arapuá",
        uf: "MG",
        estadoCod: 13,
        cod: 2286,
      },
      {
        nome: "Araújos",
        uf: "MG",
        estadoCod: 13,
        cod: 2287,
      },
      {
        nome: "Araxá",
        uf: "MG",
        estadoCod: 13,
        cod: 2288,
      },
      {
        nome: "Arceburgo",
        uf: "MG",
        estadoCod: 13,
        cod: 2289,
      },
      {
        nome: "Arcos",
        uf: "MG",
        estadoCod: 13,
        cod: 2290,
      },
      {
        nome: "Areado",
        uf: "MG",
        estadoCod: 13,
        cod: 2291,
      },
      {
        nome: "Argirita",
        uf: "MG",
        estadoCod: 13,
        cod: 2292,
      },
      {
        nome: "Aricanduva",
        uf: "MG",
        estadoCod: 13,
        cod: 2293,
      },
      {
        nome: "Arinos",
        uf: "MG",
        estadoCod: 13,
        cod: 2294,
      },
      {
        nome: "Astolfo Dutra",
        uf: "MG",
        estadoCod: 13,
        cod: 2295,
      },
      {
        nome: "Ataléia",
        uf: "MG",
        estadoCod: 13,
        cod: 2296,
      },
      {
        nome: "Augusto de Lima",
        uf: "MG",
        estadoCod: 13,
        cod: 2297,
      },
      {
        nome: "Baependi",
        uf: "MG",
        estadoCod: 13,
        cod: 2298,
      },
      {
        nome: "Baldim",
        uf: "MG",
        estadoCod: 13,
        cod: 2299,
      },
      {
        nome: "Bambuí",
        uf: "MG",
        estadoCod: 13,
        cod: 2300,
      },
      {
        nome: "Bandeira",
        uf: "MG",
        estadoCod: 13,
        cod: 2301,
      },
      {
        nome: "Bandeira do Sul",
        uf: "MG",
        estadoCod: 13,
        cod: 2302,
      },
      {
        nome: "Barão de Cocais",
        uf: "MG",
        estadoCod: 13,
        cod: 2303,
      },
      {
        nome: "Barão de Monte Alto",
        uf: "MG",
        estadoCod: 13,
        cod: 2304,
      },
      {
        nome: "Barbacena",
        uf: "MG",
        estadoCod: 13,
        cod: 2305,
      },
      {
        nome: "Barra Longa",
        uf: "MG",
        estadoCod: 13,
        cod: 2306,
      },
      {
        nome: "Barroso",
        uf: "MG",
        estadoCod: 13,
        cod: 2307,
      },
      {
        nome: "Bela Vista de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2308,
      },
      {
        nome: "Belmiro Braga",
        uf: "MG",
        estadoCod: 13,
        cod: 2309,
      },
      {
        nome: "Belo Horizonte",
        uf: "MG",
        estadoCod: 13,
        cod: 2310,
      },
      {
        nome: "Belo Oriente",
        uf: "MG",
        estadoCod: 13,
        cod: 2311,
      },
      {
        nome: "Belo Vale",
        uf: "MG",
        estadoCod: 13,
        cod: 2312,
      },
      {
        nome: "Berilo",
        uf: "MG",
        estadoCod: 13,
        cod: 2313,
      },
      {
        nome: "Bertópolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2314,
      },
      {
        nome: "Berizal",
        uf: "MG",
        estadoCod: 13,
        cod: 2315,
      },
      {
        nome: "Betim",
        uf: "MG",
        estadoCod: 13,
        cod: 2316,
      },
      {
        nome: "Bias Fortes",
        uf: "MG",
        estadoCod: 13,
        cod: 2317,
      },
      {
        nome: "Bicas",
        uf: "MG",
        estadoCod: 13,
        cod: 2318,
      },
      {
        nome: "Biquinhas",
        uf: "MG",
        estadoCod: 13,
        cod: 2319,
      },
      {
        nome: "Boa Esperança",
        uf: "MG",
        estadoCod: 13,
        cod: 2320,
      },
      {
        nome: "Bocaina de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2321,
      },
      {
        nome: "Bocaiúva",
        uf: "MG",
        estadoCod: 13,
        cod: 2322,
      },
      {
        nome: "Bom Despacho",
        uf: "MG",
        estadoCod: 13,
        cod: 2323,
      },
      {
        nome: "Bom Jardim de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2324,
      },
      {
        nome: "Bom Jesus da Penha",
        uf: "MG",
        estadoCod: 13,
        cod: 2325,
      },
      {
        nome: "Bom Jesus do Amparo",
        uf: "MG",
        estadoCod: 13,
        cod: 2326,
      },
      {
        nome: "Bom Jesus do Galho",
        uf: "MG",
        estadoCod: 13,
        cod: 2327,
      },
      {
        nome: "Bom Repouso",
        uf: "MG",
        estadoCod: 13,
        cod: 2328,
      },
      {
        nome: "Bom Sucesso",
        uf: "MG",
        estadoCod: 13,
        cod: 2329,
      },
      {
        nome: "Bonfim",
        uf: "MG",
        estadoCod: 13,
        cod: 2330,
      },
      {
        nome: "Bonfinópolis de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2331,
      },
      {
        nome: "Bonito de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2332,
      },
      {
        nome: "Borda da Mata",
        uf: "MG",
        estadoCod: 13,
        cod: 2333,
      },
      {
        nome: "Botelhos",
        uf: "MG",
        estadoCod: 13,
        cod: 2334,
      },
      {
        nome: "Botumirim",
        uf: "MG",
        estadoCod: 13,
        cod: 2335,
      },
      {
        nome: "Brasilândia de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2336,
      },
      {
        nome: "Brasília de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2337,
      },
      {
        nome: "Brás Pires",
        uf: "MG",
        estadoCod: 13,
        cod: 2338,
      },
      {
        nome: "Braúnas",
        uf: "MG",
        estadoCod: 13,
        cod: 2339,
      },
      {
        nome: "Brazópolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2340,
      },
      {
        nome: "Brumadinho",
        uf: "MG",
        estadoCod: 13,
        cod: 2341,
      },
      {
        nome: "Bueno Brandão",
        uf: "MG",
        estadoCod: 13,
        cod: 2342,
      },
      {
        nome: "Buenópolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2343,
      },
      {
        nome: "Bugre",
        uf: "MG",
        estadoCod: 13,
        cod: 2344,
      },
      {
        nome: "Buritis",
        uf: "MG",
        estadoCod: 13,
        cod: 2345,
      },
      {
        nome: "Buritizeiro",
        uf: "MG",
        estadoCod: 13,
        cod: 2346,
      },
      {
        nome: "Cabeceira Grande",
        uf: "MG",
        estadoCod: 13,
        cod: 2347,
      },
      {
        nome: "Cabo Verde",
        uf: "MG",
        estadoCod: 13,
        cod: 2348,
      },
      {
        nome: "Cachoeira da Prata",
        uf: "MG",
        estadoCod: 13,
        cod: 2349,
      },
      {
        nome: "Cachoeira de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2350,
      },
      {
        nome: "Cachoeira Dourada",
        uf: "MG",
        estadoCod: 13,
        cod: 2351,
      },
      {
        nome: "Caetanópolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2352,
      },
      {
        nome: "Caeté",
        uf: "MG",
        estadoCod: 13,
        cod: 2353,
      },
      {
        nome: "Caiana",
        uf: "MG",
        estadoCod: 13,
        cod: 2354,
      },
      {
        nome: "Cajuri",
        uf: "MG",
        estadoCod: 13,
        cod: 2355,
      },
      {
        nome: "Caldas",
        uf: "MG",
        estadoCod: 13,
        cod: 2356,
      },
      {
        nome: "Camacho",
        uf: "MG",
        estadoCod: 13,
        cod: 2357,
      },
      {
        nome: "Camanducaia",
        uf: "MG",
        estadoCod: 13,
        cod: 2358,
      },
      {
        nome: "Cambuí",
        uf: "MG",
        estadoCod: 13,
        cod: 2359,
      },
      {
        nome: "Cambuquira",
        uf: "MG",
        estadoCod: 13,
        cod: 2360,
      },
      {
        nome: "Campanário",
        uf: "MG",
        estadoCod: 13,
        cod: 2361,
      },
      {
        nome: "Campanha",
        uf: "MG",
        estadoCod: 13,
        cod: 2362,
      },
      {
        nome: "Campestre",
        uf: "MG",
        estadoCod: 13,
        cod: 2363,
      },
      {
        nome: "Campina Verde",
        uf: "MG",
        estadoCod: 13,
        cod: 2364,
      },
      {
        nome: "Campo Azul",
        uf: "MG",
        estadoCod: 13,
        cod: 2365,
      },
      {
        nome: "Campo Belo",
        uf: "MG",
        estadoCod: 13,
        cod: 2366,
      },
      {
        nome: "Campo do Meio",
        uf: "MG",
        estadoCod: 13,
        cod: 2367,
      },
      {
        nome: "Campo Florido",
        uf: "MG",
        estadoCod: 13,
        cod: 2368,
      },
      {
        nome: "Campos Altos",
        uf: "MG",
        estadoCod: 13,
        cod: 2369,
      },
      {
        nome: "Campos Gerais",
        uf: "MG",
        estadoCod: 13,
        cod: 2370,
      },
      {
        nome: "Canaã",
        uf: "MG",
        estadoCod: 13,
        cod: 2371,
      },
      {
        nome: "Canápolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2372,
      },
      {
        nome: "Cana Verde",
        uf: "MG",
        estadoCod: 13,
        cod: 2373,
      },
      {
        nome: "Candeias",
        uf: "MG",
        estadoCod: 13,
        cod: 2374,
      },
      {
        nome: "Cantagalo",
        uf: "MG",
        estadoCod: 13,
        cod: 2375,
      },
      {
        nome: "Caparaó",
        uf: "MG",
        estadoCod: 13,
        cod: 2376,
      },
      {
        nome: "Capela Nova",
        uf: "MG",
        estadoCod: 13,
        cod: 2377,
      },
      {
        nome: "Capelinha",
        uf: "MG",
        estadoCod: 13,
        cod: 2378,
      },
      {
        nome: "Capetinga",
        uf: "MG",
        estadoCod: 13,
        cod: 2379,
      },
      {
        nome: "Capim Branco",
        uf: "MG",
        estadoCod: 13,
        cod: 2380,
      },
      {
        nome: "Capinópolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2381,
      },
      {
        nome: "Capitão Andrade",
        uf: "MG",
        estadoCod: 13,
        cod: 2382,
      },
      {
        nome: "Capitão Enéas",
        uf: "MG",
        estadoCod: 13,
        cod: 2383,
      },
      {
        nome: "Capitólio",
        uf: "MG",
        estadoCod: 13,
        cod: 2384,
      },
      {
        nome: "Caputira",
        uf: "MG",
        estadoCod: 13,
        cod: 2385,
      },
      {
        nome: "Caraí",
        uf: "MG",
        estadoCod: 13,
        cod: 2386,
      },
      {
        nome: "Caranaíba",
        uf: "MG",
        estadoCod: 13,
        cod: 2387,
      },
      {
        nome: "Carandaí",
        uf: "MG",
        estadoCod: 13,
        cod: 2388,
      },
      {
        nome: "Carangola",
        uf: "MG",
        estadoCod: 13,
        cod: 2389,
      },
      {
        nome: "Caratinga",
        uf: "MG",
        estadoCod: 13,
        cod: 2390,
      },
      {
        nome: "Carbonita",
        uf: "MG",
        estadoCod: 13,
        cod: 2391,
      },
      {
        nome: "Careaçu",
        uf: "MG",
        estadoCod: 13,
        cod: 2392,
      },
      {
        nome: "Carlos Chagas",
        uf: "MG",
        estadoCod: 13,
        cod: 2393,
      },
      {
        nome: "Carmésia",
        uf: "MG",
        estadoCod: 13,
        cod: 2394,
      },
      {
        nome: "Carmo da Cachoeira",
        uf: "MG",
        estadoCod: 13,
        cod: 2395,
      },
      {
        nome: "Carmo da Mata",
        uf: "MG",
        estadoCod: 13,
        cod: 2396,
      },
      {
        nome: "Carmo de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2397,
      },
      {
        nome: "Carmo do Cajuru",
        uf: "MG",
        estadoCod: 13,
        cod: 2398,
      },
      {
        nome: "Carmo do Paranaíba",
        uf: "MG",
        estadoCod: 13,
        cod: 2399,
      },
      {
        nome: "Carmo do Rio Claro",
        uf: "MG",
        estadoCod: 13,
        cod: 2400,
      },
      {
        nome: "Carmópolis de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2401,
      },
      {
        nome: "Carneirinho",
        uf: "MG",
        estadoCod: 13,
        cod: 2402,
      },
      {
        nome: "Carrancas",
        uf: "MG",
        estadoCod: 13,
        cod: 2403,
      },
      {
        nome: "Carvalhópolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2404,
      },
      {
        nome: "Carvalhos",
        uf: "MG",
        estadoCod: 13,
        cod: 2405,
      },
      {
        nome: "Casa Grande",
        uf: "MG",
        estadoCod: 13,
        cod: 2406,
      },
      {
        nome: "Cascalho Rico",
        uf: "MG",
        estadoCod: 13,
        cod: 2407,
      },
      {
        nome: "Cássia",
        uf: "MG",
        estadoCod: 13,
        cod: 2408,
      },
      {
        nome: "Conceição da Barra de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2409,
      },
      {
        nome: "Cataguases",
        uf: "MG",
        estadoCod: 13,
        cod: 2410,
      },
      {
        nome: "Catas Altas",
        uf: "MG",
        estadoCod: 13,
        cod: 2411,
      },
      {
        nome: "Catas Altas da Noruega",
        uf: "MG",
        estadoCod: 13,
        cod: 2412,
      },
      {
        nome: "Catuji",
        uf: "MG",
        estadoCod: 13,
        cod: 2413,
      },
      {
        nome: "Catuti",
        uf: "MG",
        estadoCod: 13,
        cod: 2414,
      },
      {
        nome: "Caxambu",
        uf: "MG",
        estadoCod: 13,
        cod: 2415,
      },
      {
        nome: "Cedro do Abaeté",
        uf: "MG",
        estadoCod: 13,
        cod: 2416,
      },
      {
        nome: "Central de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2417,
      },
      {
        nome: "Centralina",
        uf: "MG",
        estadoCod: 13,
        cod: 2418,
      },
      {
        nome: "Chácara",
        uf: "MG",
        estadoCod: 13,
        cod: 2419,
      },
      {
        nome: "Chalé",
        uf: "MG",
        estadoCod: 13,
        cod: 2420,
      },
      {
        nome: "Chapada do Norte",
        uf: "MG",
        estadoCod: 13,
        cod: 2421,
      },
      {
        nome: "Chapada Gaúcha",
        uf: "MG",
        estadoCod: 13,
        cod: 2422,
      },
      {
        nome: "Chiador",
        uf: "MG",
        estadoCod: 13,
        cod: 2423,
      },
      {
        nome: "Cipotânea",
        uf: "MG",
        estadoCod: 13,
        cod: 2424,
      },
      {
        nome: "Claraval",
        uf: "MG",
        estadoCod: 13,
        cod: 2425,
      },
      {
        nome: "Claro dos Poções",
        uf: "MG",
        estadoCod: 13,
        cod: 2426,
      },
      {
        nome: "Cláudio",
        uf: "MG",
        estadoCod: 13,
        cod: 2427,
      },
      {
        nome: "Coimbra",
        uf: "MG",
        estadoCod: 13,
        cod: 2428,
      },
      {
        nome: "Coluna",
        uf: "MG",
        estadoCod: 13,
        cod: 2429,
      },
      {
        nome: "Comendador Gomes",
        uf: "MG",
        estadoCod: 13,
        cod: 2430,
      },
      {
        nome: "Comercinho",
        uf: "MG",
        estadoCod: 13,
        cod: 2431,
      },
      {
        nome: "Conceição da Aparecida",
        uf: "MG",
        estadoCod: 13,
        cod: 2432,
      },
      {
        nome: "Conceição das Pedras",
        uf: "MG",
        estadoCod: 13,
        cod: 2433,
      },
      {
        nome: "Conceição das Alagoas",
        uf: "MG",
        estadoCod: 13,
        cod: 2434,
      },
      {
        nome: "Conceição de Ipanema",
        uf: "MG",
        estadoCod: 13,
        cod: 2435,
      },
      {
        nome: "Conceição do Mato Dentro",
        uf: "MG",
        estadoCod: 13,
        cod: 2436,
      },
      {
        nome: "Conceição do Pará",
        uf: "MG",
        estadoCod: 13,
        cod: 2437,
      },
      {
        nome: "Conceição do Rio Verde",
        uf: "MG",
        estadoCod: 13,
        cod: 2438,
      },
      {
        nome: "Conceição dos Ouros",
        uf: "MG",
        estadoCod: 13,
        cod: 2439,
      },
      {
        nome: "Cônego Marinho",
        uf: "MG",
        estadoCod: 13,
        cod: 2440,
      },
      {
        nome: "Confins",
        uf: "MG",
        estadoCod: 13,
        cod: 2441,
      },
      {
        nome: "Congonhal",
        uf: "MG",
        estadoCod: 13,
        cod: 2442,
      },
      {
        nome: "Congonhas",
        uf: "MG",
        estadoCod: 13,
        cod: 2443,
      },
      {
        nome: "Congonhas do Norte",
        uf: "MG",
        estadoCod: 13,
        cod: 2444,
      },
      {
        nome: "Conquista",
        uf: "MG",
        estadoCod: 13,
        cod: 2445,
      },
      {
        nome: "Conselheiro Lafaiete",
        uf: "MG",
        estadoCod: 13,
        cod: 2446,
      },
      {
        nome: "Conselheiro Pena",
        uf: "MG",
        estadoCod: 13,
        cod: 2447,
      },
      {
        nome: "Consolação",
        uf: "MG",
        estadoCod: 13,
        cod: 2448,
      },
      {
        nome: "Contagem",
        uf: "MG",
        estadoCod: 13,
        cod: 2449,
      },
      {
        nome: "Coqueiral",
        uf: "MG",
        estadoCod: 13,
        cod: 2450,
      },
      {
        nome: "Coração de Jesus",
        uf: "MG",
        estadoCod: 13,
        cod: 2451,
      },
      {
        nome: "Cordisburgo",
        uf: "MG",
        estadoCod: 13,
        cod: 2452,
      },
      {
        nome: "Cordislândia",
        uf: "MG",
        estadoCod: 13,
        cod: 2453,
      },
      {
        nome: "Corinto",
        uf: "MG",
        estadoCod: 13,
        cod: 2454,
      },
      {
        nome: "Coroaci",
        uf: "MG",
        estadoCod: 13,
        cod: 2455,
      },
      {
        nome: "Coromandel",
        uf: "MG",
        estadoCod: 13,
        cod: 2456,
      },
      {
        nome: "Coronel Fabriciano",
        uf: "MG",
        estadoCod: 13,
        cod: 2457,
      },
      {
        nome: "Coronel Murta",
        uf: "MG",
        estadoCod: 13,
        cod: 2458,
      },
      {
        nome: "Coronel Pacheco",
        uf: "MG",
        estadoCod: 13,
        cod: 2459,
      },
      {
        nome: "Coronel Xavier Chaves",
        uf: "MG",
        estadoCod: 13,
        cod: 2460,
      },
      {
        nome: "Córrego Danta",
        uf: "MG",
        estadoCod: 13,
        cod: 2461,
      },
      {
        nome: "Córrego do Bom Jesus",
        uf: "MG",
        estadoCod: 13,
        cod: 2462,
      },
      {
        nome: "Córrego Fundo",
        uf: "MG",
        estadoCod: 13,
        cod: 2463,
      },
      {
        nome: "Córrego Novo",
        uf: "MG",
        estadoCod: 13,
        cod: 2464,
      },
      {
        nome: "Couto de Magalhães de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2465,
      },
      {
        nome: "Crisólita",
        uf: "MG",
        estadoCod: 13,
        cod: 2466,
      },
      {
        nome: "Cristais",
        uf: "MG",
        estadoCod: 13,
        cod: 2467,
      },
      {
        nome: "Cristália",
        uf: "MG",
        estadoCod: 13,
        cod: 2468,
      },
      {
        nome: "Cristiano Otoni",
        uf: "MG",
        estadoCod: 13,
        cod: 2469,
      },
      {
        nome: "Cristina",
        uf: "MG",
        estadoCod: 13,
        cod: 2470,
      },
      {
        nome: "Crucilândia",
        uf: "MG",
        estadoCod: 13,
        cod: 2471,
      },
      {
        nome: "Cruzeiro da Fortaleza",
        uf: "MG",
        estadoCod: 13,
        cod: 2472,
      },
      {
        nome: "Cruzília",
        uf: "MG",
        estadoCod: 13,
        cod: 2473,
      },
      {
        nome: "Cuparaque",
        uf: "MG",
        estadoCod: 13,
        cod: 2474,
      },
      {
        nome: "Curral de Dentro",
        uf: "MG",
        estadoCod: 13,
        cod: 2475,
      },
      {
        nome: "Curvelo",
        uf: "MG",
        estadoCod: 13,
        cod: 2476,
      },
      {
        nome: "Datas",
        uf: "MG",
        estadoCod: 13,
        cod: 2477,
      },
      {
        nome: "Delfim Moreira",
        uf: "MG",
        estadoCod: 13,
        cod: 2478,
      },
      {
        nome: "Delfinópolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2479,
      },
      {
        nome: "Delta",
        uf: "MG",
        estadoCod: 13,
        cod: 2480,
      },
      {
        nome: "Descoberto",
        uf: "MG",
        estadoCod: 13,
        cod: 2481,
      },
      {
        nome: "Desterro de Entre Rios",
        uf: "MG",
        estadoCod: 13,
        cod: 2482,
      },
      {
        nome: "Desterro do Melo",
        uf: "MG",
        estadoCod: 13,
        cod: 2483,
      },
      {
        nome: "Diamantina",
        uf: "MG",
        estadoCod: 13,
        cod: 2484,
      },
      {
        nome: "Diogo de Vasconcelos",
        uf: "MG",
        estadoCod: 13,
        cod: 2485,
      },
      {
        nome: "Dionísio",
        uf: "MG",
        estadoCod: 13,
        cod: 2486,
      },
      {
        nome: "Divinésia",
        uf: "MG",
        estadoCod: 13,
        cod: 2487,
      },
      {
        nome: "Divino",
        uf: "MG",
        estadoCod: 13,
        cod: 2488,
      },
      {
        nome: "Divino das Laranjeiras",
        uf: "MG",
        estadoCod: 13,
        cod: 2489,
      },
      {
        nome: "Divinolândia de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2490,
      },
      {
        nome: "Divinópolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2491,
      },
      {
        nome: "Divisa Alegre",
        uf: "MG",
        estadoCod: 13,
        cod: 2492,
      },
      {
        nome: "Divisa Nova",
        uf: "MG",
        estadoCod: 13,
        cod: 2493,
      },
      {
        nome: "Divisópolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2494,
      },
      {
        nome: "Dom Bosco",
        uf: "MG",
        estadoCod: 13,
        cod: 2495,
      },
      {
        nome: "Dom Cavati",
        uf: "MG",
        estadoCod: 13,
        cod: 2496,
      },
      {
        nome: "Dom Joaquim",
        uf: "MG",
        estadoCod: 13,
        cod: 2497,
      },
      {
        nome: "Dom Silvério",
        uf: "MG",
        estadoCod: 13,
        cod: 2498,
      },
      {
        nome: "Dom Viçoso",
        uf: "MG",
        estadoCod: 13,
        cod: 2499,
      },
      {
        nome: "Dona Eusébia",
        uf: "MG",
        estadoCod: 13,
        cod: 2500,
      },
      {
        nome: "Dores de Campos",
        uf: "MG",
        estadoCod: 13,
        cod: 2501,
      },
      {
        nome: "Dores de Guanhães",
        uf: "MG",
        estadoCod: 13,
        cod: 2502,
      },
      {
        nome: "Dores do Indaiá",
        uf: "MG",
        estadoCod: 13,
        cod: 2503,
      },
      {
        nome: "Dores do Turvo",
        uf: "MG",
        estadoCod: 13,
        cod: 2504,
      },
      {
        nome: "Doresópolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2505,
      },
      {
        nome: "Douradoquara",
        uf: "MG",
        estadoCod: 13,
        cod: 2506,
      },
      {
        nome: "Durandé",
        uf: "MG",
        estadoCod: 13,
        cod: 2507,
      },
      {
        nome: "Elói Mendes",
        uf: "MG",
        estadoCod: 13,
        cod: 2508,
      },
      {
        nome: "Engenheiro Caldas",
        uf: "MG",
        estadoCod: 13,
        cod: 2509,
      },
      {
        nome: "Engenheiro Navarro",
        uf: "MG",
        estadoCod: 13,
        cod: 2510,
      },
      {
        nome: "Entre Folhas",
        uf: "MG",
        estadoCod: 13,
        cod: 2511,
      },
      {
        nome: "Entre Rios de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2512,
      },
      {
        nome: "Ervália",
        uf: "MG",
        estadoCod: 13,
        cod: 2513,
      },
      {
        nome: "Esmeraldas",
        uf: "MG",
        estadoCod: 13,
        cod: 2514,
      },
      {
        nome: "Espera Feliz",
        uf: "MG",
        estadoCod: 13,
        cod: 2515,
      },
      {
        nome: "Espinosa",
        uf: "MG",
        estadoCod: 13,
        cod: 2516,
      },
      {
        nome: "Espírito Santo do Dourado",
        uf: "MG",
        estadoCod: 13,
        cod: 2517,
      },
      {
        nome: "Estiva",
        uf: "MG",
        estadoCod: 13,
        cod: 2518,
      },
      {
        nome: "Estrela Dalva",
        uf: "MG",
        estadoCod: 13,
        cod: 2519,
      },
      {
        nome: "Estrela do Indaiá",
        uf: "MG",
        estadoCod: 13,
        cod: 2520,
      },
      {
        nome: "Estrela do Sul",
        uf: "MG",
        estadoCod: 13,
        cod: 2521,
      },
      {
        nome: "Eugenópolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2522,
      },
      {
        nome: "Ewbank da Câmara",
        uf: "MG",
        estadoCod: 13,
        cod: 2523,
      },
      {
        nome: "Extrema",
        uf: "MG",
        estadoCod: 13,
        cod: 2524,
      },
      {
        nome: "Fama",
        uf: "MG",
        estadoCod: 13,
        cod: 2525,
      },
      {
        nome: "Faria Lemos",
        uf: "MG",
        estadoCod: 13,
        cod: 2526,
      },
      {
        nome: "Felício dos Santos",
        uf: "MG",
        estadoCod: 13,
        cod: 2527,
      },
      {
        nome: "São Gonçalo do Rio Preto",
        uf: "MG",
        estadoCod: 13,
        cod: 2528,
      },
      {
        nome: "Felisburgo",
        uf: "MG",
        estadoCod: 13,
        cod: 2529,
      },
      {
        nome: "Felixlândia",
        uf: "MG",
        estadoCod: 13,
        cod: 2530,
      },
      {
        nome: "Fernandes Tourinho",
        uf: "MG",
        estadoCod: 13,
        cod: 2531,
      },
      {
        nome: "Ferros",
        uf: "MG",
        estadoCod: 13,
        cod: 2532,
      },
      {
        nome: "Fervedouro",
        uf: "MG",
        estadoCod: 13,
        cod: 2533,
      },
      {
        nome: "Florestal",
        uf: "MG",
        estadoCod: 13,
        cod: 2534,
      },
      {
        nome: "Formiga",
        uf: "MG",
        estadoCod: 13,
        cod: 2535,
      },
      {
        nome: "Formoso",
        uf: "MG",
        estadoCod: 13,
        cod: 2536,
      },
      {
        nome: "Fortaleza de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2537,
      },
      {
        nome: "Fortuna de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2538,
      },
      {
        nome: "Francisco Badaró",
        uf: "MG",
        estadoCod: 13,
        cod: 2539,
      },
      {
        nome: "Francisco Dumont",
        uf: "MG",
        estadoCod: 13,
        cod: 2540,
      },
      {
        nome: "Francisco Sá",
        uf: "MG",
        estadoCod: 13,
        cod: 2541,
      },
      {
        nome: "Franciscópolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2542,
      },
      {
        nome: "Frei Gaspar",
        uf: "MG",
        estadoCod: 13,
        cod: 2543,
      },
      {
        nome: "Frei Inocêncio",
        uf: "MG",
        estadoCod: 13,
        cod: 2544,
      },
      {
        nome: "Frei Lagonegro",
        uf: "MG",
        estadoCod: 13,
        cod: 2545,
      },
      {
        nome: "Fronteira",
        uf: "MG",
        estadoCod: 13,
        cod: 2546,
      },
      {
        nome: "Fronteira dos Vales",
        uf: "MG",
        estadoCod: 13,
        cod: 2547,
      },
      {
        nome: "Fruta de Leite",
        uf: "MG",
        estadoCod: 13,
        cod: 2548,
      },
      {
        nome: "Frutal",
        uf: "MG",
        estadoCod: 13,
        cod: 2549,
      },
      {
        nome: "Funilândia",
        uf: "MG",
        estadoCod: 13,
        cod: 2550,
      },
      {
        nome: "Galiléia",
        uf: "MG",
        estadoCod: 13,
        cod: 2551,
      },
      {
        nome: "Gameleiras",
        uf: "MG",
        estadoCod: 13,
        cod: 2552,
      },
      {
        nome: "Glaucilândia",
        uf: "MG",
        estadoCod: 13,
        cod: 2553,
      },
      {
        nome: "Goiabeira",
        uf: "MG",
        estadoCod: 13,
        cod: 2554,
      },
      {
        nome: "Goianá",
        uf: "MG",
        estadoCod: 13,
        cod: 2555,
      },
      {
        nome: "Gonçalves",
        uf: "MG",
        estadoCod: 13,
        cod: 2556,
      },
      {
        nome: "Gonzaga",
        uf: "MG",
        estadoCod: 13,
        cod: 2557,
      },
      {
        nome: "Gouveia",
        uf: "MG",
        estadoCod: 13,
        cod: 2558,
      },
      {
        nome: "Governador Valadares",
        uf: "MG",
        estadoCod: 13,
        cod: 2559,
      },
      {
        nome: "Grão Mogol",
        uf: "MG",
        estadoCod: 13,
        cod: 2560,
      },
      {
        nome: "Grupiara",
        uf: "MG",
        estadoCod: 13,
        cod: 2561,
      },
      {
        nome: "Guanhães",
        uf: "MG",
        estadoCod: 13,
        cod: 2562,
      },
      {
        nome: "Guapé",
        uf: "MG",
        estadoCod: 13,
        cod: 2563,
      },
      {
        nome: "Guaraciaba",
        uf: "MG",
        estadoCod: 13,
        cod: 2564,
      },
      {
        nome: "Guaraciama",
        uf: "MG",
        estadoCod: 13,
        cod: 2565,
      },
      {
        nome: "Guaranésia",
        uf: "MG",
        estadoCod: 13,
        cod: 2566,
      },
      {
        nome: "Guarani",
        uf: "MG",
        estadoCod: 13,
        cod: 2567,
      },
      {
        nome: "Guarará",
        uf: "MG",
        estadoCod: 13,
        cod: 2568,
      },
      {
        nome: "Guarda-Mor",
        uf: "MG",
        estadoCod: 13,
        cod: 2569,
      },
      {
        nome: "Guaxupé",
        uf: "MG",
        estadoCod: 13,
        cod: 2570,
      },
      {
        nome: "Guidoval",
        uf: "MG",
        estadoCod: 13,
        cod: 2571,
      },
      {
        nome: "Guimarânia",
        uf: "MG",
        estadoCod: 13,
        cod: 2572,
      },
      {
        nome: "Guiricema",
        uf: "MG",
        estadoCod: 13,
        cod: 2573,
      },
      {
        nome: "Gurinhatã",
        uf: "MG",
        estadoCod: 13,
        cod: 2574,
      },
      {
        nome: "Heliodora",
        uf: "MG",
        estadoCod: 13,
        cod: 2575,
      },
      {
        nome: "Iapu",
        uf: "MG",
        estadoCod: 13,
        cod: 2576,
      },
      {
        nome: "Ibertioga",
        uf: "MG",
        estadoCod: 13,
        cod: 2577,
      },
      {
        nome: "Ibiá",
        uf: "MG",
        estadoCod: 13,
        cod: 2578,
      },
      {
        nome: "Ibiaí",
        uf: "MG",
        estadoCod: 13,
        cod: 2579,
      },
      {
        nome: "Ibiracatu",
        uf: "MG",
        estadoCod: 13,
        cod: 2580,
      },
      {
        nome: "Ibiraci",
        uf: "MG",
        estadoCod: 13,
        cod: 2581,
      },
      {
        nome: "Ibirité",
        uf: "MG",
        estadoCod: 13,
        cod: 2582,
      },
      {
        nome: "Ibitiúra de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2583,
      },
      {
        nome: "Ibituruna",
        uf: "MG",
        estadoCod: 13,
        cod: 2584,
      },
      {
        nome: "Icaraí de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2585,
      },
      {
        nome: "Igarapé",
        uf: "MG",
        estadoCod: 13,
        cod: 2586,
      },
      {
        nome: "Igaratinga",
        uf: "MG",
        estadoCod: 13,
        cod: 2587,
      },
      {
        nome: "Iguatama",
        uf: "MG",
        estadoCod: 13,
        cod: 2588,
      },
      {
        nome: "Ijaci",
        uf: "MG",
        estadoCod: 13,
        cod: 2589,
      },
      {
        nome: "Ilicínea",
        uf: "MG",
        estadoCod: 13,
        cod: 2590,
      },
      {
        nome: "Imbé de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2591,
      },
      {
        nome: "Inconfidentes",
        uf: "MG",
        estadoCod: 13,
        cod: 2592,
      },
      {
        nome: "Indaiabira",
        uf: "MG",
        estadoCod: 13,
        cod: 2593,
      },
      {
        nome: "Indianópolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2594,
      },
      {
        nome: "Ingaí",
        uf: "MG",
        estadoCod: 13,
        cod: 2595,
      },
      {
        nome: "Inhapim",
        uf: "MG",
        estadoCod: 13,
        cod: 2596,
      },
      {
        nome: "Inhaúma",
        uf: "MG",
        estadoCod: 13,
        cod: 2597,
      },
      {
        nome: "Inimutaba",
        uf: "MG",
        estadoCod: 13,
        cod: 2598,
      },
      {
        nome: "Ipaba",
        uf: "MG",
        estadoCod: 13,
        cod: 2599,
      },
      {
        nome: "Ipanema",
        uf: "MG",
        estadoCod: 13,
        cod: 2600,
      },
      {
        nome: "Ipatinga",
        uf: "MG",
        estadoCod: 13,
        cod: 2601,
      },
      {
        nome: "Ipiaçu",
        uf: "MG",
        estadoCod: 13,
        cod: 2602,
      },
      {
        nome: "Ipuiúna",
        uf: "MG",
        estadoCod: 13,
        cod: 2603,
      },
      {
        nome: "Iraí de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2604,
      },
      {
        nome: "Itabira",
        uf: "MG",
        estadoCod: 13,
        cod: 2605,
      },
      {
        nome: "Itabirinha",
        uf: "MG",
        estadoCod: 13,
        cod: 2606,
      },
      {
        nome: "Itabirito",
        uf: "MG",
        estadoCod: 13,
        cod: 2607,
      },
      {
        nome: "Itacambira",
        uf: "MG",
        estadoCod: 13,
        cod: 2608,
      },
      {
        nome: "Itacarambi",
        uf: "MG",
        estadoCod: 13,
        cod: 2609,
      },
      {
        nome: "Itaguara",
        uf: "MG",
        estadoCod: 13,
        cod: 2610,
      },
      {
        nome: "Itaipé",
        uf: "MG",
        estadoCod: 13,
        cod: 2611,
      },
      {
        nome: "Itajubá",
        uf: "MG",
        estadoCod: 13,
        cod: 2612,
      },
      {
        nome: "Itamarandiba",
        uf: "MG",
        estadoCod: 13,
        cod: 2613,
      },
      {
        nome: "Itamarati de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2614,
      },
      {
        nome: "Itambacuri",
        uf: "MG",
        estadoCod: 13,
        cod: 2615,
      },
      {
        nome: "Itambé do Mato Dentro",
        uf: "MG",
        estadoCod: 13,
        cod: 2616,
      },
      {
        nome: "Itamogi",
        uf: "MG",
        estadoCod: 13,
        cod: 2617,
      },
      {
        nome: "Itamonte",
        uf: "MG",
        estadoCod: 13,
        cod: 2618,
      },
      {
        nome: "Itanhandu",
        uf: "MG",
        estadoCod: 13,
        cod: 2619,
      },
      {
        nome: "Itanhomi",
        uf: "MG",
        estadoCod: 13,
        cod: 2620,
      },
      {
        nome: "Itaobim",
        uf: "MG",
        estadoCod: 13,
        cod: 2621,
      },
      {
        nome: "Itapagipe",
        uf: "MG",
        estadoCod: 13,
        cod: 2622,
      },
      {
        nome: "Itapecerica",
        uf: "MG",
        estadoCod: 13,
        cod: 2623,
      },
      {
        nome: "Itapeva",
        uf: "MG",
        estadoCod: 13,
        cod: 2624,
      },
      {
        nome: "Itatiaiuçu",
        uf: "MG",
        estadoCod: 13,
        cod: 2625,
      },
      {
        nome: "Itaú de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2626,
      },
      {
        nome: "Itaúna",
        uf: "MG",
        estadoCod: 13,
        cod: 2627,
      },
      {
        nome: "Itaverava",
        uf: "MG",
        estadoCod: 13,
        cod: 2628,
      },
      {
        nome: "Itinga",
        uf: "MG",
        estadoCod: 13,
        cod: 2629,
      },
      {
        nome: "Itueta",
        uf: "MG",
        estadoCod: 13,
        cod: 2630,
      },
      {
        nome: "Ituiutaba",
        uf: "MG",
        estadoCod: 13,
        cod: 2631,
      },
      {
        nome: "Itumirim",
        uf: "MG",
        estadoCod: 13,
        cod: 2632,
      },
      {
        nome: "Iturama",
        uf: "MG",
        estadoCod: 13,
        cod: 2633,
      },
      {
        nome: "Itutinga",
        uf: "MG",
        estadoCod: 13,
        cod: 2634,
      },
      {
        nome: "Jaboticatubas",
        uf: "MG",
        estadoCod: 13,
        cod: 2635,
      },
      {
        nome: "Jacinto",
        uf: "MG",
        estadoCod: 13,
        cod: 2636,
      },
      {
        nome: "Jacuí",
        uf: "MG",
        estadoCod: 13,
        cod: 2637,
      },
      {
        nome: "Jacutinga",
        uf: "MG",
        estadoCod: 13,
        cod: 2638,
      },
      {
        nome: "Jaguaraçu",
        uf: "MG",
        estadoCod: 13,
        cod: 2639,
      },
      {
        nome: "Jaíba",
        uf: "MG",
        estadoCod: 13,
        cod: 2640,
      },
      {
        nome: "Jampruca",
        uf: "MG",
        estadoCod: 13,
        cod: 2641,
      },
      {
        nome: "Janaúba",
        uf: "MG",
        estadoCod: 13,
        cod: 2642,
      },
      {
        nome: "Januária",
        uf: "MG",
        estadoCod: 13,
        cod: 2643,
      },
      {
        nome: "Japaraíba",
        uf: "MG",
        estadoCod: 13,
        cod: 2644,
      },
      {
        nome: "Japonvar",
        uf: "MG",
        estadoCod: 13,
        cod: 2645,
      },
      {
        nome: "Jeceaba",
        uf: "MG",
        estadoCod: 13,
        cod: 2646,
      },
      {
        nome: "Jenipapo de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2647,
      },
      {
        nome: "Jequeri",
        uf: "MG",
        estadoCod: 13,
        cod: 2648,
      },
      {
        nome: "Jequitaí",
        uf: "MG",
        estadoCod: 13,
        cod: 2649,
      },
      {
        nome: "Jequitibá",
        uf: "MG",
        estadoCod: 13,
        cod: 2650,
      },
      {
        nome: "Jequitinhonha",
        uf: "MG",
        estadoCod: 13,
        cod: 2651,
      },
      {
        nome: "Jesuânia",
        uf: "MG",
        estadoCod: 13,
        cod: 2652,
      },
      {
        nome: "Joaíma",
        uf: "MG",
        estadoCod: 13,
        cod: 2653,
      },
      {
        nome: "Joanésia",
        uf: "MG",
        estadoCod: 13,
        cod: 2654,
      },
      {
        nome: "João Monlevade",
        uf: "MG",
        estadoCod: 13,
        cod: 2655,
      },
      {
        nome: "João Pinheiro",
        uf: "MG",
        estadoCod: 13,
        cod: 2656,
      },
      {
        nome: "Joaquim Felício",
        uf: "MG",
        estadoCod: 13,
        cod: 2657,
      },
      {
        nome: "Jordânia",
        uf: "MG",
        estadoCod: 13,
        cod: 2658,
      },
      {
        nome: "José Gonçalves de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2659,
      },
      {
        nome: "José Raydan",
        uf: "MG",
        estadoCod: 13,
        cod: 2660,
      },
      {
        nome: "Josenópolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2661,
      },
      {
        nome: "Nova União",
        uf: "MG",
        estadoCod: 13,
        cod: 2662,
      },
      {
        nome: "Juatuba",
        uf: "MG",
        estadoCod: 13,
        cod: 2663,
      },
      {
        nome: "Juiz de Fora",
        uf: "MG",
        estadoCod: 13,
        cod: 2664,
      },
      {
        nome: "Juramento",
        uf: "MG",
        estadoCod: 13,
        cod: 2665,
      },
      {
        nome: "Juruaia",
        uf: "MG",
        estadoCod: 13,
        cod: 2666,
      },
      {
        nome: "Juvenília",
        uf: "MG",
        estadoCod: 13,
        cod: 2667,
      },
      {
        nome: "Ladainha",
        uf: "MG",
        estadoCod: 13,
        cod: 2668,
      },
      {
        nome: "Lagamar",
        uf: "MG",
        estadoCod: 13,
        cod: 2669,
      },
      {
        nome: "Lagoa da Prata",
        uf: "MG",
        estadoCod: 13,
        cod: 2670,
      },
      {
        nome: "Lagoa dos Patos",
        uf: "MG",
        estadoCod: 13,
        cod: 2671,
      },
      {
        nome: "Lagoa Dourada",
        uf: "MG",
        estadoCod: 13,
        cod: 2672,
      },
      {
        nome: "Lagoa Formosa",
        uf: "MG",
        estadoCod: 13,
        cod: 2673,
      },
      {
        nome: "Lagoa Grande",
        uf: "MG",
        estadoCod: 13,
        cod: 2674,
      },
      {
        nome: "Lagoa Santa",
        uf: "MG",
        estadoCod: 13,
        cod: 2675,
      },
      {
        nome: "Lajinha",
        uf: "MG",
        estadoCod: 13,
        cod: 2676,
      },
      {
        nome: "Lambari",
        uf: "MG",
        estadoCod: 13,
        cod: 2677,
      },
      {
        nome: "Lamim",
        uf: "MG",
        estadoCod: 13,
        cod: 2678,
      },
      {
        nome: "Laranjal",
        uf: "MG",
        estadoCod: 13,
        cod: 2679,
      },
      {
        nome: "Lassance",
        uf: "MG",
        estadoCod: 13,
        cod: 2680,
      },
      {
        nome: "Lavras",
        uf: "MG",
        estadoCod: 13,
        cod: 2681,
      },
      {
        nome: "Leandro Ferreira",
        uf: "MG",
        estadoCod: 13,
        cod: 2682,
      },
      {
        nome: "Leme do Prado",
        uf: "MG",
        estadoCod: 13,
        cod: 2683,
      },
      {
        nome: "Leopoldina",
        uf: "MG",
        estadoCod: 13,
        cod: 2684,
      },
      {
        nome: "Liberdade",
        uf: "MG",
        estadoCod: 13,
        cod: 2685,
      },
      {
        nome: "Lima Duarte",
        uf: "MG",
        estadoCod: 13,
        cod: 2686,
      },
      {
        nome: "Limeira do Oeste",
        uf: "MG",
        estadoCod: 13,
        cod: 2687,
      },
      {
        nome: "Lontra",
        uf: "MG",
        estadoCod: 13,
        cod: 2688,
      },
      {
        nome: "Luisburgo",
        uf: "MG",
        estadoCod: 13,
        cod: 2689,
      },
      {
        nome: "Luislândia",
        uf: "MG",
        estadoCod: 13,
        cod: 2690,
      },
      {
        nome: "Luminárias",
        uf: "MG",
        estadoCod: 13,
        cod: 2691,
      },
      {
        nome: "Luz",
        uf: "MG",
        estadoCod: 13,
        cod: 2692,
      },
      {
        nome: "Machacalis",
        uf: "MG",
        estadoCod: 13,
        cod: 2693,
      },
      {
        nome: "Machado",
        uf: "MG",
        estadoCod: 13,
        cod: 2694,
      },
      {
        nome: "Madre de Deus de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2695,
      },
      {
        nome: "Malacacheta",
        uf: "MG",
        estadoCod: 13,
        cod: 2696,
      },
      {
        nome: "Mamonas",
        uf: "MG",
        estadoCod: 13,
        cod: 2697,
      },
      {
        nome: "Manga",
        uf: "MG",
        estadoCod: 13,
        cod: 2698,
      },
      {
        nome: "Manhuaçu",
        uf: "MG",
        estadoCod: 13,
        cod: 2699,
      },
      {
        nome: "Manhumirim",
        uf: "MG",
        estadoCod: 13,
        cod: 2700,
      },
      {
        nome: "Mantena",
        uf: "MG",
        estadoCod: 13,
        cod: 2701,
      },
      {
        nome: "Maravilhas",
        uf: "MG",
        estadoCod: 13,
        cod: 2702,
      },
      {
        nome: "Mar de Espanha",
        uf: "MG",
        estadoCod: 13,
        cod: 2703,
      },
      {
        nome: "Maria da Fé",
        uf: "MG",
        estadoCod: 13,
        cod: 2704,
      },
      {
        nome: "Mariana",
        uf: "MG",
        estadoCod: 13,
        cod: 2705,
      },
      {
        nome: "Marilac",
        uf: "MG",
        estadoCod: 13,
        cod: 2706,
      },
      {
        nome: "Mário Campos",
        uf: "MG",
        estadoCod: 13,
        cod: 2707,
      },
      {
        nome: "Maripá de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2708,
      },
      {
        nome: "Marliéria",
        uf: "MG",
        estadoCod: 13,
        cod: 2709,
      },
      {
        nome: "Marmelópolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2710,
      },
      {
        nome: "Martinho Campos",
        uf: "MG",
        estadoCod: 13,
        cod: 2711,
      },
      {
        nome: "Martins Soares",
        uf: "MG",
        estadoCod: 13,
        cod: 2712,
      },
      {
        nome: "Mata Verde",
        uf: "MG",
        estadoCod: 13,
        cod: 2713,
      },
      {
        nome: "Materlândia",
        uf: "MG",
        estadoCod: 13,
        cod: 2714,
      },
      {
        nome: "Mateus Leme",
        uf: "MG",
        estadoCod: 13,
        cod: 2715,
      },
      {
        nome: "Matias Barbosa",
        uf: "MG",
        estadoCod: 13,
        cod: 2716,
      },
      {
        nome: "Matias Cardoso",
        uf: "MG",
        estadoCod: 13,
        cod: 2717,
      },
      {
        nome: "Matipó",
        uf: "MG",
        estadoCod: 13,
        cod: 2718,
      },
      {
        nome: "Mato Verde",
        uf: "MG",
        estadoCod: 13,
        cod: 2719,
      },
      {
        nome: "Matozinhos",
        uf: "MG",
        estadoCod: 13,
        cod: 2720,
      },
      {
        nome: "Matutina",
        uf: "MG",
        estadoCod: 13,
        cod: 2721,
      },
      {
        nome: "Medeiros",
        uf: "MG",
        estadoCod: 13,
        cod: 2722,
      },
      {
        nome: "Medina",
        uf: "MG",
        estadoCod: 13,
        cod: 2723,
      },
      {
        nome: "Mendes Pimentel",
        uf: "MG",
        estadoCod: 13,
        cod: 2724,
      },
      {
        nome: "Mercês",
        uf: "MG",
        estadoCod: 13,
        cod: 2725,
      },
      {
        nome: "Mesquita",
        uf: "MG",
        estadoCod: 13,
        cod: 2726,
      },
      {
        nome: "Minas Novas",
        uf: "MG",
        estadoCod: 13,
        cod: 2727,
      },
      {
        nome: "Minduri",
        uf: "MG",
        estadoCod: 13,
        cod: 2728,
      },
      {
        nome: "Mirabela",
        uf: "MG",
        estadoCod: 13,
        cod: 2729,
      },
      {
        nome: "Miradouro",
        uf: "MG",
        estadoCod: 13,
        cod: 2730,
      },
      {
        nome: "Miraí",
        uf: "MG",
        estadoCod: 13,
        cod: 2731,
      },
      {
        nome: "Miravânia",
        uf: "MG",
        estadoCod: 13,
        cod: 2732,
      },
      {
        nome: "Moeda",
        uf: "MG",
        estadoCod: 13,
        cod: 2733,
      },
      {
        nome: "Moema",
        uf: "MG",
        estadoCod: 13,
        cod: 2734,
      },
      {
        nome: "Monjolos",
        uf: "MG",
        estadoCod: 13,
        cod: 2735,
      },
      {
        nome: "Monsenhor Paulo",
        uf: "MG",
        estadoCod: 13,
        cod: 2736,
      },
      {
        nome: "Montalvânia",
        uf: "MG",
        estadoCod: 13,
        cod: 2737,
      },
      {
        nome: "Monte Alegre de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2738,
      },
      {
        nome: "Monte Azul",
        uf: "MG",
        estadoCod: 13,
        cod: 2739,
      },
      {
        nome: "Monte Belo",
        uf: "MG",
        estadoCod: 13,
        cod: 2740,
      },
      {
        nome: "Monte Carmelo",
        uf: "MG",
        estadoCod: 13,
        cod: 2741,
      },
      {
        nome: "Monte Formoso",
        uf: "MG",
        estadoCod: 13,
        cod: 2742,
      },
      {
        nome: "Monte Santo de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2743,
      },
      {
        nome: "Montes Claros",
        uf: "MG",
        estadoCod: 13,
        cod: 2744,
      },
      {
        nome: "Monte Sião",
        uf: "MG",
        estadoCod: 13,
        cod: 2745,
      },
      {
        nome: "Montezuma",
        uf: "MG",
        estadoCod: 13,
        cod: 2746,
      },
      {
        nome: "Morada Nova de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2747,
      },
      {
        nome: "Morro da Garça",
        uf: "MG",
        estadoCod: 13,
        cod: 2748,
      },
      {
        nome: "Morro do Pilar",
        uf: "MG",
        estadoCod: 13,
        cod: 2749,
      },
      {
        nome: "Munhoz",
        uf: "MG",
        estadoCod: 13,
        cod: 2750,
      },
      {
        nome: "Muriaé",
        uf: "MG",
        estadoCod: 13,
        cod: 2751,
      },
      {
        nome: "Mutum",
        uf: "MG",
        estadoCod: 13,
        cod: 2752,
      },
      {
        nome: "Muzambinho",
        uf: "MG",
        estadoCod: 13,
        cod: 2753,
      },
      {
        nome: "Nacip Raydan",
        uf: "MG",
        estadoCod: 13,
        cod: 2754,
      },
      {
        nome: "Nanuque",
        uf: "MG",
        estadoCod: 13,
        cod: 2755,
      },
      {
        nome: "Naque",
        uf: "MG",
        estadoCod: 13,
        cod: 2756,
      },
      {
        nome: "Natalândia",
        uf: "MG",
        estadoCod: 13,
        cod: 2757,
      },
      {
        nome: "Natércia",
        uf: "MG",
        estadoCod: 13,
        cod: 2758,
      },
      {
        nome: "Nazareno",
        uf: "MG",
        estadoCod: 13,
        cod: 2759,
      },
      {
        nome: "Nepomuceno",
        uf: "MG",
        estadoCod: 13,
        cod: 2760,
      },
      {
        nome: "Ninheira",
        uf: "MG",
        estadoCod: 13,
        cod: 2761,
      },
      {
        nome: "Nova Belém",
        uf: "MG",
        estadoCod: 13,
        cod: 2762,
      },
      {
        nome: "Nova Era",
        uf: "MG",
        estadoCod: 13,
        cod: 2763,
      },
      {
        nome: "Nova Lima",
        uf: "MG",
        estadoCod: 13,
        cod: 2764,
      },
      {
        nome: "Nova Módica",
        uf: "MG",
        estadoCod: 13,
        cod: 2765,
      },
      {
        nome: "Nova Ponte",
        uf: "MG",
        estadoCod: 13,
        cod: 2766,
      },
      {
        nome: "Nova Porteirinha",
        uf: "MG",
        estadoCod: 13,
        cod: 2767,
      },
      {
        nome: "Nova Resende",
        uf: "MG",
        estadoCod: 13,
        cod: 2768,
      },
      {
        nome: "Nova Serrana",
        uf: "MG",
        estadoCod: 13,
        cod: 2769,
      },
      {
        nome: "Novo Cruzeiro",
        uf: "MG",
        estadoCod: 13,
        cod: 2770,
      },
      {
        nome: "Novo Oriente de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2771,
      },
      {
        nome: "Novorizonte",
        uf: "MG",
        estadoCod: 13,
        cod: 2772,
      },
      {
        nome: "Olaria",
        uf: "MG",
        estadoCod: 13,
        cod: 2773,
      },
      {
        nome: "Olhos-D'Água",
        uf: "MG",
        estadoCod: 13,
        cod: 2774,
      },
      {
        nome: "Olímpio Noronha",
        uf: "MG",
        estadoCod: 13,
        cod: 2775,
      },
      {
        nome: "Oliveira",
        uf: "MG",
        estadoCod: 13,
        cod: 2776,
      },
      {
        nome: "Oliveira Fortes",
        uf: "MG",
        estadoCod: 13,
        cod: 2777,
      },
      {
        nome: "Onça de Pitangui",
        uf: "MG",
        estadoCod: 13,
        cod: 2778,
      },
      {
        nome: "Oratórios",
        uf: "MG",
        estadoCod: 13,
        cod: 2779,
      },
      {
        nome: "Orizânia",
        uf: "MG",
        estadoCod: 13,
        cod: 2780,
      },
      {
        nome: "Ouro Branco",
        uf: "MG",
        estadoCod: 13,
        cod: 2781,
      },
      {
        nome: "Ouro Fino",
        uf: "MG",
        estadoCod: 13,
        cod: 2782,
      },
      {
        nome: "Ouro Preto",
        uf: "MG",
        estadoCod: 13,
        cod: 2783,
      },
      {
        nome: "Ouro Verde de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2784,
      },
      {
        nome: "Padre Carvalho",
        uf: "MG",
        estadoCod: 13,
        cod: 2785,
      },
      {
        nome: "Padre Paraíso",
        uf: "MG",
        estadoCod: 13,
        cod: 2786,
      },
      {
        nome: "Paineiras",
        uf: "MG",
        estadoCod: 13,
        cod: 2787,
      },
      {
        nome: "Pains",
        uf: "MG",
        estadoCod: 13,
        cod: 2788,
      },
      {
        nome: "Pai Pedro",
        uf: "MG",
        estadoCod: 13,
        cod: 2789,
      },
      {
        nome: "Paiva",
        uf: "MG",
        estadoCod: 13,
        cod: 2790,
      },
      {
        nome: "Palma",
        uf: "MG",
        estadoCod: 13,
        cod: 2791,
      },
      {
        nome: "Palmópolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2792,
      },
      {
        nome: "Papagaios",
        uf: "MG",
        estadoCod: 13,
        cod: 2793,
      },
      {
        nome: "Paracatu",
        uf: "MG",
        estadoCod: 13,
        cod: 2794,
      },
      {
        nome: "Pará de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2795,
      },
      {
        nome: "Paraguaçu",
        uf: "MG",
        estadoCod: 13,
        cod: 2796,
      },
      {
        nome: "Paraisópolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2797,
      },
      {
        nome: "Paraopeba",
        uf: "MG",
        estadoCod: 13,
        cod: 2798,
      },
      {
        nome: "Passabém",
        uf: "MG",
        estadoCod: 13,
        cod: 2799,
      },
      {
        nome: "Passa Quatro",
        uf: "MG",
        estadoCod: 13,
        cod: 2800,
      },
      {
        nome: "Passa Tempo",
        uf: "MG",
        estadoCod: 13,
        cod: 2801,
      },
      {
        nome: "Passa-Vinte",
        uf: "MG",
        estadoCod: 13,
        cod: 2802,
      },
      {
        nome: "Passos",
        uf: "MG",
        estadoCod: 13,
        cod: 2803,
      },
      {
        nome: "Patis",
        uf: "MG",
        estadoCod: 13,
        cod: 2804,
      },
      {
        nome: "Patos de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2805,
      },
      {
        nome: "Patrocínio",
        uf: "MG",
        estadoCod: 13,
        cod: 2806,
      },
      {
        nome: "Patrocínio do Muriaé",
        uf: "MG",
        estadoCod: 13,
        cod: 2807,
      },
      {
        nome: "Paula Cândido",
        uf: "MG",
        estadoCod: 13,
        cod: 2808,
      },
      {
        nome: "Paulistas",
        uf: "MG",
        estadoCod: 13,
        cod: 2809,
      },
      {
        nome: "Pavão",
        uf: "MG",
        estadoCod: 13,
        cod: 2810,
      },
      {
        nome: "Peçanha",
        uf: "MG",
        estadoCod: 13,
        cod: 2811,
      },
      {
        nome: "Pedra Azul",
        uf: "MG",
        estadoCod: 13,
        cod: 2812,
      },
      {
        nome: "Pedra Bonita",
        uf: "MG",
        estadoCod: 13,
        cod: 2813,
      },
      {
        nome: "Pedra do Anta",
        uf: "MG",
        estadoCod: 13,
        cod: 2814,
      },
      {
        nome: "Pedra do Indaiá",
        uf: "MG",
        estadoCod: 13,
        cod: 2815,
      },
      {
        nome: "Pedra Dourada",
        uf: "MG",
        estadoCod: 13,
        cod: 2816,
      },
      {
        nome: "Pedralva",
        uf: "MG",
        estadoCod: 13,
        cod: 2817,
      },
      {
        nome: "Pedras de Maria da Cruz",
        uf: "MG",
        estadoCod: 13,
        cod: 2818,
      },
      {
        nome: "Pedrinópolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2819,
      },
      {
        nome: "Pedro Leopoldo",
        uf: "MG",
        estadoCod: 13,
        cod: 2820,
      },
      {
        nome: "Pedro Teixeira",
        uf: "MG",
        estadoCod: 13,
        cod: 2821,
      },
      {
        nome: "Pequeri",
        uf: "MG",
        estadoCod: 13,
        cod: 2822,
      },
      {
        nome: "Pequi",
        uf: "MG",
        estadoCod: 13,
        cod: 2823,
      },
      {
        nome: "Perdigão",
        uf: "MG",
        estadoCod: 13,
        cod: 2824,
      },
      {
        nome: "Perdizes",
        uf: "MG",
        estadoCod: 13,
        cod: 2825,
      },
      {
        nome: "Perdões",
        uf: "MG",
        estadoCod: 13,
        cod: 2826,
      },
      {
        nome: "Periquito",
        uf: "MG",
        estadoCod: 13,
        cod: 2827,
      },
      {
        nome: "Pescador",
        uf: "MG",
        estadoCod: 13,
        cod: 2828,
      },
      {
        nome: "Piau",
        uf: "MG",
        estadoCod: 13,
        cod: 2829,
      },
      {
        nome: "Piedade de Caratinga",
        uf: "MG",
        estadoCod: 13,
        cod: 2830,
      },
      {
        nome: "Piedade de Ponte Nova",
        uf: "MG",
        estadoCod: 13,
        cod: 2831,
      },
      {
        nome: "Piedade do Rio Grande",
        uf: "MG",
        estadoCod: 13,
        cod: 2832,
      },
      {
        nome: "Piedade dos Gerais",
        uf: "MG",
        estadoCod: 13,
        cod: 2833,
      },
      {
        nome: "Pimenta",
        uf: "MG",
        estadoCod: 13,
        cod: 2834,
      },
      {
        nome: "Pingo-D'Água",
        uf: "MG",
        estadoCod: 13,
        cod: 2835,
      },
      {
        nome: "Pintópolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2836,
      },
      {
        nome: "Piracema",
        uf: "MG",
        estadoCod: 13,
        cod: 2837,
      },
      {
        nome: "Pirajuba",
        uf: "MG",
        estadoCod: 13,
        cod: 2838,
      },
      {
        nome: "Piranga",
        uf: "MG",
        estadoCod: 13,
        cod: 2839,
      },
      {
        nome: "Piranguçu",
        uf: "MG",
        estadoCod: 13,
        cod: 2840,
      },
      {
        nome: "Piranguinho",
        uf: "MG",
        estadoCod: 13,
        cod: 2841,
      },
      {
        nome: "Pirapetinga",
        uf: "MG",
        estadoCod: 13,
        cod: 2842,
      },
      {
        nome: "Pirapora",
        uf: "MG",
        estadoCod: 13,
        cod: 2843,
      },
      {
        nome: "Piraúba",
        uf: "MG",
        estadoCod: 13,
        cod: 2844,
      },
      {
        nome: "Pitangui",
        uf: "MG",
        estadoCod: 13,
        cod: 2845,
      },
      {
        nome: "Piumhi",
        uf: "MG",
        estadoCod: 13,
        cod: 2846,
      },
      {
        nome: "Planura",
        uf: "MG",
        estadoCod: 13,
        cod: 2847,
      },
      {
        nome: "Poço Fundo",
        uf: "MG",
        estadoCod: 13,
        cod: 2848,
      },
      {
        nome: "Poços de Caldas",
        uf: "MG",
        estadoCod: 13,
        cod: 2849,
      },
      {
        nome: "Pocrane",
        uf: "MG",
        estadoCod: 13,
        cod: 2850,
      },
      {
        nome: "Pompéu",
        uf: "MG",
        estadoCod: 13,
        cod: 2851,
      },
      {
        nome: "Ponte Nova",
        uf: "MG",
        estadoCod: 13,
        cod: 2852,
      },
      {
        nome: "Ponto Chique",
        uf: "MG",
        estadoCod: 13,
        cod: 2853,
      },
      {
        nome: "Ponto dos Volantes",
        uf: "MG",
        estadoCod: 13,
        cod: 2854,
      },
      {
        nome: "Porteirinha",
        uf: "MG",
        estadoCod: 13,
        cod: 2855,
      },
      {
        nome: "Porto Firme",
        uf: "MG",
        estadoCod: 13,
        cod: 2856,
      },
      {
        nome: "Poté",
        uf: "MG",
        estadoCod: 13,
        cod: 2857,
      },
      {
        nome: "Pouso Alegre",
        uf: "MG",
        estadoCod: 13,
        cod: 2858,
      },
      {
        nome: "Pouso Alto",
        uf: "MG",
        estadoCod: 13,
        cod: 2859,
      },
      {
        nome: "Prados",
        uf: "MG",
        estadoCod: 13,
        cod: 2860,
      },
      {
        nome: "Prata",
        uf: "MG",
        estadoCod: 13,
        cod: 2861,
      },
      {
        nome: "Pratápolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2862,
      },
      {
        nome: "Pratinha",
        uf: "MG",
        estadoCod: 13,
        cod: 2863,
      },
      {
        nome: "Presidente Juscelino",
        uf: "MG",
        estadoCod: 13,
        cod: 2865,
      },
      {
        nome: "Presidente Kubitschek",
        uf: "MG",
        estadoCod: 13,
        cod: 2866,
      },
      {
        nome: "Presidente Olegário",
        uf: "MG",
        estadoCod: 13,
        cod: 2867,
      },
      {
        nome: "Alto Jequitibá",
        uf: "MG",
        estadoCod: 13,
        cod: 2868,
      },
      {
        nome: "Prudente de Morais",
        uf: "MG",
        estadoCod: 13,
        cod: 2869,
      },
      {
        nome: "Quartel Geral",
        uf: "MG",
        estadoCod: 13,
        cod: 2870,
      },
      {
        nome: "Queluzito",
        uf: "MG",
        estadoCod: 13,
        cod: 2871,
      },
      {
        nome: "Raposos",
        uf: "MG",
        estadoCod: 13,
        cod: 2872,
      },
      {
        nome: "Raul Soares",
        uf: "MG",
        estadoCod: 13,
        cod: 2873,
      },
      {
        nome: "Recreio",
        uf: "MG",
        estadoCod: 13,
        cod: 2874,
      },
      {
        nome: "Reduto",
        uf: "MG",
        estadoCod: 13,
        cod: 2875,
      },
      {
        nome: "Resende Costa",
        uf: "MG",
        estadoCod: 13,
        cod: 2876,
      },
      {
        nome: "Resplendor",
        uf: "MG",
        estadoCod: 13,
        cod: 2877,
      },
      {
        nome: "Ressaquinha",
        uf: "MG",
        estadoCod: 13,
        cod: 2878,
      },
      {
        nome: "Riachinho",
        uf: "MG",
        estadoCod: 13,
        cod: 2879,
      },
      {
        nome: "Riacho dos Machados",
        uf: "MG",
        estadoCod: 13,
        cod: 2880,
      },
      {
        nome: "Ribeirão das Neves",
        uf: "MG",
        estadoCod: 13,
        cod: 2881,
      },
      {
        nome: "Ribeirão Vermelho",
        uf: "MG",
        estadoCod: 13,
        cod: 2882,
      },
      {
        nome: "Rio Acima",
        uf: "MG",
        estadoCod: 13,
        cod: 2883,
      },
      {
        nome: "Rio Casca",
        uf: "MG",
        estadoCod: 13,
        cod: 2884,
      },
      {
        nome: "Rio Doce",
        uf: "MG",
        estadoCod: 13,
        cod: 2885,
      },
      {
        nome: "Rio do Prado",
        uf: "MG",
        estadoCod: 13,
        cod: 2886,
      },
      {
        nome: "Rio Espera",
        uf: "MG",
        estadoCod: 13,
        cod: 2887,
      },
      {
        nome: "Rio Manso",
        uf: "MG",
        estadoCod: 13,
        cod: 2888,
      },
      {
        nome: "Rio Novo",
        uf: "MG",
        estadoCod: 13,
        cod: 2889,
      },
      {
        nome: "Rio Paranaíba",
        uf: "MG",
        estadoCod: 13,
        cod: 2890,
      },
      {
        nome: "Rio Pardo de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2891,
      },
      {
        nome: "Rio Piracicaba",
        uf: "MG",
        estadoCod: 13,
        cod: 2892,
      },
      {
        nome: "Rio Pomba",
        uf: "MG",
        estadoCod: 13,
        cod: 2893,
      },
      {
        nome: "Rio Preto",
        uf: "MG",
        estadoCod: 13,
        cod: 2894,
      },
      {
        nome: "Rio Vermelho",
        uf: "MG",
        estadoCod: 13,
        cod: 2895,
      },
      {
        nome: "Ritápolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2896,
      },
      {
        nome: "Rochedo de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2897,
      },
      {
        nome: "Rodeiro",
        uf: "MG",
        estadoCod: 13,
        cod: 2898,
      },
      {
        nome: "Romaria",
        uf: "MG",
        estadoCod: 13,
        cod: 2899,
      },
      {
        nome: "Rosário da Limeira",
        uf: "MG",
        estadoCod: 13,
        cod: 2900,
      },
      {
        nome: "Rubelita",
        uf: "MG",
        estadoCod: 13,
        cod: 2901,
      },
      {
        nome: "Rubim",
        uf: "MG",
        estadoCod: 13,
        cod: 2902,
      },
      {
        nome: "Sabará",
        uf: "MG",
        estadoCod: 13,
        cod: 2903,
      },
      {
        nome: "Sabinópolis",
        uf: "MG",
        estadoCod: 13,
        cod: 2904,
      },
      {
        nome: "Sacramento",
        uf: "MG",
        estadoCod: 13,
        cod: 2905,
      },
      {
        nome: "Salinas",
        uf: "MG",
        estadoCod: 13,
        cod: 2906,
      },
      {
        nome: "Salto da Divisa",
        uf: "MG",
        estadoCod: 13,
        cod: 2907,
      },
      {
        nome: "Santa Bárbara",
        uf: "MG",
        estadoCod: 13,
        cod: 2908,
      },
      {
        nome: "Santa Bárbara do Leste",
        uf: "MG",
        estadoCod: 13,
        cod: 2909,
      },
      {
        nome: "Santa Bárbara do Monte Verde",
        uf: "MG",
        estadoCod: 13,
        cod: 2910,
      },
      {
        nome: "Santa Bárbara do Tugúrio",
        uf: "MG",
        estadoCod: 13,
        cod: 2911,
      },
      {
        nome: "Santa Cruz de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2912,
      },
      {
        nome: "Santa Cruz de Salinas",
        uf: "MG",
        estadoCod: 13,
        cod: 2913,
      },
      {
        nome: "Santa Cruz do Escalvado",
        uf: "MG",
        estadoCod: 13,
        cod: 2914,
      },
      {
        nome: "Santa Efigênia de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2915,
      },
      {
        nome: "Santa Fé de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2916,
      },
      {
        nome: "Santa Helena de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2917,
      },
      {
        nome: "Santa Juliana",
        uf: "MG",
        estadoCod: 13,
        cod: 2918,
      },
      {
        nome: "Santa Luzia",
        uf: "MG",
        estadoCod: 13,
        cod: 2919,
      },
      {
        nome: "Santa Margarida",
        uf: "MG",
        estadoCod: 13,
        cod: 2920,
      },
      {
        nome: "Santa Maria de Itabira",
        uf: "MG",
        estadoCod: 13,
        cod: 2921,
      },
      {
        nome: "Santa Maria do Salto",
        uf: "MG",
        estadoCod: 13,
        cod: 2922,
      },
      {
        nome: "Santa Maria do Suaçuí",
        uf: "MG",
        estadoCod: 13,
        cod: 2923,
      },
      {
        nome: "Santana da Vargem",
        uf: "MG",
        estadoCod: 13,
        cod: 2924,
      },
      {
        nome: "Santana de Cataguases",
        uf: "MG",
        estadoCod: 13,
        cod: 2925,
      },
      {
        nome: "Santana de Pirapama",
        uf: "MG",
        estadoCod: 13,
        cod: 2926,
      },
      {
        nome: "Santana do Deserto",
        uf: "MG",
        estadoCod: 13,
        cod: 2927,
      },
      {
        nome: "Santana do Garambéu",
        uf: "MG",
        estadoCod: 13,
        cod: 2928,
      },
      {
        nome: "Santana do Jacaré",
        uf: "MG",
        estadoCod: 13,
        cod: 2929,
      },
      {
        nome: "Santana do Manhuaçu",
        uf: "MG",
        estadoCod: 13,
        cod: 2930,
      },
      {
        nome: "Santana do Paraíso",
        uf: "MG",
        estadoCod: 13,
        cod: 2931,
      },
      {
        nome: "Santana do Riacho",
        uf: "MG",
        estadoCod: 13,
        cod: 2932,
      },
      {
        nome: "Santana dos Montes",
        uf: "MG",
        estadoCod: 13,
        cod: 2933,
      },
      {
        nome: "Santa Rita de Caldas",
        uf: "MG",
        estadoCod: 13,
        cod: 2934,
      },
      {
        nome: "Santa Rita de Jacutinga",
        uf: "MG",
        estadoCod: 13,
        cod: 2935,
      },
      {
        nome: "Santa Rita de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2936,
      },
      {
        nome: "Santa Rita de Ibitipoca",
        uf: "MG",
        estadoCod: 13,
        cod: 2937,
      },
      {
        nome: "Santa Rita do Itueto",
        uf: "MG",
        estadoCod: 13,
        cod: 2938,
      },
      {
        nome: "Santa Rita do Sapucaí",
        uf: "MG",
        estadoCod: 13,
        cod: 2939,
      },
      {
        nome: "Santa Rosa da Serra",
        uf: "MG",
        estadoCod: 13,
        cod: 2940,
      },
      {
        nome: "Santa Vitória",
        uf: "MG",
        estadoCod: 13,
        cod: 2941,
      },
      {
        nome: "Santo Antônio do Amparo",
        uf: "MG",
        estadoCod: 13,
        cod: 2942,
      },
      {
        nome: "Santo Antônio do Aventureiro",
        uf: "MG",
        estadoCod: 13,
        cod: 2943,
      },
      {
        nome: "Santo Antônio do Grama",
        uf: "MG",
        estadoCod: 13,
        cod: 2944,
      },
      {
        nome: "Santo Antônio do Itambé",
        uf: "MG",
        estadoCod: 13,
        cod: 2945,
      },
      {
        nome: "Santo Antônio do Jacinto",
        uf: "MG",
        estadoCod: 13,
        cod: 2946,
      },
      {
        nome: "Santo Antônio do Monte",
        uf: "MG",
        estadoCod: 13,
        cod: 2947,
      },
      {
        nome: "Santo Antônio do Retiro",
        uf: "MG",
        estadoCod: 13,
        cod: 2948,
      },
      {
        nome: "Santo Antônio do Rio Abaixo",
        uf: "MG",
        estadoCod: 13,
        cod: 2949,
      },
      {
        nome: "Santo Hipólito",
        uf: "MG",
        estadoCod: 13,
        cod: 2950,
      },
      {
        nome: "Santos Dumont",
        uf: "MG",
        estadoCod: 13,
        cod: 2951,
      },
      {
        nome: "São Bento Abade",
        uf: "MG",
        estadoCod: 13,
        cod: 2952,
      },
      {
        nome: "São Brás do Suaçuí",
        uf: "MG",
        estadoCod: 13,
        cod: 2953,
      },
      {
        nome: "São Domingos das Dores",
        uf: "MG",
        estadoCod: 13,
        cod: 2954,
      },
      {
        nome: "São Domingos do Prata",
        uf: "MG",
        estadoCod: 13,
        cod: 2955,
      },
      {
        nome: "São Félix de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2956,
      },
      {
        nome: "São Francisco",
        uf: "MG",
        estadoCod: 13,
        cod: 2957,
      },
      {
        nome: "São Francisco de Paula",
        uf: "MG",
        estadoCod: 13,
        cod: 2958,
      },
      {
        nome: "São Francisco de Sales",
        uf: "MG",
        estadoCod: 13,
        cod: 2959,
      },
      {
        nome: "São Francisco do Glória",
        uf: "MG",
        estadoCod: 13,
        cod: 2960,
      },
      {
        nome: "São Geraldo",
        uf: "MG",
        estadoCod: 13,
        cod: 2961,
      },
      {
        nome: "São Geraldo da Piedade",
        uf: "MG",
        estadoCod: 13,
        cod: 2962,
      },
      {
        nome: "São Geraldo do Baixio",
        uf: "MG",
        estadoCod: 13,
        cod: 2963,
      },
      {
        nome: "São Gonçalo do Abaeté",
        uf: "MG",
        estadoCod: 13,
        cod: 2964,
      },
      {
        nome: "São Gonçalo do Pará",
        uf: "MG",
        estadoCod: 13,
        cod: 2965,
      },
      {
        nome: "São Gonçalo do Rio Abaixo",
        uf: "MG",
        estadoCod: 13,
        cod: 2966,
      },
      {
        nome: "São Gonçalo do Sapucaí",
        uf: "MG",
        estadoCod: 13,
        cod: 2967,
      },
      {
        nome: "São Gotardo",
        uf: "MG",
        estadoCod: 13,
        cod: 2968,
      },
      {
        nome: "São João Batista do Glória",
        uf: "MG",
        estadoCod: 13,
        cod: 2969,
      },
      {
        nome: "São João da Lagoa",
        uf: "MG",
        estadoCod: 13,
        cod: 2970,
      },
      {
        nome: "São João da Mata",
        uf: "MG",
        estadoCod: 13,
        cod: 2971,
      },
      {
        nome: "São João da Ponte",
        uf: "MG",
        estadoCod: 13,
        cod: 2972,
      },
      {
        nome: "São João das Missões",
        uf: "MG",
        estadoCod: 13,
        cod: 2973,
      },
      {
        nome: "São João del Rei",
        uf: "MG",
        estadoCod: 13,
        cod: 2974,
      },
      {
        nome: "São João do Manhuaçu",
        uf: "MG",
        estadoCod: 13,
        cod: 2975,
      },
      {
        nome: "São João do Manteninha",
        uf: "MG",
        estadoCod: 13,
        cod: 2976,
      },
      {
        nome: "São João do Oriente",
        uf: "MG",
        estadoCod: 13,
        cod: 2977,
      },
      {
        nome: "São João do Pacuí",
        uf: "MG",
        estadoCod: 13,
        cod: 2978,
      },
      {
        nome: "São João do Paraíso",
        uf: "MG",
        estadoCod: 13,
        cod: 2979,
      },
      {
        nome: "São João Evangelista",
        uf: "MG",
        estadoCod: 13,
        cod: 2980,
      },
      {
        nome: "São João Nepomuceno",
        uf: "MG",
        estadoCod: 13,
        cod: 2981,
      },
      {
        nome: "São Joaquim de Bicas",
        uf: "MG",
        estadoCod: 13,
        cod: 2982,
      },
      {
        nome: "São José da Barra",
        uf: "MG",
        estadoCod: 13,
        cod: 2983,
      },
      {
        nome: "São José da Lapa",
        uf: "MG",
        estadoCod: 13,
        cod: 2984,
      },
      {
        nome: "São José da Safira",
        uf: "MG",
        estadoCod: 13,
        cod: 2985,
      },
      {
        nome: "São José da Varginha",
        uf: "MG",
        estadoCod: 13,
        cod: 2986,
      },
      {
        nome: "São José do Alegre",
        uf: "MG",
        estadoCod: 13,
        cod: 2987,
      },
      {
        nome: "São José do Divino",
        uf: "MG",
        estadoCod: 13,
        cod: 2988,
      },
      {
        nome: "São José do Goiabal",
        uf: "MG",
        estadoCod: 13,
        cod: 2989,
      },
      {
        nome: "São José do Jacuri",
        uf: "MG",
        estadoCod: 13,
        cod: 2990,
      },
      {
        nome: "São José do Mantimento",
        uf: "MG",
        estadoCod: 13,
        cod: 2991,
      },
      {
        nome: "São Lourenço",
        uf: "MG",
        estadoCod: 13,
        cod: 2992,
      },
      {
        nome: "São Miguel do Anta",
        uf: "MG",
        estadoCod: 13,
        cod: 2993,
      },
      {
        nome: "São Pedro da União",
        uf: "MG",
        estadoCod: 13,
        cod: 2994,
      },
      {
        nome: "São Pedro dos Ferros",
        uf: "MG",
        estadoCod: 13,
        cod: 2995,
      },
      {
        nome: "São Pedro do Suaçuí",
        uf: "MG",
        estadoCod: 13,
        cod: 2996,
      },
      {
        nome: "São Romão",
        uf: "MG",
        estadoCod: 13,
        cod: 2997,
      },
      {
        nome: "São Roque de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 2998,
      },
      {
        nome: "São Sebastião da Bela Vista",
        uf: "MG",
        estadoCod: 13,
        cod: 2999,
      },
      {
        nome: "São Sebastião da Vargem Alegre",
        uf: "MG",
        estadoCod: 13,
        cod: 3000,
      },
      {
        nome: "São Sebastião do Anta",
        uf: "MG",
        estadoCod: 13,
        cod: 3001,
      },
      {
        nome: "São Sebastião do Maranhão",
        uf: "MG",
        estadoCod: 13,
        cod: 3002,
      },
      {
        nome: "São Sebastião do Oeste",
        uf: "MG",
        estadoCod: 13,
        cod: 3003,
      },
      {
        nome: "São Sebastião do Paraíso",
        uf: "MG",
        estadoCod: 13,
        cod: 3004,
      },
      {
        nome: "São Sebastião do Rio Preto",
        uf: "MG",
        estadoCod: 13,
        cod: 3005,
      },
      {
        nome: "São Sebastião do Rio Verde",
        uf: "MG",
        estadoCod: 13,
        cod: 3006,
      },
      {
        nome: "São Tiago",
        uf: "MG",
        estadoCod: 13,
        cod: 3007,
      },
      {
        nome: "São Tomás de Aquino",
        uf: "MG",
        estadoCod: 13,
        cod: 3008,
      },
      {
        nome: "São Thomé das Letras",
        uf: "MG",
        estadoCod: 13,
        cod: 3009,
      },
      {
        nome: "São Vicente de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 3010,
      },
      {
        nome: "Sapucaí-Mirim",
        uf: "MG",
        estadoCod: 13,
        cod: 3011,
      },
      {
        nome: "Sardoá",
        uf: "MG",
        estadoCod: 13,
        cod: 3012,
      },
      {
        nome: "Sarzedo",
        uf: "MG",
        estadoCod: 13,
        cod: 3013,
      },
      {
        nome: "Setubinha",
        uf: "MG",
        estadoCod: 13,
        cod: 3014,
      },
      {
        nome: "Sem-Peixe",
        uf: "MG",
        estadoCod: 13,
        cod: 3015,
      },
      {
        nome: "Senador Amaral",
        uf: "MG",
        estadoCod: 13,
        cod: 3016,
      },
      {
        nome: "Senador Cortes",
        uf: "MG",
        estadoCod: 13,
        cod: 3017,
      },
      {
        nome: "Senador Firmino",
        uf: "MG",
        estadoCod: 13,
        cod: 3018,
      },
      {
        nome: "Senador José Bento",
        uf: "MG",
        estadoCod: 13,
        cod: 3019,
      },
      {
        nome: "Senador Modestino Gonçalves",
        uf: "MG",
        estadoCod: 13,
        cod: 3020,
      },
      {
        nome: "Senhora de Oliveira",
        uf: "MG",
        estadoCod: 13,
        cod: 3021,
      },
      {
        nome: "Senhora do Porto",
        uf: "MG",
        estadoCod: 13,
        cod: 3022,
      },
      {
        nome: "Senhora dos Remédios",
        uf: "MG",
        estadoCod: 13,
        cod: 3023,
      },
      {
        nome: "Sericita",
        uf: "MG",
        estadoCod: 13,
        cod: 3024,
      },
      {
        nome: "Seritinga",
        uf: "MG",
        estadoCod: 13,
        cod: 3025,
      },
      {
        nome: "Serra Azul de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 3026,
      },
      {
        nome: "Serra da Saudade",
        uf: "MG",
        estadoCod: 13,
        cod: 3027,
      },
      {
        nome: "Serra dos Aimorés",
        uf: "MG",
        estadoCod: 13,
        cod: 3028,
      },
      {
        nome: "Serra do Salitre",
        uf: "MG",
        estadoCod: 13,
        cod: 3029,
      },
      {
        nome: "Serrania",
        uf: "MG",
        estadoCod: 13,
        cod: 3030,
      },
      {
        nome: "Serranópolis de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 3031,
      },
      {
        nome: "Serranos",
        uf: "MG",
        estadoCod: 13,
        cod: 3032,
      },
      {
        nome: "Serro",
        uf: "MG",
        estadoCod: 13,
        cod: 3033,
      },
      {
        nome: "Sete Lagoas",
        uf: "MG",
        estadoCod: 13,
        cod: 3034,
      },
      {
        nome: "Silveirânia",
        uf: "MG",
        estadoCod: 13,
        cod: 3035,
      },
      {
        nome: "Silvianópolis",
        uf: "MG",
        estadoCod: 13,
        cod: 3036,
      },
      {
        nome: "Simão Pereira",
        uf: "MG",
        estadoCod: 13,
        cod: 3037,
      },
      {
        nome: "Simonésia",
        uf: "MG",
        estadoCod: 13,
        cod: 3038,
      },
      {
        nome: "Sobrália",
        uf: "MG",
        estadoCod: 13,
        cod: 3039,
      },
      {
        nome: "Soledade de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 3040,
      },
      {
        nome: "Tabuleiro",
        uf: "MG",
        estadoCod: 13,
        cod: 3041,
      },
      {
        nome: "Taiobeiras",
        uf: "MG",
        estadoCod: 13,
        cod: 3042,
      },
      {
        nome: "Taparuba",
        uf: "MG",
        estadoCod: 13,
        cod: 3043,
      },
      {
        nome: "Tapira",
        uf: "MG",
        estadoCod: 13,
        cod: 3044,
      },
      {
        nome: "Tapiraí",
        uf: "MG",
        estadoCod: 13,
        cod: 3045,
      },
      {
        nome: "Taquaraçu de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 3046,
      },
      {
        nome: "Tarumirim",
        uf: "MG",
        estadoCod: 13,
        cod: 3047,
      },
      {
        nome: "Teixeiras",
        uf: "MG",
        estadoCod: 13,
        cod: 3048,
      },
      {
        nome: "Teófilo Otoni",
        uf: "MG",
        estadoCod: 13,
        cod: 3049,
      },
      {
        nome: "Timóteo",
        uf: "MG",
        estadoCod: 13,
        cod: 3050,
      },
      {
        nome: "Tiradentes",
        uf: "MG",
        estadoCod: 13,
        cod: 3051,
      },
      {
        nome: "Tiros",
        uf: "MG",
        estadoCod: 13,
        cod: 3052,
      },
      {
        nome: "Tocantins",
        uf: "MG",
        estadoCod: 13,
        cod: 3053,
      },
      {
        nome: "Tocos do Moji",
        uf: "MG",
        estadoCod: 13,
        cod: 3054,
      },
      {
        nome: "Toledo",
        uf: "MG",
        estadoCod: 13,
        cod: 3055,
      },
      {
        nome: "Tombos",
        uf: "MG",
        estadoCod: 13,
        cod: 3056,
      },
      {
        nome: "Três Corações",
        uf: "MG",
        estadoCod: 13,
        cod: 3057,
      },
      {
        nome: "Três Marias",
        uf: "MG",
        estadoCod: 13,
        cod: 3058,
      },
      {
        nome: "Três Pontas",
        uf: "MG",
        estadoCod: 13,
        cod: 3059,
      },
      {
        nome: "Tumiritinga",
        uf: "MG",
        estadoCod: 13,
        cod: 3060,
      },
      {
        nome: "Tupaciguara",
        uf: "MG",
        estadoCod: 13,
        cod: 3061,
      },
      {
        nome: "Turmalina",
        uf: "MG",
        estadoCod: 13,
        cod: 3062,
      },
      {
        nome: "Turvolândia",
        uf: "MG",
        estadoCod: 13,
        cod: 3063,
      },
      {
        nome: "Ubá",
        uf: "MG",
        estadoCod: 13,
        cod: 3064,
      },
      {
        nome: "Ubaí",
        uf: "MG",
        estadoCod: 13,
        cod: 3065,
      },
      {
        nome: "Ubaporanga",
        uf: "MG",
        estadoCod: 13,
        cod: 3066,
      },
      {
        nome: "Uberaba",
        uf: "MG",
        estadoCod: 13,
        cod: 3067,
      },
      {
        nome: "Uberlândia",
        uf: "MG",
        estadoCod: 13,
        cod: 3068,
      },
      {
        nome: "Umburatiba",
        uf: "MG",
        estadoCod: 13,
        cod: 3069,
      },
      {
        nome: "Unaí",
        uf: "MG",
        estadoCod: 13,
        cod: 3070,
      },
      {
        nome: "União de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 3071,
      },
      {
        nome: "Uruana de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 3072,
      },
      {
        nome: "Urucânia",
        uf: "MG",
        estadoCod: 13,
        cod: 3073,
      },
      {
        nome: "Urucuia",
        uf: "MG",
        estadoCod: 13,
        cod: 3074,
      },
      {
        nome: "Vargem Alegre",
        uf: "MG",
        estadoCod: 13,
        cod: 3075,
      },
      {
        nome: "Vargem Bonita",
        uf: "MG",
        estadoCod: 13,
        cod: 3076,
      },
      {
        nome: "Vargem Grande do Rio Pardo",
        uf: "MG",
        estadoCod: 13,
        cod: 3077,
      },
      {
        nome: "Varginha",
        uf: "MG",
        estadoCod: 13,
        cod: 3078,
      },
      {
        nome: "Varjão de Minas",
        uf: "MG",
        estadoCod: 13,
        cod: 3079,
      },
      {
        nome: "Várzea da Palma",
        uf: "MG",
        estadoCod: 13,
        cod: 3080,
      },
      {
        nome: "Varzelândia",
        uf: "MG",
        estadoCod: 13,
        cod: 3081,
      },
      {
        nome: "Vazante",
        uf: "MG",
        estadoCod: 13,
        cod: 3082,
      },
      {
        nome: "Verdelândia",
        uf: "MG",
        estadoCod: 13,
        cod: 3083,
      },
      {
        nome: "Veredinha",
        uf: "MG",
        estadoCod: 13,
        cod: 3084,
      },
      {
        nome: "Veríssimo",
        uf: "MG",
        estadoCod: 13,
        cod: 3085,
      },
      {
        nome: "Vermelho Novo",
        uf: "MG",
        estadoCod: 13,
        cod: 3086,
      },
      {
        nome: "Vespasiano",
        uf: "MG",
        estadoCod: 13,
        cod: 3087,
      },
      {
        nome: "Viçosa",
        uf: "MG",
        estadoCod: 13,
        cod: 3088,
      },
      {
        nome: "Vieiras",
        uf: "MG",
        estadoCod: 13,
        cod: 3089,
      },
      {
        nome: "Mathias Lobato",
        uf: "MG",
        estadoCod: 13,
        cod: 3090,
      },
      {
        nome: "Virgem da Lapa",
        uf: "MG",
        estadoCod: 13,
        cod: 3091,
      },
      {
        nome: "Virgínia",
        uf: "MG",
        estadoCod: 13,
        cod: 3092,
      },
      {
        nome: "Virginópolis",
        uf: "MG",
        estadoCod: 13,
        cod: 3093,
      },
      {
        nome: "Virgolândia",
        uf: "MG",
        estadoCod: 13,
        cod: 3094,
      },
      {
        nome: "Visconde do Rio Branco",
        uf: "MG",
        estadoCod: 13,
        cod: 3095,
      },
      {
        nome: "Volta Grande",
        uf: "MG",
        estadoCod: 13,
        cod: 3096,
      },
      {
        nome: "Wenceslau Braz",
        uf: "MG",
        estadoCod: 13,
        cod: 3097,
      },
    ],
  },
  {
    nome: "Rondônia",
    uf: "RO",
    regiaoCod: 1,
    cod: 22,
    cidades: [
      {
        nome: "Alta Floresta D'Oeste",
        uf: "RO",
        estadoCod: 22,
        cod: 1,
      },
      {
        nome: "Ariquemes",
        uf: "RO",
        estadoCod: 22,
        cod: 2,
      },
      {
        nome: "Cabixi",
        uf: "RO",
        estadoCod: 22,
        cod: 3,
      },
      {
        nome: "Cacoal",
        uf: "RO",
        estadoCod: 22,
        cod: 4,
      },
      {
        nome: "Cerejeiras",
        uf: "RO",
        estadoCod: 22,
        cod: 5,
      },
      {
        nome: "Colorado do Oeste",
        uf: "RO",
        estadoCod: 22,
        cod: 6,
      },
      {
        nome: "Corumbiara",
        uf: "RO",
        estadoCod: 22,
        cod: 7,
      },
      {
        nome: "Costa Marques",
        uf: "RO",
        estadoCod: 22,
        cod: 8,
      },
      {
        nome: "Espigão D'Oeste",
        uf: "RO",
        estadoCod: 22,
        cod: 9,
      },
      {
        nome: "Guajará-Mirim",
        uf: "RO",
        estadoCod: 22,
        cod: 10,
      },
      {
        nome: "Jaru",
        uf: "RO",
        estadoCod: 22,
        cod: 11,
      },
      {
        nome: "Ji-Paraná",
        uf: "RO",
        estadoCod: 22,
        cod: 12,
      },
      {
        nome: "Machadinho D'Oeste",
        uf: "RO",
        estadoCod: 22,
        cod: 13,
      },
      {
        nome: "Nova Brasilândia D'Oeste",
        uf: "RO",
        estadoCod: 22,
        cod: 14,
      },
      {
        nome: "Ouro Preto do Oeste",
        uf: "RO",
        estadoCod: 22,
        cod: 15,
      },
      {
        nome: "Pimenta Bueno",
        uf: "RO",
        estadoCod: 22,
        cod: 16,
      },
      {
        nome: "Porto Velho",
        uf: "RO",
        estadoCod: 22,
        cod: 17,
      },
      {
        nome: "Presidente Médici",
        uf: "RO",
        estadoCod: 22,
        cod: 18,
      },
      {
        nome: "Rio Crespo",
        uf: "RO",
        estadoCod: 22,
        cod: 19,
      },
      {
        nome: "Rolim de Moura",
        uf: "RO",
        estadoCod: 22,
        cod: 20,
      },
      {
        nome: "Santa Luzia D'Oeste",
        uf: "RO",
        estadoCod: 22,
        cod: 21,
      },
      {
        nome: "Vilhena",
        uf: "RO",
        estadoCod: 22,
        cod: 22,
      },
      {
        nome: "São Miguel do Guaporé",
        uf: "RO",
        estadoCod: 22,
        cod: 23,
      },
      {
        nome: "Nova Mamoré",
        uf: "RO",
        estadoCod: 22,
        cod: 24,
      },
      {
        nome: "Alvorada D'Oeste",
        uf: "RO",
        estadoCod: 22,
        cod: 25,
      },
      {
        nome: "Alto Alegre dos Parecis",
        uf: "RO",
        estadoCod: 22,
        cod: 26,
      },
      {
        nome: "Alto Paraíso",
        uf: "RO",
        estadoCod: 22,
        cod: 27,
      },
      {
        nome: "Buritis",
        uf: "RO",
        estadoCod: 22,
        cod: 28,
      },
      {
        nome: "Novo Horizonte do Oeste",
        uf: "RO",
        estadoCod: 22,
        cod: 29,
      },
      {
        nome: "Cacaulândia",
        uf: "RO",
        estadoCod: 22,
        cod: 30,
      },
      {
        nome: "Campo Novo de Rondônia",
        uf: "RO",
        estadoCod: 22,
        cod: 31,
      },
      {
        nome: "Candeias do Jamari",
        uf: "RO",
        estadoCod: 22,
        cod: 32,
      },
      {
        nome: "Castanheiras",
        uf: "RO",
        estadoCod: 22,
        cod: 33,
      },
      {
        nome: "Chupinguaia",
        uf: "RO",
        estadoCod: 22,
        cod: 34,
      },
      {
        nome: "Cujubim",
        uf: "RO",
        estadoCod: 22,
        cod: 35,
      },
      {
        nome: "Governador Jorge Teixeira",
        uf: "RO",
        estadoCod: 22,
        cod: 36,
      },
      {
        nome: "Itapuã do Oeste",
        uf: "RO",
        estadoCod: 22,
        cod: 37,
      },
      {
        nome: "Ministro Andreazza",
        uf: "RO",
        estadoCod: 22,
        cod: 38,
      },
      {
        nome: "Mirante da Serra",
        uf: "RO",
        estadoCod: 22,
        cod: 39,
      },
      {
        nome: "Monte Negro",
        uf: "RO",
        estadoCod: 22,
        cod: 40,
      },
      {
        nome: "Nova União",
        uf: "RO",
        estadoCod: 22,
        cod: 41,
      },
      {
        nome: "Parecis",
        uf: "RO",
        estadoCod: 22,
        cod: 42,
      },
      {
        nome: "Pimenteiras do Oeste",
        uf: "RO",
        estadoCod: 22,
        cod: 43,
      },
      {
        nome: "Primavera de Rondônia",
        uf: "RO",
        estadoCod: 22,
        cod: 44,
      },
      {
        nome: "São Felipe D'Oeste",
        uf: "RO",
        estadoCod: 22,
        cod: 45,
      },
      {
        nome: "São Francisco do Guaporé",
        uf: "RO",
        estadoCod: 22,
        cod: 46,
      },
      {
        nome: "Seringueiras",
        uf: "RO",
        estadoCod: 22,
        cod: 47,
      },
      {
        nome: "Teixeirópolis",
        uf: "RO",
        estadoCod: 22,
        cod: 48,
      },
      {
        nome: "Theobroma",
        uf: "RO",
        estadoCod: 22,
        cod: 49,
      },
      {
        nome: "Urupá",
        uf: "RO",
        estadoCod: 22,
        cod: 50,
      },
      {
        nome: "Vale do Anari",
        uf: "RO",
        estadoCod: 22,
        cod: 51,
      },
      {
        nome: "Vale do Paraíso",
        uf: "RO",
        estadoCod: 22,
        cod: 52,
      },
    ],
  },
  {
    nome: "Acre",
    uf: "AC",
    regiaoCod: 1,
    cod: 1,
    cidades: [
      {
        nome: "Acrelândia",
        uf: "AC",
        estadoCod: 1,
        cod: 53,
      },
      {
        nome: "Assis Brasil",
        uf: "AC",
        estadoCod: 1,
        cod: 54,
      },
      {
        nome: "Brasiléia",
        uf: "AC",
        estadoCod: 1,
        cod: 55,
      },
      {
        nome: "Bujari",
        uf: "AC",
        estadoCod: 1,
        cod: 56,
      },
      {
        nome: "Capixaba",
        uf: "AC",
        estadoCod: 1,
        cod: 57,
      },
      {
        nome: "Cruzeiro do Sul",
        uf: "AC",
        estadoCod: 1,
        cod: 58,
      },
      {
        nome: "Epitaciolândia",
        uf: "AC",
        estadoCod: 1,
        cod: 59,
      },
      {
        nome: "Feijó",
        uf: "AC",
        estadoCod: 1,
        cod: 60,
      },
      {
        nome: "Jordão",
        uf: "AC",
        estadoCod: 1,
        cod: 61,
      },
      {
        nome: "Mâncio Lima",
        uf: "AC",
        estadoCod: 1,
        cod: 62,
      },
      {
        nome: "Manoel Urbano",
        uf: "AC",
        estadoCod: 1,
        cod: 63,
      },
      {
        nome: "Marechal Thaumaturgo",
        uf: "AC",
        estadoCod: 1,
        cod: 64,
      },
      {
        nome: "Plácido de Castro",
        uf: "AC",
        estadoCod: 1,
        cod: 65,
      },
      {
        nome: "Porto Walter",
        uf: "AC",
        estadoCod: 1,
        cod: 66,
      },
      {
        nome: "Rio Branco",
        uf: "AC",
        estadoCod: 1,
        cod: 67,
      },
      {
        nome: "Rodrigues Alves",
        uf: "AC",
        estadoCod: 1,
        cod: 68,
      },
      {
        nome: "Santa Rosa do Purus",
        uf: "AC",
        estadoCod: 1,
        cod: 69,
      },
      {
        nome: "Senador Guiomard",
        uf: "AC",
        estadoCod: 1,
        cod: 70,
      },
      {
        nome: "Sena Madureira",
        uf: "AC",
        estadoCod: 1,
        cod: 71,
      },
      {
        nome: "Tarauacá",
        uf: "AC",
        estadoCod: 1,
        cod: 72,
      },
      {
        nome: "Xapuri",
        uf: "AC",
        estadoCod: 1,
        cod: 73,
      },
      {
        nome: "Porto Acre",
        uf: "AC",
        estadoCod: 1,
        cod: 74,
      },
    ],
  },
  {
    nome: "Amazonas",
    uf: "AM",
    regiaoCod: 1,
    cod: 4,
    cidades: [
      {
        nome: "Alvarães",
        uf: "AM",
        estadoCod: 4,
        cod: 75,
      },
      {
        nome: "Amaturá",
        uf: "AM",
        estadoCod: 4,
        cod: 76,
      },
      {
        nome: "Anamã",
        uf: "AM",
        estadoCod: 4,
        cod: 77,
      },
      {
        nome: "Anori",
        uf: "AM",
        estadoCod: 4,
        cod: 78,
      },
      {
        nome: "Apuí",
        uf: "AM",
        estadoCod: 4,
        cod: 79,
      },
      {
        nome: "Atalaia do Norte",
        uf: "AM",
        estadoCod: 4,
        cod: 80,
      },
      {
        nome: "Autazes",
        uf: "AM",
        estadoCod: 4,
        cod: 81,
      },
      {
        nome: "Barcelos",
        uf: "AM",
        estadoCod: 4,
        cod: 82,
      },
      {
        nome: "Barreirinha",
        uf: "AM",
        estadoCod: 4,
        cod: 83,
      },
      {
        nome: "Benjamin Constant",
        uf: "AM",
        estadoCod: 4,
        cod: 84,
      },
      {
        nome: "Beruri",
        uf: "AM",
        estadoCod: 4,
        cod: 85,
      },
      {
        nome: "Boa Vista do Ramos",
        uf: "AM",
        estadoCod: 4,
        cod: 86,
      },
      {
        nome: "Boca do Acre",
        uf: "AM",
        estadoCod: 4,
        cod: 87,
      },
      {
        nome: "Borba",
        uf: "AM",
        estadoCod: 4,
        cod: 88,
      },
      {
        nome: "Caapiranga",
        uf: "AM",
        estadoCod: 4,
        cod: 89,
      },
      {
        nome: "Canutama",
        uf: "AM",
        estadoCod: 4,
        cod: 90,
      },
      {
        nome: "Carauari",
        uf: "AM",
        estadoCod: 4,
        cod: 91,
      },
      {
        nome: "Careiro",
        uf: "AM",
        estadoCod: 4,
        cod: 92,
      },
      {
        nome: "Careiro da Várzea",
        uf: "AM",
        estadoCod: 4,
        cod: 93,
      },
      {
        nome: "Coari",
        uf: "AM",
        estadoCod: 4,
        cod: 94,
      },
      {
        nome: "Codajás",
        uf: "AM",
        estadoCod: 4,
        cod: 95,
      },
      {
        nome: "Eirunepé",
        uf: "AM",
        estadoCod: 4,
        cod: 96,
      },
      {
        nome: "Envira",
        uf: "AM",
        estadoCod: 4,
        cod: 97,
      },
      {
        nome: "Fonte Boa",
        uf: "AM",
        estadoCod: 4,
        cod: 98,
      },
      {
        nome: "Guajará",
        uf: "AM",
        estadoCod: 4,
        cod: 99,
      },
      {
        nome: "Humaitá",
        uf: "AM",
        estadoCod: 4,
        cod: 100,
      },
      {
        nome: "Ipixuna",
        uf: "AM",
        estadoCod: 4,
        cod: 101,
      },
      {
        nome: "Iranduba",
        uf: "AM",
        estadoCod: 4,
        cod: 102,
      },
      {
        nome: "Itacoatiara",
        uf: "AM",
        estadoCod: 4,
        cod: 103,
      },
      {
        nome: "Itamarati",
        uf: "AM",
        estadoCod: 4,
        cod: 104,
      },
      {
        nome: "Itapiranga",
        uf: "AM",
        estadoCod: 4,
        cod: 105,
      },
      {
        nome: "Japurá",
        uf: "AM",
        estadoCod: 4,
        cod: 106,
      },
      {
        nome: "Juruá",
        uf: "AM",
        estadoCod: 4,
        cod: 107,
      },
      {
        nome: "Jutaí",
        uf: "AM",
        estadoCod: 4,
        cod: 108,
      },
      {
        nome: "Lábrea",
        uf: "AM",
        estadoCod: 4,
        cod: 109,
      },
      {
        nome: "Manacapuru",
        uf: "AM",
        estadoCod: 4,
        cod: 110,
      },
      {
        nome: "Manaquiri",
        uf: "AM",
        estadoCod: 4,
        cod: 111,
      },
      {
        nome: "Manaus",
        uf: "AM",
        estadoCod: 4,
        cod: 112,
      },
      {
        nome: "Manicoré",
        uf: "AM",
        estadoCod: 4,
        cod: 113,
      },
      {
        nome: "Maraã",
        uf: "AM",
        estadoCod: 4,
        cod: 114,
      },
      {
        nome: "Maués",
        uf: "AM",
        estadoCod: 4,
        cod: 115,
      },
      {
        nome: "Nhamundá",
        uf: "AM",
        estadoCod: 4,
        cod: 116,
      },
      {
        nome: "Nova Olinda do Norte",
        uf: "AM",
        estadoCod: 4,
        cod: 117,
      },
      {
        nome: "Novo Airão",
        uf: "AM",
        estadoCod: 4,
        cod: 118,
      },
      {
        nome: "Novo Aripuanã",
        uf: "AM",
        estadoCod: 4,
        cod: 119,
      },
      {
        nome: "Parintins",
        uf: "AM",
        estadoCod: 4,
        cod: 120,
      },
      {
        nome: "Pauini",
        uf: "AM",
        estadoCod: 4,
        cod: 121,
      },
      {
        nome: "Presidente Figueiredo",
        uf: "AM",
        estadoCod: 4,
        cod: 122,
      },
      {
        nome: "Rio Preto da Eva",
        uf: "AM",
        estadoCod: 4,
        cod: 123,
      },
      {
        nome: "Santa Isabel do Rio Negro",
        uf: "AM",
        estadoCod: 4,
        cod: 124,
      },
      {
        nome: "Santo Antônio do Içá",
        uf: "AM",
        estadoCod: 4,
        cod: 125,
      },
      {
        nome: "São Gabriel da Cachoeira",
        uf: "AM",
        estadoCod: 4,
        cod: 126,
      },
      {
        nome: "São Paulo de Olivença",
        uf: "AM",
        estadoCod: 4,
        cod: 127,
      },
      {
        nome: "São Sebastião do Uatumã",
        uf: "AM",
        estadoCod: 4,
        cod: 128,
      },
      {
        nome: "Silves",
        uf: "AM",
        estadoCod: 4,
        cod: 129,
      },
      {
        nome: "Tabatinga",
        uf: "AM",
        estadoCod: 4,
        cod: 130,
      },
      {
        nome: "Tapauá",
        uf: "AM",
        estadoCod: 4,
        cod: 131,
      },
      {
        nome: "Tefé",
        uf: "AM",
        estadoCod: 4,
        cod: 132,
      },
      {
        nome: "Tonantins",
        uf: "AM",
        estadoCod: 4,
        cod: 133,
      },
      {
        nome: "Uarini",
        uf: "AM",
        estadoCod: 4,
        cod: 134,
      },
      {
        nome: "Urucará",
        uf: "AM",
        estadoCod: 4,
        cod: 135,
      },
      {
        nome: "Urucurituba",
        uf: "AM",
        estadoCod: 4,
        cod: 136,
      },
    ],
  },
  {
    nome: "Roraima",
    uf: "RR",
    regiaoCod: 1,
    cod: 23,
    cidades: [
      {
        nome: "Amajari",
        uf: "RR",
        estadoCod: 23,
        cod: 137,
      },
      {
        nome: "Alto Alegre",
        uf: "RR",
        estadoCod: 23,
        cod: 138,
      },
      {
        nome: "Boa Vista",
        uf: "RR",
        estadoCod: 23,
        cod: 139,
      },
      {
        nome: "Bonfim",
        uf: "RR",
        estadoCod: 23,
        cod: 140,
      },
      {
        nome: "Cantá",
        uf: "RR",
        estadoCod: 23,
        cod: 141,
      },
      {
        nome: "Caracaraí",
        uf: "RR",
        estadoCod: 23,
        cod: 142,
      },
      {
        nome: "Caroebe",
        uf: "RR",
        estadoCod: 23,
        cod: 143,
      },
      {
        nome: "Iracema",
        uf: "RR",
        estadoCod: 23,
        cod: 144,
      },
      {
        nome: "Mucajaí",
        uf: "RR",
        estadoCod: 23,
        cod: 145,
      },
      {
        nome: "Normandia",
        uf: "RR",
        estadoCod: 23,
        cod: 146,
      },
      {
        nome: "Pacaraima",
        uf: "RR",
        estadoCod: 23,
        cod: 147,
      },
      {
        nome: "Rorainópolis",
        uf: "RR",
        estadoCod: 23,
        cod: 148,
      },
      {
        nome: "São João da Baliza",
        uf: "RR",
        estadoCod: 23,
        cod: 149,
      },
      {
        nome: "São Luiz",
        uf: "RR",
        estadoCod: 23,
        cod: 150,
      },
      {
        nome: "Uiramutã",
        uf: "RR",
        estadoCod: 23,
        cod: 151,
      },
    ],
  },
  {
    nome: "Pará",
    uf: "PA",
    regiaoCod: 1,
    cod: 14,
    cidades: [
      {
        nome: "Abaetetuba",
        uf: "PA",
        estadoCod: 14,
        cod: 152,
      },
      {
        nome: "Abel Figueiredo",
        uf: "PA",
        estadoCod: 14,
        cod: 153,
      },
      {
        nome: "Acará",
        uf: "PA",
        estadoCod: 14,
        cod: 154,
      },
      {
        nome: "Afuá",
        uf: "PA",
        estadoCod: 14,
        cod: 155,
      },
      {
        nome: "Água Azul do Norte",
        uf: "PA",
        estadoCod: 14,
        cod: 156,
      },
      {
        nome: "Alenquer",
        uf: "PA",
        estadoCod: 14,
        cod: 157,
      },
      {
        nome: "Almeirim",
        uf: "PA",
        estadoCod: 14,
        cod: 158,
      },
      {
        nome: "Altamira",
        uf: "PA",
        estadoCod: 14,
        cod: 159,
      },
      {
        nome: "Anajás",
        uf: "PA",
        estadoCod: 14,
        cod: 160,
      },
      {
        nome: "Ananindeua",
        uf: "PA",
        estadoCod: 14,
        cod: 161,
      },
      {
        nome: "Anapu",
        uf: "PA",
        estadoCod: 14,
        cod: 162,
      },
      {
        nome: "Augusto Corrêa",
        uf: "PA",
        estadoCod: 14,
        cod: 163,
      },
      {
        nome: "Aurora do Pará",
        uf: "PA",
        estadoCod: 14,
        cod: 164,
      },
      {
        nome: "Aveiro",
        uf: "PA",
        estadoCod: 14,
        cod: 165,
      },
      {
        nome: "Bagre",
        uf: "PA",
        estadoCod: 14,
        cod: 166,
      },
      {
        nome: "Baião",
        uf: "PA",
        estadoCod: 14,
        cod: 167,
      },
      {
        nome: "Bannach",
        uf: "PA",
        estadoCod: 14,
        cod: 168,
      },
      {
        nome: "Barcarena",
        uf: "PA",
        estadoCod: 14,
        cod: 169,
      },
      {
        nome: "Belém",
        uf: "PA",
        estadoCod: 14,
        cod: 170,
      },
      {
        nome: "Belterra",
        uf: "PA",
        estadoCod: 14,
        cod: 171,
      },
      {
        nome: "Benevides",
        uf: "PA",
        estadoCod: 14,
        cod: 172,
      },
      {
        nome: "Bom Jesus do Tocantins",
        uf: "PA",
        estadoCod: 14,
        cod: 173,
      },
      {
        nome: "Bonito",
        uf: "PA",
        estadoCod: 14,
        cod: 174,
      },
      {
        nome: "Bragança",
        uf: "PA",
        estadoCod: 14,
        cod: 175,
      },
      {
        nome: "Brasil Novo",
        uf: "PA",
        estadoCod: 14,
        cod: 176,
      },
      {
        nome: "Brejo Grande do Araguaia",
        uf: "PA",
        estadoCod: 14,
        cod: 177,
      },
      {
        nome: "Breu Branco",
        uf: "PA",
        estadoCod: 14,
        cod: 178,
      },
      {
        nome: "Breves",
        uf: "PA",
        estadoCod: 14,
        cod: 179,
      },
      {
        nome: "Bujaru",
        uf: "PA",
        estadoCod: 14,
        cod: 180,
      },
      {
        nome: "Cachoeira do Piriá",
        uf: "PA",
        estadoCod: 14,
        cod: 181,
      },
      {
        nome: "Cachoeira do Arari",
        uf: "PA",
        estadoCod: 14,
        cod: 182,
      },
      {
        nome: "Cametá",
        uf: "PA",
        estadoCod: 14,
        cod: 183,
      },
      {
        nome: "Canaã dos Carajás",
        uf: "PA",
        estadoCod: 14,
        cod: 184,
      },
      {
        nome: "Capanema",
        uf: "PA",
        estadoCod: 14,
        cod: 185,
      },
      {
        nome: "Capitão Poço",
        uf: "PA",
        estadoCod: 14,
        cod: 186,
      },
      {
        nome: "Castanhal",
        uf: "PA",
        estadoCod: 14,
        cod: 187,
      },
      {
        nome: "Chaves",
        uf: "PA",
        estadoCod: 14,
        cod: 188,
      },
      {
        nome: "Colares",
        uf: "PA",
        estadoCod: 14,
        cod: 189,
      },
      {
        nome: "Conceição do Araguaia",
        uf: "PA",
        estadoCod: 14,
        cod: 190,
      },
      {
        nome: "Concórdia do Pará",
        uf: "PA",
        estadoCod: 14,
        cod: 191,
      },
      {
        nome: "Cumaru do Norte",
        uf: "PA",
        estadoCod: 14,
        cod: 192,
      },
      {
        nome: "Curionópolis",
        uf: "PA",
        estadoCod: 14,
        cod: 193,
      },
      {
        nome: "Curralinho",
        uf: "PA",
        estadoCod: 14,
        cod: 194,
      },
      {
        nome: "Curuá",
        uf: "PA",
        estadoCod: 14,
        cod: 195,
      },
      {
        nome: "Curuçá",
        uf: "PA",
        estadoCod: 14,
        cod: 196,
      },
      {
        nome: "Dom Eliseu",
        uf: "PA",
        estadoCod: 14,
        cod: 197,
      },
      {
        nome: "Eldorado dos Carajás",
        uf: "PA",
        estadoCod: 14,
        cod: 198,
      },
      {
        nome: "Faro",
        uf: "PA",
        estadoCod: 14,
        cod: 199,
      },
      {
        nome: "Floresta do Araguaia",
        uf: "PA",
        estadoCod: 14,
        cod: 200,
      },
      {
        nome: "Garrafão do Norte",
        uf: "PA",
        estadoCod: 14,
        cod: 201,
      },
      {
        nome: "Goianésia do Pará",
        uf: "PA",
        estadoCod: 14,
        cod: 202,
      },
      {
        nome: "Gurupá",
        uf: "PA",
        estadoCod: 14,
        cod: 203,
      },
      {
        nome: "Igarapé-Açu",
        uf: "PA",
        estadoCod: 14,
        cod: 204,
      },
      {
        nome: "Igarapé-Miri",
        uf: "PA",
        estadoCod: 14,
        cod: 205,
      },
      {
        nome: "Inhangapi",
        uf: "PA",
        estadoCod: 14,
        cod: 206,
      },
      {
        nome: "Ipixuna do Pará",
        uf: "PA",
        estadoCod: 14,
        cod: 207,
      },
      {
        nome: "Irituia",
        uf: "PA",
        estadoCod: 14,
        cod: 208,
      },
      {
        nome: "Itaituba",
        uf: "PA",
        estadoCod: 14,
        cod: 209,
      },
      {
        nome: "Itupiranga",
        uf: "PA",
        estadoCod: 14,
        cod: 210,
      },
      {
        nome: "Jacareacanga",
        uf: "PA",
        estadoCod: 14,
        cod: 211,
      },
      {
        nome: "Jacundá",
        uf: "PA",
        estadoCod: 14,
        cod: 212,
      },
      {
        nome: "Juruti",
        uf: "PA",
        estadoCod: 14,
        cod: 213,
      },
      {
        nome: "Limoeiro do Ajuru",
        uf: "PA",
        estadoCod: 14,
        cod: 214,
      },
      {
        nome: "Mãe do Rio",
        uf: "PA",
        estadoCod: 14,
        cod: 215,
      },
      {
        nome: "Magalhães Barata",
        uf: "PA",
        estadoCod: 14,
        cod: 216,
      },
      {
        nome: "Marabá",
        uf: "PA",
        estadoCod: 14,
        cod: 217,
      },
      {
        nome: "Maracanã",
        uf: "PA",
        estadoCod: 14,
        cod: 218,
      },
      {
        nome: "Marapanim",
        uf: "PA",
        estadoCod: 14,
        cod: 219,
      },
      {
        nome: "Marituba",
        uf: "PA",
        estadoCod: 14,
        cod: 220,
      },
      {
        nome: "Medicilândia",
        uf: "PA",
        estadoCod: 14,
        cod: 221,
      },
      {
        nome: "Melgaço",
        uf: "PA",
        estadoCod: 14,
        cod: 222,
      },
      {
        nome: "Mocajuba",
        uf: "PA",
        estadoCod: 14,
        cod: 223,
      },
      {
        nome: "Moju",
        uf: "PA",
        estadoCod: 14,
        cod: 224,
      },
      {
        nome: "Mojuí dos Campos",
        uf: "PA",
        estadoCod: 14,
        cod: 225,
      },
      {
        nome: "Monte Alegre",
        uf: "PA",
        estadoCod: 14,
        cod: 226,
      },
      {
        nome: "Muaná",
        uf: "PA",
        estadoCod: 14,
        cod: 227,
      },
      {
        nome: "Nova Esperança do Piriá",
        uf: "PA",
        estadoCod: 14,
        cod: 228,
      },
      {
        nome: "Nova Ipixuna",
        uf: "PA",
        estadoCod: 14,
        cod: 229,
      },
      {
        nome: "Nova Timboteua",
        uf: "PA",
        estadoCod: 14,
        cod: 230,
      },
      {
        nome: "Novo Progresso",
        uf: "PA",
        estadoCod: 14,
        cod: 231,
      },
      {
        nome: "Novo Repartimento",
        uf: "PA",
        estadoCod: 14,
        cod: 232,
      },
      {
        nome: "Óbidos",
        uf: "PA",
        estadoCod: 14,
        cod: 233,
      },
      {
        nome: "Oeiras do Pará",
        uf: "PA",
        estadoCod: 14,
        cod: 234,
      },
      {
        nome: "Oriximiná",
        uf: "PA",
        estadoCod: 14,
        cod: 235,
      },
      {
        nome: "Ourém",
        uf: "PA",
        estadoCod: 14,
        cod: 236,
      },
      {
        nome: "Ourilândia do Norte",
        uf: "PA",
        estadoCod: 14,
        cod: 237,
      },
      {
        nome: "Pacajá",
        uf: "PA",
        estadoCod: 14,
        cod: 238,
      },
      {
        nome: "Palestina do Pará",
        uf: "PA",
        estadoCod: 14,
        cod: 239,
      },
      {
        nome: "Paragominas",
        uf: "PA",
        estadoCod: 14,
        cod: 240,
      },
      {
        nome: "Parauapebas",
        uf: "PA",
        estadoCod: 14,
        cod: 241,
      },
      {
        nome: "Pau D'Arco",
        uf: "PA",
        estadoCod: 14,
        cod: 242,
      },
      {
        nome: "Peixe-Boi",
        uf: "PA",
        estadoCod: 14,
        cod: 243,
      },
      {
        nome: "Piçarra",
        uf: "PA",
        estadoCod: 14,
        cod: 244,
      },
      {
        nome: "Placas",
        uf: "PA",
        estadoCod: 14,
        cod: 245,
      },
      {
        nome: "Ponta de Pedras",
        uf: "PA",
        estadoCod: 14,
        cod: 246,
      },
      {
        nome: "Portel",
        uf: "PA",
        estadoCod: 14,
        cod: 247,
      },
      {
        nome: "Porto de Moz",
        uf: "PA",
        estadoCod: 14,
        cod: 248,
      },
      {
        nome: "Prainha",
        uf: "PA",
        estadoCod: 14,
        cod: 249,
      },
      {
        nome: "Primavera",
        uf: "PA",
        estadoCod: 14,
        cod: 250,
      },
      {
        nome: "Quatipuru",
        uf: "PA",
        estadoCod: 14,
        cod: 251,
      },
      {
        nome: "Redenção",
        uf: "PA",
        estadoCod: 14,
        cod: 252,
      },
      {
        nome: "Rio Maria",
        uf: "PA",
        estadoCod: 14,
        cod: 253,
      },
      {
        nome: "Rondon do Pará",
        uf: "PA",
        estadoCod: 14,
        cod: 254,
      },
      {
        nome: "Rurópolis",
        uf: "PA",
        estadoCod: 14,
        cod: 255,
      },
      {
        nome: "Salinópolis",
        uf: "PA",
        estadoCod: 14,
        cod: 256,
      },
      {
        nome: "Salvaterra",
        uf: "PA",
        estadoCod: 14,
        cod: 257,
      },
      {
        nome: "Santa Bárbara do Pará",
        uf: "PA",
        estadoCod: 14,
        cod: 258,
      },
      {
        nome: "Santa Cruz do Arari",
        uf: "PA",
        estadoCod: 14,
        cod: 259,
      },
      {
        nome: "Santa Isabel do Pará",
        uf: "PA",
        estadoCod: 14,
        cod: 260,
      },
      {
        nome: "Santa Luzia do Pará",
        uf: "PA",
        estadoCod: 14,
        cod: 261,
      },
      {
        nome: "Santa Maria das Barreiras",
        uf: "PA",
        estadoCod: 14,
        cod: 262,
      },
      {
        nome: "Santa Maria do Pará",
        uf: "PA",
        estadoCod: 14,
        cod: 263,
      },
      {
        nome: "Santana do Araguaia",
        uf: "PA",
        estadoCod: 14,
        cod: 264,
      },
      {
        nome: "Santarém",
        uf: "PA",
        estadoCod: 14,
        cod: 265,
      },
      {
        nome: "Santarém Novo",
        uf: "PA",
        estadoCod: 14,
        cod: 266,
      },
      {
        nome: "Santo Antônio do Tauá",
        uf: "PA",
        estadoCod: 14,
        cod: 267,
      },
      {
        nome: "São Caetano de Odivelas",
        uf: "PA",
        estadoCod: 14,
        cod: 268,
      },
      {
        nome: "São Domingos do Araguaia",
        uf: "PA",
        estadoCod: 14,
        cod: 269,
      },
      {
        nome: "São Domingos do Capim",
        uf: "PA",
        estadoCod: 14,
        cod: 270,
      },
      {
        nome: "São Félix do Xingu",
        uf: "PA",
        estadoCod: 14,
        cod: 271,
      },
      {
        nome: "São Francisco do Pará",
        uf: "PA",
        estadoCod: 14,
        cod: 272,
      },
      {
        nome: "São Geraldo do Araguaia",
        uf: "PA",
        estadoCod: 14,
        cod: 273,
      },
      {
        nome: "São João da Ponta",
        uf: "PA",
        estadoCod: 14,
        cod: 274,
      },
      {
        nome: "São João de Pirabas",
        uf: "PA",
        estadoCod: 14,
        cod: 275,
      },
      {
        nome: "São João do Araguaia",
        uf: "PA",
        estadoCod: 14,
        cod: 276,
      },
      {
        nome: "São Miguel do Guamá",
        uf: "PA",
        estadoCod: 14,
        cod: 277,
      },
      {
        nome: "São Sebastião da Boa Vista",
        uf: "PA",
        estadoCod: 14,
        cod: 278,
      },
      {
        nome: "Sapucaia",
        uf: "PA",
        estadoCod: 14,
        cod: 279,
      },
      {
        nome: "Senador José Porfírio",
        uf: "PA",
        estadoCod: 14,
        cod: 280,
      },
      {
        nome: "Soure",
        uf: "PA",
        estadoCod: 14,
        cod: 281,
      },
      {
        nome: "Tailândia",
        uf: "PA",
        estadoCod: 14,
        cod: 282,
      },
      {
        nome: "Terra Alta",
        uf: "PA",
        estadoCod: 14,
        cod: 283,
      },
      {
        nome: "Terra Santa",
        uf: "PA",
        estadoCod: 14,
        cod: 284,
      },
      {
        nome: "Tomé-Açu",
        uf: "PA",
        estadoCod: 14,
        cod: 285,
      },
      {
        nome: "Tracuateua",
        uf: "PA",
        estadoCod: 14,
        cod: 286,
      },
      {
        nome: "Trairão",
        uf: "PA",
        estadoCod: 14,
        cod: 287,
      },
      {
        nome: "Tucumã",
        uf: "PA",
        estadoCod: 14,
        cod: 288,
      },
      {
        nome: "Tucuruí",
        uf: "PA",
        estadoCod: 14,
        cod: 289,
      },
      {
        nome: "Ulianópolis",
        uf: "PA",
        estadoCod: 14,
        cod: 290,
      },
      {
        nome: "Uruará",
        uf: "PA",
        estadoCod: 14,
        cod: 291,
      },
      {
        nome: "Vigia",
        uf: "PA",
        estadoCod: 14,
        cod: 292,
      },
      {
        nome: "Viseu",
        uf: "PA",
        estadoCod: 14,
        cod: 293,
      },
      {
        nome: "Vitória do Xingu",
        uf: "PA",
        estadoCod: 14,
        cod: 294,
      },
      {
        nome: "Xinguara",
        uf: "PA",
        estadoCod: 14,
        cod: 295,
      },
    ],
  },
  {
    nome: "Amapá",
    uf: "AP",
    regiaoCod: 1,
    cod: 3,
    cidades: [
      {
        nome: "Serra do Navio",
        uf: "AP",
        estadoCod: 3,
        cod: 296,
      },
      {
        nome: "Amapá",
        uf: "AP",
        estadoCod: 3,
        cod: 297,
      },
      {
        nome: "Pedra Branca do Amapari",
        uf: "AP",
        estadoCod: 3,
        cod: 298,
      },
      {
        nome: "Calçoene",
        uf: "AP",
        estadoCod: 3,
        cod: 299,
      },
      {
        nome: "Cutias",
        uf: "AP",
        estadoCod: 3,
        cod: 300,
      },
      {
        nome: "Ferreira Gomes",
        uf: "AP",
        estadoCod: 3,
        cod: 301,
      },
      {
        nome: "Itaubal",
        uf: "AP",
        estadoCod: 3,
        cod: 302,
      },
      {
        nome: "Laranjal do Jari",
        uf: "AP",
        estadoCod: 3,
        cod: 303,
      },
      {
        nome: "Macapá",
        uf: "AP",
        estadoCod: 3,
        cod: 304,
      },
      {
        nome: "Mazagão",
        uf: "AP",
        estadoCod: 3,
        cod: 305,
      },
      {
        nome: "Oiapoque",
        uf: "AP",
        estadoCod: 3,
        cod: 306,
      },
      {
        nome: "Porto Grande",
        uf: "AP",
        estadoCod: 3,
        cod: 307,
      },
      {
        nome: "Pracuúba",
        uf: "AP",
        estadoCod: 3,
        cod: 308,
      },
      {
        nome: "Santana",
        uf: "AP",
        estadoCod: 3,
        cod: 309,
      },
      {
        nome: "Tartarugalzinho",
        uf: "AP",
        estadoCod: 3,
        cod: 310,
      },
      {
        nome: "Vitória do Jari",
        uf: "AP",
        estadoCod: 3,
        cod: 311,
      },
    ],
  },
  {
    nome: "Tocantins",
    uf: "TO",
    regiaoCod: 1,
    cod: 27,
    cidades: [
      {
        nome: "Abreulândia",
        uf: "TO",
        estadoCod: 27,
        cod: 312,
      },
      {
        nome: "Aguiarnópolis",
        uf: "TO",
        estadoCod: 27,
        cod: 313,
      },
      {
        nome: "Aliança do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 314,
      },
      {
        nome: "Almas",
        uf: "TO",
        estadoCod: 27,
        cod: 315,
      },
      {
        nome: "Alvorada",
        uf: "TO",
        estadoCod: 27,
        cod: 316,
      },
      {
        nome: "Ananás",
        uf: "TO",
        estadoCod: 27,
        cod: 317,
      },
      {
        nome: "Angico",
        uf: "TO",
        estadoCod: 27,
        cod: 318,
      },
      {
        nome: "Aparecida do Rio Negro",
        uf: "TO",
        estadoCod: 27,
        cod: 319,
      },
      {
        nome: "Aragominas",
        uf: "TO",
        estadoCod: 27,
        cod: 320,
      },
      {
        nome: "Araguacema",
        uf: "TO",
        estadoCod: 27,
        cod: 321,
      },
      {
        nome: "Araguaçu",
        uf: "TO",
        estadoCod: 27,
        cod: 322,
      },
      {
        nome: "Araguaína",
        uf: "TO",
        estadoCod: 27,
        cod: 323,
      },
      {
        nome: "Araguanã",
        uf: "TO",
        estadoCod: 27,
        cod: 324,
      },
      {
        nome: "Araguatins",
        uf: "TO",
        estadoCod: 27,
        cod: 325,
      },
      {
        nome: "Arapoema",
        uf: "TO",
        estadoCod: 27,
        cod: 326,
      },
      {
        nome: "Arraias",
        uf: "TO",
        estadoCod: 27,
        cod: 327,
      },
      {
        nome: "Augustinópolis",
        uf: "TO",
        estadoCod: 27,
        cod: 328,
      },
      {
        nome: "Aurora do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 329,
      },
      {
        nome: "Axixá do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 330,
      },
      {
        nome: "Babaçulândia",
        uf: "TO",
        estadoCod: 27,
        cod: 331,
      },
      {
        nome: "Bandeirantes do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 332,
      },
      {
        nome: "Barra do Ouro",
        uf: "TO",
        estadoCod: 27,
        cod: 333,
      },
      {
        nome: "Barrolândia",
        uf: "TO",
        estadoCod: 27,
        cod: 334,
      },
      {
        nome: "Bernardo Sayão",
        uf: "TO",
        estadoCod: 27,
        cod: 335,
      },
      {
        nome: "Bom Jesus do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 336,
      },
      {
        nome: "Brasilândia do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 337,
      },
      {
        nome: "Brejinho de Nazaré",
        uf: "TO",
        estadoCod: 27,
        cod: 338,
      },
      {
        nome: "Buriti do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 339,
      },
      {
        nome: "Cachoeirinha",
        uf: "TO",
        estadoCod: 27,
        cod: 340,
      },
      {
        nome: "Campos Lindos",
        uf: "TO",
        estadoCod: 27,
        cod: 341,
      },
      {
        nome: "Cariri do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 342,
      },
      {
        nome: "Carmolândia",
        uf: "TO",
        estadoCod: 27,
        cod: 343,
      },
      {
        nome: "Carrasco Bonito",
        uf: "TO",
        estadoCod: 27,
        cod: 344,
      },
      {
        nome: "Caseara",
        uf: "TO",
        estadoCod: 27,
        cod: 345,
      },
      {
        nome: "Centenário",
        uf: "TO",
        estadoCod: 27,
        cod: 346,
      },
      {
        nome: "Chapada de Areia",
        uf: "TO",
        estadoCod: 27,
        cod: 347,
      },
      {
        nome: "Chapada da Natividade",
        uf: "TO",
        estadoCod: 27,
        cod: 348,
      },
      {
        nome: "Colinas do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 349,
      },
      {
        nome: "Combinado",
        uf: "TO",
        estadoCod: 27,
        cod: 350,
      },
      {
        nome: "Conceição do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 351,
      },
      {
        nome: "Couto Magalhães",
        uf: "TO",
        estadoCod: 27,
        cod: 352,
      },
      {
        nome: "Cristalândia",
        uf: "TO",
        estadoCod: 27,
        cod: 353,
      },
      {
        nome: "Crixás do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 354,
      },
      {
        nome: "Darcinópolis",
        uf: "TO",
        estadoCod: 27,
        cod: 355,
      },
      {
        nome: "Dianópolis",
        uf: "TO",
        estadoCod: 27,
        cod: 356,
      },
      {
        nome: "Divinópolis do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 357,
      },
      {
        nome: "Dois Irmãos do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 358,
      },
      {
        nome: "Dueré",
        uf: "TO",
        estadoCod: 27,
        cod: 359,
      },
      {
        nome: "Esperantina",
        uf: "TO",
        estadoCod: 27,
        cod: 360,
      },
      {
        nome: "Fátima",
        uf: "TO",
        estadoCod: 27,
        cod: 361,
      },
      {
        nome: "Figueirópolis",
        uf: "TO",
        estadoCod: 27,
        cod: 362,
      },
      {
        nome: "Filadélfia",
        uf: "TO",
        estadoCod: 27,
        cod: 363,
      },
      {
        nome: "Formoso do Araguaia",
        uf: "TO",
        estadoCod: 27,
        cod: 364,
      },
      {
        nome: "Fortaleza do Tabocão",
        uf: "TO",
        estadoCod: 27,
        cod: 365,
      },
      {
        nome: "Goianorte",
        uf: "TO",
        estadoCod: 27,
        cod: 366,
      },
      {
        nome: "Goiatins",
        uf: "TO",
        estadoCod: 27,
        cod: 367,
      },
      {
        nome: "Guaraí",
        uf: "TO",
        estadoCod: 27,
        cod: 368,
      },
      {
        nome: "Gurupi",
        uf: "TO",
        estadoCod: 27,
        cod: 369,
      },
      {
        nome: "Ipueiras",
        uf: "TO",
        estadoCod: 27,
        cod: 370,
      },
      {
        nome: "Itacajá",
        uf: "TO",
        estadoCod: 27,
        cod: 371,
      },
      {
        nome: "Itaguatins",
        uf: "TO",
        estadoCod: 27,
        cod: 372,
      },
      {
        nome: "Itapiratins",
        uf: "TO",
        estadoCod: 27,
        cod: 373,
      },
      {
        nome: "Itaporã do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 374,
      },
      {
        nome: "Jaú do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 375,
      },
      {
        nome: "Juarina",
        uf: "TO",
        estadoCod: 27,
        cod: 376,
      },
      {
        nome: "Lagoa da Confusão",
        uf: "TO",
        estadoCod: 27,
        cod: 377,
      },
      {
        nome: "Lagoa do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 378,
      },
      {
        nome: "Lajeado",
        uf: "TO",
        estadoCod: 27,
        cod: 379,
      },
      {
        nome: "Lavandeira",
        uf: "TO",
        estadoCod: 27,
        cod: 380,
      },
      {
        nome: "Lizarda",
        uf: "TO",
        estadoCod: 27,
        cod: 381,
      },
      {
        nome: "Luzinópolis",
        uf: "TO",
        estadoCod: 27,
        cod: 382,
      },
      {
        nome: "Marianópolis do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 383,
      },
      {
        nome: "Mateiros",
        uf: "TO",
        estadoCod: 27,
        cod: 384,
      },
      {
        nome: "Maurilândia do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 385,
      },
      {
        nome: "Miracema do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 386,
      },
      {
        nome: "Miranorte",
        uf: "TO",
        estadoCod: 27,
        cod: 387,
      },
      {
        nome: "Monte do Carmo",
        uf: "TO",
        estadoCod: 27,
        cod: 388,
      },
      {
        nome: "Monte Santo do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 389,
      },
      {
        nome: "Palmeiras do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 390,
      },
      {
        nome: "Muricilândia",
        uf: "TO",
        estadoCod: 27,
        cod: 391,
      },
      {
        nome: "Natividade",
        uf: "TO",
        estadoCod: 27,
        cod: 392,
      },
      {
        nome: "Nazaré",
        uf: "TO",
        estadoCod: 27,
        cod: 393,
      },
      {
        nome: "Nova Olinda",
        uf: "TO",
        estadoCod: 27,
        cod: 394,
      },
      {
        nome: "Nova Rosalândia",
        uf: "TO",
        estadoCod: 27,
        cod: 395,
      },
      {
        nome: "Novo Acordo",
        uf: "TO",
        estadoCod: 27,
        cod: 396,
      },
      {
        nome: "Novo Alegre",
        uf: "TO",
        estadoCod: 27,
        cod: 397,
      },
      {
        nome: "Novo Jardim",
        uf: "TO",
        estadoCod: 27,
        cod: 398,
      },
      {
        nome: "Oliveira de Fátima",
        uf: "TO",
        estadoCod: 27,
        cod: 399,
      },
      {
        nome: "Palmeirante",
        uf: "TO",
        estadoCod: 27,
        cod: 400,
      },
      {
        nome: "Palmeirópolis",
        uf: "TO",
        estadoCod: 27,
        cod: 401,
      },
      {
        nome: "Paraíso do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 402,
      },
      {
        nome: "Paranã",
        uf: "TO",
        estadoCod: 27,
        cod: 403,
      },
      {
        nome: "Pau D'Arco",
        uf: "TO",
        estadoCod: 27,
        cod: 404,
      },
      {
        nome: "Pedro Afonso",
        uf: "TO",
        estadoCod: 27,
        cod: 405,
      },
      {
        nome: "Peixe",
        uf: "TO",
        estadoCod: 27,
        cod: 406,
      },
      {
        nome: "Pequizeiro",
        uf: "TO",
        estadoCod: 27,
        cod: 407,
      },
      {
        nome: "Colméia",
        uf: "TO",
        estadoCod: 27,
        cod: 408,
      },
      {
        nome: "Pindorama do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 409,
      },
      {
        nome: "Piraquê",
        uf: "TO",
        estadoCod: 27,
        cod: 410,
      },
      {
        nome: "Pium",
        uf: "TO",
        estadoCod: 27,
        cod: 411,
      },
      {
        nome: "Ponte Alta do Bom Jesus",
        uf: "TO",
        estadoCod: 27,
        cod: 412,
      },
      {
        nome: "Ponte Alta do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 413,
      },
      {
        nome: "Porto Alegre do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 414,
      },
      {
        nome: "Porto Nacional",
        uf: "TO",
        estadoCod: 27,
        cod: 415,
      },
      {
        nome: "Praia Norte",
        uf: "TO",
        estadoCod: 27,
        cod: 416,
      },
      {
        nome: "Presidente Kennedy",
        uf: "TO",
        estadoCod: 27,
        cod: 417,
      },
      {
        nome: "Pugmil",
        uf: "TO",
        estadoCod: 27,
        cod: 418,
      },
      {
        nome: "Recursolândia",
        uf: "TO",
        estadoCod: 27,
        cod: 419,
      },
      {
        nome: "Riachinho",
        uf: "TO",
        estadoCod: 27,
        cod: 420,
      },
      {
        nome: "Rio da Conceição",
        uf: "TO",
        estadoCod: 27,
        cod: 421,
      },
      {
        nome: "Rio dos Bois",
        uf: "TO",
        estadoCod: 27,
        cod: 422,
      },
      {
        nome: "Rio Sono",
        uf: "TO",
        estadoCod: 27,
        cod: 423,
      },
      {
        nome: "Sampaio",
        uf: "TO",
        estadoCod: 27,
        cod: 424,
      },
      {
        nome: "Sandolândia",
        uf: "TO",
        estadoCod: 27,
        cod: 425,
      },
      {
        nome: "Santa Fé do Araguaia",
        uf: "TO",
        estadoCod: 27,
        cod: 426,
      },
      {
        nome: "Santa Maria do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 427,
      },
      {
        nome: "Santa Rita do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 428,
      },
      {
        nome: "Santa Rosa do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 429,
      },
      {
        nome: "Santa Tereza do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 430,
      },
      {
        nome: "Santa Terezinha do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 431,
      },
      {
        nome: "São Bento do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 432,
      },
      {
        nome: "São Félix do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 433,
      },
      {
        nome: "São Miguel do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 434,
      },
      {
        nome: "São Salvador do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 435,
      },
      {
        nome: "São Sebastião do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 436,
      },
      {
        nome: "São Valério",
        uf: "TO",
        estadoCod: 27,
        cod: 437,
      },
      {
        nome: "Silvanópolis",
        uf: "TO",
        estadoCod: 27,
        cod: 438,
      },
      {
        nome: "Sítio Novo do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 439,
      },
      {
        nome: "Sucupira",
        uf: "TO",
        estadoCod: 27,
        cod: 440,
      },
      {
        nome: "Taguatinga",
        uf: "TO",
        estadoCod: 27,
        cod: 441,
      },
      {
        nome: "Taipas do Tocantins",
        uf: "TO",
        estadoCod: 27,
        cod: 442,
      },
      {
        nome: "Talismã",
        uf: "TO",
        estadoCod: 27,
        cod: 443,
      },
      {
        nome: "Palmas",
        uf: "TO",
        estadoCod: 27,
        cod: 444,
      },
      {
        nome: "Tocantínia",
        uf: "TO",
        estadoCod: 27,
        cod: 445,
      },
      {
        nome: "Tocantinópolis",
        uf: "TO",
        estadoCod: 27,
        cod: 446,
      },
      {
        nome: "Tupirama",
        uf: "TO",
        estadoCod: 27,
        cod: 447,
      },
      {
        nome: "Tupiratins",
        uf: "TO",
        estadoCod: 27,
        cod: 448,
      },
      {
        nome: "Wanderlândia",
        uf: "TO",
        estadoCod: 27,
        cod: 449,
      },
      {
        nome: "Xambioá",
        uf: "TO",
        estadoCod: 27,
        cod: 450,
      },
    ],
  },
  {
    nome: "Maranhão",
    uf: "MA",
    regiaoCod: 2,
    cod: 10,
    cidades: [
      {
        nome: "Açailândia",
        uf: "MA",
        estadoCod: 10,
        cod: 451,
      },
      {
        nome: "Afonso Cunha",
        uf: "MA",
        estadoCod: 10,
        cod: 452,
      },
      {
        nome: "Água Doce do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 453,
      },
      {
        nome: "Alcântara",
        uf: "MA",
        estadoCod: 10,
        cod: 454,
      },
      {
        nome: "Aldeias Altas",
        uf: "MA",
        estadoCod: 10,
        cod: 455,
      },
      {
        nome: "Altamira do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 456,
      },
      {
        nome: "Alto Alegre do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 457,
      },
      {
        nome: "Alto Alegre do Pindaré",
        uf: "MA",
        estadoCod: 10,
        cod: 458,
      },
      {
        nome: "Alto Parnaíba",
        uf: "MA",
        estadoCod: 10,
        cod: 459,
      },
      {
        nome: "Amapá do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 460,
      },
      {
        nome: "Amarante do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 461,
      },
      {
        nome: "Anajatuba",
        uf: "MA",
        estadoCod: 10,
        cod: 462,
      },
      {
        nome: "Anapurus",
        uf: "MA",
        estadoCod: 10,
        cod: 463,
      },
      {
        nome: "Apicum-Açu",
        uf: "MA",
        estadoCod: 10,
        cod: 464,
      },
      {
        nome: "Araguanã",
        uf: "MA",
        estadoCod: 10,
        cod: 465,
      },
      {
        nome: "Araioses",
        uf: "MA",
        estadoCod: 10,
        cod: 466,
      },
      {
        nome: "Arame",
        uf: "MA",
        estadoCod: 10,
        cod: 467,
      },
      {
        nome: "Arari",
        uf: "MA",
        estadoCod: 10,
        cod: 468,
      },
      {
        nome: "Axixá",
        uf: "MA",
        estadoCod: 10,
        cod: 469,
      },
      {
        nome: "Bacabal",
        uf: "MA",
        estadoCod: 10,
        cod: 470,
      },
      {
        nome: "Bacabeira",
        uf: "MA",
        estadoCod: 10,
        cod: 471,
      },
      {
        nome: "Bacuri",
        uf: "MA",
        estadoCod: 10,
        cod: 472,
      },
      {
        nome: "Bacurituba",
        uf: "MA",
        estadoCod: 10,
        cod: 473,
      },
      {
        nome: "Balsas",
        uf: "MA",
        estadoCod: 10,
        cod: 474,
      },
      {
        nome: "Barão de Grajaú",
        uf: "MA",
        estadoCod: 10,
        cod: 475,
      },
      {
        nome: "Barra do Corda",
        uf: "MA",
        estadoCod: 10,
        cod: 476,
      },
      {
        nome: "Barreirinhas",
        uf: "MA",
        estadoCod: 10,
        cod: 477,
      },
      {
        nome: "Belágua",
        uf: "MA",
        estadoCod: 10,
        cod: 478,
      },
      {
        nome: "Bela Vista do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 479,
      },
      {
        nome: "Benedito Leite",
        uf: "MA",
        estadoCod: 10,
        cod: 480,
      },
      {
        nome: "Bequimão",
        uf: "MA",
        estadoCod: 10,
        cod: 481,
      },
      {
        nome: "Bernardo do Mearim",
        uf: "MA",
        estadoCod: 10,
        cod: 482,
      },
      {
        nome: "Boa Vista do Gurupi",
        uf: "MA",
        estadoCod: 10,
        cod: 483,
      },
      {
        nome: "Bom Jardim",
        uf: "MA",
        estadoCod: 10,
        cod: 484,
      },
      {
        nome: "Bom Jesus das Selvas",
        uf: "MA",
        estadoCod: 10,
        cod: 485,
      },
      {
        nome: "Bom Lugar",
        uf: "MA",
        estadoCod: 10,
        cod: 486,
      },
      {
        nome: "Brejo",
        uf: "MA",
        estadoCod: 10,
        cod: 487,
      },
      {
        nome: "Brejo de Areia",
        uf: "MA",
        estadoCod: 10,
        cod: 488,
      },
      {
        nome: "Buriti",
        uf: "MA",
        estadoCod: 10,
        cod: 489,
      },
      {
        nome: "Buriti Bravo",
        uf: "MA",
        estadoCod: 10,
        cod: 490,
      },
      {
        nome: "Buriticupu",
        uf: "MA",
        estadoCod: 10,
        cod: 491,
      },
      {
        nome: "Buritirana",
        uf: "MA",
        estadoCod: 10,
        cod: 492,
      },
      {
        nome: "Cachoeira Grande",
        uf: "MA",
        estadoCod: 10,
        cod: 493,
      },
      {
        nome: "Cajapió",
        uf: "MA",
        estadoCod: 10,
        cod: 494,
      },
      {
        nome: "Cajari",
        uf: "MA",
        estadoCod: 10,
        cod: 495,
      },
      {
        nome: "Campestre do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 496,
      },
      {
        nome: "Cândido Mendes",
        uf: "MA",
        estadoCod: 10,
        cod: 497,
      },
      {
        nome: "Cantanhede",
        uf: "MA",
        estadoCod: 10,
        cod: 498,
      },
      {
        nome: "Capinzal do Norte",
        uf: "MA",
        estadoCod: 10,
        cod: 499,
      },
      {
        nome: "Carolina",
        uf: "MA",
        estadoCod: 10,
        cod: 500,
      },
      {
        nome: "Carutapera",
        uf: "MA",
        estadoCod: 10,
        cod: 501,
      },
      {
        nome: "Caxias",
        uf: "MA",
        estadoCod: 10,
        cod: 502,
      },
      {
        nome: "Cedral",
        uf: "MA",
        estadoCod: 10,
        cod: 503,
      },
      {
        nome: "Central do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 504,
      },
      {
        nome: "Centro do Guilherme",
        uf: "MA",
        estadoCod: 10,
        cod: 505,
      },
      {
        nome: "Centro Novo do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 506,
      },
      {
        nome: "Chapadinha",
        uf: "MA",
        estadoCod: 10,
        cod: 507,
      },
      {
        nome: "Cidelândia",
        uf: "MA",
        estadoCod: 10,
        cod: 508,
      },
      {
        nome: "Codó",
        uf: "MA",
        estadoCod: 10,
        cod: 509,
      },
      {
        nome: "Coelho Neto",
        uf: "MA",
        estadoCod: 10,
        cod: 510,
      },
      {
        nome: "Colinas",
        uf: "MA",
        estadoCod: 10,
        cod: 511,
      },
      {
        nome: "Conceição do Lago-Açu",
        uf: "MA",
        estadoCod: 10,
        cod: 512,
      },
      {
        nome: "Coroatá",
        uf: "MA",
        estadoCod: 10,
        cod: 513,
      },
      {
        nome: "Cururupu",
        uf: "MA",
        estadoCod: 10,
        cod: 514,
      },
      {
        nome: "Davinópolis",
        uf: "MA",
        estadoCod: 10,
        cod: 515,
      },
      {
        nome: "Dom Pedro",
        uf: "MA",
        estadoCod: 10,
        cod: 516,
      },
      {
        nome: "Duque Bacelar",
        uf: "MA",
        estadoCod: 10,
        cod: 517,
      },
      {
        nome: "Esperantinópolis",
        uf: "MA",
        estadoCod: 10,
        cod: 518,
      },
      {
        nome: "Estreito",
        uf: "MA",
        estadoCod: 10,
        cod: 519,
      },
      {
        nome: "Feira Nova do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 520,
      },
      {
        nome: "Fernando Falcão",
        uf: "MA",
        estadoCod: 10,
        cod: 521,
      },
      {
        nome: "Formosa da Serra Negra",
        uf: "MA",
        estadoCod: 10,
        cod: 522,
      },
      {
        nome: "Fortaleza dos Nogueiras",
        uf: "MA",
        estadoCod: 10,
        cod: 523,
      },
      {
        nome: "Fortuna",
        uf: "MA",
        estadoCod: 10,
        cod: 524,
      },
      {
        nome: "Godofredo Viana",
        uf: "MA",
        estadoCod: 10,
        cod: 525,
      },
      {
        nome: "Gonçalves Dias",
        uf: "MA",
        estadoCod: 10,
        cod: 526,
      },
      {
        nome: "Governador Archer",
        uf: "MA",
        estadoCod: 10,
        cod: 527,
      },
      {
        nome: "Governador Edison Lobão",
        uf: "MA",
        estadoCod: 10,
        cod: 528,
      },
      {
        nome: "Governador Eugênio Barros",
        uf: "MA",
        estadoCod: 10,
        cod: 529,
      },
      {
        nome: "Governador Luiz Rocha",
        uf: "MA",
        estadoCod: 10,
        cod: 530,
      },
      {
        nome: "Governador Newton Bello",
        uf: "MA",
        estadoCod: 10,
        cod: 531,
      },
      {
        nome: "Governador Nunes Freire",
        uf: "MA",
        estadoCod: 10,
        cod: 532,
      },
      {
        nome: "Graça Aranha",
        uf: "MA",
        estadoCod: 10,
        cod: 533,
      },
      {
        nome: "Grajaú",
        uf: "MA",
        estadoCod: 10,
        cod: 534,
      },
      {
        nome: "Guimarães",
        uf: "MA",
        estadoCod: 10,
        cod: 535,
      },
      {
        nome: "Humberto de Campos",
        uf: "MA",
        estadoCod: 10,
        cod: 536,
      },
      {
        nome: "Icatu",
        uf: "MA",
        estadoCod: 10,
        cod: 537,
      },
      {
        nome: "Igarapé do Meio",
        uf: "MA",
        estadoCod: 10,
        cod: 538,
      },
      {
        nome: "Igarapé Grande",
        uf: "MA",
        estadoCod: 10,
        cod: 539,
      },
      {
        nome: "Imperatriz",
        uf: "MA",
        estadoCod: 10,
        cod: 540,
      },
      {
        nome: "Itaipava do Grajaú",
        uf: "MA",
        estadoCod: 10,
        cod: 541,
      },
      {
        nome: "Itapecuru Mirim",
        uf: "MA",
        estadoCod: 10,
        cod: 542,
      },
      {
        nome: "Itinga do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 543,
      },
      {
        nome: "Jatobá",
        uf: "MA",
        estadoCod: 10,
        cod: 544,
      },
      {
        nome: "Jenipapo dos Vieiras",
        uf: "MA",
        estadoCod: 10,
        cod: 545,
      },
      {
        nome: "João Lisboa",
        uf: "MA",
        estadoCod: 10,
        cod: 546,
      },
      {
        nome: "Joselândia",
        uf: "MA",
        estadoCod: 10,
        cod: 547,
      },
      {
        nome: "Junco do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 548,
      },
      {
        nome: "Lago da Pedra",
        uf: "MA",
        estadoCod: 10,
        cod: 549,
      },
      {
        nome: "Lago do Junco",
        uf: "MA",
        estadoCod: 10,
        cod: 550,
      },
      {
        nome: "Lago Verde",
        uf: "MA",
        estadoCod: 10,
        cod: 551,
      },
      {
        nome: "Lagoa do Mato",
        uf: "MA",
        estadoCod: 10,
        cod: 552,
      },
      {
        nome: "Lago dos Rodrigues",
        uf: "MA",
        estadoCod: 10,
        cod: 553,
      },
      {
        nome: "Lagoa Grande do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 554,
      },
      {
        nome: "Lajeado Novo",
        uf: "MA",
        estadoCod: 10,
        cod: 555,
      },
      {
        nome: "Lima Campos",
        uf: "MA",
        estadoCod: 10,
        cod: 556,
      },
      {
        nome: "Loreto",
        uf: "MA",
        estadoCod: 10,
        cod: 557,
      },
      {
        nome: "Luís Domingues",
        uf: "MA",
        estadoCod: 10,
        cod: 558,
      },
      {
        nome: "Magalhães de Almeida",
        uf: "MA",
        estadoCod: 10,
        cod: 559,
      },
      {
        nome: "Maracaçumé",
        uf: "MA",
        estadoCod: 10,
        cod: 560,
      },
      {
        nome: "Marajá do Sena",
        uf: "MA",
        estadoCod: 10,
        cod: 561,
      },
      {
        nome: "Maranhãozinho",
        uf: "MA",
        estadoCod: 10,
        cod: 562,
      },
      {
        nome: "Mata Roma",
        uf: "MA",
        estadoCod: 10,
        cod: 563,
      },
      {
        nome: "Matinha",
        uf: "MA",
        estadoCod: 10,
        cod: 564,
      },
      {
        nome: "Matões",
        uf: "MA",
        estadoCod: 10,
        cod: 565,
      },
      {
        nome: "Matões do Norte",
        uf: "MA",
        estadoCod: 10,
        cod: 566,
      },
      {
        nome: "Milagres do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 567,
      },
      {
        nome: "Mirador",
        uf: "MA",
        estadoCod: 10,
        cod: 568,
      },
      {
        nome: "Miranda do Norte",
        uf: "MA",
        estadoCod: 10,
        cod: 569,
      },
      {
        nome: "Mirinzal",
        uf: "MA",
        estadoCod: 10,
        cod: 570,
      },
      {
        nome: "Monção",
        uf: "MA",
        estadoCod: 10,
        cod: 571,
      },
      {
        nome: "Montes Altos",
        uf: "MA",
        estadoCod: 10,
        cod: 572,
      },
      {
        nome: "Morros",
        uf: "MA",
        estadoCod: 10,
        cod: 573,
      },
      {
        nome: "Nina Rodrigues",
        uf: "MA",
        estadoCod: 10,
        cod: 574,
      },
      {
        nome: "Nova Colinas",
        uf: "MA",
        estadoCod: 10,
        cod: 575,
      },
      {
        nome: "Nova Iorque",
        uf: "MA",
        estadoCod: 10,
        cod: 576,
      },
      {
        nome: "Nova Olinda do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 577,
      },
      {
        nome: "Olho D'Água das Cunhãs",
        uf: "MA",
        estadoCod: 10,
        cod: 578,
      },
      {
        nome: "Olinda Nova do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 579,
      },
      {
        nome: "Paço do Lumiar",
        uf: "MA",
        estadoCod: 10,
        cod: 580,
      },
      {
        nome: "Palmeirândia",
        uf: "MA",
        estadoCod: 10,
        cod: 581,
      },
      {
        nome: "Paraibano",
        uf: "MA",
        estadoCod: 10,
        cod: 582,
      },
      {
        nome: "Parnarama",
        uf: "MA",
        estadoCod: 10,
        cod: 583,
      },
      {
        nome: "Passagem Franca",
        uf: "MA",
        estadoCod: 10,
        cod: 584,
      },
      {
        nome: "Pastos Bons",
        uf: "MA",
        estadoCod: 10,
        cod: 585,
      },
      {
        nome: "Paulino Neves",
        uf: "MA",
        estadoCod: 10,
        cod: 586,
      },
      {
        nome: "Paulo Ramos",
        uf: "MA",
        estadoCod: 10,
        cod: 587,
      },
      {
        nome: "Pedreiras",
        uf: "MA",
        estadoCod: 10,
        cod: 588,
      },
      {
        nome: "Pedro do Rosário",
        uf: "MA",
        estadoCod: 10,
        cod: 589,
      },
      {
        nome: "Penalva",
        uf: "MA",
        estadoCod: 10,
        cod: 590,
      },
      {
        nome: "Peri Mirim",
        uf: "MA",
        estadoCod: 10,
        cod: 591,
      },
      {
        nome: "Peritoró",
        uf: "MA",
        estadoCod: 10,
        cod: 592,
      },
      {
        nome: "Pindaré-Mirim",
        uf: "MA",
        estadoCod: 10,
        cod: 593,
      },
      {
        nome: "Pinheiro",
        uf: "MA",
        estadoCod: 10,
        cod: 594,
      },
      {
        nome: "Pio XII",
        uf: "MA",
        estadoCod: 10,
        cod: 595,
      },
      {
        nome: "Pirapemas",
        uf: "MA",
        estadoCod: 10,
        cod: 596,
      },
      {
        nome: "Poção de Pedras",
        uf: "MA",
        estadoCod: 10,
        cod: 597,
      },
      {
        nome: "Porto Franco",
        uf: "MA",
        estadoCod: 10,
        cod: 598,
      },
      {
        nome: "Porto Rico do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 599,
      },
      {
        nome: "Presidente Dutra",
        uf: "MA",
        estadoCod: 10,
        cod: 600,
      },
      {
        nome: "Presidente Juscelino",
        uf: "MA",
        estadoCod: 10,
        cod: 601,
      },
      {
        nome: "Presidente Médici",
        uf: "MA",
        estadoCod: 10,
        cod: 602,
      },
      {
        nome: "Presidente Sarney",
        uf: "MA",
        estadoCod: 10,
        cod: 603,
      },
      {
        nome: "Presidente Vargas",
        uf: "MA",
        estadoCod: 10,
        cod: 604,
      },
      {
        nome: "Primeira Cruz",
        uf: "MA",
        estadoCod: 10,
        cod: 605,
      },
      {
        nome: "Raposa",
        uf: "MA",
        estadoCod: 10,
        cod: 606,
      },
      {
        nome: "Riachão",
        uf: "MA",
        estadoCod: 10,
        cod: 607,
      },
      {
        nome: "Ribamar Fiquene",
        uf: "MA",
        estadoCod: 10,
        cod: 608,
      },
      {
        nome: "Rosário",
        uf: "MA",
        estadoCod: 10,
        cod: 609,
      },
      {
        nome: "Sambaíba",
        uf: "MA",
        estadoCod: 10,
        cod: 610,
      },
      {
        nome: "Santa Filomena do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 611,
      },
      {
        nome: "Santa Helena",
        uf: "MA",
        estadoCod: 10,
        cod: 612,
      },
      {
        nome: "Santa Inês",
        uf: "MA",
        estadoCod: 10,
        cod: 613,
      },
      {
        nome: "Santa Luzia",
        uf: "MA",
        estadoCod: 10,
        cod: 614,
      },
      {
        nome: "Santa Luzia do Paruá",
        uf: "MA",
        estadoCod: 10,
        cod: 615,
      },
      {
        nome: "Santa Quitéria do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 616,
      },
      {
        nome: "Santa Rita",
        uf: "MA",
        estadoCod: 10,
        cod: 617,
      },
      {
        nome: "Santana do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 618,
      },
      {
        nome: "Santo Amaro do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 619,
      },
      {
        nome: "Santo Antônio dos Lopes",
        uf: "MA",
        estadoCod: 10,
        cod: 620,
      },
      {
        nome: "São Benedito do Rio Preto",
        uf: "MA",
        estadoCod: 10,
        cod: 621,
      },
      {
        nome: "São Bento",
        uf: "MA",
        estadoCod: 10,
        cod: 622,
      },
      {
        nome: "São Bernardo",
        uf: "MA",
        estadoCod: 10,
        cod: 623,
      },
      {
        nome: "São Domingos do Azeitão",
        uf: "MA",
        estadoCod: 10,
        cod: 624,
      },
      {
        nome: "São Domingos do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 625,
      },
      {
        nome: "São Félix de Balsas",
        uf: "MA",
        estadoCod: 10,
        cod: 626,
      },
      {
        nome: "São Francisco do Brejão",
        uf: "MA",
        estadoCod: 10,
        cod: 627,
      },
      {
        nome: "São Francisco do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 628,
      },
      {
        nome: "São João Batista",
        uf: "MA",
        estadoCod: 10,
        cod: 629,
      },
      {
        nome: "São João do Carú",
        uf: "MA",
        estadoCod: 10,
        cod: 630,
      },
      {
        nome: "São João do Paraíso",
        uf: "MA",
        estadoCod: 10,
        cod: 631,
      },
      {
        nome: "São João do Soter",
        uf: "MA",
        estadoCod: 10,
        cod: 632,
      },
      {
        nome: "São João dos Patos",
        uf: "MA",
        estadoCod: 10,
        cod: 633,
      },
      {
        nome: "São José de Ribamar",
        uf: "MA",
        estadoCod: 10,
        cod: 634,
      },
      {
        nome: "São José dos Basílios",
        uf: "MA",
        estadoCod: 10,
        cod: 635,
      },
      {
        nome: "São Luís",
        uf: "MA",
        estadoCod: 10,
        cod: 636,
      },
      {
        nome: "São Luís Gonzaga do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 637,
      },
      {
        nome: "São Mateus do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 638,
      },
      {
        nome: "São Pedro da Água Branca",
        uf: "MA",
        estadoCod: 10,
        cod: 639,
      },
      {
        nome: "São Pedro dos Crentes",
        uf: "MA",
        estadoCod: 10,
        cod: 640,
      },
      {
        nome: "São Raimundo das Mangabeiras",
        uf: "MA",
        estadoCod: 10,
        cod: 641,
      },
      {
        nome: "São Raimundo do Doca Bezerra",
        uf: "MA",
        estadoCod: 10,
        cod: 642,
      },
      {
        nome: "São Roberto",
        uf: "MA",
        estadoCod: 10,
        cod: 643,
      },
      {
        nome: "São Vicente Ferrer",
        uf: "MA",
        estadoCod: 10,
        cod: 644,
      },
      {
        nome: "Satubinha",
        uf: "MA",
        estadoCod: 10,
        cod: 645,
      },
      {
        nome: "Senador Alexandre Costa",
        uf: "MA",
        estadoCod: 10,
        cod: 646,
      },
      {
        nome: "Senador La Rocque",
        uf: "MA",
        estadoCod: 10,
        cod: 647,
      },
      {
        nome: "Serrano do Maranhão",
        uf: "MA",
        estadoCod: 10,
        cod: 648,
      },
      {
        nome: "Sítio Novo",
        uf: "MA",
        estadoCod: 10,
        cod: 649,
      },
      {
        nome: "Sucupira do Norte",
        uf: "MA",
        estadoCod: 10,
        cod: 650,
      },
      {
        nome: "Sucupira do Riachão",
        uf: "MA",
        estadoCod: 10,
        cod: 651,
      },
      {
        nome: "Tasso Fragoso",
        uf: "MA",
        estadoCod: 10,
        cod: 652,
      },
      {
        nome: "Timbiras",
        uf: "MA",
        estadoCod: 10,
        cod: 653,
      },
      {
        nome: "Timon",
        uf: "MA",
        estadoCod: 10,
        cod: 654,
      },
      {
        nome: "Trizidela do Vale",
        uf: "MA",
        estadoCod: 10,
        cod: 655,
      },
      {
        nome: "Tufilândia",
        uf: "MA",
        estadoCod: 10,
        cod: 656,
      },
      {
        nome: "Tuntum",
        uf: "MA",
        estadoCod: 10,
        cod: 657,
      },
      {
        nome: "Turiaçu",
        uf: "MA",
        estadoCod: 10,
        cod: 658,
      },
      {
        nome: "Turilândia",
        uf: "MA",
        estadoCod: 10,
        cod: 659,
      },
      {
        nome: "Tutóia",
        uf: "MA",
        estadoCod: 10,
        cod: 660,
      },
      {
        nome: "Urbano Santos",
        uf: "MA",
        estadoCod: 10,
        cod: 661,
      },
      {
        nome: "Vargem Grande",
        uf: "MA",
        estadoCod: 10,
        cod: 662,
      },
      {
        nome: "Viana",
        uf: "MA",
        estadoCod: 10,
        cod: 663,
      },
      {
        nome: "Vila Nova dos Martírios",
        uf: "MA",
        estadoCod: 10,
        cod: 664,
      },
      {
        nome: "Vitória do Mearim",
        uf: "MA",
        estadoCod: 10,
        cod: 665,
      },
      {
        nome: "Vitorino Freire",
        uf: "MA",
        estadoCod: 10,
        cod: 666,
      },
      {
        nome: "Zé Doca",
        uf: "MA",
        estadoCod: 10,
        cod: 667,
      },
    ],
  },
  {
    nome: "Piauí",
    uf: "PI",
    regiaoCod: 2,
    cod: 18,
    cidades: [
      {
        nome: "Acauã",
        uf: "PI",
        estadoCod: 18,
        cod: 668,
      },
      {
        nome: "Agricolândia",
        uf: "PI",
        estadoCod: 18,
        cod: 669,
      },
      {
        nome: "Água Branca",
        uf: "PI",
        estadoCod: 18,
        cod: 670,
      },
      {
        nome: "Alagoinha do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 671,
      },
      {
        nome: "Alegrete do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 672,
      },
      {
        nome: "Alto Longá",
        uf: "PI",
        estadoCod: 18,
        cod: 673,
      },
      {
        nome: "Altos",
        uf: "PI",
        estadoCod: 18,
        cod: 674,
      },
      {
        nome: "Alvorada do Gurguéia",
        uf: "PI",
        estadoCod: 18,
        cod: 675,
      },
      {
        nome: "Amarante",
        uf: "PI",
        estadoCod: 18,
        cod: 676,
      },
      {
        nome: "Angical do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 677,
      },
      {
        nome: "Anísio de Abreu",
        uf: "PI",
        estadoCod: 18,
        cod: 678,
      },
      {
        nome: "Antônio Almeida",
        uf: "PI",
        estadoCod: 18,
        cod: 679,
      },
      {
        nome: "Aroazes",
        uf: "PI",
        estadoCod: 18,
        cod: 680,
      },
      {
        nome: "Aroeiras do Itaim",
        uf: "PI",
        estadoCod: 18,
        cod: 681,
      },
      {
        nome: "Arraial",
        uf: "PI",
        estadoCod: 18,
        cod: 682,
      },
      {
        nome: "Assunção do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 683,
      },
      {
        nome: "Avelino Lopes",
        uf: "PI",
        estadoCod: 18,
        cod: 684,
      },
      {
        nome: "Baixa Grande do Ribeiro",
        uf: "PI",
        estadoCod: 18,
        cod: 685,
      },
      {
        nome: "Barra D'Alcântara",
        uf: "PI",
        estadoCod: 18,
        cod: 686,
      },
      {
        nome: "Barras",
        uf: "PI",
        estadoCod: 18,
        cod: 687,
      },
      {
        nome: "Barreiras do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 688,
      },
      {
        nome: "Barro Duro",
        uf: "PI",
        estadoCod: 18,
        cod: 689,
      },
      {
        nome: "Batalha",
        uf: "PI",
        estadoCod: 18,
        cod: 690,
      },
      {
        nome: "Bela Vista do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 691,
      },
      {
        nome: "Belém do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 692,
      },
      {
        nome: "Beneditinos",
        uf: "PI",
        estadoCod: 18,
        cod: 693,
      },
      {
        nome: "Bertolínia",
        uf: "PI",
        estadoCod: 18,
        cod: 694,
      },
      {
        nome: "Betânia do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 695,
      },
      {
        nome: "Boa Hora",
        uf: "PI",
        estadoCod: 18,
        cod: 696,
      },
      {
        nome: "Bocaina",
        uf: "PI",
        estadoCod: 18,
        cod: 697,
      },
      {
        nome: "Bom Jesus",
        uf: "PI",
        estadoCod: 18,
        cod: 698,
      },
      {
        nome: "Bom Princípio do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 699,
      },
      {
        nome: "Bonfim do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 700,
      },
      {
        nome: "Boqueirão do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 701,
      },
      {
        nome: "Brasileira",
        uf: "PI",
        estadoCod: 18,
        cod: 702,
      },
      {
        nome: "Brejo do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 703,
      },
      {
        nome: "Buriti dos Lopes",
        uf: "PI",
        estadoCod: 18,
        cod: 704,
      },
      {
        nome: "Buriti dos Montes",
        uf: "PI",
        estadoCod: 18,
        cod: 705,
      },
      {
        nome: "Cabeceiras do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 706,
      },
      {
        nome: "Cajazeiras do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 707,
      },
      {
        nome: "Cajueiro da Praia",
        uf: "PI",
        estadoCod: 18,
        cod: 708,
      },
      {
        nome: "Caldeirão Grande do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 709,
      },
      {
        nome: "Campinas do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 710,
      },
      {
        nome: "Campo Alegre do Fidalgo",
        uf: "PI",
        estadoCod: 18,
        cod: 711,
      },
      {
        nome: "Campo Grande do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 712,
      },
      {
        nome: "Campo Largo do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 713,
      },
      {
        nome: "Campo Maior",
        uf: "PI",
        estadoCod: 18,
        cod: 714,
      },
      {
        nome: "Canavieira",
        uf: "PI",
        estadoCod: 18,
        cod: 715,
      },
      {
        nome: "Canto do Buriti",
        uf: "PI",
        estadoCod: 18,
        cod: 716,
      },
      {
        nome: "Capitão de Campos",
        uf: "PI",
        estadoCod: 18,
        cod: 717,
      },
      {
        nome: "Capitão Gervásio Oliveira",
        uf: "PI",
        estadoCod: 18,
        cod: 718,
      },
      {
        nome: "Caracol",
        uf: "PI",
        estadoCod: 18,
        cod: 719,
      },
      {
        nome: "Caraúbas do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 720,
      },
      {
        nome: "Caridade do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 721,
      },
      {
        nome: "Castelo do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 722,
      },
      {
        nome: "Caxingó",
        uf: "PI",
        estadoCod: 18,
        cod: 723,
      },
      {
        nome: "Cocal",
        uf: "PI",
        estadoCod: 18,
        cod: 724,
      },
      {
        nome: "Cocal de Telha",
        uf: "PI",
        estadoCod: 18,
        cod: 725,
      },
      {
        nome: "Cocal dos Alves",
        uf: "PI",
        estadoCod: 18,
        cod: 726,
      },
      {
        nome: "Coivaras",
        uf: "PI",
        estadoCod: 18,
        cod: 727,
      },
      {
        nome: "Colônia do Gurguéia",
        uf: "PI",
        estadoCod: 18,
        cod: 728,
      },
      {
        nome: "Colônia do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 729,
      },
      {
        nome: "Conceição do Canindé",
        uf: "PI",
        estadoCod: 18,
        cod: 730,
      },
      {
        nome: "Coronel José Dias",
        uf: "PI",
        estadoCod: 18,
        cod: 731,
      },
      {
        nome: "Corrente",
        uf: "PI",
        estadoCod: 18,
        cod: 732,
      },
      {
        nome: "Cristalândia do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 733,
      },
      {
        nome: "Cristino Castro",
        uf: "PI",
        estadoCod: 18,
        cod: 734,
      },
      {
        nome: "Curimatá",
        uf: "PI",
        estadoCod: 18,
        cod: 735,
      },
      {
        nome: "Currais",
        uf: "PI",
        estadoCod: 18,
        cod: 736,
      },
      {
        nome: "Curralinhos",
        uf: "PI",
        estadoCod: 18,
        cod: 737,
      },
      {
        nome: "Curral Novo do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 738,
      },
      {
        nome: "Demerval Lobão",
        uf: "PI",
        estadoCod: 18,
        cod: 739,
      },
      {
        nome: "Dirceu Arcoverde",
        uf: "PI",
        estadoCod: 18,
        cod: 740,
      },
      {
        nome: "Dom Expedito Lopes",
        uf: "PI",
        estadoCod: 18,
        cod: 741,
      },
      {
        nome: "Domingos Mourão",
        uf: "PI",
        estadoCod: 18,
        cod: 742,
      },
      {
        nome: "Dom Inocêncio",
        uf: "PI",
        estadoCod: 18,
        cod: 743,
      },
      {
        nome: "Elesbão Veloso",
        uf: "PI",
        estadoCod: 18,
        cod: 744,
      },
      {
        nome: "Eliseu Martins",
        uf: "PI",
        estadoCod: 18,
        cod: 745,
      },
      {
        nome: "Esperantina",
        uf: "PI",
        estadoCod: 18,
        cod: 746,
      },
      {
        nome: "Fartura do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 747,
      },
      {
        nome: "Flores do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 748,
      },
      {
        nome: "Floresta do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 749,
      },
      {
        nome: "Floriano",
        uf: "PI",
        estadoCod: 18,
        cod: 750,
      },
      {
        nome: "Francinópolis",
        uf: "PI",
        estadoCod: 18,
        cod: 751,
      },
      {
        nome: "Francisco Ayres",
        uf: "PI",
        estadoCod: 18,
        cod: 752,
      },
      {
        nome: "Francisco Macedo",
        uf: "PI",
        estadoCod: 18,
        cod: 753,
      },
      {
        nome: "Francisco Santos",
        uf: "PI",
        estadoCod: 18,
        cod: 754,
      },
      {
        nome: "Fronteiras",
        uf: "PI",
        estadoCod: 18,
        cod: 755,
      },
      {
        nome: "Geminiano",
        uf: "PI",
        estadoCod: 18,
        cod: 756,
      },
      {
        nome: "Gilbués",
        uf: "PI",
        estadoCod: 18,
        cod: 757,
      },
      {
        nome: "Guadalupe",
        uf: "PI",
        estadoCod: 18,
        cod: 758,
      },
      {
        nome: "Guaribas",
        uf: "PI",
        estadoCod: 18,
        cod: 759,
      },
      {
        nome: "Hugo Napoleão",
        uf: "PI",
        estadoCod: 18,
        cod: 760,
      },
      {
        nome: "Ilha Grande",
        uf: "PI",
        estadoCod: 18,
        cod: 761,
      },
      {
        nome: "Inhuma",
        uf: "PI",
        estadoCod: 18,
        cod: 762,
      },
      {
        nome: "Ipiranga do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 763,
      },
      {
        nome: "Isaías Coelho",
        uf: "PI",
        estadoCod: 18,
        cod: 764,
      },
      {
        nome: "Itainópolis",
        uf: "PI",
        estadoCod: 18,
        cod: 765,
      },
      {
        nome: "Itaueira",
        uf: "PI",
        estadoCod: 18,
        cod: 766,
      },
      {
        nome: "Jacobina do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 767,
      },
      {
        nome: "Jaicós",
        uf: "PI",
        estadoCod: 18,
        cod: 768,
      },
      {
        nome: "Jardim do Mulato",
        uf: "PI",
        estadoCod: 18,
        cod: 769,
      },
      {
        nome: "Jatobá do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 770,
      },
      {
        nome: "Jerumenha",
        uf: "PI",
        estadoCod: 18,
        cod: 771,
      },
      {
        nome: "João Costa",
        uf: "PI",
        estadoCod: 18,
        cod: 772,
      },
      {
        nome: "Joaquim Pires",
        uf: "PI",
        estadoCod: 18,
        cod: 773,
      },
      {
        nome: "Joca Marques",
        uf: "PI",
        estadoCod: 18,
        cod: 774,
      },
      {
        nome: "José de Freitas",
        uf: "PI",
        estadoCod: 18,
        cod: 775,
      },
      {
        nome: "Juazeiro do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 776,
      },
      {
        nome: "Júlio Borges",
        uf: "PI",
        estadoCod: 18,
        cod: 777,
      },
      {
        nome: "Jurema",
        uf: "PI",
        estadoCod: 18,
        cod: 778,
      },
      {
        nome: "Lagoinha do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 779,
      },
      {
        nome: "Lagoa Alegre",
        uf: "PI",
        estadoCod: 18,
        cod: 780,
      },
      {
        nome: "Lagoa do Barro do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 781,
      },
      {
        nome: "Lagoa de São Francisco",
        uf: "PI",
        estadoCod: 18,
        cod: 782,
      },
      {
        nome: "Lagoa do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 783,
      },
      {
        nome: "Lagoa do Sítio",
        uf: "PI",
        estadoCod: 18,
        cod: 784,
      },
      {
        nome: "Landri Sales",
        uf: "PI",
        estadoCod: 18,
        cod: 785,
      },
      {
        nome: "Luís Correia",
        uf: "PI",
        estadoCod: 18,
        cod: 786,
      },
      {
        nome: "Luzilândia",
        uf: "PI",
        estadoCod: 18,
        cod: 787,
      },
      {
        nome: "Madeiro",
        uf: "PI",
        estadoCod: 18,
        cod: 788,
      },
      {
        nome: "Manoel Emídio",
        uf: "PI",
        estadoCod: 18,
        cod: 789,
      },
      {
        nome: "Marcolândia",
        uf: "PI",
        estadoCod: 18,
        cod: 790,
      },
      {
        nome: "Marcos Parente",
        uf: "PI",
        estadoCod: 18,
        cod: 791,
      },
      {
        nome: "Massapê do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 792,
      },
      {
        nome: "Matias Olímpio",
        uf: "PI",
        estadoCod: 18,
        cod: 793,
      },
      {
        nome: "Miguel Alves",
        uf: "PI",
        estadoCod: 18,
        cod: 794,
      },
      {
        nome: "Miguel Leão",
        uf: "PI",
        estadoCod: 18,
        cod: 795,
      },
      {
        nome: "Milton Brandão",
        uf: "PI",
        estadoCod: 18,
        cod: 796,
      },
      {
        nome: "Monsenhor Gil",
        uf: "PI",
        estadoCod: 18,
        cod: 797,
      },
      {
        nome: "Monsenhor Hipólito",
        uf: "PI",
        estadoCod: 18,
        cod: 798,
      },
      {
        nome: "Monte Alegre do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 799,
      },
      {
        nome: "Morro Cabeça no Tempo",
        uf: "PI",
        estadoCod: 18,
        cod: 800,
      },
      {
        nome: "Morro do Chapéu do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 801,
      },
      {
        nome: "Murici dos Portelas",
        uf: "PI",
        estadoCod: 18,
        cod: 802,
      },
      {
        nome: "Nazaré do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 803,
      },
      {
        nome: "Nazária",
        uf: "PI",
        estadoCod: 18,
        cod: 804,
      },
      {
        nome: "Nossa Senhora de Nazaré",
        uf: "PI",
        estadoCod: 18,
        cod: 805,
      },
      {
        nome: "Nossa Senhora dos Remédios",
        uf: "PI",
        estadoCod: 18,
        cod: 806,
      },
      {
        nome: "Novo Oriente do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 807,
      },
      {
        nome: "Novo Santo Antônio",
        uf: "PI",
        estadoCod: 18,
        cod: 808,
      },
      {
        nome: "Oeiras",
        uf: "PI",
        estadoCod: 18,
        cod: 809,
      },
      {
        nome: "Olho D'Água do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 810,
      },
      {
        nome: "Padre Marcos",
        uf: "PI",
        estadoCod: 18,
        cod: 811,
      },
      {
        nome: "Paes Landim",
        uf: "PI",
        estadoCod: 18,
        cod: 812,
      },
      {
        nome: "Pajeú do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 813,
      },
      {
        nome: "Palmeira do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 814,
      },
      {
        nome: "Palmeirais",
        uf: "PI",
        estadoCod: 18,
        cod: 815,
      },
      {
        nome: "Paquetá",
        uf: "PI",
        estadoCod: 18,
        cod: 816,
      },
      {
        nome: "Parnaguá",
        uf: "PI",
        estadoCod: 18,
        cod: 817,
      },
      {
        nome: "Parnaíba",
        uf: "PI",
        estadoCod: 18,
        cod: 818,
      },
      {
        nome: "Passagem Franca do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 819,
      },
      {
        nome: "Patos do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 820,
      },
      {
        nome: "Pau D'Arco do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 821,
      },
      {
        nome: "Paulistana",
        uf: "PI",
        estadoCod: 18,
        cod: 822,
      },
      {
        nome: "Pavussu",
        uf: "PI",
        estadoCod: 18,
        cod: 823,
      },
      {
        nome: "Pedro II",
        uf: "PI",
        estadoCod: 18,
        cod: 824,
      },
      {
        nome: "Pedro Laurentino",
        uf: "PI",
        estadoCod: 18,
        cod: 825,
      },
      {
        nome: "Nova Santa Rita",
        uf: "PI",
        estadoCod: 18,
        cod: 826,
      },
      {
        nome: "Picos",
        uf: "PI",
        estadoCod: 18,
        cod: 827,
      },
      {
        nome: "Pimenteiras",
        uf: "PI",
        estadoCod: 18,
        cod: 828,
      },
      {
        nome: "Pio IX",
        uf: "PI",
        estadoCod: 18,
        cod: 829,
      },
      {
        nome: "Piracuruca",
        uf: "PI",
        estadoCod: 18,
        cod: 830,
      },
      {
        nome: "Piripiri",
        uf: "PI",
        estadoCod: 18,
        cod: 831,
      },
      {
        nome: "Porto",
        uf: "PI",
        estadoCod: 18,
        cod: 832,
      },
      {
        nome: "Porto Alegre do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 833,
      },
      {
        nome: "Prata do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 834,
      },
      {
        nome: "Queimada Nova",
        uf: "PI",
        estadoCod: 18,
        cod: 835,
      },
      {
        nome: "Redenção do Gurguéia",
        uf: "PI",
        estadoCod: 18,
        cod: 836,
      },
      {
        nome: "Regeneração",
        uf: "PI",
        estadoCod: 18,
        cod: 837,
      },
      {
        nome: "Riacho Frio",
        uf: "PI",
        estadoCod: 18,
        cod: 838,
      },
      {
        nome: "Ribeira do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 839,
      },
      {
        nome: "Ribeiro Gonçalves",
        uf: "PI",
        estadoCod: 18,
        cod: 840,
      },
      {
        nome: "Rio Grande do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 841,
      },
      {
        nome: "Santa Cruz do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 842,
      },
      {
        nome: "Santa Cruz dos Milagres",
        uf: "PI",
        estadoCod: 18,
        cod: 843,
      },
      {
        nome: "Santa Filomena",
        uf: "PI",
        estadoCod: 18,
        cod: 844,
      },
      {
        nome: "Santa Luz",
        uf: "PI",
        estadoCod: 18,
        cod: 845,
      },
      {
        nome: "Santana do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 846,
      },
      {
        nome: "Santa Rosa do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 847,
      },
      {
        nome: "Santo Antônio de Lisboa",
        uf: "PI",
        estadoCod: 18,
        cod: 848,
      },
      {
        nome: "Santo Antônio dos Milagres",
        uf: "PI",
        estadoCod: 18,
        cod: 849,
      },
      {
        nome: "Santo Inácio do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 850,
      },
      {
        nome: "São Braz do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 851,
      },
      {
        nome: "São Félix do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 852,
      },
      {
        nome: "São Francisco de Assis do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 853,
      },
      {
        nome: "São Francisco do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 854,
      },
      {
        nome: "São Gonçalo do Gurguéia",
        uf: "PI",
        estadoCod: 18,
        cod: 855,
      },
      {
        nome: "São Gonçalo do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 856,
      },
      {
        nome: "São João da Canabrava",
        uf: "PI",
        estadoCod: 18,
        cod: 857,
      },
      {
        nome: "São João da Fronteira",
        uf: "PI",
        estadoCod: 18,
        cod: 858,
      },
      {
        nome: "São João da Serra",
        uf: "PI",
        estadoCod: 18,
        cod: 859,
      },
      {
        nome: "São João da Varjota",
        uf: "PI",
        estadoCod: 18,
        cod: 860,
      },
      {
        nome: "São João do Arraial",
        uf: "PI",
        estadoCod: 18,
        cod: 861,
      },
      {
        nome: "São João do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 862,
      },
      {
        nome: "São José do Divino",
        uf: "PI",
        estadoCod: 18,
        cod: 863,
      },
      {
        nome: "São José do Peixe",
        uf: "PI",
        estadoCod: 18,
        cod: 864,
      },
      {
        nome: "São José do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 865,
      },
      {
        nome: "São Julião",
        uf: "PI",
        estadoCod: 18,
        cod: 866,
      },
      {
        nome: "São Lourenço do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 867,
      },
      {
        nome: "São Luis do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 868,
      },
      {
        nome: "São Miguel da Baixa Grande",
        uf: "PI",
        estadoCod: 18,
        cod: 869,
      },
      {
        nome: "São Miguel do Fidalgo",
        uf: "PI",
        estadoCod: 18,
        cod: 870,
      },
      {
        nome: "São Miguel do Tapuio",
        uf: "PI",
        estadoCod: 18,
        cod: 871,
      },
      {
        nome: "São Pedro do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 872,
      },
      {
        nome: "São Raimundo Nonato",
        uf: "PI",
        estadoCod: 18,
        cod: 873,
      },
      {
        nome: "Sebastião Barros",
        uf: "PI",
        estadoCod: 18,
        cod: 874,
      },
      {
        nome: "Sebastião Leal",
        uf: "PI",
        estadoCod: 18,
        cod: 875,
      },
      {
        nome: "Sigefredo Pacheco",
        uf: "PI",
        estadoCod: 18,
        cod: 876,
      },
      {
        nome: "Simões",
        uf: "PI",
        estadoCod: 18,
        cod: 877,
      },
      {
        nome: "Simplício Mendes",
        uf: "PI",
        estadoCod: 18,
        cod: 878,
      },
      {
        nome: "Socorro do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 879,
      },
      {
        nome: "Sussuapara",
        uf: "PI",
        estadoCod: 18,
        cod: 880,
      },
      {
        nome: "Tamboril do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 881,
      },
      {
        nome: "Tanque do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 882,
      },
      {
        nome: "Teresina",
        uf: "PI",
        estadoCod: 18,
        cod: 883,
      },
      {
        nome: "União",
        uf: "PI",
        estadoCod: 18,
        cod: 884,
      },
      {
        nome: "Uruçuí",
        uf: "PI",
        estadoCod: 18,
        cod: 885,
      },
      {
        nome: "Valença do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 886,
      },
      {
        nome: "Várzea Branca",
        uf: "PI",
        estadoCod: 18,
        cod: 887,
      },
      {
        nome: "Várzea Grande",
        uf: "PI",
        estadoCod: 18,
        cod: 888,
      },
      {
        nome: "Vera Mendes",
        uf: "PI",
        estadoCod: 18,
        cod: 889,
      },
      {
        nome: "Vila Nova do Piauí",
        uf: "PI",
        estadoCod: 18,
        cod: 890,
      },
      {
        nome: "Wall Ferraz",
        uf: "PI",
        estadoCod: 18,
        cod: 891,
      },
    ],
  },
  {
    nome: "Ceará",
    uf: "CE",
    regiaoCod: 2,
    cod: 6,
    cidades: [
      {
        nome: "Abaiara",
        uf: "CE",
        estadoCod: 6,
        cod: 892,
      },
      {
        nome: "Acarape",
        uf: "CE",
        estadoCod: 6,
        cod: 893,
      },
      {
        nome: "Acaraú",
        uf: "CE",
        estadoCod: 6,
        cod: 894,
      },
      {
        nome: "Acopiara",
        uf: "CE",
        estadoCod: 6,
        cod: 895,
      },
      {
        nome: "Aiuaba",
        uf: "CE",
        estadoCod: 6,
        cod: 896,
      },
      {
        nome: "Alcântaras",
        uf: "CE",
        estadoCod: 6,
        cod: 897,
      },
      {
        nome: "Altaneira",
        uf: "CE",
        estadoCod: 6,
        cod: 898,
      },
      {
        nome: "Alto Santo",
        uf: "CE",
        estadoCod: 6,
        cod: 899,
      },
      {
        nome: "Amontada",
        uf: "CE",
        estadoCod: 6,
        cod: 900,
      },
      {
        nome: "Antonina do Norte",
        uf: "CE",
        estadoCod: 6,
        cod: 901,
      },
      {
        nome: "Apuiarés",
        uf: "CE",
        estadoCod: 6,
        cod: 902,
      },
      {
        nome: "Aquiraz",
        uf: "CE",
        estadoCod: 6,
        cod: 903,
      },
      {
        nome: "Aracati",
        uf: "CE",
        estadoCod: 6,
        cod: 904,
      },
      {
        nome: "Aracoiaba",
        uf: "CE",
        estadoCod: 6,
        cod: 905,
      },
      {
        nome: "Ararendá",
        uf: "CE",
        estadoCod: 6,
        cod: 906,
      },
      {
        nome: "Araripe",
        uf: "CE",
        estadoCod: 6,
        cod: 907,
      },
      {
        nome: "Aratuba",
        uf: "CE",
        estadoCod: 6,
        cod: 908,
      },
      {
        nome: "Arneiroz",
        uf: "CE",
        estadoCod: 6,
        cod: 909,
      },
      {
        nome: "Assaré",
        uf: "CE",
        estadoCod: 6,
        cod: 910,
      },
      {
        nome: "Aurora",
        uf: "CE",
        estadoCod: 6,
        cod: 911,
      },
      {
        nome: "Baixio",
        uf: "CE",
        estadoCod: 6,
        cod: 912,
      },
      {
        nome: "Banabuiú",
        uf: "CE",
        estadoCod: 6,
        cod: 913,
      },
      {
        nome: "Barbalha",
        uf: "CE",
        estadoCod: 6,
        cod: 914,
      },
      {
        nome: "Barreira",
        uf: "CE",
        estadoCod: 6,
        cod: 915,
      },
      {
        nome: "Barro",
        uf: "CE",
        estadoCod: 6,
        cod: 916,
      },
      {
        nome: "Barroquinha",
        uf: "CE",
        estadoCod: 6,
        cod: 917,
      },
      {
        nome: "Baturité",
        uf: "CE",
        estadoCod: 6,
        cod: 918,
      },
      {
        nome: "Beberibe",
        uf: "CE",
        estadoCod: 6,
        cod: 919,
      },
      {
        nome: "Bela Cruz",
        uf: "CE",
        estadoCod: 6,
        cod: 920,
      },
      {
        nome: "Boa Viagem",
        uf: "CE",
        estadoCod: 6,
        cod: 921,
      },
      {
        nome: "Brejo Santo",
        uf: "CE",
        estadoCod: 6,
        cod: 922,
      },
      {
        nome: "Camocim",
        uf: "CE",
        estadoCod: 6,
        cod: 923,
      },
      {
        nome: "Campos Sales",
        uf: "CE",
        estadoCod: 6,
        cod: 924,
      },
      {
        nome: "Canindé",
        uf: "CE",
        estadoCod: 6,
        cod: 925,
      },
      {
        nome: "Capistrano",
        uf: "CE",
        estadoCod: 6,
        cod: 926,
      },
      {
        nome: "Caridade",
        uf: "CE",
        estadoCod: 6,
        cod: 927,
      },
      {
        nome: "Cariré",
        uf: "CE",
        estadoCod: 6,
        cod: 928,
      },
      {
        nome: "Caririaçu",
        uf: "CE",
        estadoCod: 6,
        cod: 929,
      },
      {
        nome: "Cariús",
        uf: "CE",
        estadoCod: 6,
        cod: 930,
      },
      {
        nome: "Carnaubal",
        uf: "CE",
        estadoCod: 6,
        cod: 931,
      },
      {
        nome: "Cascavel",
        uf: "CE",
        estadoCod: 6,
        cod: 932,
      },
      {
        nome: "Catarina",
        uf: "CE",
        estadoCod: 6,
        cod: 933,
      },
      {
        nome: "Catunda",
        uf: "CE",
        estadoCod: 6,
        cod: 934,
      },
      {
        nome: "Caucaia",
        uf: "CE",
        estadoCod: 6,
        cod: 935,
      },
      {
        nome: "Cedro",
        uf: "CE",
        estadoCod: 6,
        cod: 936,
      },
      {
        nome: "Chaval",
        uf: "CE",
        estadoCod: 6,
        cod: 937,
      },
      {
        nome: "Choró",
        uf: "CE",
        estadoCod: 6,
        cod: 938,
      },
      {
        nome: "Chorozinho",
        uf: "CE",
        estadoCod: 6,
        cod: 939,
      },
      {
        nome: "Coreaú",
        uf: "CE",
        estadoCod: 6,
        cod: 940,
      },
      {
        nome: "Crateús",
        uf: "CE",
        estadoCod: 6,
        cod: 941,
      },
      {
        nome: "Crato",
        uf: "CE",
        estadoCod: 6,
        cod: 942,
      },
      {
        nome: "Croatá",
        uf: "CE",
        estadoCod: 6,
        cod: 943,
      },
      {
        nome: "Cruz",
        uf: "CE",
        estadoCod: 6,
        cod: 944,
      },
      {
        nome: "Deputado Irapuan Pinheiro",
        uf: "CE",
        estadoCod: 6,
        cod: 945,
      },
      {
        nome: "Ererê",
        uf: "CE",
        estadoCod: 6,
        cod: 946,
      },
      {
        nome: "Eusébio",
        uf: "CE",
        estadoCod: 6,
        cod: 947,
      },
      {
        nome: "Farias Brito",
        uf: "CE",
        estadoCod: 6,
        cod: 948,
      },
      {
        nome: "Forquilha",
        uf: "CE",
        estadoCod: 6,
        cod: 949,
      },
      {
        nome: "Fortaleza",
        uf: "CE",
        estadoCod: 6,
        cod: 950,
      },
      {
        nome: "Fortim",
        uf: "CE",
        estadoCod: 6,
        cod: 951,
      },
      {
        nome: "Frecheirinha",
        uf: "CE",
        estadoCod: 6,
        cod: 952,
      },
      {
        nome: "General Sampaio",
        uf: "CE",
        estadoCod: 6,
        cod: 953,
      },
      {
        nome: "Graça",
        uf: "CE",
        estadoCod: 6,
        cod: 954,
      },
      {
        nome: "Granja",
        uf: "CE",
        estadoCod: 6,
        cod: 955,
      },
      {
        nome: "Granjeiro",
        uf: "CE",
        estadoCod: 6,
        cod: 956,
      },
      {
        nome: "Groaíras",
        uf: "CE",
        estadoCod: 6,
        cod: 957,
      },
      {
        nome: "Guaiúba",
        uf: "CE",
        estadoCod: 6,
        cod: 958,
      },
      {
        nome: "Guaraciaba do Norte",
        uf: "CE",
        estadoCod: 6,
        cod: 959,
      },
      {
        nome: "Guaramiranga",
        uf: "CE",
        estadoCod: 6,
        cod: 960,
      },
      {
        nome: "Hidrolândia",
        uf: "CE",
        estadoCod: 6,
        cod: 961,
      },
      {
        nome: "Horizonte",
        uf: "CE",
        estadoCod: 6,
        cod: 962,
      },
      {
        nome: "Ibaretama",
        uf: "CE",
        estadoCod: 6,
        cod: 963,
      },
      {
        nome: "Ibiapina",
        uf: "CE",
        estadoCod: 6,
        cod: 964,
      },
      {
        nome: "Ibicuitinga",
        uf: "CE",
        estadoCod: 6,
        cod: 965,
      },
      {
        nome: "Icapuí",
        uf: "CE",
        estadoCod: 6,
        cod: 966,
      },
      {
        nome: "Icó",
        uf: "CE",
        estadoCod: 6,
        cod: 967,
      },
      {
        nome: "Iguatu",
        uf: "CE",
        estadoCod: 6,
        cod: 968,
      },
      {
        nome: "Independência",
        uf: "CE",
        estadoCod: 6,
        cod: 969,
      },
      {
        nome: "Ipaporanga",
        uf: "CE",
        estadoCod: 6,
        cod: 970,
      },
      {
        nome: "Ipaumirim",
        uf: "CE",
        estadoCod: 6,
        cod: 971,
      },
      {
        nome: "Ipu",
        uf: "CE",
        estadoCod: 6,
        cod: 972,
      },
      {
        nome: "Ipueiras",
        uf: "CE",
        estadoCod: 6,
        cod: 973,
      },
      {
        nome: "Iracema",
        uf: "CE",
        estadoCod: 6,
        cod: 974,
      },
      {
        nome: "Irauçuba",
        uf: "CE",
        estadoCod: 6,
        cod: 975,
      },
      {
        nome: "Itaiçaba",
        uf: "CE",
        estadoCod: 6,
        cod: 976,
      },
      {
        nome: "Itaitinga",
        uf: "CE",
        estadoCod: 6,
        cod: 977,
      },
      {
        nome: "Itapagé",
        uf: "CE",
        estadoCod: 6,
        cod: 978,
      },
      {
        nome: "Itapipoca",
        uf: "CE",
        estadoCod: 6,
        cod: 979,
      },
      {
        nome: "Itapiúna",
        uf: "CE",
        estadoCod: 6,
        cod: 980,
      },
      {
        nome: "Itarema",
        uf: "CE",
        estadoCod: 6,
        cod: 981,
      },
      {
        nome: "Itatira",
        uf: "CE",
        estadoCod: 6,
        cod: 982,
      },
      {
        nome: "Jaguaretama",
        uf: "CE",
        estadoCod: 6,
        cod: 983,
      },
      {
        nome: "Jaguaribara",
        uf: "CE",
        estadoCod: 6,
        cod: 984,
      },
      {
        nome: "Jaguaribe",
        uf: "CE",
        estadoCod: 6,
        cod: 985,
      },
      {
        nome: "Jaguaruana",
        uf: "CE",
        estadoCod: 6,
        cod: 986,
      },
      {
        nome: "Jardim",
        uf: "CE",
        estadoCod: 6,
        cod: 987,
      },
      {
        nome: "Jati",
        uf: "CE",
        estadoCod: 6,
        cod: 988,
      },
      {
        nome: "Jijoca de Jericoacoara",
        uf: "CE",
        estadoCod: 6,
        cod: 989,
      },
      {
        nome: "Juazeiro do Norte",
        uf: "CE",
        estadoCod: 6,
        cod: 990,
      },
      {
        nome: "Jucás",
        uf: "CE",
        estadoCod: 6,
        cod: 991,
      },
      {
        nome: "Lavras da Mangabeira",
        uf: "CE",
        estadoCod: 6,
        cod: 992,
      },
      {
        nome: "Limoeiro do Norte",
        uf: "CE",
        estadoCod: 6,
        cod: 993,
      },
      {
        nome: "Madalena",
        uf: "CE",
        estadoCod: 6,
        cod: 994,
      },
      {
        nome: "Maracanaú",
        uf: "CE",
        estadoCod: 6,
        cod: 995,
      },
      {
        nome: "Maranguape",
        uf: "CE",
        estadoCod: 6,
        cod: 996,
      },
      {
        nome: "Marco",
        uf: "CE",
        estadoCod: 6,
        cod: 997,
      },
      {
        nome: "Martinópole",
        uf: "CE",
        estadoCod: 6,
        cod: 998,
      },
      {
        nome: "Massapê",
        uf: "CE",
        estadoCod: 6,
        cod: 999,
      },
      {
        nome: "Mauriti",
        uf: "CE",
        estadoCod: 6,
        cod: 1000,
      },
      {
        nome: "Meruoca",
        uf: "CE",
        estadoCod: 6,
        cod: 1001,
      },
      {
        nome: "Milagres",
        uf: "CE",
        estadoCod: 6,
        cod: 1002,
      },
      {
        nome: "Milhã",
        uf: "CE",
        estadoCod: 6,
        cod: 1003,
      },
      {
        nome: "Miraíma",
        uf: "CE",
        estadoCod: 6,
        cod: 1004,
      },
      {
        nome: "Missão Velha",
        uf: "CE",
        estadoCod: 6,
        cod: 1005,
      },
      {
        nome: "Mombaça",
        uf: "CE",
        estadoCod: 6,
        cod: 1006,
      },
      {
        nome: "Monsenhor Tabosa",
        uf: "CE",
        estadoCod: 6,
        cod: 1007,
      },
      {
        nome: "Morada Nova",
        uf: "CE",
        estadoCod: 6,
        cod: 1008,
      },
      {
        nome: "Moraújo",
        uf: "CE",
        estadoCod: 6,
        cod: 1009,
      },
      {
        nome: "Morrinhos",
        uf: "CE",
        estadoCod: 6,
        cod: 1010,
      },
      {
        nome: "Mucambo",
        uf: "CE",
        estadoCod: 6,
        cod: 1011,
      },
      {
        nome: "Mulungu",
        uf: "CE",
        estadoCod: 6,
        cod: 1012,
      },
      {
        nome: "Nova Olinda",
        uf: "CE",
        estadoCod: 6,
        cod: 1013,
      },
      {
        nome: "Nova Russas",
        uf: "CE",
        estadoCod: 6,
        cod: 1014,
      },
      {
        nome: "Novo Oriente",
        uf: "CE",
        estadoCod: 6,
        cod: 1015,
      },
      {
        nome: "Ocara",
        uf: "CE",
        estadoCod: 6,
        cod: 1016,
      },
      {
        nome: "Orós",
        uf: "CE",
        estadoCod: 6,
        cod: 1017,
      },
      {
        nome: "Pacajus",
        uf: "CE",
        estadoCod: 6,
        cod: 1018,
      },
      {
        nome: "Pacatuba",
        uf: "CE",
        estadoCod: 6,
        cod: 1019,
      },
      {
        nome: "Pacoti",
        uf: "CE",
        estadoCod: 6,
        cod: 1020,
      },
      {
        nome: "Pacujá",
        uf: "CE",
        estadoCod: 6,
        cod: 1021,
      },
      {
        nome: "Palhano",
        uf: "CE",
        estadoCod: 6,
        cod: 1022,
      },
      {
        nome: "Palmácia",
        uf: "CE",
        estadoCod: 6,
        cod: 1023,
      },
      {
        nome: "Paracuru",
        uf: "CE",
        estadoCod: 6,
        cod: 1024,
      },
      {
        nome: "Paraipaba",
        uf: "CE",
        estadoCod: 6,
        cod: 1025,
      },
      {
        nome: "Parambu",
        uf: "CE",
        estadoCod: 6,
        cod: 1026,
      },
      {
        nome: "Paramoti",
        uf: "CE",
        estadoCod: 6,
        cod: 1027,
      },
      {
        nome: "Pedra Branca",
        uf: "CE",
        estadoCod: 6,
        cod: 1028,
      },
      {
        nome: "Penaforte",
        uf: "CE",
        estadoCod: 6,
        cod: 1029,
      },
      {
        nome: "Pentecoste",
        uf: "CE",
        estadoCod: 6,
        cod: 1030,
      },
      {
        nome: "Pereiro",
        uf: "CE",
        estadoCod: 6,
        cod: 1031,
      },
      {
        nome: "Pindoretama",
        uf: "CE",
        estadoCod: 6,
        cod: 1032,
      },
      {
        nome: "Piquet Carneiro",
        uf: "CE",
        estadoCod: 6,
        cod: 1033,
      },
      {
        nome: "Pires Ferreira",
        uf: "CE",
        estadoCod: 6,
        cod: 1034,
      },
      {
        nome: "Poranga",
        uf: "CE",
        estadoCod: 6,
        cod: 1035,
      },
      {
        nome: "Porteiras",
        uf: "CE",
        estadoCod: 6,
        cod: 1036,
      },
      {
        nome: "Potengi",
        uf: "CE",
        estadoCod: 6,
        cod: 1037,
      },
      {
        nome: "Potiretama",
        uf: "CE",
        estadoCod: 6,
        cod: 1038,
      },
      {
        nome: "Quiterianópolis",
        uf: "CE",
        estadoCod: 6,
        cod: 1039,
      },
      {
        nome: "Quixadá",
        uf: "CE",
        estadoCod: 6,
        cod: 1040,
      },
      {
        nome: "Quixelô",
        uf: "CE",
        estadoCod: 6,
        cod: 1041,
      },
      {
        nome: "Quixeramobim",
        uf: "CE",
        estadoCod: 6,
        cod: 1042,
      },
      {
        nome: "Quixeré",
        uf: "CE",
        estadoCod: 6,
        cod: 1043,
      },
      {
        nome: "Redenção",
        uf: "CE",
        estadoCod: 6,
        cod: 1044,
      },
      {
        nome: "Reriutaba",
        uf: "CE",
        estadoCod: 6,
        cod: 1045,
      },
      {
        nome: "Russas",
        uf: "CE",
        estadoCod: 6,
        cod: 1046,
      },
      {
        nome: "Saboeiro",
        uf: "CE",
        estadoCod: 6,
        cod: 1047,
      },
      {
        nome: "Salitre",
        uf: "CE",
        estadoCod: 6,
        cod: 1048,
      },
      {
        nome: "Santana do Acaraú",
        uf: "CE",
        estadoCod: 6,
        cod: 1049,
      },
      {
        nome: "Santana do Cariri",
        uf: "CE",
        estadoCod: 6,
        cod: 1050,
      },
      {
        nome: "Santa Quitéria",
        uf: "CE",
        estadoCod: 6,
        cod: 1051,
      },
      {
        nome: "São Benedito",
        uf: "CE",
        estadoCod: 6,
        cod: 1052,
      },
      {
        nome: "São Gonçalo do Amarante",
        uf: "CE",
        estadoCod: 6,
        cod: 1053,
      },
      {
        nome: "São João do Jaguaribe",
        uf: "CE",
        estadoCod: 6,
        cod: 1054,
      },
      {
        nome: "São Luís do Curu",
        uf: "CE",
        estadoCod: 6,
        cod: 1055,
      },
      {
        nome: "Senador Pompeu",
        uf: "CE",
        estadoCod: 6,
        cod: 1056,
      },
      {
        nome: "Senador Sá",
        uf: "CE",
        estadoCod: 6,
        cod: 1057,
      },
      {
        nome: "Sobral",
        uf: "CE",
        estadoCod: 6,
        cod: 1058,
      },
      {
        nome: "Solonópole",
        uf: "CE",
        estadoCod: 6,
        cod: 1059,
      },
      {
        nome: "Tabuleiro do Norte",
        uf: "CE",
        estadoCod: 6,
        cod: 1060,
      },
      {
        nome: "Tamboril",
        uf: "CE",
        estadoCod: 6,
        cod: 1061,
      },
      {
        nome: "Tarrafas",
        uf: "CE",
        estadoCod: 6,
        cod: 1062,
      },
      {
        nome: "Tauá",
        uf: "CE",
        estadoCod: 6,
        cod: 1063,
      },
      {
        nome: "Tejuçuoca",
        uf: "CE",
        estadoCod: 6,
        cod: 1064,
      },
      {
        nome: "Tianguá",
        uf: "CE",
        estadoCod: 6,
        cod: 1065,
      },
      {
        nome: "Trairi",
        uf: "CE",
        estadoCod: 6,
        cod: 1066,
      },
      {
        nome: "Tururu",
        uf: "CE",
        estadoCod: 6,
        cod: 1067,
      },
      {
        nome: "Ubajara",
        uf: "CE",
        estadoCod: 6,
        cod: 1068,
      },
      {
        nome: "Umari",
        uf: "CE",
        estadoCod: 6,
        cod: 1069,
      },
      {
        nome: "Umirim",
        uf: "CE",
        estadoCod: 6,
        cod: 1070,
      },
      {
        nome: "Uruburetama",
        uf: "CE",
        estadoCod: 6,
        cod: 1071,
      },
      {
        nome: "Uruoca",
        uf: "CE",
        estadoCod: 6,
        cod: 1072,
      },
      {
        nome: "Varjota",
        uf: "CE",
        estadoCod: 6,
        cod: 1073,
      },
      {
        nome: "Várzea Alegre",
        uf: "CE",
        estadoCod: 6,
        cod: 1074,
      },
      {
        nome: "Viçosa do Ceará",
        uf: "CE",
        estadoCod: 6,
        cod: 1075,
      },
    ],
  },
  {
    nome: "Rio Grande do Norte",
    uf: "RN",
    regiaoCod: 2,
    cod: 20,
    cidades: [
      {
        nome: "Acari",
        uf: "RN",
        estadoCod: 20,
        cod: 1076,
      },
      {
        nome: "Açu",
        uf: "RN",
        estadoCod: 20,
        cod: 1077,
      },
      {
        nome: "Afonso Bezerra",
        uf: "RN",
        estadoCod: 20,
        cod: 1078,
      },
      {
        nome: "Água Nova",
        uf: "RN",
        estadoCod: 20,
        cod: 1079,
      },
      {
        nome: "Alexandria",
        uf: "RN",
        estadoCod: 20,
        cod: 1080,
      },
      {
        nome: "Almino Afonso",
        uf: "RN",
        estadoCod: 20,
        cod: 1081,
      },
      {
        nome: "Alto do Rodrigues",
        uf: "RN",
        estadoCod: 20,
        cod: 1082,
      },
      {
        nome: "Angicos",
        uf: "RN",
        estadoCod: 20,
        cod: 1083,
      },
      {
        nome: "Antônio Martins",
        uf: "RN",
        estadoCod: 20,
        cod: 1084,
      },
      {
        nome: "Apodi",
        uf: "RN",
        estadoCod: 20,
        cod: 1085,
      },
      {
        nome: "Areia Branca",
        uf: "RN",
        estadoCod: 20,
        cod: 1086,
      },
      {
        nome: "Arês",
        uf: "RN",
        estadoCod: 20,
        cod: 1087,
      },
      {
        nome: "Augusto Severo",
        uf: "RN",
        estadoCod: 20,
        cod: 1088,
      },
      {
        nome: "Baía Formosa",
        uf: "RN",
        estadoCod: 20,
        cod: 1089,
      },
      {
        nome: "Baraúna",
        uf: "RN",
        estadoCod: 20,
        cod: 1090,
      },
      {
        nome: "Barcelona",
        uf: "RN",
        estadoCod: 20,
        cod: 1091,
      },
      {
        nome: "Bento Fernandes",
        uf: "RN",
        estadoCod: 20,
        cod: 1092,
      },
      {
        nome: "Bodó",
        uf: "RN",
        estadoCod: 20,
        cod: 1093,
      },
      {
        nome: "Bom Jesus",
        uf: "RN",
        estadoCod: 20,
        cod: 1094,
      },
      {
        nome: "Brejinho",
        uf: "RN",
        estadoCod: 20,
        cod: 1095,
      },
      {
        nome: "Caiçara do Norte",
        uf: "RN",
        estadoCod: 20,
        cod: 1096,
      },
      {
        nome: "Caiçara do Rio do Vento",
        uf: "RN",
        estadoCod: 20,
        cod: 1097,
      },
      {
        nome: "Caicó",
        uf: "RN",
        estadoCod: 20,
        cod: 1098,
      },
      {
        nome: "Campo Redondo",
        uf: "RN",
        estadoCod: 20,
        cod: 1099,
      },
      {
        nome: "Canguaretama",
        uf: "RN",
        estadoCod: 20,
        cod: 1100,
      },
      {
        nome: "Caraúbas",
        uf: "RN",
        estadoCod: 20,
        cod: 1101,
      },
      {
        nome: "Carnaúba dos Dantas",
        uf: "RN",
        estadoCod: 20,
        cod: 1102,
      },
      {
        nome: "Carnaubais",
        uf: "RN",
        estadoCod: 20,
        cod: 1103,
      },
      {
        nome: "Ceará-Mirim",
        uf: "RN",
        estadoCod: 20,
        cod: 1104,
      },
      {
        nome: "Cerro Corá",
        uf: "RN",
        estadoCod: 20,
        cod: 1105,
      },
      {
        nome: "Coronel Ezequiel",
        uf: "RN",
        estadoCod: 20,
        cod: 1106,
      },
      {
        nome: "Coronel João Pessoa",
        uf: "RN",
        estadoCod: 20,
        cod: 1107,
      },
      {
        nome: "Cruzeta",
        uf: "RN",
        estadoCod: 20,
        cod: 1108,
      },
      {
        nome: "Currais Novos",
        uf: "RN",
        estadoCod: 20,
        cod: 1109,
      },
      {
        nome: "Doutor Severiano",
        uf: "RN",
        estadoCod: 20,
        cod: 1110,
      },
      {
        nome: "Parnamirim",
        uf: "RN",
        estadoCod: 20,
        cod: 1111,
      },
      {
        nome: "Encanto",
        uf: "RN",
        estadoCod: 20,
        cod: 1112,
      },
      {
        nome: "Equador",
        uf: "RN",
        estadoCod: 20,
        cod: 1113,
      },
      {
        nome: "Espírito Santo",
        uf: "RN",
        estadoCod: 20,
        cod: 1114,
      },
      {
        nome: "Extremoz",
        uf: "RN",
        estadoCod: 20,
        cod: 1115,
      },
      {
        nome: "Felipe Guerra",
        uf: "RN",
        estadoCod: 20,
        cod: 1116,
      },
      {
        nome: "Fernando Pedroza",
        uf: "RN",
        estadoCod: 20,
        cod: 1117,
      },
      {
        nome: "Florânia",
        uf: "RN",
        estadoCod: 20,
        cod: 1118,
      },
      {
        nome: "Francisco Dantas",
        uf: "RN",
        estadoCod: 20,
        cod: 1119,
      },
      {
        nome: "Frutuoso Gomes",
        uf: "RN",
        estadoCod: 20,
        cod: 1120,
      },
      {
        nome: "Galinhos",
        uf: "RN",
        estadoCod: 20,
        cod: 1121,
      },
      {
        nome: "Goianinha",
        uf: "RN",
        estadoCod: 20,
        cod: 1122,
      },
      {
        nome: "Governador Dix-Sept Rosado",
        uf: "RN",
        estadoCod: 20,
        cod: 1123,
      },
      {
        nome: "Grossos",
        uf: "RN",
        estadoCod: 20,
        cod: 1124,
      },
      {
        nome: "Guamaré",
        uf: "RN",
        estadoCod: 20,
        cod: 1125,
      },
      {
        nome: "Ielmo Marinho",
        uf: "RN",
        estadoCod: 20,
        cod: 1126,
      },
      {
        nome: "Ipanguaçu",
        uf: "RN",
        estadoCod: 20,
        cod: 1127,
      },
      {
        nome: "Ipueira",
        uf: "RN",
        estadoCod: 20,
        cod: 1128,
      },
      {
        nome: "Itajá",
        uf: "RN",
        estadoCod: 20,
        cod: 1129,
      },
      {
        nome: "Itaú",
        uf: "RN",
        estadoCod: 20,
        cod: 1130,
      },
      {
        nome: "Jaçanã",
        uf: "RN",
        estadoCod: 20,
        cod: 1131,
      },
      {
        nome: "Jandaíra",
        uf: "RN",
        estadoCod: 20,
        cod: 1132,
      },
      {
        nome: "Janduís",
        uf: "RN",
        estadoCod: 20,
        cod: 1133,
      },
      {
        nome: "Januário Cicco",
        uf: "RN",
        estadoCod: 20,
        cod: 1134,
      },
      {
        nome: "Japi",
        uf: "RN",
        estadoCod: 20,
        cod: 1135,
      },
      {
        nome: "Jardim de Angicos",
        uf: "RN",
        estadoCod: 20,
        cod: 1136,
      },
      {
        nome: "Jardim de Piranhas",
        uf: "RN",
        estadoCod: 20,
        cod: 1137,
      },
      {
        nome: "Jardim do Seridó",
        uf: "RN",
        estadoCod: 20,
        cod: 1138,
      },
      {
        nome: "João Câmara",
        uf: "RN",
        estadoCod: 20,
        cod: 1139,
      },
      {
        nome: "João Dias",
        uf: "RN",
        estadoCod: 20,
        cod: 1140,
      },
      {
        nome: "José da Penha",
        uf: "RN",
        estadoCod: 20,
        cod: 1141,
      },
      {
        nome: "Jucurutu",
        uf: "RN",
        estadoCod: 20,
        cod: 1142,
      },
      {
        nome: "Jundiá",
        uf: "RN",
        estadoCod: 20,
        cod: 1143,
      },
      {
        nome: "Lagoa D'Anta",
        uf: "RN",
        estadoCod: 20,
        cod: 1144,
      },
      {
        nome: "Lagoa de Pedras",
        uf: "RN",
        estadoCod: 20,
        cod: 1145,
      },
      {
        nome: "Lagoa de Velhos",
        uf: "RN",
        estadoCod: 20,
        cod: 1146,
      },
      {
        nome: "Lagoa Nova",
        uf: "RN",
        estadoCod: 20,
        cod: 1147,
      },
      {
        nome: "Lagoa Salgada",
        uf: "RN",
        estadoCod: 20,
        cod: 1148,
      },
      {
        nome: "Lajes",
        uf: "RN",
        estadoCod: 20,
        cod: 1149,
      },
      {
        nome: "Lajes Pintadas",
        uf: "RN",
        estadoCod: 20,
        cod: 1150,
      },
      {
        nome: "Lucrécia",
        uf: "RN",
        estadoCod: 20,
        cod: 1151,
      },
      {
        nome: "Luís Gomes",
        uf: "RN",
        estadoCod: 20,
        cod: 1152,
      },
      {
        nome: "Macaíba",
        uf: "RN",
        estadoCod: 20,
        cod: 1153,
      },
      {
        nome: "Macau",
        uf: "RN",
        estadoCod: 20,
        cod: 1154,
      },
      {
        nome: "Major Sales",
        uf: "RN",
        estadoCod: 20,
        cod: 1155,
      },
      {
        nome: "Marcelino Vieira",
        uf: "RN",
        estadoCod: 20,
        cod: 1156,
      },
      {
        nome: "Martins",
        uf: "RN",
        estadoCod: 20,
        cod: 1157,
      },
      {
        nome: "Maxaranguape",
        uf: "RN",
        estadoCod: 20,
        cod: 1158,
      },
      {
        nome: "Messias Targino",
        uf: "RN",
        estadoCod: 20,
        cod: 1159,
      },
      {
        nome: "Montanhas",
        uf: "RN",
        estadoCod: 20,
        cod: 1160,
      },
      {
        nome: "Monte Alegre",
        uf: "RN",
        estadoCod: 20,
        cod: 1161,
      },
      {
        nome: "Monte das Gameleiras",
        uf: "RN",
        estadoCod: 20,
        cod: 1162,
      },
      {
        nome: "Mossoró",
        uf: "RN",
        estadoCod: 20,
        cod: 1163,
      },
      {
        nome: "Natal",
        uf: "RN",
        estadoCod: 20,
        cod: 1164,
      },
      {
        nome: "Nísia Floresta",
        uf: "RN",
        estadoCod: 20,
        cod: 1165,
      },
      {
        nome: "Nova Cruz",
        uf: "RN",
        estadoCod: 20,
        cod: 1166,
      },
      {
        nome: "Olho-D'Água do Borges",
        uf: "RN",
        estadoCod: 20,
        cod: 1167,
      },
      {
        nome: "Ouro Branco",
        uf: "RN",
        estadoCod: 20,
        cod: 1168,
      },
      {
        nome: "Paraná",
        uf: "RN",
        estadoCod: 20,
        cod: 1169,
      },
      {
        nome: "Paraú",
        uf: "RN",
        estadoCod: 20,
        cod: 1170,
      },
      {
        nome: "Parazinho",
        uf: "RN",
        estadoCod: 20,
        cod: 1171,
      },
      {
        nome: "Parelhas",
        uf: "RN",
        estadoCod: 20,
        cod: 1172,
      },
      {
        nome: "Rio do Fogo",
        uf: "RN",
        estadoCod: 20,
        cod: 1173,
      },
      {
        nome: "Passa e Fica",
        uf: "RN",
        estadoCod: 20,
        cod: 1174,
      },
      {
        nome: "Passagem",
        uf: "RN",
        estadoCod: 20,
        cod: 1175,
      },
      {
        nome: "Patu",
        uf: "RN",
        estadoCod: 20,
        cod: 1176,
      },
      {
        nome: "Santa Maria",
        uf: "RN",
        estadoCod: 20,
        cod: 1177,
      },
      {
        nome: "Pau dos Ferros",
        uf: "RN",
        estadoCod: 20,
        cod: 1178,
      },
      {
        nome: "Pedra Grande",
        uf: "RN",
        estadoCod: 20,
        cod: 1179,
      },
      {
        nome: "Pedra Preta",
        uf: "RN",
        estadoCod: 20,
        cod: 1180,
      },
      {
        nome: "Pedro Avelino",
        uf: "RN",
        estadoCod: 20,
        cod: 1181,
      },
      {
        nome: "Pedro Velho",
        uf: "RN",
        estadoCod: 20,
        cod: 1182,
      },
      {
        nome: "Pendências",
        uf: "RN",
        estadoCod: 20,
        cod: 1183,
      },
      {
        nome: "Pilões",
        uf: "RN",
        estadoCod: 20,
        cod: 1184,
      },
      {
        nome: "Poço Branco",
        uf: "RN",
        estadoCod: 20,
        cod: 1185,
      },
      {
        nome: "Portalegre",
        uf: "RN",
        estadoCod: 20,
        cod: 1186,
      },
      {
        nome: "Porto do Mangue",
        uf: "RN",
        estadoCod: 20,
        cod: 1187,
      },
      {
        nome: "Presidente Juscelino",
        uf: "RN",
        estadoCod: 20,
        cod: 1188,
      },
      {
        nome: "Pureza",
        uf: "RN",
        estadoCod: 20,
        cod: 1189,
      },
      {
        nome: "Rafael Fernandes",
        uf: "RN",
        estadoCod: 20,
        cod: 1190,
      },
      {
        nome: "Rafael Godeiro",
        uf: "RN",
        estadoCod: 20,
        cod: 1191,
      },
      {
        nome: "Riacho da Cruz",
        uf: "RN",
        estadoCod: 20,
        cod: 1192,
      },
      {
        nome: "Riacho de Santana",
        uf: "RN",
        estadoCod: 20,
        cod: 1193,
      },
      {
        nome: "Riachuelo",
        uf: "RN",
        estadoCod: 20,
        cod: 1194,
      },
      {
        nome: "Rodolfo Fernandes",
        uf: "RN",
        estadoCod: 20,
        cod: 1195,
      },
      {
        nome: "Tibau",
        uf: "RN",
        estadoCod: 20,
        cod: 1196,
      },
      {
        nome: "Ruy Barbosa",
        uf: "RN",
        estadoCod: 20,
        cod: 1197,
      },
      {
        nome: "Santa Cruz",
        uf: "RN",
        estadoCod: 20,
        cod: 1198,
      },
      {
        nome: "Santana do Matos",
        uf: "RN",
        estadoCod: 20,
        cod: 1199,
      },
      {
        nome: "Santana do Seridó",
        uf: "RN",
        estadoCod: 20,
        cod: 1200,
      },
      {
        nome: "Santo Antônio",
        uf: "RN",
        estadoCod: 20,
        cod: 1201,
      },
      {
        nome: "São Bento do Norte",
        uf: "RN",
        estadoCod: 20,
        cod: 1202,
      },
      {
        nome: "São Bento do Trairí",
        uf: "RN",
        estadoCod: 20,
        cod: 1203,
      },
      {
        nome: "São Fernando",
        uf: "RN",
        estadoCod: 20,
        cod: 1204,
      },
      {
        nome: "São Francisco do Oeste",
        uf: "RN",
        estadoCod: 20,
        cod: 1205,
      },
      {
        nome: "São Gonçalo do Amarante",
        uf: "RN",
        estadoCod: 20,
        cod: 1206,
      },
      {
        nome: "São João do Sabugi",
        uf: "RN",
        estadoCod: 20,
        cod: 1207,
      },
      {
        nome: "São José de Mipibu",
        uf: "RN",
        estadoCod: 20,
        cod: 1208,
      },
      {
        nome: "São José do Campestre",
        uf: "RN",
        estadoCod: 20,
        cod: 1209,
      },
      {
        nome: "São José do Seridó",
        uf: "RN",
        estadoCod: 20,
        cod: 1210,
      },
      {
        nome: "São Miguel",
        uf: "RN",
        estadoCod: 20,
        cod: 1211,
      },
      {
        nome: "São Miguel do Gostoso",
        uf: "RN",
        estadoCod: 20,
        cod: 1212,
      },
      {
        nome: "São Paulo do Potengi",
        uf: "RN",
        estadoCod: 20,
        cod: 1213,
      },
      {
        nome: "São Pedro",
        uf: "RN",
        estadoCod: 20,
        cod: 1214,
      },
      {
        nome: "São Rafael",
        uf: "RN",
        estadoCod: 20,
        cod: 1215,
      },
      {
        nome: "São Tomé",
        uf: "RN",
        estadoCod: 20,
        cod: 1216,
      },
      {
        nome: "São Vicente",
        uf: "RN",
        estadoCod: 20,
        cod: 1217,
      },
      {
        nome: "Senador Elói de Souza",
        uf: "RN",
        estadoCod: 20,
        cod: 1218,
      },
      {
        nome: "Senador Georgino Avelino",
        uf: "RN",
        estadoCod: 20,
        cod: 1219,
      },
      {
        nome: "Serra de São Bento",
        uf: "RN",
        estadoCod: 20,
        cod: 1220,
      },
      {
        nome: "Serra do Mel",
        uf: "RN",
        estadoCod: 20,
        cod: 1221,
      },
      {
        nome: "Serra Negra do Norte",
        uf: "RN",
        estadoCod: 20,
        cod: 1222,
      },
      {
        nome: "Serrinha",
        uf: "RN",
        estadoCod: 20,
        cod: 1223,
      },
      {
        nome: "Serrinha dos Pintos",
        uf: "RN",
        estadoCod: 20,
        cod: 1224,
      },
      {
        nome: "Severiano Melo",
        uf: "RN",
        estadoCod: 20,
        cod: 1225,
      },
      {
        nome: "Sítio Novo",
        uf: "RN",
        estadoCod: 20,
        cod: 1226,
      },
      {
        nome: "Taboleiro Grande",
        uf: "RN",
        estadoCod: 20,
        cod: 1227,
      },
      {
        nome: "Taipu",
        uf: "RN",
        estadoCod: 20,
        cod: 1228,
      },
      {
        nome: "Tangará",
        uf: "RN",
        estadoCod: 20,
        cod: 1229,
      },
      {
        nome: "Tenente Ananias",
        uf: "RN",
        estadoCod: 20,
        cod: 1230,
      },
      {
        nome: "Tenente Laurentino Cruz",
        uf: "RN",
        estadoCod: 20,
        cod: 1231,
      },
      {
        nome: "Tibau do Sul",
        uf: "RN",
        estadoCod: 20,
        cod: 1232,
      },
      {
        nome: "Timbaúba dos Batistas",
        uf: "RN",
        estadoCod: 20,
        cod: 1233,
      },
      {
        nome: "Touros",
        uf: "RN",
        estadoCod: 20,
        cod: 1234,
      },
      {
        nome: "Triunfo Potiguar",
        uf: "RN",
        estadoCod: 20,
        cod: 1235,
      },
      {
        nome: "Umarizal",
        uf: "RN",
        estadoCod: 20,
        cod: 1236,
      },
      {
        nome: "Upanema",
        uf: "RN",
        estadoCod: 20,
        cod: 1237,
      },
      {
        nome: "Várzea",
        uf: "RN",
        estadoCod: 20,
        cod: 1238,
      },
      {
        nome: "Venha-Ver",
        uf: "RN",
        estadoCod: 20,
        cod: 1239,
      },
      {
        nome: "Vera Cruz",
        uf: "RN",
        estadoCod: 20,
        cod: 1240,
      },
      {
        nome: "Viçosa",
        uf: "RN",
        estadoCod: 20,
        cod: 1241,
      },
      {
        nome: "Vila Flor",
        uf: "RN",
        estadoCod: 20,
        cod: 1242,
      },
    ],
  },
  {
    nome: "Paraíba",
    uf: "PB",
    regiaoCod: 2,
    cod: 15,
    cidades: [
      {
        nome: "Água Branca",
        uf: "PB",
        estadoCod: 15,
        cod: 1243,
      },
      {
        nome: "Aguiar",
        uf: "PB",
        estadoCod: 15,
        cod: 1244,
      },
      {
        nome: "Alagoa Grande",
        uf: "PB",
        estadoCod: 15,
        cod: 1245,
      },
      {
        nome: "Alagoa Nova",
        uf: "PB",
        estadoCod: 15,
        cod: 1246,
      },
      {
        nome: "Alagoinha",
        uf: "PB",
        estadoCod: 15,
        cod: 1247,
      },
      {
        nome: "Alcantil",
        uf: "PB",
        estadoCod: 15,
        cod: 1248,
      },
      {
        nome: "Algodão de Jandaíra",
        uf: "PB",
        estadoCod: 15,
        cod: 1249,
      },
      {
        nome: "Alhandra",
        uf: "PB",
        estadoCod: 15,
        cod: 1250,
      },
      {
        nome: "São João do Rio do Peixe",
        uf: "PB",
        estadoCod: 15,
        cod: 1251,
      },
      {
        nome: "Amparo",
        uf: "PB",
        estadoCod: 15,
        cod: 1252,
      },
      {
        nome: "Aparecida",
        uf: "PB",
        estadoCod: 15,
        cod: 1253,
      },
      {
        nome: "Araçagi",
        uf: "PB",
        estadoCod: 15,
        cod: 1254,
      },
      {
        nome: "Arara",
        uf: "PB",
        estadoCod: 15,
        cod: 1255,
      },
      {
        nome: "Araruna",
        uf: "PB",
        estadoCod: 15,
        cod: 1256,
      },
      {
        nome: "Areia",
        uf: "PB",
        estadoCod: 15,
        cod: 1257,
      },
      {
        nome: "Areia de Baraúnas",
        uf: "PB",
        estadoCod: 15,
        cod: 1258,
      },
      {
        nome: "Areial",
        uf: "PB",
        estadoCod: 15,
        cod: 1259,
      },
      {
        nome: "Aroeiras",
        uf: "PB",
        estadoCod: 15,
        cod: 1260,
      },
      {
        nome: "Assunção",
        uf: "PB",
        estadoCod: 15,
        cod: 1261,
      },
      {
        nome: "Baía da Traição",
        uf: "PB",
        estadoCod: 15,
        cod: 1262,
      },
      {
        nome: "Bananeiras",
        uf: "PB",
        estadoCod: 15,
        cod: 1263,
      },
      {
        nome: "Baraúna",
        uf: "PB",
        estadoCod: 15,
        cod: 1264,
      },
      {
        nome: "Barra de Santana",
        uf: "PB",
        estadoCod: 15,
        cod: 1265,
      },
      {
        nome: "Barra de Santa Rosa",
        uf: "PB",
        estadoCod: 15,
        cod: 1266,
      },
      {
        nome: "Barra de São Miguel",
        uf: "PB",
        estadoCod: 15,
        cod: 1267,
      },
      {
        nome: "Bayeux",
        uf: "PB",
        estadoCod: 15,
        cod: 1268,
      },
      {
        nome: "Belém",
        uf: "PB",
        estadoCod: 15,
        cod: 1269,
      },
      {
        nome: "Belém do Brejo do Cruz",
        uf: "PB",
        estadoCod: 15,
        cod: 1270,
      },
      {
        nome: "Bernardino Batista",
        uf: "PB",
        estadoCod: 15,
        cod: 1271,
      },
      {
        nome: "Boa Ventura",
        uf: "PB",
        estadoCod: 15,
        cod: 1272,
      },
      {
        nome: "Boa Vista",
        uf: "PB",
        estadoCod: 15,
        cod: 1273,
      },
      {
        nome: "Bom Jesus",
        uf: "PB",
        estadoCod: 15,
        cod: 1274,
      },
      {
        nome: "Bom Sucesso",
        uf: "PB",
        estadoCod: 15,
        cod: 1275,
      },
      {
        nome: "Bonito de Santa Fé",
        uf: "PB",
        estadoCod: 15,
        cod: 1276,
      },
      {
        nome: "Boqueirão",
        uf: "PB",
        estadoCod: 15,
        cod: 1277,
      },
      {
        nome: "Igaracy",
        uf: "PB",
        estadoCod: 15,
        cod: 1278,
      },
      {
        nome: "Borborema",
        uf: "PB",
        estadoCod: 15,
        cod: 1279,
      },
      {
        nome: "Brejo do Cruz",
        uf: "PB",
        estadoCod: 15,
        cod: 1280,
      },
      {
        nome: "Brejo dos Santos",
        uf: "PB",
        estadoCod: 15,
        cod: 1281,
      },
      {
        nome: "Caaporã",
        uf: "PB",
        estadoCod: 15,
        cod: 1282,
      },
      {
        nome: "Cabaceiras",
        uf: "PB",
        estadoCod: 15,
        cod: 1283,
      },
      {
        nome: "Cabedelo",
        uf: "PB",
        estadoCod: 15,
        cod: 1284,
      },
      {
        nome: "Cachoeira dos Índios",
        uf: "PB",
        estadoCod: 15,
        cod: 1285,
      },
      {
        nome: "Cacimba de Areia",
        uf: "PB",
        estadoCod: 15,
        cod: 1286,
      },
      {
        nome: "Cacimba de Dentro",
        uf: "PB",
        estadoCod: 15,
        cod: 1287,
      },
      {
        nome: "Cacimbas",
        uf: "PB",
        estadoCod: 15,
        cod: 1288,
      },
      {
        nome: "Caiçara",
        uf: "PB",
        estadoCod: 15,
        cod: 1289,
      },
      {
        nome: "Cajazeiras",
        uf: "PB",
        estadoCod: 15,
        cod: 1290,
      },
      {
        nome: "Cajazeirinhas",
        uf: "PB",
        estadoCod: 15,
        cod: 1291,
      },
      {
        nome: "Caldas Brandão",
        uf: "PB",
        estadoCod: 15,
        cod: 1292,
      },
      {
        nome: "Camalaú",
        uf: "PB",
        estadoCod: 15,
        cod: 1293,
      },
      {
        nome: "Campina Grande",
        uf: "PB",
        estadoCod: 15,
        cod: 1294,
      },
      {
        nome: "Capim",
        uf: "PB",
        estadoCod: 15,
        cod: 1295,
      },
      {
        nome: "Caraúbas",
        uf: "PB",
        estadoCod: 15,
        cod: 1296,
      },
      {
        nome: "Carrapateira",
        uf: "PB",
        estadoCod: 15,
        cod: 1297,
      },
      {
        nome: "Casserengue",
        uf: "PB",
        estadoCod: 15,
        cod: 1298,
      },
      {
        nome: "Catingueira",
        uf: "PB",
        estadoCod: 15,
        cod: 1299,
      },
      {
        nome: "Catolé do Rocha",
        uf: "PB",
        estadoCod: 15,
        cod: 1300,
      },
      {
        nome: "Caturité",
        uf: "PB",
        estadoCod: 15,
        cod: 1301,
      },
      {
        nome: "Conceição",
        uf: "PB",
        estadoCod: 15,
        cod: 1302,
      },
      {
        nome: "Condado",
        uf: "PB",
        estadoCod: 15,
        cod: 1303,
      },
      {
        nome: "Conde",
        uf: "PB",
        estadoCod: 15,
        cod: 1304,
      },
      {
        nome: "Congo",
        uf: "PB",
        estadoCod: 15,
        cod: 1305,
      },
      {
        nome: "Coremas",
        uf: "PB",
        estadoCod: 15,
        cod: 1306,
      },
      {
        nome: "Coxixola",
        uf: "PB",
        estadoCod: 15,
        cod: 1307,
      },
      {
        nome: "Cruz do Espírito Santo",
        uf: "PB",
        estadoCod: 15,
        cod: 1308,
      },
      {
        nome: "Cubati",
        uf: "PB",
        estadoCod: 15,
        cod: 1309,
      },
      {
        nome: "Cuité",
        uf: "PB",
        estadoCod: 15,
        cod: 1310,
      },
      {
        nome: "Cuitegi",
        uf: "PB",
        estadoCod: 15,
        cod: 1311,
      },
      {
        nome: "Cuité de Mamanguape",
        uf: "PB",
        estadoCod: 15,
        cod: 1312,
      },
      {
        nome: "Curral de Cima",
        uf: "PB",
        estadoCod: 15,
        cod: 1313,
      },
      {
        nome: "Curral Velho",
        uf: "PB",
        estadoCod: 15,
        cod: 1314,
      },
      {
        nome: "Damião",
        uf: "PB",
        estadoCod: 15,
        cod: 1315,
      },
      {
        nome: "Desterro",
        uf: "PB",
        estadoCod: 15,
        cod: 1316,
      },
      {
        nome: "Vista Serrana",
        uf: "PB",
        estadoCod: 15,
        cod: 1317,
      },
      {
        nome: "Diamante",
        uf: "PB",
        estadoCod: 15,
        cod: 1318,
      },
      {
        nome: "Dona Inês",
        uf: "PB",
        estadoCod: 15,
        cod: 1319,
      },
      {
        nome: "Duas Estradas",
        uf: "PB",
        estadoCod: 15,
        cod: 1320,
      },
      {
        nome: "Emas",
        uf: "PB",
        estadoCod: 15,
        cod: 1321,
      },
      {
        nome: "Esperança",
        uf: "PB",
        estadoCod: 15,
        cod: 1322,
      },
      {
        nome: "Fagundes",
        uf: "PB",
        estadoCod: 15,
        cod: 1323,
      },
      {
        nome: "Frei Martinho",
        uf: "PB",
        estadoCod: 15,
        cod: 1324,
      },
      {
        nome: "Gado Bravo",
        uf: "PB",
        estadoCod: 15,
        cod: 1325,
      },
      {
        nome: "Guarabira",
        uf: "PB",
        estadoCod: 15,
        cod: 1326,
      },
      {
        nome: "Gurinhém",
        uf: "PB",
        estadoCod: 15,
        cod: 1327,
      },
      {
        nome: "Gurjão",
        uf: "PB",
        estadoCod: 15,
        cod: 1328,
      },
      {
        nome: "Ibiara",
        uf: "PB",
        estadoCod: 15,
        cod: 1329,
      },
      {
        nome: "Imaculada",
        uf: "PB",
        estadoCod: 15,
        cod: 1330,
      },
      {
        nome: "Ingá",
        uf: "PB",
        estadoCod: 15,
        cod: 1331,
      },
      {
        nome: "Itabaiana",
        uf: "PB",
        estadoCod: 15,
        cod: 1332,
      },
      {
        nome: "Itaporanga",
        uf: "PB",
        estadoCod: 15,
        cod: 1333,
      },
      {
        nome: "Itapororoca",
        uf: "PB",
        estadoCod: 15,
        cod: 1334,
      },
      {
        nome: "Itatuba",
        uf: "PB",
        estadoCod: 15,
        cod: 1335,
      },
      {
        nome: "Jacaraú",
        uf: "PB",
        estadoCod: 15,
        cod: 1336,
      },
      {
        nome: "Jericó",
        uf: "PB",
        estadoCod: 15,
        cod: 1337,
      },
      {
        nome: "João Pessoa",
        uf: "PB",
        estadoCod: 15,
        cod: 1338,
      },
      {
        nome: "Juarez Távora",
        uf: "PB",
        estadoCod: 15,
        cod: 1339,
      },
      {
        nome: "Juazeirinho",
        uf: "PB",
        estadoCod: 15,
        cod: 1340,
      },
      {
        nome: "Junco do Seridó",
        uf: "PB",
        estadoCod: 15,
        cod: 1341,
      },
      {
        nome: "Juripiranga",
        uf: "PB",
        estadoCod: 15,
        cod: 1342,
      },
      {
        nome: "Juru",
        uf: "PB",
        estadoCod: 15,
        cod: 1343,
      },
      {
        nome: "Lagoa",
        uf: "PB",
        estadoCod: 15,
        cod: 1344,
      },
      {
        nome: "Lagoa de Dentro",
        uf: "PB",
        estadoCod: 15,
        cod: 1345,
      },
      {
        nome: "Lagoa Seca",
        uf: "PB",
        estadoCod: 15,
        cod: 1346,
      },
      {
        nome: "Lastro",
        uf: "PB",
        estadoCod: 15,
        cod: 1347,
      },
      {
        nome: "Livramento",
        uf: "PB",
        estadoCod: 15,
        cod: 1348,
      },
      {
        nome: "Logradouro",
        uf: "PB",
        estadoCod: 15,
        cod: 1349,
      },
      {
        nome: "Lucena",
        uf: "PB",
        estadoCod: 15,
        cod: 1350,
      },
      {
        nome: "Mãe D'Água",
        uf: "PB",
        estadoCod: 15,
        cod: 1351,
      },
      {
        nome: "Malta",
        uf: "PB",
        estadoCod: 15,
        cod: 1352,
      },
      {
        nome: "Mamanguape",
        uf: "PB",
        estadoCod: 15,
        cod: 1353,
      },
      {
        nome: "Manaíra",
        uf: "PB",
        estadoCod: 15,
        cod: 1354,
      },
      {
        nome: "Marcação",
        uf: "PB",
        estadoCod: 15,
        cod: 1355,
      },
      {
        nome: "Mari",
        uf: "PB",
        estadoCod: 15,
        cod: 1356,
      },
      {
        nome: "Marizópolis",
        uf: "PB",
        estadoCod: 15,
        cod: 1357,
      },
      {
        nome: "Massaranduba",
        uf: "PB",
        estadoCod: 15,
        cod: 1358,
      },
      {
        nome: "Mataraca",
        uf: "PB",
        estadoCod: 15,
        cod: 1359,
      },
      {
        nome: "Matinhas",
        uf: "PB",
        estadoCod: 15,
        cod: 1360,
      },
      {
        nome: "Mato Grosso",
        uf: "PB",
        estadoCod: 15,
        cod: 1361,
      },
      {
        nome: "Maturéia",
        uf: "PB",
        estadoCod: 15,
        cod: 1362,
      },
      {
        nome: "Mogeiro",
        uf: "PB",
        estadoCod: 15,
        cod: 1363,
      },
      {
        nome: "Montadas",
        uf: "PB",
        estadoCod: 15,
        cod: 1364,
      },
      {
        nome: "Monte Horebe",
        uf: "PB",
        estadoCod: 15,
        cod: 1365,
      },
      {
        nome: "Monteiro",
        uf: "PB",
        estadoCod: 15,
        cod: 1366,
      },
      {
        nome: "Mulungu",
        uf: "PB",
        estadoCod: 15,
        cod: 1367,
      },
      {
        nome: "Natuba",
        uf: "PB",
        estadoCod: 15,
        cod: 1368,
      },
      {
        nome: "Nazarezinho",
        uf: "PB",
        estadoCod: 15,
        cod: 1369,
      },
      {
        nome: "Nova Floresta",
        uf: "PB",
        estadoCod: 15,
        cod: 1370,
      },
      {
        nome: "Nova Olinda",
        uf: "PB",
        estadoCod: 15,
        cod: 1371,
      },
      {
        nome: "Nova Palmeira",
        uf: "PB",
        estadoCod: 15,
        cod: 1372,
      },
      {
        nome: "Olho D'Água",
        uf: "PB",
        estadoCod: 15,
        cod: 1373,
      },
      {
        nome: "Olivedos",
        uf: "PB",
        estadoCod: 15,
        cod: 1374,
      },
      {
        nome: "Ouro Velho",
        uf: "PB",
        estadoCod: 15,
        cod: 1375,
      },
      {
        nome: "Parari",
        uf: "PB",
        estadoCod: 15,
        cod: 1376,
      },
      {
        nome: "Passagem",
        uf: "PB",
        estadoCod: 15,
        cod: 1377,
      },
      {
        nome: "Patos",
        uf: "PB",
        estadoCod: 15,
        cod: 1378,
      },
      {
        nome: "Paulista",
        uf: "PB",
        estadoCod: 15,
        cod: 1379,
      },
      {
        nome: "Pedra Branca",
        uf: "PB",
        estadoCod: 15,
        cod: 1380,
      },
      {
        nome: "Pedra Lavrada",
        uf: "PB",
        estadoCod: 15,
        cod: 1381,
      },
      {
        nome: "Pedras de Fogo",
        uf: "PB",
        estadoCod: 15,
        cod: 1382,
      },
      {
        nome: "Piancó",
        uf: "PB",
        estadoCod: 15,
        cod: 1383,
      },
      {
        nome: "Picuí",
        uf: "PB",
        estadoCod: 15,
        cod: 1384,
      },
      {
        nome: "Pilar",
        uf: "PB",
        estadoCod: 15,
        cod: 1385,
      },
      {
        nome: "Pilões",
        uf: "PB",
        estadoCod: 15,
        cod: 1386,
      },
      {
        nome: "Pilõezinhos",
        uf: "PB",
        estadoCod: 15,
        cod: 1387,
      },
      {
        nome: "Pirpirituba",
        uf: "PB",
        estadoCod: 15,
        cod: 1388,
      },
      {
        nome: "Pitimbu",
        uf: "PB",
        estadoCod: 15,
        cod: 1389,
      },
      {
        nome: "Pocinhos",
        uf: "PB",
        estadoCod: 15,
        cod: 1390,
      },
      {
        nome: "Poço Dantas",
        uf: "PB",
        estadoCod: 15,
        cod: 1391,
      },
      {
        nome: "Poço de José de Moura",
        uf: "PB",
        estadoCod: 15,
        cod: 1392,
      },
      {
        nome: "Pombal",
        uf: "PB",
        estadoCod: 15,
        cod: 1393,
      },
      {
        nome: "Prata",
        uf: "PB",
        estadoCod: 15,
        cod: 1394,
      },
      {
        nome: "Princesa Isabel",
        uf: "PB",
        estadoCod: 15,
        cod: 1395,
      },
      {
        nome: "Puxinanã",
        uf: "PB",
        estadoCod: 15,
        cod: 1396,
      },
      {
        nome: "Queimadas",
        uf: "PB",
        estadoCod: 15,
        cod: 1397,
      },
      {
        nome: "Quixabá",
        uf: "PB",
        estadoCod: 15,
        cod: 1398,
      },
      {
        nome: "Remígio",
        uf: "PB",
        estadoCod: 15,
        cod: 1399,
      },
      {
        nome: "Pedro Régis",
        uf: "PB",
        estadoCod: 15,
        cod: 1400,
      },
      {
        nome: "Riachão",
        uf: "PB",
        estadoCod: 15,
        cod: 1401,
      },
      {
        nome: "Riachão do Bacamarte",
        uf: "PB",
        estadoCod: 15,
        cod: 1402,
      },
      {
        nome: "Riachão do Poço",
        uf: "PB",
        estadoCod: 15,
        cod: 1403,
      },
      {
        nome: "Riacho de Santo Antônio",
        uf: "PB",
        estadoCod: 15,
        cod: 1404,
      },
      {
        nome: "Riacho dos Cavalos",
        uf: "PB",
        estadoCod: 15,
        cod: 1405,
      },
      {
        nome: "Rio Tinto",
        uf: "PB",
        estadoCod: 15,
        cod: 1406,
      },
      {
        nome: "Salgadinho",
        uf: "PB",
        estadoCod: 15,
        cod: 1407,
      },
      {
        nome: "Salgado de São Félix",
        uf: "PB",
        estadoCod: 15,
        cod: 1408,
      },
      {
        nome: "Santa Cecília",
        uf: "PB",
        estadoCod: 15,
        cod: 1409,
      },
      {
        nome: "Santa Cruz",
        uf: "PB",
        estadoCod: 15,
        cod: 1410,
      },
      {
        nome: "Santa Helena",
        uf: "PB",
        estadoCod: 15,
        cod: 1411,
      },
      {
        nome: "Santa Inês",
        uf: "PB",
        estadoCod: 15,
        cod: 1412,
      },
      {
        nome: "Santa Luzia",
        uf: "PB",
        estadoCod: 15,
        cod: 1413,
      },
      {
        nome: "Santana de Mangueira",
        uf: "PB",
        estadoCod: 15,
        cod: 1414,
      },
      {
        nome: "Santana dos Garrotes",
        uf: "PB",
        estadoCod: 15,
        cod: 1415,
      },
      {
        nome: "Joca Claudino",
        uf: "PB",
        estadoCod: 15,
        cod: 1416,
      },
      {
        nome: "Santa Rita",
        uf: "PB",
        estadoCod: 15,
        cod: 1417,
      },
      {
        nome: "Santa Teresinha",
        uf: "PB",
        estadoCod: 15,
        cod: 1418,
      },
      {
        nome: "Santo André",
        uf: "PB",
        estadoCod: 15,
        cod: 1419,
      },
      {
        nome: "São Bento",
        uf: "PB",
        estadoCod: 15,
        cod: 1420,
      },
      {
        nome: "São Bentinho",
        uf: "PB",
        estadoCod: 15,
        cod: 1421,
      },
      {
        nome: "São Domingos do Cariri",
        uf: "PB",
        estadoCod: 15,
        cod: 1422,
      },
      {
        nome: "São Domingos",
        uf: "PB",
        estadoCod: 15,
        cod: 1423,
      },
      {
        nome: "São Francisco",
        uf: "PB",
        estadoCod: 15,
        cod: 1424,
      },
      {
        nome: "São João do Cariri",
        uf: "PB",
        estadoCod: 15,
        cod: 1425,
      },
      {
        nome: "São João do Tigre",
        uf: "PB",
        estadoCod: 15,
        cod: 1426,
      },
      {
        nome: "São José da Lagoa Tapada",
        uf: "PB",
        estadoCod: 15,
        cod: 1427,
      },
      {
        nome: "São José de Caiana",
        uf: "PB",
        estadoCod: 15,
        cod: 1428,
      },
      {
        nome: "São José de Espinharas",
        uf: "PB",
        estadoCod: 15,
        cod: 1429,
      },
      {
        nome: "São José dos Ramos",
        uf: "PB",
        estadoCod: 15,
        cod: 1430,
      },
      {
        nome: "São José de Piranhas",
        uf: "PB",
        estadoCod: 15,
        cod: 1431,
      },
      {
        nome: "São José de Princesa",
        uf: "PB",
        estadoCod: 15,
        cod: 1432,
      },
      {
        nome: "São José do Bonfim",
        uf: "PB",
        estadoCod: 15,
        cod: 1433,
      },
      {
        nome: "São José do Brejo do Cruz",
        uf: "PB",
        estadoCod: 15,
        cod: 1434,
      },
      {
        nome: "São José do Sabugi",
        uf: "PB",
        estadoCod: 15,
        cod: 1435,
      },
      {
        nome: "São José dos Cordeiros",
        uf: "PB",
        estadoCod: 15,
        cod: 1436,
      },
      {
        nome: "São Mamede",
        uf: "PB",
        estadoCod: 15,
        cod: 1437,
      },
      {
        nome: "São Miguel de Taipu",
        uf: "PB",
        estadoCod: 15,
        cod: 1438,
      },
      {
        nome: "São Sebastião de Lagoa de Roça",
        uf: "PB",
        estadoCod: 15,
        cod: 1439,
      },
      {
        nome: "São Sebastião do Umbuzeiro",
        uf: "PB",
        estadoCod: 15,
        cod: 1440,
      },
      {
        nome: "Sapé",
        uf: "PB",
        estadoCod: 15,
        cod: 1441,
      },
      {
        nome: "São Vicente do Seridó",
        uf: "PB",
        estadoCod: 15,
        cod: 1442,
      },
      {
        nome: "Serra Branca",
        uf: "PB",
        estadoCod: 15,
        cod: 1443,
      },
      {
        nome: "Serra da Raiz",
        uf: "PB",
        estadoCod: 15,
        cod: 1444,
      },
      {
        nome: "Serra Grande",
        uf: "PB",
        estadoCod: 15,
        cod: 1445,
      },
      {
        nome: "Serra Redonda",
        uf: "PB",
        estadoCod: 15,
        cod: 1446,
      },
      {
        nome: "Serraria",
        uf: "PB",
        estadoCod: 15,
        cod: 1447,
      },
      {
        nome: "Sertãozinho",
        uf: "PB",
        estadoCod: 15,
        cod: 1448,
      },
      {
        nome: "Sobrado",
        uf: "PB",
        estadoCod: 15,
        cod: 1449,
      },
      {
        nome: "Solânea",
        uf: "PB",
        estadoCod: 15,
        cod: 1450,
      },
      {
        nome: "Soledade",
        uf: "PB",
        estadoCod: 15,
        cod: 1451,
      },
      {
        nome: "Sossêgo",
        uf: "PB",
        estadoCod: 15,
        cod: 1452,
      },
      {
        nome: "Sousa",
        uf: "PB",
        estadoCod: 15,
        cod: 1453,
      },
      {
        nome: "Sumé",
        uf: "PB",
        estadoCod: 15,
        cod: 1454,
      },
      {
        nome: "Tacima",
        uf: "PB",
        estadoCod: 15,
        cod: 1455,
      },
      {
        nome: "Taperoá",
        uf: "PB",
        estadoCod: 15,
        cod: 1456,
      },
      {
        nome: "Tavares",
        uf: "PB",
        estadoCod: 15,
        cod: 1457,
      },
      {
        nome: "Teixeira",
        uf: "PB",
        estadoCod: 15,
        cod: 1458,
      },
      {
        nome: "Tenório",
        uf: "PB",
        estadoCod: 15,
        cod: 1459,
      },
      {
        nome: "Triunfo",
        uf: "PB",
        estadoCod: 15,
        cod: 1460,
      },
      {
        nome: "Uiraúna",
        uf: "PB",
        estadoCod: 15,
        cod: 1461,
      },
      {
        nome: "Umbuzeiro",
        uf: "PB",
        estadoCod: 15,
        cod: 1462,
      },
      {
        nome: "Várzea",
        uf: "PB",
        estadoCod: 15,
        cod: 1463,
      },
      {
        nome: "Vieirópolis",
        uf: "PB",
        estadoCod: 15,
        cod: 1464,
      },
      {
        nome: "Zabelê",
        uf: "PB",
        estadoCod: 15,
        cod: 1465,
      },
    ],
  },
  {
    nome: "Pernambuco",
    uf: "PE",
    regiaoCod: 2,
    cod: 17,
    cidades: [
      {
        nome: "Abreu e Lima",
        uf: "PE",
        estadoCod: 17,
        cod: 1466,
      },
      {
        nome: "Afogados da Ingazeira",
        uf: "PE",
        estadoCod: 17,
        cod: 1467,
      },
      {
        nome: "Afrânio",
        uf: "PE",
        estadoCod: 17,
        cod: 1468,
      },
      {
        nome: "Agrestina",
        uf: "PE",
        estadoCod: 17,
        cod: 1469,
      },
      {
        nome: "Água Preta",
        uf: "PE",
        estadoCod: 17,
        cod: 1470,
      },
      {
        nome: "Águas Belas",
        uf: "PE",
        estadoCod: 17,
        cod: 1471,
      },
      {
        nome: "Alagoinha",
        uf: "PE",
        estadoCod: 17,
        cod: 1472,
      },
      {
        nome: "Aliança",
        uf: "PE",
        estadoCod: 17,
        cod: 1473,
      },
      {
        nome: "Altinho",
        uf: "PE",
        estadoCod: 17,
        cod: 1474,
      },
      {
        nome: "Amaraji",
        uf: "PE",
        estadoCod: 17,
        cod: 1475,
      },
      {
        nome: "Angelim",
        uf: "PE",
        estadoCod: 17,
        cod: 1476,
      },
      {
        nome: "Araçoiaba",
        uf: "PE",
        estadoCod: 17,
        cod: 1477,
      },
      {
        nome: "Araripina",
        uf: "PE",
        estadoCod: 17,
        cod: 1478,
      },
      {
        nome: "Arcoverde",
        uf: "PE",
        estadoCod: 17,
        cod: 1479,
      },
      {
        nome: "Barra de Guabiraba",
        uf: "PE",
        estadoCod: 17,
        cod: 1480,
      },
      {
        nome: "Barreiros",
        uf: "PE",
        estadoCod: 17,
        cod: 1481,
      },
      {
        nome: "Belém de Maria",
        uf: "PE",
        estadoCod: 17,
        cod: 1482,
      },
      {
        nome: "Belém do São Francisco",
        uf: "PE",
        estadoCod: 17,
        cod: 1483,
      },
      {
        nome: "Belo Jardim",
        uf: "PE",
        estadoCod: 17,
        cod: 1484,
      },
      {
        nome: "Betânia",
        uf: "PE",
        estadoCod: 17,
        cod: 1485,
      },
      {
        nome: "Bezerros",
        uf: "PE",
        estadoCod: 17,
        cod: 1486,
      },
      {
        nome: "Bodocó",
        uf: "PE",
        estadoCod: 17,
        cod: 1487,
      },
      {
        nome: "Bom Conselho",
        uf: "PE",
        estadoCod: 17,
        cod: 1488,
      },
      {
        nome: "Bom Jardim",
        uf: "PE",
        estadoCod: 17,
        cod: 1489,
      },
      {
        nome: "Bonito",
        uf: "PE",
        estadoCod: 17,
        cod: 1490,
      },
      {
        nome: "Brejão",
        uf: "PE",
        estadoCod: 17,
        cod: 1491,
      },
      {
        nome: "Brejinho",
        uf: "PE",
        estadoCod: 17,
        cod: 1492,
      },
      {
        nome: "Brejo da Madre de Deus",
        uf: "PE",
        estadoCod: 17,
        cod: 1493,
      },
      {
        nome: "Buenos Aires",
        uf: "PE",
        estadoCod: 17,
        cod: 1494,
      },
      {
        nome: "Buíque",
        uf: "PE",
        estadoCod: 17,
        cod: 1495,
      },
      {
        nome: "Cabo de Santo Agostinho",
        uf: "PE",
        estadoCod: 17,
        cod: 1496,
      },
      {
        nome: "Cabrobó",
        uf: "PE",
        estadoCod: 17,
        cod: 1497,
      },
      {
        nome: "Cachoeirinha",
        uf: "PE",
        estadoCod: 17,
        cod: 1498,
      },
      {
        nome: "Caetés",
        uf: "PE",
        estadoCod: 17,
        cod: 1499,
      },
      {
        nome: "Calçado",
        uf: "PE",
        estadoCod: 17,
        cod: 1500,
      },
      {
        nome: "Calumbi",
        uf: "PE",
        estadoCod: 17,
        cod: 1501,
      },
      {
        nome: "Camaragibe",
        uf: "PE",
        estadoCod: 17,
        cod: 1502,
      },
      {
        nome: "Camocim de São Félix",
        uf: "PE",
        estadoCod: 17,
        cod: 1503,
      },
      {
        nome: "Camutanga",
        uf: "PE",
        estadoCod: 17,
        cod: 1504,
      },
      {
        nome: "Canhotinho",
        uf: "PE",
        estadoCod: 17,
        cod: 1505,
      },
      {
        nome: "Capoeiras",
        uf: "PE",
        estadoCod: 17,
        cod: 1506,
      },
      {
        nome: "Carnaíba",
        uf: "PE",
        estadoCod: 17,
        cod: 1507,
      },
      {
        nome: "Carnaubeira da Penha",
        uf: "PE",
        estadoCod: 17,
        cod: 1508,
      },
      {
        nome: "Carpina",
        uf: "PE",
        estadoCod: 17,
        cod: 1509,
      },
      {
        nome: "Caruaru",
        uf: "PE",
        estadoCod: 17,
        cod: 1510,
      },
      {
        nome: "Casinhas",
        uf: "PE",
        estadoCod: 17,
        cod: 1511,
      },
      {
        nome: "Catende",
        uf: "PE",
        estadoCod: 17,
        cod: 1512,
      },
      {
        nome: "Cedro",
        uf: "PE",
        estadoCod: 17,
        cod: 1513,
      },
      {
        nome: "Chã de Alegria",
        uf: "PE",
        estadoCod: 17,
        cod: 1514,
      },
      {
        nome: "Chã Grande",
        uf: "PE",
        estadoCod: 17,
        cod: 1515,
      },
      {
        nome: "Condado",
        uf: "PE",
        estadoCod: 17,
        cod: 1516,
      },
      {
        nome: "Correntes",
        uf: "PE",
        estadoCod: 17,
        cod: 1517,
      },
      {
        nome: "Cortês",
        uf: "PE",
        estadoCod: 17,
        cod: 1518,
      },
      {
        nome: "Cumaru",
        uf: "PE",
        estadoCod: 17,
        cod: 1519,
      },
      {
        nome: "Cupira",
        uf: "PE",
        estadoCod: 17,
        cod: 1520,
      },
      {
        nome: "Custódia",
        uf: "PE",
        estadoCod: 17,
        cod: 1521,
      },
      {
        nome: "Dormentes",
        uf: "PE",
        estadoCod: 17,
        cod: 1522,
      },
      {
        nome: "Escada",
        uf: "PE",
        estadoCod: 17,
        cod: 1523,
      },
      {
        nome: "Exu",
        uf: "PE",
        estadoCod: 17,
        cod: 1524,
      },
      {
        nome: "Feira Nova",
        uf: "PE",
        estadoCod: 17,
        cod: 1525,
      },
      {
        nome: "Fernando de Noronha",
        uf: "PE",
        estadoCod: 17,
        cod: 1526,
      },
      {
        nome: "Ferreiros",
        uf: "PE",
        estadoCod: 17,
        cod: 1527,
      },
      {
        nome: "Flores",
        uf: "PE",
        estadoCod: 17,
        cod: 1528,
      },
      {
        nome: "Floresta",
        uf: "PE",
        estadoCod: 17,
        cod: 1529,
      },
      {
        nome: "Frei Miguelinho",
        uf: "PE",
        estadoCod: 17,
        cod: 1530,
      },
      {
        nome: "Gameleira",
        uf: "PE",
        estadoCod: 17,
        cod: 1531,
      },
      {
        nome: "Garanhuns",
        uf: "PE",
        estadoCod: 17,
        cod: 1532,
      },
      {
        nome: "Glória do Goitá",
        uf: "PE",
        estadoCod: 17,
        cod: 1533,
      },
      {
        nome: "Goiana",
        uf: "PE",
        estadoCod: 17,
        cod: 1534,
      },
      {
        nome: "Granito",
        uf: "PE",
        estadoCod: 17,
        cod: 1535,
      },
      {
        nome: "Gravatá",
        uf: "PE",
        estadoCod: 17,
        cod: 1536,
      },
      {
        nome: "Iati",
        uf: "PE",
        estadoCod: 17,
        cod: 1537,
      },
      {
        nome: "Ibimirim",
        uf: "PE",
        estadoCod: 17,
        cod: 1538,
      },
      {
        nome: "Ibirajuba",
        uf: "PE",
        estadoCod: 17,
        cod: 1539,
      },
      {
        nome: "Igarassu",
        uf: "PE",
        estadoCod: 17,
        cod: 1540,
      },
      {
        nome: "Iguaraci",
        uf: "PE",
        estadoCod: 17,
        cod: 1541,
      },
      {
        nome: "Inajá",
        uf: "PE",
        estadoCod: 17,
        cod: 1542,
      },
      {
        nome: "Ingazeira",
        uf: "PE",
        estadoCod: 17,
        cod: 1543,
      },
      {
        nome: "Ipojuca",
        uf: "PE",
        estadoCod: 17,
        cod: 1544,
      },
      {
        nome: "Ipubi",
        uf: "PE",
        estadoCod: 17,
        cod: 1545,
      },
      {
        nome: "Itacuruba",
        uf: "PE",
        estadoCod: 17,
        cod: 1546,
      },
      {
        nome: "Itaíba",
        uf: "PE",
        estadoCod: 17,
        cod: 1547,
      },
      {
        nome: "Ilha de Itamaracá",
        uf: "PE",
        estadoCod: 17,
        cod: 1548,
      },
      {
        nome: "Itambé",
        uf: "PE",
        estadoCod: 17,
        cod: 1549,
      },
      {
        nome: "Itapetim",
        uf: "PE",
        estadoCod: 17,
        cod: 1550,
      },
      {
        nome: "Itapissuma",
        uf: "PE",
        estadoCod: 17,
        cod: 1551,
      },
      {
        nome: "Itaquitinga",
        uf: "PE",
        estadoCod: 17,
        cod: 1552,
      },
      {
        nome: "Jaboatão dos Guararapes",
        uf: "PE",
        estadoCod: 17,
        cod: 1553,
      },
      {
        nome: "Jaqueira",
        uf: "PE",
        estadoCod: 17,
        cod: 1554,
      },
      {
        nome: "Jataúba",
        uf: "PE",
        estadoCod: 17,
        cod: 1555,
      },
      {
        nome: "Jatobá",
        uf: "PE",
        estadoCod: 17,
        cod: 1556,
      },
      {
        nome: "João Alfredo",
        uf: "PE",
        estadoCod: 17,
        cod: 1557,
      },
      {
        nome: "Joaquim Nabuco",
        uf: "PE",
        estadoCod: 17,
        cod: 1558,
      },
      {
        nome: "Jucati",
        uf: "PE",
        estadoCod: 17,
        cod: 1559,
      },
      {
        nome: "Jupi",
        uf: "PE",
        estadoCod: 17,
        cod: 1560,
      },
      {
        nome: "Jurema",
        uf: "PE",
        estadoCod: 17,
        cod: 1561,
      },
      {
        nome: "Lagoa do Carro",
        uf: "PE",
        estadoCod: 17,
        cod: 1562,
      },
      {
        nome: "Lagoa de Itaenga",
        uf: "PE",
        estadoCod: 17,
        cod: 1563,
      },
      {
        nome: "Lagoa do Ouro",
        uf: "PE",
        estadoCod: 17,
        cod: 1564,
      },
      {
        nome: "Lagoa dos Gatos",
        uf: "PE",
        estadoCod: 17,
        cod: 1565,
      },
      {
        nome: "Lagoa Grande",
        uf: "PE",
        estadoCod: 17,
        cod: 1566,
      },
      {
        nome: "Lajedo",
        uf: "PE",
        estadoCod: 17,
        cod: 1567,
      },
      {
        nome: "Limoeiro",
        uf: "PE",
        estadoCod: 17,
        cod: 1568,
      },
      {
        nome: "Macaparana",
        uf: "PE",
        estadoCod: 17,
        cod: 1569,
      },
      {
        nome: "Machados",
        uf: "PE",
        estadoCod: 17,
        cod: 1570,
      },
      {
        nome: "Manari",
        uf: "PE",
        estadoCod: 17,
        cod: 1571,
      },
      {
        nome: "Maraial",
        uf: "PE",
        estadoCod: 17,
        cod: 1572,
      },
      {
        nome: "Mirandiba",
        uf: "PE",
        estadoCod: 17,
        cod: 1573,
      },
      {
        nome: "Moreno",
        uf: "PE",
        estadoCod: 17,
        cod: 1574,
      },
      {
        nome: "Nazaré da Mata",
        uf: "PE",
        estadoCod: 17,
        cod: 1575,
      },
      {
        nome: "Olinda",
        uf: "PE",
        estadoCod: 17,
        cod: 1576,
      },
      {
        nome: "Orobó",
        uf: "PE",
        estadoCod: 17,
        cod: 1577,
      },
      {
        nome: "Orocó",
        uf: "PE",
        estadoCod: 17,
        cod: 1578,
      },
      {
        nome: "Ouricuri",
        uf: "PE",
        estadoCod: 17,
        cod: 1579,
      },
      {
        nome: "Palmares",
        uf: "PE",
        estadoCod: 17,
        cod: 1580,
      },
      {
        nome: "Palmeirina",
        uf: "PE",
        estadoCod: 17,
        cod: 1581,
      },
      {
        nome: "Panelas",
        uf: "PE",
        estadoCod: 17,
        cod: 1582,
      },
      {
        nome: "Paranatama",
        uf: "PE",
        estadoCod: 17,
        cod: 1583,
      },
      {
        nome: "Parnamirim",
        uf: "PE",
        estadoCod: 17,
        cod: 1584,
      },
      {
        nome: "Passira",
        uf: "PE",
        estadoCod: 17,
        cod: 1585,
      },
      {
        nome: "Paudalho",
        uf: "PE",
        estadoCod: 17,
        cod: 1586,
      },
      {
        nome: "Paulista",
        uf: "PE",
        estadoCod: 17,
        cod: 1587,
      },
      {
        nome: "Pedra",
        uf: "PE",
        estadoCod: 17,
        cod: 1588,
      },
      {
        nome: "Pesqueira",
        uf: "PE",
        estadoCod: 17,
        cod: 1589,
      },
      {
        nome: "Petrolândia",
        uf: "PE",
        estadoCod: 17,
        cod: 1590,
      },
      {
        nome: "Petrolina",
        uf: "PE",
        estadoCod: 17,
        cod: 1591,
      },
      {
        nome: "Poção",
        uf: "PE",
        estadoCod: 17,
        cod: 1592,
      },
      {
        nome: "Pombos",
        uf: "PE",
        estadoCod: 17,
        cod: 1593,
      },
      {
        nome: "Primavera",
        uf: "PE",
        estadoCod: 17,
        cod: 1594,
      },
      {
        nome: "Quipapá",
        uf: "PE",
        estadoCod: 17,
        cod: 1595,
      },
      {
        nome: "Quixaba",
        uf: "PE",
        estadoCod: 17,
        cod: 1596,
      },
      {
        nome: "Recife",
        uf: "PE",
        estadoCod: 17,
        cod: 1597,
      },
      {
        nome: "Riacho das Almas",
        uf: "PE",
        estadoCod: 17,
        cod: 1598,
      },
      {
        nome: "Ribeirão",
        uf: "PE",
        estadoCod: 17,
        cod: 1599,
      },
      {
        nome: "Rio Formoso",
        uf: "PE",
        estadoCod: 17,
        cod: 1600,
      },
      {
        nome: "Sairé",
        uf: "PE",
        estadoCod: 17,
        cod: 1601,
      },
      {
        nome: "Salgadinho",
        uf: "PE",
        estadoCod: 17,
        cod: 1602,
      },
      {
        nome: "Salgueiro",
        uf: "PE",
        estadoCod: 17,
        cod: 1603,
      },
      {
        nome: "Saloá",
        uf: "PE",
        estadoCod: 17,
        cod: 1604,
      },
      {
        nome: "Sanharó",
        uf: "PE",
        estadoCod: 17,
        cod: 1605,
      },
      {
        nome: "Santa Cruz",
        uf: "PE",
        estadoCod: 17,
        cod: 1606,
      },
      {
        nome: "Santa Cruz da Baixa Verde",
        uf: "PE",
        estadoCod: 17,
        cod: 1607,
      },
      {
        nome: "Santa Cruz do Capibaribe",
        uf: "PE",
        estadoCod: 17,
        cod: 1608,
      },
      {
        nome: "Santa Filomena",
        uf: "PE",
        estadoCod: 17,
        cod: 1609,
      },
      {
        nome: "Santa Maria da Boa Vista",
        uf: "PE",
        estadoCod: 17,
        cod: 1610,
      },
      {
        nome: "Santa Maria do Cambucá",
        uf: "PE",
        estadoCod: 17,
        cod: 1611,
      },
      {
        nome: "Santa Terezinha",
        uf: "PE",
        estadoCod: 17,
        cod: 1612,
      },
      {
        nome: "São Benedito do Sul",
        uf: "PE",
        estadoCod: 17,
        cod: 1613,
      },
      {
        nome: "São Bento do Una",
        uf: "PE",
        estadoCod: 17,
        cod: 1614,
      },
      {
        nome: "São Caitano",
        uf: "PE",
        estadoCod: 17,
        cod: 1615,
      },
      {
        nome: "São João",
        uf: "PE",
        estadoCod: 17,
        cod: 1616,
      },
      {
        nome: "São Joaquim do Monte",
        uf: "PE",
        estadoCod: 17,
        cod: 1617,
      },
      {
        nome: "São José da Coroa Grande",
        uf: "PE",
        estadoCod: 17,
        cod: 1618,
      },
      {
        nome: "São José do Belmonte",
        uf: "PE",
        estadoCod: 17,
        cod: 1619,
      },
      {
        nome: "São José do Egito",
        uf: "PE",
        estadoCod: 17,
        cod: 1620,
      },
      {
        nome: "São Lourenço da Mata",
        uf: "PE",
        estadoCod: 17,
        cod: 1621,
      },
      {
        nome: "São Vicente Ferrer",
        uf: "PE",
        estadoCod: 17,
        cod: 1622,
      },
      {
        nome: "Serra Talhada",
        uf: "PE",
        estadoCod: 17,
        cod: 1623,
      },
      {
        nome: "Serrita",
        uf: "PE",
        estadoCod: 17,
        cod: 1624,
      },
      {
        nome: "Sertânia",
        uf: "PE",
        estadoCod: 17,
        cod: 1625,
      },
      {
        nome: "Sirinhaém",
        uf: "PE",
        estadoCod: 17,
        cod: 1626,
      },
      {
        nome: "Moreilândia",
        uf: "PE",
        estadoCod: 17,
        cod: 1627,
      },
      {
        nome: "Solidão",
        uf: "PE",
        estadoCod: 17,
        cod: 1628,
      },
      {
        nome: "Surubim",
        uf: "PE",
        estadoCod: 17,
        cod: 1629,
      },
      {
        nome: "Tabira",
        uf: "PE",
        estadoCod: 17,
        cod: 1630,
      },
      {
        nome: "Tacaimbó",
        uf: "PE",
        estadoCod: 17,
        cod: 1631,
      },
      {
        nome: "Tacaratu",
        uf: "PE",
        estadoCod: 17,
        cod: 1632,
      },
      {
        nome: "Tamandaré",
        uf: "PE",
        estadoCod: 17,
        cod: 1633,
      },
      {
        nome: "Taquaritinga do Norte",
        uf: "PE",
        estadoCod: 17,
        cod: 1634,
      },
      {
        nome: "Terezinha",
        uf: "PE",
        estadoCod: 17,
        cod: 1635,
      },
      {
        nome: "Terra Nova",
        uf: "PE",
        estadoCod: 17,
        cod: 1636,
      },
      {
        nome: "Timbaúba",
        uf: "PE",
        estadoCod: 17,
        cod: 1637,
      },
      {
        nome: "Toritama",
        uf: "PE",
        estadoCod: 17,
        cod: 1638,
      },
      {
        nome: "Tracunhaém",
        uf: "PE",
        estadoCod: 17,
        cod: 1639,
      },
      {
        nome: "Trindade",
        uf: "PE",
        estadoCod: 17,
        cod: 1640,
      },
      {
        nome: "Triunfo",
        uf: "PE",
        estadoCod: 17,
        cod: 1641,
      },
      {
        nome: "Tupanatinga",
        uf: "PE",
        estadoCod: 17,
        cod: 1642,
      },
      {
        nome: "Tuparetama",
        uf: "PE",
        estadoCod: 17,
        cod: 1643,
      },
      {
        nome: "Venturosa",
        uf: "PE",
        estadoCod: 17,
        cod: 1644,
      },
      {
        nome: "Verdejante",
        uf: "PE",
        estadoCod: 17,
        cod: 1645,
      },
      {
        nome: "Vertente do Lério",
        uf: "PE",
        estadoCod: 17,
        cod: 1646,
      },
      {
        nome: "Vertentes",
        uf: "PE",
        estadoCod: 17,
        cod: 1647,
      },
      {
        nome: "Vicência",
        uf: "PE",
        estadoCod: 17,
        cod: 1648,
      },
      {
        nome: "Vitória de Santo Antão",
        uf: "PE",
        estadoCod: 17,
        cod: 1649,
      },
      {
        nome: "Xexéu",
        uf: "PE",
        estadoCod: 17,
        cod: 1650,
      },
    ],
  },
  {
    nome: "Alagoas",
    uf: "AL",
    regiaoCod: 2,
    cod: 2,
    cidades: [
      {
        nome: "Água Branca",
        uf: "AL",
        estadoCod: 2,
        cod: 1651,
      },
      {
        nome: "Anadia",
        uf: "AL",
        estadoCod: 2,
        cod: 1652,
      },
      {
        nome: "Arapiraca",
        uf: "AL",
        estadoCod: 2,
        cod: 1653,
      },
      {
        nome: "Atalaia",
        uf: "AL",
        estadoCod: 2,
        cod: 1654,
      },
      {
        nome: "Barra de Santo Antônio",
        uf: "AL",
        estadoCod: 2,
        cod: 1655,
      },
      {
        nome: "Barra de São Miguel",
        uf: "AL",
        estadoCod: 2,
        cod: 1656,
      },
      {
        nome: "Batalha",
        uf: "AL",
        estadoCod: 2,
        cod: 1657,
      },
      {
        nome: "Belém",
        uf: "AL",
        estadoCod: 2,
        cod: 1658,
      },
      {
        nome: "Belo Monte",
        uf: "AL",
        estadoCod: 2,
        cod: 1659,
      },
      {
        nome: "Boca da Mata",
        uf: "AL",
        estadoCod: 2,
        cod: 1660,
      },
      {
        nome: "Branquinha",
        uf: "AL",
        estadoCod: 2,
        cod: 1661,
      },
      {
        nome: "Cacimbinhas",
        uf: "AL",
        estadoCod: 2,
        cod: 1662,
      },
      {
        nome: "Cajueiro",
        uf: "AL",
        estadoCod: 2,
        cod: 1663,
      },
      {
        nome: "Campestre",
        uf: "AL",
        estadoCod: 2,
        cod: 1664,
      },
      {
        nome: "Campo Alegre",
        uf: "AL",
        estadoCod: 2,
        cod: 1665,
      },
      {
        nome: "Campo Grande",
        uf: "AL",
        estadoCod: 2,
        cod: 1666,
      },
      {
        nome: "Canapi",
        uf: "AL",
        estadoCod: 2,
        cod: 1667,
      },
      {
        nome: "Capela",
        uf: "AL",
        estadoCod: 2,
        cod: 1668,
      },
      {
        nome: "Carneiros",
        uf: "AL",
        estadoCod: 2,
        cod: 1669,
      },
      {
        nome: "Chã Preta",
        uf: "AL",
        estadoCod: 2,
        cod: 1670,
      },
      {
        nome: "Coité do Nóia",
        uf: "AL",
        estadoCod: 2,
        cod: 1671,
      },
      {
        nome: "Colônia Leopoldina",
        uf: "AL",
        estadoCod: 2,
        cod: 1672,
      },
      {
        nome: "Coqueiro Seco",
        uf: "AL",
        estadoCod: 2,
        cod: 1673,
      },
      {
        nome: "Coruripe",
        uf: "AL",
        estadoCod: 2,
        cod: 1674,
      },
      {
        nome: "Craíbas",
        uf: "AL",
        estadoCod: 2,
        cod: 1675,
      },
      {
        nome: "Delmiro Gouveia",
        uf: "AL",
        estadoCod: 2,
        cod: 1676,
      },
      {
        nome: "Dois Riachos",
        uf: "AL",
        estadoCod: 2,
        cod: 1677,
      },
      {
        nome: "Estrela de Alagoas",
        uf: "AL",
        estadoCod: 2,
        cod: 1678,
      },
      {
        nome: "Feira Grande",
        uf: "AL",
        estadoCod: 2,
        cod: 1679,
      },
      {
        nome: "Feliz Deserto",
        uf: "AL",
        estadoCod: 2,
        cod: 1680,
      },
      {
        nome: "Flexeiras",
        uf: "AL",
        estadoCod: 2,
        cod: 1681,
      },
      {
        nome: "Girau do Ponciano",
        uf: "AL",
        estadoCod: 2,
        cod: 1682,
      },
      {
        nome: "Ibateguara",
        uf: "AL",
        estadoCod: 2,
        cod: 1683,
      },
      {
        nome: "Igaci",
        uf: "AL",
        estadoCod: 2,
        cod: 1684,
      },
      {
        nome: "Igreja Nova",
        uf: "AL",
        estadoCod: 2,
        cod: 1685,
      },
      {
        nome: "Inhapi",
        uf: "AL",
        estadoCod: 2,
        cod: 1686,
      },
      {
        nome: "Jacaré dos Homens",
        uf: "AL",
        estadoCod: 2,
        cod: 1687,
      },
      {
        nome: "Jacuípe",
        uf: "AL",
        estadoCod: 2,
        cod: 1688,
      },
      {
        nome: "Japaratinga",
        uf: "AL",
        estadoCod: 2,
        cod: 1689,
      },
      {
        nome: "Jaramataia",
        uf: "AL",
        estadoCod: 2,
        cod: 1690,
      },
      {
        nome: "Jequiá da Praia",
        uf: "AL",
        estadoCod: 2,
        cod: 1691,
      },
      {
        nome: "Joaquim Gomes",
        uf: "AL",
        estadoCod: 2,
        cod: 1692,
      },
      {
        nome: "Jundiá",
        uf: "AL",
        estadoCod: 2,
        cod: 1693,
      },
      {
        nome: "Junqueiro",
        uf: "AL",
        estadoCod: 2,
        cod: 1694,
      },
      {
        nome: "Lagoa da Canoa",
        uf: "AL",
        estadoCod: 2,
        cod: 1695,
      },
      {
        nome: "Limoeiro de Anadia",
        uf: "AL",
        estadoCod: 2,
        cod: 1696,
      },
      {
        nome: "Maceió",
        uf: "AL",
        estadoCod: 2,
        cod: 1697,
      },
      {
        nome: "Major Isidoro",
        uf: "AL",
        estadoCod: 2,
        cod: 1698,
      },
      {
        nome: "Maragogi",
        uf: "AL",
        estadoCod: 2,
        cod: 1699,
      },
      {
        nome: "Maravilha",
        uf: "AL",
        estadoCod: 2,
        cod: 1700,
      },
      {
        nome: "Marechal Deodoro",
        uf: "AL",
        estadoCod: 2,
        cod: 1701,
      },
      {
        nome: "Maribondo",
        uf: "AL",
        estadoCod: 2,
        cod: 1702,
      },
      {
        nome: "Mar Vermelho",
        uf: "AL",
        estadoCod: 2,
        cod: 1703,
      },
      {
        nome: "Mata Grande",
        uf: "AL",
        estadoCod: 2,
        cod: 1704,
      },
      {
        nome: "Matriz de Camaragibe",
        uf: "AL",
        estadoCod: 2,
        cod: 1705,
      },
      {
        nome: "Messias",
        uf: "AL",
        estadoCod: 2,
        cod: 1706,
      },
      {
        nome: "Minador do Negrão",
        uf: "AL",
        estadoCod: 2,
        cod: 1707,
      },
      {
        nome: "Monteirópolis",
        uf: "AL",
        estadoCod: 2,
        cod: 1708,
      },
      {
        nome: "Murici",
        uf: "AL",
        estadoCod: 2,
        cod: 1709,
      },
      {
        nome: "Novo Lino",
        uf: "AL",
        estadoCod: 2,
        cod: 1710,
      },
      {
        nome: "Olho D'Água das Flores",
        uf: "AL",
        estadoCod: 2,
        cod: 1711,
      },
      {
        nome: "Olho D'Água do Casado",
        uf: "AL",
        estadoCod: 2,
        cod: 1712,
      },
      {
        nome: "Olho D'Água Grande",
        uf: "AL",
        estadoCod: 2,
        cod: 1713,
      },
      {
        nome: "Olivença",
        uf: "AL",
        estadoCod: 2,
        cod: 1714,
      },
      {
        nome: "Ouro Branco",
        uf: "AL",
        estadoCod: 2,
        cod: 1715,
      },
      {
        nome: "Palestina",
        uf: "AL",
        estadoCod: 2,
        cod: 1716,
      },
      {
        nome: "Palmeira dos Índios",
        uf: "AL",
        estadoCod: 2,
        cod: 1717,
      },
      {
        nome: "Pão de Açúcar",
        uf: "AL",
        estadoCod: 2,
        cod: 1718,
      },
      {
        nome: "Pariconha",
        uf: "AL",
        estadoCod: 2,
        cod: 1719,
      },
      {
        nome: "Paripueira",
        uf: "AL",
        estadoCod: 2,
        cod: 1720,
      },
      {
        nome: "Passo de Camaragibe",
        uf: "AL",
        estadoCod: 2,
        cod: 1721,
      },
      {
        nome: "Paulo Jacinto",
        uf: "AL",
        estadoCod: 2,
        cod: 1722,
      },
      {
        nome: "Penedo",
        uf: "AL",
        estadoCod: 2,
        cod: 1723,
      },
      {
        nome: "Piaçabuçu",
        uf: "AL",
        estadoCod: 2,
        cod: 1724,
      },
      {
        nome: "Pilar",
        uf: "AL",
        estadoCod: 2,
        cod: 1725,
      },
      {
        nome: "Pindoba",
        uf: "AL",
        estadoCod: 2,
        cod: 1726,
      },
      {
        nome: "Piranhas",
        uf: "AL",
        estadoCod: 2,
        cod: 1727,
      },
      {
        nome: "Poço das Trincheiras",
        uf: "AL",
        estadoCod: 2,
        cod: 1728,
      },
      {
        nome: "Porto Calvo",
        uf: "AL",
        estadoCod: 2,
        cod: 1729,
      },
      {
        nome: "Porto de Pedras",
        uf: "AL",
        estadoCod: 2,
        cod: 1730,
      },
      {
        nome: "Porto Real do Colégio",
        uf: "AL",
        estadoCod: 2,
        cod: 1731,
      },
      {
        nome: "Quebrangulo",
        uf: "AL",
        estadoCod: 2,
        cod: 1732,
      },
      {
        nome: "Rio Largo",
        uf: "AL",
        estadoCod: 2,
        cod: 1733,
      },
      {
        nome: "Roteiro",
        uf: "AL",
        estadoCod: 2,
        cod: 1734,
      },
      {
        nome: "Santa Luzia do Norte",
        uf: "AL",
        estadoCod: 2,
        cod: 1735,
      },
      {
        nome: "Santana do Ipanema",
        uf: "AL",
        estadoCod: 2,
        cod: 1736,
      },
      {
        nome: "Santana do Mundaú",
        uf: "AL",
        estadoCod: 2,
        cod: 1737,
      },
      {
        nome: "São Brás",
        uf: "AL",
        estadoCod: 2,
        cod: 1738,
      },
      {
        nome: "São José da Laje",
        uf: "AL",
        estadoCod: 2,
        cod: 1739,
      },
      {
        nome: "São José da Tapera",
        uf: "AL",
        estadoCod: 2,
        cod: 1740,
      },
      {
        nome: "São Luís do Quitunde",
        uf: "AL",
        estadoCod: 2,
        cod: 1741,
      },
      {
        nome: "São Miguel dos Campos",
        uf: "AL",
        estadoCod: 2,
        cod: 1742,
      },
      {
        nome: "São Miguel dos Milagres",
        uf: "AL",
        estadoCod: 2,
        cod: 1743,
      },
      {
        nome: "São Sebastião",
        uf: "AL",
        estadoCod: 2,
        cod: 1744,
      },
      {
        nome: "Satuba",
        uf: "AL",
        estadoCod: 2,
        cod: 1745,
      },
      {
        nome: "Senador Rui Palmeira",
        uf: "AL",
        estadoCod: 2,
        cod: 1746,
      },
      {
        nome: "Tanque D'Arca",
        uf: "AL",
        estadoCod: 2,
        cod: 1747,
      },
      {
        nome: "Taquarana",
        uf: "AL",
        estadoCod: 2,
        cod: 1748,
      },
      {
        nome: "Teotônio Vilela",
        uf: "AL",
        estadoCod: 2,
        cod: 1749,
      },
      {
        nome: "Traipu",
        uf: "AL",
        estadoCod: 2,
        cod: 1750,
      },
      {
        nome: "União dos Palmares",
        uf: "AL",
        estadoCod: 2,
        cod: 1751,
      },
      {
        nome: "Viçosa",
        uf: "AL",
        estadoCod: 2,
        cod: 1752,
      },
    ],
  },
  {
    nome: "Sergipe",
    uf: "SE",
    regiaoCod: 2,
    cod: 26,
    cidades: [
      {
        nome: "Amparo de São Francisco",
        uf: "SE",
        estadoCod: 26,
        cod: 1753,
      },
      {
        nome: "Aquidabã",
        uf: "SE",
        estadoCod: 26,
        cod: 1754,
      },
      {
        nome: "Aracaju",
        uf: "SE",
        estadoCod: 26,
        cod: 1755,
      },
      {
        nome: "Arauá",
        uf: "SE",
        estadoCod: 26,
        cod: 1756,
      },
      {
        nome: "Areia Branca",
        uf: "SE",
        estadoCod: 26,
        cod: 1757,
      },
      {
        nome: "Barra dos Coqueiros",
        uf: "SE",
        estadoCod: 26,
        cod: 1758,
      },
      {
        nome: "Boquim",
        uf: "SE",
        estadoCod: 26,
        cod: 1759,
      },
      {
        nome: "Brejo Grande",
        uf: "SE",
        estadoCod: 26,
        cod: 1760,
      },
      {
        nome: "Campo do Brito",
        uf: "SE",
        estadoCod: 26,
        cod: 1761,
      },
      {
        nome: "Canhoba",
        uf: "SE",
        estadoCod: 26,
        cod: 1762,
      },
      {
        nome: "Canindé de São Francisco",
        uf: "SE",
        estadoCod: 26,
        cod: 1763,
      },
      {
        nome: "Capela",
        uf: "SE",
        estadoCod: 26,
        cod: 1764,
      },
      {
        nome: "Carira",
        uf: "SE",
        estadoCod: 26,
        cod: 1765,
      },
      {
        nome: "Carmópolis",
        uf: "SE",
        estadoCod: 26,
        cod: 1766,
      },
      {
        nome: "Cedro de São João",
        uf: "SE",
        estadoCod: 26,
        cod: 1767,
      },
      {
        nome: "Cristinápolis",
        uf: "SE",
        estadoCod: 26,
        cod: 1768,
      },
      {
        nome: "Cumbe",
        uf: "SE",
        estadoCod: 26,
        cod: 1769,
      },
      {
        nome: "Divina Pastora",
        uf: "SE",
        estadoCod: 26,
        cod: 1770,
      },
      {
        nome: "Estância",
        uf: "SE",
        estadoCod: 26,
        cod: 1771,
      },
      {
        nome: "Feira Nova",
        uf: "SE",
        estadoCod: 26,
        cod: 1772,
      },
      {
        nome: "Frei Paulo",
        uf: "SE",
        estadoCod: 26,
        cod: 1773,
      },
      {
        nome: "Gararu",
        uf: "SE",
        estadoCod: 26,
        cod: 1774,
      },
      {
        nome: "General Maynard",
        uf: "SE",
        estadoCod: 26,
        cod: 1775,
      },
      {
        nome: "Gracho Cardoso",
        uf: "SE",
        estadoCod: 26,
        cod: 1776,
      },
      {
        nome: "Ilha das Flores",
        uf: "SE",
        estadoCod: 26,
        cod: 1777,
      },
      {
        nome: "Indiaroba",
        uf: "SE",
        estadoCod: 26,
        cod: 1778,
      },
      {
        nome: "Itabaiana",
        uf: "SE",
        estadoCod: 26,
        cod: 1779,
      },
      {
        nome: "Itabaianinha",
        uf: "SE",
        estadoCod: 26,
        cod: 1780,
      },
      {
        nome: "Itabi",
        uf: "SE",
        estadoCod: 26,
        cod: 1781,
      },
      {
        nome: "Itaporanga D'Ajuda",
        uf: "SE",
        estadoCod: 26,
        cod: 1782,
      },
      {
        nome: "Japaratuba",
        uf: "SE",
        estadoCod: 26,
        cod: 1783,
      },
      {
        nome: "Japoatã",
        uf: "SE",
        estadoCod: 26,
        cod: 1784,
      },
      {
        nome: "Lagarto",
        uf: "SE",
        estadoCod: 26,
        cod: 1785,
      },
      {
        nome: "Laranjeiras",
        uf: "SE",
        estadoCod: 26,
        cod: 1786,
      },
      {
        nome: "Macambira",
        uf: "SE",
        estadoCod: 26,
        cod: 1787,
      },
      {
        nome: "Malhada dos Bois",
        uf: "SE",
        estadoCod: 26,
        cod: 1788,
      },
      {
        nome: "Malhador",
        uf: "SE",
        estadoCod: 26,
        cod: 1789,
      },
      {
        nome: "Maruim",
        uf: "SE",
        estadoCod: 26,
        cod: 1790,
      },
      {
        nome: "Moita Bonita",
        uf: "SE",
        estadoCod: 26,
        cod: 1791,
      },
      {
        nome: "Monte Alegre de Sergipe",
        uf: "SE",
        estadoCod: 26,
        cod: 1792,
      },
      {
        nome: "Muribeca",
        uf: "SE",
        estadoCod: 26,
        cod: 1793,
      },
      {
        nome: "Neópolis",
        uf: "SE",
        estadoCod: 26,
        cod: 1794,
      },
      {
        nome: "Nossa Senhora Aparecida",
        uf: "SE",
        estadoCod: 26,
        cod: 1795,
      },
      {
        nome: "Nossa Senhora da Glória",
        uf: "SE",
        estadoCod: 26,
        cod: 1796,
      },
      {
        nome: "Nossa Senhora das Dores",
        uf: "SE",
        estadoCod: 26,
        cod: 1797,
      },
      {
        nome: "Nossa Senhora de Lourdes",
        uf: "SE",
        estadoCod: 26,
        cod: 1798,
      },
      {
        nome: "Nossa Senhora do Socorro",
        uf: "SE",
        estadoCod: 26,
        cod: 1799,
      },
      {
        nome: "Pacatuba",
        uf: "SE",
        estadoCod: 26,
        cod: 1800,
      },
      {
        nome: "Pedra Mole",
        uf: "SE",
        estadoCod: 26,
        cod: 1801,
      },
      {
        nome: "Pedrinhas",
        uf: "SE",
        estadoCod: 26,
        cod: 1802,
      },
      {
        nome: "Pinhão",
        uf: "SE",
        estadoCod: 26,
        cod: 1803,
      },
      {
        nome: "Pirambu",
        uf: "SE",
        estadoCod: 26,
        cod: 1804,
      },
      {
        nome: "Poço Redondo",
        uf: "SE",
        estadoCod: 26,
        cod: 1805,
      },
      {
        nome: "Poço Verde",
        uf: "SE",
        estadoCod: 26,
        cod: 1806,
      },
      {
        nome: "Porto da Folha",
        uf: "SE",
        estadoCod: 26,
        cod: 1807,
      },
      {
        nome: "Propriá",
        uf: "SE",
        estadoCod: 26,
        cod: 1808,
      },
      {
        nome: "Riachão do Dantas",
        uf: "SE",
        estadoCod: 26,
        cod: 1809,
      },
      {
        nome: "Riachuelo",
        uf: "SE",
        estadoCod: 26,
        cod: 1810,
      },
      {
        nome: "Ribeirópolis",
        uf: "SE",
        estadoCod: 26,
        cod: 1811,
      },
      {
        nome: "Rosário do Catete",
        uf: "SE",
        estadoCod: 26,
        cod: 1812,
      },
      {
        nome: "Salgado",
        uf: "SE",
        estadoCod: 26,
        cod: 1813,
      },
      {
        nome: "Santa Luzia do Itanhy",
        uf: "SE",
        estadoCod: 26,
        cod: 1814,
      },
      {
        nome: "Santana do São Francisco",
        uf: "SE",
        estadoCod: 26,
        cod: 1815,
      },
      {
        nome: "Santa Rosa de Lima",
        uf: "SE",
        estadoCod: 26,
        cod: 1816,
      },
      {
        nome: "Santo Amaro das Brotas",
        uf: "SE",
        estadoCod: 26,
        cod: 1817,
      },
      {
        nome: "São Cristóvão",
        uf: "SE",
        estadoCod: 26,
        cod: 1818,
      },
      {
        nome: "São Domingos",
        uf: "SE",
        estadoCod: 26,
        cod: 1819,
      },
      {
        nome: "São Francisco",
        uf: "SE",
        estadoCod: 26,
        cod: 1820,
      },
      {
        nome: "São Miguel do Aleixo",
        uf: "SE",
        estadoCod: 26,
        cod: 1821,
      },
      {
        nome: "Simão Dias",
        uf: "SE",
        estadoCod: 26,
        cod: 1822,
      },
      {
        nome: "Siriri",
        uf: "SE",
        estadoCod: 26,
        cod: 1823,
      },
      {
        nome: "Telha",
        uf: "SE",
        estadoCod: 26,
        cod: 1824,
      },
      {
        nome: "Tobias Barreto",
        uf: "SE",
        estadoCod: 26,
        cod: 1825,
      },
      {
        nome: "Tomar do Geru",
        uf: "SE",
        estadoCod: 26,
        cod: 1826,
      },
      {
        nome: "Umbaúba",
        uf: "SE",
        estadoCod: 26,
        cod: 1827,
      },
    ],
  },
  {
    nome: "Bahia",
    uf: "BA",
    regiaoCod: 2,
    cod: 5,
    cidades: [
      {
        nome: "Abaíra",
        uf: "BA",
        estadoCod: 5,
        cod: 1828,
      },
      {
        nome: "Abaré",
        uf: "BA",
        estadoCod: 5,
        cod: 1829,
      },
      {
        nome: "Acajutiba",
        uf: "BA",
        estadoCod: 5,
        cod: 1830,
      },
      {
        nome: "Adustina",
        uf: "BA",
        estadoCod: 5,
        cod: 1831,
      },
      {
        nome: "Água Fria",
        uf: "BA",
        estadoCod: 5,
        cod: 1832,
      },
      {
        nome: "Érico Cardoso",
        uf: "BA",
        estadoCod: 5,
        cod: 1833,
      },
      {
        nome: "Aiquara",
        uf: "BA",
        estadoCod: 5,
        cod: 1834,
      },
      {
        nome: "Alagoinhas",
        uf: "BA",
        estadoCod: 5,
        cod: 1835,
      },
      {
        nome: "Alcobaça",
        uf: "BA",
        estadoCod: 5,
        cod: 1836,
      },
      {
        nome: "Almadina",
        uf: "BA",
        estadoCod: 5,
        cod: 1837,
      },
      {
        nome: "Amargosa",
        uf: "BA",
        estadoCod: 5,
        cod: 1838,
      },
      {
        nome: "Amélia Rodrigues",
        uf: "BA",
        estadoCod: 5,
        cod: 1839,
      },
      {
        nome: "América Dourada",
        uf: "BA",
        estadoCod: 5,
        cod: 1840,
      },
      {
        nome: "Anagé",
        uf: "BA",
        estadoCod: 5,
        cod: 1841,
      },
      {
        nome: "Andaraí",
        uf: "BA",
        estadoCod: 5,
        cod: 1842,
      },
      {
        nome: "Andorinha",
        uf: "BA",
        estadoCod: 5,
        cod: 1843,
      },
      {
        nome: "Angical",
        uf: "BA",
        estadoCod: 5,
        cod: 1844,
      },
      {
        nome: "Anguera",
        uf: "BA",
        estadoCod: 5,
        cod: 1845,
      },
      {
        nome: "Antas",
        uf: "BA",
        estadoCod: 5,
        cod: 1846,
      },
      {
        nome: "Antônio Cardoso",
        uf: "BA",
        estadoCod: 5,
        cod: 1847,
      },
      {
        nome: "Antônio Gonçalves",
        uf: "BA",
        estadoCod: 5,
        cod: 1848,
      },
      {
        nome: "Aporá",
        uf: "BA",
        estadoCod: 5,
        cod: 1849,
      },
      {
        nome: "Apuarema",
        uf: "BA",
        estadoCod: 5,
        cod: 1850,
      },
      {
        nome: "Aracatu",
        uf: "BA",
        estadoCod: 5,
        cod: 1851,
      },
      {
        nome: "Araças",
        uf: "BA",
        estadoCod: 5,
        cod: 1852,
      },
      {
        nome: "Araci",
        uf: "BA",
        estadoCod: 5,
        cod: 1853,
      },
      {
        nome: "Aramari",
        uf: "BA",
        estadoCod: 5,
        cod: 1854,
      },
      {
        nome: "Arataca",
        uf: "BA",
        estadoCod: 5,
        cod: 1855,
      },
      {
        nome: "Aratuípe",
        uf: "BA",
        estadoCod: 5,
        cod: 1856,
      },
      {
        nome: "Aurelino Leal",
        uf: "BA",
        estadoCod: 5,
        cod: 1857,
      },
      {
        nome: "Baianópolis",
        uf: "BA",
        estadoCod: 5,
        cod: 1858,
      },
      {
        nome: "Baixa Grande",
        uf: "BA",
        estadoCod: 5,
        cod: 1859,
      },
      {
        nome: "Banzaê",
        uf: "BA",
        estadoCod: 5,
        cod: 1860,
      },
      {
        nome: "Barra",
        uf: "BA",
        estadoCod: 5,
        cod: 1861,
      },
      {
        nome: "Barra da Estiva",
        uf: "BA",
        estadoCod: 5,
        cod: 1862,
      },
      {
        nome: "Barra do Choça",
        uf: "BA",
        estadoCod: 5,
        cod: 1863,
      },
      {
        nome: "Barra do Mendes",
        uf: "BA",
        estadoCod: 5,
        cod: 1864,
      },
      {
        nome: "Barra do Rocha",
        uf: "BA",
        estadoCod: 5,
        cod: 1865,
      },
      {
        nome: "Barreiras",
        uf: "BA",
        estadoCod: 5,
        cod: 1866,
      },
      {
        nome: "Barro Alto",
        uf: "BA",
        estadoCod: 5,
        cod: 1867,
      },
      {
        nome: "Barrocas",
        uf: "BA",
        estadoCod: 5,
        cod: 1868,
      },
      {
        nome: "Barro Preto",
        uf: "BA",
        estadoCod: 5,
        cod: 1869,
      },
      {
        nome: "Belmonte",
        uf: "BA",
        estadoCod: 5,
        cod: 1870,
      },
      {
        nome: "Belo Campo",
        uf: "BA",
        estadoCod: 5,
        cod: 1871,
      },
      {
        nome: "Biritinga",
        uf: "BA",
        estadoCod: 5,
        cod: 1872,
      },
      {
        nome: "Boa Nova",
        uf: "BA",
        estadoCod: 5,
        cod: 1873,
      },
      {
        nome: "Boa Vista do Tupim",
        uf: "BA",
        estadoCod: 5,
        cod: 1874,
      },
      {
        nome: "Bom Jesus da Lapa",
        uf: "BA",
        estadoCod: 5,
        cod: 1875,
      },
      {
        nome: "Bom Jesus da Serra",
        uf: "BA",
        estadoCod: 5,
        cod: 1876,
      },
      {
        nome: "Boninal",
        uf: "BA",
        estadoCod: 5,
        cod: 1877,
      },
      {
        nome: "Bonito",
        uf: "BA",
        estadoCod: 5,
        cod: 1878,
      },
      {
        nome: "Boquira",
        uf: "BA",
        estadoCod: 5,
        cod: 1879,
      },
      {
        nome: "Botuporã",
        uf: "BA",
        estadoCod: 5,
        cod: 1880,
      },
      {
        nome: "Brejões",
        uf: "BA",
        estadoCod: 5,
        cod: 1881,
      },
      {
        nome: "Brejolândia",
        uf: "BA",
        estadoCod: 5,
        cod: 1882,
      },
      {
        nome: "Brotas de Macaúbas",
        uf: "BA",
        estadoCod: 5,
        cod: 1883,
      },
      {
        nome: "Brumado",
        uf: "BA",
        estadoCod: 5,
        cod: 1884,
      },
      {
        nome: "Buerarema",
        uf: "BA",
        estadoCod: 5,
        cod: 1885,
      },
      {
        nome: "Buritirama",
        uf: "BA",
        estadoCod: 5,
        cod: 1886,
      },
      {
        nome: "Caatiba",
        uf: "BA",
        estadoCod: 5,
        cod: 1887,
      },
      {
        nome: "Cabaceiras do Paraguaçu",
        uf: "BA",
        estadoCod: 5,
        cod: 1888,
      },
      {
        nome: "Cachoeira",
        uf: "BA",
        estadoCod: 5,
        cod: 1889,
      },
      {
        nome: "Caculé",
        uf: "BA",
        estadoCod: 5,
        cod: 1890,
      },
      {
        nome: "Caém",
        uf: "BA",
        estadoCod: 5,
        cod: 1891,
      },
      {
        nome: "Caetanos",
        uf: "BA",
        estadoCod: 5,
        cod: 1892,
      },
      {
        nome: "Caetité",
        uf: "BA",
        estadoCod: 5,
        cod: 1893,
      },
      {
        nome: "Cafarnaum",
        uf: "BA",
        estadoCod: 5,
        cod: 1894,
      },
      {
        nome: "Cairu",
        uf: "BA",
        estadoCod: 5,
        cod: 1895,
      },
      {
        nome: "Caldeirão Grande",
        uf: "BA",
        estadoCod: 5,
        cod: 1896,
      },
      {
        nome: "Camacan",
        uf: "BA",
        estadoCod: 5,
        cod: 1897,
      },
      {
        nome: "Camaçari",
        uf: "BA",
        estadoCod: 5,
        cod: 1898,
      },
      {
        nome: "Camamu",
        uf: "BA",
        estadoCod: 5,
        cod: 1899,
      },
      {
        nome: "Campo Alegre de Lourdes",
        uf: "BA",
        estadoCod: 5,
        cod: 1900,
      },
      {
        nome: "Campo Formoso",
        uf: "BA",
        estadoCod: 5,
        cod: 1901,
      },
      {
        nome: "Canápolis",
        uf: "BA",
        estadoCod: 5,
        cod: 1902,
      },
      {
        nome: "Canarana",
        uf: "BA",
        estadoCod: 5,
        cod: 1903,
      },
      {
        nome: "Canavieiras",
        uf: "BA",
        estadoCod: 5,
        cod: 1904,
      },
      {
        nome: "Candeal",
        uf: "BA",
        estadoCod: 5,
        cod: 1905,
      },
      {
        nome: "Candeias",
        uf: "BA",
        estadoCod: 5,
        cod: 1906,
      },
      {
        nome: "Candiba",
        uf: "BA",
        estadoCod: 5,
        cod: 1907,
      },
      {
        nome: "Cândido Sales",
        uf: "BA",
        estadoCod: 5,
        cod: 1908,
      },
      {
        nome: "Cansanção",
        uf: "BA",
        estadoCod: 5,
        cod: 1909,
      },
      {
        nome: "Canudos",
        uf: "BA",
        estadoCod: 5,
        cod: 1910,
      },
      {
        nome: "Capela do Alto Alegre",
        uf: "BA",
        estadoCod: 5,
        cod: 1911,
      },
      {
        nome: "Capim Grosso",
        uf: "BA",
        estadoCod: 5,
        cod: 1912,
      },
      {
        nome: "Caraíbas",
        uf: "BA",
        estadoCod: 5,
        cod: 1913,
      },
      {
        nome: "Caravelas",
        uf: "BA",
        estadoCod: 5,
        cod: 1914,
      },
      {
        nome: "Cardeal da Silva",
        uf: "BA",
        estadoCod: 5,
        cod: 1915,
      },
      {
        nome: "Carinhanha",
        uf: "BA",
        estadoCod: 5,
        cod: 1916,
      },
      {
        nome: "Casa Nova",
        uf: "BA",
        estadoCod: 5,
        cod: 1917,
      },
      {
        nome: "Castro Alves",
        uf: "BA",
        estadoCod: 5,
        cod: 1918,
      },
      {
        nome: "Catolândia",
        uf: "BA",
        estadoCod: 5,
        cod: 1919,
      },
      {
        nome: "Catu",
        uf: "BA",
        estadoCod: 5,
        cod: 1920,
      },
      {
        nome: "Caturama",
        uf: "BA",
        estadoCod: 5,
        cod: 1921,
      },
      {
        nome: "Central",
        uf: "BA",
        estadoCod: 5,
        cod: 1922,
      },
      {
        nome: "Chorrochó",
        uf: "BA",
        estadoCod: 5,
        cod: 1923,
      },
      {
        nome: "Cícero Dantas",
        uf: "BA",
        estadoCod: 5,
        cod: 1924,
      },
      {
        nome: "Cipó",
        uf: "BA",
        estadoCod: 5,
        cod: 1925,
      },
      {
        nome: "Coaraci",
        uf: "BA",
        estadoCod: 5,
        cod: 1926,
      },
      {
        nome: "Cocos",
        uf: "BA",
        estadoCod: 5,
        cod: 1927,
      },
      {
        nome: "Conceição da Feira",
        uf: "BA",
        estadoCod: 5,
        cod: 1928,
      },
      {
        nome: "Conceição do Almeida",
        uf: "BA",
        estadoCod: 5,
        cod: 1929,
      },
      {
        nome: "Conceição do Coité",
        uf: "BA",
        estadoCod: 5,
        cod: 1930,
      },
      {
        nome: "Conceição do Jacuípe",
        uf: "BA",
        estadoCod: 5,
        cod: 1931,
      },
      {
        nome: "Conde",
        uf: "BA",
        estadoCod: 5,
        cod: 1932,
      },
      {
        nome: "Condeúba",
        uf: "BA",
        estadoCod: 5,
        cod: 1933,
      },
      {
        nome: "Contendas do Sincorá",
        uf: "BA",
        estadoCod: 5,
        cod: 1934,
      },
      {
        nome: "Coração de Maria",
        uf: "BA",
        estadoCod: 5,
        cod: 1935,
      },
      {
        nome: "Cordeiros",
        uf: "BA",
        estadoCod: 5,
        cod: 1936,
      },
      {
        nome: "Coribe",
        uf: "BA",
        estadoCod: 5,
        cod: 1937,
      },
      {
        nome: "Coronel João Sá",
        uf: "BA",
        estadoCod: 5,
        cod: 1938,
      },
      {
        nome: "Correntina",
        uf: "BA",
        estadoCod: 5,
        cod: 1939,
      },
      {
        nome: "Cotegipe",
        uf: "BA",
        estadoCod: 5,
        cod: 1940,
      },
      {
        nome: "Cravolândia",
        uf: "BA",
        estadoCod: 5,
        cod: 1941,
      },
      {
        nome: "Crisópolis",
        uf: "BA",
        estadoCod: 5,
        cod: 1942,
      },
      {
        nome: "Cristópolis",
        uf: "BA",
        estadoCod: 5,
        cod: 1943,
      },
      {
        nome: "Cruz das Almas",
        uf: "BA",
        estadoCod: 5,
        cod: 1944,
      },
      {
        nome: "Curaçá",
        uf: "BA",
        estadoCod: 5,
        cod: 1945,
      },
      {
        nome: "Dário Meira",
        uf: "BA",
        estadoCod: 5,
        cod: 1946,
      },
      {
        nome: "Dias D'Ávila",
        uf: "BA",
        estadoCod: 5,
        cod: 1947,
      },
      {
        nome: "Dom Basílio",
        uf: "BA",
        estadoCod: 5,
        cod: 1948,
      },
      {
        nome: "Dom Macedo Costa",
        uf: "BA",
        estadoCod: 5,
        cod: 1949,
      },
      {
        nome: "Elísio Medrado",
        uf: "BA",
        estadoCod: 5,
        cod: 1950,
      },
      {
        nome: "Encruzilhada",
        uf: "BA",
        estadoCod: 5,
        cod: 1951,
      },
      {
        nome: "Entre Rios",
        uf: "BA",
        estadoCod: 5,
        cod: 1952,
      },
      {
        nome: "Esplanada",
        uf: "BA",
        estadoCod: 5,
        cod: 1953,
      },
      {
        nome: "Euclides da Cunha",
        uf: "BA",
        estadoCod: 5,
        cod: 1954,
      },
      {
        nome: "Eunápolis",
        uf: "BA",
        estadoCod: 5,
        cod: 1955,
      },
      {
        nome: "Fátima",
        uf: "BA",
        estadoCod: 5,
        cod: 1956,
      },
      {
        nome: "Feira da Mata",
        uf: "BA",
        estadoCod: 5,
        cod: 1957,
      },
      {
        nome: "Feira de Santana",
        uf: "BA",
        estadoCod: 5,
        cod: 1958,
      },
      {
        nome: "Filadélfia",
        uf: "BA",
        estadoCod: 5,
        cod: 1959,
      },
      {
        nome: "Firmino Alves",
        uf: "BA",
        estadoCod: 5,
        cod: 1960,
      },
      {
        nome: "Floresta Azul",
        uf: "BA",
        estadoCod: 5,
        cod: 1961,
      },
      {
        nome: "Formosa do Rio Preto",
        uf: "BA",
        estadoCod: 5,
        cod: 1962,
      },
      {
        nome: "Gandu",
        uf: "BA",
        estadoCod: 5,
        cod: 1963,
      },
      {
        nome: "Gavião",
        uf: "BA",
        estadoCod: 5,
        cod: 1964,
      },
      {
        nome: "Gentio do Ouro",
        uf: "BA",
        estadoCod: 5,
        cod: 1965,
      },
      {
        nome: "Glória",
        uf: "BA",
        estadoCod: 5,
        cod: 1966,
      },
      {
        nome: "Gongogi",
        uf: "BA",
        estadoCod: 5,
        cod: 1967,
      },
      {
        nome: "Governador Mangabeira",
        uf: "BA",
        estadoCod: 5,
        cod: 1968,
      },
      {
        nome: "Guajeru",
        uf: "BA",
        estadoCod: 5,
        cod: 1969,
      },
      {
        nome: "Guanambi",
        uf: "BA",
        estadoCod: 5,
        cod: 1970,
      },
      {
        nome: "Guaratinga",
        uf: "BA",
        estadoCod: 5,
        cod: 1971,
      },
      {
        nome: "Heliópolis",
        uf: "BA",
        estadoCod: 5,
        cod: 1972,
      },
      {
        nome: "Iaçu",
        uf: "BA",
        estadoCod: 5,
        cod: 1973,
      },
      {
        nome: "Ibiassucê",
        uf: "BA",
        estadoCod: 5,
        cod: 1974,
      },
      {
        nome: "Ibicaraí",
        uf: "BA",
        estadoCod: 5,
        cod: 1975,
      },
      {
        nome: "Ibicoara",
        uf: "BA",
        estadoCod: 5,
        cod: 1976,
      },
      {
        nome: "Ibicuí",
        uf: "BA",
        estadoCod: 5,
        cod: 1977,
      },
      {
        nome: "Ibipeba",
        uf: "BA",
        estadoCod: 5,
        cod: 1978,
      },
      {
        nome: "Ibipitanga",
        uf: "BA",
        estadoCod: 5,
        cod: 1979,
      },
      {
        nome: "Ibiquera",
        uf: "BA",
        estadoCod: 5,
        cod: 1980,
      },
      {
        nome: "Ibirapitanga",
        uf: "BA",
        estadoCod: 5,
        cod: 1981,
      },
      {
        nome: "Ibirapuã",
        uf: "BA",
        estadoCod: 5,
        cod: 1982,
      },
      {
        nome: "Ibirataia",
        uf: "BA",
        estadoCod: 5,
        cod: 1983,
      },
      {
        nome: "Ibitiara",
        uf: "BA",
        estadoCod: 5,
        cod: 1984,
      },
      {
        nome: "Ibititá",
        uf: "BA",
        estadoCod: 5,
        cod: 1985,
      },
      {
        nome: "Ibotirama",
        uf: "BA",
        estadoCod: 5,
        cod: 1986,
      },
      {
        nome: "Ichu",
        uf: "BA",
        estadoCod: 5,
        cod: 1987,
      },
      {
        nome: "Igaporã",
        uf: "BA",
        estadoCod: 5,
        cod: 1988,
      },
      {
        nome: "Igrapiúna",
        uf: "BA",
        estadoCod: 5,
        cod: 1989,
      },
      {
        nome: "Iguaí",
        uf: "BA",
        estadoCod: 5,
        cod: 1990,
      },
      {
        nome: "Ilhéus",
        uf: "BA",
        estadoCod: 5,
        cod: 1991,
      },
      {
        nome: "Inhambupe",
        uf: "BA",
        estadoCod: 5,
        cod: 1992,
      },
      {
        nome: "Ipecaetá",
        uf: "BA",
        estadoCod: 5,
        cod: 1993,
      },
      {
        nome: "Ipiaú",
        uf: "BA",
        estadoCod: 5,
        cod: 1994,
      },
      {
        nome: "Ipirá",
        uf: "BA",
        estadoCod: 5,
        cod: 1995,
      },
      {
        nome: "Ipupiara",
        uf: "BA",
        estadoCod: 5,
        cod: 1996,
      },
      {
        nome: "Irajuba",
        uf: "BA",
        estadoCod: 5,
        cod: 1997,
      },
      {
        nome: "Iramaia",
        uf: "BA",
        estadoCod: 5,
        cod: 1998,
      },
      {
        nome: "Iraquara",
        uf: "BA",
        estadoCod: 5,
        cod: 1999,
      },
      {
        nome: "Irará",
        uf: "BA",
        estadoCod: 5,
        cod: 2000,
      },
      {
        nome: "Irecê",
        uf: "BA",
        estadoCod: 5,
        cod: 2001,
      },
      {
        nome: "Itabela",
        uf: "BA",
        estadoCod: 5,
        cod: 2002,
      },
      {
        nome: "Itaberaba",
        uf: "BA",
        estadoCod: 5,
        cod: 2003,
      },
      {
        nome: "Itabuna",
        uf: "BA",
        estadoCod: 5,
        cod: 2004,
      },
      {
        nome: "Itacaré",
        uf: "BA",
        estadoCod: 5,
        cod: 2005,
      },
      {
        nome: "Itaeté",
        uf: "BA",
        estadoCod: 5,
        cod: 2006,
      },
      {
        nome: "Itagi",
        uf: "BA",
        estadoCod: 5,
        cod: 2007,
      },
      {
        nome: "Itagibá",
        uf: "BA",
        estadoCod: 5,
        cod: 2008,
      },
      {
        nome: "Itagimirim",
        uf: "BA",
        estadoCod: 5,
        cod: 2009,
      },
      {
        nome: "Itaguaçu da Bahia",
        uf: "BA",
        estadoCod: 5,
        cod: 2010,
      },
      {
        nome: "Itaju do Colônia",
        uf: "BA",
        estadoCod: 5,
        cod: 2011,
      },
      {
        nome: "Itajuípe",
        uf: "BA",
        estadoCod: 5,
        cod: 2012,
      },
      {
        nome: "Itamaraju",
        uf: "BA",
        estadoCod: 5,
        cod: 2013,
      },
      {
        nome: "Itamari",
        uf: "BA",
        estadoCod: 5,
        cod: 2014,
      },
      {
        nome: "Itambé",
        uf: "BA",
        estadoCod: 5,
        cod: 2015,
      },
      {
        nome: "Itanagra",
        uf: "BA",
        estadoCod: 5,
        cod: 2016,
      },
      {
        nome: "Itanhém",
        uf: "BA",
        estadoCod: 5,
        cod: 2017,
      },
      {
        nome: "Itaparica",
        uf: "BA",
        estadoCod: 5,
        cod: 2018,
      },
      {
        nome: "Itapé",
        uf: "BA",
        estadoCod: 5,
        cod: 2019,
      },
      {
        nome: "Itapebi",
        uf: "BA",
        estadoCod: 5,
        cod: 2020,
      },
      {
        nome: "Itapetinga",
        uf: "BA",
        estadoCod: 5,
        cod: 2021,
      },
      {
        nome: "Itapicuru",
        uf: "BA",
        estadoCod: 5,
        cod: 2022,
      },
      {
        nome: "Itapitanga",
        uf: "BA",
        estadoCod: 5,
        cod: 2023,
      },
      {
        nome: "Itaquara",
        uf: "BA",
        estadoCod: 5,
        cod: 2024,
      },
      {
        nome: "Itarantim",
        uf: "BA",
        estadoCod: 5,
        cod: 2025,
      },
      {
        nome: "Itatim",
        uf: "BA",
        estadoCod: 5,
        cod: 2026,
      },
      {
        nome: "Itiruçu",
        uf: "BA",
        estadoCod: 5,
        cod: 2027,
      },
      {
        nome: "Itiúba",
        uf: "BA",
        estadoCod: 5,
        cod: 2028,
      },
      {
        nome: "Itororó",
        uf: "BA",
        estadoCod: 5,
        cod: 2029,
      },
      {
        nome: "Ituaçu",
        uf: "BA",
        estadoCod: 5,
        cod: 2030,
      },
      {
        nome: "Ituberá",
        uf: "BA",
        estadoCod: 5,
        cod: 2031,
      },
      {
        nome: "Iuiú",
        uf: "BA",
        estadoCod: 5,
        cod: 2032,
      },
      {
        nome: "Jaborandi",
        uf: "BA",
        estadoCod: 5,
        cod: 2033,
      },
      {
        nome: "Jacaraci",
        uf: "BA",
        estadoCod: 5,
        cod: 2034,
      },
      {
        nome: "Jacobina",
        uf: "BA",
        estadoCod: 5,
        cod: 2035,
      },
      {
        nome: "Jaguaquara",
        uf: "BA",
        estadoCod: 5,
        cod: 2036,
      },
      {
        nome: "Jaguarari",
        uf: "BA",
        estadoCod: 5,
        cod: 2037,
      },
      {
        nome: "Jaguaripe",
        uf: "BA",
        estadoCod: 5,
        cod: 2038,
      },
      {
        nome: "Jandaíra",
        uf: "BA",
        estadoCod: 5,
        cod: 2039,
      },
      {
        nome: "Jequié",
        uf: "BA",
        estadoCod: 5,
        cod: 2040,
      },
      {
        nome: "Jeremoabo",
        uf: "BA",
        estadoCod: 5,
        cod: 2041,
      },
      {
        nome: "Jiquiriçá",
        uf: "BA",
        estadoCod: 5,
        cod: 2042,
      },
      {
        nome: "Jitaúna",
        uf: "BA",
        estadoCod: 5,
        cod: 2043,
      },
      {
        nome: "João Dourado",
        uf: "BA",
        estadoCod: 5,
        cod: 2044,
      },
      {
        nome: "Juazeiro",
        uf: "BA",
        estadoCod: 5,
        cod: 2045,
      },
      {
        nome: "Jucuruçu",
        uf: "BA",
        estadoCod: 5,
        cod: 2046,
      },
      {
        nome: "Jussara",
        uf: "BA",
        estadoCod: 5,
        cod: 2047,
      },
      {
        nome: "Jussari",
        uf: "BA",
        estadoCod: 5,
        cod: 2048,
      },
      {
        nome: "Jussiape",
        uf: "BA",
        estadoCod: 5,
        cod: 2049,
      },
      {
        nome: "Lafaiete Coutinho",
        uf: "BA",
        estadoCod: 5,
        cod: 2050,
      },
      {
        nome: "Lagoa Real",
        uf: "BA",
        estadoCod: 5,
        cod: 2051,
      },
      {
        nome: "Laje",
        uf: "BA",
        estadoCod: 5,
        cod: 2052,
      },
      {
        nome: "Lajedão",
        uf: "BA",
        estadoCod: 5,
        cod: 2053,
      },
      {
        nome: "Lajedinho",
        uf: "BA",
        estadoCod: 5,
        cod: 2054,
      },
      {
        nome: "Lajedo do Tabocal",
        uf: "BA",
        estadoCod: 5,
        cod: 2055,
      },
      {
        nome: "Lamarão",
        uf: "BA",
        estadoCod: 5,
        cod: 2056,
      },
      {
        nome: "Lapão",
        uf: "BA",
        estadoCod: 5,
        cod: 2057,
      },
      {
        nome: "Lauro de Freitas",
        uf: "BA",
        estadoCod: 5,
        cod: 2058,
      },
      {
        nome: "Lençóis",
        uf: "BA",
        estadoCod: 5,
        cod: 2059,
      },
      {
        nome: "Licínio de Almeida",
        uf: "BA",
        estadoCod: 5,
        cod: 2060,
      },
      {
        nome: "Livramento de Nossa Senhora",
        uf: "BA",
        estadoCod: 5,
        cod: 2061,
      },
      {
        nome: "Luís Eduardo Magalhães",
        uf: "BA",
        estadoCod: 5,
        cod: 2062,
      },
      {
        nome: "Macajuba",
        uf: "BA",
        estadoCod: 5,
        cod: 2063,
      },
      {
        nome: "Macarani",
        uf: "BA",
        estadoCod: 5,
        cod: 2064,
      },
      {
        nome: "Macaúbas",
        uf: "BA",
        estadoCod: 5,
        cod: 2065,
      },
      {
        nome: "Macururé",
        uf: "BA",
        estadoCod: 5,
        cod: 2066,
      },
      {
        nome: "Madre de Deus",
        uf: "BA",
        estadoCod: 5,
        cod: 2067,
      },
      {
        nome: "Maetinga",
        uf: "BA",
        estadoCod: 5,
        cod: 2068,
      },
      {
        nome: "Maiquinique",
        uf: "BA",
        estadoCod: 5,
        cod: 2069,
      },
      {
        nome: "Mairi",
        uf: "BA",
        estadoCod: 5,
        cod: 2070,
      },
      {
        nome: "Malhada",
        uf: "BA",
        estadoCod: 5,
        cod: 2071,
      },
      {
        nome: "Malhada de Pedras",
        uf: "BA",
        estadoCod: 5,
        cod: 2072,
      },
      {
        nome: "Manoel Vitorino",
        uf: "BA",
        estadoCod: 5,
        cod: 2073,
      },
      {
        nome: "Mansidão",
        uf: "BA",
        estadoCod: 5,
        cod: 2074,
      },
      {
        nome: "Maracás",
        uf: "BA",
        estadoCod: 5,
        cod: 2075,
      },
      {
        nome: "Maragogipe",
        uf: "BA",
        estadoCod: 5,
        cod: 2076,
      },
      {
        nome: "Maraú",
        uf: "BA",
        estadoCod: 5,
        cod: 2077,
      },
      {
        nome: "Marcionílio Souza",
        uf: "BA",
        estadoCod: 5,
        cod: 2078,
      },
      {
        nome: "Mascote",
        uf: "BA",
        estadoCod: 5,
        cod: 2079,
      },
      {
        nome: "Mata de São João",
        uf: "BA",
        estadoCod: 5,
        cod: 2080,
      },
      {
        nome: "Matina",
        uf: "BA",
        estadoCod: 5,
        cod: 2081,
      },
      {
        nome: "Medeiros Neto",
        uf: "BA",
        estadoCod: 5,
        cod: 2082,
      },
      {
        nome: "Miguel Calmon",
        uf: "BA",
        estadoCod: 5,
        cod: 2083,
      },
      {
        nome: "Milagres",
        uf: "BA",
        estadoCod: 5,
        cod: 2084,
      },
      {
        nome: "Mirangaba",
        uf: "BA",
        estadoCod: 5,
        cod: 2085,
      },
      {
        nome: "Mirante",
        uf: "BA",
        estadoCod: 5,
        cod: 2086,
      },
      {
        nome: "Monte Santo",
        uf: "BA",
        estadoCod: 5,
        cod: 2087,
      },
      {
        nome: "Morpará",
        uf: "BA",
        estadoCod: 5,
        cod: 2088,
      },
      {
        nome: "Morro do Chapéu",
        uf: "BA",
        estadoCod: 5,
        cod: 2089,
      },
      {
        nome: "Mortugaba",
        uf: "BA",
        estadoCod: 5,
        cod: 2090,
      },
      {
        nome: "Mucugê",
        uf: "BA",
        estadoCod: 5,
        cod: 2091,
      },
      {
        nome: "Mucuri",
        uf: "BA",
        estadoCod: 5,
        cod: 2092,
      },
      {
        nome: "Mulungu do Morro",
        uf: "BA",
        estadoCod: 5,
        cod: 2093,
      },
      {
        nome: "Mundo Novo",
        uf: "BA",
        estadoCod: 5,
        cod: 2094,
      },
      {
        nome: "Muniz Ferreira",
        uf: "BA",
        estadoCod: 5,
        cod: 2095,
      },
      {
        nome: "Muquém de São Francisco",
        uf: "BA",
        estadoCod: 5,
        cod: 2096,
      },
      {
        nome: "Muritiba",
        uf: "BA",
        estadoCod: 5,
        cod: 2097,
      },
      {
        nome: "Mutuípe",
        uf: "BA",
        estadoCod: 5,
        cod: 2098,
      },
      {
        nome: "Nazaré",
        uf: "BA",
        estadoCod: 5,
        cod: 2099,
      },
      {
        nome: "Nilo Peçanha",
        uf: "BA",
        estadoCod: 5,
        cod: 2100,
      },
      {
        nome: "Nordestina",
        uf: "BA",
        estadoCod: 5,
        cod: 2101,
      },
      {
        nome: "Nova Canaã",
        uf: "BA",
        estadoCod: 5,
        cod: 2102,
      },
      {
        nome: "Nova Fátima",
        uf: "BA",
        estadoCod: 5,
        cod: 2103,
      },
      {
        nome: "Nova Ibiá",
        uf: "BA",
        estadoCod: 5,
        cod: 2104,
      },
      {
        nome: "Nova Itarana",
        uf: "BA",
        estadoCod: 5,
        cod: 2105,
      },
      {
        nome: "Nova Redenção",
        uf: "BA",
        estadoCod: 5,
        cod: 2106,
      },
      {
        nome: "Nova Soure",
        uf: "BA",
        estadoCod: 5,
        cod: 2107,
      },
      {
        nome: "Nova Viçosa",
        uf: "BA",
        estadoCod: 5,
        cod: 2108,
      },
      {
        nome: "Novo Horizonte",
        uf: "BA",
        estadoCod: 5,
        cod: 2109,
      },
      {
        nome: "Novo Triunfo",
        uf: "BA",
        estadoCod: 5,
        cod: 2110,
      },
      {
        nome: "Olindina",
        uf: "BA",
        estadoCod: 5,
        cod: 2111,
      },
      {
        nome: "Oliveira dos Brejinhos",
        uf: "BA",
        estadoCod: 5,
        cod: 2112,
      },
      {
        nome: "Ouriçangas",
        uf: "BA",
        estadoCod: 5,
        cod: 2113,
      },
      {
        nome: "Ourolândia",
        uf: "BA",
        estadoCod: 5,
        cod: 2114,
      },
      {
        nome: "Palmas de Monte Alto",
        uf: "BA",
        estadoCod: 5,
        cod: 2115,
      },
      {
        nome: "Palmeiras",
        uf: "BA",
        estadoCod: 5,
        cod: 2116,
      },
      {
        nome: "Paramirim",
        uf: "BA",
        estadoCod: 5,
        cod: 2117,
      },
      {
        nome: "Paratinga",
        uf: "BA",
        estadoCod: 5,
        cod: 2118,
      },
      {
        nome: "Paripiranga",
        uf: "BA",
        estadoCod: 5,
        cod: 2119,
      },
      {
        nome: "Pau Brasil",
        uf: "BA",
        estadoCod: 5,
        cod: 2120,
      },
      {
        nome: "Paulo Afonso",
        uf: "BA",
        estadoCod: 5,
        cod: 2121,
      },
      {
        nome: "Pé de Serra",
        uf: "BA",
        estadoCod: 5,
        cod: 2122,
      },
      {
        nome: "Pedrão",
        uf: "BA",
        estadoCod: 5,
        cod: 2123,
      },
      {
        nome: "Pedro Alexandre",
        uf: "BA",
        estadoCod: 5,
        cod: 2124,
      },
      {
        nome: "Piatã",
        uf: "BA",
        estadoCod: 5,
        cod: 2125,
      },
      {
        nome: "Pilão Arcado",
        uf: "BA",
        estadoCod: 5,
        cod: 2126,
      },
      {
        nome: "Pindaí",
        uf: "BA",
        estadoCod: 5,
        cod: 2127,
      },
      {
        nome: "Pindobaçu",
        uf: "BA",
        estadoCod: 5,
        cod: 2128,
      },
      {
        nome: "Pintadas",
        uf: "BA",
        estadoCod: 5,
        cod: 2129,
      },
      {
        nome: "Piraí do Norte",
        uf: "BA",
        estadoCod: 5,
        cod: 2130,
      },
      {
        nome: "Piripá",
        uf: "BA",
        estadoCod: 5,
        cod: 2131,
      },
      {
        nome: "Piritiba",
        uf: "BA",
        estadoCod: 5,
        cod: 2132,
      },
      {
        nome: "Planaltino",
        uf: "BA",
        estadoCod: 5,
        cod: 2133,
      },
      {
        nome: "Planalto",
        uf: "BA",
        estadoCod: 5,
        cod: 2134,
      },
      {
        nome: "Poções",
        uf: "BA",
        estadoCod: 5,
        cod: 2135,
      },
      {
        nome: "Pojuca",
        uf: "BA",
        estadoCod: 5,
        cod: 2136,
      },
      {
        nome: "Ponto Novo",
        uf: "BA",
        estadoCod: 5,
        cod: 2137,
      },
      {
        nome: "Porto Seguro",
        uf: "BA",
        estadoCod: 5,
        cod: 2138,
      },
      {
        nome: "Potiraguá",
        uf: "BA",
        estadoCod: 5,
        cod: 2139,
      },
      {
        nome: "Prado",
        uf: "BA",
        estadoCod: 5,
        cod: 2140,
      },
      {
        nome: "Presidente Dutra",
        uf: "BA",
        estadoCod: 5,
        cod: 2141,
      },
      {
        nome: "Presidente Jânio Quadros",
        uf: "BA",
        estadoCod: 5,
        cod: 2142,
      },
      {
        nome: "Presidente Tancredo Neves",
        uf: "BA",
        estadoCod: 5,
        cod: 2143,
      },
      {
        nome: "Queimadas",
        uf: "BA",
        estadoCod: 5,
        cod: 2144,
      },
      {
        nome: "Quijingue",
        uf: "BA",
        estadoCod: 5,
        cod: 2145,
      },
      {
        nome: "Quixabeira",
        uf: "BA",
        estadoCod: 5,
        cod: 2146,
      },
      {
        nome: "Rafael Jambeiro",
        uf: "BA",
        estadoCod: 5,
        cod: 2147,
      },
      {
        nome: "Remanso",
        uf: "BA",
        estadoCod: 5,
        cod: 2148,
      },
      {
        nome: "Retirolândia",
        uf: "BA",
        estadoCod: 5,
        cod: 2149,
      },
      {
        nome: "Riachão das Neves",
        uf: "BA",
        estadoCod: 5,
        cod: 2150,
      },
      {
        nome: "Riachão do Jacuípe",
        uf: "BA",
        estadoCod: 5,
        cod: 2151,
      },
      {
        nome: "Riacho de Santana",
        uf: "BA",
        estadoCod: 5,
        cod: 2152,
      },
      {
        nome: "Ribeira do Amparo",
        uf: "BA",
        estadoCod: 5,
        cod: 2153,
      },
      {
        nome: "Ribeira do Pombal",
        uf: "BA",
        estadoCod: 5,
        cod: 2154,
      },
      {
        nome: "Ribeirão do Largo",
        uf: "BA",
        estadoCod: 5,
        cod: 2155,
      },
      {
        nome: "Rio de Contas",
        uf: "BA",
        estadoCod: 5,
        cod: 2156,
      },
      {
        nome: "Rio do Antônio",
        uf: "BA",
        estadoCod: 5,
        cod: 2157,
      },
      {
        nome: "Rio do Pires",
        uf: "BA",
        estadoCod: 5,
        cod: 2158,
      },
      {
        nome: "Rio Real",
        uf: "BA",
        estadoCod: 5,
        cod: 2159,
      },
      {
        nome: "Rodelas",
        uf: "BA",
        estadoCod: 5,
        cod: 2160,
      },
      {
        nome: "Ruy Barbosa",
        uf: "BA",
        estadoCod: 5,
        cod: 2161,
      },
      {
        nome: "Salinas da Margarida",
        uf: "BA",
        estadoCod: 5,
        cod: 2162,
      },
      {
        nome: "Salvador",
        uf: "BA",
        estadoCod: 5,
        cod: 2163,
      },
      {
        nome: "Santa Bárbara",
        uf: "BA",
        estadoCod: 5,
        cod: 2164,
      },
      {
        nome: "Santa Brígida",
        uf: "BA",
        estadoCod: 5,
        cod: 2165,
      },
      {
        nome: "Santa Cruz Cabrália",
        uf: "BA",
        estadoCod: 5,
        cod: 2166,
      },
      {
        nome: "Santa Cruz da Vitória",
        uf: "BA",
        estadoCod: 5,
        cod: 2167,
      },
      {
        nome: "Santa Inês",
        uf: "BA",
        estadoCod: 5,
        cod: 2168,
      },
      {
        nome: "Santaluz",
        uf: "BA",
        estadoCod: 5,
        cod: 2169,
      },
      {
        nome: "Santa Luzia",
        uf: "BA",
        estadoCod: 5,
        cod: 2170,
      },
      {
        nome: "Santa Maria da Vitória",
        uf: "BA",
        estadoCod: 5,
        cod: 2171,
      },
      {
        nome: "Santana",
        uf: "BA",
        estadoCod: 5,
        cod: 2172,
      },
      {
        nome: "Santanópolis",
        uf: "BA",
        estadoCod: 5,
        cod: 2173,
      },
      {
        nome: "Santa Rita de Cássia",
        uf: "BA",
        estadoCod: 5,
        cod: 2174,
      },
      {
        nome: "Santa Teresinha",
        uf: "BA",
        estadoCod: 5,
        cod: 2175,
      },
      {
        nome: "Santo Amaro",
        uf: "BA",
        estadoCod: 5,
        cod: 2176,
      },
      {
        nome: "Santo Antônio de Jesus",
        uf: "BA",
        estadoCod: 5,
        cod: 2177,
      },
      {
        nome: "Santo Estêvão",
        uf: "BA",
        estadoCod: 5,
        cod: 2178,
      },
      {
        nome: "São Desidério",
        uf: "BA",
        estadoCod: 5,
        cod: 2179,
      },
      {
        nome: "São Domingos",
        uf: "BA",
        estadoCod: 5,
        cod: 2180,
      },
      {
        nome: "São Félix",
        uf: "BA",
        estadoCod: 5,
        cod: 2181,
      },
      {
        nome: "São Félix do Coribe",
        uf: "BA",
        estadoCod: 5,
        cod: 2182,
      },
      {
        nome: "São Felipe",
        uf: "BA",
        estadoCod: 5,
        cod: 2183,
      },
      {
        nome: "São Francisco do Conde",
        uf: "BA",
        estadoCod: 5,
        cod: 2184,
      },
      {
        nome: "São Gabriel",
        uf: "BA",
        estadoCod: 5,
        cod: 2185,
      },
      {
        nome: "São Gonçalo dos Campos",
        uf: "BA",
        estadoCod: 5,
        cod: 2186,
      },
      {
        nome: "São José da Vitória",
        uf: "BA",
        estadoCod: 5,
        cod: 2187,
      },
      {
        nome: "São José do Jacuípe",
        uf: "BA",
        estadoCod: 5,
        cod: 2188,
      },
      {
        nome: "São Miguel das Matas",
        uf: "BA",
        estadoCod: 5,
        cod: 2189,
      },
      {
        nome: "São Sebastião do Passé",
        uf: "BA",
        estadoCod: 5,
        cod: 2190,
      },
      {
        nome: "Sapeaçu",
        uf: "BA",
        estadoCod: 5,
        cod: 2191,
      },
      {
        nome: "Sátiro Dias",
        uf: "BA",
        estadoCod: 5,
        cod: 2192,
      },
      {
        nome: "Saubara",
        uf: "BA",
        estadoCod: 5,
        cod: 2193,
      },
      {
        nome: "Saúde",
        uf: "BA",
        estadoCod: 5,
        cod: 2194,
      },
      {
        nome: "Seabra",
        uf: "BA",
        estadoCod: 5,
        cod: 2195,
      },
      {
        nome: "Sebastião Laranjeiras",
        uf: "BA",
        estadoCod: 5,
        cod: 2196,
      },
      {
        nome: "Senhor do Bonfim",
        uf: "BA",
        estadoCod: 5,
        cod: 2197,
      },
      {
        nome: "Serra do Ramalho",
        uf: "BA",
        estadoCod: 5,
        cod: 2198,
      },
      {
        nome: "Sento Sé",
        uf: "BA",
        estadoCod: 5,
        cod: 2199,
      },
      {
        nome: "Serra Dourada",
        uf: "BA",
        estadoCod: 5,
        cod: 2200,
      },
      {
        nome: "Serra Preta",
        uf: "BA",
        estadoCod: 5,
        cod: 2201,
      },
      {
        nome: "Serrinha",
        uf: "BA",
        estadoCod: 5,
        cod: 2202,
      },
      {
        nome: "Serrolândia",
        uf: "BA",
        estadoCod: 5,
        cod: 2203,
      },
      {
        nome: "Simões Filho",
        uf: "BA",
        estadoCod: 5,
        cod: 2204,
      },
      {
        nome: "Sítio do Mato",
        uf: "BA",
        estadoCod: 5,
        cod: 2205,
      },
      {
        nome: "Sítio do Quinto",
        uf: "BA",
        estadoCod: 5,
        cod: 2206,
      },
      {
        nome: "Sobradinho",
        uf: "BA",
        estadoCod: 5,
        cod: 2207,
      },
      {
        nome: "Souto Soares",
        uf: "BA",
        estadoCod: 5,
        cod: 2208,
      },
      {
        nome: "Tabocas do Brejo Velho",
        uf: "BA",
        estadoCod: 5,
        cod: 2209,
      },
      {
        nome: "Tanhaçu",
        uf: "BA",
        estadoCod: 5,
        cod: 2210,
      },
      {
        nome: "Tanque Novo",
        uf: "BA",
        estadoCod: 5,
        cod: 2211,
      },
      {
        nome: "Tanquinho",
        uf: "BA",
        estadoCod: 5,
        cod: 2212,
      },
      {
        nome: "Taperoá",
        uf: "BA",
        estadoCod: 5,
        cod: 2213,
      },
      {
        nome: "Tapiramutá",
        uf: "BA",
        estadoCod: 5,
        cod: 2214,
      },
      {
        nome: "Teixeira de Freitas",
        uf: "BA",
        estadoCod: 5,
        cod: 2215,
      },
      {
        nome: "Teodoro Sampaio",
        uf: "BA",
        estadoCod: 5,
        cod: 2216,
      },
      {
        nome: "Teofilândia",
        uf: "BA",
        estadoCod: 5,
        cod: 2217,
      },
      {
        nome: "Teolândia",
        uf: "BA",
        estadoCod: 5,
        cod: 2218,
      },
      {
        nome: "Terra Nova",
        uf: "BA",
        estadoCod: 5,
        cod: 2219,
      },
      {
        nome: "Tremedal",
        uf: "BA",
        estadoCod: 5,
        cod: 2220,
      },
      {
        nome: "Tucano",
        uf: "BA",
        estadoCod: 5,
        cod: 2221,
      },
      {
        nome: "Uauá",
        uf: "BA",
        estadoCod: 5,
        cod: 2222,
      },
      {
        nome: "Ubaíra",
        uf: "BA",
        estadoCod: 5,
        cod: 2223,
      },
      {
        nome: "Ubaitaba",
        uf: "BA",
        estadoCod: 5,
        cod: 2224,
      },
      {
        nome: "Ubatã",
        uf: "BA",
        estadoCod: 5,
        cod: 2225,
      },
      {
        nome: "Uibaí",
        uf: "BA",
        estadoCod: 5,
        cod: 2226,
      },
      {
        nome: "Umburanas",
        uf: "BA",
        estadoCod: 5,
        cod: 2227,
      },
      {
        nome: "Una",
        uf: "BA",
        estadoCod: 5,
        cod: 2228,
      },
      {
        nome: "Urandi",
        uf: "BA",
        estadoCod: 5,
        cod: 2229,
      },
      {
        nome: "Uruçuca",
        uf: "BA",
        estadoCod: 5,
        cod: 2230,
      },
      {
        nome: "Utinga",
        uf: "BA",
        estadoCod: 5,
        cod: 2231,
      },
      {
        nome: "Valença",
        uf: "BA",
        estadoCod: 5,
        cod: 2232,
      },
      {
        nome: "Valente",
        uf: "BA",
        estadoCod: 5,
        cod: 2233,
      },
      {
        nome: "Várzea da Roça",
        uf: "BA",
        estadoCod: 5,
        cod: 2234,
      },
      {
        nome: "Várzea do Poço",
        uf: "BA",
        estadoCod: 5,
        cod: 2235,
      },
      {
        nome: "Várzea Nova",
        uf: "BA",
        estadoCod: 5,
        cod: 2236,
      },
      {
        nome: "Varzedo",
        uf: "BA",
        estadoCod: 5,
        cod: 2237,
      },
      {
        nome: "Vera Cruz",
        uf: "BA",
        estadoCod: 5,
        cod: 2238,
      },
      {
        nome: "Vereda",
        uf: "BA",
        estadoCod: 5,
        cod: 2239,
      },
      {
        nome: "Vitória da Conquista",
        uf: "BA",
        estadoCod: 5,
        cod: 2240,
      },
      {
        nome: "Wagner",
        uf: "BA",
        estadoCod: 5,
        cod: 2241,
      },
      {
        nome: "Wanderley",
        uf: "BA",
        estadoCod: 5,
        cod: 2242,
      },
      {
        nome: "Wenceslau Guimarães",
        uf: "BA",
        estadoCod: 5,
        cod: 2243,
      },
      {
        nome: "Xique-Xique",
        uf: "BA",
        estadoCod: 5,
        cod: 2244,
      },
    ],
  },
  {
    nome: "Espírito Santo",
    uf: "ES",
    regiaoCod: 3,
    cod: 8,
    cidades: [
      {
        nome: "Afonso Cláudio",
        uf: "ES",
        estadoCod: 8,
        cod: 3098,
      },
      {
        nome: "Águia Branca",
        uf: "ES",
        estadoCod: 8,
        cod: 3099,
      },
      {
        nome: "Água Doce do Norte",
        uf: "ES",
        estadoCod: 8,
        cod: 3100,
      },
      {
        nome: "Alegre",
        uf: "ES",
        estadoCod: 8,
        cod: 3101,
      },
      {
        nome: "Alfredo Chaves",
        uf: "ES",
        estadoCod: 8,
        cod: 3102,
      },
      {
        nome: "Alto Rio Novo",
        uf: "ES",
        estadoCod: 8,
        cod: 3103,
      },
      {
        nome: "Anchieta",
        uf: "ES",
        estadoCod: 8,
        cod: 3104,
      },
      {
        nome: "Apiacá",
        uf: "ES",
        estadoCod: 8,
        cod: 3105,
      },
      {
        nome: "Aracruz",
        uf: "ES",
        estadoCod: 8,
        cod: 3106,
      },
      {
        nome: "Atilio Vivacqua",
        uf: "ES",
        estadoCod: 8,
        cod: 3107,
      },
      {
        nome: "Baixo Guandu",
        uf: "ES",
        estadoCod: 8,
        cod: 3108,
      },
      {
        nome: "Barra de São Francisco",
        uf: "ES",
        estadoCod: 8,
        cod: 3109,
      },
      {
        nome: "Boa Esperança",
        uf: "ES",
        estadoCod: 8,
        cod: 3110,
      },
      {
        nome: "Bom Jesus do Norte",
        uf: "ES",
        estadoCod: 8,
        cod: 3111,
      },
      {
        nome: "Brejetuba",
        uf: "ES",
        estadoCod: 8,
        cod: 3112,
      },
      {
        nome: "Cachoeiro de Itapemirim",
        uf: "ES",
        estadoCod: 8,
        cod: 3113,
      },
      {
        nome: "Cariacica",
        uf: "ES",
        estadoCod: 8,
        cod: 3114,
      },
      {
        nome: "Castelo",
        uf: "ES",
        estadoCod: 8,
        cod: 3115,
      },
      {
        nome: "Colatina",
        uf: "ES",
        estadoCod: 8,
        cod: 3116,
      },
      {
        nome: "Conceição da Barra",
        uf: "ES",
        estadoCod: 8,
        cod: 3117,
      },
      {
        nome: "Conceição do Castelo",
        uf: "ES",
        estadoCod: 8,
        cod: 3118,
      },
      {
        nome: "Divino de São Lourenço",
        uf: "ES",
        estadoCod: 8,
        cod: 3119,
      },
      {
        nome: "Domingos Martins",
        uf: "ES",
        estadoCod: 8,
        cod: 3120,
      },
      {
        nome: "Dores do Rio Preto",
        uf: "ES",
        estadoCod: 8,
        cod: 3121,
      },
      {
        nome: "Ecoporanga",
        uf: "ES",
        estadoCod: 8,
        cod: 3122,
      },
      {
        nome: "Fundão",
        uf: "ES",
        estadoCod: 8,
        cod: 3123,
      },
      {
        nome: "Governador Lindenberg",
        uf: "ES",
        estadoCod: 8,
        cod: 3124,
      },
      {
        nome: "Guaçuí",
        uf: "ES",
        estadoCod: 8,
        cod: 3125,
      },
      {
        nome: "Guarapari",
        uf: "ES",
        estadoCod: 8,
        cod: 3126,
      },
      {
        nome: "Ibatiba",
        uf: "ES",
        estadoCod: 8,
        cod: 3127,
      },
      {
        nome: "Ibiraçu",
        uf: "ES",
        estadoCod: 8,
        cod: 3128,
      },
      {
        nome: "Ibitirama",
        uf: "ES",
        estadoCod: 8,
        cod: 3129,
      },
      {
        nome: "Iconha",
        uf: "ES",
        estadoCod: 8,
        cod: 3130,
      },
      {
        nome: "Irupi",
        uf: "ES",
        estadoCod: 8,
        cod: 3131,
      },
      {
        nome: "Itaguaçu",
        uf: "ES",
        estadoCod: 8,
        cod: 3132,
      },
      {
        nome: "Itapemirim",
        uf: "ES",
        estadoCod: 8,
        cod: 3133,
      },
      {
        nome: "Itarana",
        uf: "ES",
        estadoCod: 8,
        cod: 3134,
      },
      {
        nome: "Iúna",
        uf: "ES",
        estadoCod: 8,
        cod: 3135,
      },
      {
        nome: "Jaguaré",
        uf: "ES",
        estadoCod: 8,
        cod: 3136,
      },
      {
        nome: "Jerônimo Monteiro",
        uf: "ES",
        estadoCod: 8,
        cod: 3137,
      },
      {
        nome: "João Neiva",
        uf: "ES",
        estadoCod: 8,
        cod: 3138,
      },
      {
        nome: "Laranja da Terra",
        uf: "ES",
        estadoCod: 8,
        cod: 3139,
      },
      {
        nome: "Linhares",
        uf: "ES",
        estadoCod: 8,
        cod: 3140,
      },
      {
        nome: "Mantenópolis",
        uf: "ES",
        estadoCod: 8,
        cod: 3141,
      },
      {
        nome: "Marataízes",
        uf: "ES",
        estadoCod: 8,
        cod: 3142,
      },
      {
        nome: "Marechal Floriano",
        uf: "ES",
        estadoCod: 8,
        cod: 3143,
      },
      {
        nome: "Marilândia",
        uf: "ES",
        estadoCod: 8,
        cod: 3144,
      },
      {
        nome: "Mimoso do Sul",
        uf: "ES",
        estadoCod: 8,
        cod: 3145,
      },
      {
        nome: "Montanha",
        uf: "ES",
        estadoCod: 8,
        cod: 3146,
      },
      {
        nome: "Mucurici",
        uf: "ES",
        estadoCod: 8,
        cod: 3147,
      },
      {
        nome: "Muniz Freire",
        uf: "ES",
        estadoCod: 8,
        cod: 3148,
      },
      {
        nome: "Muqui",
        uf: "ES",
        estadoCod: 8,
        cod: 3149,
      },
      {
        nome: "Nova Venécia",
        uf: "ES",
        estadoCod: 8,
        cod: 3150,
      },
      {
        nome: "Pancas",
        uf: "ES",
        estadoCod: 8,
        cod: 3151,
      },
      {
        nome: "Pedro Canário",
        uf: "ES",
        estadoCod: 8,
        cod: 3152,
      },
      {
        nome: "Pinheiros",
        uf: "ES",
        estadoCod: 8,
        cod: 3153,
      },
      {
        nome: "Piúma",
        uf: "ES",
        estadoCod: 8,
        cod: 3154,
      },
      {
        nome: "Ponto Belo",
        uf: "ES",
        estadoCod: 8,
        cod: 3155,
      },
      {
        nome: "Presidente Kennedy",
        uf: "ES",
        estadoCod: 8,
        cod: 3156,
      },
      {
        nome: "Rio Bananal",
        uf: "ES",
        estadoCod: 8,
        cod: 3157,
      },
      {
        nome: "Rio Novo do Sul",
        uf: "ES",
        estadoCod: 8,
        cod: 3158,
      },
      {
        nome: "Santa Leopoldina",
        uf: "ES",
        estadoCod: 8,
        cod: 3159,
      },
      {
        nome: "Santa Maria de Jetibá",
        uf: "ES",
        estadoCod: 8,
        cod: 3160,
      },
      {
        nome: "Santa Teresa",
        uf: "ES",
        estadoCod: 8,
        cod: 3161,
      },
      {
        nome: "São Domingos do Norte",
        uf: "ES",
        estadoCod: 8,
        cod: 3162,
      },
      {
        nome: "São Gabriel da Palha",
        uf: "ES",
        estadoCod: 8,
        cod: 3163,
      },
      {
        nome: "São José do Calçado",
        uf: "ES",
        estadoCod: 8,
        cod: 3164,
      },
      {
        nome: "São Mateus",
        uf: "ES",
        estadoCod: 8,
        cod: 3165,
      },
      {
        nome: "São Roque do Canaã",
        uf: "ES",
        estadoCod: 8,
        cod: 3166,
      },
      {
        nome: "Serra",
        uf: "ES",
        estadoCod: 8,
        cod: 3167,
      },
      {
        nome: "Sooretama",
        uf: "ES",
        estadoCod: 8,
        cod: 3168,
      },
      {
        nome: "Vargem Alta",
        uf: "ES",
        estadoCod: 8,
        cod: 3169,
      },
      {
        nome: "Venda Nova do Imigrante",
        uf: "ES",
        estadoCod: 8,
        cod: 3170,
      },
      {
        nome: "Viana",
        uf: "ES",
        estadoCod: 8,
        cod: 3171,
      },
      {
        nome: "Vila Pavão",
        uf: "ES",
        estadoCod: 8,
        cod: 3172,
      },
      {
        nome: "Vila Valério",
        uf: "ES",
        estadoCod: 8,
        cod: 3173,
      },
      {
        nome: "Vila Velha",
        uf: "ES",
        estadoCod: 8,
        cod: 3174,
      },
      {
        nome: "Vitória",
        uf: "ES",
        estadoCod: 8,
        cod: 3175,
      },
    ],
  },
  {
    nome: "Rio de Janeiro",
    uf: "RJ",
    regiaoCod: 3,
    cod: 19,
    cidades: [
      {
        nome: "Angra dos Reis",
        uf: "RJ",
        estadoCod: 19,
        cod: 3176,
      },
      {
        nome: "Aperibé",
        uf: "RJ",
        estadoCod: 19,
        cod: 3177,
      },
      {
        nome: "Araruama",
        uf: "RJ",
        estadoCod: 19,
        cod: 3178,
      },
      {
        nome: "Areal",
        uf: "RJ",
        estadoCod: 19,
        cod: 3179,
      },
      {
        nome: "Armação dos Búzios",
        uf: "RJ",
        estadoCod: 19,
        cod: 3180,
      },
      {
        nome: "Arraial do Cabo",
        uf: "RJ",
        estadoCod: 19,
        cod: 3181,
      },
      {
        nome: "Barra do Piraí",
        uf: "RJ",
        estadoCod: 19,
        cod: 3182,
      },
      {
        nome: "Barra Mansa",
        uf: "RJ",
        estadoCod: 19,
        cod: 3183,
      },
      {
        nome: "Belford Roxo",
        uf: "RJ",
        estadoCod: 19,
        cod: 3184,
      },
      {
        nome: "Bom Jardim",
        uf: "RJ",
        estadoCod: 19,
        cod: 3185,
      },
      {
        nome: "Bom Jesus do Itabapoana",
        uf: "RJ",
        estadoCod: 19,
        cod: 3186,
      },
      {
        nome: "Cabo Frio",
        uf: "RJ",
        estadoCod: 19,
        cod: 3187,
      },
      {
        nome: "Cachoeiras de Macacu",
        uf: "RJ",
        estadoCod: 19,
        cod: 3188,
      },
      {
        nome: "Cambuci",
        uf: "RJ",
        estadoCod: 19,
        cod: 3189,
      },
      {
        nome: "Carapebus",
        uf: "RJ",
        estadoCod: 19,
        cod: 3190,
      },
      {
        nome: "Comendador Levy Gasparian",
        uf: "RJ",
        estadoCod: 19,
        cod: 3191,
      },
      {
        nome: "Campos dos Goytacazes",
        uf: "RJ",
        estadoCod: 19,
        cod: 3192,
      },
      {
        nome: "Cantagalo",
        uf: "RJ",
        estadoCod: 19,
        cod: 3193,
      },
      {
        nome: "Cardoso Moreira",
        uf: "RJ",
        estadoCod: 19,
        cod: 3194,
      },
      {
        nome: "Carmo",
        uf: "RJ",
        estadoCod: 19,
        cod: 3195,
      },
      {
        nome: "Casimiro de Abreu",
        uf: "RJ",
        estadoCod: 19,
        cod: 3196,
      },
      {
        nome: "Conceição de Macabu",
        uf: "RJ",
        estadoCod: 19,
        cod: 3197,
      },
      {
        nome: "Cordeiro",
        uf: "RJ",
        estadoCod: 19,
        cod: 3198,
      },
      {
        nome: "Duas Barras",
        uf: "RJ",
        estadoCod: 19,
        cod: 3199,
      },
      {
        nome: "Duque de Caxias",
        uf: "RJ",
        estadoCod: 19,
        cod: 3200,
      },
      {
        nome: "Engenheiro Paulo de Frontin",
        uf: "RJ",
        estadoCod: 19,
        cod: 3201,
      },
      {
        nome: "Guapimirim",
        uf: "RJ",
        estadoCod: 19,
        cod: 3202,
      },
      {
        nome: "Iguaba Grande",
        uf: "RJ",
        estadoCod: 19,
        cod: 3203,
      },
      {
        nome: "Itaboraí",
        uf: "RJ",
        estadoCod: 19,
        cod: 3204,
      },
      {
        nome: "Itaguaí",
        uf: "RJ",
        estadoCod: 19,
        cod: 3205,
      },
      {
        nome: "Italva",
        uf: "RJ",
        estadoCod: 19,
        cod: 3206,
      },
      {
        nome: "Itaocara",
        uf: "RJ",
        estadoCod: 19,
        cod: 3207,
      },
      {
        nome: "Itaperuna",
        uf: "RJ",
        estadoCod: 19,
        cod: 3208,
      },
      {
        nome: "Itatiaia",
        uf: "RJ",
        estadoCod: 19,
        cod: 3209,
      },
      {
        nome: "Japeri",
        uf: "RJ",
        estadoCod: 19,
        cod: 3210,
      },
      {
        nome: "Laje do Muriaé",
        uf: "RJ",
        estadoCod: 19,
        cod: 3211,
      },
      {
        nome: "Macaé",
        uf: "RJ",
        estadoCod: 19,
        cod: 3212,
      },
      {
        nome: "Macuco",
        uf: "RJ",
        estadoCod: 19,
        cod: 3213,
      },
      {
        nome: "Magé",
        uf: "RJ",
        estadoCod: 19,
        cod: 3214,
      },
      {
        nome: "Mangaratiba",
        uf: "RJ",
        estadoCod: 19,
        cod: 3215,
      },
      {
        nome: "Maricá",
        uf: "RJ",
        estadoCod: 19,
        cod: 3216,
      },
      {
        nome: "Mendes",
        uf: "RJ",
        estadoCod: 19,
        cod: 3217,
      },
      {
        nome: "Mesquita",
        uf: "RJ",
        estadoCod: 19,
        cod: 3218,
      },
      {
        nome: "Miguel Pereira",
        uf: "RJ",
        estadoCod: 19,
        cod: 3219,
      },
      {
        nome: "Miracema",
        uf: "RJ",
        estadoCod: 19,
        cod: 3220,
      },
      {
        nome: "Natividade",
        uf: "RJ",
        estadoCod: 19,
        cod: 3221,
      },
      {
        nome: "Nilópolis",
        uf: "RJ",
        estadoCod: 19,
        cod: 3222,
      },
      {
        nome: "Niterói",
        uf: "RJ",
        estadoCod: 19,
        cod: 3223,
      },
      {
        nome: "Nova Friburgo",
        uf: "RJ",
        estadoCod: 19,
        cod: 3224,
      },
      {
        nome: "Nova Iguaçu",
        uf: "RJ",
        estadoCod: 19,
        cod: 3225,
      },
      {
        nome: "Paracambi",
        uf: "RJ",
        estadoCod: 19,
        cod: 3226,
      },
      {
        nome: "Paraíba do Sul",
        uf: "RJ",
        estadoCod: 19,
        cod: 3227,
      },
      {
        nome: "Paraty",
        uf: "RJ",
        estadoCod: 19,
        cod: 3228,
      },
      {
        nome: "Paty do Alferes",
        uf: "RJ",
        estadoCod: 19,
        cod: 3229,
      },
      {
        nome: "Petrópolis",
        uf: "RJ",
        estadoCod: 19,
        cod: 3230,
      },
      {
        nome: "Pinheiral",
        uf: "RJ",
        estadoCod: 19,
        cod: 3231,
      },
      {
        nome: "Piraí",
        uf: "RJ",
        estadoCod: 19,
        cod: 3232,
      },
      {
        nome: "Porciúncula",
        uf: "RJ",
        estadoCod: 19,
        cod: 3233,
      },
      {
        nome: "Porto Real",
        uf: "RJ",
        estadoCod: 19,
        cod: 3234,
      },
      {
        nome: "Quatis",
        uf: "RJ",
        estadoCod: 19,
        cod: 3235,
      },
      {
        nome: "Queimados",
        uf: "RJ",
        estadoCod: 19,
        cod: 3236,
      },
      {
        nome: "Quissamã",
        uf: "RJ",
        estadoCod: 19,
        cod: 3237,
      },
      {
        nome: "Resende",
        uf: "RJ",
        estadoCod: 19,
        cod: 3238,
      },
      {
        nome: "Rio Bonito",
        uf: "RJ",
        estadoCod: 19,
        cod: 3239,
      },
      {
        nome: "Rio Claro",
        uf: "RJ",
        estadoCod: 19,
        cod: 3240,
      },
      {
        nome: "Rio das Flores",
        uf: "RJ",
        estadoCod: 19,
        cod: 3241,
      },
      {
        nome: "Rio das Ostras",
        uf: "RJ",
        estadoCod: 19,
        cod: 3242,
      },
      {
        nome: "Rio de Janeiro",
        uf: "RJ",
        estadoCod: 19,
        cod: 3243,
      },
      {
        nome: "Santa Maria Madalena",
        uf: "RJ",
        estadoCod: 19,
        cod: 3244,
      },
      {
        nome: "Santo Antônio de Pádua",
        uf: "RJ",
        estadoCod: 19,
        cod: 3245,
      },
      {
        nome: "São Francisco de Itabapoana",
        uf: "RJ",
        estadoCod: 19,
        cod: 3246,
      },
      {
        nome: "São Fidélis",
        uf: "RJ",
        estadoCod: 19,
        cod: 3247,
      },
      {
        nome: "São Gonçalo",
        uf: "RJ",
        estadoCod: 19,
        cod: 3248,
      },
      {
        nome: "São João da Barra",
        uf: "RJ",
        estadoCod: 19,
        cod: 3249,
      },
      {
        nome: "São João de Meriti",
        uf: "RJ",
        estadoCod: 19,
        cod: 3250,
      },
      {
        nome: "São José de Ubá",
        uf: "RJ",
        estadoCod: 19,
        cod: 3251,
      },
      {
        nome: "São José do Vale do Rio Preto",
        uf: "RJ",
        estadoCod: 19,
        cod: 3252,
      },
      {
        nome: "São Pedro da Aldeia",
        uf: "RJ",
        estadoCod: 19,
        cod: 3253,
      },
      {
        nome: "São Sebastião do Alto",
        uf: "RJ",
        estadoCod: 19,
        cod: 3254,
      },
      {
        nome: "Sapucaia",
        uf: "RJ",
        estadoCod: 19,
        cod: 3255,
      },
      {
        nome: "Saquarema",
        uf: "RJ",
        estadoCod: 19,
        cod: 3256,
      },
      {
        nome: "Seropédica",
        uf: "RJ",
        estadoCod: 19,
        cod: 3257,
      },
      {
        nome: "Silva Jardim",
        uf: "RJ",
        estadoCod: 19,
        cod: 3258,
      },
      {
        nome: "Sumidouro",
        uf: "RJ",
        estadoCod: 19,
        cod: 3259,
      },
      {
        nome: "Tanguá",
        uf: "RJ",
        estadoCod: 19,
        cod: 3260,
      },
      {
        nome: "Teresópolis",
        uf: "RJ",
        estadoCod: 19,
        cod: 3261,
      },
      {
        nome: "Trajano de Moraes",
        uf: "RJ",
        estadoCod: 19,
        cod: 3262,
      },
      {
        nome: "Três Rios",
        uf: "RJ",
        estadoCod: 19,
        cod: 3263,
      },
      {
        nome: "Valença",
        uf: "RJ",
        estadoCod: 19,
        cod: 3264,
      },
      {
        nome: "Varre-Sai",
        uf: "RJ",
        estadoCod: 19,
        cod: 3265,
      },
      {
        nome: "Vassouras",
        uf: "RJ",
        estadoCod: 19,
        cod: 3266,
      },
      {
        nome: "Volta Redonda",
        uf: "RJ",
        estadoCod: 19,
        cod: 3267,
      },
    ],
  },
  {
    nome: "São Paulo",
    uf: "SP",
    regiaoCod: 3,
    cod: 25,
    cidades: [
      {
        nome: "Adamantina",
        uf: "SP",
        estadoCod: 25,
        cod: 3268,
      },
      {
        nome: "Adolfo",
        uf: "SP",
        estadoCod: 25,
        cod: 3269,
      },
      {
        nome: "Aguaí",
        uf: "SP",
        estadoCod: 25,
        cod: 3270,
      },
      {
        nome: "Águas da Prata",
        uf: "SP",
        estadoCod: 25,
        cod: 3271,
      },
      {
        nome: "Águas de Lindóia",
        uf: "SP",
        estadoCod: 25,
        cod: 3272,
      },
      {
        nome: "Águas de Santa Bárbara",
        uf: "SP",
        estadoCod: 25,
        cod: 3273,
      },
      {
        nome: "Águas de São Pedro",
        uf: "SP",
        estadoCod: 25,
        cod: 3274,
      },
      {
        nome: "Agudos",
        uf: "SP",
        estadoCod: 25,
        cod: 3275,
      },
      {
        nome: "Alambari",
        uf: "SP",
        estadoCod: 25,
        cod: 3276,
      },
      {
        nome: "Alfredo Marcondes",
        uf: "SP",
        estadoCod: 25,
        cod: 3277,
      },
      {
        nome: "Altair",
        uf: "SP",
        estadoCod: 25,
        cod: 3278,
      },
      {
        nome: "Altinópolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3279,
      },
      {
        nome: "Alto Alegre",
        uf: "SP",
        estadoCod: 25,
        cod: 3280,
      },
      {
        nome: "Alumínio",
        uf: "SP",
        estadoCod: 25,
        cod: 3281,
      },
      {
        nome: "Álvares Florence",
        uf: "SP",
        estadoCod: 25,
        cod: 3282,
      },
      {
        nome: "Álvares Machado",
        uf: "SP",
        estadoCod: 25,
        cod: 3283,
      },
      {
        nome: "Álvaro de Carvalho",
        uf: "SP",
        estadoCod: 25,
        cod: 3284,
      },
      {
        nome: "Alvinlândia",
        uf: "SP",
        estadoCod: 25,
        cod: 3285,
      },
      {
        nome: "Americana",
        uf: "SP",
        estadoCod: 25,
        cod: 3286,
      },
      {
        nome: "Américo Brasiliense",
        uf: "SP",
        estadoCod: 25,
        cod: 3287,
      },
      {
        nome: "Américo de Campos",
        uf: "SP",
        estadoCod: 25,
        cod: 3288,
      },
      {
        nome: "Amparo",
        uf: "SP",
        estadoCod: 25,
        cod: 3289,
      },
      {
        nome: "Analândia",
        uf: "SP",
        estadoCod: 25,
        cod: 3290,
      },
      {
        nome: "Andradina",
        uf: "SP",
        estadoCod: 25,
        cod: 3291,
      },
      {
        nome: "Angatuba",
        uf: "SP",
        estadoCod: 25,
        cod: 3292,
      },
      {
        nome: "Anhembi",
        uf: "SP",
        estadoCod: 25,
        cod: 3293,
      },
      {
        nome: "Anhumas",
        uf: "SP",
        estadoCod: 25,
        cod: 3294,
      },
      {
        nome: "Aparecida",
        uf: "SP",
        estadoCod: 25,
        cod: 3295,
      },
      {
        nome: "Aparecida D'Oeste",
        uf: "SP",
        estadoCod: 25,
        cod: 3296,
      },
      {
        nome: "Apiaí",
        uf: "SP",
        estadoCod: 25,
        cod: 3297,
      },
      {
        nome: "Araçariguama",
        uf: "SP",
        estadoCod: 25,
        cod: 3298,
      },
      {
        nome: "Araçatuba",
        uf: "SP",
        estadoCod: 25,
        cod: 3299,
      },
      {
        nome: "Araçoiaba da Serra",
        uf: "SP",
        estadoCod: 25,
        cod: 3300,
      },
      {
        nome: "Aramina",
        uf: "SP",
        estadoCod: 25,
        cod: 3301,
      },
      {
        nome: "Arandu",
        uf: "SP",
        estadoCod: 25,
        cod: 3302,
      },
      {
        nome: "Arapeí",
        uf: "SP",
        estadoCod: 25,
        cod: 3303,
      },
      {
        nome: "Araraquara",
        uf: "SP",
        estadoCod: 25,
        cod: 3304,
      },
      {
        nome: "Araras",
        uf: "SP",
        estadoCod: 25,
        cod: 3305,
      },
      {
        nome: "Arco-Íris",
        uf: "SP",
        estadoCod: 25,
        cod: 3306,
      },
      {
        nome: "Arealva",
        uf: "SP",
        estadoCod: 25,
        cod: 3307,
      },
      {
        nome: "Areias",
        uf: "SP",
        estadoCod: 25,
        cod: 3308,
      },
      {
        nome: "Areiópolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3309,
      },
      {
        nome: "Ariranha",
        uf: "SP",
        estadoCod: 25,
        cod: 3310,
      },
      {
        nome: "Artur Nogueira",
        uf: "SP",
        estadoCod: 25,
        cod: 3311,
      },
      {
        nome: "Arujá",
        uf: "SP",
        estadoCod: 25,
        cod: 3312,
      },
      {
        nome: "Aspásia",
        uf: "SP",
        estadoCod: 25,
        cod: 3313,
      },
      {
        nome: "Assis",
        uf: "SP",
        estadoCod: 25,
        cod: 3314,
      },
      {
        nome: "Atibaia",
        uf: "SP",
        estadoCod: 25,
        cod: 3315,
      },
      {
        nome: "Auriflama",
        uf: "SP",
        estadoCod: 25,
        cod: 3316,
      },
      {
        nome: "Avaí",
        uf: "SP",
        estadoCod: 25,
        cod: 3317,
      },
      {
        nome: "Avanhandava",
        uf: "SP",
        estadoCod: 25,
        cod: 3318,
      },
      {
        nome: "Avaré",
        uf: "SP",
        estadoCod: 25,
        cod: 3319,
      },
      {
        nome: "Bady Bassitt",
        uf: "SP",
        estadoCod: 25,
        cod: 3320,
      },
      {
        nome: "Balbinos",
        uf: "SP",
        estadoCod: 25,
        cod: 3321,
      },
      {
        nome: "Bálsamo",
        uf: "SP",
        estadoCod: 25,
        cod: 3322,
      },
      {
        nome: "Bananal",
        uf: "SP",
        estadoCod: 25,
        cod: 3323,
      },
      {
        nome: "Barão de Antonina",
        uf: "SP",
        estadoCod: 25,
        cod: 3324,
      },
      {
        nome: "Barbosa",
        uf: "SP",
        estadoCod: 25,
        cod: 3325,
      },
      {
        nome: "Bariri",
        uf: "SP",
        estadoCod: 25,
        cod: 3326,
      },
      {
        nome: "Barra Bonita",
        uf: "SP",
        estadoCod: 25,
        cod: 3327,
      },
      {
        nome: "Barra do Chapéu",
        uf: "SP",
        estadoCod: 25,
        cod: 3328,
      },
      {
        nome: "Barra do Turvo",
        uf: "SP",
        estadoCod: 25,
        cod: 3329,
      },
      {
        nome: "Barretos",
        uf: "SP",
        estadoCod: 25,
        cod: 3330,
      },
      {
        nome: "Barrinha",
        uf: "SP",
        estadoCod: 25,
        cod: 3331,
      },
      {
        nome: "Barueri",
        uf: "SP",
        estadoCod: 25,
        cod: 3332,
      },
      {
        nome: "Bastos",
        uf: "SP",
        estadoCod: 25,
        cod: 3333,
      },
      {
        nome: "Batatais",
        uf: "SP",
        estadoCod: 25,
        cod: 3334,
      },
      {
        nome: "Bauru",
        uf: "SP",
        estadoCod: 25,
        cod: 3335,
      },
      {
        nome: "Bebedouro",
        uf: "SP",
        estadoCod: 25,
        cod: 3336,
      },
      {
        nome: "Bento de Abreu",
        uf: "SP",
        estadoCod: 25,
        cod: 3337,
      },
      {
        nome: "Bernardino de Campos",
        uf: "SP",
        estadoCod: 25,
        cod: 3338,
      },
      {
        nome: "Bertioga",
        uf: "SP",
        estadoCod: 25,
        cod: 3339,
      },
      {
        nome: "Bilac",
        uf: "SP",
        estadoCod: 25,
        cod: 3340,
      },
      {
        nome: "Birigui",
        uf: "SP",
        estadoCod: 25,
        cod: 3341,
      },
      {
        nome: "Biritiba-Mirim",
        uf: "SP",
        estadoCod: 25,
        cod: 3342,
      },
      {
        nome: "Boa Esperança do Sul",
        uf: "SP",
        estadoCod: 25,
        cod: 3343,
      },
      {
        nome: "Bocaina",
        uf: "SP",
        estadoCod: 25,
        cod: 3344,
      },
      {
        nome: "Bofete",
        uf: "SP",
        estadoCod: 25,
        cod: 3345,
      },
      {
        nome: "Boituva",
        uf: "SP",
        estadoCod: 25,
        cod: 3346,
      },
      {
        nome: "Bom Jesus dos Perdões",
        uf: "SP",
        estadoCod: 25,
        cod: 3347,
      },
      {
        nome: "Bom Sucesso de Itararé",
        uf: "SP",
        estadoCod: 25,
        cod: 3348,
      },
      {
        nome: "Borá",
        uf: "SP",
        estadoCod: 25,
        cod: 3349,
      },
      {
        nome: "Boracéia",
        uf: "SP",
        estadoCod: 25,
        cod: 3350,
      },
      {
        nome: "Borborema",
        uf: "SP",
        estadoCod: 25,
        cod: 3351,
      },
      {
        nome: "Borebi",
        uf: "SP",
        estadoCod: 25,
        cod: 3352,
      },
      {
        nome: "Botucatu",
        uf: "SP",
        estadoCod: 25,
        cod: 3353,
      },
      {
        nome: "Bragança Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3354,
      },
      {
        nome: "Braúna",
        uf: "SP",
        estadoCod: 25,
        cod: 3355,
      },
      {
        nome: "Brejo Alegre",
        uf: "SP",
        estadoCod: 25,
        cod: 3356,
      },
      {
        nome: "Brodowski",
        uf: "SP",
        estadoCod: 25,
        cod: 3357,
      },
      {
        nome: "Brotas",
        uf: "SP",
        estadoCod: 25,
        cod: 3358,
      },
      {
        nome: "Buri",
        uf: "SP",
        estadoCod: 25,
        cod: 3359,
      },
      {
        nome: "Buritama",
        uf: "SP",
        estadoCod: 25,
        cod: 3360,
      },
      {
        nome: "Buritizal",
        uf: "SP",
        estadoCod: 25,
        cod: 3361,
      },
      {
        nome: "Cabrália Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3362,
      },
      {
        nome: "Cabreúva",
        uf: "SP",
        estadoCod: 25,
        cod: 3363,
      },
      {
        nome: "Caçapava",
        uf: "SP",
        estadoCod: 25,
        cod: 3364,
      },
      {
        nome: "Cachoeira Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3365,
      },
      {
        nome: "Caconde",
        uf: "SP",
        estadoCod: 25,
        cod: 3366,
      },
      {
        nome: "Cafelândia",
        uf: "SP",
        estadoCod: 25,
        cod: 3367,
      },
      {
        nome: "Caiabu",
        uf: "SP",
        estadoCod: 25,
        cod: 3368,
      },
      {
        nome: "Caieiras",
        uf: "SP",
        estadoCod: 25,
        cod: 3369,
      },
      {
        nome: "Caiuá",
        uf: "SP",
        estadoCod: 25,
        cod: 3370,
      },
      {
        nome: "Cajamar",
        uf: "SP",
        estadoCod: 25,
        cod: 3371,
      },
      {
        nome: "Cajati",
        uf: "SP",
        estadoCod: 25,
        cod: 3372,
      },
      {
        nome: "Cajobi",
        uf: "SP",
        estadoCod: 25,
        cod: 3373,
      },
      {
        nome: "Cajuru",
        uf: "SP",
        estadoCod: 25,
        cod: 3374,
      },
      {
        nome: "Campina do Monte Alegre",
        uf: "SP",
        estadoCod: 25,
        cod: 3375,
      },
      {
        nome: "Campinas",
        uf: "SP",
        estadoCod: 25,
        cod: 3376,
      },
      {
        nome: "Campo Limpo Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3377,
      },
      {
        nome: "Campos do Jordão",
        uf: "SP",
        estadoCod: 25,
        cod: 3378,
      },
      {
        nome: "Campos Novos Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3379,
      },
      {
        nome: "Cananéia",
        uf: "SP",
        estadoCod: 25,
        cod: 3380,
      },
      {
        nome: "Canas",
        uf: "SP",
        estadoCod: 25,
        cod: 3381,
      },
      {
        nome: "Cândido Mota",
        uf: "SP",
        estadoCod: 25,
        cod: 3382,
      },
      {
        nome: "Cândido Rodrigues",
        uf: "SP",
        estadoCod: 25,
        cod: 3383,
      },
      {
        nome: "Canitar",
        uf: "SP",
        estadoCod: 25,
        cod: 3384,
      },
      {
        nome: "Capão Bonito",
        uf: "SP",
        estadoCod: 25,
        cod: 3385,
      },
      {
        nome: "Capela do Alto",
        uf: "SP",
        estadoCod: 25,
        cod: 3386,
      },
      {
        nome: "Capivari",
        uf: "SP",
        estadoCod: 25,
        cod: 3387,
      },
      {
        nome: "Caraguatatuba",
        uf: "SP",
        estadoCod: 25,
        cod: 3388,
      },
      {
        nome: "Carapicuíba",
        uf: "SP",
        estadoCod: 25,
        cod: 3389,
      },
      {
        nome: "Cardoso",
        uf: "SP",
        estadoCod: 25,
        cod: 3390,
      },
      {
        nome: "Casa Branca",
        uf: "SP",
        estadoCod: 25,
        cod: 3391,
      },
      {
        nome: "Cássia dos Coqueiros",
        uf: "SP",
        estadoCod: 25,
        cod: 3392,
      },
      {
        nome: "Castilho",
        uf: "SP",
        estadoCod: 25,
        cod: 3393,
      },
      {
        nome: "Catanduva",
        uf: "SP",
        estadoCod: 25,
        cod: 3394,
      },
      {
        nome: "Catiguá",
        uf: "SP",
        estadoCod: 25,
        cod: 3395,
      },
      {
        nome: "Cedral",
        uf: "SP",
        estadoCod: 25,
        cod: 3396,
      },
      {
        nome: "Cerqueira César",
        uf: "SP",
        estadoCod: 25,
        cod: 3397,
      },
      {
        nome: "Cerquilho",
        uf: "SP",
        estadoCod: 25,
        cod: 3398,
      },
      {
        nome: "Cesário Lange",
        uf: "SP",
        estadoCod: 25,
        cod: 3399,
      },
      {
        nome: "Charqueada",
        uf: "SP",
        estadoCod: 25,
        cod: 3400,
      },
      {
        nome: "Clementina",
        uf: "SP",
        estadoCod: 25,
        cod: 3401,
      },
      {
        nome: "Colina",
        uf: "SP",
        estadoCod: 25,
        cod: 3402,
      },
      {
        nome: "Colômbia",
        uf: "SP",
        estadoCod: 25,
        cod: 3403,
      },
      {
        nome: "Conchal",
        uf: "SP",
        estadoCod: 25,
        cod: 3404,
      },
      {
        nome: "Conchas",
        uf: "SP",
        estadoCod: 25,
        cod: 3405,
      },
      {
        nome: "Cordeirópolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3406,
      },
      {
        nome: "Coroados",
        uf: "SP",
        estadoCod: 25,
        cod: 3407,
      },
      {
        nome: "Coronel Macedo",
        uf: "SP",
        estadoCod: 25,
        cod: 3408,
      },
      {
        nome: "Corumbataí",
        uf: "SP",
        estadoCod: 25,
        cod: 3409,
      },
      {
        nome: "Cosmópolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3410,
      },
      {
        nome: "Cosmorama",
        uf: "SP",
        estadoCod: 25,
        cod: 3411,
      },
      {
        nome: "Cotia",
        uf: "SP",
        estadoCod: 25,
        cod: 3412,
      },
      {
        nome: "Cravinhos",
        uf: "SP",
        estadoCod: 25,
        cod: 3413,
      },
      {
        nome: "Cristais Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3414,
      },
      {
        nome: "Cruzália",
        uf: "SP",
        estadoCod: 25,
        cod: 3415,
      },
      {
        nome: "Cruzeiro",
        uf: "SP",
        estadoCod: 25,
        cod: 3416,
      },
      {
        nome: "Cubatão",
        uf: "SP",
        estadoCod: 25,
        cod: 3417,
      },
      {
        nome: "Cunha",
        uf: "SP",
        estadoCod: 25,
        cod: 3418,
      },
      {
        nome: "Descalvado",
        uf: "SP",
        estadoCod: 25,
        cod: 3419,
      },
      {
        nome: "Diadema",
        uf: "SP",
        estadoCod: 25,
        cod: 3420,
      },
      {
        nome: "Dirce Reis",
        uf: "SP",
        estadoCod: 25,
        cod: 3421,
      },
      {
        nome: "Divinolândia",
        uf: "SP",
        estadoCod: 25,
        cod: 3422,
      },
      {
        nome: "Dobrada",
        uf: "SP",
        estadoCod: 25,
        cod: 3423,
      },
      {
        nome: "Dois Córregos",
        uf: "SP",
        estadoCod: 25,
        cod: 3424,
      },
      {
        nome: "Dolcinópolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3425,
      },
      {
        nome: "Dourado",
        uf: "SP",
        estadoCod: 25,
        cod: 3426,
      },
      {
        nome: "Dracena",
        uf: "SP",
        estadoCod: 25,
        cod: 3427,
      },
      {
        nome: "Duartina",
        uf: "SP",
        estadoCod: 25,
        cod: 3428,
      },
      {
        nome: "Dumont",
        uf: "SP",
        estadoCod: 25,
        cod: 3429,
      },
      {
        nome: "Echaporã",
        uf: "SP",
        estadoCod: 25,
        cod: 3430,
      },
      {
        nome: "Eldorado",
        uf: "SP",
        estadoCod: 25,
        cod: 3431,
      },
      {
        nome: "Elias Fausto",
        uf: "SP",
        estadoCod: 25,
        cod: 3432,
      },
      {
        nome: "Elisiário",
        uf: "SP",
        estadoCod: 25,
        cod: 3433,
      },
      {
        nome: "Embaúba",
        uf: "SP",
        estadoCod: 25,
        cod: 3434,
      },
      {
        nome: "Embu das Artes",
        uf: "SP",
        estadoCod: 25,
        cod: 3435,
      },
      {
        nome: "Embu-Guaçu",
        uf: "SP",
        estadoCod: 25,
        cod: 3436,
      },
      {
        nome: "Emilianópolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3437,
      },
      {
        nome: "Engenheiro Coelho",
        uf: "SP",
        estadoCod: 25,
        cod: 3438,
      },
      {
        nome: "Espírito Santo do Pinhal",
        uf: "SP",
        estadoCod: 25,
        cod: 3439,
      },
      {
        nome: "Espírito Santo do Turvo",
        uf: "SP",
        estadoCod: 25,
        cod: 3440,
      },
      {
        nome: "Estrela D'Oeste",
        uf: "SP",
        estadoCod: 25,
        cod: 3441,
      },
      {
        nome: "Estrela do Norte",
        uf: "SP",
        estadoCod: 25,
        cod: 3442,
      },
      {
        nome: "Euclides da Cunha Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3443,
      },
      {
        nome: "Fartura",
        uf: "SP",
        estadoCod: 25,
        cod: 3444,
      },
      {
        nome: "Fernandópolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3445,
      },
      {
        nome: "Fernando Prestes",
        uf: "SP",
        estadoCod: 25,
        cod: 3446,
      },
      {
        nome: "Fernão",
        uf: "SP",
        estadoCod: 25,
        cod: 3447,
      },
      {
        nome: "Ferraz de Vasconcelos",
        uf: "SP",
        estadoCod: 25,
        cod: 3448,
      },
      {
        nome: "Flora Rica",
        uf: "SP",
        estadoCod: 25,
        cod: 3449,
      },
      {
        nome: "Floreal",
        uf: "SP",
        estadoCod: 25,
        cod: 3450,
      },
      {
        nome: "Flórida Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3451,
      },
      {
        nome: "Florínia",
        uf: "SP",
        estadoCod: 25,
        cod: 3452,
      },
      {
        nome: "Franca",
        uf: "SP",
        estadoCod: 25,
        cod: 3453,
      },
      {
        nome: "Francisco Morato",
        uf: "SP",
        estadoCod: 25,
        cod: 3454,
      },
      {
        nome: "Franco da Rocha",
        uf: "SP",
        estadoCod: 25,
        cod: 3455,
      },
      {
        nome: "Gabriel Monteiro",
        uf: "SP",
        estadoCod: 25,
        cod: 3456,
      },
      {
        nome: "Gália",
        uf: "SP",
        estadoCod: 25,
        cod: 3457,
      },
      {
        nome: "Garça",
        uf: "SP",
        estadoCod: 25,
        cod: 3458,
      },
      {
        nome: "Gastão Vidigal",
        uf: "SP",
        estadoCod: 25,
        cod: 3459,
      },
      {
        nome: "Gavião Peixoto",
        uf: "SP",
        estadoCod: 25,
        cod: 3460,
      },
      {
        nome: "General Salgado",
        uf: "SP",
        estadoCod: 25,
        cod: 3461,
      },
      {
        nome: "Getulina",
        uf: "SP",
        estadoCod: 25,
        cod: 3462,
      },
      {
        nome: "Glicério",
        uf: "SP",
        estadoCod: 25,
        cod: 3463,
      },
      {
        nome: "Guaiçara",
        uf: "SP",
        estadoCod: 25,
        cod: 3464,
      },
      {
        nome: "Guaimbê",
        uf: "SP",
        estadoCod: 25,
        cod: 3465,
      },
      {
        nome: "Guaíra",
        uf: "SP",
        estadoCod: 25,
        cod: 3466,
      },
      {
        nome: "Guapiaçu",
        uf: "SP",
        estadoCod: 25,
        cod: 3467,
      },
      {
        nome: "Guapiara",
        uf: "SP",
        estadoCod: 25,
        cod: 3468,
      },
      {
        nome: "Guará",
        uf: "SP",
        estadoCod: 25,
        cod: 3469,
      },
      {
        nome: "Guaraçaí",
        uf: "SP",
        estadoCod: 25,
        cod: 3470,
      },
      {
        nome: "Guaraci",
        uf: "SP",
        estadoCod: 25,
        cod: 3471,
      },
      {
        nome: "Guarani D'Oeste",
        uf: "SP",
        estadoCod: 25,
        cod: 3472,
      },
      {
        nome: "Guarantã",
        uf: "SP",
        estadoCod: 25,
        cod: 3473,
      },
      {
        nome: "Guararapes",
        uf: "SP",
        estadoCod: 25,
        cod: 3474,
      },
      {
        nome: "Guararema",
        uf: "SP",
        estadoCod: 25,
        cod: 3475,
      },
      {
        nome: "Guaratinguetá",
        uf: "SP",
        estadoCod: 25,
        cod: 3476,
      },
      {
        nome: "Guareí",
        uf: "SP",
        estadoCod: 25,
        cod: 3477,
      },
      {
        nome: "Guariba",
        uf: "SP",
        estadoCod: 25,
        cod: 3478,
      },
      {
        nome: "Guarujá",
        uf: "SP",
        estadoCod: 25,
        cod: 3479,
      },
      {
        nome: "Guarulhos",
        uf: "SP",
        estadoCod: 25,
        cod: 3480,
      },
      {
        nome: "Guatapará",
        uf: "SP",
        estadoCod: 25,
        cod: 3481,
      },
      {
        nome: "Guzolândia",
        uf: "SP",
        estadoCod: 25,
        cod: 3482,
      },
      {
        nome: "Herculândia",
        uf: "SP",
        estadoCod: 25,
        cod: 3483,
      },
      {
        nome: "Holambra",
        uf: "SP",
        estadoCod: 25,
        cod: 3484,
      },
      {
        nome: "Hortolândia",
        uf: "SP",
        estadoCod: 25,
        cod: 3485,
      },
      {
        nome: "Iacanga",
        uf: "SP",
        estadoCod: 25,
        cod: 3486,
      },
      {
        nome: "Iacri",
        uf: "SP",
        estadoCod: 25,
        cod: 3487,
      },
      {
        nome: "Iaras",
        uf: "SP",
        estadoCod: 25,
        cod: 3488,
      },
      {
        nome: "Ibaté",
        uf: "SP",
        estadoCod: 25,
        cod: 3489,
      },
      {
        nome: "Ibirá",
        uf: "SP",
        estadoCod: 25,
        cod: 3490,
      },
      {
        nome: "Ibirarema",
        uf: "SP",
        estadoCod: 25,
        cod: 3491,
      },
      {
        nome: "Ibitinga",
        uf: "SP",
        estadoCod: 25,
        cod: 3492,
      },
      {
        nome: "Ibiúna",
        uf: "SP",
        estadoCod: 25,
        cod: 3493,
      },
      {
        nome: "Icém",
        uf: "SP",
        estadoCod: 25,
        cod: 3494,
      },
      {
        nome: "Iepê",
        uf: "SP",
        estadoCod: 25,
        cod: 3495,
      },
      {
        nome: "Igaraçu do Tietê",
        uf: "SP",
        estadoCod: 25,
        cod: 3496,
      },
      {
        nome: "Igarapava",
        uf: "SP",
        estadoCod: 25,
        cod: 3497,
      },
      {
        nome: "Igaratá",
        uf: "SP",
        estadoCod: 25,
        cod: 3498,
      },
      {
        nome: "Iguape",
        uf: "SP",
        estadoCod: 25,
        cod: 3499,
      },
      {
        nome: "Ilhabela",
        uf: "SP",
        estadoCod: 25,
        cod: 3500,
      },
      {
        nome: "Ilha Comprida",
        uf: "SP",
        estadoCod: 25,
        cod: 3501,
      },
      {
        nome: "Ilha Solteira",
        uf: "SP",
        estadoCod: 25,
        cod: 3502,
      },
      {
        nome: "Indaiatuba",
        uf: "SP",
        estadoCod: 25,
        cod: 3503,
      },
      {
        nome: "Indiana",
        uf: "SP",
        estadoCod: 25,
        cod: 3504,
      },
      {
        nome: "Indiaporã",
        uf: "SP",
        estadoCod: 25,
        cod: 3505,
      },
      {
        nome: "Inúbia Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3506,
      },
      {
        nome: "Ipaussu",
        uf: "SP",
        estadoCod: 25,
        cod: 3507,
      },
      {
        nome: "Iperó",
        uf: "SP",
        estadoCod: 25,
        cod: 3508,
      },
      {
        nome: "Ipeúna",
        uf: "SP",
        estadoCod: 25,
        cod: 3509,
      },
      {
        nome: "Ipiguá",
        uf: "SP",
        estadoCod: 25,
        cod: 3510,
      },
      {
        nome: "Iporanga",
        uf: "SP",
        estadoCod: 25,
        cod: 3511,
      },
      {
        nome: "Ipuã",
        uf: "SP",
        estadoCod: 25,
        cod: 3512,
      },
      {
        nome: "Iracemápolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3513,
      },
      {
        nome: "Irapuã",
        uf: "SP",
        estadoCod: 25,
        cod: 3514,
      },
      {
        nome: "Irapuru",
        uf: "SP",
        estadoCod: 25,
        cod: 3515,
      },
      {
        nome: "Itaberá",
        uf: "SP",
        estadoCod: 25,
        cod: 3516,
      },
      {
        nome: "Itaí",
        uf: "SP",
        estadoCod: 25,
        cod: 3517,
      },
      {
        nome: "Itajobi",
        uf: "SP",
        estadoCod: 25,
        cod: 3518,
      },
      {
        nome: "Itaju",
        uf: "SP",
        estadoCod: 25,
        cod: 3519,
      },
      {
        nome: "Itanhaém",
        uf: "SP",
        estadoCod: 25,
        cod: 3520,
      },
      {
        nome: "Itaóca",
        uf: "SP",
        estadoCod: 25,
        cod: 3521,
      },
      {
        nome: "Itapecerica da Serra",
        uf: "SP",
        estadoCod: 25,
        cod: 3522,
      },
      {
        nome: "Itapetininga",
        uf: "SP",
        estadoCod: 25,
        cod: 3523,
      },
      {
        nome: "Itapeva",
        uf: "SP",
        estadoCod: 25,
        cod: 3524,
      },
      {
        nome: "Itapevi",
        uf: "SP",
        estadoCod: 25,
        cod: 3525,
      },
      {
        nome: "Itapira",
        uf: "SP",
        estadoCod: 25,
        cod: 3526,
      },
      {
        nome: "Itapirapuã Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3527,
      },
      {
        nome: "Itápolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3528,
      },
      {
        nome: "Itaporanga",
        uf: "SP",
        estadoCod: 25,
        cod: 3529,
      },
      {
        nome: "Itapuí",
        uf: "SP",
        estadoCod: 25,
        cod: 3530,
      },
      {
        nome: "Itapura",
        uf: "SP",
        estadoCod: 25,
        cod: 3531,
      },
      {
        nome: "Itaquaquecetuba",
        uf: "SP",
        estadoCod: 25,
        cod: 3532,
      },
      {
        nome: "Itararé",
        uf: "SP",
        estadoCod: 25,
        cod: 3533,
      },
      {
        nome: "Itariri",
        uf: "SP",
        estadoCod: 25,
        cod: 3534,
      },
      {
        nome: "Itatiba",
        uf: "SP",
        estadoCod: 25,
        cod: 3535,
      },
      {
        nome: "Itatinga",
        uf: "SP",
        estadoCod: 25,
        cod: 3536,
      },
      {
        nome: "Itirapina",
        uf: "SP",
        estadoCod: 25,
        cod: 3537,
      },
      {
        nome: "Itirapuã",
        uf: "SP",
        estadoCod: 25,
        cod: 3538,
      },
      {
        nome: "Itobi",
        uf: "SP",
        estadoCod: 25,
        cod: 3539,
      },
      {
        nome: "Itu",
        uf: "SP",
        estadoCod: 25,
        cod: 3540,
      },
      {
        nome: "Itupeva",
        uf: "SP",
        estadoCod: 25,
        cod: 3541,
      },
      {
        nome: "Ituverava",
        uf: "SP",
        estadoCod: 25,
        cod: 3542,
      },
      {
        nome: "Jaborandi",
        uf: "SP",
        estadoCod: 25,
        cod: 3543,
      },
      {
        nome: "Jaboticabal",
        uf: "SP",
        estadoCod: 25,
        cod: 3544,
      },
      {
        nome: "Jacareí",
        uf: "SP",
        estadoCod: 25,
        cod: 3545,
      },
      {
        nome: "Jaci",
        uf: "SP",
        estadoCod: 25,
        cod: 3546,
      },
      {
        nome: "Jacupiranga",
        uf: "SP",
        estadoCod: 25,
        cod: 3547,
      },
      {
        nome: "Jaguariúna",
        uf: "SP",
        estadoCod: 25,
        cod: 3548,
      },
      {
        nome: "Jales",
        uf: "SP",
        estadoCod: 25,
        cod: 3549,
      },
      {
        nome: "Jambeiro",
        uf: "SP",
        estadoCod: 25,
        cod: 3550,
      },
      {
        nome: "Jandira",
        uf: "SP",
        estadoCod: 25,
        cod: 3551,
      },
      {
        nome: "Jardinópolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3552,
      },
      {
        nome: "Jarinu",
        uf: "SP",
        estadoCod: 25,
        cod: 3553,
      },
      {
        nome: "Jaú",
        uf: "SP",
        estadoCod: 25,
        cod: 3554,
      },
      {
        nome: "Jeriquara",
        uf: "SP",
        estadoCod: 25,
        cod: 3555,
      },
      {
        nome: "Joanópolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3556,
      },
      {
        nome: "João Ramalho",
        uf: "SP",
        estadoCod: 25,
        cod: 3557,
      },
      {
        nome: "José Bonifácio",
        uf: "SP",
        estadoCod: 25,
        cod: 3558,
      },
      {
        nome: "Júlio Mesquita",
        uf: "SP",
        estadoCod: 25,
        cod: 3559,
      },
      {
        nome: "Jumirim",
        uf: "SP",
        estadoCod: 25,
        cod: 3560,
      },
      {
        nome: "Jundiaí",
        uf: "SP",
        estadoCod: 25,
        cod: 3561,
      },
      {
        nome: "Junqueirópolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3562,
      },
      {
        nome: "Juquiá",
        uf: "SP",
        estadoCod: 25,
        cod: 3563,
      },
      {
        nome: "Juquitiba",
        uf: "SP",
        estadoCod: 25,
        cod: 3564,
      },
      {
        nome: "Lagoinha",
        uf: "SP",
        estadoCod: 25,
        cod: 3565,
      },
      {
        nome: "Laranjal Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3566,
      },
      {
        nome: "Lavínia",
        uf: "SP",
        estadoCod: 25,
        cod: 3567,
      },
      {
        nome: "Lavrinhas",
        uf: "SP",
        estadoCod: 25,
        cod: 3568,
      },
      {
        nome: "Leme",
        uf: "SP",
        estadoCod: 25,
        cod: 3569,
      },
      {
        nome: "Lençóis Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3570,
      },
      {
        nome: "Limeira",
        uf: "SP",
        estadoCod: 25,
        cod: 3571,
      },
      {
        nome: "Lindóia",
        uf: "SP",
        estadoCod: 25,
        cod: 3572,
      },
      {
        nome: "Lins",
        uf: "SP",
        estadoCod: 25,
        cod: 3573,
      },
      {
        nome: "Lorena",
        uf: "SP",
        estadoCod: 25,
        cod: 3574,
      },
      {
        nome: "Lourdes",
        uf: "SP",
        estadoCod: 25,
        cod: 3575,
      },
      {
        nome: "Louveira",
        uf: "SP",
        estadoCod: 25,
        cod: 3576,
      },
      {
        nome: "Lucélia",
        uf: "SP",
        estadoCod: 25,
        cod: 3577,
      },
      {
        nome: "Lucianópolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3578,
      },
      {
        nome: "Luís Antônio",
        uf: "SP",
        estadoCod: 25,
        cod: 3579,
      },
      {
        nome: "Luiziânia",
        uf: "SP",
        estadoCod: 25,
        cod: 3580,
      },
      {
        nome: "Lupércio",
        uf: "SP",
        estadoCod: 25,
        cod: 3581,
      },
      {
        nome: "Lutécia",
        uf: "SP",
        estadoCod: 25,
        cod: 3582,
      },
      {
        nome: "Macatuba",
        uf: "SP",
        estadoCod: 25,
        cod: 3583,
      },
      {
        nome: "Macaubal",
        uf: "SP",
        estadoCod: 25,
        cod: 3584,
      },
      {
        nome: "Macedônia",
        uf: "SP",
        estadoCod: 25,
        cod: 3585,
      },
      {
        nome: "Magda",
        uf: "SP",
        estadoCod: 25,
        cod: 3586,
      },
      {
        nome: "Mairinque",
        uf: "SP",
        estadoCod: 25,
        cod: 3587,
      },
      {
        nome: "Mairiporã",
        uf: "SP",
        estadoCod: 25,
        cod: 3588,
      },
      {
        nome: "Manduri",
        uf: "SP",
        estadoCod: 25,
        cod: 3589,
      },
      {
        nome: "Marabá Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3590,
      },
      {
        nome: "Maracaí",
        uf: "SP",
        estadoCod: 25,
        cod: 3591,
      },
      {
        nome: "Marapoama",
        uf: "SP",
        estadoCod: 25,
        cod: 3592,
      },
      {
        nome: "Mariápolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3593,
      },
      {
        nome: "Marília",
        uf: "SP",
        estadoCod: 25,
        cod: 3594,
      },
      {
        nome: "Marinópolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3595,
      },
      {
        nome: "Martinópolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3596,
      },
      {
        nome: "Matão",
        uf: "SP",
        estadoCod: 25,
        cod: 3597,
      },
      {
        nome: "Mauá",
        uf: "SP",
        estadoCod: 25,
        cod: 3598,
      },
      {
        nome: "Mendonça",
        uf: "SP",
        estadoCod: 25,
        cod: 3599,
      },
      {
        nome: "Meridiano",
        uf: "SP",
        estadoCod: 25,
        cod: 3600,
      },
      {
        nome: "Mesópolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3601,
      },
      {
        nome: "Miguelópolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3602,
      },
      {
        nome: "Mineiros do Tietê",
        uf: "SP",
        estadoCod: 25,
        cod: 3603,
      },
      {
        nome: "Miracatu",
        uf: "SP",
        estadoCod: 25,
        cod: 3604,
      },
      {
        nome: "Mira Estrela",
        uf: "SP",
        estadoCod: 25,
        cod: 3605,
      },
      {
        nome: "Mirandópolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3606,
      },
      {
        nome: "Mirante do Paranapanema",
        uf: "SP",
        estadoCod: 25,
        cod: 3607,
      },
      {
        nome: "Mirassol",
        uf: "SP",
        estadoCod: 25,
        cod: 3608,
      },
      {
        nome: "Mirassolândia",
        uf: "SP",
        estadoCod: 25,
        cod: 3609,
      },
      {
        nome: "Mococa",
        uf: "SP",
        estadoCod: 25,
        cod: 3610,
      },
      {
        nome: "Mogi das Cruzes",
        uf: "SP",
        estadoCod: 25,
        cod: 3611,
      },
      {
        nome: "Mogi Guaçu",
        uf: "SP",
        estadoCod: 25,
        cod: 3612,
      },
      {
        nome: "Moji Mirim",
        uf: "SP",
        estadoCod: 25,
        cod: 3613,
      },
      {
        nome: "Mombuca",
        uf: "SP",
        estadoCod: 25,
        cod: 3614,
      },
      {
        nome: "Monções",
        uf: "SP",
        estadoCod: 25,
        cod: 3615,
      },
      {
        nome: "Mongaguá",
        uf: "SP",
        estadoCod: 25,
        cod: 3616,
      },
      {
        nome: "Monte Alegre do Sul",
        uf: "SP",
        estadoCod: 25,
        cod: 3617,
      },
      {
        nome: "Monte Alto",
        uf: "SP",
        estadoCod: 25,
        cod: 3618,
      },
      {
        nome: "Monte Aprazível",
        uf: "SP",
        estadoCod: 25,
        cod: 3619,
      },
      {
        nome: "Monte Azul Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3620,
      },
      {
        nome: "Monte Castelo",
        uf: "SP",
        estadoCod: 25,
        cod: 3621,
      },
      {
        nome: "Monteiro Lobato",
        uf: "SP",
        estadoCod: 25,
        cod: 3622,
      },
      {
        nome: "Monte Mor",
        uf: "SP",
        estadoCod: 25,
        cod: 3623,
      },
      {
        nome: "Morro Agudo",
        uf: "SP",
        estadoCod: 25,
        cod: 3624,
      },
      {
        nome: "Morungaba",
        uf: "SP",
        estadoCod: 25,
        cod: 3625,
      },
      {
        nome: "Motuca",
        uf: "SP",
        estadoCod: 25,
        cod: 3626,
      },
      {
        nome: "Murutinga do Sul",
        uf: "SP",
        estadoCod: 25,
        cod: 3627,
      },
      {
        nome: "Nantes",
        uf: "SP",
        estadoCod: 25,
        cod: 3628,
      },
      {
        nome: "Narandiba",
        uf: "SP",
        estadoCod: 25,
        cod: 3629,
      },
      {
        nome: "Natividade da Serra",
        uf: "SP",
        estadoCod: 25,
        cod: 3630,
      },
      {
        nome: "Nazaré Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3631,
      },
      {
        nome: "Neves Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3632,
      },
      {
        nome: "Nhandeara",
        uf: "SP",
        estadoCod: 25,
        cod: 3633,
      },
      {
        nome: "Nipoã",
        uf: "SP",
        estadoCod: 25,
        cod: 3634,
      },
      {
        nome: "Nova Aliança",
        uf: "SP",
        estadoCod: 25,
        cod: 3635,
      },
      {
        nome: "Nova Campina",
        uf: "SP",
        estadoCod: 25,
        cod: 3636,
      },
      {
        nome: "Nova Canaã Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3637,
      },
      {
        nome: "Nova Castilho",
        uf: "SP",
        estadoCod: 25,
        cod: 3638,
      },
      {
        nome: "Nova Europa",
        uf: "SP",
        estadoCod: 25,
        cod: 3639,
      },
      {
        nome: "Nova Granada",
        uf: "SP",
        estadoCod: 25,
        cod: 3640,
      },
      {
        nome: "Nova Guataporanga",
        uf: "SP",
        estadoCod: 25,
        cod: 3641,
      },
      {
        nome: "Nova Independência",
        uf: "SP",
        estadoCod: 25,
        cod: 3642,
      },
      {
        nome: "Novais",
        uf: "SP",
        estadoCod: 25,
        cod: 3643,
      },
      {
        nome: "Nova Luzitânia",
        uf: "SP",
        estadoCod: 25,
        cod: 3644,
      },
      {
        nome: "Nova Odessa",
        uf: "SP",
        estadoCod: 25,
        cod: 3645,
      },
      {
        nome: "Novo Horizonte",
        uf: "SP",
        estadoCod: 25,
        cod: 3646,
      },
      {
        nome: "Nuporanga",
        uf: "SP",
        estadoCod: 25,
        cod: 3647,
      },
      {
        nome: "Ocauçu",
        uf: "SP",
        estadoCod: 25,
        cod: 3648,
      },
      {
        nome: "Óleo",
        uf: "SP",
        estadoCod: 25,
        cod: 3649,
      },
      {
        nome: "Olímpia",
        uf: "SP",
        estadoCod: 25,
        cod: 3650,
      },
      {
        nome: "Onda Verde",
        uf: "SP",
        estadoCod: 25,
        cod: 3651,
      },
      {
        nome: "Oriente",
        uf: "SP",
        estadoCod: 25,
        cod: 3652,
      },
      {
        nome: "Orindiúva",
        uf: "SP",
        estadoCod: 25,
        cod: 3653,
      },
      {
        nome: "Orlândia",
        uf: "SP",
        estadoCod: 25,
        cod: 3654,
      },
      {
        nome: "Osasco",
        uf: "SP",
        estadoCod: 25,
        cod: 3655,
      },
      {
        nome: "Oscar Bressane",
        uf: "SP",
        estadoCod: 25,
        cod: 3656,
      },
      {
        nome: "Osvaldo Cruz",
        uf: "SP",
        estadoCod: 25,
        cod: 3657,
      },
      {
        nome: "Ourinhos",
        uf: "SP",
        estadoCod: 25,
        cod: 3658,
      },
      {
        nome: "Ouroeste",
        uf: "SP",
        estadoCod: 25,
        cod: 3659,
      },
      {
        nome: "Ouro Verde",
        uf: "SP",
        estadoCod: 25,
        cod: 3660,
      },
      {
        nome: "Pacaembu",
        uf: "SP",
        estadoCod: 25,
        cod: 3661,
      },
      {
        nome: "Palestina",
        uf: "SP",
        estadoCod: 25,
        cod: 3662,
      },
      {
        nome: "Palmares Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3663,
      },
      {
        nome: "Palmeira D'Oeste",
        uf: "SP",
        estadoCod: 25,
        cod: 3664,
      },
      {
        nome: "Palmital",
        uf: "SP",
        estadoCod: 25,
        cod: 3665,
      },
      {
        nome: "Panorama",
        uf: "SP",
        estadoCod: 25,
        cod: 3666,
      },
      {
        nome: "Paraguaçu Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3667,
      },
      {
        nome: "Paraibuna",
        uf: "SP",
        estadoCod: 25,
        cod: 3668,
      },
      {
        nome: "Paraíso",
        uf: "SP",
        estadoCod: 25,
        cod: 3669,
      },
      {
        nome: "Paranapanema",
        uf: "SP",
        estadoCod: 25,
        cod: 3670,
      },
      {
        nome: "Paranapuã",
        uf: "SP",
        estadoCod: 25,
        cod: 3671,
      },
      {
        nome: "Parapuã",
        uf: "SP",
        estadoCod: 25,
        cod: 3672,
      },
      {
        nome: "Pardinho",
        uf: "SP",
        estadoCod: 25,
        cod: 3673,
      },
      {
        nome: "Pariquera-Açu",
        uf: "SP",
        estadoCod: 25,
        cod: 3674,
      },
      {
        nome: "Parisi",
        uf: "SP",
        estadoCod: 25,
        cod: 3675,
      },
      {
        nome: "Patrocínio Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3676,
      },
      {
        nome: "Paulicéia",
        uf: "SP",
        estadoCod: 25,
        cod: 3677,
      },
      {
        nome: "Paulínia",
        uf: "SP",
        estadoCod: 25,
        cod: 3678,
      },
      {
        nome: "Paulistânia",
        uf: "SP",
        estadoCod: 25,
        cod: 3679,
      },
      {
        nome: "Paulo de Faria",
        uf: "SP",
        estadoCod: 25,
        cod: 3680,
      },
      {
        nome: "Pederneiras",
        uf: "SP",
        estadoCod: 25,
        cod: 3681,
      },
      {
        nome: "Pedra Bela",
        uf: "SP",
        estadoCod: 25,
        cod: 3682,
      },
      {
        nome: "Pedranópolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3683,
      },
      {
        nome: "Pedregulho",
        uf: "SP",
        estadoCod: 25,
        cod: 3684,
      },
      {
        nome: "Pedreira",
        uf: "SP",
        estadoCod: 25,
        cod: 3685,
      },
      {
        nome: "Pedrinhas Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3686,
      },
      {
        nome: "Pedro de Toledo",
        uf: "SP",
        estadoCod: 25,
        cod: 3687,
      },
      {
        nome: "Penápolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3688,
      },
      {
        nome: "Pereira Barreto",
        uf: "SP",
        estadoCod: 25,
        cod: 3689,
      },
      {
        nome: "Pereiras",
        uf: "SP",
        estadoCod: 25,
        cod: 3690,
      },
      {
        nome: "Peruíbe",
        uf: "SP",
        estadoCod: 25,
        cod: 3691,
      },
      {
        nome: "Piacatu",
        uf: "SP",
        estadoCod: 25,
        cod: 3692,
      },
      {
        nome: "Piedade",
        uf: "SP",
        estadoCod: 25,
        cod: 3693,
      },
      {
        nome: "Pilar do Sul",
        uf: "SP",
        estadoCod: 25,
        cod: 3694,
      },
      {
        nome: "Pindamonhangaba",
        uf: "SP",
        estadoCod: 25,
        cod: 3695,
      },
      {
        nome: "Pindorama",
        uf: "SP",
        estadoCod: 25,
        cod: 3696,
      },
      {
        nome: "Pinhalzinho",
        uf: "SP",
        estadoCod: 25,
        cod: 3697,
      },
      {
        nome: "Piquerobi",
        uf: "SP",
        estadoCod: 25,
        cod: 3698,
      },
      {
        nome: "Piquete",
        uf: "SP",
        estadoCod: 25,
        cod: 3699,
      },
      {
        nome: "Piracaia",
        uf: "SP",
        estadoCod: 25,
        cod: 3700,
      },
      {
        nome: "Piracicaba",
        uf: "SP",
        estadoCod: 25,
        cod: 3701,
      },
      {
        nome: "Piraju",
        uf: "SP",
        estadoCod: 25,
        cod: 3702,
      },
      {
        nome: "Pirajuí",
        uf: "SP",
        estadoCod: 25,
        cod: 3703,
      },
      {
        nome: "Pirangi",
        uf: "SP",
        estadoCod: 25,
        cod: 3704,
      },
      {
        nome: "Pirapora do Bom Jesus",
        uf: "SP",
        estadoCod: 25,
        cod: 3705,
      },
      {
        nome: "Pirapozinho",
        uf: "SP",
        estadoCod: 25,
        cod: 3706,
      },
      {
        nome: "Pirassununga",
        uf: "SP",
        estadoCod: 25,
        cod: 3707,
      },
      {
        nome: "Piratininga",
        uf: "SP",
        estadoCod: 25,
        cod: 3708,
      },
      {
        nome: "Pitangueiras",
        uf: "SP",
        estadoCod: 25,
        cod: 3709,
      },
      {
        nome: "Planalto",
        uf: "SP",
        estadoCod: 25,
        cod: 3710,
      },
      {
        nome: "Platina",
        uf: "SP",
        estadoCod: 25,
        cod: 3711,
      },
      {
        nome: "Poá",
        uf: "SP",
        estadoCod: 25,
        cod: 3712,
      },
      {
        nome: "Poloni",
        uf: "SP",
        estadoCod: 25,
        cod: 3713,
      },
      {
        nome: "Pompéia",
        uf: "SP",
        estadoCod: 25,
        cod: 3714,
      },
      {
        nome: "Pongaí",
        uf: "SP",
        estadoCod: 25,
        cod: 3715,
      },
      {
        nome: "Pontal",
        uf: "SP",
        estadoCod: 25,
        cod: 3716,
      },
      {
        nome: "Pontalinda",
        uf: "SP",
        estadoCod: 25,
        cod: 3717,
      },
      {
        nome: "Pontes Gestal",
        uf: "SP",
        estadoCod: 25,
        cod: 3718,
      },
      {
        nome: "Populina",
        uf: "SP",
        estadoCod: 25,
        cod: 3719,
      },
      {
        nome: "Porangaba",
        uf: "SP",
        estadoCod: 25,
        cod: 3720,
      },
      {
        nome: "Porto Feliz",
        uf: "SP",
        estadoCod: 25,
        cod: 3721,
      },
      {
        nome: "Porto Ferreira",
        uf: "SP",
        estadoCod: 25,
        cod: 3722,
      },
      {
        nome: "Potim",
        uf: "SP",
        estadoCod: 25,
        cod: 3723,
      },
      {
        nome: "Potirendaba",
        uf: "SP",
        estadoCod: 25,
        cod: 3724,
      },
      {
        nome: "Pracinha",
        uf: "SP",
        estadoCod: 25,
        cod: 3725,
      },
      {
        nome: "Pradópolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3726,
      },
      {
        nome: "Praia Grande",
        uf: "SP",
        estadoCod: 25,
        cod: 3727,
      },
      {
        nome: "Pratânia",
        uf: "SP",
        estadoCod: 25,
        cod: 3728,
      },
      {
        nome: "Presidente Alves",
        uf: "SP",
        estadoCod: 25,
        cod: 3729,
      },
      {
        nome: "Presidente Bernardes",
        uf: "SP",
        estadoCod: 25,
        cod: 3730,
      },
      {
        nome: "Presidente Epitácio",
        uf: "SP",
        estadoCod: 25,
        cod: 3731,
      },
      {
        nome: "Presidente Prudente",
        uf: "SP",
        estadoCod: 25,
        cod: 3732,
      },
      {
        nome: "Presidente Venceslau",
        uf: "SP",
        estadoCod: 25,
        cod: 3733,
      },
      {
        nome: "Promissão",
        uf: "SP",
        estadoCod: 25,
        cod: 3734,
      },
      {
        nome: "Quadra",
        uf: "SP",
        estadoCod: 25,
        cod: 3735,
      },
      {
        nome: "Quatá",
        uf: "SP",
        estadoCod: 25,
        cod: 3736,
      },
      {
        nome: "Queiroz",
        uf: "SP",
        estadoCod: 25,
        cod: 3737,
      },
      {
        nome: "Queluz",
        uf: "SP",
        estadoCod: 25,
        cod: 3738,
      },
      {
        nome: "Quintana",
        uf: "SP",
        estadoCod: 25,
        cod: 3739,
      },
      {
        nome: "Rafard",
        uf: "SP",
        estadoCod: 25,
        cod: 3740,
      },
      {
        nome: "Rancharia",
        uf: "SP",
        estadoCod: 25,
        cod: 3741,
      },
      {
        nome: "Redenção da Serra",
        uf: "SP",
        estadoCod: 25,
        cod: 3742,
      },
      {
        nome: "Regente Feijó",
        uf: "SP",
        estadoCod: 25,
        cod: 3743,
      },
      {
        nome: "Reginópolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3744,
      },
      {
        nome: "Registro",
        uf: "SP",
        estadoCod: 25,
        cod: 3745,
      },
      {
        nome: "Restinga",
        uf: "SP",
        estadoCod: 25,
        cod: 3746,
      },
      {
        nome: "Ribeira",
        uf: "SP",
        estadoCod: 25,
        cod: 3747,
      },
      {
        nome: "Ribeirão Bonito",
        uf: "SP",
        estadoCod: 25,
        cod: 3748,
      },
      {
        nome: "Ribeirão Branco",
        uf: "SP",
        estadoCod: 25,
        cod: 3749,
      },
      {
        nome: "Ribeirão Corrente",
        uf: "SP",
        estadoCod: 25,
        cod: 3750,
      },
      {
        nome: "Ribeirão do Sul",
        uf: "SP",
        estadoCod: 25,
        cod: 3751,
      },
      {
        nome: "Ribeirão dos Índios",
        uf: "SP",
        estadoCod: 25,
        cod: 3752,
      },
      {
        nome: "Ribeirão Grande",
        uf: "SP",
        estadoCod: 25,
        cod: 3753,
      },
      {
        nome: "Ribeirão Pires",
        uf: "SP",
        estadoCod: 25,
        cod: 3754,
      },
      {
        nome: "Ribeirão Preto",
        uf: "SP",
        estadoCod: 25,
        cod: 3755,
      },
      {
        nome: "Riversul",
        uf: "SP",
        estadoCod: 25,
        cod: 3756,
      },
      {
        nome: "Rifaina",
        uf: "SP",
        estadoCod: 25,
        cod: 3757,
      },
      {
        nome: "Rincão",
        uf: "SP",
        estadoCod: 25,
        cod: 3758,
      },
      {
        nome: "Rinópolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3759,
      },
      {
        nome: "Rio Claro",
        uf: "SP",
        estadoCod: 25,
        cod: 3760,
      },
      {
        nome: "Rio das Pedras",
        uf: "SP",
        estadoCod: 25,
        cod: 3761,
      },
      {
        nome: "Rio Grande da Serra",
        uf: "SP",
        estadoCod: 25,
        cod: 3762,
      },
      {
        nome: "Riolândia",
        uf: "SP",
        estadoCod: 25,
        cod: 3763,
      },
      {
        nome: "Rosana",
        uf: "SP",
        estadoCod: 25,
        cod: 3764,
      },
      {
        nome: "Roseira",
        uf: "SP",
        estadoCod: 25,
        cod: 3765,
      },
      {
        nome: "Rubiácea",
        uf: "SP",
        estadoCod: 25,
        cod: 3766,
      },
      {
        nome: "Rubinéia",
        uf: "SP",
        estadoCod: 25,
        cod: 3767,
      },
      {
        nome: "Sabino",
        uf: "SP",
        estadoCod: 25,
        cod: 3768,
      },
      {
        nome: "Sagres",
        uf: "SP",
        estadoCod: 25,
        cod: 3769,
      },
      {
        nome: "Sales",
        uf: "SP",
        estadoCod: 25,
        cod: 3770,
      },
      {
        nome: "Sales Oliveira",
        uf: "SP",
        estadoCod: 25,
        cod: 3771,
      },
      {
        nome: "Salesópolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3772,
      },
      {
        nome: "Salmourão",
        uf: "SP",
        estadoCod: 25,
        cod: 3773,
      },
      {
        nome: "Saltinho",
        uf: "SP",
        estadoCod: 25,
        cod: 3774,
      },
      {
        nome: "Salto",
        uf: "SP",
        estadoCod: 25,
        cod: 3775,
      },
      {
        nome: "Salto de Pirapora",
        uf: "SP",
        estadoCod: 25,
        cod: 3776,
      },
      {
        nome: "Salto Grande",
        uf: "SP",
        estadoCod: 25,
        cod: 3777,
      },
      {
        nome: "Sandovalina",
        uf: "SP",
        estadoCod: 25,
        cod: 3778,
      },
      {
        nome: "Santa Adélia",
        uf: "SP",
        estadoCod: 25,
        cod: 3779,
      },
      {
        nome: "Santa Albertina",
        uf: "SP",
        estadoCod: 25,
        cod: 3780,
      },
      {
        nome: "Santa Bárbara D'Oeste",
        uf: "SP",
        estadoCod: 25,
        cod: 3781,
      },
      {
        nome: "Santa Branca",
        uf: "SP",
        estadoCod: 25,
        cod: 3782,
      },
      {
        nome: "Santa Clara D'Oeste",
        uf: "SP",
        estadoCod: 25,
        cod: 3783,
      },
      {
        nome: "Santa Cruz da Conceição",
        uf: "SP",
        estadoCod: 25,
        cod: 3784,
      },
      {
        nome: "Santa Cruz da Esperança",
        uf: "SP",
        estadoCod: 25,
        cod: 3785,
      },
      {
        nome: "Santa Cruz das Palmeiras",
        uf: "SP",
        estadoCod: 25,
        cod: 3786,
      },
      {
        nome: "Santa Cruz do Rio Pardo",
        uf: "SP",
        estadoCod: 25,
        cod: 3787,
      },
      {
        nome: "Santa Ernestina",
        uf: "SP",
        estadoCod: 25,
        cod: 3788,
      },
      {
        nome: "Santa Fé do Sul",
        uf: "SP",
        estadoCod: 25,
        cod: 3789,
      },
      {
        nome: "Santa Gertrudes",
        uf: "SP",
        estadoCod: 25,
        cod: 3790,
      },
      {
        nome: "Santa Isabel",
        uf: "SP",
        estadoCod: 25,
        cod: 3791,
      },
      {
        nome: "Santa Lúcia",
        uf: "SP",
        estadoCod: 25,
        cod: 3792,
      },
      {
        nome: "Santa Maria da Serra",
        uf: "SP",
        estadoCod: 25,
        cod: 3793,
      },
      {
        nome: "Santa Mercedes",
        uf: "SP",
        estadoCod: 25,
        cod: 3794,
      },
      {
        nome: "Santana da Ponte Pensa",
        uf: "SP",
        estadoCod: 25,
        cod: 3795,
      },
      {
        nome: "Santana de Parnaíba",
        uf: "SP",
        estadoCod: 25,
        cod: 3796,
      },
      {
        nome: "Santa Rita D'Oeste",
        uf: "SP",
        estadoCod: 25,
        cod: 3797,
      },
      {
        nome: "Santa Rita do Passa Quatro",
        uf: "SP",
        estadoCod: 25,
        cod: 3798,
      },
      {
        nome: "Santa Rosa de Viterbo",
        uf: "SP",
        estadoCod: 25,
        cod: 3799,
      },
      {
        nome: "Santa Salete",
        uf: "SP",
        estadoCod: 25,
        cod: 3800,
      },
      {
        nome: "Santo Anastácio",
        uf: "SP",
        estadoCod: 25,
        cod: 3801,
      },
      {
        nome: "Santo André",
        uf: "SP",
        estadoCod: 25,
        cod: 3802,
      },
      {
        nome: "Santo Antônio da Alegria",
        uf: "SP",
        estadoCod: 25,
        cod: 3803,
      },
      {
        nome: "Santo Antônio de Posse",
        uf: "SP",
        estadoCod: 25,
        cod: 3804,
      },
      {
        nome: "Santo Antônio do Aracanguá",
        uf: "SP",
        estadoCod: 25,
        cod: 3805,
      },
      {
        nome: "Santo Antônio do Jardim",
        uf: "SP",
        estadoCod: 25,
        cod: 3806,
      },
      {
        nome: "Santo Antônio do Pinhal",
        uf: "SP",
        estadoCod: 25,
        cod: 3807,
      },
      {
        nome: "Santo Expedito",
        uf: "SP",
        estadoCod: 25,
        cod: 3808,
      },
      {
        nome: "Santópolis do Aguapeí",
        uf: "SP",
        estadoCod: 25,
        cod: 3809,
      },
      {
        nome: "Santos",
        uf: "SP",
        estadoCod: 25,
        cod: 3810,
      },
      {
        nome: "São Bento do Sapucaí",
        uf: "SP",
        estadoCod: 25,
        cod: 3811,
      },
      {
        nome: "São Bernardo do Campo",
        uf: "SP",
        estadoCod: 25,
        cod: 3812,
      },
      {
        nome: "São Caetano do Sul",
        uf: "SP",
        estadoCod: 25,
        cod: 3813,
      },
      {
        nome: "São Carlos",
        uf: "SP",
        estadoCod: 25,
        cod: 3814,
      },
      {
        nome: "São Francisco",
        uf: "SP",
        estadoCod: 25,
        cod: 3815,
      },
      {
        nome: "São João da Boa Vista",
        uf: "SP",
        estadoCod: 25,
        cod: 3816,
      },
      {
        nome: "São João das Duas Pontes",
        uf: "SP",
        estadoCod: 25,
        cod: 3817,
      },
      {
        nome: "São João de Iracema",
        uf: "SP",
        estadoCod: 25,
        cod: 3818,
      },
      {
        nome: "São João do Pau D'Alho",
        uf: "SP",
        estadoCod: 25,
        cod: 3819,
      },
      {
        nome: "São Joaquim da Barra",
        uf: "SP",
        estadoCod: 25,
        cod: 3820,
      },
      {
        nome: "São José da Bela Vista",
        uf: "SP",
        estadoCod: 25,
        cod: 3821,
      },
      {
        nome: "São José do Barreiro",
        uf: "SP",
        estadoCod: 25,
        cod: 3822,
      },
      {
        nome: "São José do Rio Pardo",
        uf: "SP",
        estadoCod: 25,
        cod: 3823,
      },
      {
        nome: "São José do Rio Preto",
        uf: "SP",
        estadoCod: 25,
        cod: 3824,
      },
      {
        nome: "São José dos Campos",
        uf: "SP",
        estadoCod: 25,
        cod: 3825,
      },
      {
        nome: "São Lourenço da Serra",
        uf: "SP",
        estadoCod: 25,
        cod: 3826,
      },
      {
        nome: "São Luís do Paraitinga",
        uf: "SP",
        estadoCod: 25,
        cod: 3827,
      },
      {
        nome: "São Manuel",
        uf: "SP",
        estadoCod: 25,
        cod: 3828,
      },
      {
        nome: "São Miguel Arcanjo",
        uf: "SP",
        estadoCod: 25,
        cod: 3829,
      },
      {
        nome: "São Paulo",
        uf: "SP",
        estadoCod: 25,
        cod: 3830,
      },
      {
        nome: "São Pedro",
        uf: "SP",
        estadoCod: 25,
        cod: 3831,
      },
      {
        nome: "São Pedro do Turvo",
        uf: "SP",
        estadoCod: 25,
        cod: 3832,
      },
      {
        nome: "São Roque",
        uf: "SP",
        estadoCod: 25,
        cod: 3833,
      },
      {
        nome: "São Sebastião",
        uf: "SP",
        estadoCod: 25,
        cod: 3834,
      },
      {
        nome: "São Sebastião da Grama",
        uf: "SP",
        estadoCod: 25,
        cod: 3835,
      },
      {
        nome: "São Simão",
        uf: "SP",
        estadoCod: 25,
        cod: 3836,
      },
      {
        nome: "São Vicente",
        uf: "SP",
        estadoCod: 25,
        cod: 3837,
      },
      {
        nome: "Sarapuí",
        uf: "SP",
        estadoCod: 25,
        cod: 3838,
      },
      {
        nome: "Sarutaiá",
        uf: "SP",
        estadoCod: 25,
        cod: 3839,
      },
      {
        nome: "Sebastianópolis do Sul",
        uf: "SP",
        estadoCod: 25,
        cod: 3840,
      },
      {
        nome: "Serra Azul",
        uf: "SP",
        estadoCod: 25,
        cod: 3841,
      },
      {
        nome: "Serrana",
        uf: "SP",
        estadoCod: 25,
        cod: 3842,
      },
      {
        nome: "Serra Negra",
        uf: "SP",
        estadoCod: 25,
        cod: 3843,
      },
      {
        nome: "Sertãozinho",
        uf: "SP",
        estadoCod: 25,
        cod: 3844,
      },
      {
        nome: "Sete Barras",
        uf: "SP",
        estadoCod: 25,
        cod: 3845,
      },
      {
        nome: "Severínia",
        uf: "SP",
        estadoCod: 25,
        cod: 3846,
      },
      {
        nome: "Silveiras",
        uf: "SP",
        estadoCod: 25,
        cod: 3847,
      },
      {
        nome: "Socorro",
        uf: "SP",
        estadoCod: 25,
        cod: 3848,
      },
      {
        nome: "Sorocaba",
        uf: "SP",
        estadoCod: 25,
        cod: 3849,
      },
      {
        nome: "Sud Mennucci",
        uf: "SP",
        estadoCod: 25,
        cod: 3850,
      },
      {
        nome: "Sumaré",
        uf: "SP",
        estadoCod: 25,
        cod: 3851,
      },
      {
        nome: "Suzano",
        uf: "SP",
        estadoCod: 25,
        cod: 3852,
      },
      {
        nome: "Suzanápolis",
        uf: "SP",
        estadoCod: 25,
        cod: 3853,
      },
      {
        nome: "Tabapuã",
        uf: "SP",
        estadoCod: 25,
        cod: 3854,
      },
      {
        nome: "Tabatinga",
        uf: "SP",
        estadoCod: 25,
        cod: 3855,
      },
      {
        nome: "Taboão da Serra",
        uf: "SP",
        estadoCod: 25,
        cod: 3856,
      },
      {
        nome: "Taciba",
        uf: "SP",
        estadoCod: 25,
        cod: 3857,
      },
      {
        nome: "Taguaí",
        uf: "SP",
        estadoCod: 25,
        cod: 3858,
      },
      {
        nome: "Taiaçu",
        uf: "SP",
        estadoCod: 25,
        cod: 3859,
      },
      {
        nome: "Taiúva",
        uf: "SP",
        estadoCod: 25,
        cod: 3860,
      },
      {
        nome: "Tambaú",
        uf: "SP",
        estadoCod: 25,
        cod: 3861,
      },
      {
        nome: "Tanabi",
        uf: "SP",
        estadoCod: 25,
        cod: 3862,
      },
      {
        nome: "Tapiraí",
        uf: "SP",
        estadoCod: 25,
        cod: 3863,
      },
      {
        nome: "Tapiratiba",
        uf: "SP",
        estadoCod: 25,
        cod: 3864,
      },
      {
        nome: "Taquaral",
        uf: "SP",
        estadoCod: 25,
        cod: 3865,
      },
      {
        nome: "Taquaritinga",
        uf: "SP",
        estadoCod: 25,
        cod: 3866,
      },
      {
        nome: "Taquarituba",
        uf: "SP",
        estadoCod: 25,
        cod: 3867,
      },
      {
        nome: "Taquarivaí",
        uf: "SP",
        estadoCod: 25,
        cod: 3868,
      },
      {
        nome: "Tarabai",
        uf: "SP",
        estadoCod: 25,
        cod: 3869,
      },
      {
        nome: "Tarumã",
        uf: "SP",
        estadoCod: 25,
        cod: 3870,
      },
      {
        nome: "Tatuí",
        uf: "SP",
        estadoCod: 25,
        cod: 3871,
      },
      {
        nome: "Taubaté",
        uf: "SP",
        estadoCod: 25,
        cod: 3872,
      },
      {
        nome: "Tejupá",
        uf: "SP",
        estadoCod: 25,
        cod: 3873,
      },
      {
        nome: "Teodoro Sampaio",
        uf: "SP",
        estadoCod: 25,
        cod: 3874,
      },
      {
        nome: "Terra Roxa",
        uf: "SP",
        estadoCod: 25,
        cod: 3875,
      },
      {
        nome: "Tietê",
        uf: "SP",
        estadoCod: 25,
        cod: 3876,
      },
      {
        nome: "Timburi",
        uf: "SP",
        estadoCod: 25,
        cod: 3877,
      },
      {
        nome: "Torre de Pedra",
        uf: "SP",
        estadoCod: 25,
        cod: 3878,
      },
      {
        nome: "Torrinha",
        uf: "SP",
        estadoCod: 25,
        cod: 3879,
      },
      {
        nome: "Trabiju",
        uf: "SP",
        estadoCod: 25,
        cod: 3880,
      },
      {
        nome: "Tremembé",
        uf: "SP",
        estadoCod: 25,
        cod: 3881,
      },
      {
        nome: "Três Fronteiras",
        uf: "SP",
        estadoCod: 25,
        cod: 3882,
      },
      {
        nome: "Tuiuti",
        uf: "SP",
        estadoCod: 25,
        cod: 3883,
      },
      {
        nome: "Tupã",
        uf: "SP",
        estadoCod: 25,
        cod: 3884,
      },
      {
        nome: "Tupi Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3885,
      },
      {
        nome: "Turiúba",
        uf: "SP",
        estadoCod: 25,
        cod: 3886,
      },
      {
        nome: "Turmalina",
        uf: "SP",
        estadoCod: 25,
        cod: 3887,
      },
      {
        nome: "Ubarana",
        uf: "SP",
        estadoCod: 25,
        cod: 3888,
      },
      {
        nome: "Ubatuba",
        uf: "SP",
        estadoCod: 25,
        cod: 3889,
      },
      {
        nome: "Ubirajara",
        uf: "SP",
        estadoCod: 25,
        cod: 3890,
      },
      {
        nome: "Uchoa",
        uf: "SP",
        estadoCod: 25,
        cod: 3891,
      },
      {
        nome: "União Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3892,
      },
      {
        nome: "Urânia",
        uf: "SP",
        estadoCod: 25,
        cod: 3893,
      },
      {
        nome: "Uru",
        uf: "SP",
        estadoCod: 25,
        cod: 3894,
      },
      {
        nome: "Urupês",
        uf: "SP",
        estadoCod: 25,
        cod: 3895,
      },
      {
        nome: "Valentim Gentil",
        uf: "SP",
        estadoCod: 25,
        cod: 3896,
      },
      {
        nome: "Valinhos",
        uf: "SP",
        estadoCod: 25,
        cod: 3897,
      },
      {
        nome: "Valparaíso",
        uf: "SP",
        estadoCod: 25,
        cod: 3898,
      },
      {
        nome: "Vargem",
        uf: "SP",
        estadoCod: 25,
        cod: 3899,
      },
      {
        nome: "Vargem Grande do Sul",
        uf: "SP",
        estadoCod: 25,
        cod: 3900,
      },
      {
        nome: "Vargem Grande Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3901,
      },
      {
        nome: "Várzea Paulista",
        uf: "SP",
        estadoCod: 25,
        cod: 3902,
      },
      {
        nome: "Vera Cruz",
        uf: "SP",
        estadoCod: 25,
        cod: 3903,
      },
      {
        nome: "Vinhedo",
        uf: "SP",
        estadoCod: 25,
        cod: 3904,
      },
      {
        nome: "Viradouro",
        uf: "SP",
        estadoCod: 25,
        cod: 3905,
      },
      {
        nome: "Vista Alegre do Alto",
        uf: "SP",
        estadoCod: 25,
        cod: 3906,
      },
      {
        nome: "Vitória Brasil",
        uf: "SP",
        estadoCod: 25,
        cod: 3907,
      },
      {
        nome: "Votorantim",
        uf: "SP",
        estadoCod: 25,
        cod: 3908,
      },
      {
        nome: "Votuporanga",
        uf: "SP",
        estadoCod: 25,
        cod: 3909,
      },
      {
        nome: "Zacarias",
        uf: "SP",
        estadoCod: 25,
        cod: 3910,
      },
      {
        nome: "Chavantes",
        uf: "SP",
        estadoCod: 25,
        cod: 3911,
      },
      {
        nome: "Estiva Gerbi",
        uf: "SP",
        estadoCod: 25,
        cod: 3912,
      },
    ],
  },
  {
    nome: "Paraná",
    uf: "PR",
    regiaoCod: 4,
    cod: 16,
    cidades: [
      {
        nome: "Abatiá",
        uf: "PR",
        estadoCod: 16,
        cod: 3913,
      },
      {
        nome: "Adrianópolis",
        uf: "PR",
        estadoCod: 16,
        cod: 3914,
      },
      {
        nome: "Agudos do Sul",
        uf: "PR",
        estadoCod: 16,
        cod: 3915,
      },
      {
        nome: "Almirante Tamandaré",
        uf: "PR",
        estadoCod: 16,
        cod: 3916,
      },
      {
        nome: "Altamira do Paraná",
        uf: "PR",
        estadoCod: 16,
        cod: 3917,
      },
      {
        nome: "Altônia",
        uf: "PR",
        estadoCod: 16,
        cod: 3918,
      },
      {
        nome: "Alto Paraná",
        uf: "PR",
        estadoCod: 16,
        cod: 3919,
      },
      {
        nome: "Alto Piquiri",
        uf: "PR",
        estadoCod: 16,
        cod: 3920,
      },
      {
        nome: "Alvorada do Sul",
        uf: "PR",
        estadoCod: 16,
        cod: 3921,
      },
      {
        nome: "Amaporã",
        uf: "PR",
        estadoCod: 16,
        cod: 3922,
      },
      {
        nome: "Ampére",
        uf: "PR",
        estadoCod: 16,
        cod: 3923,
      },
      {
        nome: "Anahy",
        uf: "PR",
        estadoCod: 16,
        cod: 3924,
      },
      {
        nome: "Andirá",
        uf: "PR",
        estadoCod: 16,
        cod: 3925,
      },
      {
        nome: "Ângulo",
        uf: "PR",
        estadoCod: 16,
        cod: 3926,
      },
      {
        nome: "Antonina",
        uf: "PR",
        estadoCod: 16,
        cod: 3927,
      },
      {
        nome: "Antônio Olinto",
        uf: "PR",
        estadoCod: 16,
        cod: 3928,
      },
      {
        nome: "Apucarana",
        uf: "PR",
        estadoCod: 16,
        cod: 3929,
      },
      {
        nome: "Arapongas",
        uf: "PR",
        estadoCod: 16,
        cod: 3930,
      },
      {
        nome: "Arapoti",
        uf: "PR",
        estadoCod: 16,
        cod: 3931,
      },
      {
        nome: "Arapuã",
        uf: "PR",
        estadoCod: 16,
        cod: 3932,
      },
      {
        nome: "Araruna",
        uf: "PR",
        estadoCod: 16,
        cod: 3933,
      },
      {
        nome: "Araucária",
        uf: "PR",
        estadoCod: 16,
        cod: 3934,
      },
      {
        nome: "Ariranha do Ivaí",
        uf: "PR",
        estadoCod: 16,
        cod: 3935,
      },
      {
        nome: "Assaí",
        uf: "PR",
        estadoCod: 16,
        cod: 3936,
      },
      {
        nome: "Assis Chateaubriand",
        uf: "PR",
        estadoCod: 16,
        cod: 3937,
      },
      {
        nome: "Astorga",
        uf: "PR",
        estadoCod: 16,
        cod: 3938,
      },
      {
        nome: "Atalaia",
        uf: "PR",
        estadoCod: 16,
        cod: 3939,
      },
      {
        nome: "Balsa Nova",
        uf: "PR",
        estadoCod: 16,
        cod: 3940,
      },
      {
        nome: "Bandeirantes",
        uf: "PR",
        estadoCod: 16,
        cod: 3941,
      },
      {
        nome: "Barbosa Ferraz",
        uf: "PR",
        estadoCod: 16,
        cod: 3942,
      },
      {
        nome: "Barracão",
        uf: "PR",
        estadoCod: 16,
        cod: 3943,
      },
      {
        nome: "Barra do Jacaré",
        uf: "PR",
        estadoCod: 16,
        cod: 3944,
      },
      {
        nome: "Bela Vista da Caroba",
        uf: "PR",
        estadoCod: 16,
        cod: 3945,
      },
      {
        nome: "Bela Vista do Paraíso",
        uf: "PR",
        estadoCod: 16,
        cod: 3946,
      },
      {
        nome: "Bituruna",
        uf: "PR",
        estadoCod: 16,
        cod: 3947,
      },
      {
        nome: "Boa Esperança",
        uf: "PR",
        estadoCod: 16,
        cod: 3948,
      },
      {
        nome: "Boa Esperança do Iguaçu",
        uf: "PR",
        estadoCod: 16,
        cod: 3949,
      },
      {
        nome: "Boa Ventura de São Roque",
        uf: "PR",
        estadoCod: 16,
        cod: 3950,
      },
      {
        nome: "Boa Vista da Aparecida",
        uf: "PR",
        estadoCod: 16,
        cod: 3951,
      },
      {
        nome: "Bocaiúva do Sul",
        uf: "PR",
        estadoCod: 16,
        cod: 3952,
      },
      {
        nome: "Bom Jesus do Sul",
        uf: "PR",
        estadoCod: 16,
        cod: 3953,
      },
      {
        nome: "Bom Sucesso",
        uf: "PR",
        estadoCod: 16,
        cod: 3954,
      },
      {
        nome: "Bom Sucesso do Sul",
        uf: "PR",
        estadoCod: 16,
        cod: 3955,
      },
      {
        nome: "Borrazópolis",
        uf: "PR",
        estadoCod: 16,
        cod: 3956,
      },
      {
        nome: "Braganey",
        uf: "PR",
        estadoCod: 16,
        cod: 3957,
      },
      {
        nome: "Brasilândia do Sul",
        uf: "PR",
        estadoCod: 16,
        cod: 3958,
      },
      {
        nome: "Cafeara",
        uf: "PR",
        estadoCod: 16,
        cod: 3959,
      },
      {
        nome: "Cafelândia",
        uf: "PR",
        estadoCod: 16,
        cod: 3960,
      },
      {
        nome: "Cafezal do Sul",
        uf: "PR",
        estadoCod: 16,
        cod: 3961,
      },
      {
        nome: "Califórnia",
        uf: "PR",
        estadoCod: 16,
        cod: 3962,
      },
      {
        nome: "Cambará",
        uf: "PR",
        estadoCod: 16,
        cod: 3963,
      },
      {
        nome: "Cambé",
        uf: "PR",
        estadoCod: 16,
        cod: 3964,
      },
      {
        nome: "Cambira",
        uf: "PR",
        estadoCod: 16,
        cod: 3965,
      },
      {
        nome: "Campina da Lagoa",
        uf: "PR",
        estadoCod: 16,
        cod: 3966,
      },
      {
        nome: "Campina do Simão",
        uf: "PR",
        estadoCod: 16,
        cod: 3967,
      },
      {
        nome: "Campina Grande do Sul",
        uf: "PR",
        estadoCod: 16,
        cod: 3968,
      },
      {
        nome: "Campo Bonito",
        uf: "PR",
        estadoCod: 16,
        cod: 3969,
      },
      {
        nome: "Campo do Tenente",
        uf: "PR",
        estadoCod: 16,
        cod: 3970,
      },
      {
        nome: "Campo Largo",
        uf: "PR",
        estadoCod: 16,
        cod: 3971,
      },
      {
        nome: "Campo Magro",
        uf: "PR",
        estadoCod: 16,
        cod: 3972,
      },
      {
        nome: "Campo Mourão",
        uf: "PR",
        estadoCod: 16,
        cod: 3973,
      },
      {
        nome: "Cândido de Abreu",
        uf: "PR",
        estadoCod: 16,
        cod: 3974,
      },
      {
        nome: "Candói",
        uf: "PR",
        estadoCod: 16,
        cod: 3975,
      },
      {
        nome: "Cantagalo",
        uf: "PR",
        estadoCod: 16,
        cod: 3976,
      },
      {
        nome: "Capanema",
        uf: "PR",
        estadoCod: 16,
        cod: 3977,
      },
      {
        nome: "Capitão Leônidas Marques",
        uf: "PR",
        estadoCod: 16,
        cod: 3978,
      },
      {
        nome: "Carambeí",
        uf: "PR",
        estadoCod: 16,
        cod: 3979,
      },
      {
        nome: "Carlópolis",
        uf: "PR",
        estadoCod: 16,
        cod: 3980,
      },
      {
        nome: "Cascavel",
        uf: "PR",
        estadoCod: 16,
        cod: 3981,
      },
      {
        nome: "Castro",
        uf: "PR",
        estadoCod: 16,
        cod: 3982,
      },
      {
        nome: "Catanduvas",
        uf: "PR",
        estadoCod: 16,
        cod: 3983,
      },
      {
        nome: "Centenário do Sul",
        uf: "PR",
        estadoCod: 16,
        cod: 3984,
      },
      {
        nome: "Cerro Azul",
        uf: "PR",
        estadoCod: 16,
        cod: 3985,
      },
      {
        nome: "Céu Azul",
        uf: "PR",
        estadoCod: 16,
        cod: 3986,
      },
      {
        nome: "Chopinzinho",
        uf: "PR",
        estadoCod: 16,
        cod: 3987,
      },
      {
        nome: "Cianorte",
        uf: "PR",
        estadoCod: 16,
        cod: 3988,
      },
      {
        nome: "Cidade Gaúcha",
        uf: "PR",
        estadoCod: 16,
        cod: 3989,
      },
      {
        nome: "Clevelândia",
        uf: "PR",
        estadoCod: 16,
        cod: 3990,
      },
      {
        nome: "Colombo",
        uf: "PR",
        estadoCod: 16,
        cod: 3991,
      },
      {
        nome: "Colorado",
        uf: "PR",
        estadoCod: 16,
        cod: 3992,
      },
      {
        nome: "Congonhinhas",
        uf: "PR",
        estadoCod: 16,
        cod: 3993,
      },
      {
        nome: "Conselheiro Mairinck",
        uf: "PR",
        estadoCod: 16,
        cod: 3994,
      },
      {
        nome: "Contenda",
        uf: "PR",
        estadoCod: 16,
        cod: 3995,
      },
      {
        nome: "Corbélia",
        uf: "PR",
        estadoCod: 16,
        cod: 3996,
      },
      {
        nome: "Cornélio Procópio",
        uf: "PR",
        estadoCod: 16,
        cod: 3997,
      },
      {
        nome: "Coronel Domingos Soares",
        uf: "PR",
        estadoCod: 16,
        cod: 3998,
      },
      {
        nome: "Coronel Vivida",
        uf: "PR",
        estadoCod: 16,
        cod: 3999,
      },
      {
        nome: "Corumbataí do Sul",
        uf: "PR",
        estadoCod: 16,
        cod: 4000,
      },
      {
        nome: "Cruzeiro do Iguaçu",
        uf: "PR",
        estadoCod: 16,
        cod: 4001,
      },
      {
        nome: "Cruzeiro do Oeste",
        uf: "PR",
        estadoCod: 16,
        cod: 4002,
      },
      {
        nome: "Cruzeiro do Sul",
        uf: "PR",
        estadoCod: 16,
        cod: 4003,
      },
      {
        nome: "Cruz Machado",
        uf: "PR",
        estadoCod: 16,
        cod: 4004,
      },
      {
        nome: "Cruzmaltina",
        uf: "PR",
        estadoCod: 16,
        cod: 4005,
      },
      {
        nome: "Curitiba",
        uf: "PR",
        estadoCod: 16,
        cod: 4006,
      },
      {
        nome: "Curiúva",
        uf: "PR",
        estadoCod: 16,
        cod: 4007,
      },
      {
        nome: "Diamante do Norte",
        uf: "PR",
        estadoCod: 16,
        cod: 4008,
      },
      {
        nome: "Diamante do Sul",
        uf: "PR",
        estadoCod: 16,
        cod: 4009,
      },
      {
        nome: "Diamante D'Oeste",
        uf: "PR",
        estadoCod: 16,
        cod: 4010,
      },
      {
        nome: "Dois Vizinhos",
        uf: "PR",
        estadoCod: 16,
        cod: 4011,
      },
      {
        nome: "Douradina",
        uf: "PR",
        estadoCod: 16,
        cod: 4012,
      },
      {
        nome: "Doutor Camargo",
        uf: "PR",
        estadoCod: 16,
        cod: 4013,
      },
      {
        nome: "Enéas Marques",
        uf: "PR",
        estadoCod: 16,
        cod: 4014,
      },
      {
        nome: "Engenheiro Beltrão",
        uf: "PR",
        estadoCod: 16,
        cod: 4015,
      },
      {
        nome: "Esperança Nova",
        uf: "PR",
        estadoCod: 16,
        cod: 4016,
      },
      {
        nome: "Entre Rios do Oeste",
        uf: "PR",
        estadoCod: 16,
        cod: 4017,
      },
      {
        nome: "Espigão Alto do Iguaçu",
        uf: "PR",
        estadoCod: 16,
        cod: 4018,
      },
      {
        nome: "Farol",
        uf: "PR",
        estadoCod: 16,
        cod: 4019,
      },
      {
        nome: "Faxinal",
        uf: "PR",
        estadoCod: 16,
        cod: 4020,
      },
      {
        nome: "Fazenda Rio Grande",
        uf: "PR",
        estadoCod: 16,
        cod: 4021,
      },
      {
        nome: "Fênix",
        uf: "PR",
        estadoCod: 16,
        cod: 4022,
      },
      {
        nome: "Fernandes Pinheiro",
        uf: "PR",
        estadoCod: 16,
        cod: 4023,
      },
      {
        nome: "Figueira",
        uf: "PR",
        estadoCod: 16,
        cod: 4024,
      },
      {
        nome: "Floraí",
        uf: "PR",
        estadoCod: 16,
        cod: 4025,
      },
      {
        nome: "Flor da Serra do Sul",
        uf: "PR",
        estadoCod: 16,
        cod: 4026,
      },
      {
        nome: "Floresta",
        uf: "PR",
        estadoCod: 16,
        cod: 4027,
      },
      {
        nome: "Florestópolis",
        uf: "PR",
        estadoCod: 16,
        cod: 4028,
      },
      {
        nome: "Flórida",
        uf: "PR",
        estadoCod: 16,
        cod: 4029,
      },
      {
        nome: "Formosa do Oeste",
        uf: "PR",
        estadoCod: 16,
        cod: 4030,
      },
      {
        nome: "Foz do Iguaçu",
        uf: "PR",
        estadoCod: 16,
        cod: 4031,
      },
      {
        nome: "Francisco Alves",
        uf: "PR",
        estadoCod: 16,
        cod: 4032,
      },
      {
        nome: "Francisco Beltrão",
        uf: "PR",
        estadoCod: 16,
        cod: 4033,
      },
      {
        nome: "Foz do Jordão",
        uf: "PR",
        estadoCod: 16,
        cod: 4034,
      },
      {
        nome: "General Carneiro",
        uf: "PR",
        estadoCod: 16,
        cod: 4035,
      },
      {
        nome: "Godoy Moreira",
        uf: "PR",
        estadoCod: 16,
        cod: 4036,
      },
      {
        nome: "Goioerê",
        uf: "PR",
        estadoCod: 16,
        cod: 4037,
      },
      {
        nome: "Goioxim",
        uf: "PR",
        estadoCod: 16,
        cod: 4038,
      },
      {
        nome: "Grandes Rios",
        uf: "PR",
        estadoCod: 16,
        cod: 4039,
      },
      {
        nome: "Guaíra",
        uf: "PR",
        estadoCod: 16,
        cod: 4040,
      },
      {
        nome: "Guairaçá",
        uf: "PR",
        estadoCod: 16,
        cod: 4041,
      },
      {
        nome: "Guamiranga",
        uf: "PR",
        estadoCod: 16,
        cod: 4042,
      },
      {
        nome: "Guapirama",
        uf: "PR",
        estadoCod: 16,
        cod: 4043,
      },
      {
        nome: "Guaporema",
        uf: "PR",
        estadoCod: 16,
        cod: 4044,
      },
      {
        nome: "Guaraci",
        uf: "PR",
        estadoCod: 16,
        cod: 4045,
      },
      {
        nome: "Guaraniaçu",
        uf: "PR",
        estadoCod: 16,
        cod: 4046,
      },
      {
        nome: "Guarapuava",
        uf: "PR",
        estadoCod: 16,
        cod: 4047,
      },
      {
        nome: "Guaraqueçaba",
        uf: "PR",
        estadoCod: 16,
        cod: 4048,
      },
      {
        nome: "Guaratuba",
        uf: "PR",
        estadoCod: 16,
        cod: 4049,
      },
      {
        nome: "Honório Serpa",
        uf: "PR",
        estadoCod: 16,
        cod: 4050,
      },
      {
        nome: "Ibaiti",
        uf: "PR",
        estadoCod: 16,
        cod: 4051,
      },
      {
        nome: "Ibema",
        uf: "PR",
        estadoCod: 16,
        cod: 4052,
      },
      {
        nome: "Ibiporã",
        uf: "PR",
        estadoCod: 16,
        cod: 4053,
      },
      {
        nome: "Icaraíma",
        uf: "PR",
        estadoCod: 16,
        cod: 4054,
      },
      {
        nome: "Iguaraçu",
        uf: "PR",
        estadoCod: 16,
        cod: 4055,
      },
      {
        nome: "Iguatu",
        uf: "PR",
        estadoCod: 16,
        cod: 4056,
      },
      {
        nome: "Imbaú",
        uf: "PR",
        estadoCod: 16,
        cod: 4057,
      },
      {
        nome: "Imbituva",
        uf: "PR",
        estadoCod: 16,
        cod: 4058,
      },
      {
        nome: "Inácio Martins",
        uf: "PR",
        estadoCod: 16,
        cod: 4059,
      },
      {
        nome: "Inajá",
        uf: "PR",
        estadoCod: 16,
        cod: 4060,
      },
      {
        nome: "Indianópolis",
        uf: "PR",
        estadoCod: 16,
        cod: 4061,
      },
      {
        nome: "Ipiranga",
        uf: "PR",
        estadoCod: 16,
        cod: 4062,
      },
      {
        nome: "Iporã",
        uf: "PR",
        estadoCod: 16,
        cod: 4063,
      },
      {
        nome: "Iracema do Oeste",
        uf: "PR",
        estadoCod: 16,
        cod: 4064,
      },
      {
        nome: "Irati",
        uf: "PR",
        estadoCod: 16,
        cod: 4065,
      },
      {
        nome: "Iretama",
        uf: "PR",
        estadoCod: 16,
        cod: 4066,
      },
      {
        nome: "Itaguajé",
        uf: "PR",
        estadoCod: 16,
        cod: 4067,
      },
      {
        nome: "Itaipulândia",
        uf: "PR",
        estadoCod: 16,
        cod: 4068,
      },
      {
        nome: "Itambaracá",
        uf: "PR",
        estadoCod: 16,
        cod: 4069,
      },
      {
        nome: "Itambé",
        uf: "PR",
        estadoCod: 16,
        cod: 4070,
      },
      {
        nome: "Itapejara D'Oeste",
        uf: "PR",
        estadoCod: 16,
        cod: 4071,
      },
      {
        nome: "Itaperuçu",
        uf: "PR",
        estadoCod: 16,
        cod: 4072,
      },
      {
        nome: "Itaúna do Sul",
        uf: "PR",
        estadoCod: 16,
        cod: 4073,
      },
      {
        nome: "Ivaí",
        uf: "PR",
        estadoCod: 16,
        cod: 4074,
      },
      {
        nome: "Ivaiporã",
        uf: "PR",
        estadoCod: 16,
        cod: 4075,
      },
      {
        nome: "Ivaté",
        uf: "PR",
        estadoCod: 16,
        cod: 4076,
      },
      {
        nome: "Ivatuba",
        uf: "PR",
        estadoCod: 16,
        cod: 4077,
      },
      {
        nome: "Jaboti",
        uf: "PR",
        estadoCod: 16,
        cod: 4078,
      },
      {
        nome: "Jacarezinho",
        uf: "PR",
        estadoCod: 16,
        cod: 4079,
      },
      {
        nome: "Jaguapitã",
        uf: "PR",
        estadoCod: 16,
        cod: 4080,
      },
      {
        nome: "Jaguariaíva",
        uf: "PR",
        estadoCod: 16,
        cod: 4081,
      },
      {
        nome: "Jandaia do Sul",
        uf: "PR",
        estadoCod: 16,
        cod: 4082,
      },
      {
        nome: "Janiópolis",
        uf: "PR",
        estadoCod: 16,
        cod: 4083,
      },
      {
        nome: "Japira",
        uf: "PR",
        estadoCod: 16,
        cod: 4084,
      },
      {
        nome: "Japurá",
        uf: "PR",
        estadoCod: 16,
        cod: 4085,
      },
      {
        nome: "Jardim Alegre",
        uf: "PR",
        estadoCod: 16,
        cod: 4086,
      },
      {
        nome: "Jardim Olinda",
        uf: "PR",
        estadoCod: 16,
        cod: 4087,
      },
      {
        nome: "Jataizinho",
        uf: "PR",
        estadoCod: 16,
        cod: 4088,
      },
      {
        nome: "Jesuítas",
        uf: "PR",
        estadoCod: 16,
        cod: 4089,
      },
      {
        nome: "Joaquim Távora",
        uf: "PR",
        estadoCod: 16,
        cod: 4090,
      },
      {
        nome: "Jundiaí do Sul",
        uf: "PR",
        estadoCod: 16,
        cod: 4091,
      },
      {
        nome: "Juranda",
        uf: "PR",
        estadoCod: 16,
        cod: 4092,
      },
      {
        nome: "Jussara",
        uf: "PR",
        estadoCod: 16,
        cod: 4093,
      },
      {
        nome: "Kaloré",
        uf: "PR",
        estadoCod: 16,
        cod: 4094,
      },
      {
        nome: "Lapa",
        uf: "PR",
        estadoCod: 16,
        cod: 4095,
      },
      {
        nome: "Laranjal",
        uf: "PR",
        estadoCod: 16,
        cod: 4096,
      },
      {
        nome: "Laranjeiras do Sul",
        uf: "PR",
        estadoCod: 16,
        cod: 4097,
      },
      {
        nome: "Leópolis",
        uf: "PR",
        estadoCod: 16,
        cod: 4098,
      },
      {
        nome: "Lidianópolis",
        uf: "PR",
        estadoCod: 16,
        cod: 4099,
      },
      {
        nome: "Lindoeste",
        uf: "PR",
        estadoCod: 16,
        cod: 4100,
      },
      {
        nome: "Loanda",
        uf: "PR",
        estadoCod: 16,
        cod: 4101,
      },
      {
        nome: "Lobato",
        uf: "PR",
        estadoCod: 16,
        cod: 4102,
      },
      {
        nome: "Londrina",
        uf: "PR",
        estadoCod: 16,
        cod: 4103,
      },
      {
        nome: "Luiziana",
        uf: "PR",
        estadoCod: 16,
        cod: 4104,
      },
      {
        nome: "Lunardelli",
        uf: "PR",
        estadoCod: 16,
        cod: 4105,
      },
      {
        nome: "Lupionópolis",
        uf: "PR",
        estadoCod: 16,
        cod: 4106,
      },
      {
        nome: "Mallet",
        uf: "PR",
        estadoCod: 16,
        cod: 4107,
      },
      {
        nome: "Mamborê",
        uf: "PR",
        estadoCod: 16,
        cod: 4108,
      },
      {
        nome: "Mandaguaçu",
        uf: "PR",
        estadoCod: 16,
        cod: 4109,
      },
      {
        nome: "Mandaguari",
        uf: "PR",
        estadoCod: 16,
        cod: 4110,
      },
      {
        nome: "Mandirituba",
        uf: "PR",
        estadoCod: 16,
        cod: 4111,
      },
      {
        nome: "Manfrinópolis",
        uf: "PR",
        estadoCod: 16,
        cod: 4112,
      },
      {
        nome: "Mangueirinha",
        uf: "PR",
        estadoCod: 16,
        cod: 4113,
      },
      {
        nome: "Manoel Ribas",
        uf: "PR",
        estadoCod: 16,
        cod: 4114,
      },
      {
        nome: "Marechal Cândido Rondon",
        uf: "PR",
        estadoCod: 16,
        cod: 4115,
      },
      {
        nome: "Maria Helena",
        uf: "PR",
        estadoCod: 16,
        cod: 4116,
      },
      {
        nome: "Marialva",
        uf: "PR",
        estadoCod: 16,
        cod: 4117,
      },
      {
        nome: "Marilândia do Sul",
        uf: "PR",
        estadoCod: 16,
        cod: 4118,
      },
      {
        nome: "Marilena",
        uf: "PR",
        estadoCod: 16,
        cod: 4119,
      },
      {
        nome: "Mariluz",
        uf: "PR",
        estadoCod: 16,
        cod: 4120,
      },
      {
        nome: "Maringá",
        uf: "PR",
        estadoCod: 16,
        cod: 4121,
      },
      {
        nome: "Mariópolis",
        uf: "PR",
        estadoCod: 16,
        cod: 4122,
      },
      {
        nome: "Maripá",
        uf: "PR",
        estadoCod: 16,
        cod: 4123,
      },
      {
        nome: "Marmeleiro",
        uf: "PR",
        estadoCod: 16,
        cod: 4124,
      },
      {
        nome: "Marquinho",
        uf: "PR",
        estadoCod: 16,
        cod: 4125,
      },
      {
        nome: "Marumbi",
        uf: "PR",
        estadoCod: 16,
        cod: 4126,
      },
      {
        nome: "Matelândia",
        uf: "PR",
        estadoCod: 16,
        cod: 4127,
      },
      {
        nome: "Matinhos",
        uf: "PR",
        estadoCod: 16,
        cod: 4128,
      },
      {
        nome: "Mato Rico",
        uf: "PR",
        estadoCod: 16,
        cod: 4129,
      },
      {
        nome: "Mauá da Serra",
        uf: "PR",
        estadoCod: 16,
        cod: 4130,
      },
      {
        nome: "Medianeira",
        uf: "PR",
        estadoCod: 16,
        cod: 4131,
      },
      {
        nome: "Mercedes",
        uf: "PR",
        estadoCod: 16,
        cod: 4132,
      },
      {
        nome: "Mirador",
        uf: "PR",
        estadoCod: 16,
        cod: 4133,
      },
      {
        nome: "Miraselva",
        uf: "PR",
        estadoCod: 16,
        cod: 4134,
      },
      {
        nome: "Missal",
        uf: "PR",
        estadoCod: 16,
        cod: 4135,
      },
      {
        nome: "Moreira Sales",
        uf: "PR",
        estadoCod: 16,
        cod: 4136,
      },
      {
        nome: "Morretes",
        uf: "PR",
        estadoCod: 16,
        cod: 4137,
      },
      {
        nome: "Munhoz de Melo",
        uf: "PR",
        estadoCod: 16,
        cod: 4138,
      },
      {
        nome: "Nossa Senhora das Graças",
        uf: "PR",
        estadoCod: 16,
        cod: 4139,
      },
      {
        nome: "Nova Aliança do Ivaí",
        uf: "PR",
        estadoCod: 16,
        cod: 4140,
      },
      {
        nome: "Nova América da Colina",
        uf: "PR",
        estadoCod: 16,
        cod: 4141,
      },
      {
        nome: "Nova Aurora",
        uf: "PR",
        estadoCod: 16,
        cod: 4142,
      },
      {
        nome: "Nova Cantu",
        uf: "PR",
        estadoCod: 16,
        cod: 4143,
      },
      {
        nome: "Nova Esperança",
        uf: "PR",
        estadoCod: 16,
        cod: 4144,
      },
      {
        nome: "Nova Esperança do Sudoeste",
        uf: "PR",
        estadoCod: 16,
        cod: 4145,
      },
      {
        nome: "Nova Fátima",
        uf: "PR",
        estadoCod: 16,
        cod: 4146,
      },
      {
        nome: "Nova Laranjeiras",
        uf: "PR",
        estadoCod: 16,
        cod: 4147,
      },
      {
        nome: "Nova Londrina",
        uf: "PR",
        estadoCod: 16,
        cod: 4148,
      },
      {
        nome: "Nova Olímpia",
        uf: "PR",
        estadoCod: 16,
        cod: 4149,
      },
      {
        nome: "Nova Santa Bárbara",
        uf: "PR",
        estadoCod: 16,
        cod: 4150,
      },
      {
        nome: "Nova Santa Rosa",
        uf: "PR",
        estadoCod: 16,
        cod: 4151,
      },
      {
        nome: "Nova Prata do Iguaçu",
        uf: "PR",
        estadoCod: 16,
        cod: 4152,
      },
      {
        nome: "Nova Tebas",
        uf: "PR",
        estadoCod: 16,
        cod: 4153,
      },
      {
        nome: "Novo Itacolomi",
        uf: "PR",
        estadoCod: 16,
        cod: 4154,
      },
      {
        nome: "Ortigueira",
        uf: "PR",
        estadoCod: 16,
        cod: 4155,
      },
      {
        nome: "Ourizona",
        uf: "PR",
        estadoCod: 16,
        cod: 4156,
      },
      {
        nome: "Ouro Verde do Oeste",
        uf: "PR",
        estadoCod: 16,
        cod: 4157,
      },
      {
        nome: "Paiçandu",
        uf: "PR",
        estadoCod: 16,
        cod: 4158,
      },
      {
        nome: "Palmas",
        uf: "PR",
        estadoCod: 16,
        cod: 4159,
      },
      {
        nome: "Palmeira",
        uf: "PR",
        estadoCod: 16,
        cod: 4160,
      },
      {
        nome: "Palmital",
        uf: "PR",
        estadoCod: 16,
        cod: 4161,
      },
      {
        nome: "Palotina",
        uf: "PR",
        estadoCod: 16,
        cod: 4162,
      },
      {
        nome: "Paraíso do Norte",
        uf: "PR",
        estadoCod: 16,
        cod: 4163,
      },
      {
        nome: "Paranacity",
        uf: "PR",
        estadoCod: 16,
        cod: 4164,
      },
      {
        nome: "Paranaguá",
        uf: "PR",
        estadoCod: 16,
        cod: 4165,
      },
      {
        nome: "Paranapoema",
        uf: "PR",
        estadoCod: 16,
        cod: 4166,
      },
      {
        nome: "Paranavaí",
        uf: "PR",
        estadoCod: 16,
        cod: 4167,
      },
      {
        nome: "Pato Bragado",
        uf: "PR",
        estadoCod: 16,
        cod: 4168,
      },
      {
        nome: "Pato Branco",
        uf: "PR",
        estadoCod: 16,
        cod: 4169,
      },
      {
        nome: "Paula Freitas",
        uf: "PR",
        estadoCod: 16,
        cod: 4170,
      },
      {
        nome: "Paulo Frontin",
        uf: "PR",
        estadoCod: 16,
        cod: 4171,
      },
      {
        nome: "Peabiru",
        uf: "PR",
        estadoCod: 16,
        cod: 4172,
      },
      {
        nome: "Perobal",
        uf: "PR",
        estadoCod: 16,
        cod: 4173,
      },
      {
        nome: "Pérola",
        uf: "PR",
        estadoCod: 16,
        cod: 4174,
      },
      {
        nome: "Pérola D'Oeste",
        uf: "PR",
        estadoCod: 16,
        cod: 4175,
      },
      {
        nome: "Piên",
        uf: "PR",
        estadoCod: 16,
        cod: 4176,
      },
      {
        nome: "Pinhais",
        uf: "PR",
        estadoCod: 16,
        cod: 4177,
      },
      {
        nome: "Pinhalão",
        uf: "PR",
        estadoCod: 16,
        cod: 4178,
      },
      {
        nome: "Pinhal de São Bento",
        uf: "PR",
        estadoCod: 16,
        cod: 4179,
      },
      {
        nome: "Pinhão",
        uf: "PR",
        estadoCod: 16,
        cod: 4180,
      },
      {
        nome: "Piraí do Sul",
        uf: "PR",
        estadoCod: 16,
        cod: 4181,
      },
      {
        nome: "Piraquara",
        uf: "PR",
        estadoCod: 16,
        cod: 4182,
      },
      {
        nome: "Pitanga",
        uf: "PR",
        estadoCod: 16,
        cod: 4183,
      },
      {
        nome: "Pitangueiras",
        uf: "PR",
        estadoCod: 16,
        cod: 4184,
      },
      {
        nome: "Planaltina do Paraná",
        uf: "PR",
        estadoCod: 16,
        cod: 4185,
      },
      {
        nome: "Planalto",
        uf: "PR",
        estadoCod: 16,
        cod: 4186,
      },
      {
        nome: "Ponta Grossa",
        uf: "PR",
        estadoCod: 16,
        cod: 4187,
      },
      {
        nome: "Pontal do Paraná",
        uf: "PR",
        estadoCod: 16,
        cod: 4188,
      },
      {
        nome: "Porecatu",
        uf: "PR",
        estadoCod: 16,
        cod: 4189,
      },
      {
        nome: "Porto Amazonas",
        uf: "PR",
        estadoCod: 16,
        cod: 4190,
      },
      {
        nome: "Porto Barreiro",
        uf: "PR",
        estadoCod: 16,
        cod: 4191,
      },
      {
        nome: "Porto Rico",
        uf: "PR",
        estadoCod: 16,
        cod: 4192,
      },
      {
        nome: "Porto Vitória",
        uf: "PR",
        estadoCod: 16,
        cod: 4193,
      },
      {
        nome: "Prado Ferreira",
        uf: "PR",
        estadoCod: 16,
        cod: 4194,
      },
      {
        nome: "Pranchita",
        uf: "PR",
        estadoCod: 16,
        cod: 4195,
      },
      {
        nome: "Presidente Castelo Branco",
        uf: "PR",
        estadoCod: 16,
        cod: 4196,
      },
      {
        nome: "Primeiro de Maio",
        uf: "PR",
        estadoCod: 16,
        cod: 4197,
      },
      {
        nome: "Prudentópolis",
        uf: "PR",
        estadoCod: 16,
        cod: 4198,
      },
      {
        nome: "Quarto Centenário",
        uf: "PR",
        estadoCod: 16,
        cod: 4199,
      },
      {
        nome: "Quatiguá",
        uf: "PR",
        estadoCod: 16,
        cod: 4200,
      },
      {
        nome: "Quatro Barras",
        uf: "PR",
        estadoCod: 16,
        cod: 4201,
      },
      {
        nome: "Quatro Pontes",
        uf: "PR",
        estadoCod: 16,
        cod: 4202,
      },
      {
        nome: "Quedas do Iguaçu",
        uf: "PR",
        estadoCod: 16,
        cod: 4203,
      },
      {
        nome: "Querência do Norte",
        uf: "PR",
        estadoCod: 16,
        cod: 4204,
      },
      {
        nome: "Quinta do Sol",
        uf: "PR",
        estadoCod: 16,
        cod: 4205,
      },
      {
        nome: "Quitandinha",
        uf: "PR",
        estadoCod: 16,
        cod: 4206,
      },
      {
        nome: "Ramilândia",
        uf: "PR",
        estadoCod: 16,
        cod: 4207,
      },
      {
        nome: "Rancho Alegre",
        uf: "PR",
        estadoCod: 16,
        cod: 4208,
      },
      {
        nome: "Rancho Alegre D'Oeste",
        uf: "PR",
        estadoCod: 16,
        cod: 4209,
      },
      {
        nome: "Realeza",
        uf: "PR",
        estadoCod: 16,
        cod: 4210,
      },
      {
        nome: "Rebouças",
        uf: "PR",
        estadoCod: 16,
        cod: 4211,
      },
      {
        nome: "Renascença",
        uf: "PR",
        estadoCod: 16,
        cod: 4212,
      },
      {
        nome: "Reserva",
        uf: "PR",
        estadoCod: 16,
        cod: 4213,
      },
      {
        nome: "Reserva do Iguaçu",
        uf: "PR",
        estadoCod: 16,
        cod: 4214,
      },
      {
        nome: "Ribeirão Claro",
        uf: "PR",
        estadoCod: 16,
        cod: 4215,
      },
      {
        nome: "Ribeirão do Pinhal",
        uf: "PR",
        estadoCod: 16,
        cod: 4216,
      },
      {
        nome: "Rio Azul",
        uf: "PR",
        estadoCod: 16,
        cod: 4217,
      },
      {
        nome: "Rio Bom",
        uf: "PR",
        estadoCod: 16,
        cod: 4218,
      },
      {
        nome: "Rio Bonito do Iguaçu",
        uf: "PR",
        estadoCod: 16,
        cod: 4219,
      },
      {
        nome: "Rio Branco do Ivaí",
        uf: "PR",
        estadoCod: 16,
        cod: 4220,
      },
      {
        nome: "Rio Branco do Sul",
        uf: "PR",
        estadoCod: 16,
        cod: 4221,
      },
      {
        nome: "Rio Negro",
        uf: "PR",
        estadoCod: 16,
        cod: 4222,
      },
      {
        nome: "Rolândia",
        uf: "PR",
        estadoCod: 16,
        cod: 4223,
      },
      {
        nome: "Roncador",
        uf: "PR",
        estadoCod: 16,
        cod: 4224,
      },
      {
        nome: "Rondon",
        uf: "PR",
        estadoCod: 16,
        cod: 4225,
      },
      {
        nome: "Rosário do Ivaí",
        uf: "PR",
        estadoCod: 16,
        cod: 4226,
      },
      {
        nome: "Sabáudia",
        uf: "PR",
        estadoCod: 16,
        cod: 4227,
      },
      {
        nome: "Salgado Filho",
        uf: "PR",
        estadoCod: 16,
        cod: 4228,
      },
      {
        nome: "Salto do Itararé",
        uf: "PR",
        estadoCod: 16,
        cod: 4229,
      },
      {
        nome: "Salto do Lontra",
        uf: "PR",
        estadoCod: 16,
        cod: 4230,
      },
      {
        nome: "Santa Amélia",
        uf: "PR",
        estadoCod: 16,
        cod: 4231,
      },
      {
        nome: "Santa Cecília do Pavão",
        uf: "PR",
        estadoCod: 16,
        cod: 4232,
      },
      {
        nome: "Santa Cruz de Monte Castelo",
        uf: "PR",
        estadoCod: 16,
        cod: 4233,
      },
      {
        nome: "Santa Fé",
        uf: "PR",
        estadoCod: 16,
        cod: 4234,
      },
      {
        nome: "Santa Helena",
        uf: "PR",
        estadoCod: 16,
        cod: 4235,
      },
      {
        nome: "Santa Inês",
        uf: "PR",
        estadoCod: 16,
        cod: 4236,
      },
      {
        nome: "Santa Isabel do Ivaí",
        uf: "PR",
        estadoCod: 16,
        cod: 4237,
      },
      {
        nome: "Santa Izabel do Oeste",
        uf: "PR",
        estadoCod: 16,
        cod: 4238,
      },
      {
        nome: "Santa Lúcia",
        uf: "PR",
        estadoCod: 16,
        cod: 4239,
      },
      {
        nome: "Santa Maria do Oeste",
        uf: "PR",
        estadoCod: 16,
        cod: 4240,
      },
      {
        nome: "Santa Mariana",
        uf: "PR",
        estadoCod: 16,
        cod: 4241,
      },
      {
        nome: "Santa Mônica",
        uf: "PR",
        estadoCod: 16,
        cod: 4242,
      },
      {
        nome: "Santana do Itararé",
        uf: "PR",
        estadoCod: 16,
        cod: 4243,
      },
      {
        nome: "Santa Tereza do Oeste",
        uf: "PR",
        estadoCod: 16,
        cod: 4244,
      },
      {
        nome: "Santa Terezinha de Itaipu",
        uf: "PR",
        estadoCod: 16,
        cod: 4245,
      },
      {
        nome: "Santo Antônio da Platina",
        uf: "PR",
        estadoCod: 16,
        cod: 4246,
      },
      {
        nome: "Santo Antônio do Caiuá",
        uf: "PR",
        estadoCod: 16,
        cod: 4247,
      },
      {
        nome: "Santo Antônio do Paraíso",
        uf: "PR",
        estadoCod: 16,
        cod: 4248,
      },
      {
        nome: "Santo Antônio do Sudoeste",
        uf: "PR",
        estadoCod: 16,
        cod: 4249,
      },
      {
        nome: "Santo Inácio",
        uf: "PR",
        estadoCod: 16,
        cod: 4250,
      },
      {
        nome: "São Carlos do Ivaí",
        uf: "PR",
        estadoCod: 16,
        cod: 4251,
      },
      {
        nome: "São Jerônimo da Serra",
        uf: "PR",
        estadoCod: 16,
        cod: 4252,
      },
      {
        nome: "São João",
        uf: "PR",
        estadoCod: 16,
        cod: 4253,
      },
      {
        nome: "São João do Caiuá",
        uf: "PR",
        estadoCod: 16,
        cod: 4254,
      },
      {
        nome: "São João do Ivaí",
        uf: "PR",
        estadoCod: 16,
        cod: 4255,
      },
      {
        nome: "São João do Triunfo",
        uf: "PR",
        estadoCod: 16,
        cod: 4256,
      },
      {
        nome: "São Jorge D'Oeste",
        uf: "PR",
        estadoCod: 16,
        cod: 4257,
      },
      {
        nome: "São Jorge do Ivaí",
        uf: "PR",
        estadoCod: 16,
        cod: 4258,
      },
      {
        nome: "São Jorge do Patrocínio",
        uf: "PR",
        estadoCod: 16,
        cod: 4259,
      },
      {
        nome: "São José da Boa Vista",
        uf: "PR",
        estadoCod: 16,
        cod: 4260,
      },
      {
        nome: "São José das Palmeiras",
        uf: "PR",
        estadoCod: 16,
        cod: 4261,
      },
      {
        nome: "São José dos Pinhais",
        uf: "PR",
        estadoCod: 16,
        cod: 4262,
      },
      {
        nome: "São Manoel do Paraná",
        uf: "PR",
        estadoCod: 16,
        cod: 4263,
      },
      {
        nome: "São Mateus do Sul",
        uf: "PR",
        estadoCod: 16,
        cod: 4264,
      },
      {
        nome: "São Miguel do Iguaçu",
        uf: "PR",
        estadoCod: 16,
        cod: 4265,
      },
      {
        nome: "São Pedro do Iguaçu",
        uf: "PR",
        estadoCod: 16,
        cod: 4266,
      },
      {
        nome: "São Pedro do Ivaí",
        uf: "PR",
        estadoCod: 16,
        cod: 4267,
      },
      {
        nome: "São Pedro do Paraná",
        uf: "PR",
        estadoCod: 16,
        cod: 4268,
      },
      {
        nome: "São Sebastião da Amoreira",
        uf: "PR",
        estadoCod: 16,
        cod: 4269,
      },
      {
        nome: "São Tomé",
        uf: "PR",
        estadoCod: 16,
        cod: 4270,
      },
      {
        nome: "Sapopema",
        uf: "PR",
        estadoCod: 16,
        cod: 4271,
      },
      {
        nome: "Sarandi",
        uf: "PR",
        estadoCod: 16,
        cod: 4272,
      },
      {
        nome: "Saudade do Iguaçu",
        uf: "PR",
        estadoCod: 16,
        cod: 4273,
      },
      {
        nome: "Sengés",
        uf: "PR",
        estadoCod: 16,
        cod: 4274,
      },
      {
        nome: "Serranópolis do Iguaçu",
        uf: "PR",
        estadoCod: 16,
        cod: 4275,
      },
      {
        nome: "Sertaneja",
        uf: "PR",
        estadoCod: 16,
        cod: 4276,
      },
      {
        nome: "Sertanópolis",
        uf: "PR",
        estadoCod: 16,
        cod: 4277,
      },
      {
        nome: "Siqueira Campos",
        uf: "PR",
        estadoCod: 16,
        cod: 4278,
      },
      {
        nome: "Sulina",
        uf: "PR",
        estadoCod: 16,
        cod: 4279,
      },
      {
        nome: "Tamarana",
        uf: "PR",
        estadoCod: 16,
        cod: 4280,
      },
      {
        nome: "Tamboara",
        uf: "PR",
        estadoCod: 16,
        cod: 4281,
      },
      {
        nome: "Tapejara",
        uf: "PR",
        estadoCod: 16,
        cod: 4282,
      },
      {
        nome: "Tapira",
        uf: "PR",
        estadoCod: 16,
        cod: 4283,
      },
      {
        nome: "Teixeira Soares",
        uf: "PR",
        estadoCod: 16,
        cod: 4284,
      },
      {
        nome: "Telêmaco Borba",
        uf: "PR",
        estadoCod: 16,
        cod: 4285,
      },
      {
        nome: "Terra Boa",
        uf: "PR",
        estadoCod: 16,
        cod: 4286,
      },
      {
        nome: "Terra Rica",
        uf: "PR",
        estadoCod: 16,
        cod: 4287,
      },
      {
        nome: "Terra Roxa",
        uf: "PR",
        estadoCod: 16,
        cod: 4288,
      },
      {
        nome: "Tibagi",
        uf: "PR",
        estadoCod: 16,
        cod: 4289,
      },
      {
        nome: "Tijucas do Sul",
        uf: "PR",
        estadoCod: 16,
        cod: 4290,
      },
      {
        nome: "Toledo",
        uf: "PR",
        estadoCod: 16,
        cod: 4291,
      },
      {
        nome: "Tomazina",
        uf: "PR",
        estadoCod: 16,
        cod: 4292,
      },
      {
        nome: "Três Barras do Paraná",
        uf: "PR",
        estadoCod: 16,
        cod: 4293,
      },
      {
        nome: "Tunas do Paraná",
        uf: "PR",
        estadoCod: 16,
        cod: 4294,
      },
      {
        nome: "Tuneiras do Oeste",
        uf: "PR",
        estadoCod: 16,
        cod: 4295,
      },
      {
        nome: "Tupãssi",
        uf: "PR",
        estadoCod: 16,
        cod: 4296,
      },
      {
        nome: "Turvo",
        uf: "PR",
        estadoCod: 16,
        cod: 4297,
      },
      {
        nome: "Ubiratã",
        uf: "PR",
        estadoCod: 16,
        cod: 4298,
      },
      {
        nome: "Umuarama",
        uf: "PR",
        estadoCod: 16,
        cod: 4299,
      },
      {
        nome: "União da Vitória",
        uf: "PR",
        estadoCod: 16,
        cod: 4300,
      },
      {
        nome: "Uniflor",
        uf: "PR",
        estadoCod: 16,
        cod: 4301,
      },
      {
        nome: "Uraí",
        uf: "PR",
        estadoCod: 16,
        cod: 4302,
      },
      {
        nome: "Wenceslau Braz",
        uf: "PR",
        estadoCod: 16,
        cod: 4303,
      },
      {
        nome: "Ventania",
        uf: "PR",
        estadoCod: 16,
        cod: 4304,
      },
      {
        nome: "Vera Cruz do Oeste",
        uf: "PR",
        estadoCod: 16,
        cod: 4305,
      },
      {
        nome: "Verê",
        uf: "PR",
        estadoCod: 16,
        cod: 4306,
      },
      {
        nome: "Alto Paraíso",
        uf: "PR",
        estadoCod: 16,
        cod: 4307,
      },
      {
        nome: "Doutor Ulysses",
        uf: "PR",
        estadoCod: 16,
        cod: 4308,
      },
      {
        nome: "Virmond",
        uf: "PR",
        estadoCod: 16,
        cod: 4309,
      },
      {
        nome: "Vitorino",
        uf: "PR",
        estadoCod: 16,
        cod: 4310,
      },
      {
        nome: "Xambrê",
        uf: "PR",
        estadoCod: 16,
        cod: 4311,
      },
    ],
  },
  {
    nome: "Santa Catarina",
    uf: "SC",
    regiaoCod: 4,
    cod: 24,
    cidades: [
      {
        nome: "Abdon Batista",
        uf: "SC",
        estadoCod: 24,
        cod: 4312,
      },
      {
        nome: "Abelardo Luz",
        uf: "SC",
        estadoCod: 24,
        cod: 4313,
      },
      {
        nome: "Agrolândia",
        uf: "SC",
        estadoCod: 24,
        cod: 4314,
      },
      {
        nome: "Agronômica",
        uf: "SC",
        estadoCod: 24,
        cod: 4315,
      },
      {
        nome: "Água Doce",
        uf: "SC",
        estadoCod: 24,
        cod: 4316,
      },
      {
        nome: "Águas de Chapecó",
        uf: "SC",
        estadoCod: 24,
        cod: 4317,
      },
      {
        nome: "Águas Frias",
        uf: "SC",
        estadoCod: 24,
        cod: 4318,
      },
      {
        nome: "Águas Mornas",
        uf: "SC",
        estadoCod: 24,
        cod: 4319,
      },
      {
        nome: "Alfredo Wagner",
        uf: "SC",
        estadoCod: 24,
        cod: 4320,
      },
      {
        nome: "Alto Bela Vista",
        uf: "SC",
        estadoCod: 24,
        cod: 4321,
      },
      {
        nome: "Anchieta",
        uf: "SC",
        estadoCod: 24,
        cod: 4322,
      },
      {
        nome: "Angelina",
        uf: "SC",
        estadoCod: 24,
        cod: 4323,
      },
      {
        nome: "Anita Garibaldi",
        uf: "SC",
        estadoCod: 24,
        cod: 4324,
      },
      {
        nome: "Anitápolis",
        uf: "SC",
        estadoCod: 24,
        cod: 4325,
      },
      {
        nome: "Antônio Carlos",
        uf: "SC",
        estadoCod: 24,
        cod: 4326,
      },
      {
        nome: "Apiúna",
        uf: "SC",
        estadoCod: 24,
        cod: 4327,
      },
      {
        nome: "Arabutã",
        uf: "SC",
        estadoCod: 24,
        cod: 4328,
      },
      {
        nome: "Araquari",
        uf: "SC",
        estadoCod: 24,
        cod: 4329,
      },
      {
        nome: "Araranguá",
        uf: "SC",
        estadoCod: 24,
        cod: 4330,
      },
      {
        nome: "Armazém",
        uf: "SC",
        estadoCod: 24,
        cod: 4331,
      },
      {
        nome: "Arroio Trinta",
        uf: "SC",
        estadoCod: 24,
        cod: 4332,
      },
      {
        nome: "Arvoredo",
        uf: "SC",
        estadoCod: 24,
        cod: 4333,
      },
      {
        nome: "Ascurra",
        uf: "SC",
        estadoCod: 24,
        cod: 4334,
      },
      {
        nome: "Atalanta",
        uf: "SC",
        estadoCod: 24,
        cod: 4335,
      },
      {
        nome: "Aurora",
        uf: "SC",
        estadoCod: 24,
        cod: 4336,
      },
      {
        nome: "Balneário Arroio do Silva",
        uf: "SC",
        estadoCod: 24,
        cod: 4337,
      },
      {
        nome: "Balneário Camboriú",
        uf: "SC",
        estadoCod: 24,
        cod: 4338,
      },
      {
        nome: "Balneário Barra do Sul",
        uf: "SC",
        estadoCod: 24,
        cod: 4339,
      },
      {
        nome: "Balneário Gaivota",
        uf: "SC",
        estadoCod: 24,
        cod: 4340,
      },
      {
        nome: "Bandeirante",
        uf: "SC",
        estadoCod: 24,
        cod: 4341,
      },
      {
        nome: "Barra Bonita",
        uf: "SC",
        estadoCod: 24,
        cod: 4342,
      },
      {
        nome: "Barra Velha",
        uf: "SC",
        estadoCod: 24,
        cod: 4343,
      },
      {
        nome: "Bela Vista do Toldo",
        uf: "SC",
        estadoCod: 24,
        cod: 4344,
      },
      {
        nome: "Belmonte",
        uf: "SC",
        estadoCod: 24,
        cod: 4345,
      },
      {
        nome: "Benedito Novo",
        uf: "SC",
        estadoCod: 24,
        cod: 4346,
      },
      {
        nome: "Biguaçu",
        uf: "SC",
        estadoCod: 24,
        cod: 4347,
      },
      {
        nome: "Blumenau",
        uf: "SC",
        estadoCod: 24,
        cod: 4348,
      },
      {
        nome: "Bocaina do Sul",
        uf: "SC",
        estadoCod: 24,
        cod: 4349,
      },
      {
        nome: "Bombinhas",
        uf: "SC",
        estadoCod: 24,
        cod: 4350,
      },
      {
        nome: "Bom Jardim da Serra",
        uf: "SC",
        estadoCod: 24,
        cod: 4351,
      },
      {
        nome: "Bom Jesus",
        uf: "SC",
        estadoCod: 24,
        cod: 4352,
      },
      {
        nome: "Bom Jesus do Oeste",
        uf: "SC",
        estadoCod: 24,
        cod: 4353,
      },
      {
        nome: "Bom Retiro",
        uf: "SC",
        estadoCod: 24,
        cod: 4354,
      },
      {
        nome: "Botuverá",
        uf: "SC",
        estadoCod: 24,
        cod: 4355,
      },
      {
        nome: "Braço do Norte",
        uf: "SC",
        estadoCod: 24,
        cod: 4356,
      },
      {
        nome: "Braço do Trombudo",
        uf: "SC",
        estadoCod: 24,
        cod: 4357,
      },
      {
        nome: "Brunópolis",
        uf: "SC",
        estadoCod: 24,
        cod: 4358,
      },
      {
        nome: "Brusque",
        uf: "SC",
        estadoCod: 24,
        cod: 4359,
      },
      {
        nome: "Caçador",
        uf: "SC",
        estadoCod: 24,
        cod: 4360,
      },
      {
        nome: "Caibi",
        uf: "SC",
        estadoCod: 24,
        cod: 4361,
      },
      {
        nome: "Calmon",
        uf: "SC",
        estadoCod: 24,
        cod: 4362,
      },
      {
        nome: "Camboriú",
        uf: "SC",
        estadoCod: 24,
        cod: 4363,
      },
      {
        nome: "Capão Alto",
        uf: "SC",
        estadoCod: 24,
        cod: 4364,
      },
      {
        nome: "Campo Alegre",
        uf: "SC",
        estadoCod: 24,
        cod: 4365,
      },
      {
        nome: "Campo Belo do Sul",
        uf: "SC",
        estadoCod: 24,
        cod: 4366,
      },
      {
        nome: "Campo Erê",
        uf: "SC",
        estadoCod: 24,
        cod: 4367,
      },
      {
        nome: "Campos Novos",
        uf: "SC",
        estadoCod: 24,
        cod: 4368,
      },
      {
        nome: "Canelinha",
        uf: "SC",
        estadoCod: 24,
        cod: 4369,
      },
      {
        nome: "Canoinhas",
        uf: "SC",
        estadoCod: 24,
        cod: 4370,
      },
      {
        nome: "Capinzal",
        uf: "SC",
        estadoCod: 24,
        cod: 4371,
      },
      {
        nome: "Capivari de Baixo",
        uf: "SC",
        estadoCod: 24,
        cod: 4372,
      },
      {
        nome: "Catanduvas",
        uf: "SC",
        estadoCod: 24,
        cod: 4373,
      },
      {
        nome: "Caxambu do Sul",
        uf: "SC",
        estadoCod: 24,
        cod: 4374,
      },
      {
        nome: "Celso Ramos",
        uf: "SC",
        estadoCod: 24,
        cod: 4375,
      },
      {
        nome: "Cerro Negro",
        uf: "SC",
        estadoCod: 24,
        cod: 4376,
      },
      {
        nome: "Chapadão do Lageado",
        uf: "SC",
        estadoCod: 24,
        cod: 4377,
      },
      {
        nome: "Chapecó",
        uf: "SC",
        estadoCod: 24,
        cod: 4378,
      },
      {
        nome: "Cocal do Sul",
        uf: "SC",
        estadoCod: 24,
        cod: 4379,
      },
      {
        nome: "Concórdia",
        uf: "SC",
        estadoCod: 24,
        cod: 4380,
      },
      {
        nome: "Cordilheira Alta",
        uf: "SC",
        estadoCod: 24,
        cod: 4381,
      },
      {
        nome: "Coronel Freitas",
        uf: "SC",
        estadoCod: 24,
        cod: 4382,
      },
      {
        nome: "Coronel Martins",
        uf: "SC",
        estadoCod: 24,
        cod: 4383,
      },
      {
        nome: "Corupá",
        uf: "SC",
        estadoCod: 24,
        cod: 4384,
      },
      {
        nome: "Correia Pinto",
        uf: "SC",
        estadoCod: 24,
        cod: 4385,
      },
      {
        nome: "Criciúma",
        uf: "SC",
        estadoCod: 24,
        cod: 4386,
      },
      {
        nome: "Cunha Porã",
        uf: "SC",
        estadoCod: 24,
        cod: 4387,
      },
      {
        nome: "Cunhataí",
        uf: "SC",
        estadoCod: 24,
        cod: 4388,
      },
      {
        nome: "Curitibanos",
        uf: "SC",
        estadoCod: 24,
        cod: 4389,
      },
      {
        nome: "Descanso",
        uf: "SC",
        estadoCod: 24,
        cod: 4390,
      },
      {
        nome: "Dionísio Cerqueira",
        uf: "SC",
        estadoCod: 24,
        cod: 4391,
      },
      {
        nome: "Dona Emma",
        uf: "SC",
        estadoCod: 24,
        cod: 4392,
      },
      {
        nome: "Doutor Pedrinho",
        uf: "SC",
        estadoCod: 24,
        cod: 4393,
      },
      {
        nome: "Entre Rios",
        uf: "SC",
        estadoCod: 24,
        cod: 4394,
      },
      {
        nome: "Ermo",
        uf: "SC",
        estadoCod: 24,
        cod: 4395,
      },
      {
        nome: "Erval Velho",
        uf: "SC",
        estadoCod: 24,
        cod: 4396,
      },
      {
        nome: "Faxinal dos Guedes",
        uf: "SC",
        estadoCod: 24,
        cod: 4397,
      },
      {
        nome: "Flor do Sertão",
        uf: "SC",
        estadoCod: 24,
        cod: 4398,
      },
      {
        nome: "Florianópolis",
        uf: "SC",
        estadoCod: 24,
        cod: 4399,
      },
      {
        nome: "Formosa do Sul",
        uf: "SC",
        estadoCod: 24,
        cod: 4400,
      },
      {
        nome: "Forquilhinha",
        uf: "SC",
        estadoCod: 24,
        cod: 4401,
      },
      {
        nome: "Fraiburgo",
        uf: "SC",
        estadoCod: 24,
        cod: 4402,
      },
      {
        nome: "Frei Rogério",
        uf: "SC",
        estadoCod: 24,
        cod: 4403,
      },
      {
        nome: "Galvão",
        uf: "SC",
        estadoCod: 24,
        cod: 4404,
      },
      {
        nome: "Garopaba",
        uf: "SC",
        estadoCod: 24,
        cod: 4405,
      },
      {
        nome: "Garuva",
        uf: "SC",
        estadoCod: 24,
        cod: 4406,
      },
      {
        nome: "Gaspar",
        uf: "SC",
        estadoCod: 24,
        cod: 4407,
      },
      {
        nome: "Governador Celso Ramos",
        uf: "SC",
        estadoCod: 24,
        cod: 4408,
      },
      {
        nome: "Grão Pará",
        uf: "SC",
        estadoCod: 24,
        cod: 4409,
      },
      {
        nome: "Gravatal",
        uf: "SC",
        estadoCod: 24,
        cod: 4410,
      },
      {
        nome: "Guabiruba",
        uf: "SC",
        estadoCod: 24,
        cod: 4411,
      },
      {
        nome: "Guaraciaba",
        uf: "SC",
        estadoCod: 24,
        cod: 4412,
      },
      {
        nome: "Guaramirim",
        uf: "SC",
        estadoCod: 24,
        cod: 4413,
      },
      {
        nome: "Guarujá do Sul",
        uf: "SC",
        estadoCod: 24,
        cod: 4414,
      },
      {
        nome: "Guatambú",
        uf: "SC",
        estadoCod: 24,
        cod: 4415,
      },
      {
        nome: "Herval D'Oeste",
        uf: "SC",
        estadoCod: 24,
        cod: 4416,
      },
      {
        nome: "Ibiam",
        uf: "SC",
        estadoCod: 24,
        cod: 4417,
      },
      {
        nome: "Ibicaré",
        uf: "SC",
        estadoCod: 24,
        cod: 4418,
      },
      {
        nome: "Ibirama",
        uf: "SC",
        estadoCod: 24,
        cod: 4419,
      },
      {
        nome: "Içara",
        uf: "SC",
        estadoCod: 24,
        cod: 4420,
      },
      {
        nome: "Ilhota",
        uf: "SC",
        estadoCod: 24,
        cod: 4421,
      },
      {
        nome: "Imaruí",
        uf: "SC",
        estadoCod: 24,
        cod: 4422,
      },
      {
        nome: "Imbituba",
        uf: "SC",
        estadoCod: 24,
        cod: 4423,
      },
      {
        nome: "Imbuia",
        uf: "SC",
        estadoCod: 24,
        cod: 4424,
      },
      {
        nome: "Indaial",
        uf: "SC",
        estadoCod: 24,
        cod: 4425,
      },
      {
        nome: "Iomerê",
        uf: "SC",
        estadoCod: 24,
        cod: 4426,
      },
      {
        nome: "Ipira",
        uf: "SC",
        estadoCod: 24,
        cod: 4427,
      },
      {
        nome: "Iporã do Oeste",
        uf: "SC",
        estadoCod: 24,
        cod: 4428,
      },
      {
        nome: "Ipuaçu",
        uf: "SC",
        estadoCod: 24,
        cod: 4429,
      },
      {
        nome: "Ipumirim",
        uf: "SC",
        estadoCod: 24,
        cod: 4430,
      },
      {
        nome: "Iraceminha",
        uf: "SC",
        estadoCod: 24,
        cod: 4431,
      },
      {
        nome: "Irani",
        uf: "SC",
        estadoCod: 24,
        cod: 4432,
      },
      {
        nome: "Irati",
        uf: "SC",
        estadoCod: 24,
        cod: 4433,
      },
      {
        nome: "Irineópolis",
        uf: "SC",
        estadoCod: 24,
        cod: 4434,
      },
      {
        nome: "Itá",
        uf: "SC",
        estadoCod: 24,
        cod: 4435,
      },
      {
        nome: "Itaiópolis",
        uf: "SC",
        estadoCod: 24,
        cod: 4436,
      },
      {
        nome: "Itajaí",
        uf: "SC",
        estadoCod: 24,
        cod: 4437,
      },
      {
        nome: "Itapema",
        uf: "SC",
        estadoCod: 24,
        cod: 4438,
      },
      {
        nome: "Itapiranga",
        uf: "SC",
        estadoCod: 24,
        cod: 4439,
      },
      {
        nome: "Itapoá",
        uf: "SC",
        estadoCod: 24,
        cod: 4440,
      },
      {
        nome: "Ituporanga",
        uf: "SC",
        estadoCod: 24,
        cod: 4441,
      },
      {
        nome: "Jaborá",
        uf: "SC",
        estadoCod: 24,
        cod: 4442,
      },
      {
        nome: "Jacinto Machado",
        uf: "SC",
        estadoCod: 24,
        cod: 4443,
      },
      {
        nome: "Jaguaruna",
        uf: "SC",
        estadoCod: 24,
        cod: 4444,
      },
      {
        nome: "Jaraguá do Sul",
        uf: "SC",
        estadoCod: 24,
        cod: 4445,
      },
      {
        nome: "Jardinópolis",
        uf: "SC",
        estadoCod: 24,
        cod: 4446,
      },
      {
        nome: "Joaçaba",
        uf: "SC",
        estadoCod: 24,
        cod: 4447,
      },
      {
        nome: "Joinville",
        uf: "SC",
        estadoCod: 24,
        cod: 4448,
      },
      {
        nome: "José Boiteux",
        uf: "SC",
        estadoCod: 24,
        cod: 4449,
      },
      {
        nome: "Jupiá",
        uf: "SC",
        estadoCod: 24,
        cod: 4450,
      },
      {
        nome: "Lacerdópolis",
        uf: "SC",
        estadoCod: 24,
        cod: 4451,
      },
      {
        nome: "Lages",
        uf: "SC",
        estadoCod: 24,
        cod: 4452,
      },
      {
        nome: "Laguna",
        uf: "SC",
        estadoCod: 24,
        cod: 4453,
      },
      {
        nome: "Lajeado Grande",
        uf: "SC",
        estadoCod: 24,
        cod: 4454,
      },
      {
        nome: "Laurentino",
        uf: "SC",
        estadoCod: 24,
        cod: 4455,
      },
      {
        nome: "Lauro Muller",
        uf: "SC",
        estadoCod: 24,
        cod: 4456,
      },
      {
        nome: "Lebon Régis",
        uf: "SC",
        estadoCod: 24,
        cod: 4457,
      },
      {
        nome: "Leoberto Leal",
        uf: "SC",
        estadoCod: 24,
        cod: 4458,
      },
      {
        nome: "Lindóia do Sul",
        uf: "SC",
        estadoCod: 24,
        cod: 4459,
      },
      {
        nome: "Lontras",
        uf: "SC",
        estadoCod: 24,
        cod: 4460,
      },
      {
        nome: "Luiz Alves",
        uf: "SC",
        estadoCod: 24,
        cod: 4461,
      },
      {
        nome: "Luzerna",
        uf: "SC",
        estadoCod: 24,
        cod: 4462,
      },
      {
        nome: "Macieira",
        uf: "SC",
        estadoCod: 24,
        cod: 4463,
      },
      {
        nome: "Mafra",
        uf: "SC",
        estadoCod: 24,
        cod: 4464,
      },
      {
        nome: "Major Gercino",
        uf: "SC",
        estadoCod: 24,
        cod: 4465,
      },
      {
        nome: "Major Vieira",
        uf: "SC",
        estadoCod: 24,
        cod: 4466,
      },
      {
        nome: "Maracajá",
        uf: "SC",
        estadoCod: 24,
        cod: 4467,
      },
      {
        nome: "Maravilha",
        uf: "SC",
        estadoCod: 24,
        cod: 4468,
      },
      {
        nome: "Marema",
        uf: "SC",
        estadoCod: 24,
        cod: 4469,
      },
      {
        nome: "Massaranduba",
        uf: "SC",
        estadoCod: 24,
        cod: 4470,
      },
      {
        nome: "Matos Costa",
        uf: "SC",
        estadoCod: 24,
        cod: 4471,
      },
      {
        nome: "Meleiro",
        uf: "SC",
        estadoCod: 24,
        cod: 4472,
      },
      {
        nome: "Mirim Doce",
        uf: "SC",
        estadoCod: 24,
        cod: 4473,
      },
      {
        nome: "Modelo",
        uf: "SC",
        estadoCod: 24,
        cod: 4474,
      },
      {
        nome: "Mondaí",
        uf: "SC",
        estadoCod: 24,
        cod: 4475,
      },
      {
        nome: "Monte Carlo",
        uf: "SC",
        estadoCod: 24,
        cod: 4476,
      },
      {
        nome: "Monte Castelo",
        uf: "SC",
        estadoCod: 24,
        cod: 4477,
      },
      {
        nome: "Morro da Fumaça",
        uf: "SC",
        estadoCod: 24,
        cod: 4478,
      },
      {
        nome: "Morro Grande",
        uf: "SC",
        estadoCod: 24,
        cod: 4479,
      },
      {
        nome: "Navegantes",
        uf: "SC",
        estadoCod: 24,
        cod: 4480,
      },
      {
        nome: "Nova Erechim",
        uf: "SC",
        estadoCod: 24,
        cod: 4481,
      },
      {
        nome: "Nova Itaberaba",
        uf: "SC",
        estadoCod: 24,
        cod: 4482,
      },
      {
        nome: "Nova Trento",
        uf: "SC",
        estadoCod: 24,
        cod: 4483,
      },
      {
        nome: "Nova Veneza",
        uf: "SC",
        estadoCod: 24,
        cod: 4484,
      },
      {
        nome: "Novo Horizonte",
        uf: "SC",
        estadoCod: 24,
        cod: 4485,
      },
      {
        nome: "Orleans",
        uf: "SC",
        estadoCod: 24,
        cod: 4486,
      },
      {
        nome: "Otacílio Costa",
        uf: "SC",
        estadoCod: 24,
        cod: 4487,
      },
      {
        nome: "Ouro",
        uf: "SC",
        estadoCod: 24,
        cod: 4488,
      },
      {
        nome: "Ouro Verde",
        uf: "SC",
        estadoCod: 24,
        cod: 4489,
      },
      {
        nome: "Paial",
        uf: "SC",
        estadoCod: 24,
        cod: 4490,
      },
      {
        nome: "Painel",
        uf: "SC",
        estadoCod: 24,
        cod: 4491,
      },
      {
        nome: "Palhoça",
        uf: "SC",
        estadoCod: 24,
        cod: 4492,
      },
      {
        nome: "Palma Sola",
        uf: "SC",
        estadoCod: 24,
        cod: 4493,
      },
      {
        nome: "Palmeira",
        uf: "SC",
        estadoCod: 24,
        cod: 4494,
      },
      {
        nome: "Palmitos",
        uf: "SC",
        estadoCod: 24,
        cod: 4495,
      },
      {
        nome: "Papanduva",
        uf: "SC",
        estadoCod: 24,
        cod: 4496,
      },
      {
        nome: "Paraíso",
        uf: "SC",
        estadoCod: 24,
        cod: 4497,
      },
      {
        nome: "Passo de Torres",
        uf: "SC",
        estadoCod: 24,
        cod: 4498,
      },
      {
        nome: "Passos Maia",
        uf: "SC",
        estadoCod: 24,
        cod: 4499,
      },
      {
        nome: "Paulo Lopes",
        uf: "SC",
        estadoCod: 24,
        cod: 4500,
      },
      {
        nome: "Pedras Grandes",
        uf: "SC",
        estadoCod: 24,
        cod: 4501,
      },
      {
        nome: "Penha",
        uf: "SC",
        estadoCod: 24,
        cod: 4502,
      },
      {
        nome: "Peritiba",
        uf: "SC",
        estadoCod: 24,
        cod: 4503,
      },
      {
        nome: "Pescaria Brava",
        uf: "SC",
        estadoCod: 24,
        cod: 4504,
      },
      {
        nome: "Petrolândia",
        uf: "SC",
        estadoCod: 24,
        cod: 4505,
      },
      {
        nome: "Balneário Piçarras",
        uf: "SC",
        estadoCod: 24,
        cod: 4506,
      },
      {
        nome: "Pinhalzinho",
        uf: "SC",
        estadoCod: 24,
        cod: 4507,
      },
      {
        nome: "Pinheiro Preto",
        uf: "SC",
        estadoCod: 24,
        cod: 4508,
      },
      {
        nome: "Piratuba",
        uf: "SC",
        estadoCod: 24,
        cod: 4509,
      },
      {
        nome: "Planalto Alegre",
        uf: "SC",
        estadoCod: 24,
        cod: 4510,
      },
      {
        nome: "Pomerode",
        uf: "SC",
        estadoCod: 24,
        cod: 4511,
      },
      {
        nome: "Ponte Alta",
        uf: "SC",
        estadoCod: 24,
        cod: 4512,
      },
      {
        nome: "Ponte Alta do Norte",
        uf: "SC",
        estadoCod: 24,
        cod: 4513,
      },
      {
        nome: "Ponte Serrada",
        uf: "SC",
        estadoCod: 24,
        cod: 4514,
      },
      {
        nome: "Porto Belo",
        uf: "SC",
        estadoCod: 24,
        cod: 4515,
      },
      {
        nome: "Porto União",
        uf: "SC",
        estadoCod: 24,
        cod: 4516,
      },
      {
        nome: "Pouso Redondo",
        uf: "SC",
        estadoCod: 24,
        cod: 4517,
      },
      {
        nome: "Praia Grande",
        uf: "SC",
        estadoCod: 24,
        cod: 4518,
      },
      {
        nome: "Presidente Castello Branco",
        uf: "SC",
        estadoCod: 24,
        cod: 4519,
      },
      {
        nome: "Presidente Getúlio",
        uf: "SC",
        estadoCod: 24,
        cod: 4520,
      },
      {
        nome: "Presidente Nereu",
        uf: "SC",
        estadoCod: 24,
        cod: 4521,
      },
      {
        nome: "Princesa",
        uf: "SC",
        estadoCod: 24,
        cod: 4522,
      },
      {
        nome: "Quilombo",
        uf: "SC",
        estadoCod: 24,
        cod: 4523,
      },
      {
        nome: "Rancho Queimado",
        uf: "SC",
        estadoCod: 24,
        cod: 4524,
      },
      {
        nome: "Rio das Antas",
        uf: "SC",
        estadoCod: 24,
        cod: 4525,
      },
      {
        nome: "Rio do Campo",
        uf: "SC",
        estadoCod: 24,
        cod: 4526,
      },
      {
        nome: "Rio do Oeste",
        uf: "SC",
        estadoCod: 24,
        cod: 4527,
      },
      {
        nome: "Rio dos Cedros",
        uf: "SC",
        estadoCod: 24,
        cod: 4528,
      },
      {
        nome: "Rio do Sul",
        uf: "SC",
        estadoCod: 24,
        cod: 4529,
      },
      {
        nome: "Rio Fortuna",
        uf: "SC",
        estadoCod: 24,
        cod: 4530,
      },
      {
        nome: "Rio Negrinho",
        uf: "SC",
        estadoCod: 24,
        cod: 4531,
      },
      {
        nome: "Rio Rufino",
        uf: "SC",
        estadoCod: 24,
        cod: 4532,
      },
      {
        nome: "Riqueza",
        uf: "SC",
        estadoCod: 24,
        cod: 4533,
      },
      {
        nome: "Rodeio",
        uf: "SC",
        estadoCod: 24,
        cod: 4534,
      },
      {
        nome: "Romelândia",
        uf: "SC",
        estadoCod: 24,
        cod: 4535,
      },
      {
        nome: "Salete",
        uf: "SC",
        estadoCod: 24,
        cod: 4536,
      },
      {
        nome: "Saltinho",
        uf: "SC",
        estadoCod: 24,
        cod: 4537,
      },
      {
        nome: "Salto Veloso",
        uf: "SC",
        estadoCod: 24,
        cod: 4538,
      },
      {
        nome: "Sangão",
        uf: "SC",
        estadoCod: 24,
        cod: 4539,
      },
      {
        nome: "Santa Cecília",
        uf: "SC",
        estadoCod: 24,
        cod: 4540,
      },
      {
        nome: "Santa Helena",
        uf: "SC",
        estadoCod: 24,
        cod: 4541,
      },
      {
        nome: "Santa Rosa de Lima",
        uf: "SC",
        estadoCod: 24,
        cod: 4542,
      },
      {
        nome: "Santa Rosa do Sul",
        uf: "SC",
        estadoCod: 24,
        cod: 4543,
      },
      {
        nome: "Santa Terezinha",
        uf: "SC",
        estadoCod: 24,
        cod: 4544,
      },
      {
        nome: "Santa Terezinha do Progresso",
        uf: "SC",
        estadoCod: 24,
        cod: 4545,
      },
      {
        nome: "Santiago do Sul",
        uf: "SC",
        estadoCod: 24,
        cod: 4546,
      },
      {
        nome: "Santo Amaro da Imperatriz",
        uf: "SC",
        estadoCod: 24,
        cod: 4547,
      },
      {
        nome: "São Bernardino",
        uf: "SC",
        estadoCod: 24,
        cod: 4548,
      },
      {
        nome: "São Bento do Sul",
        uf: "SC",
        estadoCod: 24,
        cod: 4549,
      },
      {
        nome: "São Bonifácio",
        uf: "SC",
        estadoCod: 24,
        cod: 4550,
      },
      {
        nome: "São Carlos",
        uf: "SC",
        estadoCod: 24,
        cod: 4551,
      },
      {
        nome: "São Cristovão do Sul",
        uf: "SC",
        estadoCod: 24,
        cod: 4552,
      },
      {
        nome: "São Domingos",
        uf: "SC",
        estadoCod: 24,
        cod: 4553,
      },
      {
        nome: "São Francisco do Sul",
        uf: "SC",
        estadoCod: 24,
        cod: 4554,
      },
      {
        nome: "São João do Oeste",
        uf: "SC",
        estadoCod: 24,
        cod: 4555,
      },
      {
        nome: "São João Batista",
        uf: "SC",
        estadoCod: 24,
        cod: 4556,
      },
      {
        nome: "São João do Itaperiú",
        uf: "SC",
        estadoCod: 24,
        cod: 4557,
      },
      {
        nome: "São João do Sul",
        uf: "SC",
        estadoCod: 24,
        cod: 4558,
      },
      {
        nome: "São Joaquim",
        uf: "SC",
        estadoCod: 24,
        cod: 4559,
      },
      {
        nome: "São José",
        uf: "SC",
        estadoCod: 24,
        cod: 4560,
      },
      {
        nome: "São José do Cedro",
        uf: "SC",
        estadoCod: 24,
        cod: 4561,
      },
      {
        nome: "São José do Cerrito",
        uf: "SC",
        estadoCod: 24,
        cod: 4562,
      },
      {
        nome: "São Lourenço do Oeste",
        uf: "SC",
        estadoCod: 24,
        cod: 4563,
      },
      {
        nome: "São Ludgero",
        uf: "SC",
        estadoCod: 24,
        cod: 4564,
      },
      {
        nome: "São Martinho",
        uf: "SC",
        estadoCod: 24,
        cod: 4565,
      },
      {
        nome: "São Miguel da Boa Vista",
        uf: "SC",
        estadoCod: 24,
        cod: 4566,
      },
      {
        nome: "São Miguel do Oeste",
        uf: "SC",
        estadoCod: 24,
        cod: 4567,
      },
      {
        nome: "São Pedro de Alcântara",
        uf: "SC",
        estadoCod: 24,
        cod: 4568,
      },
      {
        nome: "Saudades",
        uf: "SC",
        estadoCod: 24,
        cod: 4569,
      },
      {
        nome: "Schroeder",
        uf: "SC",
        estadoCod: 24,
        cod: 4570,
      },
      {
        nome: "Seara",
        uf: "SC",
        estadoCod: 24,
        cod: 4571,
      },
      {
        nome: "Serra Alta",
        uf: "SC",
        estadoCod: 24,
        cod: 4572,
      },
      {
        nome: "Siderópolis",
        uf: "SC",
        estadoCod: 24,
        cod: 4573,
      },
      {
        nome: "Sombrio",
        uf: "SC",
        estadoCod: 24,
        cod: 4574,
      },
      {
        nome: "Sul Brasil",
        uf: "SC",
        estadoCod: 24,
        cod: 4575,
      },
      {
        nome: "Taió",
        uf: "SC",
        estadoCod: 24,
        cod: 4576,
      },
      {
        nome: "Tangará",
        uf: "SC",
        estadoCod: 24,
        cod: 4577,
      },
      {
        nome: "Tigrinhos",
        uf: "SC",
        estadoCod: 24,
        cod: 4578,
      },
      {
        nome: "Tijucas",
        uf: "SC",
        estadoCod: 24,
        cod: 4579,
      },
      {
        nome: "Timbé do Sul",
        uf: "SC",
        estadoCod: 24,
        cod: 4580,
      },
      {
        nome: "Timbó",
        uf: "SC",
        estadoCod: 24,
        cod: 4581,
      },
      {
        nome: "Timbó Grande",
        uf: "SC",
        estadoCod: 24,
        cod: 4582,
      },
      {
        nome: "Três Barras",
        uf: "SC",
        estadoCod: 24,
        cod: 4583,
      },
      {
        nome: "Treviso",
        uf: "SC",
        estadoCod: 24,
        cod: 4584,
      },
      {
        nome: "Treze de Maio",
        uf: "SC",
        estadoCod: 24,
        cod: 4585,
      },
      {
        nome: "Treze Tílias",
        uf: "SC",
        estadoCod: 24,
        cod: 4586,
      },
      {
        nome: "Trombudo Central",
        uf: "SC",
        estadoCod: 24,
        cod: 4587,
      },
      {
        nome: "Tubarão",
        uf: "SC",
        estadoCod: 24,
        cod: 4588,
      },
      {
        nome: "Tunápolis",
        uf: "SC",
        estadoCod: 24,
        cod: 4589,
      },
      {
        nome: "Turvo",
        uf: "SC",
        estadoCod: 24,
        cod: 4590,
      },
      {
        nome: "União do Oeste",
        uf: "SC",
        estadoCod: 24,
        cod: 4591,
      },
      {
        nome: "Urubici",
        uf: "SC",
        estadoCod: 24,
        cod: 4592,
      },
      {
        nome: "Urupema",
        uf: "SC",
        estadoCod: 24,
        cod: 4593,
      },
      {
        nome: "Urussanga",
        uf: "SC",
        estadoCod: 24,
        cod: 4594,
      },
      {
        nome: "Vargeão",
        uf: "SC",
        estadoCod: 24,
        cod: 4595,
      },
      {
        nome: "Vargem",
        uf: "SC",
        estadoCod: 24,
        cod: 4596,
      },
      {
        nome: "Vargem Bonita",
        uf: "SC",
        estadoCod: 24,
        cod: 4597,
      },
      {
        nome: "Vidal Ramos",
        uf: "SC",
        estadoCod: 24,
        cod: 4598,
      },
      {
        nome: "Videira",
        uf: "SC",
        estadoCod: 24,
        cod: 4599,
      },
      {
        nome: "Vitor Meireles",
        uf: "SC",
        estadoCod: 24,
        cod: 4600,
      },
      {
        nome: "Witmarsum",
        uf: "SC",
        estadoCod: 24,
        cod: 4601,
      },
      {
        nome: "Xanxerê",
        uf: "SC",
        estadoCod: 24,
        cod: 4602,
      },
      {
        nome: "Xavantina",
        uf: "SC",
        estadoCod: 24,
        cod: 4603,
      },
      {
        nome: "Xaxim",
        uf: "SC",
        estadoCod: 24,
        cod: 4604,
      },
      {
        nome: "Zortéa",
        uf: "SC",
        estadoCod: 24,
        cod: 4605,
      },
      {
        nome: "Balneário Rincão",
        uf: "SC",
        estadoCod: 24,
        cod: 4606,
      },
    ],
  },
  {
    nome: "Rio Grande do Sul",
    uf: "RS",
    regiaoCod: 4,
    cod: 21,
    cidades: [
      {
        nome: "Aceguá",
        uf: "RS",
        estadoCod: 21,
        cod: 4607,
      },
      {
        nome: "Água Santa",
        uf: "RS",
        estadoCod: 21,
        cod: 4608,
      },
      {
        nome: "Agudo",
        uf: "RS",
        estadoCod: 21,
        cod: 4609,
      },
      {
        nome: "Ajuricaba",
        uf: "RS",
        estadoCod: 21,
        cod: 4610,
      },
      {
        nome: "Alecrim",
        uf: "RS",
        estadoCod: 21,
        cod: 4611,
      },
      {
        nome: "Alegrete",
        uf: "RS",
        estadoCod: 21,
        cod: 4612,
      },
      {
        nome: "Alegria",
        uf: "RS",
        estadoCod: 21,
        cod: 4613,
      },
      {
        nome: "Almirante Tamandaré do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4614,
      },
      {
        nome: "Alpestre",
        uf: "RS",
        estadoCod: 21,
        cod: 4615,
      },
      {
        nome: "Alto Alegre",
        uf: "RS",
        estadoCod: 21,
        cod: 4616,
      },
      {
        nome: "Alto Feliz",
        uf: "RS",
        estadoCod: 21,
        cod: 4617,
      },
      {
        nome: "Alvorada",
        uf: "RS",
        estadoCod: 21,
        cod: 4618,
      },
      {
        nome: "Amaral Ferrador",
        uf: "RS",
        estadoCod: 21,
        cod: 4619,
      },
      {
        nome: "Ametista do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4620,
      },
      {
        nome: "André da Rocha",
        uf: "RS",
        estadoCod: 21,
        cod: 4621,
      },
      {
        nome: "Anta Gorda",
        uf: "RS",
        estadoCod: 21,
        cod: 4622,
      },
      {
        nome: "Antônio Prado",
        uf: "RS",
        estadoCod: 21,
        cod: 4623,
      },
      {
        nome: "Arambaré",
        uf: "RS",
        estadoCod: 21,
        cod: 4624,
      },
      {
        nome: "Araricá",
        uf: "RS",
        estadoCod: 21,
        cod: 4625,
      },
      {
        nome: "Aratiba",
        uf: "RS",
        estadoCod: 21,
        cod: 4626,
      },
      {
        nome: "Arroio do Meio",
        uf: "RS",
        estadoCod: 21,
        cod: 4627,
      },
      {
        nome: "Arroio do Sal",
        uf: "RS",
        estadoCod: 21,
        cod: 4628,
      },
      {
        nome: "Arroio do Padre",
        uf: "RS",
        estadoCod: 21,
        cod: 4629,
      },
      {
        nome: "Arroio dos Ratos",
        uf: "RS",
        estadoCod: 21,
        cod: 4630,
      },
      {
        nome: "Arroio do Tigre",
        uf: "RS",
        estadoCod: 21,
        cod: 4631,
      },
      {
        nome: "Arroio Grande",
        uf: "RS",
        estadoCod: 21,
        cod: 4632,
      },
      {
        nome: "Arvorezinha",
        uf: "RS",
        estadoCod: 21,
        cod: 4633,
      },
      {
        nome: "Augusto Pestana",
        uf: "RS",
        estadoCod: 21,
        cod: 4634,
      },
      {
        nome: "Áurea",
        uf: "RS",
        estadoCod: 21,
        cod: 4635,
      },
      {
        nome: "Bagé",
        uf: "RS",
        estadoCod: 21,
        cod: 4636,
      },
      {
        nome: "Balneário Pinhal",
        uf: "RS",
        estadoCod: 21,
        cod: 4637,
      },
      {
        nome: "Barão",
        uf: "RS",
        estadoCod: 21,
        cod: 4638,
      },
      {
        nome: "Barão de Cotegipe",
        uf: "RS",
        estadoCod: 21,
        cod: 4639,
      },
      {
        nome: "Barão do Triunfo",
        uf: "RS",
        estadoCod: 21,
        cod: 4640,
      },
      {
        nome: "Barracão",
        uf: "RS",
        estadoCod: 21,
        cod: 4641,
      },
      {
        nome: "Barra do Guarita",
        uf: "RS",
        estadoCod: 21,
        cod: 4642,
      },
      {
        nome: "Barra do Quaraí",
        uf: "RS",
        estadoCod: 21,
        cod: 4643,
      },
      {
        nome: "Barra do Ribeiro",
        uf: "RS",
        estadoCod: 21,
        cod: 4644,
      },
      {
        nome: "Barra do Rio Azul",
        uf: "RS",
        estadoCod: 21,
        cod: 4645,
      },
      {
        nome: "Barra Funda",
        uf: "RS",
        estadoCod: 21,
        cod: 4646,
      },
      {
        nome: "Barros Cassal",
        uf: "RS",
        estadoCod: 21,
        cod: 4647,
      },
      {
        nome: "Benjamin Constant do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4648,
      },
      {
        nome: "Bento Gonçalves",
        uf: "RS",
        estadoCod: 21,
        cod: 4649,
      },
      {
        nome: "Boa Vista das Missões",
        uf: "RS",
        estadoCod: 21,
        cod: 4650,
      },
      {
        nome: "Boa Vista do Buricá",
        uf: "RS",
        estadoCod: 21,
        cod: 4651,
      },
      {
        nome: "Boa Vista do Cadeado",
        uf: "RS",
        estadoCod: 21,
        cod: 4652,
      },
      {
        nome: "Boa Vista do Incra",
        uf: "RS",
        estadoCod: 21,
        cod: 4653,
      },
      {
        nome: "Boa Vista do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4654,
      },
      {
        nome: "Bom Jesus",
        uf: "RS",
        estadoCod: 21,
        cod: 4655,
      },
      {
        nome: "Bom Princípio",
        uf: "RS",
        estadoCod: 21,
        cod: 4656,
      },
      {
        nome: "Bom Progresso",
        uf: "RS",
        estadoCod: 21,
        cod: 4657,
      },
      {
        nome: "Bom Retiro do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4658,
      },
      {
        nome: "Boqueirão do Leão",
        uf: "RS",
        estadoCod: 21,
        cod: 4659,
      },
      {
        nome: "Bossoroca",
        uf: "RS",
        estadoCod: 21,
        cod: 4660,
      },
      {
        nome: "Bozano",
        uf: "RS",
        estadoCod: 21,
        cod: 4661,
      },
      {
        nome: "Braga",
        uf: "RS",
        estadoCod: 21,
        cod: 4662,
      },
      {
        nome: "Brochier",
        uf: "RS",
        estadoCod: 21,
        cod: 4663,
      },
      {
        nome: "Butiá",
        uf: "RS",
        estadoCod: 21,
        cod: 4664,
      },
      {
        nome: "Caçapava do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4665,
      },
      {
        nome: "Cacequi",
        uf: "RS",
        estadoCod: 21,
        cod: 4666,
      },
      {
        nome: "Cachoeira do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4667,
      },
      {
        nome: "Cachoeirinha",
        uf: "RS",
        estadoCod: 21,
        cod: 4668,
      },
      {
        nome: "Cacique Doble",
        uf: "RS",
        estadoCod: 21,
        cod: 4669,
      },
      {
        nome: "Caibaté",
        uf: "RS",
        estadoCod: 21,
        cod: 4670,
      },
      {
        nome: "Caiçara",
        uf: "RS",
        estadoCod: 21,
        cod: 4671,
      },
      {
        nome: "Camaquã",
        uf: "RS",
        estadoCod: 21,
        cod: 4672,
      },
      {
        nome: "Camargo",
        uf: "RS",
        estadoCod: 21,
        cod: 4673,
      },
      {
        nome: "Cambará do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4674,
      },
      {
        nome: "Campestre da Serra",
        uf: "RS",
        estadoCod: 21,
        cod: 4675,
      },
      {
        nome: "Campina das Missões",
        uf: "RS",
        estadoCod: 21,
        cod: 4676,
      },
      {
        nome: "Campinas do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4677,
      },
      {
        nome: "Campo Bom",
        uf: "RS",
        estadoCod: 21,
        cod: 4678,
      },
      {
        nome: "Campo Novo",
        uf: "RS",
        estadoCod: 21,
        cod: 4679,
      },
      {
        nome: "Campos Borges",
        uf: "RS",
        estadoCod: 21,
        cod: 4680,
      },
      {
        nome: "Candelária",
        uf: "RS",
        estadoCod: 21,
        cod: 4681,
      },
      {
        nome: "Cândido Godói",
        uf: "RS",
        estadoCod: 21,
        cod: 4682,
      },
      {
        nome: "Candiota",
        uf: "RS",
        estadoCod: 21,
        cod: 4683,
      },
      {
        nome: "Canela",
        uf: "RS",
        estadoCod: 21,
        cod: 4684,
      },
      {
        nome: "Canguçu",
        uf: "RS",
        estadoCod: 21,
        cod: 4685,
      },
      {
        nome: "Canoas",
        uf: "RS",
        estadoCod: 21,
        cod: 4686,
      },
      {
        nome: "Canudos do Vale",
        uf: "RS",
        estadoCod: 21,
        cod: 4687,
      },
      {
        nome: "Capão Bonito do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4688,
      },
      {
        nome: "Capão da Canoa",
        uf: "RS",
        estadoCod: 21,
        cod: 4689,
      },
      {
        nome: "Capão do Cipó",
        uf: "RS",
        estadoCod: 21,
        cod: 4690,
      },
      {
        nome: "Capão do Leão",
        uf: "RS",
        estadoCod: 21,
        cod: 4691,
      },
      {
        nome: "Capivari do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4692,
      },
      {
        nome: "Capela de Santana",
        uf: "RS",
        estadoCod: 21,
        cod: 4693,
      },
      {
        nome: "Capitão",
        uf: "RS",
        estadoCod: 21,
        cod: 4694,
      },
      {
        nome: "Carazinho",
        uf: "RS",
        estadoCod: 21,
        cod: 4695,
      },
      {
        nome: "Caraá",
        uf: "RS",
        estadoCod: 21,
        cod: 4696,
      },
      {
        nome: "Carlos Barbosa",
        uf: "RS",
        estadoCod: 21,
        cod: 4697,
      },
      {
        nome: "Carlos Gomes",
        uf: "RS",
        estadoCod: 21,
        cod: 4698,
      },
      {
        nome: "Casca",
        uf: "RS",
        estadoCod: 21,
        cod: 4699,
      },
      {
        nome: "Caseiros",
        uf: "RS",
        estadoCod: 21,
        cod: 4700,
      },
      {
        nome: "Catuípe",
        uf: "RS",
        estadoCod: 21,
        cod: 4701,
      },
      {
        nome: "Caxias do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4702,
      },
      {
        nome: "Centenário",
        uf: "RS",
        estadoCod: 21,
        cod: 4703,
      },
      {
        nome: "Cerrito",
        uf: "RS",
        estadoCod: 21,
        cod: 4704,
      },
      {
        nome: "Cerro Branco",
        uf: "RS",
        estadoCod: 21,
        cod: 4705,
      },
      {
        nome: "Cerro Grande",
        uf: "RS",
        estadoCod: 21,
        cod: 4706,
      },
      {
        nome: "Cerro Grande do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4707,
      },
      {
        nome: "Cerro Largo",
        uf: "RS",
        estadoCod: 21,
        cod: 4708,
      },
      {
        nome: "Chapada",
        uf: "RS",
        estadoCod: 21,
        cod: 4709,
      },
      {
        nome: "Charqueadas",
        uf: "RS",
        estadoCod: 21,
        cod: 4710,
      },
      {
        nome: "Charrua",
        uf: "RS",
        estadoCod: 21,
        cod: 4711,
      },
      {
        nome: "Chiapetta",
        uf: "RS",
        estadoCod: 21,
        cod: 4712,
      },
      {
        nome: "Chuí",
        uf: "RS",
        estadoCod: 21,
        cod: 4713,
      },
      {
        nome: "Chuvisca",
        uf: "RS",
        estadoCod: 21,
        cod: 4714,
      },
      {
        nome: "Cidreira",
        uf: "RS",
        estadoCod: 21,
        cod: 4715,
      },
      {
        nome: "Ciríaco",
        uf: "RS",
        estadoCod: 21,
        cod: 4716,
      },
      {
        nome: "Colinas",
        uf: "RS",
        estadoCod: 21,
        cod: 4717,
      },
      {
        nome: "Colorado",
        uf: "RS",
        estadoCod: 21,
        cod: 4718,
      },
      {
        nome: "Condor",
        uf: "RS",
        estadoCod: 21,
        cod: 4719,
      },
      {
        nome: "Constantina",
        uf: "RS",
        estadoCod: 21,
        cod: 4720,
      },
      {
        nome: "Coqueiro Baixo",
        uf: "RS",
        estadoCod: 21,
        cod: 4721,
      },
      {
        nome: "Coqueiros do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4722,
      },
      {
        nome: "Coronel Barros",
        uf: "RS",
        estadoCod: 21,
        cod: 4723,
      },
      {
        nome: "Coronel Bicaco",
        uf: "RS",
        estadoCod: 21,
        cod: 4724,
      },
      {
        nome: "Coronel Pilar",
        uf: "RS",
        estadoCod: 21,
        cod: 4725,
      },
      {
        nome: "Cotiporã",
        uf: "RS",
        estadoCod: 21,
        cod: 4726,
      },
      {
        nome: "Coxilha",
        uf: "RS",
        estadoCod: 21,
        cod: 4727,
      },
      {
        nome: "Crissiumal",
        uf: "RS",
        estadoCod: 21,
        cod: 4728,
      },
      {
        nome: "Cristal",
        uf: "RS",
        estadoCod: 21,
        cod: 4729,
      },
      {
        nome: "Cristal do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4730,
      },
      {
        nome: "Cruz Alta",
        uf: "RS",
        estadoCod: 21,
        cod: 4731,
      },
      {
        nome: "Cruzaltense",
        uf: "RS",
        estadoCod: 21,
        cod: 4732,
      },
      {
        nome: "Cruzeiro do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4733,
      },
      {
        nome: "David Canabarro",
        uf: "RS",
        estadoCod: 21,
        cod: 4734,
      },
      {
        nome: "Derrubadas",
        uf: "RS",
        estadoCod: 21,
        cod: 4735,
      },
      {
        nome: "Dezesseis de Novembro",
        uf: "RS",
        estadoCod: 21,
        cod: 4736,
      },
      {
        nome: "Dilermando de Aguiar",
        uf: "RS",
        estadoCod: 21,
        cod: 4737,
      },
      {
        nome: "Dois Irmãos",
        uf: "RS",
        estadoCod: 21,
        cod: 4738,
      },
      {
        nome: "Dois Irmãos das Missões",
        uf: "RS",
        estadoCod: 21,
        cod: 4739,
      },
      {
        nome: "Dois Lajeados",
        uf: "RS",
        estadoCod: 21,
        cod: 4740,
      },
      {
        nome: "Dom Feliciano",
        uf: "RS",
        estadoCod: 21,
        cod: 4741,
      },
      {
        nome: "Dom Pedro de Alcântara",
        uf: "RS",
        estadoCod: 21,
        cod: 4742,
      },
      {
        nome: "Dom Pedrito",
        uf: "RS",
        estadoCod: 21,
        cod: 4743,
      },
      {
        nome: "Dona Francisca",
        uf: "RS",
        estadoCod: 21,
        cod: 4744,
      },
      {
        nome: "Doutor Maurício Cardoso",
        uf: "RS",
        estadoCod: 21,
        cod: 4745,
      },
      {
        nome: "Doutor Ricardo",
        uf: "RS",
        estadoCod: 21,
        cod: 4746,
      },
      {
        nome: "Eldorado do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4747,
      },
      {
        nome: "Encantado",
        uf: "RS",
        estadoCod: 21,
        cod: 4748,
      },
      {
        nome: "Encruzilhada do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4749,
      },
      {
        nome: "Engenho Velho",
        uf: "RS",
        estadoCod: 21,
        cod: 4750,
      },
      {
        nome: "Entre-Ijuís",
        uf: "RS",
        estadoCod: 21,
        cod: 4751,
      },
      {
        nome: "Entre Rios do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4752,
      },
      {
        nome: "Erebango",
        uf: "RS",
        estadoCod: 21,
        cod: 4753,
      },
      {
        nome: "Erechim",
        uf: "RS",
        estadoCod: 21,
        cod: 4754,
      },
      {
        nome: "Ernestina",
        uf: "RS",
        estadoCod: 21,
        cod: 4755,
      },
      {
        nome: "Herval",
        uf: "RS",
        estadoCod: 21,
        cod: 4756,
      },
      {
        nome: "Erval Grande",
        uf: "RS",
        estadoCod: 21,
        cod: 4757,
      },
      {
        nome: "Erval Seco",
        uf: "RS",
        estadoCod: 21,
        cod: 4758,
      },
      {
        nome: "Esmeralda",
        uf: "RS",
        estadoCod: 21,
        cod: 4759,
      },
      {
        nome: "Esperança do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4760,
      },
      {
        nome: "Espumoso",
        uf: "RS",
        estadoCod: 21,
        cod: 4761,
      },
      {
        nome: "Estação",
        uf: "RS",
        estadoCod: 21,
        cod: 4762,
      },
      {
        nome: "Estância Velha",
        uf: "RS",
        estadoCod: 21,
        cod: 4763,
      },
      {
        nome: "Esteio",
        uf: "RS",
        estadoCod: 21,
        cod: 4764,
      },
      {
        nome: "Estrela",
        uf: "RS",
        estadoCod: 21,
        cod: 4765,
      },
      {
        nome: "Estrela Velha",
        uf: "RS",
        estadoCod: 21,
        cod: 4766,
      },
      {
        nome: "Eugênio de Castro",
        uf: "RS",
        estadoCod: 21,
        cod: 4767,
      },
      {
        nome: "Fagundes Varela",
        uf: "RS",
        estadoCod: 21,
        cod: 4768,
      },
      {
        nome: "Farroupilha",
        uf: "RS",
        estadoCod: 21,
        cod: 4769,
      },
      {
        nome: "Faxinal do Soturno",
        uf: "RS",
        estadoCod: 21,
        cod: 4770,
      },
      {
        nome: "Faxinalzinho",
        uf: "RS",
        estadoCod: 21,
        cod: 4771,
      },
      {
        nome: "Fazenda Vilanova",
        uf: "RS",
        estadoCod: 21,
        cod: 4772,
      },
      {
        nome: "Feliz",
        uf: "RS",
        estadoCod: 21,
        cod: 4773,
      },
      {
        nome: "Flores da Cunha",
        uf: "RS",
        estadoCod: 21,
        cod: 4774,
      },
      {
        nome: "Floriano Peixoto",
        uf: "RS",
        estadoCod: 21,
        cod: 4775,
      },
      {
        nome: "Fontoura Xavier",
        uf: "RS",
        estadoCod: 21,
        cod: 4776,
      },
      {
        nome: "Formigueiro",
        uf: "RS",
        estadoCod: 21,
        cod: 4777,
      },
      {
        nome: "Forquetinha",
        uf: "RS",
        estadoCod: 21,
        cod: 4778,
      },
      {
        nome: "Fortaleza dos Valos",
        uf: "RS",
        estadoCod: 21,
        cod: 4779,
      },
      {
        nome: "Frederico Westphalen",
        uf: "RS",
        estadoCod: 21,
        cod: 4780,
      },
      {
        nome: "Garibaldi",
        uf: "RS",
        estadoCod: 21,
        cod: 4781,
      },
      {
        nome: "Garruchos",
        uf: "RS",
        estadoCod: 21,
        cod: 4782,
      },
      {
        nome: "Gaurama",
        uf: "RS",
        estadoCod: 21,
        cod: 4783,
      },
      {
        nome: "General Câmara",
        uf: "RS",
        estadoCod: 21,
        cod: 4784,
      },
      {
        nome: "Gentil",
        uf: "RS",
        estadoCod: 21,
        cod: 4785,
      },
      {
        nome: "Getúlio Vargas",
        uf: "RS",
        estadoCod: 21,
        cod: 4786,
      },
      {
        nome: "Giruá",
        uf: "RS",
        estadoCod: 21,
        cod: 4787,
      },
      {
        nome: "Glorinha",
        uf: "RS",
        estadoCod: 21,
        cod: 4788,
      },
      {
        nome: "Gramado",
        uf: "RS",
        estadoCod: 21,
        cod: 4789,
      },
      {
        nome: "Gramado dos Loureiros",
        uf: "RS",
        estadoCod: 21,
        cod: 4790,
      },
      {
        nome: "Gramado Xavier",
        uf: "RS",
        estadoCod: 21,
        cod: 4791,
      },
      {
        nome: "Gravataí",
        uf: "RS",
        estadoCod: 21,
        cod: 4792,
      },
      {
        nome: "Guabiju",
        uf: "RS",
        estadoCod: 21,
        cod: 4793,
      },
      {
        nome: "Guaíba",
        uf: "RS",
        estadoCod: 21,
        cod: 4794,
      },
      {
        nome: "Guaporé",
        uf: "RS",
        estadoCod: 21,
        cod: 4795,
      },
      {
        nome: "Guarani das Missões",
        uf: "RS",
        estadoCod: 21,
        cod: 4796,
      },
      {
        nome: "Harmonia",
        uf: "RS",
        estadoCod: 21,
        cod: 4797,
      },
      {
        nome: "Herveiras",
        uf: "RS",
        estadoCod: 21,
        cod: 4798,
      },
      {
        nome: "Horizontina",
        uf: "RS",
        estadoCod: 21,
        cod: 4799,
      },
      {
        nome: "Hulha Negra",
        uf: "RS",
        estadoCod: 21,
        cod: 4800,
      },
      {
        nome: "Humaitá",
        uf: "RS",
        estadoCod: 21,
        cod: 4801,
      },
      {
        nome: "Ibarama",
        uf: "RS",
        estadoCod: 21,
        cod: 4802,
      },
      {
        nome: "Ibiaçá",
        uf: "RS",
        estadoCod: 21,
        cod: 4803,
      },
      {
        nome: "Ibiraiaras",
        uf: "RS",
        estadoCod: 21,
        cod: 4804,
      },
      {
        nome: "Ibirapuitã",
        uf: "RS",
        estadoCod: 21,
        cod: 4805,
      },
      {
        nome: "Ibirubá",
        uf: "RS",
        estadoCod: 21,
        cod: 4806,
      },
      {
        nome: "Igrejinha",
        uf: "RS",
        estadoCod: 21,
        cod: 4807,
      },
      {
        nome: "Ijuí",
        uf: "RS",
        estadoCod: 21,
        cod: 4808,
      },
      {
        nome: "Ilópolis",
        uf: "RS",
        estadoCod: 21,
        cod: 4809,
      },
      {
        nome: "Imbé",
        uf: "RS",
        estadoCod: 21,
        cod: 4810,
      },
      {
        nome: "Imigrante",
        uf: "RS",
        estadoCod: 21,
        cod: 4811,
      },
      {
        nome: "Independência",
        uf: "RS",
        estadoCod: 21,
        cod: 4812,
      },
      {
        nome: "Inhacorá",
        uf: "RS",
        estadoCod: 21,
        cod: 4813,
      },
      {
        nome: "Ipê",
        uf: "RS",
        estadoCod: 21,
        cod: 4814,
      },
      {
        nome: "Ipiranga do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4815,
      },
      {
        nome: "Iraí",
        uf: "RS",
        estadoCod: 21,
        cod: 4816,
      },
      {
        nome: "Itaara",
        uf: "RS",
        estadoCod: 21,
        cod: 4817,
      },
      {
        nome: "Itacurubi",
        uf: "RS",
        estadoCod: 21,
        cod: 4818,
      },
      {
        nome: "Itapuca",
        uf: "RS",
        estadoCod: 21,
        cod: 4819,
      },
      {
        nome: "Itaqui",
        uf: "RS",
        estadoCod: 21,
        cod: 4820,
      },
      {
        nome: "Itati",
        uf: "RS",
        estadoCod: 21,
        cod: 4821,
      },
      {
        nome: "Itatiba do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4822,
      },
      {
        nome: "Ivorá",
        uf: "RS",
        estadoCod: 21,
        cod: 4823,
      },
      {
        nome: "Ivoti",
        uf: "RS",
        estadoCod: 21,
        cod: 4824,
      },
      {
        nome: "Jaboticaba",
        uf: "RS",
        estadoCod: 21,
        cod: 4825,
      },
      {
        nome: "Jacuizinho",
        uf: "RS",
        estadoCod: 21,
        cod: 4826,
      },
      {
        nome: "Jacutinga",
        uf: "RS",
        estadoCod: 21,
        cod: 4827,
      },
      {
        nome: "Jaguarão",
        uf: "RS",
        estadoCod: 21,
        cod: 4828,
      },
      {
        nome: "Jaguari",
        uf: "RS",
        estadoCod: 21,
        cod: 4829,
      },
      {
        nome: "Jaquirana",
        uf: "RS",
        estadoCod: 21,
        cod: 4830,
      },
      {
        nome: "Jari",
        uf: "RS",
        estadoCod: 21,
        cod: 4831,
      },
      {
        nome: "Jóia",
        uf: "RS",
        estadoCod: 21,
        cod: 4832,
      },
      {
        nome: "Júlio de Castilhos",
        uf: "RS",
        estadoCod: 21,
        cod: 4833,
      },
      {
        nome: "Lagoa Bonita do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4834,
      },
      {
        nome: "Lagoão",
        uf: "RS",
        estadoCod: 21,
        cod: 4835,
      },
      {
        nome: "Lagoa dos Três Cantos",
        uf: "RS",
        estadoCod: 21,
        cod: 4836,
      },
      {
        nome: "Lagoa Vermelha",
        uf: "RS",
        estadoCod: 21,
        cod: 4837,
      },
      {
        nome: "Lajeado",
        uf: "RS",
        estadoCod: 21,
        cod: 4838,
      },
      {
        nome: "Lajeado do Bugre",
        uf: "RS",
        estadoCod: 21,
        cod: 4839,
      },
      {
        nome: "Lavras do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4840,
      },
      {
        nome: "Liberato Salzano",
        uf: "RS",
        estadoCod: 21,
        cod: 4841,
      },
      {
        nome: "Lindolfo Collor",
        uf: "RS",
        estadoCod: 21,
        cod: 4842,
      },
      {
        nome: "Linha Nova",
        uf: "RS",
        estadoCod: 21,
        cod: 4843,
      },
      {
        nome: "Machadinho",
        uf: "RS",
        estadoCod: 21,
        cod: 4844,
      },
      {
        nome: "Maçambará",
        uf: "RS",
        estadoCod: 21,
        cod: 4845,
      },
      {
        nome: "Mampituba",
        uf: "RS",
        estadoCod: 21,
        cod: 4846,
      },
      {
        nome: "Manoel Viana",
        uf: "RS",
        estadoCod: 21,
        cod: 4847,
      },
      {
        nome: "Maquiné",
        uf: "RS",
        estadoCod: 21,
        cod: 4848,
      },
      {
        nome: "Maratá",
        uf: "RS",
        estadoCod: 21,
        cod: 4849,
      },
      {
        nome: "Marau",
        uf: "RS",
        estadoCod: 21,
        cod: 4850,
      },
      {
        nome: "Marcelino Ramos",
        uf: "RS",
        estadoCod: 21,
        cod: 4851,
      },
      {
        nome: "Mariana Pimentel",
        uf: "RS",
        estadoCod: 21,
        cod: 4852,
      },
      {
        nome: "Mariano Moro",
        uf: "RS",
        estadoCod: 21,
        cod: 4853,
      },
      {
        nome: "Marques de Souza",
        uf: "RS",
        estadoCod: 21,
        cod: 4854,
      },
      {
        nome: "Mata",
        uf: "RS",
        estadoCod: 21,
        cod: 4855,
      },
      {
        nome: "Mato Castelhano",
        uf: "RS",
        estadoCod: 21,
        cod: 4856,
      },
      {
        nome: "Mato Leitão",
        uf: "RS",
        estadoCod: 21,
        cod: 4857,
      },
      {
        nome: "Mato Queimado",
        uf: "RS",
        estadoCod: 21,
        cod: 4858,
      },
      {
        nome: "Maximiliano de Almeida",
        uf: "RS",
        estadoCod: 21,
        cod: 4859,
      },
      {
        nome: "Minas do Leão",
        uf: "RS",
        estadoCod: 21,
        cod: 4860,
      },
      {
        nome: "Miraguaí",
        uf: "RS",
        estadoCod: 21,
        cod: 4861,
      },
      {
        nome: "Montauri",
        uf: "RS",
        estadoCod: 21,
        cod: 4862,
      },
      {
        nome: "Monte Alegre dos Campos",
        uf: "RS",
        estadoCod: 21,
        cod: 4863,
      },
      {
        nome: "Monte Belo do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4864,
      },
      {
        nome: "Montenegro",
        uf: "RS",
        estadoCod: 21,
        cod: 4865,
      },
      {
        nome: "Mormaço",
        uf: "RS",
        estadoCod: 21,
        cod: 4866,
      },
      {
        nome: "Morrinhos do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4867,
      },
      {
        nome: "Morro Redondo",
        uf: "RS",
        estadoCod: 21,
        cod: 4868,
      },
      {
        nome: "Morro Reuter",
        uf: "RS",
        estadoCod: 21,
        cod: 4869,
      },
      {
        nome: "Mostardas",
        uf: "RS",
        estadoCod: 21,
        cod: 4870,
      },
      {
        nome: "Muçum",
        uf: "RS",
        estadoCod: 21,
        cod: 4871,
      },
      {
        nome: "Muitos Capões",
        uf: "RS",
        estadoCod: 21,
        cod: 4872,
      },
      {
        nome: "Muliterno",
        uf: "RS",
        estadoCod: 21,
        cod: 4873,
      },
      {
        nome: "Não-Me-Toque",
        uf: "RS",
        estadoCod: 21,
        cod: 4874,
      },
      {
        nome: "Nicolau Vergueiro",
        uf: "RS",
        estadoCod: 21,
        cod: 4875,
      },
      {
        nome: "Nonoai",
        uf: "RS",
        estadoCod: 21,
        cod: 4876,
      },
      {
        nome: "Nova Alvorada",
        uf: "RS",
        estadoCod: 21,
        cod: 4877,
      },
      {
        nome: "Nova Araçá",
        uf: "RS",
        estadoCod: 21,
        cod: 4878,
      },
      {
        nome: "Nova Bassano",
        uf: "RS",
        estadoCod: 21,
        cod: 4879,
      },
      {
        nome: "Nova Boa Vista",
        uf: "RS",
        estadoCod: 21,
        cod: 4880,
      },
      {
        nome: "Nova Bréscia",
        uf: "RS",
        estadoCod: 21,
        cod: 4881,
      },
      {
        nome: "Nova Candelária",
        uf: "RS",
        estadoCod: 21,
        cod: 4882,
      },
      {
        nome: "Nova Esperança do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4883,
      },
      {
        nome: "Nova Hartz",
        uf: "RS",
        estadoCod: 21,
        cod: 4884,
      },
      {
        nome: "Nova Pádua",
        uf: "RS",
        estadoCod: 21,
        cod: 4885,
      },
      {
        nome: "Nova Palma",
        uf: "RS",
        estadoCod: 21,
        cod: 4886,
      },
      {
        nome: "Nova Petrópolis",
        uf: "RS",
        estadoCod: 21,
        cod: 4887,
      },
      {
        nome: "Nova Prata",
        uf: "RS",
        estadoCod: 21,
        cod: 4888,
      },
      {
        nome: "Nova Ramada",
        uf: "RS",
        estadoCod: 21,
        cod: 4889,
      },
      {
        nome: "Nova Roma do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4890,
      },
      {
        nome: "Nova Santa Rita",
        uf: "RS",
        estadoCod: 21,
        cod: 4891,
      },
      {
        nome: "Novo Cabrais",
        uf: "RS",
        estadoCod: 21,
        cod: 4892,
      },
      {
        nome: "Novo Hamburgo",
        uf: "RS",
        estadoCod: 21,
        cod: 4893,
      },
      {
        nome: "Novo Machado",
        uf: "RS",
        estadoCod: 21,
        cod: 4894,
      },
      {
        nome: "Novo Tiradentes",
        uf: "RS",
        estadoCod: 21,
        cod: 4895,
      },
      {
        nome: "Novo Xingu",
        uf: "RS",
        estadoCod: 21,
        cod: 4896,
      },
      {
        nome: "Novo Barreiro",
        uf: "RS",
        estadoCod: 21,
        cod: 4897,
      },
      {
        nome: "Osório",
        uf: "RS",
        estadoCod: 21,
        cod: 4898,
      },
      {
        nome: "Paim Filho",
        uf: "RS",
        estadoCod: 21,
        cod: 4899,
      },
      {
        nome: "Palmares do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4900,
      },
      {
        nome: "Palmeira das Missões",
        uf: "RS",
        estadoCod: 21,
        cod: 4901,
      },
      {
        nome: "Palmitinho",
        uf: "RS",
        estadoCod: 21,
        cod: 4902,
      },
      {
        nome: "Panambi",
        uf: "RS",
        estadoCod: 21,
        cod: 4903,
      },
      {
        nome: "Pantano Grande",
        uf: "RS",
        estadoCod: 21,
        cod: 4904,
      },
      {
        nome: "Paraí",
        uf: "RS",
        estadoCod: 21,
        cod: 4905,
      },
      {
        nome: "Paraíso do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4906,
      },
      {
        nome: "Pareci Novo",
        uf: "RS",
        estadoCod: 21,
        cod: 4907,
      },
      {
        nome: "Parobé",
        uf: "RS",
        estadoCod: 21,
        cod: 4908,
      },
      {
        nome: "Passa Sete",
        uf: "RS",
        estadoCod: 21,
        cod: 4909,
      },
      {
        nome: "Passo do Sobrado",
        uf: "RS",
        estadoCod: 21,
        cod: 4910,
      },
      {
        nome: "Passo Fundo",
        uf: "RS",
        estadoCod: 21,
        cod: 4911,
      },
      {
        nome: "Paulo Bento",
        uf: "RS",
        estadoCod: 21,
        cod: 4912,
      },
      {
        nome: "Paverama",
        uf: "RS",
        estadoCod: 21,
        cod: 4913,
      },
      {
        nome: "Pedras Altas",
        uf: "RS",
        estadoCod: 21,
        cod: 4914,
      },
      {
        nome: "Pedro Osório",
        uf: "RS",
        estadoCod: 21,
        cod: 4915,
      },
      {
        nome: "Pejuçara",
        uf: "RS",
        estadoCod: 21,
        cod: 4916,
      },
      {
        nome: "Pelotas",
        uf: "RS",
        estadoCod: 21,
        cod: 4917,
      },
      {
        nome: "Picada Café",
        uf: "RS",
        estadoCod: 21,
        cod: 4918,
      },
      {
        nome: "Pinhal",
        uf: "RS",
        estadoCod: 21,
        cod: 4919,
      },
      {
        nome: "Pinhal da Serra",
        uf: "RS",
        estadoCod: 21,
        cod: 4920,
      },
      {
        nome: "Pinhal Grande",
        uf: "RS",
        estadoCod: 21,
        cod: 4921,
      },
      {
        nome: "Pinheirinho do Vale",
        uf: "RS",
        estadoCod: 21,
        cod: 4922,
      },
      {
        nome: "Pinheiro Machado",
        uf: "RS",
        estadoCod: 21,
        cod: 4923,
      },
      {
        nome: "Pinto Bandeira",
        uf: "RS",
        estadoCod: 21,
        cod: 4924,
      },
      {
        nome: "Pirapó",
        uf: "RS",
        estadoCod: 21,
        cod: 4925,
      },
      {
        nome: "Piratini",
        uf: "RS",
        estadoCod: 21,
        cod: 4926,
      },
      {
        nome: "Planalto",
        uf: "RS",
        estadoCod: 21,
        cod: 4927,
      },
      {
        nome: "Poço das Antas",
        uf: "RS",
        estadoCod: 21,
        cod: 4928,
      },
      {
        nome: "Pontão",
        uf: "RS",
        estadoCod: 21,
        cod: 4929,
      },
      {
        nome: "Ponte Preta",
        uf: "RS",
        estadoCod: 21,
        cod: 4930,
      },
      {
        nome: "Portão",
        uf: "RS",
        estadoCod: 21,
        cod: 4931,
      },
      {
        nome: "Porto Alegre",
        uf: "RS",
        estadoCod: 21,
        cod: 4932,
      },
      {
        nome: "Porto Lucena",
        uf: "RS",
        estadoCod: 21,
        cod: 4933,
      },
      {
        nome: "Porto Mauá",
        uf: "RS",
        estadoCod: 21,
        cod: 4934,
      },
      {
        nome: "Porto Vera Cruz",
        uf: "RS",
        estadoCod: 21,
        cod: 4935,
      },
      {
        nome: "Porto Xavier",
        uf: "RS",
        estadoCod: 21,
        cod: 4936,
      },
      {
        nome: "Pouso Novo",
        uf: "RS",
        estadoCod: 21,
        cod: 4937,
      },
      {
        nome: "Presidente Lucena",
        uf: "RS",
        estadoCod: 21,
        cod: 4938,
      },
      {
        nome: "Progresso",
        uf: "RS",
        estadoCod: 21,
        cod: 4939,
      },
      {
        nome: "Protásio Alves",
        uf: "RS",
        estadoCod: 21,
        cod: 4940,
      },
      {
        nome: "Putinga",
        uf: "RS",
        estadoCod: 21,
        cod: 4941,
      },
      {
        nome: "Quaraí",
        uf: "RS",
        estadoCod: 21,
        cod: 4942,
      },
      {
        nome: "Quatro Irmãos",
        uf: "RS",
        estadoCod: 21,
        cod: 4943,
      },
      {
        nome: "Quevedos",
        uf: "RS",
        estadoCod: 21,
        cod: 4944,
      },
      {
        nome: "Quinze de Novembro",
        uf: "RS",
        estadoCod: 21,
        cod: 4945,
      },
      {
        nome: "Redentora",
        uf: "RS",
        estadoCod: 21,
        cod: 4946,
      },
      {
        nome: "Relvado",
        uf: "RS",
        estadoCod: 21,
        cod: 4947,
      },
      {
        nome: "Restinga Seca",
        uf: "RS",
        estadoCod: 21,
        cod: 4948,
      },
      {
        nome: "Rio dos Índios",
        uf: "RS",
        estadoCod: 21,
        cod: 4949,
      },
      {
        nome: "Rio Grande",
        uf: "RS",
        estadoCod: 21,
        cod: 4950,
      },
      {
        nome: "Rio Pardo",
        uf: "RS",
        estadoCod: 21,
        cod: 4951,
      },
      {
        nome: "Riozinho",
        uf: "RS",
        estadoCod: 21,
        cod: 4952,
      },
      {
        nome: "Roca Sales",
        uf: "RS",
        estadoCod: 21,
        cod: 4953,
      },
      {
        nome: "Rodeio Bonito",
        uf: "RS",
        estadoCod: 21,
        cod: 4954,
      },
      {
        nome: "Rolador",
        uf: "RS",
        estadoCod: 21,
        cod: 4955,
      },
      {
        nome: "Rolante",
        uf: "RS",
        estadoCod: 21,
        cod: 4956,
      },
      {
        nome: "Ronda Alta",
        uf: "RS",
        estadoCod: 21,
        cod: 4957,
      },
      {
        nome: "Rondinha",
        uf: "RS",
        estadoCod: 21,
        cod: 4958,
      },
      {
        nome: "Roque Gonzales",
        uf: "RS",
        estadoCod: 21,
        cod: 4959,
      },
      {
        nome: "Rosário do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4960,
      },
      {
        nome: "Sagrada Família",
        uf: "RS",
        estadoCod: 21,
        cod: 4961,
      },
      {
        nome: "Saldanha Marinho",
        uf: "RS",
        estadoCod: 21,
        cod: 4962,
      },
      {
        nome: "Salto do Jacuí",
        uf: "RS",
        estadoCod: 21,
        cod: 4963,
      },
      {
        nome: "Salvador das Missões",
        uf: "RS",
        estadoCod: 21,
        cod: 4964,
      },
      {
        nome: "Salvador do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4965,
      },
      {
        nome: "Sananduva",
        uf: "RS",
        estadoCod: 21,
        cod: 4966,
      },
      {
        nome: "Santa Bárbara do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4967,
      },
      {
        nome: "Santa Cecília do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4968,
      },
      {
        nome: "Santa Clara do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4969,
      },
      {
        nome: "Santa Cruz do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4970,
      },
      {
        nome: "Santa Maria",
        uf: "RS",
        estadoCod: 21,
        cod: 4971,
      },
      {
        nome: "Santa Maria do Herval",
        uf: "RS",
        estadoCod: 21,
        cod: 4972,
      },
      {
        nome: "Santa Margarida do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4973,
      },
      {
        nome: "Santana da Boa Vista",
        uf: "RS",
        estadoCod: 21,
        cod: 4974,
      },
      {
        nome: "Sant'Ana do Livramento",
        uf: "RS",
        estadoCod: 21,
        cod: 4975,
      },
      {
        nome: "Santa Rosa",
        uf: "RS",
        estadoCod: 21,
        cod: 4976,
      },
      {
        nome: "Santa Tereza",
        uf: "RS",
        estadoCod: 21,
        cod: 4977,
      },
      {
        nome: "Santa Vitória do Palmar",
        uf: "RS",
        estadoCod: 21,
        cod: 4978,
      },
      {
        nome: "Santiago",
        uf: "RS",
        estadoCod: 21,
        cod: 4979,
      },
      {
        nome: "Santo Ângelo",
        uf: "RS",
        estadoCod: 21,
        cod: 4980,
      },
      {
        nome: "Santo Antônio do Palma",
        uf: "RS",
        estadoCod: 21,
        cod: 4981,
      },
      {
        nome: "Santo Antônio da Patrulha",
        uf: "RS",
        estadoCod: 21,
        cod: 4982,
      },
      {
        nome: "Santo Antônio das Missões",
        uf: "RS",
        estadoCod: 21,
        cod: 4983,
      },
      {
        nome: "Santo Antônio do Planalto",
        uf: "RS",
        estadoCod: 21,
        cod: 4984,
      },
      {
        nome: "Santo Augusto",
        uf: "RS",
        estadoCod: 21,
        cod: 4985,
      },
      {
        nome: "Santo Cristo",
        uf: "RS",
        estadoCod: 21,
        cod: 4986,
      },
      {
        nome: "Santo Expedito do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4987,
      },
      {
        nome: "São Borja",
        uf: "RS",
        estadoCod: 21,
        cod: 4988,
      },
      {
        nome: "São Domingos do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 4989,
      },
      {
        nome: "São Francisco de Assis",
        uf: "RS",
        estadoCod: 21,
        cod: 4990,
      },
      {
        nome: "São Francisco de Paula",
        uf: "RS",
        estadoCod: 21,
        cod: 4991,
      },
      {
        nome: "São Gabriel",
        uf: "RS",
        estadoCod: 21,
        cod: 4992,
      },
      {
        nome: "São Jerônimo",
        uf: "RS",
        estadoCod: 21,
        cod: 4993,
      },
      {
        nome: "São João da Urtiga",
        uf: "RS",
        estadoCod: 21,
        cod: 4994,
      },
      {
        nome: "São João do Polêsine",
        uf: "RS",
        estadoCod: 21,
        cod: 4995,
      },
      {
        nome: "São Jorge",
        uf: "RS",
        estadoCod: 21,
        cod: 4996,
      },
      {
        nome: "São José das Missões",
        uf: "RS",
        estadoCod: 21,
        cod: 4997,
      },
      {
        nome: "São José do Herval",
        uf: "RS",
        estadoCod: 21,
        cod: 4998,
      },
      {
        nome: "São José do Hortêncio",
        uf: "RS",
        estadoCod: 21,
        cod: 4999,
      },
      {
        nome: "São José do Inhacorá",
        uf: "RS",
        estadoCod: 21,
        cod: 5000,
      },
      {
        nome: "São José do Norte",
        uf: "RS",
        estadoCod: 21,
        cod: 5001,
      },
      {
        nome: "São José do Ouro",
        uf: "RS",
        estadoCod: 21,
        cod: 5002,
      },
      {
        nome: "São José do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 5003,
      },
      {
        nome: "São José dos Ausentes",
        uf: "RS",
        estadoCod: 21,
        cod: 5004,
      },
      {
        nome: "São Leopoldo",
        uf: "RS",
        estadoCod: 21,
        cod: 5005,
      },
      {
        nome: "São Lourenço do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 5006,
      },
      {
        nome: "São Luiz Gonzaga",
        uf: "RS",
        estadoCod: 21,
        cod: 5007,
      },
      {
        nome: "São Marcos",
        uf: "RS",
        estadoCod: 21,
        cod: 5008,
      },
      {
        nome: "São Martinho",
        uf: "RS",
        estadoCod: 21,
        cod: 5009,
      },
      {
        nome: "São Martinho da Serra",
        uf: "RS",
        estadoCod: 21,
        cod: 5010,
      },
      {
        nome: "São Miguel das Missões",
        uf: "RS",
        estadoCod: 21,
        cod: 5011,
      },
      {
        nome: "São Nicolau",
        uf: "RS",
        estadoCod: 21,
        cod: 5012,
      },
      {
        nome: "São Paulo das Missões",
        uf: "RS",
        estadoCod: 21,
        cod: 5013,
      },
      {
        nome: "São Pedro da Serra",
        uf: "RS",
        estadoCod: 21,
        cod: 5014,
      },
      {
        nome: "São Pedro das Missões",
        uf: "RS",
        estadoCod: 21,
        cod: 5015,
      },
      {
        nome: "São Pedro do Butiá",
        uf: "RS",
        estadoCod: 21,
        cod: 5016,
      },
      {
        nome: "São Pedro do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 5017,
      },
      {
        nome: "São Sebastião do Caí",
        uf: "RS",
        estadoCod: 21,
        cod: 5018,
      },
      {
        nome: "São Sepé",
        uf: "RS",
        estadoCod: 21,
        cod: 5019,
      },
      {
        nome: "São Valentim",
        uf: "RS",
        estadoCod: 21,
        cod: 5020,
      },
      {
        nome: "São Valentim do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 5021,
      },
      {
        nome: "São Valério do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 5022,
      },
      {
        nome: "São Vendelino",
        uf: "RS",
        estadoCod: 21,
        cod: 5023,
      },
      {
        nome: "São Vicente do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 5024,
      },
      {
        nome: "Sapiranga",
        uf: "RS",
        estadoCod: 21,
        cod: 5025,
      },
      {
        nome: "Sapucaia do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 5026,
      },
      {
        nome: "Sarandi",
        uf: "RS",
        estadoCod: 21,
        cod: 5027,
      },
      {
        nome: "Seberi",
        uf: "RS",
        estadoCod: 21,
        cod: 5028,
      },
      {
        nome: "Sede Nova",
        uf: "RS",
        estadoCod: 21,
        cod: 5029,
      },
      {
        nome: "Segredo",
        uf: "RS",
        estadoCod: 21,
        cod: 5030,
      },
      {
        nome: "Selbach",
        uf: "RS",
        estadoCod: 21,
        cod: 5031,
      },
      {
        nome: "Senador Salgado Filho",
        uf: "RS",
        estadoCod: 21,
        cod: 5032,
      },
      {
        nome: "Sentinela do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 5033,
      },
      {
        nome: "Serafina Corrêa",
        uf: "RS",
        estadoCod: 21,
        cod: 5034,
      },
      {
        nome: "Sério",
        uf: "RS",
        estadoCod: 21,
        cod: 5035,
      },
      {
        nome: "Sertão",
        uf: "RS",
        estadoCod: 21,
        cod: 5036,
      },
      {
        nome: "Sertão Santana",
        uf: "RS",
        estadoCod: 21,
        cod: 5037,
      },
      {
        nome: "Sete de Setembro",
        uf: "RS",
        estadoCod: 21,
        cod: 5038,
      },
      {
        nome: "Severiano de Almeida",
        uf: "RS",
        estadoCod: 21,
        cod: 5039,
      },
      {
        nome: "Silveira Martins",
        uf: "RS",
        estadoCod: 21,
        cod: 5040,
      },
      {
        nome: "Sinimbu",
        uf: "RS",
        estadoCod: 21,
        cod: 5041,
      },
      {
        nome: "Sobradinho",
        uf: "RS",
        estadoCod: 21,
        cod: 5042,
      },
      {
        nome: "Soledade",
        uf: "RS",
        estadoCod: 21,
        cod: 5043,
      },
      {
        nome: "Tabaí",
        uf: "RS",
        estadoCod: 21,
        cod: 5044,
      },
      {
        nome: "Tapejara",
        uf: "RS",
        estadoCod: 21,
        cod: 5045,
      },
      {
        nome: "Tapera",
        uf: "RS",
        estadoCod: 21,
        cod: 5046,
      },
      {
        nome: "Tapes",
        uf: "RS",
        estadoCod: 21,
        cod: 5047,
      },
      {
        nome: "Taquara",
        uf: "RS",
        estadoCod: 21,
        cod: 5048,
      },
      {
        nome: "Taquari",
        uf: "RS",
        estadoCod: 21,
        cod: 5049,
      },
      {
        nome: "Taquaruçu do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 5050,
      },
      {
        nome: "Tavares",
        uf: "RS",
        estadoCod: 21,
        cod: 5051,
      },
      {
        nome: "Tenente Portela",
        uf: "RS",
        estadoCod: 21,
        cod: 5052,
      },
      {
        nome: "Terra de Areia",
        uf: "RS",
        estadoCod: 21,
        cod: 5053,
      },
      {
        nome: "Teutônia",
        uf: "RS",
        estadoCod: 21,
        cod: 5054,
      },
      {
        nome: "Tio Hugo",
        uf: "RS",
        estadoCod: 21,
        cod: 5055,
      },
      {
        nome: "Tiradentes do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 5056,
      },
      {
        nome: "Toropi",
        uf: "RS",
        estadoCod: 21,
        cod: 5057,
      },
      {
        nome: "Torres",
        uf: "RS",
        estadoCod: 21,
        cod: 5058,
      },
      {
        nome: "Tramandaí",
        uf: "RS",
        estadoCod: 21,
        cod: 5059,
      },
      {
        nome: "Travesseiro",
        uf: "RS",
        estadoCod: 21,
        cod: 5060,
      },
      {
        nome: "Três Arroios",
        uf: "RS",
        estadoCod: 21,
        cod: 5061,
      },
      {
        nome: "Três Cachoeiras",
        uf: "RS",
        estadoCod: 21,
        cod: 5062,
      },
      {
        nome: "Três Coroas",
        uf: "RS",
        estadoCod: 21,
        cod: 5063,
      },
      {
        nome: "Três de Maio",
        uf: "RS",
        estadoCod: 21,
        cod: 5064,
      },
      {
        nome: "Três Forquilhas",
        uf: "RS",
        estadoCod: 21,
        cod: 5065,
      },
      {
        nome: "Três Palmeiras",
        uf: "RS",
        estadoCod: 21,
        cod: 5066,
      },
      {
        nome: "Três Passos",
        uf: "RS",
        estadoCod: 21,
        cod: 5067,
      },
      {
        nome: "Trindade do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 5068,
      },
      {
        nome: "Triunfo",
        uf: "RS",
        estadoCod: 21,
        cod: 5069,
      },
      {
        nome: "Tucunduva",
        uf: "RS",
        estadoCod: 21,
        cod: 5070,
      },
      {
        nome: "Tunas",
        uf: "RS",
        estadoCod: 21,
        cod: 5071,
      },
      {
        nome: "Tupanci do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 5072,
      },
      {
        nome: "Tupanciretã",
        uf: "RS",
        estadoCod: 21,
        cod: 5073,
      },
      {
        nome: "Tupandi",
        uf: "RS",
        estadoCod: 21,
        cod: 5074,
      },
      {
        nome: "Tuparendi",
        uf: "RS",
        estadoCod: 21,
        cod: 5075,
      },
      {
        nome: "Turuçu",
        uf: "RS",
        estadoCod: 21,
        cod: 5076,
      },
      {
        nome: "Ubiretama",
        uf: "RS",
        estadoCod: 21,
        cod: 5077,
      },
      {
        nome: "União da Serra",
        uf: "RS",
        estadoCod: 21,
        cod: 5078,
      },
      {
        nome: "Unistalda",
        uf: "RS",
        estadoCod: 21,
        cod: 5079,
      },
      {
        nome: "Uruguaiana",
        uf: "RS",
        estadoCod: 21,
        cod: 5080,
      },
      {
        nome: "Vacaria",
        uf: "RS",
        estadoCod: 21,
        cod: 5081,
      },
      {
        nome: "Vale Verde",
        uf: "RS",
        estadoCod: 21,
        cod: 5082,
      },
      {
        nome: "Vale do Sol",
        uf: "RS",
        estadoCod: 21,
        cod: 5083,
      },
      {
        nome: "Vale Real",
        uf: "RS",
        estadoCod: 21,
        cod: 5084,
      },
      {
        nome: "Vanini",
        uf: "RS",
        estadoCod: 21,
        cod: 5085,
      },
      {
        nome: "Venâncio Aires",
        uf: "RS",
        estadoCod: 21,
        cod: 5086,
      },
      {
        nome: "Vera Cruz",
        uf: "RS",
        estadoCod: 21,
        cod: 5087,
      },
      {
        nome: "Veranópolis",
        uf: "RS",
        estadoCod: 21,
        cod: 5088,
      },
      {
        nome: "Vespasiano Correa",
        uf: "RS",
        estadoCod: 21,
        cod: 5089,
      },
      {
        nome: "Viadutos",
        uf: "RS",
        estadoCod: 21,
        cod: 5090,
      },
      {
        nome: "Viamão",
        uf: "RS",
        estadoCod: 21,
        cod: 5091,
      },
      {
        nome: "Vicente Dutra",
        uf: "RS",
        estadoCod: 21,
        cod: 5092,
      },
      {
        nome: "Victor Graeff",
        uf: "RS",
        estadoCod: 21,
        cod: 5093,
      },
      {
        nome: "Vila Flores",
        uf: "RS",
        estadoCod: 21,
        cod: 5094,
      },
      {
        nome: "Vila Lângaro",
        uf: "RS",
        estadoCod: 21,
        cod: 5095,
      },
      {
        nome: "Vila Maria",
        uf: "RS",
        estadoCod: 21,
        cod: 5096,
      },
      {
        nome: "Vila Nova do Sul",
        uf: "RS",
        estadoCod: 21,
        cod: 5097,
      },
      {
        nome: "Vista Alegre",
        uf: "RS",
        estadoCod: 21,
        cod: 5098,
      },
      {
        nome: "Vista Alegre do Prata",
        uf: "RS",
        estadoCod: 21,
        cod: 5099,
      },
      {
        nome: "Vista Gaúcha",
        uf: "RS",
        estadoCod: 21,
        cod: 5100,
      },
      {
        nome: "Vitória das Missões",
        uf: "RS",
        estadoCod: 21,
        cod: 5101,
      },
      {
        nome: "Westfalia",
        uf: "RS",
        estadoCod: 21,
        cod: 5102,
      },
      {
        nome: "Xangri-lá",
        uf: "RS",
        estadoCod: 21,
        cod: 5103,
      },
    ],
  },
  {
    nome: "Mato Grosso do Sul",
    uf: "MS",
    regiaoCod: 5,
    cod: 12,
    cidades: [
      {
        nome: "Água Clara",
        uf: "MS",
        estadoCod: 12,
        cod: 5104,
      },
      {
        nome: "Alcinópolis",
        uf: "MS",
        estadoCod: 12,
        cod: 5105,
      },
      {
        nome: "Amambai",
        uf: "MS",
        estadoCod: 12,
        cod: 5106,
      },
      {
        nome: "Anastácio",
        uf: "MS",
        estadoCod: 12,
        cod: 5107,
      },
      {
        nome: "Anaurilândia",
        uf: "MS",
        estadoCod: 12,
        cod: 5108,
      },
      {
        nome: "Angélica",
        uf: "MS",
        estadoCod: 12,
        cod: 5109,
      },
      {
        nome: "Antônio João",
        uf: "MS",
        estadoCod: 12,
        cod: 5110,
      },
      {
        nome: "Aparecida do Taboado",
        uf: "MS",
        estadoCod: 12,
        cod: 5111,
      },
      {
        nome: "Aquidauana",
        uf: "MS",
        estadoCod: 12,
        cod: 5112,
      },
      {
        nome: "Aral Moreira",
        uf: "MS",
        estadoCod: 12,
        cod: 5113,
      },
      {
        nome: "Bandeirantes",
        uf: "MS",
        estadoCod: 12,
        cod: 5114,
      },
      {
        nome: "Bataguassu",
        uf: "MS",
        estadoCod: 12,
        cod: 5115,
      },
      {
        nome: "Batayporã",
        uf: "MS",
        estadoCod: 12,
        cod: 5116,
      },
      {
        nome: "Bela Vista",
        uf: "MS",
        estadoCod: 12,
        cod: 5117,
      },
      {
        nome: "Bodoquena",
        uf: "MS",
        estadoCod: 12,
        cod: 5118,
      },
      {
        nome: "Bonito",
        uf: "MS",
        estadoCod: 12,
        cod: 5119,
      },
      {
        nome: "Brasilândia",
        uf: "MS",
        estadoCod: 12,
        cod: 5120,
      },
      {
        nome: "Caarapó",
        uf: "MS",
        estadoCod: 12,
        cod: 5121,
      },
      {
        nome: "Camapuã",
        uf: "MS",
        estadoCod: 12,
        cod: 5122,
      },
      {
        nome: "Campo Grande",
        uf: "MS",
        estadoCod: 12,
        cod: 5123,
      },
      {
        nome: "Caracol",
        uf: "MS",
        estadoCod: 12,
        cod: 5124,
      },
      {
        nome: "Cassilândia",
        uf: "MS",
        estadoCod: 12,
        cod: 5125,
      },
      {
        nome: "Chapadão do Sul",
        uf: "MS",
        estadoCod: 12,
        cod: 5126,
      },
      {
        nome: "Corguinho",
        uf: "MS",
        estadoCod: 12,
        cod: 5127,
      },
      {
        nome: "Coronel Sapucaia",
        uf: "MS",
        estadoCod: 12,
        cod: 5128,
      },
      {
        nome: "Corumbá",
        uf: "MS",
        estadoCod: 12,
        cod: 5129,
      },
      {
        nome: "Costa Rica",
        uf: "MS",
        estadoCod: 12,
        cod: 5130,
      },
      {
        nome: "Coxim",
        uf: "MS",
        estadoCod: 12,
        cod: 5131,
      },
      {
        nome: "Deodápolis",
        uf: "MS",
        estadoCod: 12,
        cod: 5132,
      },
      {
        nome: "Dois Irmãos do Buriti",
        uf: "MS",
        estadoCod: 12,
        cod: 5133,
      },
      {
        nome: "Douradina",
        uf: "MS",
        estadoCod: 12,
        cod: 5134,
      },
      {
        nome: "Dourados",
        uf: "MS",
        estadoCod: 12,
        cod: 5135,
      },
      {
        nome: "Eldorado",
        uf: "MS",
        estadoCod: 12,
        cod: 5136,
      },
      {
        nome: "Fátima do Sul",
        uf: "MS",
        estadoCod: 12,
        cod: 5137,
      },
      {
        nome: "Figueirão",
        uf: "MS",
        estadoCod: 12,
        cod: 5138,
      },
      {
        nome: "Glória de Dourados",
        uf: "MS",
        estadoCod: 12,
        cod: 5139,
      },
      {
        nome: "Guia Lopes da Laguna",
        uf: "MS",
        estadoCod: 12,
        cod: 5140,
      },
      {
        nome: "Iguatemi",
        uf: "MS",
        estadoCod: 12,
        cod: 5141,
      },
      {
        nome: "Inocência",
        uf: "MS",
        estadoCod: 12,
        cod: 5142,
      },
      {
        nome: "Itaporã",
        uf: "MS",
        estadoCod: 12,
        cod: 5143,
      },
      {
        nome: "Itaquiraí",
        uf: "MS",
        estadoCod: 12,
        cod: 5144,
      },
      {
        nome: "Ivinhema",
        uf: "MS",
        estadoCod: 12,
        cod: 5145,
      },
      {
        nome: "Japorã",
        uf: "MS",
        estadoCod: 12,
        cod: 5146,
      },
      {
        nome: "Jaraguari",
        uf: "MS",
        estadoCod: 12,
        cod: 5147,
      },
      {
        nome: "Jardim",
        uf: "MS",
        estadoCod: 12,
        cod: 5148,
      },
      {
        nome: "Jateí",
        uf: "MS",
        estadoCod: 12,
        cod: 5149,
      },
      {
        nome: "Juti",
        uf: "MS",
        estadoCod: 12,
        cod: 5150,
      },
      {
        nome: "Ladário",
        uf: "MS",
        estadoCod: 12,
        cod: 5151,
      },
      {
        nome: "Laguna Carapã",
        uf: "MS",
        estadoCod: 12,
        cod: 5152,
      },
      {
        nome: "Maracaju",
        uf: "MS",
        estadoCod: 12,
        cod: 5153,
      },
      {
        nome: "Miranda",
        uf: "MS",
        estadoCod: 12,
        cod: 5154,
      },
      {
        nome: "Mundo Novo",
        uf: "MS",
        estadoCod: 12,
        cod: 5155,
      },
      {
        nome: "Naviraí",
        uf: "MS",
        estadoCod: 12,
        cod: 5156,
      },
      {
        nome: "Nioaque",
        uf: "MS",
        estadoCod: 12,
        cod: 5157,
      },
      {
        nome: "Nova Alvorada do Sul",
        uf: "MS",
        estadoCod: 12,
        cod: 5158,
      },
      {
        nome: "Nova Andradina",
        uf: "MS",
        estadoCod: 12,
        cod: 5159,
      },
      {
        nome: "Novo Horizonte do Sul",
        uf: "MS",
        estadoCod: 12,
        cod: 5160,
      },
      {
        nome: "Paraíso das Águas",
        uf: "MS",
        estadoCod: 12,
        cod: 5161,
      },
      {
        nome: "Paranaíba",
        uf: "MS",
        estadoCod: 12,
        cod: 5162,
      },
      {
        nome: "Paranhos",
        uf: "MS",
        estadoCod: 12,
        cod: 5163,
      },
      {
        nome: "Pedro Gomes",
        uf: "MS",
        estadoCod: 12,
        cod: 5164,
      },
      {
        nome: "Ponta Porã",
        uf: "MS",
        estadoCod: 12,
        cod: 5165,
      },
      {
        nome: "Porto Murtinho",
        uf: "MS",
        estadoCod: 12,
        cod: 5166,
      },
      {
        nome: "Ribas do Rio Pardo",
        uf: "MS",
        estadoCod: 12,
        cod: 5167,
      },
      {
        nome: "Rio Brilhante",
        uf: "MS",
        estadoCod: 12,
        cod: 5168,
      },
      {
        nome: "Rio Negro",
        uf: "MS",
        estadoCod: 12,
        cod: 5169,
      },
      {
        nome: "Rio Verde de Mato Grosso",
        uf: "MS",
        estadoCod: 12,
        cod: 5170,
      },
      {
        nome: "Rochedo",
        uf: "MS",
        estadoCod: 12,
        cod: 5171,
      },
      {
        nome: "Santa Rita do Pardo",
        uf: "MS",
        estadoCod: 12,
        cod: 5172,
      },
      {
        nome: "São Gabriel do Oeste",
        uf: "MS",
        estadoCod: 12,
        cod: 5173,
      },
      {
        nome: "Sete Quedas",
        uf: "MS",
        estadoCod: 12,
        cod: 5174,
      },
      {
        nome: "Selvíria",
        uf: "MS",
        estadoCod: 12,
        cod: 5175,
      },
      {
        nome: "Sidrolândia",
        uf: "MS",
        estadoCod: 12,
        cod: 5176,
      },
      {
        nome: "Sonora",
        uf: "MS",
        estadoCod: 12,
        cod: 5177,
      },
      {
        nome: "Tacuru",
        uf: "MS",
        estadoCod: 12,
        cod: 5178,
      },
      {
        nome: "Taquarussu",
        uf: "MS",
        estadoCod: 12,
        cod: 5179,
      },
      {
        nome: "Terenos",
        uf: "MS",
        estadoCod: 12,
        cod: 5180,
      },
      {
        nome: "Três Lagoas",
        uf: "MS",
        estadoCod: 12,
        cod: 5181,
      },
      {
        nome: "Vicentina",
        uf: "MS",
        estadoCod: 12,
        cod: 5182,
      },
    ],
  },
  {
    nome: "Mato Grosso",
    uf: "MT",
    regiaoCod: 5,
    cod: 11,
    cidades: [
      {
        nome: "Acorizal",
        uf: "MT",
        estadoCod: 11,
        cod: 5183,
      },
      {
        nome: "Água Boa",
        uf: "MT",
        estadoCod: 11,
        cod: 5184,
      },
      {
        nome: "Alta Floresta",
        uf: "MT",
        estadoCod: 11,
        cod: 5185,
      },
      {
        nome: "Alto Araguaia",
        uf: "MT",
        estadoCod: 11,
        cod: 5186,
      },
      {
        nome: "Alto Boa Vista",
        uf: "MT",
        estadoCod: 11,
        cod: 5187,
      },
      {
        nome: "Alto Garças",
        uf: "MT",
        estadoCod: 11,
        cod: 5188,
      },
      {
        nome: "Alto Paraguai",
        uf: "MT",
        estadoCod: 11,
        cod: 5189,
      },
      {
        nome: "Alto Taquari",
        uf: "MT",
        estadoCod: 11,
        cod: 5190,
      },
      {
        nome: "Apiacás",
        uf: "MT",
        estadoCod: 11,
        cod: 5191,
      },
      {
        nome: "Araguaiana",
        uf: "MT",
        estadoCod: 11,
        cod: 5192,
      },
      {
        nome: "Araguainha",
        uf: "MT",
        estadoCod: 11,
        cod: 5193,
      },
      {
        nome: "Araputanga",
        uf: "MT",
        estadoCod: 11,
        cod: 5194,
      },
      {
        nome: "Arenápolis",
        uf: "MT",
        estadoCod: 11,
        cod: 5195,
      },
      {
        nome: "Aripuanã",
        uf: "MT",
        estadoCod: 11,
        cod: 5196,
      },
      {
        nome: "Barão de Melgaço",
        uf: "MT",
        estadoCod: 11,
        cod: 5197,
      },
      {
        nome: "Barra do Bugres",
        uf: "MT",
        estadoCod: 11,
        cod: 5198,
      },
      {
        nome: "Barra do Garças",
        uf: "MT",
        estadoCod: 11,
        cod: 5199,
      },
      {
        nome: "Bom Jesus do Araguaia",
        uf: "MT",
        estadoCod: 11,
        cod: 5200,
      },
      {
        nome: "Brasnorte",
        uf: "MT",
        estadoCod: 11,
        cod: 5201,
      },
      {
        nome: "Cáceres",
        uf: "MT",
        estadoCod: 11,
        cod: 5202,
      },
      {
        nome: "Campinápolis",
        uf: "MT",
        estadoCod: 11,
        cod: 5203,
      },
      {
        nome: "Campo Novo do Parecis",
        uf: "MT",
        estadoCod: 11,
        cod: 5204,
      },
      {
        nome: "Campo Verde",
        uf: "MT",
        estadoCod: 11,
        cod: 5205,
      },
      {
        nome: "Campos de Júlio",
        uf: "MT",
        estadoCod: 11,
        cod: 5206,
      },
      {
        nome: "Canabrava do Norte",
        uf: "MT",
        estadoCod: 11,
        cod: 5207,
      },
      {
        nome: "Canarana",
        uf: "MT",
        estadoCod: 11,
        cod: 5208,
      },
      {
        nome: "Carlinda",
        uf: "MT",
        estadoCod: 11,
        cod: 5209,
      },
      {
        nome: "Castanheira",
        uf: "MT",
        estadoCod: 11,
        cod: 5210,
      },
      {
        nome: "Chapada dos Guimarães",
        uf: "MT",
        estadoCod: 11,
        cod: 5211,
      },
      {
        nome: "Cláudia",
        uf: "MT",
        estadoCod: 11,
        cod: 5212,
      },
      {
        nome: "Cocalinho",
        uf: "MT",
        estadoCod: 11,
        cod: 5213,
      },
      {
        nome: "Colíder",
        uf: "MT",
        estadoCod: 11,
        cod: 5214,
      },
      {
        nome: "Colniza",
        uf: "MT",
        estadoCod: 11,
        cod: 5215,
      },
      {
        nome: "Comodoro",
        uf: "MT",
        estadoCod: 11,
        cod: 5216,
      },
      {
        nome: "Confresa",
        uf: "MT",
        estadoCod: 11,
        cod: 5217,
      },
      {
        nome: "Conquista D'Oeste",
        uf: "MT",
        estadoCod: 11,
        cod: 5218,
      },
      {
        nome: "Cotriguaçu",
        uf: "MT",
        estadoCod: 11,
        cod: 5219,
      },
      {
        nome: "Cuiabá",
        uf: "MT",
        estadoCod: 11,
        cod: 5220,
      },
      {
        nome: "Curvelândia",
        uf: "MT",
        estadoCod: 11,
        cod: 5221,
      },
      {
        nome: "Denise",
        uf: "MT",
        estadoCod: 11,
        cod: 5222,
      },
      {
        nome: "Diamantino",
        uf: "MT",
        estadoCod: 11,
        cod: 5223,
      },
      {
        nome: "Dom Aquino",
        uf: "MT",
        estadoCod: 11,
        cod: 5224,
      },
      {
        nome: "Feliz Natal",
        uf: "MT",
        estadoCod: 11,
        cod: 5225,
      },
      {
        nome: "Figueirópolis D'Oeste",
        uf: "MT",
        estadoCod: 11,
        cod: 5226,
      },
      {
        nome: "Gaúcha do Norte",
        uf: "MT",
        estadoCod: 11,
        cod: 5227,
      },
      {
        nome: "General Carneiro",
        uf: "MT",
        estadoCod: 11,
        cod: 5228,
      },
      {
        nome: "Glória D'Oeste",
        uf: "MT",
        estadoCod: 11,
        cod: 5229,
      },
      {
        nome: "Guarantã do Norte",
        uf: "MT",
        estadoCod: 11,
        cod: 5230,
      },
      {
        nome: "Guiratinga",
        uf: "MT",
        estadoCod: 11,
        cod: 5231,
      },
      {
        nome: "Indiavaí",
        uf: "MT",
        estadoCod: 11,
        cod: 5232,
      },
      {
        nome: "Ipiranga do Norte",
        uf: "MT",
        estadoCod: 11,
        cod: 5233,
      },
      {
        nome: "Itanhangá",
        uf: "MT",
        estadoCod: 11,
        cod: 5234,
      },
      {
        nome: "Itaúba",
        uf: "MT",
        estadoCod: 11,
        cod: 5235,
      },
      {
        nome: "Itiquira",
        uf: "MT",
        estadoCod: 11,
        cod: 5236,
      },
      {
        nome: "Jaciara",
        uf: "MT",
        estadoCod: 11,
        cod: 5237,
      },
      {
        nome: "Jangada",
        uf: "MT",
        estadoCod: 11,
        cod: 5238,
      },
      {
        nome: "Jauru",
        uf: "MT",
        estadoCod: 11,
        cod: 5239,
      },
      {
        nome: "Juara",
        uf: "MT",
        estadoCod: 11,
        cod: 5240,
      },
      {
        nome: "Juína",
        uf: "MT",
        estadoCod: 11,
        cod: 5241,
      },
      {
        nome: "Juruena",
        uf: "MT",
        estadoCod: 11,
        cod: 5242,
      },
      {
        nome: "Juscimeira",
        uf: "MT",
        estadoCod: 11,
        cod: 5243,
      },
      {
        nome: "Lambari D'Oeste",
        uf: "MT",
        estadoCod: 11,
        cod: 5244,
      },
      {
        nome: "Lucas do Rio Verde",
        uf: "MT",
        estadoCod: 11,
        cod: 5245,
      },
      {
        nome: "Luciara",
        uf: "MT",
        estadoCod: 11,
        cod: 5246,
      },
      {
        nome: "Vila Bela da Santíssima Trindade",
        uf: "MT",
        estadoCod: 11,
        cod: 5247,
      },
      {
        nome: "Marcelândia",
        uf: "MT",
        estadoCod: 11,
        cod: 5248,
      },
      {
        nome: "Matupá",
        uf: "MT",
        estadoCod: 11,
        cod: 5249,
      },
      {
        nome: "Mirassol D'Oeste",
        uf: "MT",
        estadoCod: 11,
        cod: 5250,
      },
      {
        nome: "Nobres",
        uf: "MT",
        estadoCod: 11,
        cod: 5251,
      },
      {
        nome: "Nortelândia",
        uf: "MT",
        estadoCod: 11,
        cod: 5252,
      },
      {
        nome: "Nossa Senhora do Livramento",
        uf: "MT",
        estadoCod: 11,
        cod: 5253,
      },
      {
        nome: "Nova Bandeirantes",
        uf: "MT",
        estadoCod: 11,
        cod: 5254,
      },
      {
        nome: "Nova Nazaré",
        uf: "MT",
        estadoCod: 11,
        cod: 5255,
      },
      {
        nome: "Nova Lacerda",
        uf: "MT",
        estadoCod: 11,
        cod: 5256,
      },
      {
        nome: "Nova Santa Helena",
        uf: "MT",
        estadoCod: 11,
        cod: 5257,
      },
      {
        nome: "Nova Brasilândia",
        uf: "MT",
        estadoCod: 11,
        cod: 5258,
      },
      {
        nome: "Nova Canaã do Norte",
        uf: "MT",
        estadoCod: 11,
        cod: 5259,
      },
      {
        nome: "Nova Mutum",
        uf: "MT",
        estadoCod: 11,
        cod: 5260,
      },
      {
        nome: "Nova Olímpia",
        uf: "MT",
        estadoCod: 11,
        cod: 5261,
      },
      {
        nome: "Nova Ubiratã",
        uf: "MT",
        estadoCod: 11,
        cod: 5262,
      },
      {
        nome: "Nova Xavantina",
        uf: "MT",
        estadoCod: 11,
        cod: 5263,
      },
      {
        nome: "Novo Mundo",
        uf: "MT",
        estadoCod: 11,
        cod: 5264,
      },
      {
        nome: "Novo Horizonte do Norte",
        uf: "MT",
        estadoCod: 11,
        cod: 5265,
      },
      {
        nome: "Novo São Joaquim",
        uf: "MT",
        estadoCod: 11,
        cod: 5266,
      },
      {
        nome: "Paranaíta",
        uf: "MT",
        estadoCod: 11,
        cod: 5267,
      },
      {
        nome: "Paranatinga",
        uf: "MT",
        estadoCod: 11,
        cod: 5268,
      },
      {
        nome: "Novo Santo Antônio",
        uf: "MT",
        estadoCod: 11,
        cod: 5269,
      },
      {
        nome: "Pedra Preta",
        uf: "MT",
        estadoCod: 11,
        cod: 5270,
      },
      {
        nome: "Peixoto de Azevedo",
        uf: "MT",
        estadoCod: 11,
        cod: 5271,
      },
      {
        nome: "Planalto da Serra",
        uf: "MT",
        estadoCod: 11,
        cod: 5272,
      },
      {
        nome: "Poconé",
        uf: "MT",
        estadoCod: 11,
        cod: 5273,
      },
      {
        nome: "Pontal do Araguaia",
        uf: "MT",
        estadoCod: 11,
        cod: 5274,
      },
      {
        nome: "Ponte Branca",
        uf: "MT",
        estadoCod: 11,
        cod: 5275,
      },
      {
        nome: "Pontes e Lacerda",
        uf: "MT",
        estadoCod: 11,
        cod: 5276,
      },
      {
        nome: "Porto Alegre do Norte",
        uf: "MT",
        estadoCod: 11,
        cod: 5277,
      },
      {
        nome: "Porto dos Gaúchos",
        uf: "MT",
        estadoCod: 11,
        cod: 5278,
      },
      {
        nome: "Porto Esperidião",
        uf: "MT",
        estadoCod: 11,
        cod: 5279,
      },
      {
        nome: "Porto Estrela",
        uf: "MT",
        estadoCod: 11,
        cod: 5280,
      },
      {
        nome: "Poxoréo",
        uf: "MT",
        estadoCod: 11,
        cod: 5281,
      },
      {
        nome: "Primavera do Leste",
        uf: "MT",
        estadoCod: 11,
        cod: 5282,
      },
      {
        nome: "Querência",
        uf: "MT",
        estadoCod: 11,
        cod: 5283,
      },
      {
        nome: "São José dos Quatro Marcos",
        uf: "MT",
        estadoCod: 11,
        cod: 5284,
      },
      {
        nome: "Reserva do Cabaçal",
        uf: "MT",
        estadoCod: 11,
        cod: 5285,
      },
      {
        nome: "Ribeirão Cascalheira",
        uf: "MT",
        estadoCod: 11,
        cod: 5286,
      },
      {
        nome: "Ribeirãozinho",
        uf: "MT",
        estadoCod: 11,
        cod: 5287,
      },
      {
        nome: "Rio Branco",
        uf: "MT",
        estadoCod: 11,
        cod: 5288,
      },
      {
        nome: "Santa Carmem",
        uf: "MT",
        estadoCod: 11,
        cod: 5289,
      },
      {
        nome: "Santo Afonso",
        uf: "MT",
        estadoCod: 11,
        cod: 5290,
      },
      {
        nome: "São José do Povo",
        uf: "MT",
        estadoCod: 11,
        cod: 5291,
      },
      {
        nome: "São José do Rio Claro",
        uf: "MT",
        estadoCod: 11,
        cod: 5292,
      },
      {
        nome: "São José do Xingu",
        uf: "MT",
        estadoCod: 11,
        cod: 5293,
      },
      {
        nome: "São Pedro da Cipa",
        uf: "MT",
        estadoCod: 11,
        cod: 5294,
      },
      {
        nome: "Rondolândia",
        uf: "MT",
        estadoCod: 11,
        cod: 5295,
      },
      {
        nome: "Rondonópolis",
        uf: "MT",
        estadoCod: 11,
        cod: 5296,
      },
      {
        nome: "Rosário Oeste",
        uf: "MT",
        estadoCod: 11,
        cod: 5297,
      },
      {
        nome: "Santa Cruz do Xingu",
        uf: "MT",
        estadoCod: 11,
        cod: 5298,
      },
      {
        nome: "Salto do Céu",
        uf: "MT",
        estadoCod: 11,
        cod: 5299,
      },
      {
        nome: "Santa Rita do Trivelato",
        uf: "MT",
        estadoCod: 11,
        cod: 5300,
      },
      {
        nome: "Santa Terezinha",
        uf: "MT",
        estadoCod: 11,
        cod: 5301,
      },
      {
        nome: "Santo Antônio do Leste",
        uf: "MT",
        estadoCod: 11,
        cod: 5302,
      },
      {
        nome: "Santo Antônio do Leverger",
        uf: "MT",
        estadoCod: 11,
        cod: 5303,
      },
      {
        nome: "São Félix do Araguaia",
        uf: "MT",
        estadoCod: 11,
        cod: 5304,
      },
      {
        nome: "Sapezal",
        uf: "MT",
        estadoCod: 11,
        cod: 5305,
      },
      {
        nome: "Serra Nova Dourada",
        uf: "MT",
        estadoCod: 11,
        cod: 5306,
      },
      {
        nome: "Sinop",
        uf: "MT",
        estadoCod: 11,
        cod: 5307,
      },
      {
        nome: "Sorriso",
        uf: "MT",
        estadoCod: 11,
        cod: 5308,
      },
      {
        nome: "Tabaporã",
        uf: "MT",
        estadoCod: 11,
        cod: 5309,
      },
      {
        nome: "Tangará da Serra",
        uf: "MT",
        estadoCod: 11,
        cod: 5310,
      },
      {
        nome: "Tapurah",
        uf: "MT",
        estadoCod: 11,
        cod: 5311,
      },
      {
        nome: "Terra Nova do Norte",
        uf: "MT",
        estadoCod: 11,
        cod: 5312,
      },
      {
        nome: "Tesouro",
        uf: "MT",
        estadoCod: 11,
        cod: 5313,
      },
      {
        nome: "Torixoréu",
        uf: "MT",
        estadoCod: 11,
        cod: 5314,
      },
      {
        nome: "União do Sul",
        uf: "MT",
        estadoCod: 11,
        cod: 5315,
      },
      {
        nome: "Vale de São Domingos",
        uf: "MT",
        estadoCod: 11,
        cod: 5316,
      },
      {
        nome: "Várzea Grande",
        uf: "MT",
        estadoCod: 11,
        cod: 5317,
      },
      {
        nome: "Vera",
        uf: "MT",
        estadoCod: 11,
        cod: 5318,
      },
      {
        nome: "Vila Rica",
        uf: "MT",
        estadoCod: 11,
        cod: 5319,
      },
      {
        nome: "Nova Guarita",
        uf: "MT",
        estadoCod: 11,
        cod: 5320,
      },
      {
        nome: "Nova Marilândia",
        uf: "MT",
        estadoCod: 11,
        cod: 5321,
      },
      {
        nome: "Nova Maringá",
        uf: "MT",
        estadoCod: 11,
        cod: 5322,
      },
      {
        nome: "Nova Monte Verde",
        uf: "MT",
        estadoCod: 11,
        cod: 5323,
      },
    ],
  },
  {
    nome: "Goiás",
    uf: "GO",
    regiaoCod: 5,
    cod: 9,
    cidades: [
      {
        nome: "Abadia de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5324,
      },
      {
        nome: "Abadiânia",
        uf: "GO",
        estadoCod: 9,
        cod: 5325,
      },
      {
        nome: "Acreúna",
        uf: "GO",
        estadoCod: 9,
        cod: 5326,
      },
      {
        nome: "Adelândia",
        uf: "GO",
        estadoCod: 9,
        cod: 5327,
      },
      {
        nome: "Água Fria de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5328,
      },
      {
        nome: "Água Limpa",
        uf: "GO",
        estadoCod: 9,
        cod: 5329,
      },
      {
        nome: "Águas Lindas de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5330,
      },
      {
        nome: "Alexânia",
        uf: "GO",
        estadoCod: 9,
        cod: 5331,
      },
      {
        nome: "Aloândia",
        uf: "GO",
        estadoCod: 9,
        cod: 5332,
      },
      {
        nome: "Alto Horizonte",
        uf: "GO",
        estadoCod: 9,
        cod: 5333,
      },
      {
        nome: "Alto Paraíso de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5334,
      },
      {
        nome: "Alvorada do Norte",
        uf: "GO",
        estadoCod: 9,
        cod: 5335,
      },
      {
        nome: "Amaralina",
        uf: "GO",
        estadoCod: 9,
        cod: 5336,
      },
      {
        nome: "Americano do Brasil",
        uf: "GO",
        estadoCod: 9,
        cod: 5337,
      },
      {
        nome: "Amorinópolis",
        uf: "GO",
        estadoCod: 9,
        cod: 5338,
      },
      {
        nome: "Anápolis",
        uf: "GO",
        estadoCod: 9,
        cod: 5339,
      },
      {
        nome: "Anhanguera",
        uf: "GO",
        estadoCod: 9,
        cod: 5340,
      },
      {
        nome: "Anicuns",
        uf: "GO",
        estadoCod: 9,
        cod: 5341,
      },
      {
        nome: "Aparecida de Goiânia",
        uf: "GO",
        estadoCod: 9,
        cod: 5342,
      },
      {
        nome: "Aparecida do Rio Doce",
        uf: "GO",
        estadoCod: 9,
        cod: 5343,
      },
      {
        nome: "Aporé",
        uf: "GO",
        estadoCod: 9,
        cod: 5344,
      },
      {
        nome: "Araçu",
        uf: "GO",
        estadoCod: 9,
        cod: 5345,
      },
      {
        nome: "Aragarças",
        uf: "GO",
        estadoCod: 9,
        cod: 5346,
      },
      {
        nome: "Aragoiânia",
        uf: "GO",
        estadoCod: 9,
        cod: 5347,
      },
      {
        nome: "Araguapaz",
        uf: "GO",
        estadoCod: 9,
        cod: 5348,
      },
      {
        nome: "Arenópolis",
        uf: "GO",
        estadoCod: 9,
        cod: 5349,
      },
      {
        nome: "Aruanã",
        uf: "GO",
        estadoCod: 9,
        cod: 5350,
      },
      {
        nome: "Aurilândia",
        uf: "GO",
        estadoCod: 9,
        cod: 5351,
      },
      {
        nome: "Avelinópolis",
        uf: "GO",
        estadoCod: 9,
        cod: 5352,
      },
      {
        nome: "Baliza",
        uf: "GO",
        estadoCod: 9,
        cod: 5353,
      },
      {
        nome: "Barro Alto",
        uf: "GO",
        estadoCod: 9,
        cod: 5354,
      },
      {
        nome: "Bela Vista de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5355,
      },
      {
        nome: "Bom Jardim de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5356,
      },
      {
        nome: "Bom Jesus de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5357,
      },
      {
        nome: "Bonfinópolis",
        uf: "GO",
        estadoCod: 9,
        cod: 5358,
      },
      {
        nome: "Bonópolis",
        uf: "GO",
        estadoCod: 9,
        cod: 5359,
      },
      {
        nome: "Brazabrantes",
        uf: "GO",
        estadoCod: 9,
        cod: 5360,
      },
      {
        nome: "Britânia",
        uf: "GO",
        estadoCod: 9,
        cod: 5361,
      },
      {
        nome: "Buriti Alegre",
        uf: "GO",
        estadoCod: 9,
        cod: 5362,
      },
      {
        nome: "Buriti de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5363,
      },
      {
        nome: "Buritinópolis",
        uf: "GO",
        estadoCod: 9,
        cod: 5364,
      },
      {
        nome: "Cabeceiras",
        uf: "GO",
        estadoCod: 9,
        cod: 5365,
      },
      {
        nome: "Cachoeira Alta",
        uf: "GO",
        estadoCod: 9,
        cod: 5366,
      },
      {
        nome: "Cachoeira de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5367,
      },
      {
        nome: "Cachoeira Dourada",
        uf: "GO",
        estadoCod: 9,
        cod: 5368,
      },
      {
        nome: "Caçu",
        uf: "GO",
        estadoCod: 9,
        cod: 5369,
      },
      {
        nome: "Caiapônia",
        uf: "GO",
        estadoCod: 9,
        cod: 5370,
      },
      {
        nome: "Caldas Novas",
        uf: "GO",
        estadoCod: 9,
        cod: 5371,
      },
      {
        nome: "Caldazinha",
        uf: "GO",
        estadoCod: 9,
        cod: 5372,
      },
      {
        nome: "Campestre de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5373,
      },
      {
        nome: "Campinaçu",
        uf: "GO",
        estadoCod: 9,
        cod: 5374,
      },
      {
        nome: "Campinorte",
        uf: "GO",
        estadoCod: 9,
        cod: 5375,
      },
      {
        nome: "Campo Alegre de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5376,
      },
      {
        nome: "Campo Limpo de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5377,
      },
      {
        nome: "Campos Belos",
        uf: "GO",
        estadoCod: 9,
        cod: 5378,
      },
      {
        nome: "Campos Verdes",
        uf: "GO",
        estadoCod: 9,
        cod: 5379,
      },
      {
        nome: "Carmo do Rio Verde",
        uf: "GO",
        estadoCod: 9,
        cod: 5380,
      },
      {
        nome: "Castelândia",
        uf: "GO",
        estadoCod: 9,
        cod: 5381,
      },
      {
        nome: "Catalão",
        uf: "GO",
        estadoCod: 9,
        cod: 5382,
      },
      {
        nome: "Caturaí",
        uf: "GO",
        estadoCod: 9,
        cod: 5383,
      },
      {
        nome: "Cavalcante",
        uf: "GO",
        estadoCod: 9,
        cod: 5384,
      },
      {
        nome: "Ceres",
        uf: "GO",
        estadoCod: 9,
        cod: 5385,
      },
      {
        nome: "Cezarina",
        uf: "GO",
        estadoCod: 9,
        cod: 5386,
      },
      {
        nome: "Chapadão do Céu",
        uf: "GO",
        estadoCod: 9,
        cod: 5387,
      },
      {
        nome: "Cidade Ocidental",
        uf: "GO",
        estadoCod: 9,
        cod: 5388,
      },
      {
        nome: "Cocalzinho de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5389,
      },
      {
        nome: "Colinas do Sul",
        uf: "GO",
        estadoCod: 9,
        cod: 5390,
      },
      {
        nome: "Córrego do Ouro",
        uf: "GO",
        estadoCod: 9,
        cod: 5391,
      },
      {
        nome: "Corumbá de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5392,
      },
      {
        nome: "Corumbaíba",
        uf: "GO",
        estadoCod: 9,
        cod: 5393,
      },
      {
        nome: "Cristalina",
        uf: "GO",
        estadoCod: 9,
        cod: 5394,
      },
      {
        nome: "Cristianópolis",
        uf: "GO",
        estadoCod: 9,
        cod: 5395,
      },
      {
        nome: "Crixás",
        uf: "GO",
        estadoCod: 9,
        cod: 5396,
      },
      {
        nome: "Cromínia",
        uf: "GO",
        estadoCod: 9,
        cod: 5397,
      },
      {
        nome: "Cumari",
        uf: "GO",
        estadoCod: 9,
        cod: 5398,
      },
      {
        nome: "Damianópolis",
        uf: "GO",
        estadoCod: 9,
        cod: 5399,
      },
      {
        nome: "Damolândia",
        uf: "GO",
        estadoCod: 9,
        cod: 5400,
      },
      {
        nome: "Davinópolis",
        uf: "GO",
        estadoCod: 9,
        cod: 5401,
      },
      {
        nome: "Diorama",
        uf: "GO",
        estadoCod: 9,
        cod: 5402,
      },
      {
        nome: "Doverlândia",
        uf: "GO",
        estadoCod: 9,
        cod: 5403,
      },
      {
        nome: "Edealina",
        uf: "GO",
        estadoCod: 9,
        cod: 5404,
      },
      {
        nome: "Edéia",
        uf: "GO",
        estadoCod: 9,
        cod: 5405,
      },
      {
        nome: "Estrela do Norte",
        uf: "GO",
        estadoCod: 9,
        cod: 5406,
      },
      {
        nome: "Faina",
        uf: "GO",
        estadoCod: 9,
        cod: 5407,
      },
      {
        nome: "Fazenda Nova",
        uf: "GO",
        estadoCod: 9,
        cod: 5408,
      },
      {
        nome: "Firminópolis",
        uf: "GO",
        estadoCod: 9,
        cod: 5409,
      },
      {
        nome: "Flores de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5410,
      },
      {
        nome: "Formosa",
        uf: "GO",
        estadoCod: 9,
        cod: 5411,
      },
      {
        nome: "Formoso",
        uf: "GO",
        estadoCod: 9,
        cod: 5412,
      },
      {
        nome: "Gameleira de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5413,
      },
      {
        nome: "Divinópolis de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5414,
      },
      {
        nome: "Goianápolis",
        uf: "GO",
        estadoCod: 9,
        cod: 5415,
      },
      {
        nome: "Goiandira",
        uf: "GO",
        estadoCod: 9,
        cod: 5416,
      },
      {
        nome: "Goianésia",
        uf: "GO",
        estadoCod: 9,
        cod: 5417,
      },
      {
        nome: "Goiânia",
        uf: "GO",
        estadoCod: 9,
        cod: 5418,
      },
      {
        nome: "Goianira",
        uf: "GO",
        estadoCod: 9,
        cod: 5419,
      },
      {
        nome: "Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5420,
      },
      {
        nome: "Goiatuba",
        uf: "GO",
        estadoCod: 9,
        cod: 5421,
      },
      {
        nome: "Gouvelândia",
        uf: "GO",
        estadoCod: 9,
        cod: 5422,
      },
      {
        nome: "Guapó",
        uf: "GO",
        estadoCod: 9,
        cod: 5423,
      },
      {
        nome: "Guaraíta",
        uf: "GO",
        estadoCod: 9,
        cod: 5424,
      },
      {
        nome: "Guarani de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5425,
      },
      {
        nome: "Guarinos",
        uf: "GO",
        estadoCod: 9,
        cod: 5426,
      },
      {
        nome: "Heitoraí",
        uf: "GO",
        estadoCod: 9,
        cod: 5427,
      },
      {
        nome: "Hidrolândia",
        uf: "GO",
        estadoCod: 9,
        cod: 5428,
      },
      {
        nome: "Hidrolina",
        uf: "GO",
        estadoCod: 9,
        cod: 5429,
      },
      {
        nome: "Iaciara",
        uf: "GO",
        estadoCod: 9,
        cod: 5430,
      },
      {
        nome: "Inaciolândia",
        uf: "GO",
        estadoCod: 9,
        cod: 5431,
      },
      {
        nome: "Indiara",
        uf: "GO",
        estadoCod: 9,
        cod: 5432,
      },
      {
        nome: "Inhumas",
        uf: "GO",
        estadoCod: 9,
        cod: 5433,
      },
      {
        nome: "Ipameri",
        uf: "GO",
        estadoCod: 9,
        cod: 5434,
      },
      {
        nome: "Ipiranga de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5435,
      },
      {
        nome: "Iporá",
        uf: "GO",
        estadoCod: 9,
        cod: 5436,
      },
      {
        nome: "Israelândia",
        uf: "GO",
        estadoCod: 9,
        cod: 5437,
      },
      {
        nome: "Itaberaí",
        uf: "GO",
        estadoCod: 9,
        cod: 5438,
      },
      {
        nome: "Itaguari",
        uf: "GO",
        estadoCod: 9,
        cod: 5439,
      },
      {
        nome: "Itaguaru",
        uf: "GO",
        estadoCod: 9,
        cod: 5440,
      },
      {
        nome: "Itajá",
        uf: "GO",
        estadoCod: 9,
        cod: 5441,
      },
      {
        nome: "Itapaci",
        uf: "GO",
        estadoCod: 9,
        cod: 5442,
      },
      {
        nome: "Itapirapuã",
        uf: "GO",
        estadoCod: 9,
        cod: 5443,
      },
      {
        nome: "Itapuranga",
        uf: "GO",
        estadoCod: 9,
        cod: 5444,
      },
      {
        nome: "Itarumã",
        uf: "GO",
        estadoCod: 9,
        cod: 5445,
      },
      {
        nome: "Itauçu",
        uf: "GO",
        estadoCod: 9,
        cod: 5446,
      },
      {
        nome: "Itumbiara",
        uf: "GO",
        estadoCod: 9,
        cod: 5447,
      },
      {
        nome: "Ivolândia",
        uf: "GO",
        estadoCod: 9,
        cod: 5448,
      },
      {
        nome: "Jandaia",
        uf: "GO",
        estadoCod: 9,
        cod: 5449,
      },
      {
        nome: "Jaraguá",
        uf: "GO",
        estadoCod: 9,
        cod: 5450,
      },
      {
        nome: "Jataí",
        uf: "GO",
        estadoCod: 9,
        cod: 5451,
      },
      {
        nome: "Jaupaci",
        uf: "GO",
        estadoCod: 9,
        cod: 5452,
      },
      {
        nome: "Jesúpolis",
        uf: "GO",
        estadoCod: 9,
        cod: 5453,
      },
      {
        nome: "Joviânia",
        uf: "GO",
        estadoCod: 9,
        cod: 5454,
      },
      {
        nome: "Jussara",
        uf: "GO",
        estadoCod: 9,
        cod: 5455,
      },
      {
        nome: "Lagoa Santa",
        uf: "GO",
        estadoCod: 9,
        cod: 5456,
      },
      {
        nome: "Leopoldo de Bulhões",
        uf: "GO",
        estadoCod: 9,
        cod: 5457,
      },
      {
        nome: "Luziânia",
        uf: "GO",
        estadoCod: 9,
        cod: 5458,
      },
      {
        nome: "Mairipotaba",
        uf: "GO",
        estadoCod: 9,
        cod: 5459,
      },
      {
        nome: "Mambaí",
        uf: "GO",
        estadoCod: 9,
        cod: 5460,
      },
      {
        nome: "Mara Rosa",
        uf: "GO",
        estadoCod: 9,
        cod: 5461,
      },
      {
        nome: "Marzagão",
        uf: "GO",
        estadoCod: 9,
        cod: 5462,
      },
      {
        nome: "Matrinchã",
        uf: "GO",
        estadoCod: 9,
        cod: 5463,
      },
      {
        nome: "Maurilândia",
        uf: "GO",
        estadoCod: 9,
        cod: 5464,
      },
      {
        nome: "Mimoso de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5465,
      },
      {
        nome: "Minaçu",
        uf: "GO",
        estadoCod: 9,
        cod: 5466,
      },
      {
        nome: "Mineiros",
        uf: "GO",
        estadoCod: 9,
        cod: 5467,
      },
      {
        nome: "Moiporá",
        uf: "GO",
        estadoCod: 9,
        cod: 5468,
      },
      {
        nome: "Monte Alegre de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5469,
      },
      {
        nome: "Montes Claros de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5470,
      },
      {
        nome: "Montividiu",
        uf: "GO",
        estadoCod: 9,
        cod: 5471,
      },
      {
        nome: "Montividiu do Norte",
        uf: "GO",
        estadoCod: 9,
        cod: 5472,
      },
      {
        nome: "Morrinhos",
        uf: "GO",
        estadoCod: 9,
        cod: 5473,
      },
      {
        nome: "Morro Agudo de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5474,
      },
      {
        nome: "Mossâmedes",
        uf: "GO",
        estadoCod: 9,
        cod: 5475,
      },
      {
        nome: "Mozarlândia",
        uf: "GO",
        estadoCod: 9,
        cod: 5476,
      },
      {
        nome: "Mundo Novo",
        uf: "GO",
        estadoCod: 9,
        cod: 5477,
      },
      {
        nome: "Mutunópolis",
        uf: "GO",
        estadoCod: 9,
        cod: 5478,
      },
      {
        nome: "Nazário",
        uf: "GO",
        estadoCod: 9,
        cod: 5479,
      },
      {
        nome: "Nerópolis",
        uf: "GO",
        estadoCod: 9,
        cod: 5480,
      },
      {
        nome: "Niquelândia",
        uf: "GO",
        estadoCod: 9,
        cod: 5481,
      },
      {
        nome: "Nova América",
        uf: "GO",
        estadoCod: 9,
        cod: 5482,
      },
      {
        nome: "Nova Aurora",
        uf: "GO",
        estadoCod: 9,
        cod: 5483,
      },
      {
        nome: "Nova Crixás",
        uf: "GO",
        estadoCod: 9,
        cod: 5484,
      },
      {
        nome: "Nova Glória",
        uf: "GO",
        estadoCod: 9,
        cod: 5485,
      },
      {
        nome: "Nova Iguaçu de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5486,
      },
      {
        nome: "Nova Roma",
        uf: "GO",
        estadoCod: 9,
        cod: 5487,
      },
      {
        nome: "Nova Veneza",
        uf: "GO",
        estadoCod: 9,
        cod: 5488,
      },
      {
        nome: "Novo Brasil",
        uf: "GO",
        estadoCod: 9,
        cod: 5489,
      },
      {
        nome: "Novo Gama",
        uf: "GO",
        estadoCod: 9,
        cod: 5490,
      },
      {
        nome: "Novo Planalto",
        uf: "GO",
        estadoCod: 9,
        cod: 5491,
      },
      {
        nome: "Orizona",
        uf: "GO",
        estadoCod: 9,
        cod: 5492,
      },
      {
        nome: "Ouro Verde de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5493,
      },
      {
        nome: "Ouvidor",
        uf: "GO",
        estadoCod: 9,
        cod: 5494,
      },
      {
        nome: "Padre Bernardo",
        uf: "GO",
        estadoCod: 9,
        cod: 5495,
      },
      {
        nome: "Palestina de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5496,
      },
      {
        nome: "Palmeiras de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5497,
      },
      {
        nome: "Palmelo",
        uf: "GO",
        estadoCod: 9,
        cod: 5498,
      },
      {
        nome: "Palminópolis",
        uf: "GO",
        estadoCod: 9,
        cod: 5499,
      },
      {
        nome: "Panamá",
        uf: "GO",
        estadoCod: 9,
        cod: 5500,
      },
      {
        nome: "Paranaiguara",
        uf: "GO",
        estadoCod: 9,
        cod: 5501,
      },
      {
        nome: "Paraúna",
        uf: "GO",
        estadoCod: 9,
        cod: 5502,
      },
      {
        nome: "Perolândia",
        uf: "GO",
        estadoCod: 9,
        cod: 5503,
      },
      {
        nome: "Petrolina de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5504,
      },
      {
        nome: "Pilar de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5505,
      },
      {
        nome: "Piracanjuba",
        uf: "GO",
        estadoCod: 9,
        cod: 5506,
      },
      {
        nome: "Piranhas",
        uf: "GO",
        estadoCod: 9,
        cod: 5507,
      },
      {
        nome: "Pirenópolis",
        uf: "GO",
        estadoCod: 9,
        cod: 5508,
      },
      {
        nome: "Pires do Rio",
        uf: "GO",
        estadoCod: 9,
        cod: 5509,
      },
      {
        nome: "Planaltina",
        uf: "GO",
        estadoCod: 9,
        cod: 5510,
      },
      {
        nome: "Pontalina",
        uf: "GO",
        estadoCod: 9,
        cod: 5511,
      },
      {
        nome: "Porangatu",
        uf: "GO",
        estadoCod: 9,
        cod: 5512,
      },
      {
        nome: "Porteirão",
        uf: "GO",
        estadoCod: 9,
        cod: 5513,
      },
      {
        nome: "Portelândia",
        uf: "GO",
        estadoCod: 9,
        cod: 5514,
      },
      {
        nome: "Posse",
        uf: "GO",
        estadoCod: 9,
        cod: 5515,
      },
      {
        nome: "Professor Jamil",
        uf: "GO",
        estadoCod: 9,
        cod: 5516,
      },
      {
        nome: "Quirinópolis",
        uf: "GO",
        estadoCod: 9,
        cod: 5517,
      },
      {
        nome: "Rialma",
        uf: "GO",
        estadoCod: 9,
        cod: 5518,
      },
      {
        nome: "Rianápolis",
        uf: "GO",
        estadoCod: 9,
        cod: 5519,
      },
      {
        nome: "Rio Quente",
        uf: "GO",
        estadoCod: 9,
        cod: 5520,
      },
      {
        nome: "Rio Verde",
        uf: "GO",
        estadoCod: 9,
        cod: 5521,
      },
      {
        nome: "Rubiataba",
        uf: "GO",
        estadoCod: 9,
        cod: 5522,
      },
      {
        nome: "Sanclerlândia",
        uf: "GO",
        estadoCod: 9,
        cod: 5523,
      },
      {
        nome: "Santa Bárbara de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5524,
      },
      {
        nome: "Santa Cruz de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5525,
      },
      {
        nome: "Santa Fé de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5526,
      },
      {
        nome: "Santa Helena de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5527,
      },
      {
        nome: "Santa Isabel",
        uf: "GO",
        estadoCod: 9,
        cod: 5528,
      },
      {
        nome: "Santa Rita do Araguaia",
        uf: "GO",
        estadoCod: 9,
        cod: 5529,
      },
      {
        nome: "Santa Rita do Novo Destino",
        uf: "GO",
        estadoCod: 9,
        cod: 5530,
      },
      {
        nome: "Santa Rosa de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5531,
      },
      {
        nome: "Santa Tereza de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5532,
      },
      {
        nome: "Santa Terezinha de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5533,
      },
      {
        nome: "Santo Antônio da Barra",
        uf: "GO",
        estadoCod: 9,
        cod: 5534,
      },
      {
        nome: "Santo Antônio de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5535,
      },
      {
        nome: "Santo Antônio do Descoberto",
        uf: "GO",
        estadoCod: 9,
        cod: 5536,
      },
      {
        nome: "São Domingos",
        uf: "GO",
        estadoCod: 9,
        cod: 5537,
      },
      {
        nome: "São Francisco de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5538,
      },
      {
        nome: "São João D'Aliança",
        uf: "GO",
        estadoCod: 9,
        cod: 5539,
      },
      {
        nome: "São João da Paraúna",
        uf: "GO",
        estadoCod: 9,
        cod: 5540,
      },
      {
        nome: "São Luís de Montes Belos",
        uf: "GO",
        estadoCod: 9,
        cod: 5541,
      },
      {
        nome: "São Luíz do Norte",
        uf: "GO",
        estadoCod: 9,
        cod: 5542,
      },
      {
        nome: "São Miguel do Araguaia",
        uf: "GO",
        estadoCod: 9,
        cod: 5543,
      },
      {
        nome: "São Miguel do Passa Quatro",
        uf: "GO",
        estadoCod: 9,
        cod: 5544,
      },
      {
        nome: "São Patrício",
        uf: "GO",
        estadoCod: 9,
        cod: 5545,
      },
      {
        nome: "São Simão",
        uf: "GO",
        estadoCod: 9,
        cod: 5546,
      },
      {
        nome: "Senador Canedo",
        uf: "GO",
        estadoCod: 9,
        cod: 5547,
      },
      {
        nome: "Serranópolis",
        uf: "GO",
        estadoCod: 9,
        cod: 5548,
      },
      {
        nome: "Silvânia",
        uf: "GO",
        estadoCod: 9,
        cod: 5549,
      },
      {
        nome: "Simolândia",
        uf: "GO",
        estadoCod: 9,
        cod: 5550,
      },
      {
        nome: "Sítio D'Abadia",
        uf: "GO",
        estadoCod: 9,
        cod: 5551,
      },
      {
        nome: "Taquaral de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5552,
      },
      {
        nome: "Teresina de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5553,
      },
      {
        nome: "Terezópolis de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5554,
      },
      {
        nome: "Três Ranchos",
        uf: "GO",
        estadoCod: 9,
        cod: 5555,
      },
      {
        nome: "Trindade",
        uf: "GO",
        estadoCod: 9,
        cod: 5556,
      },
      {
        nome: "Trombas",
        uf: "GO",
        estadoCod: 9,
        cod: 5557,
      },
      {
        nome: "Turvânia",
        uf: "GO",
        estadoCod: 9,
        cod: 5558,
      },
      {
        nome: "Turvelândia",
        uf: "GO",
        estadoCod: 9,
        cod: 5559,
      },
      {
        nome: "Uirapuru",
        uf: "GO",
        estadoCod: 9,
        cod: 5560,
      },
      {
        nome: "Uruaçu",
        uf: "GO",
        estadoCod: 9,
        cod: 5561,
      },
      {
        nome: "Uruana",
        uf: "GO",
        estadoCod: 9,
        cod: 5562,
      },
      {
        nome: "Urutaí",
        uf: "GO",
        estadoCod: 9,
        cod: 5563,
      },
      {
        nome: "Valparaíso de Goiás",
        uf: "GO",
        estadoCod: 9,
        cod: 5564,
      },
      {
        nome: "Varjão",
        uf: "GO",
        estadoCod: 9,
        cod: 5565,
      },
      {
        nome: "Vianópolis",
        uf: "GO",
        estadoCod: 9,
        cod: 5566,
      },
      {
        nome: "Vicentinópolis",
        uf: "GO",
        estadoCod: 9,
        cod: 5567,
      },
      {
        nome: "Vila Boa",
        uf: "GO",
        estadoCod: 9,
        cod: 5568,
      },
      {
        nome: "Vila Propício",
        uf: "GO",
        estadoCod: 9,
        cod: 5569,
      },
    ],
  },
  {
    nome: "Distrito Federal",
    uf: "DF",
    regiaoCod: 5,
    cod: 7,
    cidades: [
      {
        nome: "Brasília",
        uf: "DF",
        estadoCod: 7,
        cod: 5570,
      },
    ],
  },
];
