import React from "react";
import imgStar from "~/assets/icones/estrelas.png";
import imgAstro from "~/assets/imagens_pequenas/astronaut-icon.png";

import { Container } from "./styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";

function RankingAlunos({ stats }) {
  return (
    <Container className="ranking">
      <div className="ranking-header">
        <h5>Ranking Alunos</h5>
      </div>
      <CustomScrollBar autoHeightMax="24em">
        <ul>
          {stats?.rankingAlunos?.map((usuario, index) => (
            <li key={usuario.cod}>
              <img className="RankingAlunos_img_astronauta" src={imgAstro} alt="" />
              <h4>{usuario.nome}</h4>
              <div className="star-field">
                <img src={imgStar} alt="" />
                <h6>{usuario.estrelas}</h6>
              </div>
              <div className="ball-small">{index + 1}</div>
            </li>
          ))}
        </ul>
      </CustomScrollBar>
    </Container>
  );
}

export default RankingAlunos;
