import React from "react";
import { useDispatch } from "react-redux";
import { SistemaSenhaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";
import api from "~/services/api";
import ShowError from "~/utils/ShowError";
import ShowLoading from "~/utils/ShowLoading";
import { signInRequest } from "~/store/modules/auth/actions";

import { Container } from "./styles";

function ThisConfirmModal({ outClick, closeParentModal, updateSenha, formData }) {
  const [senha, setSenha] = React.useState();
  const dispatch = useDispatch();

  function handleSubmit(closeThisModal) {
    if (!senha) {
      return ShowError("Informe sua senha.");
    }

    if (updateSenha === true || updateSenha === "true") {
      if (
        !formData?.novaSenha ||
        !formData?.confirmNovaSenha ||
        formData?.novaSenha !== formData?.confirmNovaSenha
      ) {
        return ShowError(
          "Para alterar a senha, deve ser informado uma nova senha igual à confimação."
        );
      }
    }

    if (!formData?.nome) ShowError("É necessário informar o nome.");
    else if (!formData?.cpf) ShowError("É necessário informar o cpf.");
    else if (!formData?.email) ShowError("É necessário informar o email.");
    else if (!formData?.nascimento)
      ShowError("É necessário informar o dia de nascimento.");
    else {
      ShowLoading(() =>
        api.put("global/updateUsuario", { ...formData, senha }).then((r) => {
          typeof outClick === "function" && outClick();
          typeof closeParentModal === "function" && closeParentModal();
          dispatch(
            signInRequest(
              r?.data?.email || formData?.email,
              updateSenha === true || updateSenha === "true"
                ? formData?.novaSenha
                : senha,
              true
            )
          );
        })
      );
    }
  }

  return (
    <Container
      outClick={outClick}
      title="Confirmação"
      footerButtons={{ submitButton: { type: "button", onClick: handleSubmit } }}
      scrollBar
    >
      <p>
        Tem certeza que deseja alterar os seus dados? Para confirmar, é necessário
        informar a senha atual.
      </p>
      <Label title="Senha Atual" className="Perfil_Confirmacao_Label_input_senha">
        <SistemaSenhaInput setter={setSenha} />
      </Label>
    </Container>
  );
}

export default ThisConfirmModal;
