import styled from "styled-components";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";

export const Container = styled(SistemaComponenteInterno)``;

export const ChamadoCard = styled.div`
  padding: 0 0 0.625em 0;
  margin-bottom: 0.75em;
  border-bottom: solid #ebebeb 0.125em;
  cursor: pointer;
  transition: 0.2s ease;

  &:active {
    transform: scale3d(0.98, 0.98, 0.98);
  }

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 1em;
    line-height: 1.375em;
    /* identical to box height */

    color: #1ca9d0;
  }

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.125em;
    line-height: 2em;
    /* identical to box height */

    color: #293f57;
    white-space: nowrap;
    width: 52.188em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    font-style: normal;
    font-weight: 600;
    font-size: 0.875em;
    line-height: 1.375em;
    /* or 20px */

    color: #66707c;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
`;

export const SpanCustom = styled.span`
  color: ${({ etapa }) => {
    switch (etapa) {
      case 1:
        return "#FF4031";
      case 2 || 3:
        return "#4FA754";
      default:
        return "#1ca9d0";
    }
  }};
  font-weight: 600;
  font-size: 0.9em;
  line-height: 1.375em;
  text-transform: uppercase;
`;

export const FlexH3 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AbaField = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: -0.1em;

  @media (max-width: 30em) {
    button {
      width: 4.375em !important;
      justify-content: center !important;

      h6 {
        display: none;
        font-weight: 600;
        font-size: 1em;
      }
    }
    button + button {
      margin-left: 1.8em !important;
    }
    button::before {
      width: 4.375em !important;
    }
  }
  button.ativo {
    color: #293f57;
  }
  button.ativo::before {
    background: #fff;
  }
  button.ativo::after {
    background: #fff;
  }
  button + button {
    margin-left: 2.8em;
  }
  button:active {
    transform: scale(0.97);
  }
  button {
    transition: 0.1s ease;
    cursor: pointer;
    margin-left: 1.1em;
    color: #fff;
    position: relative;
    background: none;
    border: none;
    width: 8.375em;
    height: 3.125em;
    padding: 0.4em;
    display: flex;
    align-items: center;
    justify-content: left;
    background: none;

    font-weight: 600;
    font-size: 1em;
    line-height: 1.375em;
    h6 {
      font-weight: 600;
      font-size: 1em;
    }
    span {
      margin-left: -0.2em;
      margin-right: 0.5em;
    }
  }
  button::before {
    z-index: -1;
    position: absolute;
    content: "";
    width: 9.375em;
    height: 3.125em;
    box-shadow: 0.313em -0.125em 0.25em rgb(0 0 0 / 4%);
    border-radius: 0.375em;
    top: -0.063em;
    left: -0.313em;
    transform: skew(32deg);
    background: #1ca9d0;
  }
  button::after {
    z-index: -1;
    position: absolute;
    content: "";
    width: 2.188em;
    height: 3.125em;
    border-radius: 0.375em 0 0 0;
    top: -0.063em;
    left: -1.135em;
    background: #1ca9d0;
  }
`;

export const AbaContent = styled.div`
  background: #fff;
  background: #fff;
  padding: 1em;
  border-radius: 0.3em;
`;
