import React from "react";

import BarChart from "./BarChart";
import CardList from "./CardList";
import { Container } from "./styles";

function FirstSection({ data }) {
  return (
    <Container>
      <BarChart stats={data?.mediaAnual} />
      <CardList stats={data} />
    </Container>
  );
}

export default FirstSection;
