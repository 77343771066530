/* eslint-disable eqeqeq */
import React from "react";

import { Container, MaterializeContent } from "./styles";
import FirstSection from "./FirstSection";
import ThisTable from "./ThisTable";
import Pagination from "~/componentes/Pagination";
import ThisFilter from "./ThisFilter";
import ThisPdfButton from "./ThisPdfButton";
import ThisExcelButton from "./ThisExcelButton";

function Turmas() {
  const [data, setData] = React.useState();
  const [selectedDTM, setSelectedDTM] = React.useState();
  const [atividades, setAtividades] = React.useState([]);

  const avaliacoesDisponibilizadas = React.useMemo(() => {
    let result = [];

    const mapAVT = (dtm) =>
      dtm?.avaliacoesDisponibilizadas?.forEach?.((avt) => {
        result.push({
          ...avt,
          disciplina: dtm?.disciplinaFilial?.nome,
          professor: dtm?.professor?.usuario?.nome,
        });
      });

    if (selectedDTM?.cod) {
      mapAVT(selectedDTM);
    } else {
      if (Array.isArray(data?.disciplinasTurmas)) {
        data.disciplinasTurmas.forEach((dtm) => mapAVT(dtm));
      }
    }

    return result.sort((a, b) => {
      const dateA = a?.dataFim ? new Date(a).getTime() : 0;
      const dateB = b?.dataFim ? new Date(b).getTime() : 0;

      return dateB - dateA;
    });
  }, [data, selectedDTM]);

  return (
    <Container>
      <ThisFilter {...{ setData }} />

      <FirstSection {...{ data, selectedDTM, setSelectedDTM }} />
      <div className="table-content">
        <ThisTable {...{ data, atividades }} />

        <div className="table-action-buttons">
          <MaterializeContent>
            <div className="row">
              <div className="col s12 m12 l6 paginator">
                <Pagination
                  data={avaliacoesDisponibilizadas}
                  getInfo={({ pageData }) => {
                    setAtividades(pageData);
                  }}
                  size={4}
                />
              </div>
              <div className="col s12 m12 l6 actions-paginator">
                <ThisPdfButton
                  {...{ data, selectedDTM, avaliacoesDisponibilizadas }}
                />
                <ThisExcelButton
                  {...{ data, selectedDTM, avaliacoesDisponibilizadas }}
                />
              </div>
            </div>
          </MaterializeContent>
        </div>
      </div>
    </Container>
  );
}

export default Turmas;
