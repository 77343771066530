import React from "react";
import {
  EduquemeInput,
  EduqueMeSenhaInput,
} from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";
import setRegex from "~/utils/setRegex";

// import { Container } from './styles';

function ThisFormElements({ animationClass, cadastro }) {
  return (
    <>
      {cadastro && (
        <>
          <Label className={`col s12 m12 l12 ${animationClass}`} title="Nome">
            <EduquemeInput name="nome" required />
          </Label>
          <Label className={`col s12 m12 l12 ${animationClass}`} title="Cpf">
            <EduquemeInput onChange={setRegex.setCpf} name="cpf" required />
          </Label>
          <Label
            className={`col s12 m12 l12 ${animationClass}`}
            title="Nascimento"
          >
            <EduquemeInput type="date" name="nascimento" required />
          </Label>
        </>
      )}
      <Label className="col s12 m12 l12" title="Email">
        <EduquemeInput type="email" name="email" required />
      </Label>
      <Label className="col s12 m12 l12" title="Senha">
        <EduqueMeSenhaInput name="senha" required />
      </Label>

      {cadastro && (
        <Label
          className={`col s12 m12 l12 ${animationClass}`}
          title="Confirme sua Senha"
        >
          <EduqueMeSenhaInput name="confirmaSenha" required />
        </Label>
      )}
    </>
  );
}

export default ThisFormElements;
