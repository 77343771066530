import FindKeyValue from "~/utils/FindKeyValue";
import { IsObject } from "~/utils/CheckDataType";

const stateExampe = {
  data: [],
  filteredSelects: [],
  filteredData: [],
  selects: {},
  inputs: {},
  singleSelect: false,
  singleInput: false,
};

function filterData(data, filterObject, equal) {
  let newData = [];
  if (Array.isArray(data)) {
    if (IsObject(filterObject)) {
      const thisFilter = Object.entries(filterObject).filter(
        ([, value]) => value && value !== ""
      );

      if (Array.isArray(thisFilter) && thisFilter.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i];

          const notInclude = thisFilter.some(([key, value]) => {
            if (equal) {
              return (
                FindKeyValue(key, element)?.toString()?.toLowerCase() !==
                value?.toString()?.toLowerCase()
              );
            } else {
              return !FindKeyValue(key, element)
                ?.toString()
                ?.toLowerCase()
                ?.includes(value?.toString()?.toLowerCase());
            }
          });

          !notInclude && newData.push(element);
        }
      } else {
        newData = data;
      }
    } else {
      newData = data;
    }
  }

  return newData;
}

export default function FilterListReducer(state = stateExampe, { type, payload }) {
  switch (type) {
    case "FilterListStoreLoadData": {
      const { data } = payload || {};
      const { selects, inputs } = state || {};

      const filteredSelects = filterData(data, selects, true);

      const filteredData = filterData(filteredSelects, inputs, false);

      return { ...state, data, filteredSelects, filteredData };
    }
    case "FilterListStoreHandleSelect": {
      const { key, value } = payload || {};
      const { data, inputs, singleSelect } = state || {};

      const selects =
        singleSelect || !state?.selects
          ? { [key]: value }
          : { ...state.selects, [key]: value };

      const filteredSelects = filterData(data, selects, true);

      const filteredData = filterData(filteredSelects, inputs, false);

      return { ...state, selects, filteredSelects, filteredData };
    }
    case "FilterListStoreUpdateSelects": {
      const { selects } = payload || {};
      const { data, inputs } = state || {};

      const filteredSelects = filterData(data, selects, true);

      const filteredData = filterData(filteredSelects, inputs, false);

      return { ...state, selects, filteredSelects, filteredData };
    }
    case "FilterListStoreHandleInput": {
      const { key, value } = payload || {};
      const { filteredSelects, singleInput } = state || {};

      const inputs =
        singleInput || !state?.inputs
          ? { [key]: value }
          : { ...state.inputs, [key]: value };

      const filteredData = filterData(filteredSelects, inputs, false);

      return { ...state, inputs, filteredData };
    }
    default:
      return state;
  }
}
