import React from "react";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTable, SLTableCell } from "~/pages/Sistema/componentes/SistemaList";

function TableComponent({ series, setTabFilter, periodosLetivos }) {
  return (
    <>
      <TabsWrapper>
        <Tab
          onClick={() =>
            typeof setTabFilter === "function" && setTabFilter({ ativo: true })
          }
        >
          Ativo
        </Tab>
        <Tab
          onClick={() =>
            typeof setTabFilter === "function" && setTabFilter({ ativo: (data) => !data })
          }
        >
          Desativo
        </Tab>
      </TabsWrapper>
      <SistemaComponenteInterno>
        <SLTable
          selects={[
            {
              filter: "periodoLetivoCod",
              list: periodosLetivos,
              value: "cod",
              label: "nome",
              outLabel: "Período Letivo",
            },
          ]}
        >
          <SLTableCell name="nome" inputfilter>
            Nome
          </SLTableCell>
          <SLTableCell name="serie.nome" selectfilter={{ list: series, value: "nome" }}>
            Série
          </SLTableCell>
          <SLTableCell name="serie.ensino.nome">Ensino</SLTableCell>
        </SLTable>
      </SistemaComponenteInterno>
    </>
  );
}

export default TableComponent;
