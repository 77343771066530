import React, { useEffect, useState } from "react";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import Label from "~/componentes/CustomForm/Label";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";

// import { Container } from './styles';

function InputNatureza({ naturezaSelected, setNaturezaSelected }) {

  return (
    <SistemaInput
      name="natureza"
      defaultValue={naturezaSelected?.codigo || ""}
      setter={(natureza) => setNaturezaSelected({...naturezaSelected, codigo: natureza || ""})}
      required
    />
  );
}

export default InputNatureza;
