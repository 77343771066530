import styled from "styled-components";
import Modal from "~/componentes/Modal";

export const Container = styled.div`
  position: fixed;
  background: #0005;
  top: ${(props) => (props.top ? `-${props.top}px` : 0)};
  left: ${(props) => (props.left ? `-${props.left}px` : 0)};
  width: 100vw;
  height: 100vh;

  /* box-shadow: 0 0 0 100vmax #0005;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0; */

  overflow: hidden;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: opacityAnimationIn 0.3s forwards;

  ${(props) => props.close && `animation: opacityAnimationOut 0.4s forwards;`}

  @keyframes opacityAnimationIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes opacityAnimationOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

export const ModalContainer = styled(Modal)`
  position: fixed;
  max-width: 95vw;

  background: #fff;
  border-radius: 0.6em;
  padding: 0.5em 1em 1em;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */

  box-shadow: 0 0.5em 0.625em 0.063em rgba(0, 0, 0, 0.14),
    0 0.188em 0.875em 0.125em rgba(0, 0, 0, 0.12),
    0 0.313em 0.313em -0.188em rgba(0, 0, 0, 0.3);

  animation: modalIn 0.3s forwards;

  ${(props) => props.close && `animation: modalOut 0.4s forwards;`}

  @keyframes modalIn {
    from {
      margin-top: -10%;
      transform: scaleX(0.7);
    }
    to {
      transform: scaleX(1);
      margin-top: 0%;
    }
  }
  @keyframes modalOut {
    from {
      margin-top: 0%;
      transform: scaleX(1);
    }
    to {
      transform: scaleX(0.7);
      margin-top: -10%;
    }
  }
`;
