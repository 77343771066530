import React, { useReducer } from "react";
import FilterListStore, {
  FilterListStoreLoadData,
  FilterListStoreUpdateSelects,
} from "./FilterListStore";
import { useEffect } from "react";
import FilterListReducer from "./FilterListStore/reducer";
import { useState } from "react";

/**
 * Esse componente cria um context para que os componentes Select e Input possam filtrar o data
 * caso seja passado a props "filter" neles
 * @param {Object} props
 * @param {Object[]} props.data Dados a serem filtrados.
 * @param {Function} props.setter Função que retorna o dado filtrado.
 * @param {Object} props.selectFilters Objeto contendo chave e valor. Exemplo: {"aluno.usuario.nome": "Rafael"}. Usado principalmente quando deseja realizar algo diferente do fluxo padrão.
 * @param {Boolean} props.singleInput Se true, o filtro só levará em consideração um único input, que será o último a ser alterado.
 * @param {Boolean} props.singleSelect Se true, o filtro só levará em consideração um único select, que será o último a ser alterado.
 */
function FilterList({
  data,
  setter,
  children,
  singleSelect,
  singleInput,
  selectFilters,
}) {
  const [state, dispatch] = useReducer(FilterListReducer, {
    data: [],
    filteredSelects: [],
    filteredData: [],
    selects: {},
    inputs: {},
    singleSelect,
    singleInput,
  });
  const [loaded, setLoaded] = useState();

  useEffect(() => {
    data && dispatch(FilterListStoreLoadData(data));
  }, [data]);

  useEffect(() => {
    dispatch(FilterListStoreUpdateSelects(selectFilters));
  }, [selectFilters]);

  useEffect(() => {
    if (loaded && setter) {
      setter(state.filteredData);
    } else if (
      !loaded &&
      Array.isArray(state.filteredData) &&
      state.filteredData.length > 0
    ) {
      setLoaded(true);
    }
  }, [loaded, setter, state.filteredData]);

  return (
    <FilterListStore.Provider value={{ state, dispatch }}>
      {children}
    </FilterListStore.Provider>
  );
}

export default FilterList;
