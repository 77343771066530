/* eslint-disable no-useless-computed-key */
import React, { useState } from "react";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import { SLTableCell } from "~/pages/Sistema/componentes/SistemaList";
import { ThisTable } from "./styles";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";
import Label from "~/componentes/CustomForm/Label";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";

function TableComponent({ setTabFilter, bimestre, codBimestre, setBimestre, setCodBimestre }) {
  return (
    <>
      <TabsWrapper tabHeight={2.4}>
        <Tab materialIcon="check_circle_outline" onClick={() => setTabFilter({ ["turma.ativo"]: true })}>
          Ativo
        </Tab>
        <Tab materialIcon="highlight_off" onClick={() => setTabFilter({ ["turma.ativo"]: false })}>
          Desativo
        </Tab>
      </TabsWrapper>

      <SistemaComponenteInterno>
      <Label
        title="Bimestre:"
        className="col s12 m12 l3">
        {/* <SistemaInput name={'bimestre'} defaultValue={bimestre} required /> */}
        <SistemaSelect defaultValue={bimestre} onChange={(thiss) => {setBimestre(thiss.target.value); console.log(thiss.target.value)}}>
          <option value={1}>Bimestre 1</option>
          <option value={2}>Bimestre 2</option>
          <option value={3}>Bimestre 3</option>
          <option value={4}>Bimestre 4</option>
        </SistemaSelect>
      </Label>
        <ThisTable noEdit={true}>
          <SLTableCell name="turma.nome" inputfilter>
            Nome
          </SLTableCell>
          <SLTableCell name="disciplinaFilial.nome" inputfilter>
            Disciplina
          </SLTableCell>
          <SLTableCell name="turma.serie.nome" inputfilter>
            Série
          </SLTableCell>
          <SLTableCell name="turma.periodoLetivo.nome" inputfilter>
            Período Letivo
          </SLTableCell>
        </ThisTable>
      </SistemaComponenteInterno>
    </>
  );
}

export default TableComponent;
