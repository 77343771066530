import React, { useState } from "react";
import planetas_aneis from "~/assets/planetas/planetas_aneis.png";
import planeta_vermelho from "~/assets/planetas/planeta_vermelho.png";
import planeta_fundao from "~/assets/planetas/planeta_fundao.png";

import { Container, Content, Navegacao } from "./styles";
import UserDevice from "~/componentes/UserDevice";
import { useRef } from "react";

export default function Conceito() {
  const userDevice = UserDevice();
  const { desktop, rotatedTablet } = userDevice;
  const [[sobre, missao, visao], setSelection] = useState([true, false, false]);
  const [order, setOrder] = useState(1);

  const planetaAnimation = useRef();

  const sobreTexto = (
    <p>
      Plataforma digital de apoio a educação com gestão da escola, controle de
      avaliações, matrículas online, acompanhamento dos pais e o melhor da
      plataforma é que ainda tem o aplicativo gamificado fazendo o aluno
      aprender brincando.
    </p>
  );
  const missaoTexto = (
    <p>
      Promover apoio a estudantes, pais, professores, instituições de ensino e
      colaboradores da educação através de sistemas/aplicativos.
    </p>
  );
  const visaoTexto = (
    <p>Ser uma plataforma digital de referência nacional a apoio a educação.</p>
  );

  const planetaAneis = (
    <img
      ref={planetaAnimation}
      className="toggleAnimation"
      src={planetas_aneis}
      alt="Planeta dos Anéis"
    />
  );
  const planetaVermelho = (
    <img
      ref={planetaAnimation}
      className="toggleAnimation"
      src={planeta_vermelho}
      alt="Planeta dos Anéis"
    />
  );
  const planetaFundao = (
    <img
      ref={planetaAnimation}
      className="toggleAnimation"
      src={planeta_fundao}
      alt="Planeta dos Anéis"
    />
  );

  const handleNavegation = (ord, selection) => {
    if (ord !== order && planetaAnimation?.current?.style?.animationName)
      planetaAnimation.current.style.animationName = "toggleAnimationEnd";
    setSelection(selection);
    setOrder(ord);
  };

  return (
    <Container>
      <Content desktop={desktop} rotatedTablet={rotatedTablet}>
        <h1>O que é Eduque-me</h1>
        <div className="botoes">
          <Navegacao
            selection={sobre}
            onClick={() => handleNavegation(1, [true, false, false])}
          >
            Sobre nós
          </Navegacao>
          <Navegacao
            selection={missao}
            onClick={() => handleNavegation(2, [false, true, false])}
          >
            Missão
          </Navegacao>
          <Navegacao
            selection={visao}
            onClick={() => handleNavegation(3, [false, false, true])}
          >
            Visão
          </Navegacao>
        </div>
        {(missao && missaoTexto) || (visao && visaoTexto) || sobreTexto}
      </Content>
      {(desktop || rotatedTablet) && (
        <div className="planetaDiv">
          {order === 1 && planetaAneis}
          {order === 2 && planetaVermelho}
          {order === 3 && planetaFundao}
        </div>
      )}
    </Container>
  );
}
