import React, { useState } from "react";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";
import SistemaTable from "~/componentes/StyledComponents/Tables/SistemaTable";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import Label from "~/componentes/CustomForm/Label";
import IconButton from "~/componentes/CustomForm/Button/Styles/IconButton";
import Pagination from "~/componentes/Pagination";
import { useEffect } from "react";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import api from "~/services/api";

import { ThisAddDisciplinaTurma } from "./styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import ShowError from "~/utils/ShowError";
import { Materialize } from "~/styles/materializeGrid";
import { SubTitle } from "../ShowContent/styles";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import { SLForm, SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import ADDManualmente from "./ADDManualmente";
import ADDAtividades from "./ADDAtividades";

function MediaCalcular({ data, outClick }) {
  const [aDDManualmente, setADDManualmente] = useState();
  const [aDDAtividades, setAtividades] = useState();
  
  let alunosNotaIncompleta = data.alunoDisciplinaTurma.map((ele)=>{
    if (ele.notas.length < data.quantidadeAvaliacoes) {
      return 'Nota incompleta'
    } else {
      return "Nota completa";
    }
  })
  let endSubmit = false;
  async function handleSubmit(dataPut, options){
    ShowConfirmacao(
      async () => {
        let response = await api.get(
          "professor/turma/alunos/medias",
          {params: {cod: data.cod}}
        );
        if(response.status == 200){
          options.reloadData();
          endSubmit = true;
        }
        return response
      },
      {
        errorModal: {defaultMessage: "Não foi possível atualizar a média devido a um erro no sistema"},
        successModal: false,
        message: "Tem certeza que deseja atualizar a média?",
      }
    );
  }
  function onSubmit(closeModal){
    document.querySelector("#BTN_SUBMIT").click();
    let timer = setInterval(() => {
      if(endSubmit){
        closeModal()
        clearInterval(timer);
      }
    }, 1000)
  }
  console.log(data)
  console.log(alunosNotaIncompleta)
  return (
    <SistemaFixedModal
      title="Calcular médias"
      style={{ width: "50em", maxWidth: "100%" }}
      {...{ outClick }}
      footerButtons={{submitButton: {onClick: onSubmit, text: "Calcular Média"}, cancelButton: {style: {display: "none"}}}}
    >
      {aDDAtividades && (<ADDAtividades
        data={aDDAtividades}
        outClick={() => setAtividades()}
      />)}
      {aDDManualmente && (<ADDManualmente
        data={aDDManualmente}
        outClick={() => setADDManualmente()}
      />)}
      <SLForm onSubmit={handleSubmit}>
        <CustomScrollBar fixScrollMargin autoHeightMax="45vh">
          <Materialize>
            <SubTitle>Situação das notas dos alunos</SubTitle>
            <SistemaTable>
              <thead>
                  <tr>
                    <th>Matricula</th>
                    <th>Nome</th>
                    <th>Email</th>
                    <th>Estado</th>
                  </tr>
                </thead>
              <tbody>
                {data?.alunoDisciplinaTurma?.map((t, i) => (
                  <tr key={t?.alunoTurma?.aluno?.cod || i}>
                  <td>{t?.alunoTurma?.aluno?.matricula}</td>
                  <td>{t?.alunoTurma?.aluno?.usuario?.nome}</td>
                  <td>{t?.alunoTurma?.aluno?.usuario?.email}</td>
                  <td>
                    <strong>{alunosNotaIncompleta[i]}</strong>
                  </td>
                </tr>
                ))}
              </tbody>
          <input type="submit" id="BTN_SUBMIT" style={{opacity: 0}} />
            </SistemaTable>
          </Materialize>
        </CustomScrollBar>
        <SLModalFooter cancelButton={false} submitButton={false}>
          <ColoredButton type="button" blueLayout small onClick={() => setAtividades(data)}>
            Mudar Quant. de Avaliações
          </ColoredButton>
          <ColoredButton type="button" redLayout small onClick={() => setADDManualmente(data)}>
            Adicionar Manualmente
          </ColoredButton>
        </SLModalFooter>
      </SLForm>
    </SistemaFixedModal>
  );
}

export default MediaCalcular;
