import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { Container, SubTitle } from "./styles";
import { Materialize } from "~/styles/materializeGrid";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import SistemaTable from "~/componentes/StyledComponents/Tables/SistemaTable";
import { useState } from "react";
import IconButton from "~/componentes/CustomForm/Button/Styles/IconButton";
import { SLForm, SLModalFooter, SLShowModal } from "~/pages/Sistema/componentes/SistemaList";
import ADDNotaAlunoTurmaModal from "../ADDNotaAlunoTurmaModal";
import ADDNotaAlunoTurmaModalEdit from "../ADDNotaAlunoTurmaModalEdit";
import api from "~/services/api";
import MediaCalcular from "../MediaCalcular";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import { useEffect } from "react";
import sistemaListStore from "~/pages/Sistema/componentes/SistemaList/ThisMainComponent/thisStore";

function ShowContent({ mountedData, bimestre, setMountedData}) {
  const [editAlunoTurmaModal, setEditAlunoTurmaModal] = useState();
  const [editAlunoTurmaModalEdit, setEditAlunoTurmaModalEdit] = useState();
  const [mediaCalcular, setMediaCalcular] = useState();

  
  const { state, dispatch } = React.useContext(sistemaListStore);

  useEffect(() => {
    (async () => {
      let res = await api.get("professor/turma/alunos", { params: { cod: mountedData.cod } });
      setMountedData(res.data)
    })()
  }, [editAlunoTurmaModal, editAlunoTurmaModalEdit, mediaCalcular])
console.log(mountedData);

  async function handleSubmit (dataPut, options){
  
    let response = await api.get(
      "professor/turma/alunos/medias",
      {params: {cod: mountedData.cod}}
    );

    console.log(response);
  }
  function openMediaCalcular(dataPut, options){
    setMediaCalcular(mountedData)
  }

  let alunosNotaEstado = mountedData.alunoDisciplinaTurma.map((aluno)=>{
    let alunosNota = aluno.notas.filter((el)=> {
      if (el.periodo?.periodoCod == parseInt(bimestre)) {
        return el.periodo.periodoCod
      }
    });

    if (alunosNota.length < mountedData.quantidadeAvaliacoes) {
      return 'Nota incompleta'
    } else {
      return "Nota completa";
    }
  })
  console.log(mountedData)
  let periodoAtual = mountedData.turma.periodo.find((periodo, i) => periodo.cod == bimestre)
  console.log(periodoAtual)

  return (
    <div>
    {editAlunoTurmaModal && (<ADDNotaAlunoTurmaModal
      data={editAlunoTurmaModal}
      outClick={() => setEditAlunoTurmaModal()}
    />)}
    {editAlunoTurmaModalEdit && (<ADDNotaAlunoTurmaModalEdit
      data={editAlunoTurmaModalEdit}
      outClick={() => setEditAlunoTurmaModalEdit()}
    />)}
    {mediaCalcular && (<MediaCalcular
      data={mediaCalcular}
      outClick={() => setMediaCalcular()}
    />)}
    <SLForm>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          <div className="row">
            
            <Label
              title="NOME:"
              text={mountedData?.turma?.nome || ""}
              className="col s12 m12 l3"
            />
            <Label
              title="SÉRIE:"
              text={mountedData?.turma?.serie?.nome || ""}
              className="col s12 m12 l3"
            />
            <Label
              title="PERÍODO LETIVO:"
              text={mountedData?.turma?.periodoLetivo?.nome || ""}
              className="col s12 m12 l3"
            />
            <Label
              title="DISCIPLINA:"
              text={mountedData?.disciplinaFilial?.nome || ""}
              className="col s12 m12 l2"
            />
            <Label
              title="MÉDIA:"
              text={periodoAtual?.mediaPeriodo || ""}
              className="col s12 m12 l1"
            />
            <div className="col s12 m12 l12">
              <SubTitle>Alunos</SubTitle>
              <SistemaTable>
                <thead>
                  <tr>
                    <th>Matricula</th>
                    <th>Nome</th>
                    <th>Email</th>
                    <th>Telefone</th>
                    <th>Estado</th>
                    <th> - </th>
                  </tr>
                </thead>
                <tbody>
                  {mountedData?.alunoDisciplinaTurma?.map((t, i) => (
                    <tr key={t?.alunoTurma?.aluno?.cod || i}>
                      <td>{t?.alunoTurma?.aluno?.matricula}</td>
                      <td>{t?.alunoTurma?.aluno?.usuario?.nome}</td>
                      <td>{t?.alunoTurma?.aluno?.usuario?.email}</td>
                      <td>
                        {t?.alunoTurma?.aluno?.usuario?.telefone1
                          ? t?.alunoTurma?.aluno?.usuario?.telefone1
                          : ""}
                      </td>
                      <td>
                        <strong>{alunosNotaEstado[i]}</strong>
                      </td>
                      <td>
                        <IconButton
                          onClick={() => setEditAlunoTurmaModalEdit({t, turma: mountedData?.turma,  tudo: mountedData,bimestre})}
                          materialIcon="create"
                        />
                        <IconButton
                          onClick={() => setEditAlunoTurmaModal({t, turma: mountedData?.turma, tudo: mountedData, bimestre})}
                          materialIcon="visibility"
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </SistemaTable>
            </div>
          </div>
        </Materialize>
      </CustomScrollBar>
      <SLModalFooter cancelButton={false} submitButton={false}>
          <ColoredButton type="button" blueLayout small onClick={() => openMediaCalcular()}>
              Situação e média
          </ColoredButton>
      </SLModalFooter>
    </SLForm>
    </div>
  );
}

export default ShowContent;
