import styled from "styled-components";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import CheckWrapper from "~/componentes/CustomForm/CheckWrapper";

export const SistemaSelectCustom = styled(SistemaSelect)`
  margin-bottom: 0.75em;
`;
export const Container = styled.div`
  margin: 2.125em;
`;
export const FlexDiv = styled.div`
  display: flex;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  @media (max-width: 800px) {
    flex-direction: column;
  }
  h4 {
    color: #fff;
    font-size: 1.3em;
    font-weight: 600;
  }
  input {
    width: 22em;
    border: none;
    background: #ffffff;
    border-radius: 0.375em;
    padding-left: 1.2em;
    height: 2.6em;

    margin-top: 0.2em;
  }
  button.green {
    background: #4ea654;
    box-shadow: 0 0.313em 0 #3d7d42;
  }

  button {
    display: flex;

    align-items: center;

    justify-content: center;
    width: 3.325em;
    height: 2.4em;
    margin-left: 1em;
    border: none;
    outline: none;
    padding: 0 0.5em;
    background-color: #ffda44;
    color: #fff;
    box-shadow: 0 0.3em 0 #cba81c;
    border-radius: 0.2em;
    margin-top: 0.188em;
  }
  button:active {
    transform: translateY(0.3em);

    box-shadow: none;
  }
`;

export const CheckWrapperCustom = styled(CheckWrapper)`
  display: flex;
  justify-content: space-between;
  margin: 0 1em 0 0;
  padding-bottom: 1em;
  border-bottom: solid #f3f3f3 0.125em;
`;

export const Schools = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 98%;
  background: #ffffff;
  border-radius: 0.625em;
  margin: 0.75em auto;
`;
