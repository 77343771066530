import React, { useState, useEffect } from "react";
import StyledCustomListFindUser from "./StyledCustomListFindUser";
import StyledCustomListCreateData from "./StyledCustomListCreateData";

import CustomScrollBar from "~/componentes/CustomScrollBar";

/**
 * Componente já estilizado que irá executar uma busca de usuário e criação caso esse não exista no sistema.
 * A customização desse componente é unicamente na última etapada para criar o conteúdo desejado após a existência do usuário.
 * @param {Object} props
 * @param {string} props.title Informe o título que será utilizado na tela de cadastro que não seja a de usuário.
 * @param {Function} props.getUserData Recebe uma função que envia como argumento o dado do usuário caso encontrado.
 * @param {Function} props.onSubmit Requisição que será acionada ao confirmar o cadastro que não seja o de usuário.
 * @param {Function} props.successMessage Altera a mensagem de sucesso.
 * @param {Object} props.scrollOptions Padrão { autoHeightMax = "24em", fixScrollMargin = true}. Se false, remove o scroll.
 * @param {string} props.scrollOptions.autoHeightMax
 * @param {Boolean} props.scrollOptions.autoHeight
 * @param {string} props.scrollOptions.autoHeightMin
 * @param {Number} props.scrollOptions.autoHideDuration
 * @param {Number} props.scrollOptions.autoHideTimeout
 * @param {Boolean} props.scrollOptions.autoHide
 * @param {string} props.scrollOptions.fixScrollMargin Se true, adiciona uma div externa e interna no scroll
 *  ajustando 0.8em de margin, se for uma string, altera o tamanho da margin.
 * @param {Object} props.scrollOptions.verticalScroll
 * @param {string} props.scrollOptions.verticalScroll.width
 * @param {string} props.scrollOptions.verticalScroll.hoverWidth
 * @param {string} props.scrollOptions.verticalScroll.color
 * @param {string} props.scrollOptions.verticalScroll.hoverColor
 * @param {string} props.scrollOptions.verticalScroll.borderRadius
 */
function StyledCustomListFindBeforeCreate({
  title,
  children,
  getUserData,
  onSubmit,
  successMessage,
  scrollOptions,
  ...rest
}) {
  const [data, setData] = useState();
  const [confirmData, setConfirmData] = useState(false);
  const { autoHeightMax = "24em", fixScrollMargin = true, ...scrollRest } =
    scrollOptions || {};

  useEffect(() => {
    return () => {
      setData();
      setConfirmData(false);
    };
  }, []);

  const createData = data && confirmData;

  return (
    <>
      {!createData && (
        <StyledCustomListFindUser
          data={data}
          setData={setData}
          setConfirmData={setConfirmData}
        />
      )}
      {createData && (
        <StyledCustomListCreateData
          {...rest}
          onSubmit={onSubmit}
          getUserData={getUserData}
          data={data}
          title={title}
          successMessage={successMessage}
          setConfirmData={setConfirmData}
          setData={setData}
        >
          {scrollOptions !== false ? (
            <CustomScrollBar
              autoHeightMax={autoHeightMax}
              fixScrollMargin={fixScrollMargin}
              {...scrollRest}
            >
              {children}
            </CustomScrollBar>
          ) : (
            children
          )}
        </StyledCustomListCreateData>
      )}
    </>
  );
}

export default StyledCustomListFindBeforeCreate;
