import React from "react";

import { Container } from "./styles";
import Nuvem from "~/componentes/Footer/Nuvem";
import UserDevice from "~/componentes/UserDevice";
import CustomScrollBar from "~/componentes/CustomScrollBar";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";

export default function Principal({ footer: Footer = Nuvem, children }) {
  const userDevice = UserDevice();
  const pathname = children?.props?.location?.pathname;

  return (
    <Container>
      <CustomScrollBar autoHeightMax="100vh">
        <div style={{ overflow: "hidden" }}>
          {userDevice.desktop ? <DesktopMenu /> : <MobileMenu />}
          <main>{children}</main>
          {pathname !== "/entrar" && <Footer />}
        </div>
      </CustomScrollBar>
    </Container>
  );
}
