import React, { useState } from "react";

import api from "~/services/api";
import CreateContent from "./CreateContent";
import ShowContent from "./ShowContent";
import { SLCreateModal, SLShowModal } from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import CustomBodyContent from "./CustomBodyContent";

function Comunicados() {
  const [mountedData, setMountedData] = useState();
  const [bodyData, setBodyData] = useState([]);
  const [disciplinasFilial, setDisciplinasFilial] = useState();

  React.useEffect(() => {
    api
      .get("professor/comunicados/disciplinasFilial")
      .then((r) => setDisciplinasFilial(r?.data || []));
  }, []);

  return (
    <Container
      requests={{
        findAll: () => api.get("professor/comunicados/comunicados"),
        deleteOne: (thisData) =>
          api.delete("professor/comunicados/comunicado", {
            data: { cod: thisData.cod },
          }),
      }}
      inputs={[{ filter: "titulo", label: "Título" }]}
      setter={setBodyData}
      defaultStyle
      pageSize={6}
    >
      <CustomBodyContent bodyData={bodyData} />

      <SLShowModal title="Comunicado" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLCreateModal title="Novo Comunicado">
        <CreateContent {...{ disciplinasFilial }} />
      </SLCreateModal>
    </Container>
  );
}

export default Comunicados;
