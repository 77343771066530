import React, { useState, useEffect } from "react";

import {
  Container,
  SpanCustom,
  FlexH3,
  ChamadoCard,
  AbaField,
  AbaContent,
} from "./styles";
import Pagination from "~/componentes/Pagination";
function CustomBodyContent({ customBodyData }) {
  const { showModal } = customBodyData || {};
  const data = [
    {
      cod: "0001",
      tipo: 1,
      titulo: "Chamado numero 1",
      desc:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
    {
      cod: "0002",
      tipo: 2,
      titulo: "Chamado teste",
      desc: "Teste 1 dfsdfsffasfsadf",
    },
    { cod: "0003", tipo: 1, titulo: "Teste 3", desc: "112313213" },
    { cod: "0004", tipo: 2, titulo: "Teste 4", desc: "ddasdasdads" },
  ];

  const [filteredData, setFilteredData] = useState([]);
  const [pageData, setPageData] = useState();

  useEffect(() => {
    setFilteredData(data.filter((e) => e.tipo === 1));
  }, [data]);
  const tipo = filteredData.map((e) => e?.tipo)[0];

  return (
    <Container>
      <AbaField>
        <button
          onClick={(e) => {
            setFilteredData(data.filter((e) => e.tipo === 1));
          }}
          type="button"
          className={tipo === 1 && "ativo"}
        >
          <span className="material-icons">error_outline</span>
          <h6>Abertos</h6>
        </button>
        <button
          onClick={(e) => {
            setFilteredData(data.filter((e) => e.tipo === 2));
          }}
          type="button"
          className={tipo === 2 && "ativo"}
        >
          <span className="material-icons">block</span>
          <h6>Encerrados</h6>
        </button>
      </AbaField>
      <AbaContent>
        {pageData?.map((thisData, i) => (
          <ChamadoCard key={i} onClick={() => showModal(thisData)}>
            <FlexH3>
              <h3>#{thisData?.cod}</h3>
              <SpanCustom statusChamado={thisData?.tipo === 1}>
                {thisData?.tipo === 1 ? "Aberto" : "Encerrado"}
              </SpanCustom>
            </FlexH3>
            <h2>{thisData?.titulo}</h2>
            <p>{thisData?.desc}</p>
          </ChamadoCard>
        ))}
      </AbaContent>

      <Pagination
        data={filteredData}
        getInfo={({ pageData }) => setPageData(pageData)}
        size={1}
      />
    </Container>
  );
}

export default CustomBodyContent;
