import React from "react";
import customListStore, { customListToggleModal } from "../../../customListStore";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import CustomListModal from "../../CustomListModal";
import CustomListDefaultModalBody from "../../Styles/CustomListDefaultModalBody";
import { Form } from "~/componentes/CustomForm";
import { useSelector } from "react-redux";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import { CLModalButtons } from "../../../CustomListStyledComponents";

function CustomListFilter({ filters, setParentState, filtersValue, ...rest }) {
  const { state, dispatch } = React.useContext(customListStore);
  const {
    confirmacaoModal,
    errorModal: checkErrorModal,
    successModal: checkSuccessModal,
  } = useSelector((state) => state) || {};

  function handleClick() {
    if (!state.modal) {
      dispatch(
        customListToggleModal(
          <CustomListModal headerTitle={"Filtrar"}>
            <CustomListDefaultModalBody style={{ width: "30em" }} {...rest}>
              <Form
                className="CustomTable_TableModal_children"
                onSubmit={(data) => {
                  setParentState(data);
                  if (
                    !confirmacaoModal?.ativo &&
                    !checkErrorModal?.ativo &&
                    !checkSuccessModal?.ativo
                  ) {
                    dispatch(customListToggleModal(false));
                  }
                }}
              >
                {filters.map((e, i) => {
                  if (!e.list || (e.list && !e.list.list)) {
                    throw new Error(
                      "É necessário informar o atributo list no filtro da tabela"
                    );
                  } else {
                    const thisResult = (
                      <SistemaSelect
                        className="CustomTable_Filter_Modal_select"
                        defaultValue={filtersValue && e.name && filtersValue[e.name]}
                        name={e.name}
                        list={e.list}
                      >
                        <option
                          className="CustomTable_Filter_Modal_select_firstOption"
                          value=""
                        >
                          Selecione
                        </option>
                      </SistemaSelect>
                    );

                    if (e.list.outLabel) {
                      return (
                        <label className="CustomTable_Modal_label" key={i}>
                          <p>{e.list.outLabel}</p>
                          {thisResult}
                        </label>
                      );
                    } else {
                      return thisResult;
                    }
                  }
                })}
                <CLModalButtons />
              </Form>
            </CustomListDefaultModalBody>
          </CustomListModal>
        )
      );
    }
  }

  return (
    setParentState &&
    Array.isArray(filters) &&
    filters.length > 0 && (
      <div {...rest}>
        <ColoredButton
          small
          yellowLayout
          className="CustomTable_TableFiltro_buttons_div_button"
          onClick={handleClick}
        >
          <i className="material-icons CustomTable_TableFiltro_buttons_div_button_icon">
            filter_alt
          </i>
          FILTRO
        </ColoredButton>
      </div>
    )
  );
}

export default React.memo(CustomListFilter);
