import React, { useState } from "react";
import api from "~/services/api";
import { Container } from "./styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import handleFormData from "~/utils/handleFormData";
import { SistemaInput } from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";

import CustomScrollBar from "~/componentes/CustomScrollBar";
import { Materialize } from "~/styles/materializeGrid";
import { SLModalFooter } from "~/pages/Sistema/componentes/SistemaList";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import PreviewFileInput from "~/componentes/CustomForm/FileInput/styles/PreviewFileInput";
import { SistemaTextArea } from "~/componentes/CustomForm/TextArea/styles";
import SistemaTopico from "~/componentes/StyledComponents/Textos/SistemaTopico";
import ItensDiv from "./ItensDiv";
import dayjs from "dayjs";
import { useEffect } from "react";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import sistemaListStore, { sistemaListShowModal } from "~/pages/Sistema/componentes/SistemaList/ThisMainComponent/thisStore";

function EditContent({ mountedData, turmas, tipos, DTM, setData: setDataParent, onSelect, getMountedData }) {
  const [data, setData] = useState([]);
  const { state, dispatch } = React.useContext(sistemaListStore);
  useEffect(() => {
    let cod = getMountedData();
    let dtm = DTM.find(d => d.cod == cod.turmaId);
    if(dtm){
      cod.turmaId = dtm.turma.cod;
    }
    console.log(cod);
    setData(cod);

    /* api.get("/professor/calendario/one", {data: {cod}}).then((response) => {
    }); */
  }, [])

  function handleSubmit(dataForm, options) {
    const { closeReload } = options || {};
    console.log(dataForm);
    let dtm = DTM.find(d => d.turma.cod == dataForm.turmaId);
    if(dtm) {
      dataForm.turmaId = dtm.cod;
    }
    if(!dataForm.cod){
      dataForm.cod = data.cod
    }
    return ShowConfirmacao(
      async () => {
        let response;
        
        response = await api.put("professor/calendario", dataForm);

        if (response?.status === 200) {
          closeReload && closeReload();
          api.get("/professor/calendario").then((response) => {
            setDataParent(response.data);
            let now = dayjs();
            onSelect(now, response.data, true);
          });
        }

        return response;
      },
      {
        successModal: `Lembrete atualizado com sucesso.`,
        texto: `Deseja atualizar esse Lembrete?`,
        errorModal: {
          defaultMessage: `Não foi possível atualizar esse lembrete, por favor, tente novamente.`,
        },
      }
    );
  }

  function handleDelete(data) {
    console.log(data);
    return ShowConfirmacao(
      async () => {
        let response;
        
        response = await api.delete("professor/calendario", {data});

        if (response?.status === 200) {
          api.get("/professor/calendario").then((response) => {
            setDataParent(response.data);
            let now = dayjs();
            onSelect(now, response.data, true);
            dispatch(sistemaListShowModal(false))
          });
        }

        return response;
      },
      {
        successModal: `Lembrete deletado com sucesso.`,
        texto: `Deseja deletar esse Lembrete?`,
        errorModal: {
          defaultMessage: `Não foi possível deletar esse lembrete, por favor, tente novamente.`,
        },
      }
    );
  }

  return (
    <Container onSubmit={handleSubmit}>
      <CustomScrollBar fixScrollMargin autoHeightMax="75vh">
        <Materialize>
          {data && (
            <div className="row">
              <SistemaTopico>Dados Lembrete</SistemaTopico>
              <Label title="TÍTULO:" className="col s12 m12 l6">
                <SistemaInput name="titulo" defaultValue={data?.titulo} required />
              </Label>
              <Label title="TIPO:" className="col s12 m12 l3">
                <SistemaSelect
                  name="tipo"
                  firstOption
                  list={{ list: tipos, value: "cod", label: "cod" }}
                  required
                  defaultValue={data?.tipo} 
                />
              </Label>
              <Label title="TURMA:" className="col s12 m12 l3">
                <SistemaSelect
                  name="turmaId"
                  firstOption
                  list={{ list: turmas, value: "cod", label: "nome" }}
                  required
                  defaultValue={data?.turmaId} 
                />
              </Label>
              <Label title="DATA:" className="col s12 m12 l3">
                <SistemaInput name="data" type="date" defaultValue={data?.data} required />
              </Label>
              <Label title="DESCRIÇÃO:" className="col s12 m12 l8">
                <SistemaTextArea name="descricao" defaultValue={data?.descricao} required />
              </Label>
            </div>
          )}
        </Materialize>
      </CustomScrollBar>
      <SLModalFooter>
        <ColoredButton type="button" redLayout small onClick={() => handleDelete(data)}>
          Excluir
        </ColoredButton>
      </SLModalFooter>
    </Container>
  );
}

export default EditContent;
