import React, { useRef, useEffect, useState } from "react";

import customListStore, { customListToggleModal } from "../../customListStore";
import UserDevice from "~/componentes/UserDevice";
import { useLayoutEffect } from "react";
import { CLModalHeader } from "../../CustomListStyledComponents";
import CustomListReloadList from "../../utils/CustomListReloadList";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";

function CustomListModal({ headerTitle, bodyComponent, children }) {
  const { state, dispatch } = React.useContext(customListStore);
  const { childrens, modalChildrenProps, reloadTable, sortOnEdit, requests } =
    state || {};
  const { findAll } = requests || {};
  const { tableCreateForm, tableEditContent, tableShowContent } =
    childrens || {};
  const [renderChildren, setRenderChildren] = useState();
  const [refLeft, setRefLeft] = useState();
  const [refTop, setRefTop] = useState();
  const [modalMaxWidth, setModalMaxWidth] = useState();
  const { title, closeCondition = true } = modalChildrenProps || {};
  const modalRef = useRef();
  const backgroundRef = useRef();
  const { screenWidth, screenHeight } = UserDevice();

  const backgroundRefCurrent = backgroundRef?.current;
  const modalRefCurrent = modalRef?.current;
  const checkModalRef = modalRefCurrent?.getBoundingClientRect();
  const modalHeight = checkModalRef?.height;

  useEffect(() => {
    reloadTable && CustomListReloadList({ findAll, sortOnEdit, dispatch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    const checkBackgrounRef = backgroundRefCurrent?.getBoundingClientRect();
    if (checkBackgrounRef && modalHeight) {
      const {
        width: backgroundWidth,
        top: backgroundTop,
        left: backgroundLeft,
      } = checkBackgrounRef;

      const windowTop = screenHeight / 2;
      const contentTop = modalHeight / 2;

      const newLeft = backgroundWidth / 2 + backgroundLeft;
      const newTop = windowTop - contentTop;

      backgroundWidth !== modalMaxWidth && setModalMaxWidth(backgroundWidth);
      refLeft !== newLeft && setRefLeft(newLeft);
      refTop !== newTop &&
        setRefTop(newTop > backgroundTop ? newTop : backgroundTop);
    }
  }, [
    screenHeight,
    backgroundRefCurrent,
    modalRefCurrent,
    refLeft,
    refTop,
    modalHeight,
    screenWidth,
    modalMaxWidth,
  ]);

  useEffect(() => {
    const { create, edit, show } = bodyComponent || {};
    let thisChildren =
      (create && tableCreateForm) ||
      (edit && tableEditContent) ||
      (show && tableShowContent);

    if (!thisChildren) {
      thisChildren = children;
    }

    thisChildren && setRenderChildren(thisChildren);
  }, [
    bodyComponent,
    children,
    dispatch,
    tableCreateForm,
    tableEditContent,
    tableShowContent,
  ]);

  return (
    <SistemaFixedModal
      nonClickableBackground
      outClick={() => closeCondition && dispatch(customListToggleModal(false))}
    >
      {renderChildren && (
        <>
          {(title || headerTitle) && (
            <CLModalHeader>{title || headerTitle}</CLModalHeader>
          )}
          {renderChildren}
        </>
      )}
    </SistemaFixedModal>
  );
}

export default CustomListModal;
