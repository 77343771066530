import React from "react";
import customListStore, { customListToggleModal } from "../../../customListStore";
import TableButtonStyle from "../styles";
import CustomListLoadInitialData from "../../../utils/CustomListLoadInitialData";
import CustomListModal from "../../CustomListModal";

function CustomListEditButton({ data, icon, className, onClick, ...rest }) {
  const { state, dispatch } = React.useContext(customListStore);

  return (
    <TableButtonStyle
      onClick={async () => {
        await CustomListLoadInitialData({ data, state, dispatch });
        dispatch(
          customListToggleModal(<CustomListModal bodyComponent={{ edit: true }} />)
        );
        onClick && onClick();
      }}
      className={`CustomTable_button_edit ${className}`}
      {...rest}
    >
      <i className="material-icons">{icon || "create"}</i>
    </TableButtonStyle>
  );
}

export default CustomListEditButton;
