import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  max-width: 100%;
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "char1 ranking";
  grid-gap: 1em;
  margin-top: 1em;

  @media only screen and (max-width: 993px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "char1" "ranking";
    grid-gap: 1em;

    .text-list {
      background: white;
      flex: 1;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;
