import styled from "styled-components";

export const StyledTable = styled.table`
  width: 100%;
  margin: auto;
  max-width: 100%;
  border-collapse: collapse;
  table-layout: auto;
  overflow: auto;

  th {
    background: #f6f6f6;
    color: #293f57;
    height: 2.5em;
    font-weight: 550;
    text-transform: uppercase;
    font-size: 0.95em;
  }

  td {
    border-bottom: solid 0.1em #f3f3f3;
    min-height: 2.6em;
    font-weight: 600;
    font-size: 0.95em;
    color: #7b898c;

    :empty {
      height: 3.1em;
    }
  }

  td,
  th {
    white-space: nowrap;
    /* overflow: hidden;
    text-overflow: ellipsis; */
    line-break: none;
    padding: 0.56em;
    padding-right: 3em;
    text-align: left;
  }

  .CustomTable_th_options {
    width: 8em;
    text-align: center;
    padding-right: 0;
  }

  .CustomTable_td_options {
    text-align: center;
    padding-right: 0;

    .CustomTable_button_show,
    .CustomTable_button_edit,
    .CustomTable_button_delete {
      background: none;
      border: none;
      margin: 0 0.3em;

      i {
        position: relative;
        color: #7b898c;
        font-size: 1.55em;
      }
      i::after {
        transition: 0.3s ease;
        content: "";
        position: absolute;
        background: rgba(28, 169, 208, 0.22);
        width: 1.5em;
        height: 1.5em;
        left: -0.25em;
        top: -0.25em;
        border-radius: 50%;
        transform: scale(0);
        transform-origin: center center;
      }
      i:hover::after {
        transform: scale(1);
      }
    }
  }
`;
