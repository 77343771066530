import React from "react";
import { Container, QuestaoCard } from "./styles";
import TextContent from "~/componentes/TextContent";
import {
  SLEditButton,
  SLDeleteButton,
  SLRestoreButton,
  SLShowButton,
  SLAprovarButton,
} from "~/pages/Sistema/componentes/SistemaList";

function CustomBodyContent({ pageData }) {
  return (
    <Container>
      {pageData?.map((thisData, i) => {
        return (
          <QuestaoCard nivel={thisData?.nivel?.nome} key={thisData?.cod || i}>
            <div className="Questoes_head">
              <p className="Questoes_Card_cod">
                #{thisData?.cod} <span>{thisData?.disciplina?.nome}</span>
              </p>
              <p className="Questoes_Card_subTittle">
                {thisData?.filial?.nomeFantasia ? thisData?.filial?.nomeFantasia : "Eduque-Me"}
              </p>
            </div>
            <p className="Questoes_Card_titulo">{thisData?.nome}</p>
            <TextContent relativeHeight={false} className="Questoes_Card_enunciado" lines={4}>
              {thisData?.enunciado}
            </TextContent>
            <div className="Questoes_Card_footer">
              <p className="Questoes_Card_footer_dificuldade">{thisData?.nivel?.nome}</p>
              <div className="Questoes_Card_footer_buttons">
                <SLShowButton data={thisData} />
                {thisData?.etapa === 1 && thisData?.status === 1 && <SLEditButton data={thisData} />}
                {thisData?.ativo === 1 && thisData?.etapa === 0 && thisData?.status === 1 && (
                  <SLAprovarButton data={thisData} />
                )}
                {thisData?.ativo === 1 && thisData?.etapa === 0 && thisData?.status === 1 && (
                  <SLDeleteButton data={thisData} />
                )}
                {thisData?.status === 0 && <SLRestoreButton data={thisData} />}
              </div>
            </div>
          </QuestaoCard>
        );
      })}
    </Container>
  );
}

export default CustomBodyContent;
