import React from "react";
import Planeta1 from "~/assets/planetas/planeta_vermelho.png";
import Planeta2 from "~/assets/planetas/planetas_aneis.png";
import Planeta3 from "~/assets/planetas/planeta_fundao.png";
import Planeta4 from "~/assets/planetas/planeta_tithanos.png";
import Planeta5 from "~/assets/planetas/planeta_fusion.png";

import { Container } from "./styles";

function PlanetasBackground() {
  return (
    <Container>
      <img src={Planeta1} className="planeta1" alt="" />
      <img src={Planeta2} className="planeta2" alt="" />
      <img src={Planeta3} className="planeta3" alt="" />
      <img src={Planeta4} className="planeta4" alt="" />
      <img src={Planeta5} className="planeta5" alt="" />
    </Container>
  );
}

export default PlanetasBackground;
