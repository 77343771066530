import React, { useEffect } from "react";
import { Container } from "./styles";
import { useState } from "react";
import ModuloButton from "./ModuloButton";
import MenuButton from "./MenuButton";

export default function MenuLista({
  tipo,
  modulos,
  setComponentPath,
  currentModulo,
  currentMenu,
  showLateralMenu,
  setShowLateralMenu,
}) {
  const [showMenu, setShowMenu] = useState([]);

  useEffect(() => {
    !showLateralMenu && setShowMenu([]);
  }, [showLateralMenu]);

  return (
    <Container>
      {modulos &&
        modulos.map(
          (modulo, mi) =>
            modulo?.menus && (
              <div key={modulo.cod || mi}>
                <ModuloButton
                  {...{
                    modulo,
                    tipo,
                    selected: currentModulo?.cod === modulo?.cod,
                    setComponentPath,
                    showMenu,
                    setShowMenu,
                    showLateralMenu,
                    setShowLateralMenu,
                  }}
                />

                {modulo.menus &&
                  showMenu.includes(modulo.cod) &&
                  modulo.menus.map((menu) => (
                    <MenuButton
                      key={menu.cod}
                      {...{
                        tipo,
                        selected: currentMenu?.cod === menu?.cod,
                        setComponentPath,
                        menu,
                        modulo,
                      }}
                    />
                  ))}
              </div>
            )
        )}
    </Container>
  );
}
