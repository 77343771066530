import React, { useState } from "react";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";
import SistemaTable from "~/componentes/StyledComponents/Tables/SistemaTable";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import Label from "~/componentes/CustomForm/Label";
import IconButton from "~/componentes/CustomForm/Button/Styles/IconButton";
import Pagination from "~/componentes/Pagination";
import { useEffect } from "react";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import api from "~/services/api";

import { ThisAddDisciplinaTurma } from "./styles";
import ShowConfirmacao from "~/utils/ShowConfirmacao";
import ShowError from "~/utils/ShowError";

function EditAlunoTurmaModal({ data, outClick, reloadMatriculas }) {
  const [pageData, setPageData] = useState([]);
  const [disciplinasTurma, setDisciplinasTurma] = useState([]);
  const [dtMatriculada, setDtMatriculada] = useState([]);
  const [dtAvailable, setDtAvailable] = useState([]);
  const [selectedDisciplina, setSelectedDisciplina] = useState();

  useEffect(() => {
    if (data?.cod) {
      async function fetchData() {
        await api
          .get("filial/turma", { params: { cod: data?.cod } })
          .then((r) => setDisciplinasTurma(r?.data?.disciplinasTurma || []));
      }

      fetchData();
      if (Array.isArray(data?.disciplinas)) {
        setDtMatriculada((prev) => {
          const newData =
            data.disciplinas.filter(
              (e) => !prev?.find((p) => p?.cod === e?.cod)
            ) || [];

          return [...prev, ...newData];
        });
      }
    }
  }, [data]);

  useEffect(() => {
    const newData =
      disciplinasTurma.filter(
        (e) => !dtMatriculada?.find((p) => p?.cod === e?.cod)
      ) || [];

    setDtAvailable(newData);
  }, [disciplinasTurma, dtMatriculada]);

  function handleDelete(data) {
    setDtMatriculada((prev) => {
      const newPrev = [...prev];
      const checkIndex = newPrev.findIndex((e) => e?.cod === data?.cod);

      if (checkIndex >= 0) {
        newPrev.splice(checkIndex, 1);
      }
      return newPrev;
    });
  }

  function handleAddDisciplina() {
    if (selectedDisciplina?.cod) {
      const checkDisciplina = dtMatriculada?.find(
        (e) => e?.cod === selectedDisciplina.cod
      );
      if (!checkDisciplina) {
        setDtMatriculada((prev) => [...prev, selectedDisciplina]);
      }
    }
  }

  async function handleSubmit(closeModal) {
    const submitData = {
      alunoTurmaCod: data?.alunoTurma?.cod,
      disciplinasTurma: dtMatriculada?.map((e) => e?.cod),
    };

    if (submitData?.alunoTurmaCod && submitData?.disciplinasTurma?.length > 0) {
      ShowConfirmacao(
        async () => {
          const response = await api.put(
            "filial/aluno/disciplinasTurma",
            submitData
          );

          if (response?.status === 200 && reloadMatriculas) {
            await reloadMatriculas();
            closeModal && closeModal();
          }
        },
        {
          errorModal:
            "Não foi possível atualizar a matrícula dessa turma, por favor, tente novamente mais tarde.",
          successModal: false,
          message: "Tem certeza que deseja atualizar a matrícula dessa turma?",
        }
      );
    } else {
      ShowError("É necessário ter pelo menos uma disciplina");
    }
  }

  return (
    <SistemaFixedModal
      title="Atualizar Turma"
      footerButtons={{
        submitButton: { type: "button", onClick: handleSubmit },
      }}
      style={{ width: "50em", maxWidth: "100%" }}
      {...{ outClick }}
    >
      <Label title="Disciplinas" />

      <ThisAddDisciplinaTurma>
        <SistemaSelect
          firstOption
          list={{
            list: dtAvailable,
            value: "cod",
            label: "disciplinaFilial.nome",
          }}
          setList={setSelectedDisciplina}
        />
        <ColoredButton
          small
          blueLayout
          type="button"
          onClick={handleAddDisciplina}
        >
          ADICIONAR
        </ColoredButton>
      </ThisAddDisciplinaTurma>
      <SistemaTable className="TurmasList_StyledTable">
        <thead>
          <tr>
            <th>Disciplina</th>
            <th>Professor</th>
            <th>-</th>
          </tr>
        </thead>
        <tbody>
          {pageData?.map((disc, i) => (
            <tr key={disc?.cod || i}>
              <td>{disc?.disciplinaFilial?.nome}</td>
              <td>{disc?.professor?.usuario?.nome}</td>
              <td>
                <IconButton
                  onClick={() => handleDelete(disc)}
                  materialIcon={{ delete: true }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </SistemaTable>
      <Pagination
        size={4}
        data={dtMatriculada}
        getInfo={({ pageData }) => setPageData(pageData)}
      />
    </SistemaFixedModal>
  );
}

export default EditAlunoTurmaModal;

// function ThisModal({ setShowModal, setTab, setPlNovos, plDisponiveis }) {
//   function handleSubmit(closeModal) {
//     if (turma) {
//       if (
//         !Array.isArray(turma?.disciplinasTurma) ||
//         !(turma?.disciplinasTurma?.length > 0)
//       ) {
//         ShowError("É necessário adicionar pelo menos uma disciplina.");
//       } else {
//         ShowConfirmacao(
//           () => {
//             setPlNovos((prev) => {
//               let newPrev = Array.isArray(prev) ? [...prev] : [];
//               if (periodoLetivo?.cod && serie?.cod && turma?.cod) {
//                 const thisPl = newPrev?.find?.((e, i) => e.cod === periodoLetivo?.cod);

//                 if (thisPl) {
//                   const thisTurma = thisPl?.turmas?.find?.((e) => e.cod === turma?.cod);

//                   if (!thisTurma) {
//                     if (Array.isArray(thisPl?.turmas)) {
//                       thisPl.turmas.push(turma);
//                     } else {
//                       thisPl.turmas = [turma];
//                     }
//                   }
//                 } else {
//                   newPrev = [...newPrev, { ...periodoLetivo, turmas: [turma] }];
//                 }
//                 return newPrev;
//               }
//             });
//             setTab(1);
//             closeModal && closeModal();
//           },
//           { message: "Tem certeza que deseja adicionar essa turma?" }
//         );
//       }
//     } else {
//       closeModal && closeModal();
//     }
//   }

// }
