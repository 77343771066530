import React, { useState } from "react";

import api from "~/services/api";
import EditContent from "./EditContent";
import ShowContent from "./ShowContent";
import CreateContent from "./CreateContent";
import SistemaList, {
  SLShowModal,
  SLEditModal,
  SLCreateModal,
} from "~/pages/Sistema/componentes/SistemaList";
import TableComponent from "./TableComponent";

export default function Professores() {
  const [tabFilter, setTabFilter] = useState([]);
  const [mountedData, setMountedData] = useState();

  return (
    <SistemaList
      requests={{
        findAll: () =>
          api.get("filial/professores", { params: { paranoid: false } }),
        findOne: (funcionario) =>
          api.get("filial/professor", { params: { cod: funcionario.cod } }),
        deleteOne: (thisData) =>
          api.delete("filial/professor", { data: { cod: thisData.cod } }),
        restoreOne: (thisData) =>
          api.put("filial/professor/restaura", { cod: thisData.cod }),
      }}
      dataFilter={tabFilter}
    >
      <TableComponent {...{ setTabFilter }} />

      <SLShowModal title="Dados do(a) Professor(a)" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLEditModal title="Atualizar Professor(a)" setter={setMountedData}>
        <EditContent {...{ mountedData }} />
      </SLEditModal>

      <SLCreateModal title="Novo Professor">
        <CreateContent />
      </SLCreateModal>
    </SistemaList>
  );
}
