import React, { useState } from "react";

import api from "~/services/api";
import CreateContent from "./CreateContent";
import EditContent from "./EditContent";
import ShowContent from "./ShowContent";
import {
  SLCreateModal,
  SLShowModal,
  SLEditModal,
} from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import TableComponent from "./TableComponent";

function Alunos() {
  const [mountedData, setMountedData] = useState();
  const [tabFilter, setTabFilter] = useState();
  const [reloadTableData, setReloadTableData] = useState(() => () => {});

  return (
    <Container
      requests={{
        findAll: () => api.get("filial/alunos"),
        deleteOne: (thisData) =>
          api.delete("filial/aluno", { data: { cod: thisData.cod } }),
        findOne: (thisData) => api.get("filial/aluno", { params: { cod: thisData.cod } }),
      }}
      dataFilter={tabFilter}
      reloadSetter={setReloadTableData}
    >
      <TableComponent {...{ setTabFilter }} />

      <SLShowModal title="Aluno" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLEditModal title="Atualizar Aluno" setter={setMountedData}>
        <EditContent {...{ mountedData, reloadTableData }} />
      </SLEditModal>

      <SLCreateModal title="Novo Aluno">
        <CreateContent />
      </SLCreateModal>
    </Container>
  );
}

export default Alunos;
