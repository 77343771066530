/* eslint-disable eqeqeq */
import React from "react";
import { EduquemeSelect, SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import estados from "~/config/estados";

// import { Container } from './styles';

function Cidade({ dados, grupo, setDados, blueLayout, props }) {
  const ThisSelect = blueLayout ? EduquemeSelect : SistemaSelect;

  const estadoCod = React.useMemo(
    () => dados?.[grupo?.chave]?.estado?.cod || dados?.estado?.cod,
    [dados, grupo]
  );

  const cidades = React.useMemo(
    () => estados?.find((est) => est?.cod == estadoCod)?.cidades,
    [estadoCod]
  );

  function handleChangeValue(e) {
    if (grupo?.chave && setDados) {
      const thisValue = e.currentTarget.value;
      const thisCidade = cidades?.find((cid) => cid?.cod == thisValue);

      setDados((prev) => ({
        ...prev,
        [grupo.chave]: { ...prev?.[grupo.chave], cidade: thisCidade || "" },
      }));
    }
  }

  return (
    <ThisSelect
      firstOption
      {...props}
      list={{ list: cidades, value: "cod", label: "nome" }}
      onChange={handleChangeValue}
    />
  );
}

export default Cidade;
