import React, { useCallback } from "react";

import { Container } from "./styles";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  acceptConfirmacaoModalToggle,
  responseModalToggle,
  confirmacaoModalToggle,
} from "~/store/modules/confirmacaoModal/actions";
import { Button } from "~/componentes/CustomForm";
import loading from "~/assets/icones/loading2.png";
import { errorToggle } from "~/store/modules/errorModal/actions";
import { succesToggle } from "~/store/modules/successModal/actions";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";

export default function ConfirmacaoInterna(props) {
  const { confirmado, request, message, errorModal, successModal } = useSelector(
    (state) => state.confirmacaoModal
  );
  const dispatch = useDispatch();
  const toggleModal = useCallback(
    () => !confirmado && dispatch(confirmacaoModalToggle()),
    [confirmado, dispatch]
  );

  useEffect(() => {
    async function handleRequest() {
      try {
        const response = await request();

        if (successModal && response?.status === 200) {
          dispatch(succesToggle(successModal));
        }
        return dispatch(responseModalToggle(response));
      } catch (err) {
        dispatch(responseModalToggle(err?.response || err));
        if (errorModal) {
          let thisErrorMessage =
            typeof errorModal === "string" ? errorModal : err?.response || err;

          dispatch(errorToggle(thisErrorMessage, errorModal?.defaultMessage));
        }
      }
    }

    confirmado && request && handleRequest();
  }, [confirmado, dispatch, errorModal, request, successModal]);

  return (
    <Container checkRedux={false} outClick={toggleModal}>
      <div className="confirmacaoHeader">
        <p>Confirmação</p>
        <Button onClick={toggleModal} className="material-icons">
          close
        </Button>
      </div>
      <div className="confirmacaoBody">
        <i className="material-icons">error_outline</i>
        <p>{message || "Você realmente deseja fazer isso?"}</p>
      </div>
      <div className="confirmacaoBotoes">
        <ColoredButton small onClick={toggleModal} redLayout>
          CANCELAR
        </ColoredButton>
        <ColoredButton
          small
          onClick={() => dispatch(acceptConfirmacaoModalToggle())}
          greenLayout
        >
          {confirmado ? (
            <img className="loadingIcon" src={loading} alt="loading" />
          ) : (
            "CONFIRMAR"
          )}
        </ColoredButton>
      </div>
    </Container>
  );
}
