import React, { useReducer, useState, useEffect, memo } from "react";

import CustomListTable from "./components/CustomListTable";
import customListStore, {
  customListSetData,
  customListUpdateTableData,
  customListUpdateChildrens,
  customListFilterConfig,
} from "./customListStore";
import reducer from "./customListStore/reducer";
import CustomListTopComponent from "./components/CustomListTopComponent";
import CustomListCheckData from "./utils/CustomListCheckData";

import CustomListTableBody from "./components/CustomListTable/CustomListTableBody";
import CustomListTableCell from "./components/CustomListTable/CustomListTableBody/CustomListTableCell";
import CustomListCloseModal from "./components/Buttons/CustomListCloseModal";
import CustomListCreateComponent from "./components/CustomListModal/CustomListCreateComponent";
import CustomListCustomBody from "./components/CustomListCustomBody";
import CustomListDeleteButton from "./components/Buttons/CustomListDeleteButton";
import CustomListEditButton from "./components/Buttons/CustomListEditButton";
import CustomListEditComponent from "./components/CustomListModal/CustomListEditComponent";
import CustomListTableHeader from "./components/CustomListTable/CustomListTableHeader";
import CustomListShowButton from "./components/Buttons/CustomListShowButton";
import CustomListShowComponent from "./components/CustomListModal/CustomListShowComponent";
import LoadingPage from "../LoadingPage";

/**
 * É preciso informar os dados da tabela seja através do atributo data ou requests, é recomendado informar o request do findOne e deleteOne.
 * Também é esperado recebe os seguintes componentes como childrens para criar toda a lógica do crud e filtros:
 *  - CustomListTableHeader;
 *  - CustomListTableBody;
 *  - CustomListShowComponent;
 *  - CustomListEditComponent;
 *  - CustomListCreateComponent;
 * Caso seja necessário informar outro componente, adicione o nome do componente como attributo iniciando com letra minúscula.
 * Há outros componentes utilizados para obter ou alterar dados exportados no mesmo local.
 * @param {Object} props
 *  @param {Object[]} props.data Caso não queira enviar um request com o findAll, esse servirá como a lista de Array de objetos usado para popular a tabela.
 *  @param {Function} props.rowClick Recebe uma função, ao clicar em uma linha da tabela, esta será acionada passando retornando os dados desta linha.
 *  @param {Number} props.rows Informa a quantidade de linhas que a tabela irá renderizar.
 *  @param {Boolean} props.reloadTable Padrão true. Caso seja true, irá chamar o método findAll de forma síncrona enquanto visualiza outra tela que não seja a tabela.
 *  @param {Boolean} props.reloadOne Padrão true. Caso o reloadTable esteja false, será atualizado o dado da linha que for editada.
 *  @param {Boolean} props.sortOnEdit Caso informado, irá usar um sort na tabela após editar algum dado.
 *  @param {String} props.primaryKey Caso a chave primária não seja "cod", informe uma nova.
 *  @param {Object} props.errorModal Padrão true com errorHandle. Mostra um modal de error, caso receba no erro objeto de erro do Youch, será retornado a mensagem do primeiro erro que tenha como true a chave errorHandled.
 *    @param {Boolean} props.errorModal.errorHandle Padrão true, busca em um objeto de erro do Youch a mensagem do primeiro erro que tenha como true a chave errorHandled.
 *    @param {string} props.errorModal.message Altera o texto padrão do modal.
 *  @param {Object} props.requests Objeto que aceita os tipos de requests utilizados para carregar as informações da tabela, cada chave aceita uma string que será usada como url da request, ou uma função..
 *  @param {Function} props.requests.findAll Faz uma requisição que espera como retorno um array de dados para popular a tabela.
 *  @param {Function} props.requests.deleteOne Caso seja passada uma função, é enviado como parâmetro o dado obtido do findOne ou da linha caso não o tenha informado.
 *  @param {Function} props.requests.findOne Caso seja passada uma função, é enviado como parâmetro o dado obtido da linha clicada.
 */
function CustomTable({
  data,
  rows = 7,
  errorModal = {
    errorHandle: true,
  },
  requests,
  reloadTable = true,
  rowClick,
  primaryKey,
  children,
  reloadOne = true,
  sortOnEdit,
}) {
  const initialState = {
    primaryKey: primaryKey || "cod",
    sortOnEdit,
    modal: false,
    errorModal,
    filteredData: [],
    rows: rows,
    data: [],
    requests,
    reloadTable,
    reloadOne,
    rowClick,
    editedData: {},
    childrens: {},
    rowData: {},
    page: 0,
  };

  const customListReducer = useReducer(reducer, initialState);
  const [loadedComponent, setLoadedComponent] = useState(false);
  const [state, dispatch] = customListReducer;
  const [columnNames, setColumnNames] = useState([]);

  // Carrega a tabela
  useEffect(() => {
    if (!loadedComponent) {
      if (requests?.findAll) {
        async function handleLoadData() {
          const response = await requests.findAll();

          if (response?.data) {
            dispatch(customListSetData(response.data, requests));
            setLoadedComponent(true);
          }
        }
        handleLoadData();
      } else if (data) {
        data && dispatch(customListSetData(data, requests));
        setLoadedComponent(true);
      }
    }
  }, [requests, data, dispatch, loadedComponent]);

  // Atualiza os childrens e configurações de filtro
  useEffect(() => {
    let newChildrens = {};
    let newFiltersConfig = {};
    let newColumnNames = [];

    React.Children.forEach(children, (child) => {
      if (child.type === CustomListTableBody || child?.props?.tableBody) {
        newChildrens.tableBody = child;
        if (child?.props?.children) {
          let thisKeys = [];
          let thisSearch = [];
          let thisFilter = [];
          React.Children.forEach(child.props.children, (td, i) => {
            if (td.props && td.props.name) {
              thisKeys.push(td.props.name);
              td.props.search &&
                thisSearch.push({ label: td.props.search, name: `${i}` });
              td.props.filter && thisFilter.push({ list: td.props.filter, name: `${i}` });
            }
          });
          newColumnNames = thisKeys;
          newFiltersConfig.search = thisSearch;
          newFiltersConfig.filter = thisFilter;
        }
      } else if (child.type === CustomListCustomBody || child?.props?.customBody) {
        newChildrens.customBody = child;
        if (Array.isArray(child?.props?.finders)) {
          let thisKeys = [];
          let thisSearch = [];
          let thisFilter = [];

          child.props.finders.forEach((e, i) => {
            thisKeys.push(e.key);
            e.search && thisSearch.push({ label: e.search, name: `${i}` });
            e.filter && thisFilter.push({ list: e.filter, name: `${i}` });
          });

          newColumnNames = thisKeys;
          newFiltersConfig.search = thisSearch;
          newFiltersConfig.filter = thisFilter;
        }
      } else if (child.type === CustomListTableHeader || child?.props?.tableHeader) {
        newChildrens.tableHeader = child;
      } else if (
        child.type === CustomListShowComponent ||
        child?.props?.tableShowComponent
      ) {
        newChildrens.tableShowContent = child;
      } else if (
        child.type === CustomListEditComponent ||
        child?.props?.tableEditComponent
      ) {
        newChildrens.tableEditContent = child;
      } else if (
        child.type === CustomListCreateComponent ||
        child?.props?.tableCreateComponent
      ) {
        newChildrens.tableCreateForm = child;
      }
    });

    dispatch(customListUpdateChildrens({ ...newChildrens }));
    dispatch(customListFilterConfig({ ...newFiltersConfig }));
    setColumnNames(newColumnNames);
  }, [children, dispatch]);

  // Filtro
  useEffect(() => {
    if (state.data) {
      const listSize = state.data.length;
      const newAlunosList = [];
      let newAlunosSubList = [];

      for (let i = 0; i < listSize; i++) {
        let include = true;
        columnNames &&
          columnNames.forEach((e, columnIndex) => {
            let columnFilter = state.filters[columnIndex];
            if (columnFilter) {
              let thisValue = CustomListCheckData(e, state.data[i]).value;
              typeof columnFilter !== "string" && (columnFilter = `${columnFilter}`);
              typeof thisValue !== "string" && (thisValue = `${thisValue}`);

              if (!thisValue?.toLowerCase()?.includes(columnFilter?.toLowerCase())) {
                include = false;
              }
            }
          });
        if (include === true && newAlunosSubList.length < rows) {
          newAlunosSubList.push(state.data[i]);
        }
        if (newAlunosSubList.length >= rows || i === listSize - 1) {
          newAlunosList.push(newAlunosSubList);
          newAlunosSubList = [];
        }
      }

      dispatch(customListUpdateTableData(newAlunosList));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, rows, state.data, state.filters]);

  return (
    <customListStore.Provider value={{ state, dispatch }}>
      <CustomListTopComponent />
      {state.modal && state.modal}
      {!loadedComponent ? (
        <LoadingPage />
      ) : (
        (state?.childrens?.tableBody && <CustomListTable />) ||
        state?.childrens?.customBody
      )}
    </customListStore.Provider>
  );
}

export default memo(CustomTable);

export {
  CustomListShowComponent as CLShowComponent,
  CustomListEditComponent as CLEditComponent,
  CustomListTableHeader as CLTableHeader,
  CustomListTableBody as CLTableBody,
  CustomListCreateComponent as CLCreateComponent,
  CustomListCustomBody as CLCustomBody,
  CustomListCloseModal as CLCloseModal,
  CustomListTableCell as CLTableCell,
  CustomListDeleteButton as CLDeleteButton,
  CustomListEditButton as CLEditButton,
  CustomListShowButton as CLShowButton,
};
