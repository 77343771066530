import React, { useState, useLayoutEffect } from "react";

import api from "~/services/api";
import CreateContent from "./CreateContent";
import ShowContent from "./ShowContent";
import { SLCreateModal, SLShowModal } from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import TableComponent from "./TableComponent";

function ReuniaoFuncionario() {
  const [mountedData, setMountedData] = useState();
  const [tabFilter, setTabFilter] = useState({ status: 1 });
  const [funcionario, setFuncionarios] = useState([]);

  useLayoutEffect(() => {
    api.get("filial/funcionariosFilial").then((response) => response?.data && setFuncionarios(response.data));
  }, []);

  return (
    <Container
      requests={{
        findAll: () => api.get("filial/reunioesfuncionario", { params: { paranoid: false } }),
        deleteOne: (thisData) => api.delete("filial/reuniaofuncionario", { data: { cod: thisData.cod } }),
        restoreOne: (thisData) =>
          api.post("filial/reuniaofuncionario/restaura", { data: { cod: thisData.cod } }),
        findOne: (thisData) => api.get("filial/reuniaofuncionario", { params: { cod: thisData.cod } }),
      }}
      dataFilter={tabFilter}
    >
      <TableComponent {...{ setTabFilter }} />

      <SLShowModal title="Reunião" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLCreateModal title="Nova Reunião">
        <CreateContent {...{ funcionario }} />
      </SLCreateModal>
    </Container>
  );
}

export default ReuniaoFuncionario;
