import React from "react";
import ColoredButton from "~/componentes/CustomForm/Button/Styles/ColoredButton";
import {
  EduquemeInput,
  EduqueMeSenhaInput,
} from "~/componentes/CustomForm/Input/styles";
import Label from "~/componentes/CustomForm/Label";
import CustomToast from "~/componentes/CustomToast";
import api from "~/services/api";
import { Materialize } from "~/styles/materializeGrid";
import ShowLoading from "~/utils/ShowLoading";

import { Container } from "./styles";

// import { Container } from './styles';

function ThisModal({ outClick, defaultData }) {
  const [email, setEmail] = React.useState();
  const [senhaToken, setSenhaToken] = React.useState();
  const [senhas, setSenhas] = React.useState({
    novaSenha: "",
    confirmNovaSenha: "",
  });
  const [disableEmail, setDisableEmail] = React.useState(false);
  const [validToken, setValidToken] = React.useState(false);

  async function sendToken() {
    ShowLoading(() =>
      api
        .post("senhas", { email })
        .then((r) => {
          setDisableEmail(!!r?.data?.sent);
          CustomToast.success("Email enviado!");
        })
        .catch((r) => {
          setDisableEmail(false);
          CustomToast.error("Falha ao enviar o email.");
        })
    );
  }

  async function validarToken(thisData) {
    ShowLoading(() =>
      api
        .get("validarTokenSenha", {
          params: {
            email: thisData?.email || email,
            senhaToken: thisData?.senhaToken || senhaToken,
          },
        })
        .then((r) => {
          setValidToken(!!r?.data?.valid);
          !thisData && CustomToast.success("Token válido!");
        })
        .catch((r) => {
          setValidToken(false);
          CustomToast.error("Token inválido!");
        })
    );
  }

  React.useEffect(() => {
    if (defaultData?.email && defaultData?.senhaToken) {
      setEmail(defaultData?.email);
      setSenhaToken(defaultData?.senhaToken);
      validarToken(defaultData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultData]);

  async function updateSenha(handleCloseModal) {
    const { novaSenha, confirmNovaSenha } = senhas || {};
    if (novaSenha && confirmNovaSenha) {
      if (novaSenha !== confirmNovaSenha) {
        return CustomToast.error("Confirme a senha corretamente.");
      }
      ShowLoading(() =>
        api
          .put("atualizarSenha", {
            novaSenha,
            email,
            senhaToken,
          })
          .then((r) => {
            if (r?.status === 200) {
              CustomToast.success("Senha atualizada com sucesso!");
              typeof handleCloseModal === "function" && handleCloseModal();
            }
          })
          .catch((r) => {
            CustomToast.error("Falha ao atualizar a senha.");
          })
      );
    } else {
      CustomToast.error(
        "É necessário informar a nova senha e sua confirmação."
      );
    }
  }

  return (
    <Container
      title="Alterar senha de acesso"
      footerButtons={{
        submitButton: {
          type: "button",
          onClick: validToken ? updateSenha : validarToken,
        },
      }}
      scrollBar
      outClick={outClick}
    >
      <Materialize>
        <div className="row">
          {validToken ? (
            <>
              <Label title="Nova senha" className="col s12 m12 l12">
                <EduqueMeSenhaInput
                  required
                  setter={(v) =>
                    setSenhas((prev) => ({ ...prev, novaSenha: v }))
                  }
                  notForm
                />
              </Label>
              <Label title="Confirme a nova senha" className="col s12 m12 l12">
                <EduqueMeSenhaInput
                  required
                  setter={(v) =>
                    setSenhas((prev) => ({ ...prev, confirmNovaSenha: v }))
                  }
                  notForm
                />
              </Label>
            </>
          ) : (
            <>
              <Label title="Email" className="col s12 m12 l12">
                <div className="Label_inputWithButton">
                  <EduquemeInput
                    required
                    value={email || ""}
                    disabled={disableEmail}
                    onChange={(e) => {
                      const v = e.currentTarget.value;
                      setEmail(v);
                    }}
                    notForm
                  />
                  <ColoredButton
                    type="button"
                    greenLayout
                    small
                    materialIcon="check"
                    onClick={sendToken}
                  />
                </div>
              </Label>
              <p className="col s12 m12 l12 RecuperarSenha_p_info">
                Será enviado um email contendo uma chave de recuperação de
                senha. Copie e cole a chave abaixo, e em seguida clique em
                confirmar.
              </p>
              <Label
                title="Chave"
                className="col s12 m12 l12 RecuperarSenha_p_info"
              >
                <EduquemeInput
                  value={senhaToken || ""}
                  required
                  disabled={validToken}
                  onChange={(e) => {
                    const v = e.currentTarget.value;
                    setSenhaToken(v);
                  }}
                  notForm
                />
              </Label>
            </>
          )}
        </div>
      </Materialize>
    </Container>
  );
}

export default ThisModal;
