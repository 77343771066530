import React from "react";

const CheckWrapperStore = React.createContext({
  state: {},
  dispatch: () => {},
});

export function CheckWrapperHandleData(props, selected) {
  return {
    type: "CheckWrapperHandleData",
    payload: { props, selected },
  };
}

export function CheckWrapperReloadDependency(reloadDependency) {
  return { type: "CheckWrapperReloadDependency", payload: { reloadDependency } };
}

export function CheckWrapperClearData() {
  return { type: "CheckWrapperClearData" };
}

export function CheckWrapperDefaultData(data) {
  return { type: "CheckWrapperDefaultData", payload: { data } };
}

export function CheckWrapperDisabledList(disabledData) {
  return { type: "CheckWrapperDisabledList", payload: { disabledData } };
}

export function CheckWrapperSelectedDisabledList(defaultDisabledData) {
  return { type: "CheckWrapperSelectedDisabledList", payload: { defaultDisabledData } };
}

export default CheckWrapperStore;
