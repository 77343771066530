export default function formFindKeyValue(name, object) {
  if (name) {
    function isObject(data) {
      return Object.prototype.toString.call(data) === "[object Object]";
    }

    object = isObject(object) ? object : {};

    let path = name.split(".");

    function createPath([key, ...rest], thisObject) {
      let checkObject = thisObject ? thisObject : {};
      if (rest.length > 0) {
        return createPath(rest, checkObject[key]);
      } else {
        return checkObject[key];
      }
    }

    return createPath(path, object);
  }
  return object;
}
