import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { signOut } from "~/store/modules/auth/actions";
import { BiLogOut, BiLogIn } from "react-icons/bi";

import Logo from "~/assets/logos/eduque_me_negativo.png";
import { Button } from "~/componentes/CustomForm";
import {
  Container,
  LogoButton,
  DefaultButtonsDiv,
  ThisColoredButton,
  Perfil,
  LoggedInButtonsDiv,
} from "./styles";

function MobileMenu() {
  const dispatch = useDispatch();
  const {
    auth: { token },
    user: { perfil },
  } = useSelector((state) => state) || {};
  const [showButtons, setShowButtons] = React.useState(false);

  const logado = token && perfil?.nome;

  return (
    <Container>
      <div className="toggleLogoDiv">
        <button
          onClick={() => setShowButtons(!showButtons)}
          className="material-icons toggleMenu"
        >
          menu
        </button>
        <LogoButton clean link="/">
          <img className="eduque-me" src={Logo} alt="Eduque-me" />
        </LogoButton>
      </div>

      {showButtons && (
        <div>
          <DefaultButtonsDiv>
            <ThisColoredButton link="/matricula" redLayout>
              Matrícula
            </ThisColoredButton>
            <ThisColoredButton link="/contato" blueLayout>
              Contato
            </ThisColoredButton>
            {logado ? (
              <Perfil>
                <button className="letterButton">{perfil?.nome[0]}</button>
              </Perfil>
            ) : (
              <ThisColoredButton link="/entrar" yellowLayout>
                Entrar
              </ThisColoredButton>
            )}
          </DefaultButtonsDiv>
          {logado && (
            <LoggedInButtonsDiv>
              <Button link="/sistema">
                <BiLogIn className="PerfilModal_Button_icon" />
                Entrar no sistema
              </Button>
              <Button onClick={() => dispatch(signOut())}>
                <BiLogOut className="PerfilModal_Button_icon" />
                Sair da conta
              </Button>
            </LoggedInButtonsDiv>
          )}
        </div>
      )}
    </Container>
  );
}

export default MobileMenu;
