export default function layout(props) {
  const { redLayout, greenLayout, blueLayout, yellowLayout } = props || {};

  if (redLayout) {
    return {
      background: "#FF4031",
      color: "#fff",
      shadowColor: "#CF362A",
      hoverBackground: "#f52819",
    };
  } else if (greenLayout) {
    return {
      background: "#4EA654",
      color: "#fff",
      shadowColor: "#3D7D42",
      hoverBackground: "#489d4e",
    };
  } else if (blueLayout) {
    return {
      background: "#1CA9D0",
      color: "#fff",
      shadowColor: "#288199",
      hoverBackground: "#1b9cc0",
    };
  } else if (yellowLayout) {
    return {
      background: "#FFDA44",
      color: "#fff",
      shadowColor: "#CCAA1F",
      hoverBackground: "#f7cf2b",
    };
  } else {
    return {
      background: "#aaa",
      color: "#fff",
      shadowColor: "#666",
      hoverBackground: "#bbb",
    };
  }
}
