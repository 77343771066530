import styled from "styled-components";
import SistemaFixedModal from "~/componentes/Modal/Styleds/SistemaFixedModal";

export const Container = styled(SistemaFixedModal)`
  width: 30em;
  max-width: 100%;

  p {
    margin: 0.5em 1em;
  }

  .Perfil_Confirmacao_Label_input_senha {
    width: 15em;
    margin: 1em;
    max-width: 100%;
    margin: 0.5em auto 1em;
  }
`;
