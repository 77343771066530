/* eslint-disable no-useless-computed-key */
import React, { useState, useLayoutEffect } from "react";

import api from "~/services/api";
import CreateEditContent from "./CreateEditContent";
import ShowContent from "./ShowContent";
import {
  SLCreateModal,
  SLShowModal,
  SLEditModal,
  SLEditButton,
  SLShowButton,
  SLDeleteButton,
  SLRestoreButton,
} from "~/pages/Sistema/componentes/SistemaList";

import { Container } from "./styles";
import EducaBitsImg from "~/assets/icones/educa_bits.png";
import EstrelaImg from "~/assets/icones/estrelas.png";
import TabsWrapper, { Tab } from "~/componentes/StyledComponents/TabsWrapper";
import { SistemaComponenteInterno } from "~/pages/Sistema/componentes";
import Pagination from "~/componentes/Pagination";

function Conquistas() {
  const [mountedData, setMountedData] = useState();
  const [data, setData] = useState([]);
  const [tipoConquista, setTipoConquista] = useState([]);
  const [tabFilter, setTabFilter] = useState({
    status: 1,
    ["tipoConquista.versaoApp.ativo"]: true,
  });
  const [pageData, setPageData] = useState([]);
  const [versaoApp, setVersaoApp] = useState([]);
  const [disciplina, setDisciplina] = useState([]);

  useLayoutEffect(() => {
    api
      .get("admin/tipoconquistas")
      .then((response) => response?.data && setTipoConquista(response.data));
    api
      .get("admin/versoesapp")
      .then((response) => response?.data && setVersaoApp(response.data));
    api
      .get("admin/disciplinas")
      .then((response) => response?.data && setDisciplina(response.data));
  }, []);

  return (
    <Container
      inputs={[{ filter: "nome", label: "Nome" }]}
      selects={[
        {
          filter: "tipoConquistaCod",
          list: tipoConquista,
          outLabel: "Tipo",
          value: "cod",
          label: "nome",
        },
        {
          filter: "versaoAppCod",
          list: versaoApp,
          outLabel: "Versão App",
          value: "cod",
          label: "nome",
        },
        {
          filter: "disciplinaCod",
          list: disciplina,
          outLabel: "Disciplina",
          value: "cod",
          label: "nome",
        },
      ]}
      requests={{
        findAll: () => api.get("admin/conquistas", { params: { paranoid: false } }),
        deleteOne: (thisData) =>
          api.delete("admin/conquista", { data: { cod: thisData.cod } }),
        restoreOne: (thisData) =>
          api.post("admin/conquista/restaura", { data: { cod: thisData.cod } }),
        findOne: (thisData) =>
          api.get("admin/conquista", { params: { cod: thisData.cod } }),
      }}
      setter={setData}
      dataFilter={tabFilter}
    >
      <TabsWrapper tabHeight={2.4}>
        <Tab
          materialIcon="north"
          onClick={() => setTabFilter({ status: 1, ["versaoApp.ativo"]: true })}
        >
          Versão Atual
        </Tab>
        <Tab
          materialIcon="south"
          onClick={() => setTabFilter({ status: 1, ["versaoApp.ativo"]: false })}
        >
          Versões Anteriores
        </Tab>
        <Tab materialIcon="highlight_off" onClick={() => setTabFilter({ status: 0 })}>
          Desativo
        </Tab>
      </TabsWrapper>

      <SistemaComponenteInterno>
        <div className="pai-conquista-card">
          {pageData.map((e) => (
            <div className="conquista-card">
              <div
                className={
                  e?.status === 1 ? "conquista-card-box" : "conquista-card-box desativo"
                }
              >
                <div className="conquista-card-head">
                  <p className="title-card">{e?.nome}</p>
                  <p className="sub-title-card">
                    {e?.versaoApp?.nome} - {e?.tipoConquista?.nome}
                  </p>
                </div>
                <div className="conquista-card-body">
                  {e?.arquivo?.url && <img src={e.arquivo.url} alt="imagem" />}
                </div>
                <div className="conquista-card-footer">
                  <div className="card-estrelas">
                    <p>
                      <img src={EducaBitsImg} alt="imagem" />
                      {e?.educabits}
                    </p>
                  </div>
                  <div className="card-estrelas">
                    <p>
                      <img src={EstrelaImg} alt="imagem" />
                      {e?.estrelas}
                    </p>
                  </div>
                  <div className="card-action">
                    {e?.status === 1 && <SLShowButton data={e} />}
                    {e?.status === 1 && <SLEditButton data={e} />}
                    {e?.status === 1 && <SLDeleteButton data={e} />}
                    {e?.status === 0 && <SLRestoreButton data={e} />}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Pagination
          data={data}
          getInfo={({ pageData }) => {
            setPageData(pageData);
          }}
          size={9}
        />
      </SistemaComponenteInterno>

      <SLShowModal title="Conquista" setter={setMountedData}>
        <ShowContent {...{ mountedData }} />
      </SLShowModal>

      <SLEditModal title="Atualizar Conquista" setter={setMountedData}>
        <CreateEditContent {...{ mountedData, tipoConquista, disciplina }} />
      </SLEditModal>

      <SLCreateModal title="Nova Conquista">
        <CreateEditContent {...{ tipoConquista, disciplina }} />
      </SLCreateModal>
    </Container>
  );
}

export default Conquistas;
