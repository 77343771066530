import styled from "styled-components";
import Modal from "~/componentes/Modal";
import { Button } from "~/componentes/CustomForm";

export const TriggerButton = styled(Button)`
  margin: auto 0.5em;
  display: flex;
  align-items: center;
  /* margin-left: -15em; */

  h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.875em;
    line-height: 1.188em;
    color: #293f57;
    margin: 0 0.125em;
    text-transform: uppercase;
    text-align: end;
    width: 13.125em;
  }

  @media (max-width: 993px) {
    h6 {
      display: none !important;
    }
  }
`;

export const Content = styled(Modal)`
  position: absolute;
  top: 100%;
  right: 1em;
  z-index: 1;
  width: 22em;
  max-width: 100%;
  margin-right: 0.25em;
  padding: 1em;
  background: #fff;
  border-radius: 0.5em;
  box-shadow: 0 0 0.5em #0003;

  @media (max-width: 600px) {
    width: calc(100vw - 1em);
    right: 0.5em;
    left: 0.5em;
  }
`;

export const AcessoButton = styled(Button)`
  transition: all 0.1s ease;
  position: relative;
  padding: 0.5em;
  padding-left: 1.125em;
  width: 100%;
  border-bottom: solid #f0f0f0 0.125em !important;

  & + & {
    margin: 1em 0 0 0;
  }

  h5,
  p,
  span {
    color: ${(props) => (props.ativo ? "#293f57" : "#7b7b7b")};
  }

  .Acessos_localTrabalho {
    font-style: normal;
    font-weight: normal;
    font-size: 0.875em;
    line-height: 1.188em;

    color: #7b898c;
  }

  :active {
    transform: scale(0.98);
  }

  ::before {
    content: "";
    position: absolute;
    width: 0.3em;
    height: 75%;
    left: -0.125em;
    top: 0.4em;
    background: ${(props) => (props.ativo ? "#4fa754" : "#adadad")};
    border-radius: 0.125em;
  }
`;

export const FlexH5 = styled.div`
  display: flex;
  justify-content: space-between;

  h5 {
    font-weight: 600;
    font-size: 1em;
    line-height: 1.563em;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-height: 1.2;
  }
  span {
    line-height: 1.563em;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875em;
    text-transform: uppercase;
  }
`;
