/* eslint-disable eqeqeq */
import React from "react";
import Label from "~/componentes/CustomForm/Label";
import { SistemaSelect } from "~/componentes/CustomForm/Select/styles";
import { MaterializeContent } from "../styles";

import { Container } from "./styles";

function SecondFilter({ data, setFilteredData }) {
  const [disciplina, setDisciplina] = React.useState();
  const [turma, setTurma] = React.useState();

  const disciplinas = React.useMemo(() => {
    const thisDisciplinas = {};

    if (Array.isArray(data?.disciplinasTurmas)) {
      data.disciplinasTurmas.forEach((dtm) => {
        if (dtm?.turma?.cod && dtm?.disciplinaFilial?.cod) {
          if (thisDisciplinas?.[dtm.disciplinaFilial.cod]?.turmas) {
            thisDisciplinas[dtm.disciplinaFilial.cod].turmas[dtm.turma.cod] = {
              ...dtm.turma,
              disciplinaTurma: dtm,
            };
          } else {
            thisDisciplinas[dtm.disciplinaFilial.cod] = {
              ...dtm.disciplinaFilial,
              turmas: {
                [dtm.turma.cod]: { ...dtm.turma, disciplinaTurma: dtm },
              },
            };
          }
        }
      });
    }

    const result = Object.values(thisDisciplinas);

    result.forEach((e) => {
      if (e?.turmas) {
        e.turmas = Object.values(e.turmas);
      }
    });

    setDisciplina(result[0]);
    setTurma(result[0]?.turmas?.[0]);

    return result;
  }, [data]);

  React.useEffect(() => {
    setFilteredData(turma?.disciplinaTurma);
  }, [setFilteredData, turma]);

  return (
    <Container>
      <MaterializeContent>
        <div className="row">
          <Label title="Disciplina" className="col s12 m6 l6">
            <SistemaSelect
              list={{
                list: disciplinas,
                value: "cod",
                label: "nome",
              }}
              onChange={(e) => {
                const val = e.currentTarget.value;
                const thisFind = disciplinas?.find((t) => t?.cod == val);
                setDisciplina(thisFind);
                setTurma(thisFind?.turmas?.[0]);
              }}
              value={disciplina?.cod}
            />
          </Label>
          <Label title="Turmas" className="col s12 m6 l6">
            <SistemaSelect
              list={{
                list: disciplina?.turmas,
                value: "cod",
                label: "nome",
              }}
              onChange={(e) => {
                const val = e.currentTarget.value;
                const thisFind = disciplina?.professores?.find((t) => t?.cod == val);
                setTurma(thisFind);
              }}
              value={turma?.cod}
            />
          </Label>
        </div>
      </MaterializeContent>
    </Container>
  );
}

export default SecondFilter;
