import styled from "styled-components";
import SistemaTable from "~/componentes/StyledComponents/Tables/SistemaTable";

export const Container = styled.div`
  .PeriodoMatricula_FilterList_div {
    margin-bottom: 1em;
    display: flex;
    justify-content: space-between;

    select {
      width: 100%;
      margin-right: 1em;
    }
  }
`;

export const ThisTable = styled(SistemaTable)`
  td:last-child,
  th:last-child {
    width: 6em;
    text-align: center;
  }
`;
